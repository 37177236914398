import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import BuyerContractFiltersList from "./BuyerContractFiltersList";
import Title from "../Title";
import BuyerContractFilter from "../Drawers/BuyerContractFilter";

type BuyerContractFiltersType = {
  height?: number;
};

const BuyerContractFilters: React.FC<BuyerContractFiltersType> = ({
  height = 650,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const categoryId = Admediary.category;
  const category = Admediary.category ? Admediary.category : "ALL";
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    category_id: categoryId,
    buyer_id: Admediary.buyer,
  };

  const [data, isLoading] = useAdmediaryApi("buyer_contract_filters", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddContractClick = () => {
    // Set selected content into drawer
    Admediary.openDrawer(
      <BuyerContractFilter filterId={0} refreshData={refresh} />,
      "sm"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["contract_name", "description", "reason"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <>
      <Title title="Buyer Contract Filters" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            // style={{ marginLeft: 20 }}
            onClick={handleAddContractClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Filter
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerContractFiltersList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default BuyerContractFilters;

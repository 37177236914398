import React from "react";
import { Col, Drawer, Grid, Icon, Row } from "rsuite";
import StatCard from "../Visualization/StatCard";
import { Link } from "react-router-dom";
import BudgetsByBuyerContract from "../Budgets/BudgetsByBuyerContract";
import LeadsByDayLineGraph from "../Visualization/LeadsByDayLineGraph";
import TopAffiliatesByLeadsBarGraph from "../Visualization/TopAffiliatesByLeadsBarGraph";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

type BuyerOverview = {
  buyer_id: number;
  buyer_name?: string;
  leads_sold?: number;
  price_owed?: number;
  price_received?: number;
};

const BuyerOverview: React.FC<BuyerOverview> = ({
  buyer_id,
  buyer_name,
  leads_sold = undefined,
  price_owed = undefined,
  price_received = undefined,
}) => {
  const admediary_context = React.useContext(AdmediaryContext);
  const buyer_details = admediary_context.config.buyers.filter(
    (item: any) => item.buyer_id === buyer_id
  );

  //   .shift();
  const buyer_contracts = admediary_context.config.contracts_by_buyer.filter(
    (item: any) => item.buyer_id === buyer_id
  );
  const [budget, setBudget] = React.useState<number | undefined>();
  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: admediary_context.category,
    product_id: admediary_context.product,
    affiliate_id: 0,
    buyer_id: buyer_details[0].buyer_id,
    group: "",
  };

  const [data, leadsIsLoading] = useAdmediaryApi(
    "buyer_contract_counts",
    params
  );
  const [budgetData, budgetDataIsLoading] = useAdmediaryApi(
    "buyer_contract_budgets",
    params
  );

  const captureContractData = (data: any): void => {
    let total = 0;
    data.forEach((item: any) => {
      total += item.cap;
    });
    setBudget(total);
  };

  let considered = undefined,
    accepted = leads_sold;
  let total_budget = 0;
  if (!leadsIsLoading && Array.isArray(data)) {
    accepted = data[0].leads_sold;
    const { filtered, rejected } = data[0];
    considered = filtered + rejected;
  }

  if (!budgetDataIsLoading && Array.isArray(budgetData)) {
    budgetData.forEach((item: any) => {
      total_budget += item.cap;
    });
  }

  const profit =
    price_received !== undefined && price_owed !== undefined
      ? price_received - price_owed
      : undefined;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>
          <Link
            to={`/buyer/${buyer_id}`}
            onClick={admediary_context.closeDrawer}
          >
            {buyer_name} <Icon icon="external-link" />
          </Link>
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid style={{ width: "100%" }}>
          <Row style={{ marginBottom: 25 }}>
            <Col
              xs={profit ? 6 : 8}
              className="content-card overview-stat-card"
            >
              <StatCard label="Considered" type="number" value={considered} />
            </Col>
            <Col
              xs={profit ? 6 : 8}
              className="content-card overview-stat-card"
            >
              <StatCard label="Accepted" type="number" value={accepted} />
            </Col>
            <Col
              xs={profit ? 6 : 8}
              className="content-card overview-stat-card"
            >
              {/* <StatCard label="Budget" type="number" value={total_budget} /> */}
              <StatCard label="Budget" type="number" value={budget} />
            </Col>
            {profit ? (
              <Col xs={6} className="content-card overview-stat-card">
                <StatCard label="Profit" type="currency" value={profit} />
              </Col>
            ) : null}
          </Row>
          {buyer_contracts.length > 0 ? (
            <Row>
              <Col xs={24}>
                <h5>Leads by Contract</h5>
                <div style={{ height: 295 }}>
                  <BudgetsByBuyerContract
                    buyer_id={buyer_id}
                    callback={captureContractData}
                    limit={8}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col xs={24}>
              <h5>Leads by Day</h5>
              <div style={{ height: 295 }}>
                <LeadsByDayLineGraph buyer_id={buyer_id} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <h5>Leads by Affiliate</h5>
              <div style={{ height: 295 }}>
                <TopAffiliatesByLeadsBarGraph buyer_id={buyer_id} />
              </div>
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
    </>
  );
};

export default BuyerOverview;

import React from "react";
import { FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import EsignDocAffiliatesTable from "./EsignAffiliatesTable";
import { format } from "date-fns";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";
import { percent, currency } from "../../@Utils/Format";

const addCalculatedValues = (row: any) => {
  row.clicks = isNaN(parseFloat(row.clicks)) ? 0 : parseFloat(row.clicks);
  row.presented = isNaN(parseFloat(row.presented))
    ? 0
    : parseFloat(row.presented);
  row.completed = isNaN(parseFloat(row.completed))
    ? 0
    : parseFloat(row.completed);
  row.non_retainer_count = isNaN(parseFloat(row.non_retainer_count))
    ? 0
    : parseFloat(row.non_retainer_count);
  row.non_retainer_received_amount = isNaN(
    parseFloat(row.non_retainer_received_amount)
  )
    ? 0
    : parseFloat(row.non_retainer_received_amount).toFixed(2);
  row.received_amount = isNaN(parseFloat(row.received_amount))
    ? 0
    : parseFloat(row.received_amount).toFixed(2);

  row.doc_completed_rate = isNaN(parseFloat(row.doc_completed_rate))
    ? 0
    : parseFloat(row.doc_completed_rate);

  row.paid_amount = isNaN(parseFloat(row.paid_amount))
    ? 0
    : parseFloat(row.paid_amount).toFixed(2);

  const total_received =
    parseFloat(row.non_retainer_received_amount) +
    parseFloat(row.doc_completed_rate);
  const profit = total_received - parseFloat(row.paid_amount);
  const loss = parseFloat(row.paid_amount) - total_received;
  const totalLeads = row.presented + row.non_retainer_count;
  const formFillRate =
    row.clicks === 0 || isNaN(row.clicks)
      ? 0
      : (row.total_leads / row.clicks) * 100;

  return {
    ...row,
    ...{
      completed_percent:
        row.presented !== 0 ? (row.completed / row.presented) * 100 : 0,
      profit: profit > 0 ? profit : 0,
      loss:
        row.paid_amount > 0 && profit <= 0 ? "-" + Number(loss).toFixed(2) : 0,
      form_percent: isNaN(formFillRate) ? 0 : formFillRate,
    },
  };
};

const addCalculatedValuesRecursively = (list: any) => {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
};

const getSummary = (data: any) => {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "clicks",
    "total_leads",
    "presented",
    "completed",
    "completed_percent",
    "non_retainer_count",
    "paid_amount",
    "doc_completed_rate",
    "non_retainer_received_amount",
    "profit",
    "loss",
    "form_percent",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
};

const EsignDocAffiliates: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState("desc");
  const [sort_column, set_sort_column] = React.useState("date_created");
  const defaultGroupValue = "date_created";
  const defaultTypeValue = "affiliate";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [type, setType] = React.useState(defaultTypeValue);

  const groupByList = [
    { group: "date_created", label: "Date Created" },
    { group: "date_modified", label: "Date Completed" },
  ];
  const typeList = [
    { type: "affiliate", label: "Affiliate" },
    { type: "buyer", label: "Buyer" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    group: group,
    buyer_id: Admediary.buyer,
    type: type,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "panda_affiliate_select",
    params
  );

  const formatExportData = (data: any) => {
    return (
      data
        .map((item: any) => item)
        // Clean, format and reorder by fields with right headers
        .map((item: any) => {
          const ping_tree_received = parseInt(
            item.non_retainer_received_amount
          );
          const total_cost = parseInt(item.paid_amount);
          const doc_completed_rate = parseInt(item.doc_completed_rate);

          if (type === "affiliate") {
            return {
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              "Affiliate Manager": item.affiliate_manager,
              "Product ID": item.product_id,
              "Product Description": item.product_description,
              Clicks: item.clicks,
              "Form Fill Percent": percent(item.form_percent),
              "Total Leads": item.total_leads,
              "Total Created Docs": item.presented,
              "Completed Docs": item.completed,
              "Completed %": percent(item.completed_percent),
              "Non-Approved Leads": item.non_retainer_count,
              "Total Cost (CPC/CPA)": currency(total_cost),
              "Document Revenue": currency(doc_completed_rate),
              "Ping Tree Revenue": currency(ping_tree_received),
              Profit: currency(item.profit),
              Loss: currency(item.loss),
            };
          } else {
            return {
              "Buyer ID": item.buyer_id,
              "Buyer Name": item.buyer_name,
              "Product ID": item.product_id,
              "Product Description": item.product_description,
              "Accepted Leads": item.total_leads,
              "Total Created Docs": item.presented,
              "Completed Docs": item.completed,
              "Completed %": percent(item.completed_percent),
              "Total Cost (CPC/CPA)": currency(total_cost),
              "Document Revenue": currency(doc_completed_rate),
              "Ping Tree Revenue": currency(ping_tree_received),
              Profit: currency(item.profit),
              Loss: currency(item.loss),
            };
          }
        })
    );
  };

  const tableData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = formatExportData(tableData);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="E-Sign Affiliates" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Filter by"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="View Type"
            defaultValue={defaultTypeValue}
            value={type}
            data={typeList}
            valueKey="type"
            labelKey="label"
            onChange={(v) => {
              setType(v);
            }}
            style={{ marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `esign_affiliates_report_${
                  group === "date_modified" ? "date_completed" : group
                }_${format(new Date(Admediary.start), "MMddyy")}_${format(
                  new Date(Admediary.end),
                  "MMddyy"
                )}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <EsignDocAffiliatesTable
        data={tableData}
        summary={summaryData}
        isLoading={isLoading === true}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
        type={type}
      />
    </>
  );
};

export default EsignDocAffiliates;

import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { exportCsv } from "../../@Utils/Export";
import PingTreesList from "./PingTreesList";
import SelectPicker from "rsuite/lib/SelectPicker";
import Title from "../Title";
import { Query } from "../../@Utils/AdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import AddPingTree from "../Drawers/AddPingTree";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      "Tree ID": item.tree_id,
      "Category ID": item.category_id,
      "Product ID": item.product_id,
      Activity: item.active,
      Description: item.description,
    };
  });
}

type PingTreesProps = {
  height?: number;
};

const PingTrees: React.FC<PingTreesProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultActiveValue = 1;
  const [active, setActive] = React.useState(defaultActiveValue);
  const activeList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const categoryId = Admediary.category;
  const productId = Admediary.product;
  const category = Admediary.category ? Admediary.category : "ALL";

  const params = {
    category_id: categoryId || 0,
    product_id: productId || 0,
    active,
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("products_routing_ping_trees", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [active, categoryId, productId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddAction = () => {
    Forms.setTree(0);
    Forms.setActive(false);
    Forms.setCategory(0);
    Forms.setProduct(0);
    Forms.setDescription("");
    // Set selected content into drawer
    Admediary.openDrawer(
      <AddPingTree fetchData={fetchData} stateLoad={setIsLoading} />,
      "lg"
    );
  };

  return (
    <>
      <Title title="Ping Tree List" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={15}>
          <SelectPicker
            size="sm"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={false}
            defaultValue={defaultActiveValue}
            value={active}
            data={activeList}
            onChange={(v) => {
              setActive(v);
            }}
            valueKey="value"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <IconButton
            onClick={handleAddAction}
            placement="left"
            size="sm"
            appearance="ghost"
            icon={<Icon icon="plus-circle" />}
            disabled={Admediary.userDisable}
          >
            Add Ping Tree
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `ping_tree_list_CAT-${category}_${
                  active ? "ACTIVE" : "INACTIVE"
                }.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <PingTreesList
        height={height}
        data={Array.isArray(data) ? data : []}
        fetchData={fetchData}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default PingTrees;

import React from "react";
import { Drawer, Icon, Loader, Toggle } from "rsuite";
import AverageResponseTimeGraph from "../Visualization/AverageResponseTimeGraph";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { format } from "date-fns";
import moment from "moment";
import AverageResponseTimeBar from "../Visualization/AverageResponseTimeBar";

type AverageResponseTimeChart = {
  contractId: number;
  buyerName?: string;
  columnDate?: Date;
};

const AverageResponseTimeChart: React.FC<AverageResponseTimeChart> = ({
  contractId = 0,
  buyerName = "",
  columnDate,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const startDate = columnDate || Admediary.start;
  const endDate = columnDate || Admediary.end;
  const [isLineGraph, setIsLineGraph] = React.useState(false);

  const params = {
    start_date: startDate,
    end_date: endDate,
    category_id: parseInt(Admediary.category) || 0,
    product_id: parseInt(Admediary.product) || 0,
    buyer_id: parseInt(Admediary.buyer) || 0,
    contract_id: contractId ? contractId : 0,
  };

  const [data, isLoading] = useAdmediaryApi(
    "average_response_time_chart",
    params
  );

  const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
  const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
  const drawerTitle = `${buyerName} [${formattedStartDate}-${formattedEndDate}]`;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {`Average Response Times - ${drawerTitle}`}
          <span>
            <span>Chart Type: </span>
            <Toggle
              size="md"
              checkedChildren="Line"
              unCheckedChildren="Bar "
              checked={isLineGraph}
              onChange={setIsLineGraph}
            />
          </span>
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ paddingBottom: 5 }}>
        {isLoading ? (
          <Loader size="md" speed="slow" vertical center />
        ) : isLineGraph ? (
          <AverageResponseTimeGraph
            data={[
              {
                id: "Post Time",
                data: Array.isArray(data)
                  ? data
                      .filter((item: any) => item.post_time > 0)
                      .map((item: any) => ({
                        x: format(item.entry_date, "Y-MM-dd HH:mm"),
                        y: item.post_time,
                      }))
                  : [],
              },
            ]}
          />
        ) : (
          <AverageResponseTimeBar
            data={
              Array.isArray(data)
                ? data
                    .filter((item: any) => item.post_time > 0)
                    .map((item: any) => ({
                      date: format(item.entry_date, "Y-MM-dd HH:mm"),
                      post_time: item.post_time,
                    }))
                : []
            }
          />
        )}
      </Drawer.Body>
    </>
  );
};

export default AverageResponseTimeChart;

import React, { useState } from "react";
import { Checkbox, FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { percent, thousands } from "../../@Utils/Format";
import { format } from "date-fns";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";
import BuyerDailyBudgetsTable from "./BuyerDailyBudgetsTable";

const BuyerDailyBudgets: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [sortType, setSortType] = useState();
  const [sortColumn, setSortColumn] = useState();
  const categoryId = parseInt(Admediary.category || 0);
  const productId = parseInt(Admediary.product || 0);
  const buyerId = parseInt(Admediary.buyer || 0);
  const defaultActivity = 1;
  const [activity, setActivity] = useState(defaultActivity);
  const defaultGroupValue = "product";
  const [group, setGroup] = useState(defaultGroupValue);
  const [checked, setChecked] = useState(true);

  const groupByList = [
    { group: "product", label: "Product" },
    { group: "buyer", label: "Buyer" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: categoryId,
    product_id: productId,
    buyer_id: buyerId,
    activity,
    group: group,
    exclude: checked ? 1 : 0,
  };

  const [data, isLoading] = useAdmediaryApiManual("buyer_daily_budget", params);

  const formatData = (list: any) => {
    list.cap = !isNaN(list.cap) ? list.cap : 0;
    const acceptPercent =
      list.total_count !== 0
        ? (list.accepted / (list.total_count - list.filtered)) * 100
        : 0;
    const filteredPercent =
      list.total_count !== 0 ? (list.filtered / list.total_count) * 100 : 0;
    list.accepted = isNaN(parseFloat(list.accepted))
      ? 0
      : parseFloat(list.accepted);
    let remaining_cap = list.cap - list.accepted;
    remaining_cap = remaining_cap > 0 ? remaining_cap : 0;
    const percentRemainingHelper =
      !isNaN(list.accepted) && !isNaN(list.cap) ? list.cap - list.accepted : 0;
    return {
      ...list,
      ...{
        filter_percentage: isNaN(filteredPercent) ? 0 : filteredPercent,
        accept_percentage: isNaN(acceptPercent) ? 0 : acceptPercent,
        remaining_cap: remaining_cap > 0 ? remaining_cap : 0,
        percent_remaining:
          !isNaN(list.cap) &&
          list.cap !== 0 &&
          !isNaN(remaining_cap) &&
          remaining_cap !== 0
            ? (remaining_cap / list.cap) * 100
            : 0,
      },
    };
  };

  function addCalculatedValuesRecursively(list: any) {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = addCalculatedValuesRecursively(row.children);
      }

      return formatData(row);
    });
  }

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const getSummary = (data: any) => {
    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = [
      "total_count",
      "accepted",
      "cap",
      "remaining_cap",
      "percent_remaining",
      "filtered",
      "rejected",
      "filter_percentage",
      "accept_percentage",
    ];
    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = data.map((item: { [index: string]: string }): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    return formatData(summary);
  };

  const getExportData = (data: any) => {
    const flattenTree = (list: any, parent?: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        parent = parent || item;

        const current = {
          ...{
            buyer_name: item.buyer_name || parent.buyer_name,
            product_id: item.product_id || parent.product_id,
            product_description:
              item.product_description || parent.product_description,
            contract_id: item.contract_id || parent.contract_id,
            contract_name: item.contract_name || parent.contract_name,
            priority: item.priority || parent.priority,
            date: item.date || parent.date,
          },
          ...item,
        };

        if (current.children && Array.isArray(current.children)) {
          result.push(...flattenTree(current.children, current));
        } else {
          result.push(current);
        }
      });

      return result;
    };

    return flattenTree(data.map((item: any) => item)).map((item: any) => {
      return {
        Date: item.date,
        "Product ID": item.product_id,
        "Product Name": item.product_description,
        "Buyer Name": item.buyer_name,
        "Contract Name": item.contract_name,
        "Contract ID": item.contract_id,
        Priority: item.priority,
        "Total Count": thousands(item.total_count),
        Accepted: thousands(item.accepted),
        Cap: item.cap,
        "Remaining Cap": thousands(item.remaining_cap),
        "% to Cap": percent(item.percent_remaining),
        Filtered: thousands(item.filtered),
        Rejected: thousands(item.rejected),
        "Filter %": percent(item.filter_percentage),
        "Accept %": percent(item.accept_percentage),
      };
    });
  };

  const exportFileNameParts = [
    "buyer_contract_daily_budget",
    format(new Date(Admediary.start), "MMddyy"),
    format(new Date(Admediary.end), "MMddyy"),
    `CAT-${categoryId}`,
    `PRODUCT-${productId}`,
    `BUYER-${buyerId}`,
  ];
  const exportFileName = exportFileNameParts.join("_") + ".csv";

  const treeData = Array.isArray(data)
    ? addCalculatedValuesRecursively(data)
    : [];
  const exportData = Array.isArray(treeData) ? getExportData(treeData) : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);

  const handleChecked = (): void => {
    setChecked(!checked);
  };

  return (
    <>
      <Title title="Buyer Contract Daily Cap" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Sort By"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ float: "left", marginRight: 15 }}
          />
          <Checkbox
            onChange={handleChecked}
            checked={checked}
            style={{ float: "left", marginRight: 15 }}
          >
            CPL Only
          </Checkbox>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            size="sm"
            placement="right"
            icon={<Icon icon="file-download" />}
            onClick={() => exportCsv(exportFileName, exportData)}
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerDailyBudgetsTable
        data={!isLoading && data !== null ? treeData : []}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        group={group}
      />
    </>
  );
};

export default BuyerDailyBudgets;

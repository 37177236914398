import React from "react";
import {
  Loader,
  Schema,
  Form,
  DatePicker,
  InputNumber,
  CheckboxGroup,
  Checkbox,
} from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";

const { StringType, NumberType, DateType, ArrayType } = Schema.Types;

const model = Schema.Model({
  contract_id: NumberType().isRequired("Contract ID is required."), // Hidden
  day_of_week: ArrayType().isRequired("Select Day of Week please."),
  start_time: DateType().isRequired("Start Time is required."),
  end_time: DateType().isRequired("End Time is required."),
  cap: NumberType(),
  type: StringType().isRequired("Select Type please."),
  rate: NumberType(),
});

type BuyerContractSchedule24x7FormType = {
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractSchedule24x7Form: React.FC<BuyerContractSchedule24x7FormType> = ({
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading] = React.useState(false);

  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);

  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);

  const defaultFormData = {
    contract_id: contractId,
    day_of_week: ["mo", "tu", "we", "th", "fr", "sa", "su"], // All days
    start_time: startDate,
    end_time: endDate,
    cap: 0,
  };
  const [formValue, setFormValue] = React.useState(defaultFormData);
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label=""
        name="day_of_week"
        placeholder="Select Day of Week"
        accepter={CheckboxGroup}
      >
        <Checkbox value="mo">Monday</Checkbox>
        <Checkbox value="tu">Tuesday</Checkbox>
        <Checkbox value="we">Wednesday</Checkbox>
        <Checkbox value="th">Thursday</Checkbox>
        <Checkbox value="fr">Friday</Checkbox>
        <Checkbox value="sa">Saturday</Checkbox>
        <Checkbox value="su">Sunday</Checkbox>
      </Field>
      <Field
        label="Start Time"
        name="start_time"
        accepter={DatePicker}
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
      <Field
        label="End Time"
        name="end_time"
        accepter={DatePicker}
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0 && minute !== 59}
        cleanable={false}
      />
      <Field
        label="Cap"
        name="cap"
        accepter={InputNumber}
        defaultValue={0}
        min={0}
      />
      <Field
        label="Rate"
        name="rate"
        accepter={InputNumber}
        defaultValue={0.0}
        step={0.0001}
      />
      <Field
        label="Type"
        name="type"
        placeholder="Select Type"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "e", label: "Exclusive" },
          { value: "h", label: "Hybrid" },
          { value: "m", label: "Multisell" },
        ]}
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractSchedule24x7Form;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Modal, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import { FormInstance } from "rsuite/lib/Form/Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerContractEmailForm from "./BuyerContractEmailForm";
import BuyerContractEmailTemplateList from "./BuyerContractEmailTemplateList";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractEmailListType = {
  emails?: any;
  height?: number;
  autoHeight?: boolean;
  onChange: (data: any) => void;
};

const BuyerContractEmailList: React.FC<BuyerContractEmailListType> = ({
  emails = [],
  height = 650,
  autoHeight = true,
  onChange = (data: any) => {},
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const initItem = { id: 0, email: "" };
  const [formValue, setFormValue] = React.useState(initItem);
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    if (formValue.id === 0) {
      emails.push(formValue);
    } else {
      const index = emails.findIndex((item: any) => item.id === formValue.id);
      emails[index].email = formValue.email;
    }

    onChange(emails.map((item: any) => item.email));

    handleEditModalClose();
  };

  const removeItem = (id: number) => {
    emails = emails.filter((item: any) => item.id !== id);

    onChange(emails.map((item: any) => item.email));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem(initItem);

    handleEditModalOpen();
  };

  const [customFieldsModalOpen, setCustomFieldsModalOpen] = React.useState(
    false
  );
  const handleCustomFieldsModalOpen = () => setCustomFieldsModalOpen(true);
  const handleCustomFieldsModalClose = () => setCustomFieldsModalOpen(false);

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={24}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Email
          </IconButton>
          <IconButton
            onClick={handleCustomFieldsModalOpen}
            icon={<Icon icon="list" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
          >
            Email Templates
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        data={sortData(Array.isArray(emails) ? emails : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {Admediary.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}

        <Column width={250} sortable resizable>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Email?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.email !== "" ? "Edit" : "Add") +
          " Email"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <BuyerContractEmailForm
          id={selectedItem ? selectedItem.id : 0}
          email={selectedItem ? selectedItem.email : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>

      <Modal
        show={customFieldsModalOpen}
        onHide={handleCustomFieldsModalClose}
        size={"lg"}
      >
        <Modal.Header>
          <Modal.Title>Email Templates Administration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BuyerContractEmailTemplateList height={350} autoHeight={false} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyerContractEmailList;

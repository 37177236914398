import axios, { AxiosRequestConfig } from "axios";
import { format, parseISO } from "date-fns";
import Config from "../@Config/Admediary";

const API = axios.create({});

export enum ApiOperations {
  affiliates = "AFFILIATES",
  affiliate_tree_report = "AFFILIATE_TREE_REPORT",
  affiliate_sub_id = "AFFILIATE_SUB_ID",
  campaigns = "CAMPAIGNS",
  campaign = "CAMPAIGN",
  affiliate_documents = "AFFILIATE_DOCUMENTS",
  affiliate_document_upload = "AFFILIATE_DOCUMENT_UPLOAD",
  affiliate_document_download = "AFFILIATE_DOCUMENT_DOWNLOAD",
  affiliate_document_remove = "AFFILIATE_DOCUMENT_REMOVE",
  campaign_update = "CAMPAIGN_UPDATE",
  common_affiliates = "COMMON_AFFILIATES",
  common_affiliate = "COMMON_AFFILIATE",
  common_domains = "COMMON_DOMAINS",
  common_domain = "COMMON_DOMAIN",
  common_domain_update = "COMMON_DOMAIN_UPDATE",
  common_domain_renew = "COMMON_DOMAIN_RENEW",
  domain_registrars = "DOMAIN_REGISTRARS",
  products = "PRODUCTS",
  categories = "CATEGORIES",
  clicks = "CLICKS",
  leads_purchased = "LEADS_PURCHASED",
  buyer_contract_notifications = "BUYER_CONTRACT_NOTIFICATIONS",
  buyer_transactions = "BUYER_CONTRACTS",
  affiliate_notifications = "AFFILIATE_NOTIFICATIONS",
  buyer_contract_budgets = "BUYER_CONTRACT_BUDGETS",
  buyer_contract_budgets_by_contract = "BUYER_CONTRACT_BUDGETS_BY_CONTRACT",
  buyer_contract_budget = "BUYER_CONTRACT_BUDGET",
  buyer_contract_budget_update = "BUYER_CONTRACT_BUDGET_UPDATE",
  buyer_contract_budget_remove = "BUYER_CONTRACT_BUDGET_REMOVE",
  buyer_contract_caps = "BUYER_CONTRACT_CAPS",
  buyer_contract_cap = "BUYER_CONTRACT_CAP",
  buyer_contract_cap_update = "BUYER_CONTRACT_CAP_UPDATE",
  buyer_contract_cap_remove = "BUYER_CONTRACT_CAP_REMOVE",
  buyer_contract_custom_fields = "BUYER_CONTRACT_CUSTOM_FIELDS",
  buyer_contract_custom_field = "BUYER_CONTRACT_CUSTOM_FIELD",
  buyer_contract_custom_field_update = "BUYER_CONTRACT_CUSTOM_FIELD_UPDATE",
  buyer_contract_custom_field_remove = "BUYER_CONTRACT_CUSTOM_FIELD_REMOVE",
  buyer_contract_email_templates = "BUYER_CONTRACT_CUSTOM_TEMPLATES",
  buyer_contract_email_template = "BUYER_CONTRACT_CUSTOM_TEMPLATE",
  buyer_contract_email_template_update = "BUYER_CONTRACT_CUSTOM_TEMPLATE_UPDATE",
  buyer_contract_email_template_remove = "BUYER_CONTRACT_CUSTOM_TEMPLATE_REMOVE",
  buyer_contract_fields = "BUYER_CONTRACT_FIELDS",
  buyer_contract_field = "BUYER_CONTRACT_FIELD",
  buyer_contract_filters = "BUYER_CONTRACT_FILTERS",
  buyer_contract_filter = "BUYER_CONTRACT_FILTER",
  buyer_contract_field_update = "BUYER_CONTRACT_FIELD_UPDATE",
  buyer_contract_field_remove = "BUYER_CONTRACT_FIELD_REMOVE",
  buyer_contract_field_maps = "BUYER_CONTRACT_FIELD_MAPS",
  buyer_contract_field_map = "BUYER_CONTRACT_FIELD_MAP",
  buyer_contract_field_map_update = "BUYER_CONTRACT_FIELD_MAP_UPDATE",
  buyer_contract_field_map_remove = "BUYER_CONTRACT_FIELD_MAP_REMOVE",
  buyer_contract_filter_update = "BUYER_CONTRACT_FILTER_UPDATE",
  buyer_contract_filter_maps = "BUYER_CONTRACT_FILTER_MAPS",
  buyer_contract_filter_map = "BUYER_CONTRACT_FILTER_MAP",
  buyer_contract_filter_map_update = "BUYER_CONTRACT_FILTER_MAP_UPDATE",
  buyer_contract_filter_map_remove = "BUYER_CONTRACT_FILTER_MAP_REMOVE",
  buyer_contract_performance = "BUYER_CONTRACT_PERFORMANCE",
  buyer_contract_post_fields = "BUYER_CONTRACT_POST_FIELDS",
  buyer_contract_post_headers = "BUYER_CONTRACT_POST_HEADERS",
  buyer_contract_post_header = "BUYER_CONTRACT_POST_HEADER",
  buyer_contract_post_header_update = "BUYER_CONTRACT_POST_HEADER_UPDATE",
  buyer_contract_post_header_remove = "BUYER_CONTRACT_POST_HEADER_REMOVE",
  buyer_contract_post_interpret_as_list = "BUYER_CONTRACT_POST_INTERPRET_AS_LIST",
  buyer_contract_post_responses = "BUYER_CONTRACT_POST_RESPONSES",
  buyer_contract_post_response = "BUYER_CONTRACT_POST_RESPONSE",
  buyer_contract_post_response_update = "BUYER_CONTRACT_POST_RESPONSE_UPDATE",
  buyer_contract_post_response_remove = "BUYER_CONTRACT_POST_RESPONSE_REMOVE",
  buyer_contract_rates = "BUYER_CONTRACT_RATES",
  buyer_contract_rate = "BUYER_CONTRACT_RATE",
  buyer_contract_rate_update = "BUYER_CONTRACT_RATE_UPDATE",
  buyer_contract_rate_remove = "BUYER_CONTRACT_RATE_REMOVE",
  buyer_contract_schedules = "BUYER_CONTRACT_SCHEDULES",
  buyer_contract_schedule = "BUYER_CONTRACT_SCHEDULES",
  buyer_contract_schedule_update = "BUYER_CONTRACT_SCHEDULE_UPDATE",
  buyer_contract_schedule_remove = "BUYER_CONTRACT_SCHEDULE_REMOVE",
  buyer_contract_schedule24x7 = "BUYER_CONTRACT_SCHEDULE24x7",
  buyer_contract_sources = "BUYER_CONTRACT_SOURCES",
  buyer_contract_source = "BUYER_CONTRACT_SOURCE",
  buyer_contract_source_import = "BUYER_CONTRACT_SOURCE_IMPORT",
  buyer_contract_source_update = "BUYER_CONTRACT_SOURCE_UPDATE",
  buyer_contract_source_remove = "BUYER_CONTRACT_SOURCE_REMOVE",
  buyers = "BUYERS",
  buyer_tree_report = "BUYER_TREE_REPORT",
  buyer_contracts = "BUYER_CONTRACTS",
  buyer_contract = "BUYER_CONTRACT",
  buyer_contract_update = "BUYER_CONTRACT_UPDATE",
  buyer_contract_remove = "BUYER_CONTRACT_REMOVE",
  buyer_hourly_report = "BUYER_HOURLY_REPORT",
  buyer_manage = "BUYER_MANAGE",
  buyer_select_api = "BUYER_SELECT_API",
  buyer_update = "BUYER_UPDATE",
  buyer_presentation_filter_select = "BUYER_PRESENTATION_FILTER_SELECT",
  buyer_presentation_filter_update = "BUYER_PRESENTATION_FILTER_UPDATE",
  leads_disposition = "LEADS_DISPOSITION",
  lead_detail_report = "LEAD_DETAIL_REPORT",
  buyer_contract_counts = "BUYER_CONTRACT_COUNTS",
  lead_dispositions = "LEAD_DISPOSITIONS",
  prepayments_list = "PREPAYMENTS_LIST",
  prepayment_insert = "PREPAYMENTS_INSERT",
  prepayment_calc = "PREPAYMENTS_CALC",
  filter_reasons = "FILTER_REASONS",
  response_time = "RESPONSE_TIME",
  response_time_chart = "RESPONSE_TIME_CHART",
  average_response_time_chart = "AVERAGE_RESPONSE_TIME_CHART",
  response_time_tree = "RESPONSE_TIME_TREE",
  responses = "RESPONSES",
  site_roi_flow_tree = "SITE_ROI_FLOW_TREE",
  page_flow_step = "PAGE_FLOW_STEP",
  afid_subid = "AFID_SUBID",
  site_offer = "SITE_OFFER",
  offer_site = "OFFER_SITE",
  load_products = "LOAD_PRODUCTS",
  load_affiliates = "LOAD_AFFILIATES",
  search = "SEARCH",
  leads_post_summary = "LEADS_POST_SUMMARY",
  view_history = "VIEW_HISTORY",
  view_lead = "VIEW_LEAD",
  view_transactions = "VIEW_TRANSACTIONS",
  products_buyhold_tree = "PRODUCTS_BUYHOLD_TREE",
  buyhold_leads = "BUYHOLD_LEADS",
  lead_categories = "LEAD_CATEGORIES",
  post_lead_detail_report = "POST_LEAD_DETAIL_REPORT",
  performance = "PERFORMANCE",
  sms_delivery = "SMS_DELIVERY",
  filtered_lead_detail_report = "FILTERED_LEAD_DETAIL_REPORT",
  products_routing_contracts = "PRODUCTS_ROUTING_CONTRACTS",
  products_routing_ping_trees = "PRODUCTS_ROUTING_PING_TREES",
  update_ping_tree = "UPDATE_PING_TREE",
  ping_tree_campaign_select = "PING_TREE_CAMPAIGN_SELECT",
  ping_tree_campaign_update = "PING_TREE_CAMPAIGN_UPDATE",
  ping_campaign_rate_update = "PING_CAMPAIGN_RATE_UPDATE",
  ping_tree_campaign_remove = "PING_TREE_CAMPAIGN_REMOVE",
  ping_tree_routing_set_select = "PING_TREE_ROUTING_SET_SELECT",
  ping_tree_routing_set_update = "PING_TREE_ROUTING_SET_UPDATE",
  ping_tree_routing_set_remove = "PING_TREE_ROUTING_SET_REMOVE",
  buyer_lead_update = "BUYER_LEAD_UPDATE",
  refresh_token = "REFRESH_TOKEN",
  routing_set_schedule_select = "ROUTING_SET_SCHEDULE_SELECT",
  routing_set_binding_select = "ROUTING_SET_BINDING_SELECT",
  routing_set_binding_update = "ROUTING_SET_BINDING_UPDATE",
  routing_set_binding_remove = "ROUTING_SET_BINDING_REMOVE",
  routing_set_schedule_remove = "ROUTING_SET_SCHEDULE_REMOVE",
  routing_set_schedule_update = "ROUTING_SET_SCHEDULE_UPDATE",
  ping_trees_routing_schedule24x7 = "PING_TREES_ROUTING_SCHEDULE24x7",
  data_validation = "DATA_VALIDATION",
  outgoing_hourly_volume = "OUTGOING_HOURLY_VOLUME",
  outgoing_volumes = "OUTGOING_VOLUMES",
  outgoing_source_urls = "OUTGOING_SOURCE_URLS",
  report_incoming_volume_select = "REPORT_INCOMING_VOLUME_SELECT",
  recent_records = "RECENT_RECORDS",
  record_detail = "RECORD_DETAIL",
  data_hygiene = "DATA_HYGIENE",
  source_path = "SOURCE_PATH",
  source_urls = "SOURCE_URLS",
  hourly_volume = "HOURLY_VOLUME",
  unique_records = "UNIQUE_RECORDS",
  edit_source = "EDIT_SOURCE",
  all_clients = "ALL_CLIENTS",
  category_list = "CATEGORY_LIST",
  data_sources = "DATA_SOURCES",
  data_source_required_fields = "DATA_SOURCE_REQUIRED_FIELDS",
  data_source_update = "DATA_SOURCE_UPDATE",
  data_active_sources = "DATA_ACTIVE_SOURCES",
  product_sites = "PRODUCT_SITES",
  pixel_criteria_groups = "PIXEL_CRITERIA_GROUPS",
  data_service = "DATA_SERVICE",
  data_service_update = "DATA_SERVICE_UPDATE",
  sms_campaign_select = "SMS_CAMPAIGN_SELECT",
  phone_numbers = "PHONE_NUMBERS",
  sms_campaign_creative_select = "SMS_CAMPAIGN_CREATIVE_SELECT",
  sms_campaign_update = "SMS_CAMPAIGN_UPDATE",
  sms_campaign_creative_update = "SMS_CAMPAIGN_CREATIVE_UPDATE",
  data_source_remove = "DATA_SOURCE_REMOVE",
  panda_doc_status_lead_select = "panda_doc_status_lead_select",
  panda_affiliate_select = "PANDA_AFFILIATE_SELECT",
  gp_report_select = "GP_REPORT_SELECT",
  service_queue_duration_results = "service_queue_duration_results",
  report_affiliate_offers_select = "report_affiliate_offers_select",
  affiliate_offer_select_distinct = "affiliate_offer_select_distinct",
  report_affiliate_offers_select_by_interval = "report_affiliate_offers_select_by_interval",
  report_affiliate_offers_select_by_affiliate = "report_affiliate_offers_select_by_affiliate",
  affiliate_select_distinct_by_offer = "affiliate_select_distinct_by_offer",
  buyer_budget_report = "buyer_budget_report",
  panda_epc_select = "panda_epc_select",
  pixel_percentages = "pixel_percentages",
  pixel_percentage_update = "pixel_percentage_update",
  pixel_percentage_remove = "pixel_percentage_remove",
  buyer_daily_budget = "buyer_daily_budget",
  routing_set_sources = "routing_set_sources",
  routing_set_source = "routing_set_source",
  routing_set_source_update = "routing_set_source_update",
  routing_set_source_remove = "routing_set_source_remove",
  ping_post_mapping_update = "ping_post_mapping_update",
  buyer_contract_clone = "buyer_contract_clone",
  data_service_list = "data_service_list",
  data_service_delete = "data_service_delete",
  record_data_service_test = "record_data_service_test",
  service_test_record_data = "service_test_record_data",
  data_service_test_post = "data_service_test_post",
  phone_numbers_select = "PHONE_NUMBERS_SELECT",
  phone_number_update = "PHONE_NUMBER_UPDATE",
  phone_number_remove = "PHONE_NUMBER_REMOVE",
  voice_activity_tree = "voice_activity_tree",
  voice_campaign_select = "voice_campaign_select",
  voice_campaign_update = "voice_campaign_update",
  voice_campaign_log_event = "voice_campaign_log_event",
  product_select = "product_select",
  product_update = "product_update",
  disposition_lookup_report = "disposition_lookup_report",
  product_category_fields = "product_category_fields",
  product_category_update = "product_category_update",
  affiliate_managers = "affiliate_managers",
  buyer_account_managers = "buyer_account_managers",
  product_cap_select = "product_cap_select",
  product_cap_update = "product_cap_update",
  products_considered_accepted = "products_considered_accepted",
}

export type ApiParameters = {
  op?: keyof typeof ApiOperations;
  start_date?: Date;
  end_date?: Date;
  prepayment_date?: Date;
  prepayment_amount?: number;
  category_id?: number;
  buyer_name?: string;
  monitor?: number;
  product_id?: number;
  buyer_id?: number;
  phone?: any;
  email?: any;
  email_days?: any;
  lead_instance_id?: number;
  contract_id?: number;
  schedule_id?: number;
  affiliate_id?: number;
  group?: string;
  day_grouping?: number;
  tag?: string;
  disposition?: string;
  prepayment_type?: string;
  prepayment_note?: string;
  sub_id_1?: string;
  tree_id?: number;
  binding_id?: number;
  ping_campaign_id?: number;
  routing_set_id?: number;
  sub_id_2?: string;
  detail_id?: number;
  treeId?: number;
  tag_name?: string;
  active?: any;
  enable_filters?: number;
  is_esign?: number;
  is_retainer?: number;
  is_revshare?: number;
  reconcile_cake_conversions?: number;
  parameters?: any;
  source_id?: number;
  budget_id?: number;
  cap_id?: number;
  rate_id?: number;
  mapping_id?: number;
  client_id?: number;
  data_source_id?: number;
  data_source_name?: string;
  description?: string;
  data_source_key?: string;
  rev_share_from?: any;
  rev_share_percent?: any;
  window?: number;
  list_by_category?: number;
  filter_id?: number;
  field_id?: number;
  map_id?: number;
  response_id?: number;
  header_id?: number;
  template_id?: number;
  document_id?: number;
  domain_id?: number;
  expiration_date?: string;
  source_contract_id?: number;
  destination_contract_id?: number;
  campaign_id?: number;
  service_id?: number;
  refresh?: boolean;
  sms_id_list?: any;
  percent_id?: number;
  include_sources?: number;
  post_contract_id?: number;
  ping_contract_id?: number;
  ping_minimum_price?: number;
  record_id?: string;
  post_data?: string;
  phone_id?: number;
  lid_list?: string;
  log_id?: number;
};

/**
 * Intercept request to catch auth issues
 */
API.interceptors.request.use((config) => {
  const accessToken: string | null = localStorage.getItem("auth.id_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

/**
 * Intercept responses to format data by field name
 */
API.interceptors.response.use((response) => {
  if (typeof response.data !== "object") {
    throw response.data;
  }

  if (response.data.response_code === 0) {
    throw response.data.response_error;
  }

  // TODO Add formatting for tree data
  const isTreeData = [
    "buyer_tree_report",
    "affiliate_tree_report",
    "response_time_tree",
    "buyer_hourly_report",
    "performance",
  ].includes(response.config.params ? response.config.params.op : null);

  if (!isTreeData) {
    response.data.response_data.data = formatData(
      response.data.response_data.data
    );
  }

  return response.data.response_data;
});

/**
 * Format response data by key value
 *
 * @param {Object[]} data  Response data
 */
function formatData(data: Object[]) {
  if (!(data && data.length > 0)) {
    return data;
  }

  return data.map((row: any) => {
    const newRow: any = {};
    Object.keys(row).forEach((item) => {
      const value = row[item] || "";

      switch (item) {
        case "sms_id_list":
        case "sms_id_remove":
        case "buyer_id":
        case "contract_id":
        case "cap":
        case "filtered_leads":
        case "unclassified":
        case "waiting":
        case "filtered_entries":
        case "contracts_considered_count":
        case "buyers_considered_count":
        case "sold":
        case "no_buyer":
        case "afid":
        case "count":
        case "priority":
        case "clicks":
        case "category_id":
        case "accepted_percentage":
        case "allow":
        case "is_cap_amount":
        case "tree_id":
        case "routing_set_id":
        case "binding_id":
        case "ping_campaign_id":
        case "email_days":
        case "schedule_id":
        case "active":
        case "is_active":
        case "enable_filters":
        case "is_esign":
        case "is_retainer":
        case "is_revshare":
        case "reconcile_cake_conversions":
        case "template_id":
        case "cap_id":
        case "cap_interval":
        case "buyer_contract_cap_cap_interval":
        case "rate_id":
        case "mapping_id":
        case "field_id":
        case "is_get":
        case "response_id":
        case "header_id":
        case "document_id":
        case "domain_id":
        case "account_id":
        case "campaign_id":
        case "product_id":
        case "site_id":
        case "pixel_id":
        case "criteria_group_id":
        case "data_source_id":
        case "monitor":
        case "client_id":
          newRow[item] = parseInt(value);
          break;
        case "delivered":
        case "queued":
        case "sent":
        case "undelivered":
        case "accepted":
        case "filtered":
        case "rejected":
          newRow[item] = isNaN(parseInt(value)) ? 0 : parseInt(value);
          break;
        case "default_allow":
        case "ssl_certificate":
        case "renew":
        case "expired":
        case "parked":
        case "filter_id":
        case "one_of":
        case "server_side":
        case "rev_share":
        case "rev_share_percent":
        case "is_esign_campaign":
          newRow[item] = isNaN(parseInt(value)) ? null : parseInt(value);
          break;
        case "total_count":
        case "success_count":
        case "duplicate_count":
        case "failure_count":
        case "queued_count":
        case "transaction_count":
        case "service_id":
        case "feed_delay":
        case "resubmit_interval":
        case "ds_client_id":
        case "validate_data":
        case "affiliate_id":
          newRow[item] = isNaN(parseInt(value)) ? 0 : parseInt(value);
          break;
        case "revenue":
        case "prepayment_balance":
        case "price_received":
        case "price_owed":
          newRow[item] = parseFloat(value);
          break;
        case "contract_ids":
        case "buyer_ids":
          newRow[item] = value.replace(/,+$/, "").split(",");
          break;
        case "post_date":
        case "posted_date":
        case "last_accepted_date":
        case "prepay_date":
        case "notification_date":
          newRow[item] = parseISO(value);
          break;
        case "entry_date":
        case "transaction_date":
        case "last_post":
        case "processed_date":
        case "submit_date":
        case "registration_date":
        case "expiration_date":
          newRow[item] = value ? parseISO(value) : null;
          break;
        case "total_time":
        case "post_time":
        case "avg_total_time":
        case "avg_post_time":
        case "min_total_time":
        case "min_post_time":
        case "max_total_time":
        case "max_post_time":
        case "considered":
        case "presented":
        case "rate":
        case "earned":
        case "profit":
        case "lead_sold":
        case "leads_sold":
        case "prepayment_amount":
        case "conversions":
        case "impressions":
        case "submits":
        case "percent":
        case "average_duration":
        case "average_post":
        case "post_count":
          newRow[item] = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
          break;
        default:
          newRow[item] = value;
          break;
      }

      // TODO Rewrite renaming
      // Rename fields
      if (item === "afid") {
        newRow.affiliate_id = newRow.afid;
        delete newRow.afid;
      }

      if (item === "lead_sold") {
        newRow.leads_sold = newRow.lead_sold;
        delete newRow.lead_sold;
      }
    });
    return newRow;
  });
}

/**
 * Primary function for pulling in global settings and checking
 * the authentication state of the user.
 * @constructor
 */
export const Initialize = async () => {
  return await axios.all([
    Query("buyer_contracts"),
    Query("buyers"),
    Query("categories"),
    Query("products"),
    Query("affiliates"),
    Query("lead_categories"),
    Query("all_clients"),
    Query("phone_numbers"),
  ]);
};

/**
 * Abstract function for making GET requests to Admediary API
 *
 * @param op
 * @param options
 * @constructor
 */
export const Query = async (
  op: keyof typeof ApiOperations,
  options?: ApiParameters
) => {
  const dates: any = {};
  // Convert dates into proper format
  if (options && options.start_date && options.end_date) {
    dates["start_date"] = format(options.start_date, "yyyy-MM-dd 00:00:00");
    dates["end_date"] = format(options.end_date, "yyyy-MM-dd 23:59:59");
  }

  // Glue GET parameters together from options and converted values
  const params: any = { ...{ op }, ...options, ...dates };

  return API.get(getApiUrl(), { params });
};

/**
 * Wrapper function for making POST requests to Admediary API
 *
 * @param op
 * @param data
 * @param query
 * @param config
 * @constructor
 */
export const PostQuery = async (
  op: keyof typeof ApiOperations,
  data?: any,
  query?: any,
  config?: AxiosRequestConfig
) => {
  query = { ...{ op }, ...query };
  const url = getApiUrl() + "?" + toQuery(query);

  return API.post(url, data, config);
};

/**
 * Get base API URL
 * Determine if we're using the local HTTP proxy or direct API request
 * the local proxy will be a relative path with NodeJS
 *
 * @return string
 */
export const getApiUrl = () =>
  `${!Config.USE_PROXY ? Config.API_BASE : ""}/api/reports.php`;

const toQuery = (params: any) => {
  const parts = [];
  for (const i in params) {
    if (params.hasOwnProperty(i)) {
      parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i]));
    }
  }
  return parts.join("&");
};

import React, { useContext, useEffect } from "react";
import { Alert } from "rsuite";
import { auth, provider } from "../@Config/Firebase";
import { AdmediaryContext } from "./AdmediaryContext";

const INFO_DURATION: number = 2500;
const ALERT_DURATION: number = 5000;

/**
 * Constants for local storage keys
 */
const LOCAL_STORAGE_AUTH_KEYS = {
  NAME: "auth.name",
  UID: "auth.uid",
  ACCESS_TOKEN: "auth.access_token",
  ID_TOKEN: "auth.id_token",
  EMAIL: "auth.email",
  URL: "history.url",
};

interface FirebaseAuthProviderProps {
  children?: React.ReactNode;
}

type FirebaseAuthContextType = {
  isAuth: boolean;
  config: any;
  logout: any;
  signInPopup: any;
  url: any;
};

// Create React Context to keep track of current auth state
const FirebaseAuthContext = React.createContext<FirebaseAuthContextType>({
  isAuth: false,
  config: null,
  url: null,
  logout: () => {},
  signInPopup: () => {},
});

/**
 * AuthProvider has API check for authentication
 * @param props
 * @constructor
 */
const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = (props) => {
  const [AuthState, setAuthState] = React.useState({
    isAuth: false,
  });
  const [Config, setConfig] = React.useState({});
  const [URL, setURL] = React.useState("");
  const Admediary = useContext(AdmediaryContext);

  /**
   * Successful logins will store credentials in local storage. If values
   * can be found, restore them into the config and set the user as
   * authenticated.
   */
  useEffect(() => {
    let displayName = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.NAME);
    let email = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
    let uid = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.UID);
    let accessToken = localStorage.getItem(
      LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN
    );
    let idToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN);

    if (window.location.hash === "#redirecting") {
      Alert.info("We're verifying your session.. sit tight. ;)", INFO_DURATION);
    }

    if (!displayName || !uid || !accessToken || !idToken) {
      auth
        .signInWithPopup(provider)
        // .getRedirectResult()
        .then(async (result) => {
          // console.log("auth result", result);
          window.location.hash = "";
          /**
           * Confirm we have a redirect from Google Auth with proper credentials
           * and user object. In addition, it has to be confirmed that a user with
           * an @admediaryllc.com was authenticated. As a safety precaution, we'll
           * confirm that their email is flagged as verified.
           *
           * These checks are a precaution. The API will verify the tokens and
           * domain to ensure no sensitive information leaks.
           */
          if (!result.user || !result.credential) {
            Alert.warning(
              "Please Sign In to view the dashboard",
              ALERT_DURATION
            );
            return false;
          }
          // destructure result object for fields needed for verifying an employee
          let {
            user: { email, emailVerified, displayName, isAnonymous, uid },
          } = result;
          const credential: any = result.credential;

          // ensure we have necessary feilds, user is not anonymous, and verified email
          if (!email || !emailVerified || isAnonymous || !uid || !displayName) {
            Alert.error(
              "Something went wrong. Try refreshing?",
              ALERT_DURATION
            );
            return false;
          }

          // make sure an ADM emplyee is interacting with the dashboard
          const domain = email.includes("@") ? email.split("@")[1] : "";
          if (domain !== "admediaryllc.com") {
            Alert.error(
              "Dashboard is limited to Admediary, LLC employees",
              ALERT_DURATION
            );
            return false;
          }

          // make sure we have an accessToken
          if (!credential.accessToken) {
            Alert.error("Something went wrong. Try again?", ALERT_DURATION);
            return false;
          }

          try {
            const token: any = await auth.currentUser
              ?.getIdToken(true)
              .then((token) => token);

            if (token.length) {
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.NAME, displayName);
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL, email);
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.UID, uid);
              localStorage.setItem(
                LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN,
                credential.accessToken
              );
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);
              setConfig({
                displayName,
                email,
                uid,
                accessToken,
              });
              setAuthState({ isAuth: true });
              setURL(window.location.pathname);
            }
          } catch (error) {
            Alert.error(
              "Could not create an access token, Try again?",
              ALERT_DURATION
            );
            return false;
          }
        })
        .catch((e) => {
          console.log("auth error", e);
          Alert.error("Something went wrong. Try again?", ALERT_DURATION);
        });
    } else {
      setConfig({
        displayName,
        email,
        uid,
        accessToken,
      });
      setAuthState({ isAuth: true });
    }
  }, [setAuthState, setConfig]);

  /**
   * Helper function for resetting the session to log the user out
   */
  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.NAME);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.UID);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN);
    setAuthState({ isAuth: false });
  };

  const signInPopup = () => {
    let displayName = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.NAME);
    let email = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
    let uid = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.UID);
    let accessToken = localStorage.getItem(
      LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN
    );
    let idToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN);

    if (window.location.hash === "#redirecting") {
      Alert.info("We're verifying your session.. sit tight. ;)", INFO_DURATION);
    }

    if (!displayName || !uid || !accessToken || !idToken) {
      auth
        .signInWithPopup(provider)
        // .getRedirectResult()
        .then(async (result) => {
          // console.log("auth result", result);
          window.location.hash = "";
          /**
           * Confirm we have a redirect from Google Auth with proper credentials
           * and user object. In addition, it has to be confirmed that a user with
           * an @admediaryllc.com was authenticated. As a safety precaution, we'll
           * confirm that their email is flagged as verified.
           *
           * These checks are a precaution. The API will verify the tokens and
           * domain to ensure no sensitive information leaks.
           */
          if (!result.user || !result.credential) {
            Alert.warning(
              "Please Sign In to view the dashboard",
              ALERT_DURATION
            );
            return false;
          }
          // destructure result object for fields needed for verifying an employee
          let {
            user: { email, emailVerified, displayName, isAnonymous, uid },
          } = result;
          const credential: any = result.credential;

          // ensure we have necessary feilds, user is not anonymous, and verified email
          if (!email || !emailVerified || isAnonymous || !uid || !displayName) {
            Alert.error(
              "Something went wrong. Try refreshing?",
              ALERT_DURATION
            );
            return false;
          }

          // make sure an ADM emplyee is interacting with the dashboard
          const domain = email.includes("@") ? email.split("@")[1] : "";
          if (domain !== "admediaryllc.com") {
            Alert.error(
              "Dashboard is limited to Admediary, LLC employees",
              ALERT_DURATION
            );
            return false;
          }

          // make sure we have an accessToken
          if (!credential.accessToken) {
            Alert.error("Something went wrong. Try again?", ALERT_DURATION);
            return false;
          }

          try {
            const token: any = await auth.currentUser
              ?.getIdToken(true)
              .then((token) => token);

            if (token.length) {
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.NAME, displayName);
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL, email);
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.UID, uid);
              localStorage.setItem(
                LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN,
                credential.accessToken
              );
              localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);
              setConfig({
                displayName,
                email,
                uid,
                accessToken,
              });
              setAuthState({ isAuth: true });
              setURL(window.location.pathname);
            }
          } catch (error) {
            Alert.error(
              "Could not create an access token, Try again?",
              ALERT_DURATION
            );
            return false;
          }
        })
        .catch((e) => {
          console.log("auth error", e);
          Alert.error("Something went wrong. Try again?", ALERT_DURATION);
        });
    } else {
      setConfig({
        displayName,
        email,
        uid,
        accessToken,
      });
      setAuthState({ isAuth: true });
    }
  };

  // Return Higher Order Component of FirebaseAuthContext.Provider
  return (
    <FirebaseAuthContext.Provider
      value={{
        isAuth: AuthState.isAuth,
        url: URL,
        logout: logout,
        signInPopup: signInPopup,
        config: Config,
      }}
    >
      {props.children}
    </FirebaseAuthContext.Provider>
  );
};

const FirebaseAuthConsumer = FirebaseAuthContext.Consumer;

export {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  FirebaseAuthContext,
  LOCAL_STORAGE_AUTH_KEYS,
};

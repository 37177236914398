import React from "react";
import { Drawer, Table, IconButton, FlexboxGrid, Icon } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { parseISO, format } from "date-fns";
import { NumberCell, PercentCell } from "../Table";
import { exportCsv } from "../../@Utils/Export";
import Title from "../Title";

const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type FilterReason = {
  row_data: any;
};

const FilterReason: React.FC<FilterReason> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const buyer_id =
    row_data.buyer_id || getFieldValueOrParentValue(row_data, "buyer_id");
  const contract_id =
    row_data.contract_id || getFieldValueOrParentValue(row_data, "contract_id");
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id");
  const sid1 = row_data.sid1 || getFieldValueOrParentValue(row_data, "sid1");
  const sid2 = row_data.sid2 || getFieldValueOrParentValue(row_data, "sid2");
  const disposition = row_data.disposition || "";

  const params = {
    start_date,
    end_date,
    category_id: Admediary.category,
    product_id: Admediary.product,
    contract_id,
    buyer_id,
    affiliate_id,
    sid1,
    sid2,
    disposition,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Prepare data
   * @param list
   */
  const prepareData = (list: any) => {
    // Add calculated columns
    list.forEach((item: any) => {
      const filteredPercent =
        row_data.filtered !== 0 ? (item.count / row_data.filtered) * 100 : 0;
      const totalPercent =
        row_data.considered !== 0
          ? (item.count / row_data.considered) * 100
          : 0;

      item.filter_percent = filteredPercent > 100 ? 100 : filteredPercent;
      item.total_percent = totalPercent > 100 ? 100 : totalPercent;
    });

    return sort(list, sort_column, sort_type);
  };

  const [data, isLoading] = useAdmediaryApi("filter_reasons", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Title title="Filter Reasons" />
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `filter_reasons_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  data
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={Array.isArray(data) ? prepareData(data) : []}
          hover
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Response</HeaderCell>
            <Cell dataKey="response" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Count</HeaderCell>
            <NumberCell dataKey="count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>% of filtered</HeaderCell>
            <PercentCell dataKey="filter_percent" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>% of total</HeaderCell>
            <PercentCell dataKey="total_percent" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default FilterReason;

import React from "react";
import {
  Table,
  FlexboxGrid,
  IconButton,
  Icon,
  Input,
  InputGroup,
  Whisper,
  Popover,
} from "rsuite";

import { CheckCell, EditActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import { FormInstance } from "rsuite/lib/Form/Form";
import EditCampaignSms from "../Drawers/EditCampaignSms";

const { Column, HeaderCell, Cell } = Table;

type SmsReportDataTable = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  refreshData?: () => void;
};

const SmsReportDataTable: React.FC<SmsReportDataTable> = (props: any) => {
  const data = props.data;
  const isLoading = props.isLoading;
  const sortType = props.sortType;
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const admediaryContext = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const refreshData = props.refreshData;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sortColumn && sortType ? sort(list, sortColumn, sortType) : list
    );
  };

  const handleEditActionClick = (rowData: any) => {
    // Set selected content into drawer
    rowData.active === 1
      ? formContext.setActive(true)
      : formContext.setActive(false);
    formContext.setDescription(rowData.description);
    formContext.setPhone(rowData.phone_id);
    formContext.setFrom(rowData.sms_from);
    setFormValue({ ...formValue, ...{ messages: rowData.messages } });
    admediaryContext.openDrawer(
      <EditCampaignSms
        campaignId={rowData.campaign_id}
        formRef={mainFormRef}
        parentCallback={handleCallback}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  const handleAddAction = () => {
    formContext.setActive(false);
    formContext.setDescription("");
    formContext.setMessage("");
    formContext.setMessages([]);
    formContext.setPhone(0);
    admediaryContext.openDrawer(
      <EditCampaignSms
        campaignId={0}
        formRef={mainFormRef}
        parentCallback={handleCallback}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["description", "sms_from"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
      <FlexboxGrid.Item>
        <InputGroup
          style={{ width: 125, marginRight: 15, display: "inline-block" }}
        >
          <Input
            placeholder="Search"
            onChange={(v) => {
              debounceSearchTerm(v);
            }}
            size="sm"
          />
        </InputGroup>
        <IconButton
          onClick={handleAddAction}
          placement="left"
          size="sm"
          appearance="ghost"
          icon={<Icon icon="plus-circle" />}
          disabled={admediaryContext.userDisable}
        >
          Add Campaign
        </IconButton>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <Table
          isTree
          // height={650}
          autoHeight={true}
          headerHeight={65}
          data={filter(sortData(data), searchQuery)}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          <Column align={"center"} sortable resizable>
            <HeaderCell>Active</HeaderCell>
            <CheckCell dataKey="active" />
          </Column>
          <Column width={200} align={"center"} sortable resizable>
            <HeaderCell>Campaign ID</HeaderCell>
            <Cell dataKey="campaign_id" />
          </Column>
          <Column width={150} fixed sortable resizable>
            <HeaderCell>From</HeaderCell>
            <Cell dataKey="sms_from" />
          </Column>
          <Column width={450} fixed sortable resizable>
            <HeaderCell>Messages</HeaderCell>
            <CountsCell dataKey="message" />
          </Column>
          <Column width={300} fixed sortable resizable>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={150} align={"center"}>
            <HeaderCell>Action</HeaderCell>
            <EditActionCell editHandler={handleEditActionClick} />
          </Column>
        </Table>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const CountsCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  group,
  index,
  label,
  ...props
}: any) => {
  const triggerRef = React.createRef();
  const messages = rowData["messages"];
  return (
    <Cell {...props}>
      <Whisper
        triggerRef={triggerRef}
        controlId="control-id-focus"
        trigger={"click"}
        enterable={true}
        placement="bottomStart"
        speaker={<MenuPopover row={messages}>{rowData["message"]}</MenuPopover>}
      >
        <span>{rowData["message"]}</span>
      </Whisper>
    </Cell>
  );
};

const MenuPopover = ({ onSelect, row, group, label, ...rest }: any) => (
  <Popover {...rest} full>
    <List rows={row} />
  </Popover>
);

const List = ({ rows }: any) => {
  const tableData: any = [];

  rows.forEach(function (value: any, key: any) {
    tableData.push({
      probability: value.probability,
      creative_id: value.sms_creative_id,
      sms_message: value.sms_message,
    });
  });

  const tableRows = tableData.map((row: any, index: number) => (
    <tr key={index}>
      <td style={{ textAlign: "left", padding: 5 }}>{row.creative_id}</td>
      <td style={{ textAlign: "left", padding: 5 }}>{row.probability}</td>
      <td style={{ textAlign: "left", padding: 5 }}>{row.sms_message}</td>
    </tr>
  ));

  tableRows.unshift(
    <tr>
      <td style={{ textAlign: "left", padding: 5 }}>Creative ID</td>
      <td style={{ textAlign: "left", padding: 5 }}>Probability</td>
      <td style={{ textAlign: "left", padding: 5 }}>Sms Message</td>
    </tr>
  );

  return (
    <table>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

export default SmsReportDataTable;

import React from "react";
import { Col, Content, Grid, Row } from "rsuite";

import { AdmediaryContext } from "../@Context/AdmediaryContext";
import { FirebaseAuthContext } from "../@Context/FirebaseAuthContext";
// import useAdmediaryApi from "../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../@Hooks/useAdmediaryApiManual";
import { prepayment_user_auth } from "../@Config/Prepayment";
import Dashboard from "../@Components/Dashboard";
import PrepaymentStatCards from "../@Components/Visualization/PrepaymentStatCards";
import PrepaymentList from "../@Components/Prepayments/PrepaymentList";
import PrepaymentForm from "../@Components/Prepayments/PrepaymentForm";

/**
 * Prepayments Page
 *
 */
const Prepayments: React.FC = () => {
  const admediary_context = React.useContext(AdmediaryContext);
  const firebase_context = React.useContext(FirebaseAuthContext);
  const user_email = firebase_context.config.email;
  const summary: any = {
    leads_sold: 0,
    prepayment_amount: 0,
    prepayment_balance: 0,
    revenue: 0,
  };

  let summary_data_buyer: any = [];
  let summary_data_contract: any = [];
  let summary_data_date: any = [];

  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: admediary_context.category,
    product_id: admediary_context.product,
    affiliate_id: 0,
    buyer_id: admediary_context.buyer,
    group: "buyer",
    PrepaymentRefresh: admediary_context.PrepaymentRefresh,
  };

  const summaryData = (
    arr: any,
    field: string,
    group_field: string = "",
    group_value: string = ""
  ) => {
    let summed: number = 0;
    for (let i = 0; i < arr.length; i++) {
      if (group_field !== "" || group_value !== "") {
        if (arr[i][group_field] === group_value) {
          summed += parseFloat(arr[i][field]);
        }
      } else {
        summed += parseFloat(arr[i][field]);
      }
    }
    return summed;
  };

  const calcPrepaymentBalance = (
    arr: any,
    group_field: string = "",
    group_value: string = ""
  ) => {
    let data_buyer_contract: any = [];
    let dbc_keys: any = [];
    let balance: number = 0;
    // walk the data and create 2 arrays one with the key (buyer_id contract_id) and one with the value
    for (let i = 0; i < arr.length; i++) {
      if (group_field !== "" || group_value !== "") {
        if (arr[i][group_field] === group_value) {
          let key =
            arr[i].buyer_id.toString() + "_" + arr[i].contract_id.toString();
          data_buyer_contract[key] = parseFloat(arr[i].prepayment_balance);
          if (!dbc_keys.includes(key)) {
            dbc_keys.push(key);
          }
        }
      } else {
        let key =
          arr[i].buyer_id.toString() + "_" + arr[i].contract_id.toString();
        data_buyer_contract[key] = parseFloat(arr[i].prepayment_balance);
        if (!dbc_keys.includes(key)) {
          dbc_keys.push(key);
        }
      }
    }

    //need to sum the values
    for (let i = 0; i < dbc_keys.length; i++) {
      balance += data_buyer_contract[dbc_keys[i]];
    }

    return balance;
  };

  // const formatData = (list: any) => {
  //
  // return Object.keys(list).map((item: any) => {
  // return {
  // ...item,
  // ...{
  // prepay_date:
  // typeof item.prepay_date !== "undefined"
  // ? item.prepay_date.toString()
  // : "",
  // leads_sold: typeof item.leadsold !== "undefined" ? item.leads_sold.toLocaleString():0,
  // prepayment_amount: item.prepayment_amount !== "undefined" ? item.prepayment_amount
  // .toLocaleString("en-US", {
  // style: "currency",
  // currency: "USD",
  // })
  // :"$0.00",
  // prepayment_balance: item.prepayment_balance !== "undefined"?item.prepayment_balance.toLocaleString("en-US", { style: "currency",
  // currency: "USD",
  // }):"$0.00",
  // revenue: item.revenue.toLocaleString("en-US", {
  // style: "currency",
  // currency: "USD",
  // }),
  // },
  // };
  // });
  // };

  // fetch buyer_contract_counts
  let data: any = null;
  let isLoading: any = false;
  // [data, isLoading] = useAdmediaryApi("prepayments_list", params);
  [data, isLoading] = useAdmediaryApiManual("prepayments_list", params);

  if (!isLoading && data !== null) {
    //setting the summary data for the stat cards
    summary.leads_sold = summaryData(data, "leads_sold");
    summary.prepayment_amount = summaryData(data, "prepayment_amount");
    summary.revenue = summaryData(data, "revenue");
    summary.prepayment_balance = calcPrepaymentBalance(data);
    //build 3 arrays one for top level and one for the contract level and recreating the date level;
    for (let i = 0; i < data.length; i++) {
      let row = data[i];
      row.leads_sold =
        row.leads_sold === "" ||
        typeof row.leads_sold === "undefined" ||
        isNaN(row.leads_sold)
          ? 0
          : parseInt(row.leads_sold);
      row.revenue =
        row.revenue === "" ||
        typeof row.revenue === "undefined" ||
        isNaN(row.revenue)
          ? 0
          : parseFloat(row.revenue);
      row.prepayment_amount =
        row.prepayment_amount === "" ||
        typeof row.prepayment_amount === "undefined" ||
        isNaN(row.prepayment_amount)
          ? 0
          : parseFloat(row.prepayment_amount);
      row.prepayment_balance =
        row.prepayment_balance === "" ||
        typeof row.prepayment_balance === "undefined" ||
        isNaN(row.prepayment_balance)
          ? 0
          : parseFloat(row.prepayment_balance);
      summary_data_buyer[row.buyer_id] = {
        id: row.buyer_id,
        prepay_date: "",
        buyer_id: row.buyer_id,
        buyer_name: row.buyer_name,
        contract_id: 0,
        contract_name: "",
        leads_sold: summaryData(data, "leads_sold", "buyer_id", row.buyer_id),
        prepayment_amount: summaryData(
          data,
          "prepayment_amount",
          "buyer_id",
          row.buyer_id
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        revenue: summaryData(
          data,
          "revenue",
          "buyer_id",
          row.buyer_id
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),

        prepayment_balance: calcPrepaymentBalance(
          data,
          "buyer_id",
          row.buyer_id
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        children: [],
      };
      summary_data_contract[row.contract_id] = {
        id: row.buyer_id + "_" + row.contract_id,
        buyer_id: row.buyer_id,
        buyer_name: row.buyer_name,
        contract_id: row.contract_id,
        contract_name: row.contract_name,
        leads_sold: summaryData(
          data,
          "leads_sold",
          "contract_id",
          row.contract_id
        ),
        prepayment_amount: summaryData(
          data,
          "prepayment_amount",
          "contract_id",
          row.contract_id
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        prepayment_balance: calcPrepaymentBalance(
          data,
          "contract_id",
          row.contract_id
        ).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        children: [],
      };
      summary_data_date.push({
        id:
          row.buyer_id +
          "_" +
          row.contract_id +
          "_" +
          row.entry_date.toLocaleDateString(),
        prepay_date: row.entry_date.toLocaleDateString(),
        buyer_id: row.buyer_id,
        buyer_name: row.buyer_name,
        contract_id: row.contract_id,
        contract_name: row.contract_name,
        leads_sold: row.leads_sold,
        prepayment_amount: row.prepayment_amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        prepayment_balance: "",
        revenue: row.revenue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
        prepayment_type:
          typeof row.prepayment_type === "undefined" ? "" : row.prepayment_type,
        prepayment_note:
          typeof row.prepayment_note === "undefined" ? "" : row.prepayment_note,
      });
    }

    //clean up the extra elements
    summary_data_buyer = summary_data_buyer.filter((el: any) => {
      return el != null;
    });

    summary_data_contract = summary_data_contract.filter((el: any) => {
      return el != null;
    });
    summary_data_date = summary_data_date.filter((el: any) => {
      return el != null;
    });

    //combining the data into json tree format work our way from the bottom to the top level
    //walk the contract level then filter the date data by the contract_id
    for (let i = 0; i < summary_data_contract.length; i++) {
      summary_data_contract[i].children = summary_data_date.filter(
        (row: any) => {
          return row.contract_id === summary_data_contract[i].contract_id;
        }
      );
    }
    //walk the buyer level then filter the contract data by the buyer_id
    for (let i = 0; i < summary_data_buyer.length; i++) {
      summary_data_buyer[i].children = summary_data_contract.filter(
        (row: any) => {
          return row.buyer_id === summary_data_buyer[i].buyer_id;
        }
      );
    }
  }
  return (
    <Dashboard>
      <PrepaymentStatCards
        leads_sold={summary.leads_sold}
        prepayment_balance={summary.prepayment_balance}
        prepayment_amount={summary.prepayment_amount}
        revenue={summary.revenue}
      />
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            {prepayment_user_auth.includes(user_email) && (
              <Content style={{ height: "fit-content" }}>
                <PrepaymentForm />
              </Content>
            )}
            <Content style={{ height: "fit-content" }}>
              <PrepaymentList
                summary_data={data}
                data={summary_data_buyer}
                isLoading={isLoading}
              />
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default Prepayments;

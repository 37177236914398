import React from "react";
import { FlexboxGrid, Icon, IconButton, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";
import Title from "../Title";

type PrepaymentListProps = {
  data: any;
  summary_data: any;
  isLoading: boolean;
};

const PrepaymentList: React.FC<PrepaymentListProps> = (props: any) => {
  const sort_type: any = "";
  const sort_column: any = "";
  const summary_data = props.summary_data;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey: string = "id";
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */

  const formatData = (list: any) => {
    return list;
    // return list.map((item: any) => {
    // return {
    // ...item,
    // ...{
    // prepay_date:
    // typeof item.prepay_date !== "undefined"
    // ? item.prepay_date.toString()
    // : "",
    // leads_sold: item.leads_sold.toLocaleString(),
    // prepayment_amount: item.prepayment_amount.toLocaleString("en-US", {
    // style: "currency",
    // currency: "USD",
    // }),
    // prepayment_balance: item.prepayment_balance.toLocaleString("en-US", {
    // style: "currency",
    // currency: "USD",
    // }),
    // revenue: item.revenue.toLocaleString("en-US", {
    // style: "currency",
    // currency: "USD",
    // }),
    // },
    // };
    // });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  return (
    <>
      <Title title="Prepayments" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `prepayment_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                summary_data
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        isTree
        autoHeight
        loading={isLoading === true}
        data={Array.isArray(data) ? sortData(data) : []}
        rowKey={rowKey}
        hover
        onExpandChange={(isOpen: boolean, rowData: any) => {}}
        rowClassName="clickable-data striped-rows"
        renderTreeToggle={(icon, rowData) => {
          if (rowData.children && rowData.children.length === 0) {
            return <Icon icon="spinner" spin />;
          }
          return icon;
        }}
      >
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Buyer ID</Table.HeaderCell>
          <Table.Cell dataKey="buyer_id" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="buyer_name" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Contract Name</Table.HeaderCell>
          <Table.Cell dataKey="contract_name" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.Cell dataKey="prepay_date" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Leads Sold</Table.HeaderCell>
          <Table.Cell dataKey="leads_sold" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Revenue</Table.HeaderCell>
          <Table.Cell dataKey="revenue" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Prepayment Amount</Table.HeaderCell>
          <Table.Cell dataKey="prepayment_amount" />
        </Table.Column>
        <Table.Column width={150} sortable resizable>
          <Table.HeaderCell>Prepayment Balance</Table.HeaderCell>
          <Table.Cell dataKey="prepayment_balance" />
        </Table.Column>
        <Table.Column width={200} sortable resizable>
          <Table.HeaderCell>Prepayment Type</Table.HeaderCell>
          <Table.Cell dataKey="prepayment_type" />
        </Table.Column>
        <Table.Column width={200} sortable resizable>
          <Table.HeaderCell>Prepayment Notes/Comments</Table.HeaderCell>
          <Table.Cell dataKey="prepayment_note" />
        </Table.Column>
      </Table>
    </>
  );
};

export default PrepaymentList;

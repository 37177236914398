import React from "react";
import { Schema, Form } from "rsuite";
import Field from "../Field";

const { NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  id: NumberType(),
  email: StringType()
    .isRequired("Email is required.")
    .isEmail("Email is invalid."),
});

type BuyerContractEmailTemplateFormType = {
  id?: number;
  email?: string;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractEmailTemplateForm: React.FC<BuyerContractEmailTemplateFormType> = ({
  id = 0,
  email = "",
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [formValue, setFormValue] = React.useState({
    id,
    email,
  });
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Email" name="email" />
    </Form>
  );
};

export default BuyerContractEmailTemplateForm;

import React from "react";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Icon,
  Loader,
  Schema,
  FormGroup,
  ButtonToolbar,
  CheckboxGroup,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import _ from "lodash";
import SelectPicker from "rsuite/lib/SelectPicker";
import { Query } from "../../@Utils/AdmediaryApi";
import Messages from "../Campaigns/Messages";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  description: StringType()
    .addRule((value, data) => {
      return data.toString() !== "";
    }, "Enter number greater or less then 0")
    .isRequired("Description is required."),
  phone_id: NumberType()
    .addRule((value, data) => {
      return value !== 0;
    })
    .isRequired("Phone is required."),
});

type EditCampaignSmsType = {
  campaignId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  renderToolTipContent?: any;
  operation?: any;
  refreshData?: () => void;
};
const EditCampaignSms: React.FC<EditCampaignSmsType> = ({
  campaignId = 0,
  formRef = null,
  renderToolTipContent = () => {},
  parentCallback = () => {},
  refreshData = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const { config } = Admediary;
  const emptySms = {
    active: 0,
    phone_id: 0,
    description: "",
    messages: [],
  };

  const [formValue, setFormValue] = React.useState(emptySms);
  const [formError, setFormError] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [messages, setMessages] = React.useState();
  const [sumProbability, setSumProbability] = React.useState(0);
  const [removeSmsID, setRemoveSmsID] = React.useState([]);

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    campaign_id: campaignId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.campaign_id === 0) {
      setData({});
      return;
    }
    try {
      setIsLoading(true);
      const results: any = await Query("sms_campaign_creative_select", params);
      const data = {
        active: formContext.active ? [1] : [0],
        phone_id: formContext.phone,
        description: formContext.description,
        messages: results.data,
      };
      const probability = results.data
        .map((item: any) => item.probability)
        .reduce((prev: any, next: any) => parseInt(prev) + parseInt(next));
      setMessages(results.data);
      setSumProbability(probability);
      setData(data);
      //@ts-ignore
      setFormValue(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (data: any) => {
    setFormValue(data);
  };
  const handleBack = (data: any) => {
    setMessages(data);
    formValue.messages = data;
  };

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }
    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === "1" ? [1] : [];
    }
    return record;
  };

  const handleSubmit = () => {
    //@ts-ignore
    formValue.active = formContext.active ? 1 : 0;
    const data: any = _.cloneDeep(formValue);
    if (sumProbability !== 100 && sumProbability > 0) {
      Notification.warning({
        title: "Warning",
        duration: 6000,
        description: "Sum of probability for all creatives must be equal 100",
      });
      return;
    }

    const options = {
      active: data.active,
      campaign_id: campaignId,
      description: data.description,
      sms_id_list: data.messages,
      sms_id_remove: removeSmsID,
      phone_id: data.phone_id,
    };

    (async () => {
      try {
        await Query("sms_campaign_update", options);
        if (parentCallback instanceof Function) {
          parentCallback(data);
        }
      } catch (e) {
        setIsLoading(false);
      } finally {
        Admediary.closeDrawer();
        if (refreshData instanceof Function) {
          refreshData();
        }
        setIsLoading(false);
      }
    })();
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {campaignId === 0 ? "Add Campaign" : "Edit Campaign"}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <CheckboxGroup
            style={{ marginLeft: 120, marginBottom: 20 }}
            inline
            name="checkboxList"
          >
            <label>Active</label>
            <Checkbox
              onChange={() => {
                formContext.setActive(!formContext.active);
              }}
              name="active"
              checked={formContext.active ? true : false}
              disabled={Admediary.userDisable}
            />
          </CheckboxGroup>
          <Field
            label="Description"
            name="description"
            value={formContext.description}
            onChange={(v: any) => {
              formContext.setDescription(v);
            }}
            disabled={Admediary.userDisable}
          />
          <Field
            label="From"
            name="phone_id"
            accepter={SelectPicker}
            placeholder="Select Phone"
            defaultValue={""}
            value={formContext.phone}
            data={config.phones.map((phone: any) => ({
              phone_number: phone.phone_number,
              phone_id: phone.phone_id,
            }))}
            onChange={(v: any) => {
              formContext.setPhone(v);
              const phone = config.phones.filter(
                (phone: any) => v == phone.phone_id
              );
              formContext.setFrom(phone[0].phone_number);
              formContext.setPhone(v);
            }}
            labelKey="phone_number"
            valueKey="phone_id"
            disabled={Admediary.userDisable}
          />
          <Messages
            messages={messages}
            parentCallback={handleBack}
            renderToolTipContent={renderToolTipContent}
            setRemoveSmsID={setRemoveSmsID}
            removeSmsID={removeSmsID}
            setSumProbability={setSumProbability}
            sumProbability={sumProbability}
            campaignId={campaignId}
          />
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={Admediary.userDisable}
              >
                Submit
              </Button>
              <Button
                onClick={() => Admediary.closeDrawer()}
                appearance="default"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </Drawer.Body>
    </>
  );
};

export default EditCampaignSms;

import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  SelectPicker,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import DataSourcesList from "./DataSourcesList";
import Title from "../Title";
import EditSource from "../Drawers/EditSource";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      "Data Source ID": item.data_source_id,
      Active: item.active,
      Monitor: item.monitor,
      "Company Name": item.company_name,
      Description: item.description,
    };
  });
}

type ActiveSourcesProps = {
  height?: number;
};

const DataSources: React.FC<ActiveSourcesProps> = ({ height = 650 }) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const defaultActiveValue = 1;
  const defaultCategoryValue = 2;
  const [active, setActive] = React.useState(defaultActiveValue);
  const [category, setCategory] = React.useState(defaultCategoryValue);
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const activeList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const categoryList = [
    { value: 1, label: "Personal Loan Leads" },
    { value: 2, label: "Email List management" },
    { value: 3, label: "Solar Coreg" },
    { value: 4, label: "Debt" },
    { value: 5, label: "Legal" },
    { value: 6, label: "Investing" },
    { value: 7, label: "Solar" },
    { value: 8, label: "SMS/IVR" },
  ];

  const params = {
    category_id: category,
    active: active,
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApi("data_sources", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["company_name", "description"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  const handleAddDataSource = () => {
    formContext.setMonitor(false);
    formContext.setActive(false);
    formContext.setCategory(0);
    formContext.setClient(0);
    formContext.setRow(0);
    formContext.setCompany("");
    // formContext.revShare("");
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <EditSource dataSourceId={0} refreshData={refresh} />,
      "lg"
    );
  };
  return (
    <>
      <Title title="Data Source Administration" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Group By"
            defaultValue={defaultActiveValue}
            value={active}
            data={activeList}
            valueKey="value"
            labelKey="label"
            onChange={(v: any) => {
              setActive(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Group By"
            defaultValue={defaultCategoryValue}
            value={category}
            data={categoryList}
            valueKey="value"
            labelKey="label"
            onChange={(v: any) => {
              setCategory(v);
            }}
            style={{ marginRight: 15 }}
          />
          <IconButton
            onClick={handleAddDataSource}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={admediaryContext.userDisable}
          >
            Add Data Source
          </IconButton>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `data_active_contracts.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid colspan={19}></FlexboxGrid>
      <DataSourcesList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
    </>
  );
};

export default DataSources;

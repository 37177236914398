import React from "react";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Icon,
  Loader,
  Schema,
  FormGroup,
  ButtonToolbar,
  CheckboxGroup,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import _ from "lodash";
import { Query } from "../../@Utils/AdmediaryApi";
import { resourceLimits } from "worker_threads";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  description: StringType().isRequired("Description is required."),
  phone_number: StringType().isRequired("Number is Required"),
  voice: ArrayType(),
  sms: ArrayType(),
  mms: ArrayType(),
});

type EditCampaignNumberType = {
  data?: any;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: Function;
  refreshData?: () => void;
};
const EditCampaignNumber: React.FC<EditCampaignNumberType> = ({
  data = {
    phone_id: 0,
    active: 0,
    phone_number: "",
    description: "",
    voice: 0,
    sms: 0,
    mms: 0,
    entry_date: new Date(),
  },
  formRef = null,
  refreshData = () => {},
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const { config } = Admediary;

  const [formError, setFormError] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  const params = {
    phone_id: data.phone_id,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.phone_id === 0) {
      setData({
        phone_id: 0,
        active: 0,
        phone_number: "",
        description: "",
        voice: 0,
        sms: 0,
        mms: 0,
        entry_date: new Date(),
      });
      return;
    }
    try {
      setIsLoading(true);
      const results: any = await Query("phone_numbers_select", params);

      if (results?.data[0]) {
        const data = {
          active: results.data[0].active,
          phone_id: results.data[0].phone_id,
          phone_number: results.data[0].phone_number,
          description: results.data[0].description,
          voice: results.data[0].voice,
          sms: results.data[0].sms,
          mms: results.data[0].mms,
          entry_date: results.data[0].entry_date,
        };
        setData(data);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }
    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active == 1 ? [1] : [];
    }
    if (!Array.isArray(record.voice)) {
      record.voice = record.voice == 1 ? [1] : [];
    }
    if (!Array.isArray(record.sms)) {
      record.sms = record.sms == 1 ? [1] : [];
    }
    if (!Array.isArray(record.mms)) {
      record.mms = record.mms == 1 ? [1] : [];
    }
    record.entry_date = new Date(record.entry_date).toLocaleString();
    return record;
  };

  const [formValue, setFormValue] = React.useState(prepareFormData(data));
  const handleChange = (data: any) => {
    setFormValue(data);
  };
  const handleBack = (data: any) => {};

  const handleSubmit = () => {
    setIsSaving(true);
    const data: any = _.cloneDeep(formValue);

    const options = {
      active: data.active.length > 0 && data.active[0] == 1 ? 1 : 0,
      phone_id: data.phone_id,
      phone_number: data.phone_number,
      description: data.description,
      voice: data.voice.length > 0 && data.voice[0] == 1 ? 1 : 0,
      sms: data.sms.length > 0 && data.sms[0] == 1 ? 1 : 0,
      mms: data.mms.length > 0 && data.mms[0] == 1 ? 1 : 0,
    };
    (async () => {
      try {
        await Query("phone_number_update", options);
        if (parentCallback instanceof Function) {
          parentCallback(options);
        }
      } catch (e) {
        setIsSaving(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>({e})</>,
        });
      } finally {
        Admediary.closeDrawer();
        Notification.success({
          title: "Success",
          description: "Number has saved",
        });
        if (refreshData instanceof Function) {
          refreshData();
        }
        setIsSaving(false);
      }
    })();
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {data.phone_id === 0 ? "Add Number" : "Edit Number"}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <Field label="Active" name="active" accepter={CheckboxGroup}>
            <Checkbox value={1} />
          </Field>
          <Field label="Phone" name="phone_number" />
          <Field label="Description" name="description" />
          <Field label="Voice" name="voice" accepter={CheckboxGroup}>
            <Checkbox value={1} />
          </Field>
          <Field label="SMS" name="sms" accepter={CheckboxGroup}>
            <Checkbox value={1} />
          </Field>
          <Field label="MMS" name="mms" accepter={CheckboxGroup}>
            <Checkbox value={1} />
          </Field>
          {data.phone_id != 0 && (
            <Field label="Date Created" name="entry_date" disabled />
          )}
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={isSaving}
              >
                {!isSaving && <>Save</>}
                {isSaving && (
                  <Loader
                    size="sm"
                    style={{ padding: "0", margin: "0 0.5em" }}
                  />
                )}
              </Button>
              <Button
                onClick={() => Admediary.closeDrawer()}
                appearance="default"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Form>
      </Drawer.Body>
    </>
  );
};

export default EditCampaignNumber;

import React from "react";
import { Content, Loader } from "rsuite";

enum StatCardTypes {
  currency,
  number,
  string,
  percent,
}

type StatCardProps = {
  label: string;
  type: keyof typeof StatCardTypes;
  value: any;
};

const StatCard: React.FC<StatCardProps> = (props) => {
  const formatValue = (value: any): string => {
    if ([null, NaN, undefined].includes(value)) {
      value = 0;
    }
    switch (props.type) {
      case "currency":
        return parseFloat(value).toLocaleString(undefined, {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      case "number":
        return parseFloat(value).toLocaleString();
      case "percent":
        return `${parseFloat(value).toLocaleString()}%`;
      default:
        return value;
    }
  };
  return (
    <Content className="visualization-stat-card">
      <h2>{props.label}</h2>
      <h1>
        {props.value === undefined ? (
          <Loader size="sm" speed="slow" inverse />
        ) : (
          formatValue(props.value)
        )}
      </h1>
    </Content>
  );
};

export default StatCard;

import {
  Button,
  ControlLabel,
  FlexboxGrid,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Modal,
  Table,
} from "rsuite";
import React from "react";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

const GetPostDataField: React.FC<any> = ({
  name,
  label,
  gridData,
  dataSourceId,
  onAfterSave,
  error,
  modalTitle = "Edit Parameters",
  ...props
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [fields]: any = useAdmediaryApi("data_source_required_fields", {
    data_source_id: dataSourceId,
  });
  const DEFAULT_FIELDS = [
    "transmit_date",
    "transmit_date_101",
    "transmit_date_103",
    "access_key",
    "domain",
    "ak",
    "record_id",
  ].sort();
  const allFields =
    fields !== null && Array.isArray(fields)
      ? fields
          .map((field: any) => field.field_name)
          .concat(DEFAULT_FIELDS)
          .sort()
      : DEFAULT_FIELDS;

  const transformData = (data: any) =>
    data.map((item: any, index: number) => {
      const { name, value } = item;
      const clearValue = value.replaceAll(/%/g, "");
      const isMask = value.includes("%");
      const isIncludedInFields = allFields.includes(clearValue);
      const isNotMaskedValue = value && name && !(isMask && isIncludedInFields);

      return {
        ...item,
        id: index + 1,
        name: isNotMaskedValue ? `${name}=${value}` : name,
        value: isNotMaskedValue || clearValue === "" ? "" : `%%${clearValue}%%`,
      };
    });

  const [data, setData] = React.useState(transformData(gridData));
  const [show, setShow] = React.useState(false);
  const [showAfterDelay, setShowAfterDelay] = React.useState(false);
  const [shouldUpdateScroll, setShouldUpdateScroll] = React.useState(false);
  const intervalRef: any = React.useRef(null);

  // Set default values
  props.style = {
    ...{ width: 300 },
    ...props.style,
  };

  const handleButtonClick = () => {
    // Set initial data
    const items: any = transformData(gridData);

    setShow(true);
    setData(items);
    setShowAfterDelay(true);

    // It's a fix of not visible bottom rows to recalculate a table scroll
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setShowAfterDelay(false);
    }, 250);
  };

  const hideModal = () => {
    setShow(false);
  };

  const cancelModal = () => {
    hideModal();
  };

  const handleSaveClick = () => {
    setShow(false);

    if (typeof onAfterSave === "function") {
      onAfterSave(data);
    }
  };

  const handleAddItemClick = () => {
    const maxId = Math.max(...data.map((item: any) => item.id));
    const newItem = {
      id: maxId + 1,
      name: "",
      value: "",
    };

    // Scroll to top
    setShouldUpdateScroll(true);
    setData([...[newItem], ...data]);

    // Prevent scrolling when we update data in the table after scrolling
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setShouldUpdateScroll(false);
    }, 250);
  };

  const changeItem = (id: number, newData: any) => {
    const index = data.findIndex((item: any) => item.id === id);
    data[index] = {
      ...data[index],
      ...newData,
    };

    setData([...data]);
  };

  const handleSelectChange = (value: any, rowData: any) => {
    changeItem(rowData.id, { value });
  };

  const handleInputChange = (name: any, rowData: any) => {
    changeItem(rowData.id, { name });
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label}</ControlLabel>
      <InputGroup {...props} className="inner-button-field-group">
        <FormControl name={name} errorMessage={error} disabled />
        <InputGroup.Button
          onClick={handleButtonClick}
          disabled={Admediary.userDisable}
        >
          <Icon icon="search" />
        </InputGroup.Button>
      </InputGroup>

      <Modal size="sm" show={show} onHide={hideModal} overflow={true}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: 400 }}>
            <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
              <FlexboxGrid.Item colspan={19}>
                <IconButton
                  onClick={handleAddItemClick}
                  icon={<Icon icon="plus-circle" />}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{ marginRight: 15 }}
                >
                  Add Parameter
                </IconButton>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            {showAfterDelay ? (
              <Loader size="md" speed="slow" vertical center />
            ) : (
              <Table
                data={Array.isArray(data) ? data : []}
                shouldUpdateScroll={shouldUpdateScroll}
                autoHeight={true}
              >
                <Column width={250}>
                  <HeaderCell>Parameter Name</HeaderCell>
                  <InputCell dataKey="name" onChange={handleInputChange} />
                </Column>
                <Column width={250}>
                  <HeaderCell>Parameter Value</HeaderCell>
                  <SelectCell
                    dataKey="value"
                    data={allFields.map((item: any) => ({
                      value: `%%${item}%%`,
                      label: item,
                    }))}
                    onChange={handleSelectChange}
                  />
                </Column>
              </Table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={handleSaveClick}>
            Save
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </FormGroup>
  );
};

const InputCell = ({ rowData, dataKey, onChange, ...props }: any) => (
  <Cell {...props} dataKey={dataKey} className="editor-cell">
    <Input
      value={rowData[dataKey]}
      onChange={(value) => onChange(value, rowData)}
    />
  </Cell>
);

const SelectCell = ({ rowData, dataKey, data, onChange, ...props }: any) => (
  <Cell {...props} dataKey={dataKey} className="editor-cell">
    <SelectPicker
      style={{ width: "90%" }}
      size="md"
      placeholder=""
      defaultValue=""
      value={rowData[dataKey]}
      data={data}
      onChange={(value) => onChange(value, rowData)}
    />
  </Cell>
);

export default GetPostDataField;

import React from "react";
import "./App.less";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { FirebaseAuthProvider } from "./@Context/FirebaseAuthContext";
import { AdmediaryProvider } from "./@Context/AdmediaryContext";
import { FormProvider } from "./@Context/FormContext";

import BuyerDetails from "./@Pages/BuyerDetails";
import BuyerLeadList from "./@Pages/BuyerLeadList";
import AffiliateDetails from "./@Pages/AffiliateDetails";
import Affiliates from "./@Pages/Affiliates";
import CakeOffers from "./@Pages/CakeOffers";
import AffiliateTree from "./@Pages/AffiliateTree";
import CommonAffiliates from "./@Pages/CommonAffiliates";
import Roi from "./@Pages/Roi";
import RoiTree from "./@Pages/RoiTree";
import Buyers from "./@Pages/Buyers";
import BuyersTree from "./@Pages/BuyersTree";
import BuyersResponseTimes from "./@Pages/BuyersResponseTimes";
import Prepayments from "./@Pages/Prepayments";
import Debug from "./@Pages/Debug";
import Home from "./@Pages/Home";
import Leads from "./@Pages/Leads";
import Signin from "./@Pages/Signin";
import PostSummary from "./@Pages/PostSummary";
import PostLeadsPage from "./@Pages/PostLeads";
import PerformancePage from "./@Pages/Performance";
import SMSDelivery from "./@Pages/SMSDelivery";
import FilteredLeads from "./@Pages/Leads/FilteredLeads";
import LeadDetailReport from "./@Pages/Leads/LeadDetailReport";
import BuyHold from "./@Pages/Products/BuyHold";
import RecordSearch from "./@Pages/Products/RecordSearch";
import IncomingData from "./@Pages/Data/IncomingData";
import SmsCampaignsReport from "./@Pages/Campaigns/SmsReport";
import NumbersCampaignsReport from "./@Pages/Campaigns/NumbersReport";
import BuyerContracts from "./@Pages/BuyerContracts";
import BuyerContractFilters from "./@Pages/BuyerContractFilters";
import BuyerContractPerformance from "./@Pages/BuyerContractPerformance";
import PingTrees from "./@Pages/PingTrees";
import DataValidationPage from "./@Pages/Products/DataValidation";
import ActiveSources from "./@Pages/Data/ActiveSources";
import Domains from "./@Pages/Common/Domains";
import DataSources from "./@Pages/Data/DataSources";
import Campaigns from "./@Pages/Campaigns";
import OutgoingReport from "./@Pages/Data/OutgoingReport";
import EsignDocStatusPage from "./@Pages/EsignDocStatus";
import EsignDocAffiliatesPage from "./@Pages/EsignAffiliates";
import GrossProfitPage from "./@Pages/GrossProfits";
import BuyerBudget from "./@Pages/BuyerBudgets";
import BuyerDailyBudget from "./@Pages/BuyerDailyBudget";
import DataServicesReport from "./@Pages/Data/DataServicesReport";
import LeadDisposition from "./@Pages/LeadDisposition";

import ProtectedRoute from "./@Components/ProtectedRoute";
import ExternalReports from "./@Pages/ExternalReports";
import PixelPercentagePage from "./@Pages/PixelPercentage";
import ProductsPage from "./@Pages/ProductsPage";
import CallPrograms from "./@Pages/CallPrograms";
import VoiceCampaignPage from "./@Pages/Data/VoiceCampaign";
import ProductCategoriesPage from "./@Pages/ProductCategory";
import VoiceActivityPage from "./@Pages/Data/VoiceActivity";

function App() {
  return (
    <Router>
      <FirebaseAuthProvider>
        <AdmediaryProvider>
          <FormProvider>
            <Switch>
              <Route path="/signin" component={Signin} />
              <Route>
                <>
                  <Switch>
                    <ProtectedRoute
                      path="/buyer/:buyer_id"
                      component={BuyerDetails}
                    />
                    <ProtectedRoute
                      path="/buyers/tree-report"
                      component={BuyersTree}
                    />
                    <ProtectedRoute
                      path="/buyers/response-times"
                      component={BuyersResponseTimes}
                    />
                    <ProtectedRoute
                      path="/buyers/contracts"
                      component={BuyerContracts}
                    />
                    <ProtectedRoute
                      path="/buyers/contract-filters"
                      component={BuyerContractFilters}
                    />
                    <ProtectedRoute
                      path="/buyers/contract-performance"
                      component={BuyerContractPerformance}
                    />
                    <ProtectedRoute
                      path="/buyers/routing"
                      component={BuyerLeadList}
                    />
                    <ProtectedRoute
                      path="/buyers/buyer-budgets"
                      component={BuyerBudget}
                    />
                    <ProtectedRoute
                      path="/buyers/buyer-daily-caps"
                      component={BuyerDailyBudget}
                    />
                    <ProtectedRoute
                      path="/leads/filtered"
                      component={FilteredLeads}
                    />
                    <ProtectedRoute
                      path="/leads/detail-report"
                      component={LeadDetailReport}
                    />
                    <ProtectedRoute
                      path="/leads/disposition-lookup"
                      component={LeadDisposition}
                    />
                    <ProtectedRoute
                      path="/accounting/prepayments"
                      component={Prepayments}
                    />
                    <ProtectedRoute
                      path="/affiliate/:affiliate_id"
                      component={AffiliateDetails}
                    />
                    <ProtectedRoute
                      path="/affiliates/tree-report"
                      component={AffiliateTree}
                    />
                    <ProtectedRoute
                      path="/direct-posts/post-summary"
                      component={PostSummary}
                    />
                    <ProtectedRoute
                      path="/products/buy-hold"
                      component={BuyHold}
                    />
                    <ProtectedRoute
                      path="/products/record-search"
                      component={RecordSearch}
                    />
                    <ProtectedRoute
                      path="/products/ping-trees"
                      component={PingTrees}
                    />
                    <ProtectedRoute
                      path="/products/data-validation"
                      component={DataValidationPage}
                    />
                    <ProtectedRoute
                      path="/products/categories"
                      component={ProductCategoriesPage}
                    />
                    <ProtectedRoute
                      path="/data/data-report-incoming"
                      component={IncomingData}
                    />
                    <ProtectedRoute
                      path="/products/campaigns"
                      component={Campaigns}
                    />
                    <ProtectedRoute
                      path="/products/data-report-incoming"
                      component={IncomingData}
                    />
                    <ProtectedRoute
                      path="/direct-posts/post-leads"
                      component={PostLeadsPage}
                    />
                    <ProtectedRoute
                      path="/products/performance"
                      component={PerformancePage}
                    />
                    <ProtectedRoute
                      path="/products/gp-report"
                      component={GrossProfitPage}
                    />
                    <ProtectedRoute
                      path="/products/pixel-percentage"
                      component={PixelPercentagePage}
                    />
                    <ProtectedRoute
                      path="/products/products"
                      component={ProductsPage}
                    />
                    <ProtectedRoute
                      path="/data/sms-delivery"
                      component={SMSDelivery}
                    />
                    <ProtectedRoute
                      path="/campaigns/sms"
                      component={SmsCampaignsReport}
                    />
                    <ProtectedRoute
                      path="/campaigns/numbers"
                      component={NumbersCampaignsReport}
                    />
                    <ProtectedRoute
                      path="/data/outgoing-report"
                      component={OutgoingReport}
                    />
                    <ProtectedRoute
                      path="/flow/tree-report"
                      component={RoiTree}
                    />
                    <ProtectedRoute
                      path="/affiliates/tree-report"
                      component={Affiliates}
                    />
                    <ProtectedRoute
                      path="/affiliates/common-list"
                      component={CommonAffiliates}
                    />
                    <ProtectedRoute
                      path="/affiliates/esign-affiliates"
                      component={EsignDocAffiliatesPage}
                    />
                    <ProtectedRoute
                      path="/cake/cake-offers"
                      component={CakeOffers}
                    />
                    <ProtectedRoute
                      path="/accounting"
                      component={Prepayments}
                    />
                    <ProtectedRoute path="/buyers" component={Buyers} />
                    <ProtectedRoute path="/leads" component={Leads} />
                    <ProtectedRoute
                      path="/reports/external-reports"
                      component={ExternalReports}
                    />
                    <ProtectedRoute
                      path="/reports/call-programs"
                      component={CallPrograms}
                    />
                    <ProtectedRoute
                      path="/reports"
                      component={PerformancePage}
                    />
                    <ProtectedRoute
                      path="/data/active-sources"
                      component={ActiveSources}
                    />
                    <ProtectedRoute
                      path="/common/domains"
                      component={Domains}
                    />
                    <ProtectedRoute
                      path="/data/data-sources"
                      component={DataSources}
                    />
                    <ProtectedRoute
                      path="/data/voice-campaign"
                      component={VoiceCampaignPage}
                    />
                    <ProtectedRoute
                      path="/products/esign-doc"
                      component={EsignDocStatusPage}
                    />
                    <ProtectedRoute
                      path="/data/voice-activity"
                      component={VoiceActivityPage}
                    />
                    <ProtectedRoute
                      path="/data/services"
                      component={DataServicesReport}
                    />
                    <ProtectedRoute path="/flow" component={Roi} />
                    <ProtectedRoute path="/debug" component={Debug} />
                    <ProtectedRoute path="/" component={Home} />
                  </Switch>
                </>
              </Route>
            </Switch>
          </FormProvider>
        </AdmediaryProvider>
      </FirebaseAuthProvider>
    </Router>
  );
}

export default App;

import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import { HeaderSummary, CurrencyCell, CheckCell } from "../../Table";
import { currency } from "../../../@Utils/Format";
import { sort } from "../../../@Utils/Sorting";
import ViewTransactions from "../../Drawers/Transactions";
import ViewHistory from "../../Drawers/ViewHistory";
import Lead from "../../Drawers/Lead";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type FilteredLeadDetailListProps = {
  data: any;
  config: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  categoryId: number;
  affiliateId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const FilteredLeadDetailList: React.FC<FilteredLeadDetailListProps> = ({
  data = [],
  summary = {},
  config = {},
  isLoading = false,
  rowKey = "node_id",
  categoryId = 0,
  affiliateId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  // Build dynamic columns related by category and product ids
  const dynamicColumns =
    config && Array.isArray(config.keys) && config.keys.length
      ? config.keys.map((field: any, index: number) => (
          <Column sortable resizable key={index}>
            <HeaderCell>{config.fields[index]}</HeaderCell>
            <Cell dataKey={field.name} />
          </Column>
        ))
      : [];

  return (
    <Table
      virtualized
      height={height}
      headerHeight={65}
      loading={isLoading === true}
      data={sortData(data)}
      rowKey={rowKey}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={onSortColumn}
    >
      {categoryId === 14 && (
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="lead_instance_status" />
        </Column>
      )}
      ,
      <Column align="right" fixed sortable resizable>
        <HeaderCell>ID</HeaderCell>
        <Cell dataKey="lead_instance_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Product ID</HeaderCell>
        <Cell dataKey="product_id" />
      </Column>
      <Column width={135} sortable resizable>
        <HeaderCell>Description</HeaderCell>
        <Cell dataKey="product_description" />
      </Column>
      <Column width={135} sortable resizable>
        <HeaderCell>Email</HeaderCell>
        <Cell dataKey="email" />
      </Column>
      <Column align={"center"} sortable resizable>
        <HeaderCell>Paid</HeaderCell>
        <CheckCell dataKey="pixel" />
      </Column>
      <Column align={"center"} sortable resizable>
        <HeaderCell>Calling</HeaderCell>
        <CheckCell dataKey="held" />
      </Column>
      <Column align={"center"} sortable resizable>
        <HeaderCell>Buy/Hold</HeaderCell>
        <CheckCell dataKey="default" />
      </Column>
      <Column align={"center"} sortable resizable>
        <HeaderCell>BH Pending</HeaderCell>
        <CheckCell dataKey="pending" />
      </Column>
      <Column align={"center"} sortable resizable>
        <HeaderCell>BH Processing</HeaderCell>
        <CheckCell dataKey="repost_processing" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>RSID</HeaderCell>
        <Cell dataKey="request_session_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate ID</HeaderCell>
        <Cell dataKey="affiliate_id" />
      </Column>
      <Column width={135} sortable resizable>
        <HeaderCell>Affiliate Name</HeaderCell>
        <Cell dataKey="affiliate_name" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Status</HeaderCell>
        <Cell dataKey="status" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Reason</HeaderCell>
        <Cell dataKey="reason" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Rate" summary={currency(summary.rate)} />
        </HeaderCell>
        <CurrencyCell dataKey="rate" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Earned" summary={currency(summary.earned)} />
        </HeaderCell>
        <CurrencyCell dataKey="earned" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Profit" summary={currency(summary.profit)} />
        </HeaderCell>
        <CurrencyCell dataKey="profit" />
      </Column>
      <Column width={120} sortable resizable>
        <HeaderCell>Client IP</HeaderCell>
        <Cell dataKey="client_ip" />
      </Column>
      <Column width={120} sortable resizable>
        <HeaderCell>Contract Name</HeaderCell>
        <Cell dataKey="contract_name" />
      </Column>
      <Column width={120} sortable resizable>
        <HeaderCell>Tier Name</HeaderCell>
        <Cell dataKey="tier_name" />
      </Column>
      <Column width={135} sortable resizable>
        <HeaderCell>Date</HeaderCell>
        <Cell dataKey="entry_date" />
      </Column>
      <Column width={135} sortable resizable>
        <HeaderCell>Transaction Date</HeaderCell>
        <Cell dataKey="transaction_date" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>SID1</HeaderCell>
        <Cell dataKey="sid1" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>SID2</HeaderCell>
        <Cell dataKey="sid2" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>SID3</HeaderCell>
        <Cell dataKey="sid3" />
      </Column>
      {dynamicColumns}
      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_lead">View Lead</Dropdown.Item>
    <Dropdown.Item eventKey="view_transactions">
      View Transactions
    </Dropdown.Item>
    <Dropdown.Item eventKey="view_history">View History</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["view_lead", <Lead row_data={row} />],
      ["view_transactions", <ViewTransactions row_data={row} />],
      ["view_history", <ViewHistory row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_lead", "md"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} />
    </Cell>
  );
};

export default FilteredLeadDetailList;

import React from "react";
import { Whisper, Tooltip, Table, Input } from "rsuite";
import "../../css/dispositionGridStyle.css";
import { sort } from "../../@Utils/Sorting";
const { Column, HeaderCell, Cell } = Table;

// interface ChildData {
//   lead_instance_id: string;
//   status: string;
//   response: string;
//   contract_id: string | number;
//   transaction_date: string;
//   sequence_id: string | number;
// }

interface ContractData {
  contract_id: string | number;
  // children: ChildData[];
  status: string;
  response: string;
  transaction_date: string;
  sequence_id: string | number;
  lead_instance_id: string;
}

interface GridProps {
  data: ContractData[];
  isLoading: boolean;
  sortType?: any;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
}

const LeadDispositionsTable: React.FC<GridProps> = ({
  data,
  isLoading,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
}) => {
  function formatData(data: any) {
    const formatted: any = {};

    data.forEach((contract: any) => {
      const { contract_id, rows } = contract;

      rows.forEach((child: any) => {
        const {
          lead_instance_id,
          status,
          response,
          transaction_date,
          sequence_id,
        } = child;

        // Find or create a row for the lead_instance_id and sequence_id
        const rowKey = `${lead_instance_id}-${sequence_id}`;
        if (!formatted[rowKey]) {
          formatted[rowKey] = { lead_instance_id, transaction_date };
        }

        const contractKey = !isNaN(Number(contract_id))
          ? String(contract_id)
          : "0";
        // Add data based on sequence_id, transaction_date, and status
        if (contract_id === 0 && status === "") {
          // Display status directly for contract_id 0
          formatted[rowKey][contractKey] = response;
        } else if (status === "filtered") {
          // Display response for filtered status
          formatted[rowKey][contractKey] = response;
        } else {
          // Use Tooltip for accepted or rejected status
          formatted[rowKey][contractKey] = [status, response];
        }
      });
    });

    return Object.values(formatted);
  }

  const newData = data.reduce((r: any, a: any) => {
    r[a.contract_id] = r[a.contract_id] || [];
    r[a.contract_id].push(a);
    return r;
  }, Object.create(null));

  const keys = Object.keys(newData).map((key: any) => {
    return { contract_id: key, rows: newData[key] };
  });

  const formattedData = formatData(Array.isArray(keys) ? keys : []);

  const uniqueContractIds: {
    id: string | number;
    name: string;
    contract_id: string | number;
  }[] = Array.from(
    new Map(
      data.map((row: any) => [
        row.contract_id,
        {
          id: !isNaN(Number(row.contract_id)) ? String(row.contract_id) : 0,
          name: `${row.contract_name}`,
          contract_id: !isNaN(Number(row.contract_id)) ? row.contract_id : 0,
        },
      ])
    ).values()
  );

  const sortDispo = (data: any[], attribute: string, type?: string): any[] => {
    // descending order
    if (type === "desc") {
      return data.sort((a: any, b: any) => {
        return a.lead_instance_id - b.lead_instance_id ||
          a[attribute] > b[attribute]
          ? -1
          : 1;
      });
    }
    // ascending order
    return data.sort((a: any, b: any) => {
      return a.lead_instance_id - b.lead_instance_id ||
        a[attribute] > b[attribute]
        ? 1
        : -1;
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortColumn && sortType
      ? sortDispo(list, sortColumn, sortType)
      : list;
  };

  // console.log({
  //   'data': data,
  //   'formattedData': formattedData,
  //   'uniqueContractIds': uniqueContractIds
  // })

  return (
    <div>
      <div className="table-container">
        <Table
          // data={formattedData}
          data={sortData(formattedData)}
          autoHeight
          cellBordered
          hover
          virtualized={true}
          headerHeight={90}
          rowClassName="clickable-data striped-rows"
          loading={isLoading === true}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={onSortColumn}
          defaultSortType={"asc"}
        >
          <Column width={200} fixed>
            <HeaderCell>Lead Instance ID</HeaderCell>
            <Cell dataKey="lead_instance_id" />
          </Column>
          <Column width={200} fixed sortable>
            <HeaderCell>Transaction Date</HeaderCell>
            <Cell dataKey="transaction_date" />
          </Column>
          {uniqueContractIds.map(({ id, name, contract_id }: any) => (
            <Column key={id} width={200} align="center">
              <HeaderCell>
                <Whisper
                  preventOverflow
                  trigger="hover"
                  speaker={<Tooltip style={{ width: 120 }}>{name}</Tooltip>}
                  placement="auto"
                >
                  <span>
                    {contract_id} <br /> {name}
                  </span>
                </Whisper>
              </HeaderCell>
              <CustomCell2 dataKey={id} />
            </Column>
          ))}
        </Table>
      </div>
    </div>
  );
};

const CustomCell2 = ({ rowData, dataKey, onChange, ...props }: any) => (
  <Cell {...props} dataKey={dataKey}>
    {Array.isArray(rowData[dataKey]) ? (
      <Whisper
        preventOverflow
        trigger="hover"
        speaker={
          <Tooltip>
            <span>{rowData[dataKey][1]}</span>
          </Tooltip>
        }
        placement="auto"
      >
        <span>{rowData[dataKey][0]}</span>
      </Whisper>
    ) : (
      <span>{rowData[dataKey]}</span>
    )}
  </Cell>
);

export default LeadDispositionsTable;

import React from "react";
import { ResponsiveBar as NivoResponsiveBar } from "@nivo/bar";
import { Loader } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

type ConsideredVsAcceptedBarGraphByCategoryProps = {
  showLegend?: boolean;
};

const ConsideredVsAcceptedBarGraphByCategory: React.FC<ConsideredVsAcceptedBarGraphByCategoryProps> = ({
  showLegend = false,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: admediaryContext.category,
  };

  const [data, isLoading] = useAdmediaryApi(
    "products_considered_accepted",
    params
  );

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const renderLegend = (): any[] => {
    return !showLegend
      ? []
      : [
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ];
  };

  const sorted_data: any = data
    .map((item) => {
      const name = `(${item.product_id}) ${item.product_description.trim()}`;
      return {
        ...item,
        ...{
          considered: item.accepted + item.rejected,
          // affiliate_renamed: name,
          short_name: name.length > 19 ? `${name.substring(0, 20)}..` : name,
        },
      };
    })
    .sort((a, b) => {
      return a.accepted < b.accepted ? -1 : 1;
    });

  console.log(data);

  return (
    <NivoResponsiveBar
      data={Array.isArray(data) ? sorted_data : []}
      keys={["accepted", "considered"]}
      indexBy="short_name"
      margin={{ top: 0, right: 25, bottom: 50, left: 135 }}
      padding={0.2}
      groupMode="grouped"
      layout="horizontal"
      colors={["#37d199", "#ff8400"]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: 0,
        legend: "Leads",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -70,
        legendPosition: "middle",
      }}
      enableLabel={false}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={renderLegend()}
    />
  );
};

export default ConsideredVsAcceptedBarGraphByCategory;

import React from "react";
import { ResponsiveBar as NivoResponsiveBar } from "@nivo/bar";
import { Loader } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

type ConsideredVsAcceptedBarGraphProps = {
  showLegend?: boolean;
};

const ConsideredVsAcceptedBarGraph: React.FC<ConsideredVsAcceptedBarGraphProps> = ({
  showLegend = false,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: admediaryContext.category,
    product_id: admediaryContext.product,
    affiliate_id: 0,
    buyer_id: admediaryContext.buyer,
    group: "",
  };

  const [data, isLoading] = useAdmediaryApi("leads_disposition", params);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const graphData: any = [
    {
      id: "Considered",
      leads: parseInt(data[0].rejected) + parseInt(data[0].accepted),
    },
    {
      id: "Accepted",
      leads: parseInt(data[0].accepted),
    },
  ];

  const renderLegend = (): any[] => {
    return !showLegend
      ? []
      : [
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ];
  };

  return (
    <NivoResponsiveBar
      data={graphData}
      keys={["leads"]}
      indexBy={"id"}
      margin={{ top: 25, right: showLegend ? 110 : 60, bottom: 80, left: 60 }}
      padding={0.2}
      colors={(item: any) => {
        if (item.indexValue === "Considered") {
          return "#f4d556";
        }
        return "#ff8400";
      }}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: 0,
        legend: "Disposition",
        legendPosition: "middle",
        legendOffset: 36,
      }}
      enableGridX={true}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Leads",
        legendOffset: -50,
        legendPosition: "middle",
      }}
      enableLabel={false}
      // labelSkipWidth={12}
      // labelSkipHeight={12}
      // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layout="vertical"
      groupMode="grouped"
      legends={renderLegend()}
    />
  );
};

export default ConsideredVsAcceptedBarGraph;

import React from "react";
import {
  DatePicker,
  FlexboxGrid,
  Icon,
  IconButton,
  InputNumber,
  Notification,
  Table,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractScheduleForm from "./BuyerContractScheduleForm";
import BuyerContractSchedule24x7Form from "./BuyerContractSchedule24x7Form";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractScheduleListType = {
  contractId: number;
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractScheduleList: React.FC<BuyerContractScheduleListType> = ({
  contractId = 0,
  height = 650,
  autoHeight = true,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);
  const idKey = "schedule_id";

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
    fetchData();
  };

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_schedules", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const saveItem = (data: any) => {
    // Transform Date instance to an hours+minutes string
    data.start_time =
      typeof data.start_time == "string"
        ? data.start_time
        : format(data.start_time, "HH:mm");
    data.end_time =
      typeof data.end_time == "string"
        ? data.end_time
        : format(data.end_time, "HH:mm");

    Query("buyer_contract_schedule_update", data)
      .then((response) => {
        Notification.success({
          title: "Success",
          description: "Buyer Contract Schedule has been updated",
        });

        handleEditModalClose();
        refresh();
      })
      .catch((error) => {
        console.log("error", error);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: error,
        });
      });
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    saveItem(_.cloneDeep(formValue));
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_schedule_remove", {
          [idKey]: itemId,
        });
        Notification.success({
          title: "Success",
          description: "Buyer Contract Schedule has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem[idKey]);

    handleClose();
  };

  const changeActiveItemStatus = (rowData: any, status: string | null) => {
    const nextData = Object.assign([], data);
    const activeItem: any = nextData.find(
      (item) => item[idKey] === rowData[idKey]
    );
    if (activeItem) {
      activeItem.status = status;
    }

    setData(nextData);
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    changeActiveItemStatus(rowData, EDIT_STATUS);
  };

  const handleCloseActionClick = (rowData: any) => {
    setSelectedItem(null);

    // Replace changed data by original ones
    const index = changedData.findIndex(
      (item) => item[idKey] === rowData[idKey]
    );
    if (index > -1) {
      changedData[index] = { ...rowData };
      setChangedData(changedData);
    }

    changeActiveItemStatus(rowData, null);
  };

  const handleSaveActionClick = (rowData: any) => {
    setSelectedItem(null);

    const currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );
    if (currentItem) {
      saveItem(currentItem);
    }

    changeActiveItemStatus(rowData, null);
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddScheduleClick = () => {
    setSelectedItem({
      [idKey]: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  const schedule24x7FormRef = React.createRef<FormInstance>();
  const [schedule24x7FormValue, setSchedule24x7FormValue] = React.useState({});
  const [schedule24x7ModalOpen, setSchedule24x7ModalOpen] = React.useState(
    false
  );
  const handleSchedule24x7ModalOpen = () => setSchedule24x7ModalOpen(true);
  const handleSchedule24x7ModalClose = () => setSchedule24x7ModalOpen(false);

  const handleSchedule24x7Click = () => {
    setSelectedItem({
      contract_id: contractId,
    });

    handleSchedule24x7ModalOpen();
  };

  const handleSchedule24x7ModalSave = () => {
    const node = schedule24x7FormRef && schedule24x7FormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(schedule24x7FormValue);

    // Transform Date instance to an hours+minutes string
    data.start_time = format(data.start_time, "HH:mm");
    data.end_time = format(data.end_time, "HH:mm");

    (async () => {
      try {
        await Query("buyer_contract_schedule24x7", data);
        Notification.success({
          title: "Success",
          description: "Buyer Contract Schedule 24x7 has been updated",
        });

        handleSchedule24x7ModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const handleSchedule24x7Callback = (formValue: any) => {
    setSchedule24x7FormValue(formValue);
  };

  const initChangedData: any[] = [];
  const [changedData, setChangedData] = React.useState(initChangedData);
  const handleChange = (rowData: any, key: string, value: any) => {
    let currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );

    if (!currentItem) {
      currentItem = data.find((item) => item[idKey] === rowData[idKey]);
      currentItem = { ...currentItem };
      changedData.push(currentItem);
    }

    if (currentItem) {
      currentItem[key] = value;
    }

    setChangedData(changedData);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={24} style={{ textAlign: "left" }}>
          <IconButton
            onClick={handleAddScheduleClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Schedule
          </IconButton>
          <IconButton
            onClick={handleSchedule24x7Click}
            icon={<Icon icon="calendar" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            24x7
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {Admediary.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
              saveHandler={handleSaveActionClick}
              closeHandler={handleCloseActionClick}
            />
          </Column>
        )}
        <Column width={150} fixed sortable resizable>
          <HeaderCell>Day of Week</HeaderCell>
          <DayEditableCell dataKey="day_of_week" onChange={handleChange} />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Start Time</HeaderCell>
          <TimeEditableCell dataKey="start_time" onChange={handleChange} />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>End Time</HeaderCell>
          <TimeEditableCell dataKey="end_time" onChange={handleChange} />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Cap</HeaderCell>
          <CapEditableCell dataKey="cap" onChange={handleChange} />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>Type</HeaderCell>
          <TypeEditableCell dataKey="type" onChange={handleChange} />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Rate</HeaderCell>
          <RateEditableCell dataKey="rate" onChange={handleChange} />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Schedule?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem[idKey] > 0 ? "Edit" : "Add") +
          " Schedule"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <BuyerContractScheduleForm
          scheduleId={selectedItem ? selectedItem.schedule_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>

      <EditModal
        title="Edit Schedule 24x7"
        open={schedule24x7ModalOpen}
        size={"sm"}
        onClose={handleSchedule24x7ModalClose}
        onCancel={handleSchedule24x7ModalClose}
        onSubmit={handleSchedule24x7ModalSave}
      >
        <BuyerContractSchedule24x7Form
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={schedule24x7FormRef}
          parentCallback={handleSchedule24x7Callback}
        />
      </EditModal>
    </>
  );
};

const getDayOfWeekByAbbreviation = (abbr: string) => {
  const days = new Map([
    ["mo", "Monday"],
    ["tu", "Tuesday"],
    ["we", "Wednesday"],
    ["th", "Thursday"],
    ["fr", "Friday"],
    ["sa", "Saturday"],
    ["su", "Sunday"],
  ]);

  return days.has(abbr) ? days.get(abbr) : null;
};

const getTypeTitle = (abbr: string) => {
  const types = new Map([
    ["e", "Exclusive"],
    ["h", "Hybrid"],
    ["m", "Multisell"],
  ]);

  return types.has(abbr) ? types.get(abbr) : null;
};

const EDIT_STATUS = "EDIT";

/**
 * Transform string 'HH:mm' to Date object to pass into the DatePicker component
 * @param time
 */
const transformTimeToDate = (time: string) => {
  const date = new Date();
  const [hours, minutes] = time.split(":");
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));

  return date;
};

const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  saveHandler,
  closeHandler,
  ...props
}: any) => {
  const trash = removeHandler instanceof Function && (
    <IconButton
      appearance="subtle"
      onClick={(e) => removeHandler(rowData, e)}
      icon={<Icon icon="trash" />}
    />
  );

  return (
    <Cell {...props} className="link-group">
      {rowData.status === "EDIT"
        ? [
            <IconButton
              appearance="subtle"
              onClick={(e) => saveHandler(rowData, e)}
              icon={<Icon icon="check" />}
            />,
            <IconButton
              appearance="subtle"
              onClick={(e) => closeHandler(rowData, e)}
              icon={<Icon icon="close" />}
            />,
          ]
        : [
            <IconButton
              appearance="subtle"
              onClick={(e) => editHandler(rowData, e)}
              icon={<Icon icon="edit2" />}
            />,
            trash,
          ]}
    </Cell>
  );
};

const DayEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <SelectPicker
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          searchable={false}
          cleanable={false}
          data={[
            { value: "mo", label: "Monday" },
            { value: "tu", label: "Tuesday" },
            { value: "we", label: "Wednesday" },
            { value: "th", label: "Thursday" },
            { value: "fr", label: "Friday" },
            { value: "sa", label: "Saturday" },
            { value: "su", label: "Sunday" },
          ]}
        />
      ) : (
        getDayOfWeekByAbbreviation(rowData[dataKey])
      )}
    </Cell>
  );
};

const TypeEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <SelectPicker
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          searchable={false}
          cleanable={false}
          data={[
            { value: "e", label: "Exclusive" },
            { value: "h", label: "Hybrid" },
            { value: "m", label: "Multisell" },
          ]}
        />
      ) : (
        getTypeTitle(rowData[dataKey])
      )}
    </Cell>
  );
};

const TimeEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <DatePicker
          defaultValue={transformTimeToDate(rowData[dataKey])}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          format="HH:mm"
          ranges={[]}
          hideMinutes={(minute: number) => minute % 5 !== 0}
          cleanable={false}
        />
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
};

const CapEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <InputNumber
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          min={0}
        />
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
};

const RateEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <InputNumber
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          step={0.0001}
        />
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
};

export default BuyerContractScheduleList;

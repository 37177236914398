import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../../@Hooks/useAdmediaryApi";
// import useAdmediaryApiManual from "../../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../../@Utils/Export";
import FilteredLeadDetailList from "./FilteredLeadDetailList";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = ["rate", "earned", "profit"];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return summary;
}

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      ...item,
      ...{
        entry_date: item.entry_date
          ? format(item.entry_date, "MM-dd-Y HH:mm")
          : "",
        transaction_date: item.transaction_date
          ? format(item.transaction_date, "MM-dd-Y HH:mm")
          : "",
      },
    };
  });
}

type FilteredLeadDetailProps = {
  height?: number;
  defaultReason?: string;
  overrideReasons?: string[];
};

const FilteredLeadDetail: React.FC<FilteredLeadDetailProps> = ({
  height = 650,
  defaultReason = "",
  overrideReasons,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [affiliateId, setAffiliate] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState();
  const [reason, setReason] = React.useState(defaultReason);
  const [subId1, setSubId1] = React.useState();
  const [subId2, setSubId2] = React.useState();
  const affiliates = Admediary.config.affiliates;
  const categoryId = Admediary.category;
  const intervalRef: any = React.useRef(null);

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: categoryId,
    product_id: Admediary.product,
    affiliate_id: affiliateId,
    buyer_id: Admediary.buyer,
    query: searchQuery,
    sub_id_1: subId1,
    sub_id_2: subId2,
    reason,
  };

  const [data, isLoading, , config] = useAdmediaryApi(
    "filtered_lead_detail_report",
    params
  );

  // const [data, isLoading, , config] = useAdmediaryApiManual(
  //   "filtered_lead_detail_report",
  //   params
  // );

  /**
   * List of Lead Reasons (Buyer Contract Transactions status/response)
   * Reasons can be overwritten through a provided prop
   */
  const reasons = !overrideReasons
    ? [
        { reason: "", label: "All Leads" },
        { reason: "accepted", label: "Accepted Leads" },
        { reason: "rejected", label: "Rejected" },
        { reason: "filtered", label: "Filtered" },
        { reason: "affiliate", label: "Affiliate" },
      ]
    : overrideReasons.map((row) => {
        return { reason: row, label: row };
      });

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      if (type === 1) {
        setSubId1(value);
      } else if (type === 2) {
        setSubId2(value);
      } else {
        setSearchQuery(value);
      }
    }, 750);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            placeholder="Filter by Affiliate"
            value={affiliateId}
            data={affiliates}
            onChange={(v) => {
              setAffiliate(v);
            }}
            valueKey="affiliate_id"
            labelKey="affiliate_name"
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Reason"
            value={reason}
            data={reasons}
            onChange={(v) => {
              setReason(v);
            }}
            valueKey="reason"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 1"
              onChange={(v) => {
                debounceSearchTerm(v, 1);
              }}
              size="sm"
            />
          </InputGroup>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 2"
              onChange={(v) => {
                debounceSearchTerm(v, 2);
              }}
              size="sm"
            />
          </InputGroup>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `filtered_leads_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FilteredLeadDetailList
        height={height}
        data={Array.isArray(data) ? data : []}
        config={config ? config : []}
        summary={getSummary(Array.isArray(data) ? data : [])}
        isLoading={isLoading === true}
        rowKey={rowKey}
        categoryId={categoryId}
        affiliateId={affiliateId}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default FilteredLeadDetail;

import React from "react";
import { Table } from "rsuite";
import { HeaderSummary, PercentCell, ParentCell } from "../Table";
import { percent } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";

const { Column, HeaderCell, Cell } = Table;

type DataValidationProps = {
  data: any;
  isLoading: boolean;
  rowKey: string;
  type: string;
  checked: boolean;
  sortType: string;
  sortColumn: string;
  summary: any;
  onSortColumn: (column: any, type: any) => void;
};

const DataValidationTable: React.FC<DataValidationProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const checked = props.checked;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const type = props.type;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sortTree(list, sort_column, sort_type) : list
    );
  };

  const typeTitles = new Map([
    ["date", "Date/Product ID/Source URL/Affiliate ID"],
    ["product", "Product ID/Source URL/Affiliate ID/Entry Date"],
  ]);

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading}
      data={Array.isArray(data) ? sortData(data) : []}
      checked={checked}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={300} align="left" sortable resizable>
        <HeaderCell>{typeTitles.get(type)}</HeaderCell>
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column width={225} align="left" sortable resizable>
        <HeaderCell>Product Description</HeaderCell>
        <Cell dataKey="product_description" />
      </Column>
      {!checked ? (
        ""
      ) : (
        <Column width={175} align="left" sortable resizable>
          <HeaderCell>SubID</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>
      )}
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Email Request"
            summary={summary.email_request}
          />
        </HeaderCell>
        <Cell dataKey="email_request" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Email Valid" summary={summary.email_valid} />
        </HeaderCell>
        <Cell dataKey="email_valid" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Email Invalid"
            summary={summary.email_invalid}
          />
        </HeaderCell>
        <Cell dataKey="email_invalid" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Email Invalid %"
            summary={percent(summary.email_invalid_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="email_invalid_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Email Blacklist"
            summary={summary.email_blacklist}
          />
        </HeaderCell>
        <Cell dataKey="email_blacklist" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Email Blacklist %"
            summary={percent(summary.email_blacklist_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="email_blacklist_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Total Email Invalid %"
            summary={percent(summary.email_total_invalid_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="email_total_invalid_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Phone Request"
            summary={summary.phone_request}
          />
        </HeaderCell>
        <Cell dataKey="phone_request" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Phone Valid" summary={summary.phone_valid} />
        </HeaderCell>
        <Cell dataKey="phone_valid" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Phone Invalid"
            summary={summary.phone_invalid}
          />
        </HeaderCell>
        <Cell dataKey="phone_invalid" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Phone Invalid %"
            summary={percent(summary.phone_invalid_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="phone_invalid_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Phone Blacklist"
            summary={summary.phone_blacklist}
          />
        </HeaderCell>
        <Cell dataKey="phone_blacklist" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Phone Blacklist %"
            summary={percent(summary.phone_blacklist_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="phone_blacklist_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Total Phone Invalid %"
            summary={percent(summary.phone_total_invalid_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="phone_total_invalid_percent" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Overlap Invalid" summary={summary.overlap} />
        </HeaderCell>
        <Cell dataKey="overlap" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Overlap Invalid %"
            summary={summary.overlap_percent}
          />
        </HeaderCell>
        <Cell dataKey="overlap_percent" />
      </Column>
    </Table>
  );
};

export default DataValidationTable;

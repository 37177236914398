import React from "react";
import { Schema, Form, Uploader } from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";

const { StringType, NumberType, ObjectType } = Schema.Types;

const model = Schema.Model({
  affiliate_id: NumberType().isRequired("Affiliate ID is required."),
  document_id: NumberType(),
  document_name: StringType().isRequired("Name is required."),
  document_type: StringType().isRequired("Type is required."),
  document_file: ObjectType()
    .isRequired("File is required.")
    .addRule((value: any) => {
      return (
        value !== null &&
        Array.isArray(value) &&
        value.length > 0 &&
        value[0].blobFile !== null
      );
    }, "File is required."),
});

type BuyerContractBudgetFormType = {
  affiliateId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const DocumentForm: React.FC<BuyerContractBudgetFormType> = ({
  affiliateId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const emptyItem = {
    document_id: 0,
    affiliate_id: affiliateId,
    document_file: [{ blobFile: null }],
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Name" name="document_name" />
      <Field
        label="Type"
        name="document_type"
        placeholder="Select Type"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "W8", label: "W8" },
          { value: "W9", label: "W9" },
          { value: "Note", label: "Note" },
        ]}
      />
      <Field
        label="Upload"
        name="document_file"
        accepter={Uploader}
        disabled={
          formValue.document_file.length > 0 &&
          formValue.document_file[0].blobFile !== null
        }
        autoUpload={false}
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default DocumentForm;

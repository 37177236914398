import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form } from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  affiliate_id: NumberType().isRequired("Affiliate ID is required."),
  sid1: StringType(),
  sid2: StringType(),
  sid3: StringType(),
  allow: NumberType().isRequired("Select Allow please."),
});

type PingTreeRoutingSetSourceFormType = {
  sourceId?: number;
  routingId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const PingTreeRoutingSetSourceForm: React.FC<PingTreeRoutingSetSourceFormType> = ({
  sourceId = 0,
  routingId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptySource = {
    routing_set_id: routingId,
    source_id: sourceId,
  };
  const [formValue, setFormValue] = React.useState(emptySource);
  const [formError, setFormError] = React.useState({});

  const params = {
    source_id: sourceId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.source_id === 0) {
      setData(emptySource);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("routing_set_source", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Afid" name="affiliate_id" />
      <Field label="Sid1" name="sid1" />
      <Field label="Sid2" name="sid2" />
      <Field label="Sid3" name="sid3" />
      <Field
        label="Allow"
        name="allow"
        placeholder="Select Allow"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: 1, label: "Allow" },
          { value: 0, label: "Block" },
        ]}
      />
    </Form>
  );
};
export default PingTreeRoutingSetSourceForm;

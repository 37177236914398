import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  Table,
  Toggle,
  Whisper,
  IconButton,
  Tooltip,
  SelectPicker,
  CheckPicker,
  Loader,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { sort } from "../../@Utils/Sorting";
import CakeOffersBarGraph from "../Affiliates/CakeOffersBarGraph";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

type CakeOfferDrilldownType = {
  rowData: any;
  start: Date;
  end: Date;
  groupBy: string;
};

const CakeOfferDrilldown: React.FC<CakeOfferDrilldownType> = ({
  rowData,
  start,
  end,
  groupBy,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState(
    groupBy == "affiliate" ? "click_count" : "interval"
  );
  const [group_by, setGroup_by] = React.useState(groupBy);
  const [startDate, setStartDate] = React.useState(start);
  const [endDate, setEndDate] = React.useState(end);
  const [affiliateId, setAffiliateId] = React.useState(Admediary.affiliate);
  const [prevGroupBy, setPrevGroupBy] = React.useState(groupBy);
  const [viewMode, setViewMode] = React.useState("graph");
  const [graphKeys, setGraphKeys] = React.useState(["Clicks"]);

  const availableGraphBars = ["Clicks", "Conversions"].map((item) => ({
    label: item,
    value: item,
  }));

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const drilldownHour = (rowData: any) => {
    setGroup_by("hour");
    var dt = new Date(rowData.interval + "T00:00:00");
    dt.setHours(0, 0, 0);
    setStartDate(dt);
    var end = new Date(dt.getTime() + 86400 * 1000 - 1);
    setEndDate(end);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sort(
      list.map((row: any) => {
        if (typeof row[sortColumn] !== "undefined") {
          var scalarValue = row[sortColumn]
            .toString()
            .replace("%", "")
            .replace("$", "");
          scalarValue = isNaN(parseFloat(scalarValue))
            ? scalarValue.toString().toUpperCase()
            : parseFloat(scalarValue);
        } else {
          scalarValue = 0;
        }
        return {
          ...row,
          sortColumn: scalarValue,
        };
      }),
      "sortColumn",
      sortType
    );
  };

  const closeHourlyDrilldown = (e: any) => {
    setGroup_by("date");
    setStartDate(Admediary.start);
    setEndDate(Admediary.end);
  };

  const drilldownAffiliate = (row: any) => {
    setSortColumn("interval");
    setPrevGroupBy(group_by);
    setGroup_by("date");
    setAffiliateId(row.affiliate_id);
  };
  const params = {
    start_date: new Date(startDate),
    end_date: new Date(endDate),
    group_by: group_by,
    offer_id: rowData.offer_id,
    affiliate_id: affiliateId,
  };
  const endPoint =
    group_by === "affiliate"
      ? "report_affiliate_offers_select_by_affiliate"
      : "report_affiliate_offers_select_by_interval";
  const [data, isLoading] = useAdmediaryApi(endPoint, params);

  const affiliateListFetchParams = {
    start_date: new Date(startDate),
    end_date: new Date(endDate),
    offer_id: rowData.offer_id,
  };

  const [affiliateFetchList, affiliateFetchListLoading] = useAdmediaryApi(
    "affiliate_select_distinct_by_offer",
    affiliateListFetchParams
  );
  const affiliateList =
    !affiliateFetchListLoading && affiliateFetchList !== null
      ? Array.isArray(affiliateFetchList)
        ? affiliateFetchList.map((val) => {
            return {
              ...val,
              label: `(${val.affiliate_id}) ${val.affiliate_name}`,
            };
          })
        : []
      : [];

  const treeData =
    !isLoading && data !== null ? (Array.isArray(data) ? data : []) : [];

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Click Data by {group_by.charAt(0).toUpperCase() + group_by.slice(1)} -{" "}
          {rowData.offer_name} ({rowData.offer_id}) [{startDate.getMonth() + 1}/
          {startDate.getDate()}/{startDate.getFullYear()} -{" "}
          {endDate.getMonth() + 1}/{endDate.getDate()}/{endDate.getFullYear()}]
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
        <FlexboxGrid justify="start" style={{ marginTop: 20 }}>
          <FlexboxGrid.Item colspan={7}>
            <SelectPicker
              size="md"
              placeholder="Search Affiliates"
              data={affiliateList}
              valueKey="affiliate_id"
              labelKey="label"
              value={affiliateId}
              searchable
              onChange={(val) => {
                setAffiliateId(val);
              }}
              style={{
                marginRight: "20px",
                width: "300px",
                display: group_by === "affiliate" ? "none" : "inline-block",
              }}
              onClean={(e) => {
                if (prevGroupBy === "affiliate") {
                  setSortColumn("click_count");
                  setPrevGroupBy(group_by);
                  setGroup_by("affiliate");

                  setStartDate(Admediary.start);
                  setEndDate(Admediary.end);
                }
              }}
              disabled={
                isLoading === true || affiliateFetchListLoading === true
              }
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={1}></FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={6}>
            <IconButton
              size="md"
              disabled={group_by !== "hour" || isLoading === true}
              style={{ display: group_by !== "hour" ? "none" : "inline-block" }}
              onClick={closeHourlyDrilldown}
            >
              <Icon icon="close" />
              {"Hourly : " +
                endDate.getFullYear() +
                "-" +
                (endDate.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                endDate.getDate().toString().padStart(2, "0")}
            </IconButton>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={4}>
            <IconButton
              size="md"
              onClick={(e) => {
                setViewMode(viewMode === "graph" ? "table" : "graph");
              }}
            >
              <Icon icon={viewMode === "graph" ? "table" : "bar-chart"} />
              {viewMode === "graph" ? "Table" : "Graph"}
            </IconButton>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={5}>
            {viewMode === "graph" && (
              <CheckPicker
                size="md"
                data={availableGraphBars}
                label={"Columns: "}
                value={graphKeys}
                onChange={(val: any) => {
                  setGraphKeys(val);
                }}
                style={{ width: 250 }}
                countable={false}
                searchable={false}
                cleanable={false}
                renderValue={(value, items) => {
                  return (
                    <span>
                      <span style={{ color: "#575757" }}>Columns:</span>{" "}
                      {value.join(" , ")}
                    </span>
                  );
                }}
              />
            )}

            {viewMode === "table" && (
              <IconButton
                onClick={() => {
                  exportCsv(
                    `cake_offers_report_drilldown_${group_by}_${format(
                      new Date(Admediary.start),
                      "MMddyy"
                    )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                    data
                  );
                }}
                icon={<Icon icon="file-download" />}
                placement="right"
                size="md"
                disabled={isLoading === true}
              >
                Export
              </IconButton>
            )}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading === true && (
          <Loader content="Loading..." style={{ margin: "1em" }} />
        )}

        {viewMode == "table" && isLoading !== true && (
          <Table
            height={580}
            headerHeight={65}
            loading={false}
            data={
              Array.isArray(data) && data[0].interval != ""
                ? sortData(data).reverse()
                : []
            }
            rowKey="interval"
            rowClassName="clickable-data striped-rows"
            affixHeader
            affixHorizontalScrollbar
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            defaultSortType={"desc"}
          >
            {group_by !== "affiliate" && (
              <Column width={group_by === "hour" ? 150 : 120} sortable>
                <HeaderCell>
                  Date{group_by === "hour" ? "/Time" : ""}
                </HeaderCell>
                <Cell dataKey="interval" />
              </Column>
            )}
            {group_by === "date" && (
              <Column width={50}>
                <HeaderCell></HeaderCell>
                <Cell>
                  {(row: any) => (
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Hourly</Tooltip>}
                    >
                      <IconButton
                        size="sm"
                        icon={<Icon icon="clock-o" />}
                        onClick={(e) => drilldownHour(row)}
                        style={{ marginTop: "-6px" }}
                      ></IconButton>
                    </Whisper>
                  )}
                </Cell>
              </Column>
            )}
            {group_by === "affiliate" && (
              <Column width={70} sortable>
                <HeaderCell>Aff. ID</HeaderCell>
                <Cell dataKey="affiliate_id" />
              </Column>
            )}
            {group_by === "affiliate" && (
              <Column width={200} sortable>
                <HeaderCell>Affiliate Name</HeaderCell>
                <Cell dataKey="affiliate_name" />
              </Column>
            )}
            {group_by === "affiliate" && (
              <Column width={50}>
                <HeaderCell></HeaderCell>
                <Cell>
                  {(row: any) => (
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Daily</Tooltip>}
                    >
                      <IconButton
                        size="sm"
                        icon={<Icon icon="calendar" />}
                        onClick={(e) => drilldownAffiliate(row)}
                        style={{ marginTop: "-6px" }}
                      ></IconButton>
                    </Whisper>
                  )}
                </Cell>
              </Column>
            )}
            <Column width={90} sortable>
              <HeaderCell title="Clicks">Clicks</HeaderCell>
              <Cell dataKey="click_count" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell title="Total Conversions">Conversions</HeaderCell>
              <Cell dataKey="conversion_count" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell title="Paid Conversions">Paid</HeaderCell>
              <Cell dataKey="paid_total" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Total Paid</HeaderCell>
              <Cell dataKey="total_count" />
            </Column>
            <Column width={150} sortable>
              <HeaderCell title="Conversion Percentage">
                Conversion Perc%
              </HeaderCell>
              <Cell dataKey="conversion_percentage" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Revenue</HeaderCell>
              <Cell dataKey="revenue_str" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Cost</HeaderCell>
              <Cell dataKey="cost" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Profit</HeaderCell>
              <Cell dataKey="profit" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Margin</HeaderCell>
              <Cell dataKey="margin" />
            </Column>
            <Column width={90} sortable>
              <HeaderCell>Earnings Per Click</HeaderCell>
              <Cell dataKey="earnings_per_click" />
            </Column>
          </Table>
        )}
        {viewMode == "graph" && isLoading !== true && (
          <>
            {Array.isArray(data) &&
              data.length > 0 &&
              data[0].interval != "" && (
                <CakeOffersBarGraph
                  data={Array.isArray(data) ? sortData(data) : []}
                  startDate={startDate}
                  isLoading={false}
                  graphKeys={graphKeys}
                  groupBy={group_by}
                  drilldownHour={drilldownHour}
                  drilldownAffiliate={drilldownAffiliate}
                />
              )}
          </>
        )}
      </Drawer.Body>
    </>
  );
};

export default CakeOfferDrilldown;

import React, { SyntheticEvent } from "react";
import { Table } from "rsuite";
import { CheckCell, EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerContractFilter from "./../Drawers/BuyerContractFilter";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractFiltersListType = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const BuyerContractFiltersList: React.FC<BuyerContractFiltersListType> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <BuyerContractFilter
        filterId={rowData.filter_id}
        refreshData={refreshData}
      />,
      "sm"
    );
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Filter ID</HeaderCell>
          <Cell dataKey="filter_id" />
        </Column>
        <Column width={350} sortable resizable>
          <HeaderCell>Contract Name</HeaderCell>
          <Cell dataKey="contract_name" />
        </Column>
        <Column width={500} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Reason</HeaderCell>
          <Cell dataKey="reason" />
        </Column>
        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={100} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell editHandler={handleEditActionClick} />
        </Column>
      </Table>
    </>
  );
};

export default BuyerContractFiltersList;

import React from "react";
import { FlexboxGrid, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import BuyerContractsWithSourcesList from "./BuyerContractsWithSourcesList";
import SelectPicker from "rsuite/lib/SelectPicker";

type BuyerContractsWithSourcesProps = {
  contractId?: number;
  height?: number;
};

const BuyerContractsWithSources: React.FC<BuyerContractsWithSourcesProps> = ({
  height = 650,
  contractId = 0,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultActiveValue = 1;
  const [active, setActive] = React.useState(defaultActiveValue);
  const activeList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const [categoryId, setCategoryId] = React.useState(Admediary.category);
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    category_id: categoryId,
    buyer_id: Admediary.buyer,
    active,
  };

  const [data, isLoading] = useAdmediaryApi(
    "products_routing_contracts",
    params
  );

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toString().toLowerCase().includes(search);
    const fields = [
      "contract_id",
      "buyer_name",
      "category_name",
      "contract_name",
      "routing_tag",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            placeholder="All Verticals"
            data={Admediary.config.categories}
            labelKey="category_name"
            valueKey="category_id"
            onChange={setCategoryId}
            value={categoryId}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={false}
            defaultValue={defaultActiveValue}
            value={active}
            data={activeList}
            onChange={(v) => {
              setActive(v);
            }}
            valueKey="value"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerContractsWithSourcesList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        contractId={contractId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default BuyerContractsWithSources;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { exportCsv } from "../../@Utils/Export";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import SelectPicker from "rsuite/lib/SelectPicker";
import Title from "../Title";
import PixelPercentageList from "./PixelPercentageList";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormContext } from "../../@Context/FormContext";
import EditPixelPercentage from "../Drawers/EditPixelPercentage";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      Active: item.active,
      "Product ID": item.product_id,
      "Product Name": item.product_description,
      "Tier Name": item.tier_name,
      AFID: item.affiliate_id,
      SID1: item.sid1,
      "Affiliate Name": item.affiliate_name,
      "Pay Percentage": item.percent_pay,
    };
  });
}

export type PixelPercentageType = {
  active: number[];
  affiliate_name: string;
  afid?: number;
  category_id?: number;
  percent_id?: number;
  percent_pay: number;
  product_description: string;
  product_id?: number;
  sid1: string;
  tier_name: string;
};

type PixelPercentageProps = {
  height?: number;
};

const PixelPercentage: React.FC<PixelPercentageProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [data, setData] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [active, setActive] = React.useState(-1);
  const activeList = [
    { value: -1, label: "All" },
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const categoryId = Admediary.category;
  const productId = Admediary.product;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const category = Admediary.category ? Admediary.category : "ALL";

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const callbackOnRemove = (percentId: number) => {
    setData(data.filter((record: any) => record.percent_id != percentId));
  };

  const callbackOnSave = (savedPercent: PixelPercentageType) => {
    if (
      data.findIndex(
        (record: any) => record.percent_id == savedPercent.percent_id
      ) < 0
    ) {
      setData([savedPercent, ...data]);
    } else {
      let newDataList = (data ?? []).map((record: any) => {
        if (record.percent_id == savedPercent.percent_id) {
          return { ...savedPercent };
        }
        return record;
      });
      setData(newDataList);
    }
  };

  const params = {
    refresh: refreshFlag,
    category_id: categoryId || 0,
    product_id: productId || 0,
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("pixel_percentages", params);

      if (active != -1) {
        setData(results.data.filter((record: any) => record.active == active));
      } else {
        setData(results.data);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [active, refreshFlag, categoryId, productId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddAction = () => {
    Forms.setCategory(0);
    Forms.setProduct(0);
    Forms.setDescription("");
  };

  const handleAddPercentageClick = () => {
    Admediary.openDrawer(
      <EditPixelPercentage callbackOnSave={callbackOnSave} />,
      "lg"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) =>
      (isNaN(v) ? v.toLowerCase() : v.toString()).includes(search);
    const fields = [
      "percent_id",
      "affiliate_name",
      "tier_name",
      "percent_pay",
      "affiliate_id",
      "product_description",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field] ?? ""))
    );
  };

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  return (
    <>
      <Title title="Pixel Percentage" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={2}>
          <InputGroup
            style={{ border: "0px", marginRight: 20, display: "inline-block" }}
          >
            <SelectPicker
              placeholder="active"
              searchable={false}
              cleanable={false}
              value={active}
              data={activeList}
              onChange={(v: number) => {
                setActive(v);
                refresh();
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={16}>
          <InputGroup
            style={{ width: 200, marginRight: 20, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <IconButton
            onClick={handleAddPercentageClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 10 }}
            disabled={Admediary.userDisable}
          >
            Add Percentage
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `pixel-percentage-report-${today.toDateString()}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <PixelPercentageList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        callbackOnSave={callbackOnSave}
        callbackOnRemove={callbackOnRemove}
      />
    </>
  );
};

export default PixelPercentage;

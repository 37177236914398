import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form } from "rsuite";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  field_id: NumberType(),
  category_id: NumberType(),
  field_name: StringType().isRequired("Field Name is required."),
  formula: StringType().isRequired("Formula is required."),
});

type BuyerContractCustomFieldFormType = {
  fieldId?: number;
  categoryId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractCustomFieldForm: React.FC<BuyerContractCustomFieldFormType> = ({
  fieldId = 0,
  categoryId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    field_id: 0,
    category_id: categoryId,
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    field_id: fieldId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.field_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_custom_field", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Field Name" name="field_name" />
      <Field
        label="Formula"
        name="formula"
        rows={8}
        componentClass="textarea"
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractCustomFieldForm;

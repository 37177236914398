import React from "react";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ContractCheckPicker from "../ContractCheckPicker";

const BuyerContracts: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const location = useLocation();
  const [showing, setShowing] = useState(false);
  const active = 1;
  const [contracts, setContracts] = useState();

  const params = {
    category_id: Admediary.category,
    buyer_id: Admediary.buyer,
    active,
  };

  React.useEffect(() => {
    location.pathname !== "/products/esign-doc"
      ? setShowing(false)
      : setShowing(true);
  }, [location]);

  const [data, isLoading] = useAdmediaryApi(
    "products_routing_contracts",
    params
  );

  const esignContracts = Array.isArray(data)
    ? data.filter((contract) =>
        contract.contract_name.toLowerCase().includes("e-sign".toLowerCase())
      )
    : [];

  /**
   * Proxy for capturing contracts and sending it up stream
   * @param data
   */
  const setContract = (data: any) => {
    setContracts(data);
    Admediary.setBuyerContract(data);
  };

  return (
    <>
      {showing ? (
        <ContractCheckPicker
          data={Array.isArray(esignContracts) ? esignContracts : []}
          change={setContract}
          value={contracts}
        />
      ) : null}
    </>
  );
};

export default BuyerContracts;

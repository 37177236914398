import React from "react";
import { Button, Icon, Drawer, Notification } from "rsuite";

import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import PingTree from "../Products/PingTree";
import { Query } from "../../@Utils/AdmediaryApi";

type AddPingTree = {
  fetchData: any;
  stateLoad: any;
};

const AddPingTree: React.FC<AddPingTree> = ({ fetchData }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };
  /**
   * submit
   *
   */
  const handleSave = () => {
    // @ts-ignore
    if (mainFormRef && mainFormRef.current && !mainFormRef.current.check()) {
      return;
    }

    const data = {
      // ...formValue,
      ...{
        tree_id: Forms.tree,
        category_id: Forms.category,
        product_id: Forms.product,
        description: Forms.description,
        active: Forms.active ? 1 : 0,
      },
    };
    (async () => {
      try {
        await Query("update_ping_tree", data);
        Notification.open({
          title: "Success",
          description: "Ping Tree  has been updated",
        });
        fetchData();
        Admediary.closeDrawer();
      } catch (e) {
        console.log("error", e);
      } finally {
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Add new Ping Tree
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <div>
          <PingTree formRef={mainFormRef} parentCallback={handleCallback} />
        </div>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSave} appearance="primary">
          Submit
        </Button>
        <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
          Cancel Button
        </Button>
      </Drawer.Footer>
    </>
  );
};

export default AddPingTree;

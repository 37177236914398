import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form } from "rsuite";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  affiliate_id: NumberType().isRequired("Affiliate ID is required."),
  affiliate_name: StringType(),
  address: StringType(),
  city: StringType(),
  state: StringType(),
  zip_code: StringType(),
});

type CommonAffiliateFormType = {
  affiliateId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const CommonAffiliateForm: React.FC<CommonAffiliateFormType> = ({
  affiliateId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    affiliate_id: 0,
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    affiliate_id: affiliateId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.affiliate_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("common_affiliate", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
      readOnly={true}
    >
      <Field label="ID" name="affiliate_id" />
      <Field label="Name" name="affiliate_name" />
      <Field label="Address" name="address" />
      <Field label="City" name="city" />
      <Field label="State" name="state" />
      <Field label="Zip" name="zip_code" />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default CommonAffiliateForm;

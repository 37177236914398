import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  SelectPicker,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import VoiceCampaignList from "./VoiceCampaignList";
import Title from "../Title";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import EditVoiceCampaign from "../Drawers/EditVoiceCampaign";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      Active: item.active,
      "Campaign ID": item.campaign_id,
      From: item.voice_from,
      "Transfer To": item.transfer_to,
      Description: item.description,
    };
  });
}

type VoiceCampaignProps = {
  height?: number;
};

const VoiceCampaign: React.FC<VoiceCampaignProps> = ({ height = 650 }) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [campaignId, setCampaignId] = React.useState(0);
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    campaign_id: campaignId,
    // active: active,
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApi("voice_campaign_select", params);
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["campaign_id", "description", "voice_from", "transfer_to"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "campaign_id") {
          return include(item[field].toString()); // convert campaign_id to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  const handleAddDataSource = () => {
    // formContext.setMonitor(false);
    formContext.setActive(false);
    // formContext.setCategory(0);
    // formContext.setClient(0);
    // formContext.setRow(0);
    // formContext.setCompany("");
    // formContext.revShare("");
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <EditVoiceCampaign campaignId={0} refreshData={refresh} />,
      "lg"
    );
  };
  return (
    <>
      <Title title="Voice Campaign Administration" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <IconButton
            onClick={handleAddDataSource}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={admediaryContext.userDisable}
          >
            Add Voice Campaign
          </IconButton>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `data_voice_campaign.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid colspan={19}></FlexboxGrid>
      <VoiceCampaignList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
    </>
  );
};

export default VoiceCampaign;

import React from "react";
import {
  Button,
  ControlLabel,
  DatePicker,
  FlexboxGrid,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  InputGroup,
  Loader,
  Modal,
  Table,
} from "rsuite";
import _ from "lodash";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import { FormInstance } from "rsuite/lib/Form/Form";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import SelectPicker from "rsuite/lib/SelectPicker";
import ScheduleForm from "./ScheduleForm";
import Schedule24x7Form, {
  getDefaultSchedule24x6FormData,
} from "../Data/Schedule24x7Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

const SchedulesField: React.FC<any> = ({
  name,
  label,
  onAfterSave,
  error,
  gridData,
  serviceId,
  message = null,
  buttonLabel = "Select",
  modalTitle = "Select Clients",
  ...props
}) => {
  const [data, setData] = React.useState(gridData);
  const [show, setShow] = React.useState(false);
  const [showAfterDelay, setShowAfterDelay] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState();
  const delayIntervalRef: any = React.useRef(null);
  const idKey = "schedule_id";
  const initChangedData: any[] = [];
  const [changedData, setChangedData] = React.useState(initChangedData);
  const Admediary = React.useContext(AdmediaryContext);

  // Set default values
  props.style = {
    ...{ width: 300 },
    ...props.style,
  };

  const handleButtonClick = () => {
    // Clone data
    const items: any = _.cloneDeep(gridData);

    setShow(true);
    setShowAfterDelay(true);
    setData(items);

    if (delayIntervalRef.current) {
      clearTimeout(delayIntervalRef.current);
    }
    delayIntervalRef.current = setTimeout(() => {
      setShowAfterDelay(false);
    }, 250);
  };

  const hideModal = () => {
    setShow(false);
  };

  const cancelModal = () => {
    hideModal();
  };

  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    // Don't show data to remove
    list = list.filter((item: any) => item.removing !== true);

    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleSaveClick = () => {
    setShow(false);

    if (typeof onAfterSave === "function") {
      onAfterSave(data);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem[idKey]);

    handleClose();
  };

  const removeItem = (id: number) => {
    const index = data.findIndex((item: any) => item[idKey] === id);

    // Set service ID to zero to remove it after saving of service
    data[index].removing = true;

    setData([...data]);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const initItem = {
    [idKey]: 0,
    service_id: serviceId,
    start_time: "00:00",
    end_time: "18:00",
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const mainFormRef = React.createRef<FormInstance>();

  const changeActiveItemStatus = (rowData: any, status: string | null) => {
    const nextData = Object.assign([], data);
    const activeItem: any = nextData.find(
      (item: any) => item[idKey] === rowData[idKey]
    );
    if (activeItem) {
      activeItem.status = status;
    }

    setData(nextData);
  };

  const handleAddItemClick = () => {
    setSelectedItem(initItem);

    handleEditModalOpen();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    changeActiveItemStatus(rowData, EDIT_STATUS);
  };

  const handleCloseActionClick = (rowData: any) => {
    setSelectedItem(null);

    // Replace changed data by original ones
    const index = changedData.findIndex(
      (item: any) => item[idKey] === rowData[idKey]
    );
    if (index > -1) {
      changedData[index] = { ...rowData };
      setChangedData(changedData);
    }

    changeActiveItemStatus(rowData, null);
  };

  const handleSaveActionClick = (rowData: any) => {
    setSelectedItem(null);

    const currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );
    if (currentItem) {
      saveItem(currentItem);
    }

    changeActiveItemStatus(rowData, null);
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleSaveItem = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    saveItem(_.cloneDeep(formValue));
  };

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const saveItem = (formValues: any) => {
    // Transform Date instance to an hours+minutes string
    formValues.start_time =
      typeof formValues.start_time == "string"
        ? formValues.start_time
        : format(formValues.start_time, "HH:mm");
    formValues.end_time =
      typeof formValues.end_time == "string"
        ? formValues.end_time
        : format(formValues.end_time, "HH:mm");

    if (formValues[idKey] === 0) {
      data.unshift(formValues);
    } else {
      const index = data.findIndex(
        (item: any) => item[idKey] === formValues[idKey]
      );
      data[index] = formValues;
    }

    handleEditModalClose();
  };

  const schedule24x7FormRef = React.createRef<FormInstance>();
  const [schedule24x7FormValue, setSchedule24x7FormValue] = React.useState({});
  const [schedule24x7ModalOpen, setSchedule24x7ModalOpen] = React.useState(
    false
  );
  const handleSchedule24x7ModalOpen = () => setSchedule24x7ModalOpen(true);
  const handleSchedule24x7ModalClose = () => setSchedule24x7ModalOpen(false);

  const handleSchedule24x7Click = () => {
    setSelectedItem({
      service_id: serviceId,
    });

    setSchedule24x7FormValue(getDefaultSchedule24x6FormData(serviceId));

    handleSchedule24x7ModalOpen();
  };

  const handleSchedule24x7ModalSave = () => {
    const node = schedule24x7FormRef && schedule24x7FormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const newData: any = _.cloneDeep(schedule24x7FormValue);

    // Transform Date instance to an hours+minutes string
    const start_time = format(newData.start_time, "HH:mm");
    const end_time = format(newData.end_time, "HH:mm");

    // Replace with data for each day
    setData([
      ...data
        .filter((item: any) => item.removing !== true)
        .map((item: any) => ({ ...item, removing: true, service_id: 0 })),
      ...newData.day_of_week.map((day: any) => ({
        day_of_week: day,
        service_id: newData.service_id,
        start_time,
        end_time,
      })),
    ]);
    setSchedule24x7ModalOpen(false);
  };

  const handleSchedule24x7Callback = (formValue: any) => {
    setSchedule24x7FormValue(formValue);
  };

  const handleChange = (rowData: any, key: string, value: any) => {
    let currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );

    if (!currentItem) {
      currentItem = data.find((item: any) => item[idKey] === rowData[idKey]);
      currentItem = { ...currentItem };
      changedData.push(currentItem);
    }

    if (currentItem) {
      currentItem[key] = value;
    }

    setChangedData(changedData);
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label}</ControlLabel>
      <InputGroup {...props} className="inner-button-field-group">
        <FormControl name={name} errorMessage={error} disabled />
        <InputGroup.Button
          onClick={handleButtonClick}
          disabled={Admediary.userDisable}
        >
          <Icon icon="calendar" />
        </InputGroup.Button>
      </InputGroup>

      <Modal size="sm" show={show} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: 400 }}>
            <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
              <FlexboxGrid.Item colspan={24} style={{ textAlign: "left" }}>
                <IconButton
                  onClick={handleAddItemClick}
                  icon={<Icon icon="plus-circle" />}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{ marginRight: 15 }}
                >
                  Add Schedule
                </IconButton>
                <IconButton
                  onClick={handleSchedule24x7Click}
                  icon={<Icon icon="calendar" />}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{ marginRight: 15 }}
                >
                  24x7
                </IconButton>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            {showAfterDelay ? (
              <Loader size="md" speed="slow" vertical center />
            ) : (
              <Table
                data={sortData(Array.isArray(data) ? data : [])}
                rowClassName="clickable-data striped-rows"
                sortColumn={sortColumn}
                sortType={sortType}
                shouldUpdateScroll={false}
                onSortColumn={handleSortColumn}
                autoHeight={true}
              >
                <Column width={100} align={"center"} fixed sortable>
                  <HeaderCell>Action</HeaderCell>
                  <ActionCell
                    editHandler={handleEditActionClick}
                    removeHandler={handleRemoveActionClick}
                    saveHandler={handleSaveActionClick}
                    closeHandler={handleCloseActionClick}
                  />
                </Column>
                <Column width={140} sortable>
                  <HeaderCell>Day of Week</HeaderCell>
                  <DayEditableCell
                    dataKey="day_of_week"
                    onChange={handleChange}
                  />
                </Column>
                <Column sortable>
                  <HeaderCell>Start Time</HeaderCell>
                  <TimeEditableCell
                    dataKey="start_time"
                    onChange={handleChange}
                  />
                </Column>
                <Column sortable>
                  <HeaderCell>End Time</HeaderCell>
                  <TimeEditableCell
                    dataKey="end_time"
                    onChange={handleChange}
                  />
                </Column>
              </Table>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={handleSaveClick}>
            Save
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Schedule?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem[idKey] > 0 ? "Edit" : "Add") +
          " Schedule"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSaveItem}
      >
        <ScheduleForm
          id={selectedItem ? selectedItem[idKey] : 0}
          data={selectedItem ? selectedItem : {}}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>

      <EditModal
        title="Edit Schedule 24x7"
        open={schedule24x7ModalOpen}
        size={"sm"}
        onClose={handleSchedule24x7ModalClose}
        onCancel={handleSchedule24x7ModalClose}
        onSubmit={handleSchedule24x7ModalSave}
      >
        <Schedule24x7Form
          serviceId={selectedItem ? selectedItem.service_id : 0}
          formRef={schedule24x7FormRef}
          parentCallback={handleSchedule24x7Callback}
        />
      </EditModal>
    </FormGroup>
  );
};

const EDIT_STATUS = "EDIT";

const getDayOfWeekByAbbreviation = (abbr: string) => {
  const days = new Map([
    ["mo", "Monday"],
    ["tu", "Tuesday"],
    ["we", "Wednesday"],
    ["th", "Thursday"],
    ["fr", "Friday"],
    ["sa", "Saturday"],
    ["su", "Sunday"],
  ]);

  return days.has(abbr) ? days.get(abbr) : null;
};

/**
 * Transform string 'HH:mm' to Date object to pass into the DatePicker component
 * @param time
 */
const transformTimeToDate = (time: string) => {
  const date = new Date();
  const [hours, minutes] = time.split(":");
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));

  return date;
};

const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  saveHandler,
  closeHandler,
  ...props
}: any) => {
  const trash = removeHandler instanceof Function && (
    <IconButton
      key="remove"
      appearance="subtle"
      onClick={(e) => removeHandler(rowData, e)}
      icon={<Icon icon="trash" />}
    />
  );

  return (
    <Cell {...props} className="link-group">
      {rowData.status === "EDIT"
        ? [
            <IconButton
              key="save"
              appearance="subtle"
              onClick={(e) => saveHandler(rowData, e)}
              icon={<Icon icon="check" />}
            />,
            <IconButton
              key="close"
              appearance="subtle"
              onClick={(e) => closeHandler(rowData, e)}
              icon={<Icon icon="close" />}
            />,
          ]
        : [
            <IconButton
              key="edit"
              appearance="subtle"
              onClick={(e) => editHandler(rowData, e)}
              icon={<Icon icon="edit2" />}
            />,
            trash,
          ]}
    </Cell>
  );
};

const DayEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <SelectPicker
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          searchable={false}
          cleanable={false}
          data={[
            { value: "mo", label: "Monday" },
            { value: "tu", label: "Tuesday" },
            { value: "we", label: "Wednesday" },
            { value: "th", label: "Thursday" },
            { value: "fr", label: "Friday" },
            { value: "sa", label: "Saturday" },
            { value: "su", label: "Sunday" },
          ]}
        />
      ) : (
        getDayOfWeekByAbbreviation(rowData[dataKey])
      )}
    </Cell>
  );
};

const TimeEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <DatePicker
          defaultValue={transformTimeToDate(rowData[dataKey])}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          format="HH:mm"
          ranges={[]}
          hideMinutes={(minute: number) => minute % 5 !== 0}
          cleanable={false}
        />
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
};

export default SchedulesField;

import React from "react";
import {
  Icon,
  IconButton,
  FlexboxGrid,
  Drawer,
  SelectPicker,
  CheckTreePicker,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";

import "react-pivottable/pivottable.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import TableRenderers from "react-pivottable/TableRenderers";
// import Plot from "react-plotly.js";
import Plot from "plotly.js-basic-dist";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";

import { PivotTableUIProps } from "react-pivottable";
import { PivotData } from "react-pivottable/Utilities";

type PivotTableProps = {
  data: any;
  graphKeys: Array<any>;
  groupBy: string;
};
type LabelMappingProps = {
  value: string;
  label: string;
};

//Fetch renderings for plotly graphs, to append to table renderers
const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivotTable: React.FC<PivotTableProps> = (props: any) => {
  const data = props.data;
  const Admediary = React.useContext(AdmediaryContext);

  const pivotTableConfigs = require("../../@Config/LeadDetail_PivotTableSettings.json");
  const labelColumnMapping: LabelMappingProps[] =
    pivotTableConfigs["column_label_mappings"];
  const leadDetailPresetPivots = pivotTableConfigs["available_presets"];

  /**
   *
   * @param col = key value in the report data
   * @param item = row from report
   * @returns = formatted version of column
   */
  const formatCustomCols = (col: string, item: any) => {
    let ret = "";
    let colMap = "";
    switch (col) {
      case "transaction_date":
      case "entry_date":
        if (item[col] == null) {
          ret = "null";
          break;
        }
        if (!item.hasOwnProperty(col)) return;
        ret =
          item[col].getFullYear() +
          "-" +
          (item[col].getMonth() + 1) +
          "-" +
          item[col].getDate();
        break;

      case "transaction_datetime":
      case "entry_datetime":
        colMap = col.replace("time", "");
        if (item[colMap] == null) {
          ret = "null";
          break;
        }
        if (!item.hasOwnProperty(colMap)) return;
        ret =
          item[colMap].getFullYear() +
          "-" +
          (item[colMap].getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          item[colMap].getDate().toString().padStart(2, "0") +
          " " +
          item[colMap].getHours().toString().padStart(2, "0") +
          ":" +
          item[colMap].getMinutes().toString().padStart(2, "0") +
          ":" +
          item[colMap].getSeconds().toString().padStart(2, "0");
        break;

      case "affiliate_display":
        ret = `(${item.affiliate_id}) ${item.affiliate_name}`;
        break;

      case "product_display":
        ret = `(${item.product_id}) ${item.product_description}`;
        break;

      case "buyer_display":
        if (isNaN(item.contract_id)) {
          ret = "Unsold - No Buyer";
        } else {
          ret = `(${item.buyer_id}) ${item.buyers}`;
        }
        break;

      case "contract_display":
        if (isNaN(item.contract_id)) {
          ret = "Unsold - No Buyer";
        } else {
          ret = `(${item.contract_id}) ${item.contract_name}`;
        }
        break;

      case "select_issue":
        if (item.issue_caraccident == 1) {
          ret = "Car Accident";
        } else if (item.issue_mcaccident == 1) {
          ret = "Motorcycle Accident";
        } else if (item.issue_truckaccident == 1) {
          ret = "Truck Accident";
        } else if (item.issue_medmalpractice == 1) {
          ret = "Medical Malpractice";
        } else if (item.issue_workrelated == 1) {
          ret = "Work Related";
        } else if (item.issue_slip == 1) {
          ret = "Slips and Falls";
        } else if (item.issue_product == 1) {
          ret = "Product Related";
        } else if (item.issue_adhesions == 1) {
          ret = "Adhesions";
        } else if (item.issue_bowel_blockage == 1) {
          ret = "Bowel Blockage/Partial Removal";
        } else if (item.issue_erosions == 1) {
          ret = "Erosions";
        } else if (item.issue_hernia_returned == 1) {
          ret = "Hernia Returned/Reopened (Recurrence)";
        } else if (item.issue_infections == 1) {
          ret = "Infections";
        } else if (item.issue_migration_moved == 1) {
          ret = "Migration (Moved)/Shrinkage";
        } else if (item.issue_organ_perforation == 1) {
          ret = "Organ Perforation";
        } else if (item.issue_abdominal_pain == 1) {
          ret = "Severe Abdominal Pain";
        } else if (item.issue_other == 1) {
          ret = "Other";
        } else {
          return null;
        }
        break;

      default:
        ret = item[col];
        break;
    }
    return ret;
  };

  /**
   * @returns format data from report and add extra columns for readability
   */
  const getPivotTableData = () => {
    return data.map((row: any) => {
      var ret: any = {};

      labelColumnMapping.forEach((labelMapping: any) => {
        let colValue = formatCustomCols(labelMapping.value, row); //Some columns need custom formatting such as date/time fields
        if (typeof colValue != "undefined") {
          ret[labelMapping.label] = colValue;
        }
      });
      var strippedRow: any = {};
      for (let col in row) {
        if (
          labelColumnMapping.find((mapping: any) => mapping.value == col) ===
          undefined
        ) {
          strippedRow[col] = row[col];
        }
      }
      return Object.assign(strippedRow, ret);
    });
  };

  /**
   * @returns <LabelMappingProps> list of all the possible fields to view in the pivot table
   * Merges label mappings from the json data with available data
   */
  const getColumnList = () => {
    var columnList: any = {};

    getPivotTableData().forEach((row: any) => {
      for (let key in row) {
        let labelMapping = labelColumnMapping.find(
          (mapping: any) => mapping.label == key
        );
        if (!columnList.hasOwnProperty(key)) {
          columnList[key] = labelMapping ?? { value: key, label: key };
        }
      }
    });
    return Object.entries(columnList).map((col: any) => {
      return col[1];
    });
  };

  /**
   * @returns The columns in the dataset grouped together by similarity, for use with the CheckTreePicker data struct
   */
  const getGroupedColumnList = () => {
    let returnList = [];
    let columnList: string[] = getColumnList();
    let columnGroups = pivotTableConfigs["column_groups"];
    let miscGroupIndex = 0; //Allow Miscellaneous group to be added to
    //Array of all the keys to remove from column list, for use with miscellaneous group
    let columnListIntersection: number[] = [];

    for (let groupKey in columnGroups) {
      let newGroup = {
        value: groupKey,
        label: groupKey,
        children: columnList
          .filter((col: any, index: number) => {
            if (columnGroups[groupKey].includes(col.value)) {
              columnListIntersection.push(index); //Need a list of all the already mapped columns
              return true;
            }
          })
          .sort(
            (a: any, b: any) =>
              columnGroups[groupKey].indexOf(a.value) -
              columnGroups[groupKey].indexOf(b.value)
          ), //Sort grouping based on order in the json settings
      };

      if (groupKey == "Miscellaneous") {
        miscGroupIndex = returnList.length; //record misc group index to use later
      }
      returnList.push(newGroup);
    }

    //Get the remaining ungrouped columns
    columnList = columnList.filter(
      (col: any, index: number) => !columnListIntersection.includes(index)
    );

    //Append all remaining unknown columns into Miscellaneous
    //Needs to happen after the group iteration
    returnList[miscGroupIndex]["children"] = returnList[miscGroupIndex][
      "children"
    ].concat(columnList);

    // Remove empty groups
    return returnList.filter((group: any) => group.children.length);
  };

  /**
   * Need an inverted list of hidden fields for PivotTableUI
   * @returns list of fields that are available but not currently selected
   */
  const invertAvailableColumnsList = (list: string[]) => {
    return getColumnList().reduce((result: string[], col: any) => {
      if (!list.includes(col.value)) {
        result.push(col.label);
      }
      // console.log(result);
      return result;
    }, []);
  };

  /**Intersect available dataset with default column list */
  const getAvailableColumns = () => {
    let columnList = getColumnList();
    return pivotTableConfigs["default_available_columns"].filter(
      (field: string) =>
        columnList.findIndex((col: any) => col.value == field) >= 0
    );
  };
  const [availableColumns, setAvailableColumns] = React.useState<string[]>(
    getAvailableColumns()
  );

  // Find a group name and include it in the value list  if this group selected completely,
  // that is all children are selected
  const getGroupedAvailableColumns = (availableColumns: string[]) => {
    // Check if array is a sub-array of another array
    const checkSubset = (parentArray: any[], subsetArray: string[]) => {
      return subsetArray.every((el) => {
        return parentArray.includes(el);
      });
    };

    const columnGroups = { ...pivotTableConfigs.column_groups };

    // Check if a Group selected completely
    const selectedGroups = Object.keys(
      columnGroups
    ).filter((groupKey: string) =>
      checkSubset(availableColumns, columnGroups[groupKey])
    );

    return [...availableColumns, ...selectedGroups];
  };
  // We should use a group name in the value list for CheckTreePicker if this group selected completely
  const [groupedAvailableColumns, setGroupedAvailableColumns] = React.useState<
    string[]
  >(getGroupedAvailableColumns(availableColumns));

  /**
   * Need to set both the pivot table state and column list at once
   * @param list new list of available fields in PivotTable
   */
  const setAvailableColumnList = (list: string[]) => {
    //CheckTreePicker provides value list with only top level of tree
    //This section will replace those top levels with the children values that need to be used on the pivot table
    let columnGroups = pivotTableConfigs["column_groups"];
    for (let groupKey in columnGroups) {
      let index = list.findIndex((col: string) => col == groupKey);
      if (index >= 0) {
        list.splice(index, 0, ...columnGroups[groupKey]);
      }
    }

    setAvailableColumns(list);

    setPivotTableState({
      ...pivotTableState,
      hiddenFromDragDrop: invertAvailableColumnsList(list),
    });
    // console.log("Set Available");
  };

  const [pivotTableState, setPivotTableState] = React.useState<
    PivotTableUIProps
  >({
    data: getPivotTableData(),
    renderers: Object.assign({}, TableRenderers, PlotlyRenderers),
    hiddenFromDragDrop: invertAvailableColumnsList(availableColumns),
    vals: ["Lead Instance ID"],
    onChange: (s: PivotTableUIProps) => setPivotTableState(s),
    //Need to set up this field with spread operator, since Utilities typedef spells it wrong and getting a typescript error
    ...{ aggregatorName: "Count Unique Values" },
  });

  const exportCSV = () => {
    if (pivotTableState.rows !== undefined) {
      var pivotData = new PivotData(pivotTableState);
      var rowKeys = pivotData.getRowKeys();
      var colKeys = pivotData.getColKeys();
      if (rowKeys.length === 0) {
        rowKeys.push([]);
      }
      if (colKeys.length === 0) {
        colKeys.push([]);
      }

      var headerRow = pivotTableState.rows.map(function (r: any) {
        return r;
      });
      if (colKeys.length === 1 && colKeys[0].length === 0) {
        headerRow.push(pivotTableState.agregatorName ?? "Count");
      } else {
        colKeys.map(function (c: any[]) {
          return headerRow.push(c.join("-"));
        });
      }

      var result = rowKeys.map(function (r: any) {
        var row = r.map(function (x: string) {
          return x;
        });
        colKeys.map(function (c: string) {
          var v = pivotData.getAggregator(r, c).value();
          row.push(v ? v : "");
        });
        return row;
      });

      result.unshift(headerRow);

      var FinalResult = result
        .map(function (r: string[]) {
          let rowArr = r.map((j: string) => {
            if (typeof j == "string") {
              return j.replace(/\"/g, '""');
            }
            return j;
          });
          return '"' + rowArr.join('","') + '"';
        })
        .join("\n");

      const element = document.createElement("a");
      const file = new Blob([FinalResult], { type: "text/plain" });
      element.href = URL.createObjectURL(file);

      var date = new Date();
      var dateString =
        date.getFullYear() + "_" + (date.getMonth() + 1) + "_" + date.getDate();
      var pivotRows = pivotTableState.rows.join("_") ?? "";
      var pivotCols = pivotTableState.cols?.join("_") ?? "";
      var pivotVals = pivotTableState.vals?.join("_") ?? "";
      var aggregator = pivotTableState.agregatorName ?? "";

      element.download =
        "pivot_" +
        pivotVals +
        "_" +
        aggregator +
        "_" +
        pivotRows +
        "_" +
        pivotCols +
        "_" +
        dateString +
        ".csv";
      element.download = element.download.toLowerCase();
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Pivot
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
        <FlexboxGrid justify="start" style={{ marginTop: 20 }}>
          <FlexboxGridItem
            colspan={2}
            style={{
              textAlign: "right",
              marginTop: "0.4em",
              marginRight: "0.4em",
              fontWeight: "bold",
            }}
          >
            <label>Avail Cols:</label>
          </FlexboxGridItem>
          <FlexboxGridItem colspan={6}>
            <CheckTreePicker
              size="md"
              searchable={true}
              cleanable={false}
              value={groupedAvailableColumns}
              data={getGroupedColumnList()}
              onChange={(v) => {
                setGroupedAvailableColumns(v);
                setAvailableColumnList(v);
              }}
            />
          </FlexboxGridItem>
          <FlexboxGridItem colspan={2}></FlexboxGridItem>
          <FlexboxGridItem colspan={6}>
            <SelectPicker
              size="md"
              placeholder="Preset Pivot Views"
              data={leadDetailPresetPivots}
              onChange={(v) => {
                setPivotTableState({ ...pivotTableState, ...v });
              }}
              searchable={true}
              cleanable={false}
              value={null}
              style={{ marginRight: 15 }}
            />
          </FlexboxGridItem>
          <FlexboxGridItem colspan={2}></FlexboxGridItem>
          <FlexboxGridItem colspan={5} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() => exportCSV()}
              icon={<Icon icon="file-download" />}
              placement="right"
              size="md"
            >
              Export
            </IconButton>
          </FlexboxGridItem>
        </FlexboxGrid>
      </Drawer.Header>
      <Drawer.Body>
        <PivotTableUI {...pivotTableState} />
      </Drawer.Body>
    </>
  );
};

export default PivotTable;

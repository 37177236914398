import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import PerformanceList from "./PerformanceList";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";
import axios from "axios";

const PerformanceReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultReportViewValue = "accept_rate";
  const [reportView, setReportView] = React.useState(defaultReportViewValue);
  const defaultSortColumn =
    reportView === "accept_rate" ? "rejected" : "post_count";
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState(defaultSortColumn);
  const startDate = Admediary.start;
  const endDate = Admediary.end;
  const categoryId = parseInt(Admediary.category || 0);
  const productId = parseInt(Admediary.product || 0);
  const buyerId = parseInt(Admediary.buyer || 0);
  const defaultActivity = 1;
  const [activity, setActivity] = React.useState(defaultActivity);

  const params = {
    start_date: startDate,
    end_date: endDate,
    category_id: categoryId,
    product_id: productId,
    buyer_id: buyerId,
    activity,
  };

  // const [data, isLoading] = useAdmediaryApi(
  //   "buyer_contract_performance",
  //   params
  // );
  const [data, isLoading] = useAdmediaryApiManual(
    "buyer_contract_performance",
    params
  );

  const formatData = (list: any) => {
    list.pinged = isNaN(parseFloat(list.pinged)) ? 0 : parseFloat(list.pinged);
    list.posted = isNaN(parseFloat(list.posted)) ? 0 : parseFloat(list.posted);
    list.accepted = isNaN(parseFloat(list.accepted))
      ? 0
      : parseFloat(list.accepted);
    return {
      ...list,
      ...{
        filter_percentage: list.filter_percentage * 100,
        accept_percentage: list.accept_percentage * 100,
        posted_accept_percentage:
          list.posted !== 0 ? (list.accepted / list.posted) * 100 : 0,
        priority: !isNaN(list.priority) ? list.priority : null,
      },
    };
  };

  function addCalculatedValuesRecursively(list: any) {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = addCalculatedValuesRecursively(row.children);
      }

      return formatData(row);
    });
  }

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const getDateColumns = (data: any[], start: Date, end: Date) => {
    const current = new Date(startDate.getTime()); // clone date
    const dates: any = [];

    // TODO Rewrite next IF to generate dates in better way
    if (
      Array.isArray(data) &&
      data.length &&
      data[0].volume &&
      Array.isArray(data[0].volume)
    ) {
      data[0].volume.forEach((volumeItem: any) => {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        current.setDate(current.getDate() + 1);
      });
    } else {
      do {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        current.setDate(current.getDate() + 1);
      } while (current <= end);
    }

    return dates.map((current: Date) => {
      const currentDay = current.getDate();
      const currentMonth = current.getMonth() + 1;
      const dataIndexPrefix = `_${currentMonth}_${currentDay}`;
      const dataKey =
        reportView === "accept_rate" ? "accept_percentage" : "average_post";

      return {
        headerTitle: `${currentMonth}/${currentDay}`,
        dataKey: `${dataIndexPrefix}_${dataKey}`,
        keyPrefix: dataIndexPrefix,
        fullDate: current,
      };
    });
  };

  const getExportData = (data: any) => {
    const dateColumns = getDateColumns(data, startDate, endDate);

    // Clean, format and reorder by fields with right headers
    if (reportView === "accept_rate") {
      return data.map((item: any) => {
        const row: any = {
          Buyer: item.buyer_name,
          "Contract ID": item.contract_id,
          Contract: item.contract_name,
          Accepted: item.accepted,
          Rejected: item.rejected,
          Pinged: item.pinged,
          Posted: item.posted,
          Filtered: item.filtered,
          "Gross Accept %": item.accept_percentage,
          "Accept %":
            item.posted !== 0 ? (item.accepted / item.posted) * 100 : 0,
          Priority: item.priority,
        };

        // Add date columns
        dateColumns.forEach((column: any) => {
          row[column.headerTitle] = item[column.dataKey];
        });

        return row;
      });
    } else if (reportView === "response_time") {
      return data.map((item: any) => {
        const row: any = {
          Buyer: item.buyer_name,
          "Contract ID": item.contract_id,
          Contract: item.contract_name,
          "Post Count": item.post_count,
          Average: item.average_post,
        };

        // Add date columns
        dateColumns.forEach((column: any) => {
          row[column.headerTitle] = item[column.dataKey];
        });

        return row;
      });
    }
  };

  // TODO Rewrite it using general helper function
  // Getting data for drop downs
  const API = axios.create({});
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  if (!isLoading && data && Array.isArray(data) && data.length) {
    data.forEach(function (serviceData, index) {
      const current = new Date(startDate.getTime()); // clone date
      const additionalIndices = [
        "total_count",
        "filtered",
        "accepted",
        "rejected",
        "pinged",
        "posted",
        "filter_percentage",
        "accept_percentage",
        "priority",
        "posted_accept_percentage",
        "average_post",
        "min_post",
        "max_post",
        "stddev_post",
      ];

      serviceData.volume.forEach(function (groupedData: any) {
        const currentDay = current.getDate();
        const currentMonth = current.getMonth() + 1;
        const dataIndexPrefix = `_${currentMonth}_${currentDay}`;

        // Add current volume data to grid data
        additionalIndices.forEach(function (index) {
          const origValue = Number(groupedData[index]);
          const value = ["filter_percentage", "accept_percentage"].includes(
            index
          )
            ? origValue * 100
            : origValue;

          serviceData[dataIndexPrefix + "_" + index] = value;
        });

        // Increase current date by date range
        current.setDate(currentDay + 1);
      });

      //delete serviceData["volume"];
      data[index] = serviceData;
    });
  }

  const exportFileNameParts = [
    "buyer_contract_performance",
    reportView,
    format(new Date(startDate), "MMddyy"),
    format(new Date(endDate), "MMddyy"),
    `CAT-${categoryId}`,
    `PRODUCT-${productId}`,
    `BUYER-${buyerId}`,
  ];
  const exportFileName = exportFileNameParts.join("_") + ".csv";

  return (
    <>
      <Title title="Buyers Contract Performance" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            placeholder="Select Report View"
            searchable={false}
            cleanable={false}
            style={{ marginTop: 4, marginRight: 15 }}
            defaultValue={defaultReportViewValue}
            value={reportView}
            data={[
              { value: "accept_rate", label: "Accept Rate" },
              { value: "response_time", label: "Response Time" },
            ]}
            onChange={(v) => {
              setReportView(v);
              setSortColumn(v === "accept_rate" ? "rejected" : "post_count");
            }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={false}
            style={{ marginRight: 15 }}
            value={activity}
            data={[
              { value: 1, label: "Contracts with Activity" },
              { value: null, label: "All Contracts" },
            ]}
            onChange={(v) => setActivity(v)}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            size="sm"
            placement="right"
            icon={<Icon icon="file-download" />}
            onClick={() =>
              exportCsv(
                exportFileName,
                getExportData(Array.isArray(data) ? data : [])
              )
            }
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <PerformanceList
        data={
          !isLoading && data !== null
            ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
            : []
        }
        dateColumns={getDateColumns(
          Array.isArray(data) ? data : [],
          startDate,
          endDate
        )}
        startDate={startDate}
        reportView={reportView}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default PerformanceReport;

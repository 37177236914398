import React, { SyntheticEvent } from "react";
import { Table } from "rsuite";
import { EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import EditProductCategory from "../Drawers/EditProductCategory";

const { Column, HeaderCell, Cell } = Table;

type ProductsCategoriesTableProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
};

const ProductsCategoriesTable: React.FC<ProductsCategoriesTableProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  sortType = "asc",
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    admediaryContext.openDrawer(
      <EditProductCategory
        category_id={rowData.category_id}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{},
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sortColumn && sortType ? sort(list, sortColumn, sortType) : list
    );
  };

  return (
    <>
      <Table
        virtualized
        autoHeight={true}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
        defaultSortType={"asc"}
      >
        <Column align="center" fixed sortable resizable>
          <HeaderCell>Category ID</HeaderCell>
          <Cell dataKey="category_id" />
        </Column>

        <Column width={200} align="left" fixed sortable resizable>
          <HeaderCell>Category Name</HeaderCell>
          <Cell dataKey="category_name" />
        </Column>

        <Column width={100} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell editHandler={handleEditActionClick} />
        </Column>
      </Table>
    </>
  );
};

export default ProductsCategoriesTable;

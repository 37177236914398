import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format } from "date-fns";
import { exportCsv } from "../../@Utils/Export";

const { Column, HeaderCell, Cell } = Table;

type ViewLeads = {
  row_data: any;
};

const PostedLeads: React.FC<ViewLeads> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: row_data.category_id,
    product_id: Admediary.product,
    contract_id: Admediary.contract,
    buyer_id: Admediary.buyer,
    affiliate_id: Admediary.affiliate,
  };

  const [data, isLoading] = useAdmediaryApi("post_lead_detail_report", params);

  const formatPrice = (price: any) => {
    return parseFloat(price).toFixed(2);
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{ paid: formatPrice(item.paid) },
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          View Leads
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `posted_leads_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  data
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          height={650}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>
              <span>ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead Instance ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="lead_instance_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Affliate ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Affiliate ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="affiliate_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Campaign ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Campaign ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="campaign_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Campaign Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Campaign Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="campaign_name" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Rate</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Rate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="rate" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Paid</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Paid Amount</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="paid" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Email</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Email Address</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Sub ID 1</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Sub ID 1</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="subid1" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Sub ID 2</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Sub ID 2</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="subid2" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Sub ID 3</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Sub ID 3</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="subid3" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Source URL</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Source URL</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="source_url" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Response Time</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Response Time</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="response_time" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Redirect Time</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Redirect Time</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="redirect_time" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Test Mode</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Test Mode</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="test_mode" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Offer ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Offer ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="offer_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Offer Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Offer Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="offer_name" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Errors</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Errors</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="errors" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Filters</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Filters</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="filters" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Entry Date</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Entry Date</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => (
                <span>{format(rowData.entry_date, "Y-MM-dd HH:mm")}</span>
              )}
            </Cell>
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>First Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead First Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => {
                let firstName = "";
                const splitData = rowData.request.split("|");
                splitData.forEach((item: any) => {
                  const equalSplit = item.split("=");
                  if (equalSplit[0] === "fname") {
                    firstName = equalSplit[1];
                  }
                });
                return firstName;
              }}
            </Cell>
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Last Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Last Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => {
                let lastName = "";
                const splitData = rowData.request.split("|");
                splitData.forEach((item: any) => {
                  const equalSplit = item.split("=");
                  if (equalSplit[0] === "lname") {
                    lastName = equalSplit[1];
                  }
                });
                return lastName;
              }}
            </Cell>
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Home Phone</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Home Phone Number</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => {
                let phoneNum = "";
                const splitData = rowData.request.split("|");
                splitData.forEach((item: any) => {
                  const equalSplit = item.split("=");
                  if (equalSplit[0] === "homephone") {
                    phoneNum = equalSplit[1];
                  }
                });
                return phoneNum;
              }}
            </Cell>
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Zip</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Posted Lead Home Zip Code</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => {
                let zip = "";
                const splitData = rowData.request.split("|");
                splitData.forEach((item: any) => {
                  const equalSplit = item.split("=");
                  if (equalSplit[0] === "zip") {
                    zip = equalSplit[1];
                  }
                });
                return zip;
              }}
            </Cell>
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default PostedLeads;

import React from "react";
import Dashboard from "../../@Components/Dashboard";
import { Col, Content, Grid, Row } from "rsuite";
import SmsReportData from "../../@Components/Campaigns/SmsReportData";

const SmsReport: React.FC = () => {
  return (
    <Dashboard>
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content style={{ height: "fit-content" }}>
              <SmsReportData />
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default SmsReport;

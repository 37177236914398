import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { parseISO, formatDistance, format } from "date-fns";
import { exportCsv } from "../../@Utils/Export";
import { CurrencyCell } from "../Table";

const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type ViewBuyHoldLeadsProps = {
  row_data: any;
  group: string;
};

const ViewBuyHoldLeads: React.FC<ViewBuyHoldLeadsProps> = ({
  row_data,
  group,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const bh_contract_id =
    row_data.bh_contract_id ||
    getFieldValueOrParentValue(row_data, "bh_contract_id") ||
    0;
  const product_id =
    row_data.product_id ||
    getFieldValueOrParentValue(row_data, "product_id") ||
    Admediary.product ||
    0;

  const params = {
    start_date,
    end_date,
    product_id,
    contract_id: bh_contract_id,
    group,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          time_distance: formatDistance(item.entry_date, new Date()),
        },
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const formatDataForExporting = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: format(item.entry_date, "y-MM-dd HH:mm"),
        },
      };
    });
  };

  const [data, isLoading] = useAdmediaryApi("buyhold_leads", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          View Leads
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `buyhold_leads_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  formatDataForExporting(data)
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          height={650}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>
              <span>ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead Instance ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="lead_instance_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Date</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Date lead posted</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="time_distance" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Buyer</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Buyer's Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="buyer_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Affiliate</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of Affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="affiliate_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Product</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of the Product</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="product_description" />
          </Column>

          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Email</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Email address of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>First Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's first name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="first_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Last Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's last name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="last_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Phone</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Phone # of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="phone" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>Session</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Request Session ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="request_session_id" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Revenue</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Disposition Reason</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <CurrencyCell dataKey="revenue" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Cost</span>
              <Whisper trigger="hover" speaker={<Tooltip>Cost</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <CurrencyCell dataKey="cost" />
          </Column>

          <Column align="right" sortable resizable width={135}>
            <HeaderCell>
              <span>IP</span>
              <Whisper
                trigger="hover"
                speaker={
                  <Tooltip>
                    IP address of customer's computer at submission
                  </Tooltip>
                }
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="client_ip" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID1</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 1 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid1" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID2</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 2 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid2" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID3</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 3 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid3" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default ViewBuyHoldLeads;

import React, { useState } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { percent } from "../../@Utils/Format";
import { format } from "date-fns";
import Title from "../Title";
import SMSDeliveryTable from "./SMSDeliveryTable";

const addCalculatedValues = (row: any) => {
  row.sent = isNaN(parseFloat(row.sent)) ? 0 : parseFloat(row.sent);
  row.queued = isNaN(parseFloat(row.queued)) ? 0 : parseFloat(row.queued);
  row.undelivered = isNaN(parseFloat(row.undelivered))
    ? 0
    : parseFloat(row.undelivered);

  return {
    ...row,
    ...{
      deliveredPercent: row.sent !== 0 ? (row.delivered / row.sent) * 100 : 0,
      queuedPercent: row.sent !== 0 ? (row.queued / row.sent) * 100 : 0,
      undeliveredPercent:
        row.sent !== 0 ? (row.undelivered / row.sent) * 100 : 0,
      carrier: row.creative_id || row.campaign_id ? (row.carrier = "ALL") : "",
    },
  };
};

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = ["sent", "delivered", "queued", "undelivered"];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      const value = parseFloat(item[field]);

      return isNaN(value) ? 0 : value;
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

const getExportData = (data: any) => {
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        //add parent values
        ...{
          campaign_id: item.campaign_id || parent.campaign_id,
          creative_id: item.creative_id || parent.creative_id,
          carrier_id: item.carrier_id || parent.carrier_id,
          carrier: item.carrier || parent.carrier,
          message: item.message || parent.message,
          from: item.phone_number || parent.phone_number,
        },
        ...item,
      };
      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Campaign ID": item.campaign_id,
          "Creative ID": item.creative_id,
          Carrier: item.carrier,
          Description: item.description,
          From: item.phone_number,
          Message: item.message,
          Sent: item.sent,
          Delivered: item.delivered,
          "Delivered %": percent(parseFloat(item.deliveredPercent)),
          Queued: item.queued,
          "Queued %": percent(parseFloat(item.queuedPercent)),
          Undelivered: item.undelivered,
          "Undelivered %": percent(parseFloat(item.undeliveredPercent)),
        };
      })
  );
};

const SMSDeliverySummary: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [checked] = useState(false);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: Admediary.buyer,
    affiliate_id: 0,
    sid1: "",
    sid2: "",
    detail_by: "disposition",
    data_type: "date_sold",
  };

  const [data, isLoading] = useAdmediaryApiManual("sms_delivery", params);
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="SMS Delivery Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={23} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `sms_delivery_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <SMSDeliveryTable
        data={treeData}
        summary={summaryData}
        checked={checked}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default SMSDeliverySummary;

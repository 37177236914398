import React, { useState } from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "rsuite";

import RecordSearchTable from "./RecordSearchTable";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";
import Title from "../Title";

function getExportData(data: any) {
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];
    list.forEach((item: any) => {
      parent = parent || item;
      const current = {
        ...item,
      };
      result.push(current);
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Lead Instance ID": item.lead_instance_id,
          "Lead Date": item.entry_date.toString(),
          "Product ID": item.product_id,
          Email: item.email,
          Phone: item.phone,
          AFID: item.affiliate_id,
          "Affiliate Name": item.affiliate_name,
          SID1: item.sid1,
          "Client IP": item.client_ip,
          "Trusted Form URL": item.trusted_form_url,
          "TSource URL": item.source_url,
        };
      })
  );
}

const RecordSearchReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    email: email,
    phone: phone,
  };

  const onSubmit = (): void => {
    (async () => {
      try {
        setIsLoading(true);
        const results: any = await Query("search", params);
        setData(results.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const exportData = getExportData(data);
  return (
    <>
      <Title title="Record Search" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <Form onSubmit={onSubmit} layout="inline">
            <FormGroup>
              <ControlLabel>Email</ControlLabel>
              <FormControl
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={setEmail}
                onKeyUp={onKeyUpEnter}
              />
              {/*<HelpBlock>Required</HelpBlock>*/}
            </FormGroup>
            <FormGroup>
              <ControlLabel>Phone</ControlLabel>
              <FormControl
                name="phone"
                type="phone"
                placeholder="Phone"
                value={phone}
                onChange={setPhone}
                onKeyUp={onKeyUpEnter}
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={onSubmit} appearance="primary">
                {" "}
                Search
              </Button>
            </FormGroup>
          </Form>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `record_search_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <RecordSearchTable data={data} isLoading={isLoading === true} />
    </>
  );
};

export default RecordSearchReport;

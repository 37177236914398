import React from "react";
import { Query } from "../../../@Utils/AdmediaryApi";
import { Loader, Schema, Form, Checkbox, CheckboxGroup } from "rsuite";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import { FormContext } from "../../../@Context/FormContext";
import Field from "../../Field";

const { StringType, ArrayType } = Schema.Types;

const model = Schema.Model({
  category_name: StringType(),
  enable_filters: ArrayType(),
  show_in_reports: ArrayType(),
});

type ProductCategoryFormType = {
  category_id?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const ProductCategoryForm: React.FC<ProductCategoryFormType> = ({
  category_id = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyCategory = {
    category_id: 0,
    category_name: "",
    enable_filters: [],
    show_in_reports: [],
  };
  const [formValue, setFormValue] = React.useState(emptyCategory);
  const [formError, setFormError] = React.useState({});

  const params = {
    category_id: category_id,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.category_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("product_category_fields", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.enable_filters)) {
      record.enable_filters = record.enable_filters === 1 ? [1] : [];
    }

    if (!Array.isArray(record.show_in_reports)) {
      record.show_in_reports =
        parseInt(record.show_in_reports) === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    Forms.setDefaultAllow(
      data.default_allow !== undefined ? data.default_allow : null
    );

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label="Category Name"
        name="category_name"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Enable Filters"
        name="enable_filters"
        accepter={CheckboxGroup}
      >
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field
        label="Show In Reports"
        name="show_in_reports"
        accepter={CheckboxGroup}
      >
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
    </Form>
  );
};

export default ProductCategoryForm;

import React from "react";
import { Drawer, Icon } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format, parseISO } from "date-fns";
import ServiceResponseTimes from "../Responses/ServiceResponseTimes";

type ServiceResponseTime = {
  row_data: any;
  start: any;
  end: any;
};

const ServiceResponseTime: React.FC<ServiceResponseTime> = ({
  row_data,
  start,
  end,
}) => {
  const Admediary = React.useContext(AdmediaryContext);

  const start_date = start ? start : Admediary.start;
  const end_date = end ? end : Admediary.end;

  const startFormatted = format(start_date, "MM/dd/yyyy");
  const endFormatted = format(end_date, "MM/dd/yyyy");

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {`Response Time - ${row_data.service_name}  [${startFormatted} -  ${endFormatted}]`}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <ServiceResponseTimes
          startDate={start}
          endDate={end}
          serviceId={row_data.service_id}
        />
      </Drawer.Body>
    </>
  );
};

export default ServiceResponseTime;

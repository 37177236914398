import React from "react";
import { SyntheticEvent } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { Table } from "rsuite";
import { Icon } from "rsuite";
import { sortTree } from "../../@Utils/Sorting";
import {
  HeaderSummary,
  CurrencyCell,
  NumberCell,
  PercentCell,
  ParentCell,
} from "../Table";
import { currency, percent } from "../../@Utils/Format";

const { Column, Cell, HeaderCell } = Table;
const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  cursor: "move",
};

const ItemTypes = {
  COLUMN: "column",
  ROW: "row",
};
const DraggableHeaderCell = ({
  children,
  onDrag,
  id,
  title,
  summary,
  order,
  ...rest
}: any) => {
  const ref = React.useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any, monitor) {
      onDrag(item.id, id);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id, type: ItemTypes.COLUMN },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    ...style,
    opacity: isDragging ? 0 : 1,
    background: isActive ? "#ddd" : null,
  };

  return (
    <HeaderCell {...rest} style={{ padding: 0, marginTop: -10 }}>
      <div className="column_table" ref={ref}>
        {children}
      </div>
      {id === "impression_count" ? (
        <HeaderSummary style={styles} summary={summary.impression_count} />
      ) : id === "impression_unique_count" ? (
        <HeaderSummary summary={summary.impression_unique_count} />
      ) : id === "form_submit_count" && order !== "offer_site" ? (
        <HeaderSummary summary={summary.form_submit_count} />
      ) : id === "submit_percent" && order !== "offer_site" ? (
        <HeaderSummary summary={percent(summary.submit_percent, 2)} />
      ) : id === "conversion_count" ? (
        <HeaderSummary summary={summary.conversion_count} />
      ) : id === "conversion_percent" ? (
        <HeaderSummary summary={percent(summary.conversion_percent, 2)} />
      ) : id === "accept_percent" && order !== "offer_site" ? (
        <HeaderSummary summary={percent(summary.accept_percent, 2)} />
      ) : id === "cost" ? (
        <HeaderSummary summary={currency(summary.cost)} />
      ) : id === "cpl" ? (
        <HeaderSummary summary={currency(summary.cpl)} />
      ) : id === "cpi" ? (
        <HeaderSummary summary={currency(summary.cpi)} />
      ) : id === "click_count" ? (
        <HeaderSummary summary={summary.click_count} />
      ) : id === "revenue" ? (
        <HeaderSummary summary={currency(summary.revenue)} />
      ) : id === "cpm" ? (
        <HeaderSummary summary={currency(summary.cpm)} />
      ) : id === "rpi" ? (
        <HeaderSummary summary={currency(summary.rpi)} />
      ) : id === "pnl" ? (
        <HeaderSummary summary={currency(summary.pnl)} />
      ) : id === "pm" ? (
        <HeaderSummary summary={percent(summary.pm, 2)} />
      ) : (
        <HeaderSummary></HeaderSummary>
      )}
    </HeaderCell>
  );
};

function sort(source: any, sourceId: any, targetId: any) {
  const nextData = source.filter((item: any) => item.id !== sourceId);
  const dragItem = source.find((item: any) => item.id === sourceId);
  const index = nextData.findIndex((item: any) => item.id === targetId);

  nextData.splice(index + 1, 0, dragItem);
  return nextData;
}

/**
 * RoiTreeTable is a separated component to prevent a scroll-to-top bug
 */
type RoiTreeProps = {
  data: any;
  titles: any;
  order: string;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const RoiTreeTable: React.FC<RoiTreeProps> = (props: any) => {
  //
  const summary = props.summary;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const order = props.order;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [highlight, setHighlight] = React.useState(true);

  const [rowIndex] = React.useState<string | null>();
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [targetEvent, setTarget] = React.useState();
  const groupTitles = new Map([
    ["afid_offer", "Site/Affiliate/Page/Flow"],
    ["page_flow_step", "Site/Page/Flow/Step"],
    ["afid_subid", "Site/Affiliate/SubID"],
    ["site_offer", "Site/Offer"],
    ["offer_site", "Offer/Site"],
  ]);
  const [columns, setColumns] = React.useState([
    { id: "impression_unique_count", name: "Unique", width: 175 },
  ]);

  React.useEffect(() => {
    order === "offer_site"
      ? setColumns([
          { id: "impression_count", name: "Impressions", width: 175 },
          { id: "impression_unique_count", name: "Unique", width: 175 },
          { id: "conversion_count", name: "Conversions", width: 175 },
          { id: "conversion_percent", name: "Conv %", width: 175 },
          { id: "cost", name: "Cost", width: 175 },
          { id: "cpl", name: "CPL", width: 175 },
          { id: "cpi", name: "CPI", width: 175 },
          { id: "click_count", name: "Clicks", width: 175 },
          { id: "revenue", name: "Revenue", width: 175 },
          { id: "rpi", name: "RPI", width: 175 },
          { id: "cpm", name: "CPM", width: 175 },
          { id: "pnl", name: "P&L", width: 175 },
          { id: "pm", name: "PM", width: 175 },
        ])
      : order === "site_offer"
      ? setColumns([
          { id: "impression_count", name: "Impressions", width: 175 },
          { id: "impression_unique_count", name: "Unique", width: 175 },
          { id: "form_submit_count", name: "Submits", width: 175 },
          { id: "submit_percent", name: "Submits %", width: 175 },
          { id: "conversion_count", name: "Conversions", width: 175 },
          { id: "conversion_percent", name: "Conv %", width: 175 },
          { id: "accept_percent", name: "Accept %", width: 175 },
          { id: "cost", name: "Cost", width: 175 },
          { id: "cpl", name: "CPL", width: 175 },
          { id: "cpi", name: "CPI", width: 175 },
          { id: "click_count", name: "Clicks", width: 175 },
          { id: "revenue", name: "Revenue", width: 175 },
          { id: "rpi", name: "RPI", width: 175 },
          { id: "cpm", name: "CPM", width: 175 },
          { id: "pnl", name: "P&L", width: 175 },
          { id: "pm", name: "PM", width: 175 },
        ])
      : order === "afid_subid" ||
        order === "page_flow_step" ||
        order === "afid_offer"
      ? setColumns([
          { id: "impression_count", name: "Impressions", width: 175 },
          { id: "impression_unique_count", name: "Unique", width: 175 },
          { id: "form_submit_count", name: "Submits", width: 175 },
          { id: "submit_percent", name: "Submits %", width: 175 },
          { id: "conversion_count", name: "Conversions", width: 175 },
          { id: "conversion_percent", name: "Conv %", width: 175 },
          { id: "accept_percent", name: "Accept %", width: 175 },
          { id: "cost", name: "Cost", width: 175 },
          { id: "cpl", name: "CPL", width: 175 },
          { id: "cpi", name: "CPI", width: 175 },
          { id: "click_count", name: "Clicks", width: 175 },
          { id: "revenue", name: "Revenue", width: 175 },
          { id: "rpi", name: "RPI", width: 175 },

          { id: "pnl", name: "P&L", width: 175 },
          { id: "pm", name: "PM", width: 175 },
        ])
      : setColumns([]);
  }, [order]);

  const data = props.data;
  const handleDragColumn = (sourceId: any, targetId: any) => {
    setColumns(sort(columns, sourceId, targetId));
  };

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any, event: SyntheticEvent) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];
    let target = event.currentTarget;
    if (highlight && rowIndex === undefined) {
      if (event.currentTarget.getAttribute("data-depth") === "0") {
        setHighlight(!highlight);
        target.classList.add("highlight");
        setTarget(target);
      }
    } else if (targetEvent !== target) {
      if (event.currentTarget.getAttribute("data-depth") === "0") {
        targetEvent.classList.remove("highlight");
        setHighlight(false);
        target.classList.add("highlight");
        setTarget(target);
      }
    } else if (targetEvent === target) {
      if (event.currentTarget.getAttribute("data-depth") === "0") {
        setHighlight(true);
        target.classList.remove("highlight");
      }
    }

    expandedRowKeys.forEach((key) => {
      const regex = new RegExp(`^${key}_.*`);
      if (key === rowData[rowKey]) {
        open = true;
      } else if (regex.test(rowData[rowKey])) {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }
    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  return (
    <DndProvider backend={Backend}>
      <div>
        <Table
          isTree
          // height={650}
          autoHeight={true}
          headerHeight={65}
          loading={isLoading === true}
          data={sortData(data)}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
          onRowClick={handleExpanded}
        >
          <Column width={300} fixed sortable resizable>
            <HeaderCell>{groupTitles.get(order)}</HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>
          {columns.map((column) => (
            <Column width={column.width} key={column.id} sortable resizable>
              <DraggableHeaderCell
                onDrag={handleDragColumn}
                id={column.id}
                title={column.name}
                summary={summary}
                order={order}
              >
                <div className="column_header_title">{column.name}</div>
                <Icon icon="arrows" className="draggable" />
              </DraggableHeaderCell>
              {column.id === "cost" ? (
                <CurrencyCell dataKey="cost" />
              ) : column.id === "cpl" ? (
                <CurrencyCell dataKey="cpl" />
              ) : column.id === "cpi" ? (
                <CurrencyCell dataKey="cpi" />
              ) : column.id === "revenue" ? (
                <CurrencyCell dataKey="revenue" />
              ) : column.id === "pnl" ? (
                <CurrencyCell dataKey="pnl" />
              ) : (order === "site_offer" && column.id === "cpm") ||
                (order === "offer_site" && column.id === "cpm") ? (
                <CurrencyCell dataKey="cpm" digits="2" />
              ) : column.id === "impression_count" ? (
                <NumberCell dataKey="impression_count" />
              ) : column.id === "impression_unique_count" ? (
                <NumberCell dataKey="impression_unique_count" />
              ) : column.id === "form_submit_count" &&
                order !== "offer_site" ? (
                <NumberCell dataKey="form_submit_count" digits="2" />
              ) : column.id === "submit_percent" && order !== "offer_site" ? (
                <PercentCell dataKey="submit_percent" digits="2" />
              ) : column.id === "conversion_count" ? (
                <NumberCell dataKey="conversion_count" digits="2" />
              ) : column.id === "conversion_percent" ? (
                <PercentCell dataKey="conversion_percent" digits="2" />
              ) : column.id === "accept_percent" && order !== "offer_site" ? (
                <PercentCell dataKey="accept_percent" digits="2" />
              ) : column.id === "click_count" ? (
                <NumberCell dataKey="click_count" />
              ) : column.id === "rpi" ? (
                <CurrencyCell dataKey="rpi" />
              ) : column.id === "pm" ? (
                <PercentCell dataKey="pm" />
              ) : (
                <Cell></Cell>
              )}
            </Column>
          ))}
        </Table>
      </div>
    </DndProvider>
  );
};

export default RoiTreeTable;

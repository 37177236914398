import React from "react";
import { Drawer, FlexboxGrid, Icon, Table } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { NumberCell, PercentCell } from "../Table";
import SelectPicker from "rsuite/lib/SelectPicker";
import { sort } from "../../@Utils/Sorting";
const { Column, HeaderCell, Cell } = Table;
type UniqueRecords = {
  row_data: any;
  start?: any;
  end?: any;
};

const UniqueRecords: React.FC<UniqueRecords> = ({ row_data, start, end }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const defaultGroupValue = -30;
  const [group, setGroup] = React.useState(defaultGroupValue);

  let currentDate;

  if (start) {
    currentDate = start;
  } else {
    const date = row_data.start_date.split(" ")[0].split("-");
    currentDate = date[1] + "/" + date[2] + "/" + date[0];
  }

  const groupByList = [
    { value: 0, label: "ALL" },
    { value: -1, label: "One Day" },
    { value: -7, label: "One Week" },
    { value: -14, label: "Two Weeks" },
    { value: -30, label: "One Month" },
    { value: -90, label: "Three Month" },
    { value: -365, label: "One Year" },
  ];

  const params = {
    start_date: start === undefined ? Admediary.start : new Date(start),
    end_date: end === undefined ? Admediary.end : new Date(end),
    data_source_id: row_data.data_source_id,
    window: group,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  const [data, isLoading] = useAdmediaryApi("unique_records", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Unique Records - {row_data.data_source_name} - {currentDate}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={5} style={{ textAlign: "left" }}>
            <SelectPicker
              size="sm"
              searchable={false}
              cleanable={false}
              placeholder="Group By"
              defaultValue={defaultGroupValue}
              value={group}
              data={groupByList}
              valueKey="value"
              labelKey="label"
              onChange={(v) => {
                setGroup(v);
              }}
              style={{ marginRight: 15 }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={Array.isArray(data) ? sortData(data) : []}
          hover
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Count</HeaderCell>
            <NumberCell dataKey="count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Percent</HeaderCell>
            <PercentCell dataKey="percent" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default UniqueRecords;

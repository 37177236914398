import React from "react";
import { Table } from "rsuite";
import { PercentCell, ParentCell, ToolTipCell, HeaderSummary } from "../Table";
import { sortTree } from "../../@Utils/Sorting";
import { percent } from "../../@Utils/Format";

const { Column, HeaderCell, Cell } = Table;

type SMSDeliveryProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  checked: boolean;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const SMSDeliveryTable: React.FC<SMSDeliveryProps> = (props: any) => {
  const data = props.data;
  const summary = props.summary;
  const checked = props.checked;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const group = props.group;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sortTree(list, sort_column, sort_type) : list
    );
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading}
      data={Array.isArray(data) ? sortData(data) : []}
      checked={checked}
      rowKey={rowKey}
      group={group}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={225} align="left" sortable resizable>
        {checked ? (
          <HeaderCell>Campaign ID/Creative ID/Carrier</HeaderCell>
        ) : (
          <HeaderCell>Campaign ID/Creative ID/Carrier</HeaderCell>
        )}
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column width={300} sortable resizable>
        <HeaderCell>Description</HeaderCell>
        <Cell dataKey="description" />
      </Column>
      <Column width={125} sortable resizable>
        <HeaderCell>From</HeaderCell>
        <Cell dataKey="phone_number" />
      </Column>
      <Column width={300} sortable resizable>
        <HeaderCell>Message</HeaderCell>
        <ToolTipCell dataKey="message" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>Carrier</HeaderCell>
        <Cell dataKey="carrier" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Sent" summary={summary.sent} />
        </HeaderCell>
        <Cell dataKey="sent" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Delivered" summary={summary.delivered} />
        </HeaderCell>
        <Cell dataKey="delivered" />
      </Column>
      <Column width={125} sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Delivered %"
            summary={percent(summary.deliveredPercent, 2)}
          />
        </HeaderCell>
        <PercentCell dataKey="deliveredPercent" digits={"2"} />
      </Column>
      <Column sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Queued" summary={summary.queued} />
        </HeaderCell>
        <Cell dataKey="queued" />
      </Column>
      <Column sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Queued %"
            summary={percent(summary.queuedPercent, 2)}
          />
        </HeaderCell>
        <PercentCell dataKey="queuedPercent" digits={"2"} />
      </Column>
      <Column width={125} sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Undelivered" summary={summary.undelivered} />
        </HeaderCell>
        <Cell dataKey="undelivered" />
      </Column>
      <Column width={125} sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Undelivered %"
            summary={percent(summary.undeliveredPercent, 2)}
          />
        </HeaderCell>
        <PercentCell dataKey="undeliveredPercent" digits={"2"} />
      </Column>
    </Table>
  );
};

export default SMSDeliveryTable;

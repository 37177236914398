import React, { SyntheticEvent, useRef } from "react";
import {
  Table,
  Notification,
  Icon,
  IconButton,
  Whisper,
  Popover,
  FlexboxGrid,
} from "rsuite";
import { CheckCell, EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerContract from "./../Drawers/BuyerContract";
import { Query } from "../../@Utils/AdmediaryApi";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractsListProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const BuyerContractsList: React.FC<BuyerContractsListProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  //Popover to view source information on tooltip
  const SourcesPopoverCell = ({ rowData, ...props }: any) => {
    return (
      <Cell {...props} className="link-group">
        <Whisper
          placement="auto"
          trigger="click"
          enterable
          preventOverflow
          speaker={
            <Popover
              full
              title={
                rowData.default_allow == "1"
                  ? "Default: Allow"
                  : rowData.default_allow == "0"
                  ? "Default: Block"
                  : "No Default Action"
              }
              style={{ padding: "10px", position: "absolute" }}
            >
              {rowData.sources_list.length > 0 && (
                <Table
                  data={rowData.sources_list.map((source: any) => ({
                    ...source,
                    action: source.allow == 1 ? "Allow" : "Block",
                  }))}
                  width={280}
                  height={200}
                  rowHeight={30}
                  rowClassName={"striped-rows sources-list-row"}
                >
                  <Column align="left" width={60}>
                    <HeaderCell>Action</HeaderCell>
                    <Cell dataKey="action" />
                  </Column>
                  <Column align="center" width={60}>
                    <HeaderCell>AFID</HeaderCell>
                    <Cell dataKey="afid" />
                  </Column>
                  <Column align="center" width={140}>
                    <HeaderCell>SID1</HeaderCell>
                    <Cell dataKey="sid1" />
                  </Column>
                </Table>
              )}
            </Popover>
          }
        >
          <IconButton
            appearance="subtle"
            icon={
              <>
                <Icon icon="info-circle" />
                {rowData.sources_list.length > 0 && (
                  <span className={"sources-list-superscript"}>
                    {rowData.sources_list.length}
                  </span>
                )}
              </>
            }
          />
        </Whisper>
      </Cell>
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      <BuyerContract
        contractId={rowData.contract_id}
        contractName={rowData.contract_name}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  const handleRemoveActionClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeContract(selectedItem.contract_id);

    handleClose();
  };

  const removeContract = (contractId: number = 0) => {
    if (contractId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_remove", { contract_id: contractId });
        Notification.success({
          title: "Success",
          description: "Buyer Contract has been removed",
        });

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        {categoryId === 14 && (
          <Column align="right" fixed sortable resizable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="lead_instance_status" />
          </Column>
        )}
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Contract ID</HeaderCell>
          <Cell dataKey="contract_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Buyer Name</HeaderCell>
          <Cell dataKey="buyer_name" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>Category Name</HeaderCell>
          <Cell dataKey="category_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Contract Name</HeaderCell>
          <Cell dataKey="contract_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Routing Tag</HeaderCell>
          <Cell dataKey="routing_tag" />
        </Column>
        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={70} align={"center"}>
          <HeaderCell>Sources</HeaderCell>
          <SourcesPopoverCell />
        </Column>
        <Column width={100} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            removeHandler={handleRemoveActionClick}
            userPermission={admediaryContext.userDisable}
          />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Contract?
      </ConfirmModal>
    </>
  );
};

export default BuyerContractsList;

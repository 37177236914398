import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import {
  HeaderSummary,
  CurrencyCell,
  NumberCell,
  PercentCell,
  ParentCell,
} from "../Table";
import { currency, percent } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";
import FilterReasons from "../Drawers/FilterReasons";
import ViewLeads from "../Drawers/ViewLeads";
import SubID from "../Drawers/SubID";
import Responses from "../Drawers/Responses";

const { Column, HeaderCell, Cell } = Table;

/**
 * AffiliateTreeTable is a separated component to prevent a scroll-to-top bug
 */
type AffiliateTreeProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  checked: boolean;
  group: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const AffiliateTreeTable: React.FC<AffiliateTreeProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const checked = props.checked;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const group = props.group;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const groupTitles = new Map([
    ["disposition", "Disposition"],
    ["contract", "Contract"],
    ["buyer", "Buyer"],
    ["affiliate", "Affiliate"],
  ]);

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={sortData(data)}
      checked={checked}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={300} fixed sortable resizable>
        {checked ? (
          <HeaderCell>
            Affiliate/SubID/Buyer/Contract/{groupTitles.get(group)}
          </HeaderCell>
        ) : (
          <HeaderCell>
            Affiliate/Buyer/Contract/{groupTitles.get(group)}
          </HeaderCell>
        )}
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate ID</HeaderCell>
        <Cell dataKey="affiliate_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Category ID</HeaderCell>
        <Cell dataKey="category_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Product ID</HeaderCell>
        <Cell dataKey="product_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Product Name</HeaderCell>
        <Cell dataKey="product_name" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Considered" summary={summary.considered} />
        </HeaderCell>
        <NumberCell dataKey="considered" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Filtered" summary={summary.filtered} />
        </HeaderCell>
        <NumberCell dataKey="filtered" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Filter %"
            summary={percent(summary.filter_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="filter_percent" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Presented" summary={summary.presented} />
        </HeaderCell>
        <NumberCell dataKey="presented" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Accepted" summary={summary.accepted} />
        </HeaderCell>
        <NumberCell dataKey="accepted" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Accept %"
            summary={percent(summary.accept_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="accept_percent" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Rejected" summary={summary.rejected} />
        </HeaderCell>
        <NumberCell dataKey="rejected" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Paid" summary={currency(summary.rate)} />
        </HeaderCell>
        <CurrencyCell dataKey="rate" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Earned" summary={currency(summary.earned)} />
        </HeaderCell>
        <CurrencyCell dataKey="earned" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="GP" summary={currency(summary.profit)} />
        </HeaderCell>
        <CurrencyCell dataKey="profit" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="CPL" summary={currency(summary.cpl)} />
        </HeaderCell>
        <CurrencyCell dataKey="cpl" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="RPL" summary={currency(summary.rpl)} />
        </HeaderCell>
        <CurrencyCell dataKey="rpl" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="PPL" summary={currency(summary.ppl)} />
        </HeaderCell>
        <CurrencyCell dataKey="ppl" />
      </Column>

      <Column width={150} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell checked={checked} summary={summary} />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row, summary }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="filter_reasons">Filter Reasons</Dropdown.Item>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
    {row.checked && row.sid1 ? (
      <Dropdown.Item eventKey="sub_id">SubID </Dropdown.Item>
    ) : null}

    {row.disposition === "accepted" || row.disposition === "rejected" ? (
      <Dropdown.Item eventKey="view_responses">Responses </Dropdown.Item>
    ) : null}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, summary, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} checked={checked} summary={summary} />
  </Popover>
);

type TableWhisper = {
  row: any;
  summary: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const summary = props.summary;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  row.checked = props.row.checked ? props.row.checked : false;
  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["filter_reasons", <FilterReasons row_data={row} />],
      ["view_leads", <ViewLeads row_data={row} />],
      ["sub_id", <SubID row_data={row} summary={summary} />],
      ["view_responses", <Responses row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, checked, summary, ...props }: any) => {
  rowData.checked = checked;
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} summary={summary} />
    </Cell>
  );
};

export default AffiliateTreeTable;

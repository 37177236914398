import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { formatDistance, parseISO } from "date-fns";
import { Query } from "../../@Utils/AdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";

import { ToolTipCell } from "../Table";
const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    const post_date = item.post_date
      ? format(item.post_date, "y-MM-dd HH:mm")
      : "";

    return {
      ID: item.lead_instance_id,
      Request: item.request,
      Response: item.response,
      Status: item.status,
      Rate: item.rate,
      "Total Time": item.total_time,
      "Post Time": item.post_time,
      "Post Date": post_date,
      Buyer: item.buyer_name,
      Contract: item.contract_name,
      Affiliate: item.affiliate_name,
      Product: item.product_description,
      "First Name": item.first_name,
      "Last Name": item.last_name,
      Email: item.email,
      Phone: item.phone,
      Disposition: item.disposition,
      Reason: item.disposition_detailed,
      Price: item.price_received,
      IP: item.client_ip,
      State: item.edit_state,
      SID1: item.sid1,
      SID2: item.sid2,
      SID3: item.sid3,
    };
  });
}

type Responses = {
  row_data: any;
  statusLead?: any;
};

const Responses: React.FC<Responses> = ({ row_data, statusLead }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const contract_id =
    row_data.contract_id || getFieldValueOrParentValue(row_data, "contract_id");
  const status = statusLead ? statusLead : row_data.disposition;
  // console.log(row_data)

  // lead view code start
  const buyer_id =
    row_data.buyer_id || getFieldValueOrParentValue(row_data, "buyer_id");
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id");
  const sub_id_1 =
    row_data.sid1 || getFieldValueOrParentValue(row_data, "sid1");
  const sub_id_2 =
    row_data.sid2 || getFieldValueOrParentValue(row_data, "sid2");
  const disposition = row_data.disposition || "";
  // lead view code end

  const params = {
    start_date,
    end_date,
    contract_id: contract_id ? contract_id : 0,
    status: status,
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: buyer_id ? buyer_id : 0,
    affiliate_id,
    sub_id_1,
    sub_id_2,
    disposition,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          time_distance: formatDistance(item.entry_date, new Date()),
          price_received: item.price_received
            ? item.price_received.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : 0,
        },
      };
    });
  };

  const formatDataExport = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          response: item.response.replace(/[\r\n]/gm, ""),
        },
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  // Define types for responses and leads
  interface ResponseData {
    lead_instance_id: number; // Adjust the type based on your actual data structure
    // Other properties in your response data
  }

  interface LeadData {
    lead_instance_id: number; // Adjust the type based on your actual data structure
    // Other properties in your lead data
  }

  const [isLoading, setIsLoading] = React.useState(false);
  const [responses, setResponses] = React.useState<ResponseData[]>([]); // Specify the type
  const [leads, setLeads] = React.useState<LeadData[]>([]); // Specify the type
  const [data, setData] = React.useState<(ResponseData & LeadData)[]>([]); // Specify the merged type

  const fetchResponse = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("responses", params);
      setResponses(results.data);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchLeads = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("buyer_transactions", params);
      setLeads(results.data);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchResponse();
    fetchLeads();
  }, [fetchResponse, fetchLeads]);

  React.useEffect(() => {
    if (responses.length > 0 && leads.length > 0) {
      const combinedData = responses.map((response) => {
        const matchingLead = leads.find(
          (lead) => lead.lead_instance_id === response.lead_instance_id
        );
        if (matchingLead) {
          // Merge the response and lead data
          return {
            ...response,
            ...matchingLead,
          };
        }
        return response; // If no matching lead is found, keep the response as is
      });
      setData(combinedData);
    }
  }, [responses, leads]);

  // const [data, isLoading] = useAdmediaryApi("responses", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          View Responses
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `leads_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  formatDataForExporting(data)
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          height={750}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>
              <span>ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead Instance ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="lead_instance_id" />
          </Column>
          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Request</span>
              <Whisper trigger="hover" speaker={<Tooltip>Request</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <ToolTipCell dataKey="request" />
          </Column>
          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Response</span>
              <Whisper trigger="hover" speaker={<Tooltip>Response</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <ToolTipCell dataKey="response" />
          </Column>
          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Status</span>
              <Whisper trigger="hover" speaker={<Tooltip>Status</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="status" />
          </Column>
          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Rate</span>
              <Whisper trigger="hover" speaker={<Tooltip>Rate</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="rate" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Total Time</span>
              <Whisper trigger="hover" speaker={<Tooltip>Total Time</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="total_time" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Post Time</span>
              <Whisper trigger="hover" speaker={<Tooltip>Post Time</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <ToolTipCell dataKey="post_time" />
          </Column>
          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Post Date</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Date lead posted</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => <span>{rowData.time_distance}</span>}
            </Cell>
          </Column>
          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Buyer</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Buyer's Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="buyer_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Contract</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of Buyer's Contract</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="contract_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Affiliate</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of Affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="affiliate_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Product</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of the Product</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="product_description" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>First Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's first name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="first_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Last Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's last name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="last_name" />
          </Column>

          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Email</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Email address of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Phone</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Phone # of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="phone" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>Disposition</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Disposition Status of Lead</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="disposition" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Reason</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Disposition Reason</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="disposition_detailed" />
          </Column>

          <Column align="right" sortable resizable width={125}>
            <HeaderCell>
              <span>Price</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Revenue generated by lead</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="price_received" />
          </Column>

          <Column align="right" sortable resizable width={135}>
            <HeaderCell>
              <span>IP</span>
              <Whisper
                trigger="hover"
                speaker={
                  <Tooltip>
                    IP address of customer's computer at submission
                  </Tooltip>
                }
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="client_ip" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>State</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's State</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="edit_state" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID1</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 1 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid1" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID2</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 2 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid2" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID3</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 3 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid3" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default Responses;

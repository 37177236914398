import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import NumbersReportDataTable from "./NumbersReportDataTable";

import Title from "../Title";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { exportCsv } from "../../@Utils/Export";

const NumbersReportData: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    phone_id: 0,
  };

  const [data, isLoading] = useAdmediaryApi("phone_numbers_select", params);

  function formatDataForExporting(data: any) {
    return data.map((item: any) => {
      return {
        Active: item.active,
        "Number ID": item.phone_id,
        "Phone Number": item.phone_number,
        Description: item.description,
        Voice: item.voice,
        SMS: item.sms,
        MMS: item.mms,
        "Date Created": new Date(item.entry_date).toLocaleString(),
      };
    });
  }

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  return (
    <>
      <Title title="Numbers Management Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `numbers_campaigns.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <NumbersReportDataTable
        data={Array.isArray(data) ? data : []}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
    </>
  );
};

export default NumbersReportData;

import React from "react";
import { Table } from "rsuite";
import { CurrencyCell, HeaderSummary, ParentCell, PercentCell } from "../Table";
import { sortTree } from "../../@Utils/Sorting";
import { currency, percent, thousands } from "../../@Utils/Format";

const { Column, HeaderCell, Cell } = Table;

/**
 * PostSummaryTable is a separated component to prevent a scroll-to-top bug
 */
type GrossProfitReportTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
  group: string;
};

const GrossProfitReportTable: React.FC<GrossProfitReportTableProps> = (
  props: any
) => {
  const summary = props.summary;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const group = props.group;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  const formatSummary = (list: any) => {
    return {
      ...list,
      gp_percentage: list.revenue > 0 ? (list.gp / list.revenue) * 100 : 0,
    };
  };

  const formattedSummary =
    typeof summary === "object" && !isLoading ? formatSummary(summary) : [];

  return (
    <Table
      isTree
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={Array.isArray(data) ? sortData(data) : []}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={300} fixed sortable resizable>
        <HeaderCell>
          {group === "buyer"
            ? "Product/Buyer/Contract/Affiliate/SubID"
            : "Product Name/Affiliate/SubID"}
        </HeaderCell>
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate ID</HeaderCell>
        <Cell dataKey="affiliate_id" />
      </Column>
      <Column align="right" sortable resizable width={150}>
        <HeaderCell>Affiliate Manager</HeaderCell>
        <Cell dataKey="affiliate_manager" />
      </Column>
      {group === "buyer" ? (
        <Column align="right" sortable resizable width={150}>
          <HeaderCell>Buyer Manager</HeaderCell>
          <Cell dataKey="buyer_manager" />
        </Column>
      ) : (
        ""
      )}
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Sum of Cost"
            summary={currency(formattedSummary.cost)}
          />
        </HeaderCell>

        <CurrencyCell dataKey="cost" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Sum of Revenue"
            summary={currency(formattedSummary.revenue)}
          />
        </HeaderCell>

        <CurrencyCell dataKey="revenue" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Sum of GP"
            summary={currency(formattedSummary.gp)}
          />
        </HeaderCell>

        <CurrencyCell dataKey="gp" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="GP %"
            summary={percent(formattedSummary.gp_percentage)}
          />
        </HeaderCell>
        <PercentCell dataKey="gp_percentage" />
      </Column>
      <Column align="right" sortable resizable width={110}>
        <HeaderCell>Conversions</HeaderCell>
        <Cell dataKey="conversions" />
      </Column>
    </Table>
  );
};

export default GrossProfitReportTable;

import React from "react";
import { Button, ButtonToolbar, Drawer, Icon, Notification } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import _ from "lodash";
import { FormInstance } from "rsuite/lib/Form/Form";
import CampaignForm from "../Products/CampaignForm";

type CampaignType = {
  campaignId: number;
  refreshData?: () => void;
};

const Campaign: React.FC<CampaignType> = ({ campaignId = 0, refreshData }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.server_side)) {
      data.server_side = data.server_side.length ? data.server_side[0] : 0;
    }

    if (Array.isArray(data.rev_share)) {
      data.rev_share = data.rev_share.length ? data.rev_share[0] : 0;
    }

    if (Array.isArray(data.is_esign_campaign)) {
      data.is_esign_campaign = data.is_esign_campaign.length
        ? data.is_esign_campaign[0]
        : 0;
    }

    if (data.rev_share === 1) {
      data.campaign_rate = Number(data.campaign_rate) * 0.01;
    }

    (async () => {
      try {
        await Query("campaign_update", data);
        Notification.success({
          title: "Success",
          description: "Campaign has been updated",
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!campaignId ? "Edit" : "Add"} Campaign
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <CampaignForm
          campaignId={campaignId}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button onClick={handleSave} appearance="primary">
            Save
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default Campaign;

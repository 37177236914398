import React from "react";
import {
  Drawer,
  Dropdown,
  FlexboxGrid,
  Icon,
  IconButton,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format, parseISO } from "date-fns";
import { exportCsv } from "../../@Utils/Export";
import {
  HeaderSummary,
  CurrencyCell,
  NumberCell,
  PercentCell,
  ParentCell,
} from "../Table";

import { currency, percent } from "../../@Utils/Format";
import FilterReasons from "./FilterReasons";
import ViewLeads from "./ViewLeads";
import Responses from "./Responses";

const { Column, HeaderCell, Cell } = Table;

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "considered",
    "filtered",
    "presented",
    "accepted",
    "rejected",
    "rate",
    "earned",
    "profit",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}
function addCalculatedValues(row: any) {
  row.accepted = parseFloat(row.accepted);
  row.considered = parseFloat(row.considered);
  row.presented = parseFloat(row.presented);

  return {
    ...row,
    ...{
      cpl: row.accepted !== 0 ? row.rate / row.accepted : 0,
      rpl: row.accepted !== 0 ? row.earned / row.accepted : 0,
      ppl: row.accepted !== 0 ? row.profit / row.accepted : 0,
      filter_percent:
        row.considered !== 0 ? (row.filtered / row.considered) * 100 : 0,
      accept_percent:
        row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
    },
  };
}

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type SubID = {
  row_data: any;
  summary: any;
};

const SubID: React.FC<SubID> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const rowKey: string = "node_id";
  const checked = row_data.checked;
  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id");
  const sub_id_1 =
    row_data.sid1 || getFieldValueOrParentValue(row_data, "sid1");
  // const disposition = row_data.disposition || "";
  const group = "disposition";
  const params = {
    start_date,
    end_date,
    affiliate_id,
    category_id: Admediary.category,
    product_id: Admediary.product,
    sub_id_1,
    sub_id_2: "",
    detail_by: "sid2",
    data_type: "date_sold",
  };

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  /**
   * Expand rows
   */
  const handleExpanded = (data: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === data[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(data[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          // time_distance: formatDistance(item.post_date, new Date()),
          // price_received: item.price_received.toLocaleString("en-US", {
          //     style: "currency",
          //     currency: "USD",
          // }),
        },
      };
    });
  };

  const groupTitles = new Map([
    ["disposition", "Disposition"],
    ["contract", "Contract"],
    ["buyer", "Buyer"],
    ["sid2", "SubID"],
  ]);

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const [data, isLoading] = useAdmediaryApi("affiliate_sub_id", params);

  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const summary = summaryData;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          SubID
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `subID_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  data
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          isTree
          height={650}
          headerHeight={80}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          loading={isLoading === true}
          data={Array.isArray(data) ? sortData(data) : []}
          summary={summaryData}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          group={group}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
          onRowClick={handleExpanded}
        >
          <Column width={300} fixed sortable resizable>
            <HeaderCell>
              SubID/Buyer/Contract/{groupTitles.get(group)}
            </HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>

          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Considered" summary={summary.considered} />
            </HeaderCell>
            <NumberCell dataKey="considered" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Filtered" summary={summary.filtered} />
            </HeaderCell>
            <NumberCell dataKey="filtered" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Filter %"
                summary={percent(summary.filter_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="filter_percent" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Presented" summary={summary.presented} />
            </HeaderCell>
            <NumberCell dataKey="presented" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Accepted" summary={summary.accepted} />
            </HeaderCell>
            <NumberCell dataKey="accepted" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Accept %"
                summary={percent(summary.accept_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="accept_percent" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Rejected" summary={summary.rejected} />
            </HeaderCell>
            <NumberCell dataKey="rejected" />
          </Column>

          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Paid" summary={currency(summary.rate)} />
            </HeaderCell>
            <CurrencyCell dataKey="rate" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Earned"
                summary={currency(summary.earned)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="earned" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Profit"
                summary={currency(summary.profit)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="profit" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="CPL" summary={currency(summary.cpl)} />
            </HeaderCell>
            <CurrencyCell dataKey="cpl" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="RPL" summary={currency(summary.rpl)} />
            </HeaderCell>
            <CurrencyCell dataKey="rpl" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="PPL" summary={currency(summary.ppl)} />
            </HeaderCell>
            <CurrencyCell dataKey="ppl" />
          </Column>
          <Column width={150} fixed="right">
            <HeaderCell>&nbsp;</HeaderCell>
            <ActionCell checked={checked} summary={summary} />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};
const ActionCell = ({ rowData, checked, summary, ...props }: any) => {
  rowData.checked = checked;
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} summary={summary} />
    </Cell>
  );
};

type TableWhisper = {
  row: any;
  summary: any;
};

const MenuPopover = ({ onSelect, row, summary, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} checked={checked} summary={summary} />
  </Popover>
);

const Menu = ({ onSelect, row, summary }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="filter_reasons">Filter Reasons</Dropdown.Item>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
    {row.checked && row.sid1 ? (
      <Dropdown.Item eventKey="sub_id">SubID </Dropdown.Item>
    ) : null}

    {row.disposition === "accepted" || row.disposition === "rejected" ? (
      <Dropdown.Item eventKey="view_responses">Responses </Dropdown.Item>
    ) : null}
  </Dropdown.Menu>
);

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const summary = props.summary;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  row.checked = props.row.checked ? props.row.checked : false;
  function handleSelectMenu(eventKey: string, event: any) {
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["filter_reasons", <FilterReasons row_data={row} />],
      ["view_leads", <ViewLeads row_data={row} />],
      ["sub_id", <SubID row_data={row} summary={summary} />],
      ["view_responses", <Responses row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

export default SubID;

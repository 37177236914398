import React from "react";
import { Table } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { sort } from "../../@Utils/Sorting";
import PandaLead from "../Drawers/PandaLead";
import moment from "moment";
import { CurrencyCell } from "../Table";

const { Column, HeaderCell, Cell } = Table;

/**
 * EsignDocStatusTable is a separated component to prevent a scroll-to-top bug
 */
type EsignDocStatusTableProps = {
  data: any;
  isLoading: boolean;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const EsignDocStatusTable: React.FC<EsignDocStatusTableProps> = (
  props: any
) => {
  const data = props.data;
  const isLoading = props.isLoading;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const Admediary = React.useContext(AdmediaryContext);

  const getRowData = (rowData: any) => {
    Admediary.openDrawer(<PandaLead row_data={rowData} />);
  };

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          date_created: item.date_created
            ? moment(item.date_created).local().format("MM-DD-Y HH:mm")
            : "",
          date_modified: item.date_modified
            ? moment(item.date_modified).local().format("MM-DD-Y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={Array.isArray(data) ? sortData(data) : []}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={getRowData}
    >
      <Column width={125} align="right" sortable resizable>
        <HeaderCell>Lead Instance ID</HeaderCell>
        <Cell dataKey="lead_instance_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Product ID</HeaderCell>
        <Cell dataKey="product_id" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>Product Description</HeaderCell>
        <Cell dataKey="product_description" />
      </Column>
      <Column width={175} sortable resizable>
        <HeaderCell>First Name</HeaderCell>
        <Cell dataKey="first_name" />
      </Column>
      <Column width={175} sortable resizable>
        <HeaderCell>Last Name</HeaderCell>
        <Cell dataKey="last_name" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>Email</HeaderCell>
        <Cell dataKey="email" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>Phone</HeaderCell>
        <Cell dataKey="phone" />
      </Column>
      <Column width={100} align="right" sortable resizable>
        <HeaderCell>City</HeaderCell>
        <Cell dataKey="city" />
      </Column>
      <Column width={75} align="right" sortable resizable>
        <HeaderCell>State</HeaderCell>
        <Cell dataKey="state" />
      </Column>
      <Column width={75} align="right" sortable resizable>
        <HeaderCell>Zip</HeaderCell>
        <Cell dataKey="zip" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate ID</HeaderCell>
        <Cell dataKey="affiliate_id" />
      </Column>
      <Column width={75} align="right" sortable resizable>
        <HeaderCell>SID1</HeaderCell>
        <Cell dataKey="edit_sid1" />
      </Column>
      <Column width={75} align="right" sortable resizable>
        <HeaderCell>SID2</HeaderCell>
        <Cell dataKey="edit_sid2" />
      </Column>
      <Column width={75} align="right" sortable resizable>
        <HeaderCell>SID3</HeaderCell>
        <Cell dataKey="edit_sid3" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>Template Name</HeaderCell>
        <Cell dataKey="template_name" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>Doc Status</HeaderCell>
        <Cell dataKey="doc_status" />
      </Column>
      <Column width={120} align="right" sortable resizable>
        <HeaderCell>Doc Revenue</HeaderCell>
        <CurrencyCell dataKey="doc_completed_rate" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>Date Created</HeaderCell>
        <Cell dataKey="date_created" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>Last Modified</HeaderCell>
        <Cell dataKey="date_modified" />
      </Column>
    </Table>
  );
};

export default EsignDocStatusTable;

import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form } from "rsuite";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  map_id: NumberType(),
  field_id: NumberType().isRequired("Contract ID is required."),
  field_value: StringType().isRequired("From value is required."),
  map_value: StringType().isRequired("To value is required."),
});

type BuyerContractFieldMapFormType = {
  mapId?: number;
  fieldId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractFieldMapForm: React.FC<BuyerContractFieldMapFormType> = ({
  mapId = 0,
  fieldId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    map_id: 0,
    field_id: fieldId,
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    map_id: mapId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.map_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_field_map", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="From" name="field_value" />
      <Field label="To" name="map_value" />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractFieldMapForm;

import React from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Drawer,
  Grid,
  Icon,
  Nav,
  Row,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import CommonAffiliateForm from "../Affiliates/CommonAffiliateForm";
import DocumentList from "../Affiliates/DocumentList";
import { FormInstance } from "rsuite/lib/Form/Form";

type CommonAffiliateType = {
  affiliateId: number;
  refreshData?: () => void;
};

const CommonAffiliate: React.FC<CommonAffiliateType> = ({
  affiliateId = 0,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [activeKey, setActiveKey] = React.useState("main");
  const mainFormRef = React.createRef<FormInstance>();
  const isNewItem = affiliateId < 1;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isNewItem ? "Add" : "Edit"} Affiliate
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Nav
                activeKey={activeKey}
                appearance="tabs"
                onSelect={(eventKey) => {
                  setActiveKey(eventKey);
                }}
              >
                <Nav.Item eventKey="main">Affiliate</Nav.Item>
                {!isNewItem && [
                  <Nav.Item eventKey="document" key="3">
                    Documents
                  </Nav.Item>,
                ]}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                display: activeKey === "main" ? "block" : "none",
                minHeight: "100px", // put the loading icon down off the tabs
              }}
            >
              <CommonAffiliateForm
                affiliateId={affiliateId}
                formRef={mainFormRef}
              />
            </Col>
          </Row>
          {!isNewItem && (
            <Row>
              <Col
                xs={24}
                style={{ display: activeKey === "document" ? "block" : "none" }}
              >
                <DocumentList affiliateId={affiliateId} />
              </Col>
            </Row>
          )}
        </Grid>
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Close
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default CommonAffiliate;

import React from "react";
import { Avatar, FlexboxGrid, Icon, List, Loader } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

const TopAffiliateAlerts: React.FC = () => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: admediaryContext.category,
    product_id: admediaryContext.product,
    buyer_id: admediaryContext.buyer,
    affiliate_id: 0,
  };

  const [data, isLoading] = useAdmediaryApi("affiliate_notifications", params);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  // Nothing to show
  if (data.length === 0) {
    return (
      <List size="sm">
        <List.Item className="alerts-table-no-alerts">
          There are no Affiliate alerts.
        </List.Item>
      </List>
    );
  }

  // Render alerts
  return (
    <List size="sm">
      {data.slice(0, 5).map((item: any, key: number) => (
        <List.Item
          className="alerts-table-item"
          // onClick={() => clickEvent(item.name)}
          key={key}
          style={{ borderBottom: "solid grey 1px" }}
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={4} style={{ paddingTop: 7 }}>
              <Avatar size="sm" style={{ background: "none" }}>
                <Icon
                  icon="exclamation-triangle"
                  style={{ color: "#022654" }}
                />
              </Avatar>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={20}>
              <span
                style={{
                  fontSize: 11,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {item.affiliate_name}
              </span>
              <br />
              <span
                style={{
                  fontSize: 14,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {item.message}
              </span>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
      ))}
    </List>
  );
};

export default TopAffiliateAlerts;

import React from "react";
import {
  ResponsiveBarCanvas as NivoResponsiveBarCanvas,
  BarDatum,
} from "@nivo/bar";
import moment from "moment";

type AverageResponseTimeBarProps = {
  data?: BarDatum[];
};

const AverageResponseTimeBar: React.FC<AverageResponseTimeBarProps> = ({
  data = [],
}) => {
  return (
    <NivoResponsiveBarCanvas
      margin={{ top: 25, right: 30, bottom: 60, left: 60 }}
      data={data}
      indexBy="date"
      keys={["post_time"]}
      axisLeft={{
        legend: "Average Time, s",
        legendOffset: -45,
        legendPosition: "middle",
      }}
      axisBottom={{
        tickValues: "every 2 hours",
        format: (value) =>
          moment(value).format("H") === "0"
            ? moment(value).format("MM/dd")
            : `${moment(value).format("H")}h`,
        legend: "Date",
        legendOffset: 40,
        legendPosition: "middle",
      }}
      colors={["#0b79ef"]}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-left",
          direction: "row",
          justify: false,
          translateX: -35,
          translateY: 50,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 73,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      markers={[
        {
          axis: "y",
          value: 6,
          lineStyle: { stroke: "#FFFF00", strokeWidth: 2 },
        },
        {
          axis: "y",
          value: 10,
          lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
        },
      ]}
    />
  );
};

export default AverageResponseTimeBar;

import React from "react";
import {
  Table,
  FlexboxGrid,
  IconButton,
  Icon,
  Input,
  InputGroup,
  Notification,
} from "rsuite";

import { CheckCell, EditActionCell, EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import EditCampaignNumber from "../Drawers/EditCampaignNumber";
import ConfirmModal from "../ConfirmModal";

const { Column, HeaderCell, Cell } = Table;

type NumbersReportDataTable = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  refreshData?: () => void;
};

const NumbersReportDataTable: React.FC<NumbersReportDataTable> = (
  props: any
) => {
  const data = props.data;
  const isLoading = props.isLoading;
  const sortType = props.sortType;
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const admediaryContext = React.useContext(AdmediaryContext);
  const refreshData = props.refreshData;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [numberToRemove, setNumberToRemove] = React.useState(0);
  const mainFormRef = React.createRef<FormInstance>();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    if (["phone_id"].includes(sortColumn)) {
      list.map((record: any) => {
        return {
          ...record,
          sortColumn: parseInt(record[sortColumn]),
        };
      });
    }
    return formatData(
      sortColumn && sortType ? sort(list, sortColumn, sortType) : list
    );
  };

  const handleEditActionClick = (rowData: any) => {
    admediaryContext.openDrawer(
      <EditCampaignNumber
        data={rowData}
        formRef={mainFormRef}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  const handleRemoveActionClick = async () => {
    if (numberToRemove != 0) {
      const params = {
        phone_id: numberToRemove,
      };
      setNumberToRemove(0);
      try {
        const results: any = await Query("phone_number_remove", params);
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>({e})</>,
        });
      } finally {
        Notification.success({
          title: "Success",
          description: "Number has been removed",
        });
      }
      if (refreshData instanceof Function) {
        refreshData();
      }
    }
  };

  const handleAddAction = () => {
    admediaryContext.openDrawer(
      <EditCampaignNumber formRef={mainFormRef} refreshData={refreshData} />,
      "lg"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    data = data.map((row: any) => {
      return {
        ...row,
        entry_date: new Date(row.entry_date).toLocaleString(),
      };
    });
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["description", "phone_number", "phone_id", "entry_date"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
      <FlexboxGrid.Item>
        <InputGroup
          style={{ width: 125, marginRight: 15, display: "inline-block" }}
        >
          <Input
            placeholder="Search"
            onChange={(v) => {
              debounceSearchTerm(v);
            }}
            size="sm"
          />
        </InputGroup>
        <IconButton
          onClick={handleAddAction}
          placement="left"
          size="sm"
          appearance="ghost"
          icon={<Icon icon="plus-circle" />}
          disabled={admediaryContext.userDisable}
        >
          Add Number
        </IconButton>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <Table
          autoHeight={true}
          headerHeight={65}
          data={filter(sortData(data), searchQuery)}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          <Column align={"center"} sortable resizable>
            <HeaderCell>Active</HeaderCell>
            <CheckCell dataKey="active" />
          </Column>
          <Column width={100} align={"center"} sortable resizable>
            <HeaderCell>Number ID</HeaderCell>
            <Cell dataKey="phone_id" />
          </Column>
          <Column width={180} sortable resizable>
            <HeaderCell>Phone Number</HeaderCell>
            <Cell dataKey="phone_number" />
          </Column>
          <Column width={300} sortable resizable>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={80} sortable>
            <HeaderCell>Voice</HeaderCell>
            <CheckCell dataKey="voice" />
          </Column>
          <Column width={80} sortable>
            <HeaderCell>SMS</HeaderCell>
            <CheckCell dataKey="sms" />
          </Column>
          <Column width={80} sortable>
            <HeaderCell>MMS</HeaderCell>
            <CheckCell dataKey="mms" />
          </Column>
          <Column width={180} sortable>
            <HeaderCell>Created</HeaderCell>
            <Cell dataKey="entry_date" />
          </Column>
          <Column width={120} align={"center"}>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={(rowData: any) => {
                setNumberToRemove(rowData.phone_id);
              }}
            />
          </Column>
        </Table>

        <ConfirmModal
          title="Removing Number"
          open={numberToRemove != 0}
          onClose={() => setNumberToRemove(0)}
          onYes={handleRemoveActionClick}
        >
          Are you sure you want to remove this number?
        </ConfirmModal>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default NumbersReportDataTable;

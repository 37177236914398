import React from "react";
import { FlexboxGrid, Icon, IconButton, Table } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { useHistory } from "react-router";
import { sort } from "../../@Utils/Sorting";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";

const AffiliateList: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const history = useHistory();
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    add_subid: 1,
    checked: false,
    affiliate_id: 0,
    sid1: "",
    sid2: "",
    detail_by: "disposition",
    data_type: "date_sold",
    group: "affiliate",
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */

  const formatData = (list: any) => {
    return list.map((item: any) => {
      const rejected = item.rejected + item.filtered;
      const accepted_percentage = Math.ceil((item.leads_sold / rejected) * 100);
      const profit = item.price_received - item.price_owed;
      const profit_percentage = Math.ceil((profit / item.price_received) * 100);
      return {
        ...item,
        ...{
          leads_sold: item.leads_sold.toLocaleString(),
          filtered: item.filtered.toLocaleString(),
          rejected: item.rejected.toLocaleString(),
          acceptance_percentage: accepted_percentage,
          price_owed: item.price_owed.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          price_received: item.price_received.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          profit: profit.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          profit_percentage: profit_percentage,
        },
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  // fetch affiliates
  const [data, isLoading] = useAdmediaryApi("affiliate_tree_report", params);

  return (
    <>
      <div>
        <strong>Affiliate Report</strong>
      </div>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `affiliates_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                data
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        // height={650}
        autoHeight={true}
        loading={isLoading === true}
        data={Array.isArray(data) ? sortData(data) : []}
        hover
        onRowClick={(item) => {
          history.push(`/affiliate/${item.affiliate_id}`);
        }}
        rowClassName="clickable-data striped-rows"
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      >
        <Table.Column width={100} fixed sortable resizable>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.Cell dataKey="affiliate_id" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="affiliate_name" />
        </Table.Column>
        <Table.Column fixed resizable>
          <Table.HeaderCell>Accept %</Table.HeaderCell>
          <Table.Cell dataKey="acceptance_percentage">
            {(rowData: any) => (
              <span
                style={{
                  display: "inline-block",
                  height: "12px",
                  width: `${rowData.acceptance_percentage}%`,
                  background: "#ff311b",
                }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column align="right" sortable resizable>
          <Table.HeaderCell>Sold</Table.HeaderCell>
          <Table.Cell dataKey="leads_sold" />
        </Table.Column>
        <Table.Column align="right" sortable resizable>
          <Table.HeaderCell>Filtered</Table.HeaderCell>
          <Table.Cell dataKey="filtered" />
        </Table.Column>
        <Table.Column align="right" sortable resizable>
          <Table.HeaderCell>Rejected</Table.HeaderCell>
          <Table.Cell dataKey="rejected" />
        </Table.Column>
        <Table.Column align="right" sortable resizable>
          <Table.HeaderCell>Revenue</Table.HeaderCell>
          <Table.Cell dataKey="price_received" />
        </Table.Column>
        <Table.Column align="right" resizable>
          <Table.HeaderCell>Profit</Table.HeaderCell>
          <Table.Cell dataKey="profit" />
        </Table.Column>
        <Table.Column resizable>
          <Table.HeaderCell>Profit %</Table.HeaderCell>
          <Table.Cell dataKey="profit_percentage">
            {(rowData: any) => (
              <span
                style={{
                  display: "inline-block",
                  height: "12px",
                  width: `${rowData.profit_percentage}%`,
                  background: "#ff311b",
                }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column align="right" sortable resizable>
          <Table.HeaderCell>Cost</Table.HeaderCell>
          <Table.Cell dataKey="price_owed" />
        </Table.Column>
      </Table>
    </>
  );
};

export default AffiliateList;

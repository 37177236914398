import React from "react";
import {
  CheckboxGroup,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
} from "rsuite";

const Field: React.FC<any> = ({
  name,
  label,
  accepter,
  error,
  message = null,
  postfix = null,
  postfixStyles = {},
  ...props
}) => {
  postfixStyles = {
    ...{
      display: "inline-block",
      paddingLeft: 5,
      marginTop: 10,
    },
    ...postfixStyles,
  };

  // Set default values
  props.style = {
    ...{ width: 300 },
    ...props.style,
  };

  // Fixed next warning:
  // "index.js:1 Warning: Failed prop type: Invalid prop `children` of type `object` supplied to `defaultProps(CheckboxGroup)`, expected `array`."
  if (accepter === CheckboxGroup && !Array.isArray(props.children)) {
    props.children = [props.children];
  }

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label}</ControlLabel>
      <FormControl
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      {/*TODO Change the postfix span tag to InputGroup+InputGroup.Addon */}
      {postfix && (
        <span className={"field-postfix"} style={postfixStyles}>
          {postfix}
        </span>
      )}
      {message && <HelpBlock tooltip>{message}</HelpBlock>}
    </FormGroup>
  );
};

export default Field;

import React from "react";
import Dashboard from "../@Components/Dashboard";
import { FlexboxGrid, Button } from "rsuite";

const linkStyle = (rowIndex: number) => {
  const background = ["#385723", "#203864", "#C00000", "#767171"][rowIndex];
  return {
    backgroundColor: background,
    color: "#d4d4d4",
    // padding: "0.2em",
    // paddingBottom: "0.3em",
    borderRadius: "1em",
    border: "2px solid #444",
    fontWeight: 1000,
    fontSize: "1.1em",
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 4%)",
    marginBottom: 30,
    width: "95%",
    minHeight: "4.8em",
    lineHeight: "2",
    textShadow: "1px 1px #222",
  };
};

const colStyle = {
  minHeight: "4.1em",
};

const rowHeight = {};

const CallPrograms: React.FC = () => {
  return (
    <Dashboard>
      <h2 style={{ marginBottom: "1em" }}>
        Call Programs - Budgets & Offer Management
      </h2>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/10-G2JnBhAsW9jdl6MTKXhxI9GFj1MSzNGg8Cn5joBjM/edit#gid=386158829",
                "_blank"
              )
            }
          >
            March Tracker
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/u/1/d/1YYkQlfvbrqqHg18oM-iICpI946c0s0w0dmIsNEK5c5o/edit#gid=1405074530",
                "_blank"
              )
            }
          >
            February 2023 Tracker
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Dashboard>
  );
};

export default CallPrograms;

import React from "react";
import Dashboard from "../@Components/Dashboard";
import { Col, Content, Grid, Row } from "rsuite";
import LeadsByDayLineGraph from "../@Components/Visualization/LeadsByDayLineGraph";
import TopAffiliatesByLeadsBarGraph from "../@Components/Visualization/TopAffiliatesByLeadsBarGraph";
import { useParams } from "react-router";
import LeadList from "../@Components/Leads/LeadList";
import { AdmediaryContext } from "../@Context/AdmediaryContext";
import BuyerContractsOverview from "../@Components/Drawers/BuyerContractsOverview";

const BuyerDetails: React.FC = () => {
  const admediary_context = React.useContext(AdmediaryContext);

  const { buyer_id } = useParams();

  const details = admediary_context.config.contracts_by_buyer
    ? admediary_context.config.contracts_by_buyer[buyer_id]
    : null;

  const viewContractOverview = (e: any) => {
    e.preventDefault();
    admediary_context.openDrawer(
      <BuyerContractsOverview buyer_id={parseInt(buyer_id)} />
    );
  };

  return (
    <Dashboard>
      {!details ? (
        "Still loading"
      ) : (
        <>
          <Content>
            <h5>{details.buyer_name} Details</h5>
            <a onClick={viewContractOverview} href="!#">
              View Contracts
            </a>
          </Content>
          <Grid fluid>
            <Row className="show-grid">
              <Col className="content-card chart" xs={24} sm={24} md={12}>
                <Content>
                  <h5>Leads by Day</h5>
                  <div style={{ height: 295 }}>
                    <LeadsByDayLineGraph buyer_id={buyer_id} />
                  </div>
                </Content>
              </Col>
              <Col className="content-card chart" xs={24} sm={24} md={12}>
                <Content>
                  <h5>Top Affiliates</h5>
                  <div style={{ height: 295 }}>
                    <TopAffiliatesByLeadsBarGraph buyer_id={buyer_id} />
                  </div>
                </Content>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col className="content-card chart" xs={24}>
                <Content style={{ height: "100%" }}>
                  <LeadList buyer_id={parseInt(buyer_id)} />
                </Content>
              </Col>
            </Row>
          </Grid>
        </>
      )}
    </Dashboard>
  );
};

export default BuyerDetails;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Table } from "rsuite";
import {
  FloatCell,
  HeaderSummaryToolTip,
  IconToolTip,
  NumberCell,
  ParentCell,
  PercentCell,
} from "../Table";
import SelectPicker from "rsuite/lib/SelectPicker";
import { sort, sortTree } from "../../@Utils/Sorting";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { format, parseISO } from "date-fns";
import { exportCsv } from "../../@Utils/Export";
import { float, percent, thousands } from "../../@Utils/Format";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Title from "../Title";

const { Column, ColumnGroup, HeaderCell, Cell } = Table;

type ResponseTimeProps = {
  startDate?: Date;
  endDate?: Date;
  buyerId?: number;
  contractId?: number;
  affiliateId?: number;
  sid1?: string;
  disposition?: string;
  groupBy?: string;
  isDrawer?: boolean;
};

const ResponseTimes: React.FC<ResponseTimeProps> = ({
  startDate,
  endDate,
  buyerId = 0,
  contractId = 0,
  affiliateId = 0,
  disposition = "",
  sid1 = "",
  groupBy = "buyer",
  isDrawer = false,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultGroupValue = "buyer";
  const [group, setGroup] = React.useState(groupBy);

  const params = {
    start_date: startDate || Admediary.start,
    end_date: endDate || Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: buyerId > 0 ? buyerId : Admediary.buyer,
    group: group,
    affiliate_id: affiliateId,
    contract_id: contractId,
    sid1,
    disposition,
  };

  const groupByList = [
    { group: "buyer", label: "Buyer" },
    // {group: "tier",  label: "Tier"},
    { group: "hour", label: "Hour" },
  ];

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Get sorted data with calculated fields
   * @param list
   */
  const getTreeData = (list: any) => {
    const data = addCalculatedValuesRecursively(list);

    // TODO Add data formatting
    if (sortColumn && sortType) {
      return sortTree(data, sortColumn, sortType);
    }

    return data;
  };

  const getParentTitle = (groupBy: string) => {
    const titles = new Map([
      ["entry_date", "Contract/Date"],
      ["affiliate_id", "Contract/Affiliate/Date"],
      ["disposition", "Contract/Disposition/Date"],
      ["buyer", "Buyer/Contract"],
      ["tier", "Tier/Buyer/Contract"],
      ["hour", "Buyer/Contract/Hour"],
    ]);

    return titles.has(groupBy) ? titles.get(groupBy) : "Parent Title";
  };

  // const [data, isLoading] = useAdmediaryApi("response_time_tree", params);
  const [data, isLoading] = useAdmediaryApiManual("response_time_tree", params);

  const summary = getSummary(Array.isArray(data) ? data : []);

  const priorityData = Array.isArray(data) ? getTreeData(data) : [];

  const getPriorityHighestValue = priorityData.map((item: any, index: any) => {
    const children = item.children;
    const priorities = children.map((child: any) => {
      return child.priority;
    });

    return Math.max(...priorities);
  });

  const setPriorities = priorityData.map((item: any, index: any) => {
    return {
      ...item,
      priority: getPriorityHighestValue[index],
    };
  });

  const formatPriorities = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        priority: null,
      };
    });
  };

  const sortTreeData = (data: any) => {
    if (sortColumn && sortType) {
      return formatPriorities(sortTree(data, sortColumn, sortType));
    }

    return formatPriorities(data);
  };

  return (
    <>
      <Title title="Response Times" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        {!isDrawer && (
          <FlexboxGrid.Item colspan={19}>
            <SelectPicker
              size="sm"
              searchable={false}
              cleanable={false}
              placeholder="Detail by"
              defaultValue={defaultGroupValue}
              value={group}
              data={groupByList}
              valueKey="group"
              labelKey="label"
              onChange={(v) => {
                setGroup(v);
              }}
              style={{ marginRight: 15 }}
            />
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item
          colspan={isDrawer ? 24 : 3}
          style={{ textAlign: "right" }}
        >
          <IconButton
            onClick={() => {
              exportCsv(
                `response_times_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                getExportData(data, group)
              );
            }}
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        isTree
        rowKey={rowKey}
        // height={650}
        autoHeight={true}
        headerHeight={120}
        virtualized
        affixHeader
        affixHorizontalScrollbar
        // data={Array.isArray(data) ? getTreeData(setPriorities) : []}
        data={sortTreeData(setPriorities)}
        loading={isLoading === true}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={240} fixed sortable resizable>
          <HeaderCell>{getParentTitle(group)}</HeaderCell>
          <ParentCell dataKey="parent_title" />
        </Column>

        <Column align="right" sortable resizable>
          <HeaderCell>Considered</HeaderCell>
          <NumberCell dataKey="considered" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Filtered</HeaderCell>
          <NumberCell dataKey="filtered" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Filter %</HeaderCell>
          <PercentCell dataKey="filter_percent" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Presented</HeaderCell>
          <NumberCell dataKey="presented" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Accepted</HeaderCell>
          <NumberCell dataKey="accepted" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Accept %</HeaderCell>
          <PercentCell dataKey="accept_percent" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Priority</HeaderCell>
          <Cell dataKey="priority" />
        </Column>
        <Column align="right" sortable resizable>
          <HeaderCell>Rejected</HeaderCell>
          <NumberCell dataKey="rejected" />
        </Column>
        <ColumnGroup
          align="left"
          header={
            <>
              <span>Buyer Response Time</span>
              <IconToolTip description="Buyer Response Time" />
            </>
          }
        >
          <Column width={80} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Avg"
                description="Average value"
                summary={float(summary.avg_post_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="avg_post_time" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Fastest"
                description="Fastest value"
                summary={float(summary.min_post_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="min_post_time" />
          </Column>
          <Column width={120} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Slowest"
                description="Slowest value"
                summary={float(summary.max_post_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="max_post_time" />
          </Column>
        </ColumnGroup>
        <ColumnGroup
          align="left"
          header={
            <>
              <span>Total Submission Time</span>
              <IconToolTip description="Total Submission Time" />
            </>
          }
        >
          <Column width={80} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Avg"
                description="Average value"
                summary={float(summary.avg_total_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="avg_total_time" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Fastest"
                description="Fastest value"
                summary={float(summary.min_total_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="min_total_time" />
          </Column>
          <Column width={120} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummaryToolTip
                title="Slowest"
                description="Slowest value"
                summary={float(summary.max_total_time)}
              />
            </HeaderCell>
            <FloatCell dataKey="max_total_time" />
          </Column>
        </ColumnGroup>
      </Table>
    </>
  );
};

function getExportData(data: any, groupBy: string) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        ...{
          contract_id: item.contract_id || parent.contract_id,
          contract_name: item.contract_name || parent.contract_name,
          affiliate_id: item.affiliate_id || parent.affiliate_id,
          affiliate_name: item.affiliate_name || parent.affiliate_name,
          disposition: item.disposition || parent.disposition,
        },
        ...item,
      };

      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });

    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        item.filter_percent =
          item.considered !== 0 ? (item.filtered / item.considered) * 100 : 0;
        item.accept_percent =
          item.presented !== 0 ? (item.accepted / item.presented) * 100 : 0;
        try {
          item.entry_date = item.entry_date
            ? format(parseISO(item.entry_date), "Y-MM-dd HH:mm")
            : "";
        } catch (e) {
          item.entry_date = "";
        } finally {
        }

        const parentFields = new Map();
        parentFields.set("entry_date", {
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
          "Entry Date": item.entry_date,
        });
        parentFields.set("affiliate_id", {
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
          "Affiliate ID": item.affiliate_id,
          "Affiliate Name": item.affiliate_name,
          "Entry Date": item.entry_date,
        });
        parentFields.set("disposition", {
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
          Disposition: item.disposition,
          "Entry Date": item.entry_date,
        });
        parentFields.set("buyer", {
          "Buyer ID": item.buyer_id,
          "Buyer Name": item.buyer_name,
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
        });
        parentFields.set("tier", {
          "Tier ID": item.tier_id,
          "Tier Name": item.tier_name,
          "Buyer ID": item.buyer_id,
          "Buyer Name": item.buyer_name,
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
        });
        parentFields.set("hour", {
          "Buyer ID": item.buyer_id,
          "Buyer Name": item.buyer_name,
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
          "Entry Date": item.entry_date,
        });

        const parentColumnResults = parentFields.has(groupBy)
          ? parentFields.get(groupBy)
          : parentFields.get("entry_date");

        return {
          ...parentColumnResults,
          ...{
            Considered: thousands(item.considered),
            Filtered: thousands(item.filtered),
            "Filter %": percent(parseFloat(item.filter_percent)),
            Presented: thousands(item.presented),
            Accepted: thousands(item.accepted),
            "Accept %": percent(parseFloat(item.accept_percent)),
            Priority: parseFloat(item.priority),
            Rejected: thousands(item.rejected),
            "Avg Post": float(item.avg_post_time, 2),
            "Min Post": float(item.min_post_time, 2),
            "Max Post": float(item.max_post_time, 2),
            "Avg Total": float(item.total_time, 2),
            "Min Total": float(item.min_total_time, 2),
            "Max Total": float(item.max_total_time, 2),
          },
        };
      })
  );
}

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = ["considered"];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  // Calculate min values: min_post_time, min_total_time
  const minPostTimeValues = data
    .map((item: { [index: string]: string }): number => {
      return isNaN(parseFloat(item["min_post_time"]))
        ? 0
        : parseFloat(item["min_post_time"]);
    })
    .filter((item: number) => item > 0);
  summary["min_post_time"] =
    minPostTimeValues.length > 0 ? Math.min(...minPostTimeValues) || 0 : 0;

  const minTotalTimeValues = data
    .map((item: { [index: string]: string }): number => {
      return isNaN(parseFloat(item["min_total_time"]))
        ? 0
        : parseFloat(item["min_total_time"]);
    })
    .filter((item: number) => item > 0);
  summary["min_total_time"] =
    minTotalTimeValues.length > 0 ? Math.min(...minTotalTimeValues) || 0 : 0;

  // Calculate max values: max_post_time, max_total_time
  const maxPostTimeValues = data.map(
    (item: { [index: string]: string }): number => {
      return isNaN(parseFloat(item["max_post_time"]))
        ? 0
        : parseFloat(item["max_post_time"]);
    }
  );
  summary["max_post_time"] =
    maxPostTimeValues.length > 0 ? Math.max(...maxPostTimeValues) || 0 : 0;

  const maxTotalTimeValues = data.map(
    (item: { [index: string]: string }): number => {
      return isNaN(parseFloat(item["max_total_time"]))
        ? 0
        : parseFloat(item["max_total_time"]);
    }
  );
  summary["max_total_time"] =
    maxTotalTimeValues.length > 0 ? Math.max(...maxTotalTimeValues) || 0 : 0;

  // Calculate average values: avg_post_time, avg_total_time
  const avgPostTimeSummary =
    data
      .map((item: { [index: string]: string }): number => {
        const time = isNaN(parseFloat(item["avg_post_time"]))
          ? 0
          : parseFloat(item["avg_post_time"]);
        return time * parseInt(item["considered"]);
      })
      .reduce(summarizer, 0) || 0;
  summary["avg_post_time"] = summary["considered"]
    ? avgPostTimeSummary / summary["considered"]
    : 0;

  const avgTotalTimeSummary =
    data
      .map((item: { [index: string]: string }): number => {
        const time = isNaN(parseFloat(item["avg_total_time"]))
          ? 0
          : parseFloat(item["avg_total_time"]);
        return time * parseInt(item["considered"]);
      })
      .reduce(summarizer, 0) || 0;
  summary["avg_total_time"] = summary["considered"]
    ? avgTotalTimeSummary / summary["considered"]
    : 0;

  return summary;
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

function addCalculatedValues(row: any) {
  try {
    row.entry_date = row.entry_date
      ? format(parseISO(row.entry_date), "Y-MM-dd HH:mm")
      : "";
  } catch (e) {
    row.entry_date = "";
  } finally {
  }

  row.accepted = parseFloat(row.accepted);
  row.considered = parseFloat(row.considered);
  row.presented = parseFloat(row.presented);
  row.rejected = parseFloat(row.rejected);
  row.avg_post_time = parseFloat(row.avg_post_time);
  row.min_post_time = parseFloat(row.min_post_time);
  row.max_post_time = parseFloat(row.max_post_time);
  row.avg_total_time = parseFloat(row.avg_total_time);
  row.min_total_time = parseFloat(row.min_total_time);
  row.max_total_time = parseFloat(row.max_total_time);
  row.priority = row.priority ? parseFloat(row.priority) : "";

  return {
    ...row,
    ...{
      cpl: row.accepted !== 0 ? row.rate / row.accepted : 0,
      rpl: row.accepted !== 0 ? row.earned / row.accepted : 0,
      ppl: row.accepted !== 0 ? row.profit / row.accepted : 0,
      filter_percent:
        row.considered !== 0 ? (row.filtered / row.considered) * 100 : 0,
      accept_percent:
        row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
      // priority: row.priority ? row.priority : '',
    },
  };
}

export default ResponseTimes;

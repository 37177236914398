import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  FlexboxGrid,
  Form,
  Icon,
  IconButton,
  InputNumber,
  Modal,
  Notification,
  Table,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { IconsActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractFieldForm from "./BuyerContractFieldForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import BuyerContractFieldMapList from "./BuyerContractFieldMapList";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import BuyerContractCustomFieldList from "./BuyerContractCustomFieldList";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractFieldListType = {
  contractId: number;
  categoryId?: number;
  height?: number;
  autoHeight?: boolean;
  pingType?: boolean;
};

const BuyerContractFieldList: React.FC<BuyerContractFieldListType> = ({
  contractId = 0,
  categoryId = 0,
  height = 650,
  autoHeight = true,
  pingType = false,
}) => {
  height = autoHeight ? 100 : height;
  const Forms = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const [data, isLoading] = useAdmediaryApi("buyer_contract_fields", params);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    (async () => {
      try {
        await Query("buyer_contract_field_update", data);

        setFormLoading(false);

        Notification.success({
          title: "Success",
          description:
            "Field has been " + (data.field_id ? "updated" : "added"),
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        setFormLoading(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_field_remove", { field_id: itemId });
        Notification.success({
          title: "Success",
          description: "Field has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.field_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleMapActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleFieldMapModalOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem({
      field_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  const [fieldMapModalOpen, setFieldMapModalOpen] = React.useState(false);
  const handleFieldMapModalOpen = () => setFieldMapModalOpen(true);
  const handleFieldMapModalClose = () => setFieldMapModalOpen(false);

  const [customFieldsModalOpen, setCustomFieldsModalOpen] = React.useState(
    false
  );
  const handleCustomFieldsModalOpen = () => setCustomFieldsModalOpen(true);
  const handleCustomFieldsModalClose = () => setCustomFieldsModalOpen(false);

  const setPostUrl = (data: any) => {
    Forms.setPostUrl(data);
  };
  const setTimeout = (data: any) => {
    Forms.setTimeout(data);
  };

  const setPingUrl = (data: any) => {
    Forms.setPingUrl(data);
  };
  const setPingTimeout = (data: any) => {
    Forms.setPingTimeout(data);
  };
  const setPingMinimumPrice = (data: any) => {
    Forms.setPingMinimumPrice(data);
  };
  const setActive = (data: any) => {
    Forms.setActive((data[0] ?? 0) == 1);
  };

  return (
    <>
      <FlexboxGrid justify="start" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={4}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Field
          </IconButton>
          {!pingType && (
            <IconButton
              onClick={handleCustomFieldsModalOpen}
              icon={<Icon icon="list" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginTop: 36, marginRight: 15 }}
              disabled={Admediary.userDisable}
            >
              Custom Fields
            </IconButton>
          )}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={20}>
          {!pingType && (
            <Form layout="horizontal">
              <Field
                label="Post URL"
                name="post_url"
                value={Forms.postUrl}
                onChange={setPostUrl}
                style={{ width: 400 }}
                disabled={Admediary.userDisable}
              />
              <Field
                label="Timeout"
                name="timeout"
                value={Forms.timeout}
                onChange={setTimeout}
                style={{ width: 400 }}
                disabled={Admediary.userDisable}
              />
            </Form>
          )}
          {pingType && (
            <Form layout="horizontal">
              <Field
                label="Ping URL"
                name="ping_url"
                value={Forms.pingUrl}
                onChange={setPingUrl}
                style={{ width: 400 }}
                disabled={Admediary.userDisable}
              />
              <Field
                label="Timeout"
                name="ping_timeout"
                value={Forms.pingTimeout}
                onChange={setPingTimeout}
                style={{ width: 400 }}
                accepter={InputNumber}
                min={0}
                step={1}
                disabled={Admediary.userDisable}
              />
              <Field
                label="Ping Minimum Price"
                name="ping_minimum_price"
                value={Forms.pingMinimumPrice}
                onChange={setPingMinimumPrice}
                style={{ width: 400 }}
                accepter={InputNumber}
                min={0}
                step={0.01}
                disabled={Admediary.userDisable}
              />
              <Field
                label="Active"
                name="ping_active"
                accepter={CheckboxGroup}
                onChange={setActive}
                value={Forms.active ? [1] : []}
              >
                <Checkbox value={1} disabled={Admediary.userDisable} />
              </Field>
            </Form>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={
          isLoading === true
            ? []
            : sortData(
                Array.isArray(data)
                  ? data[0] != null && data[0]["contract_id"] == contractId
                    ? data
                    : []
                  : []
              )
        }
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={150} align={"center"} fixed>
          <HeaderCell>Action</HeaderCell>
          <IconsActionCell
            icons={
              Admediary.userDisable
                ? [{ icon: "setting", handler: handleMapActionClick }]
                : [
                    { icon: "edit2", handler: handleEditActionClick },
                    { icon: "setting", handler: handleMapActionClick },
                    { icon: "trash", handler: handleRemoveActionClick },
                  ]
            }
          />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Method</HeaderCell>
          <Cell dataKey="is_get">
            {(rowData: any) => (rowData.is_get ? "GET" : "POST")}
          </Cell>
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Field Name</HeaderCell>
          <Cell dataKey="parameter" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Field Value</HeaderCell>
          <Cell dataKey="field" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Field?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.field_id > 0 ? "Edit" : "Add") +
          " Field"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
        loading={formLoading}
      >
        <BuyerContractFieldForm
          fieldId={selectedItem ? selectedItem.field_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>

      <Modal show={fieldMapModalOpen} onHide={handleFieldMapModalClose}>
        <Modal.Header>
          <Modal.Title>Field Maps</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BuyerContractFieldMapList
            fieldId={selectedItem ? selectedItem.field_id : 0}
            height={350}
            autoHeight={false}
          />
        </Modal.Body>
      </Modal>

      <Modal show={customFieldsModalOpen} onHide={handleCustomFieldsModalClose}>
        <Modal.Header>
          <Modal.Title>Custom Fields Administration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BuyerContractCustomFieldList
            categoryId={categoryId}
            height={350}
            autoHeight={false}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyerContractFieldList;

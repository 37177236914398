import React from "react";
import {
  Button,
  ButtonToolbar,
  Drawer,
  Form,
  Icon,
  Loader,
  Modal,
  Notification,
  Schema,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import _ from "lodash";
import { FormInstance } from "rsuite/lib/Form/Form";
import ServiceForm from "../Data/ServiceForm";
import Field from "../Field";

const { StringType } = Schema.Types;

const model = Schema.Model({
  interface_url: StringType(),
  get_post_data: StringType(),
  server_response: StringType(),
});

type ServiceType = {
  serviceId: number;
  refreshData?: () => void;
  cloneData?: any;
};

const Service: React.FC<ServiceType> = ({
  serviceId = 0,
  refreshData,
  cloneData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const [show, setShow] = React.useState(false);
  const [cloneShow, setCloneShow] = React.useState(false);
  const [postDataFormValue, setPostDataFormValue] = React.useState();
  const [formError, setFormError] = React.useState({});
  const [postData, setPostData] = React.useState();
  const [interfaceUrl, setInterfaceUrl] = React.useState();
  const [interfaceType, setInterfaceType] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
    setPostData(formValue.post_data);
    setInterfaceUrl(formValue.interface_url);
    setInterfaceType(formValue.interface_type);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transformed to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }

    if (Array.isArray(data.validate_data)) {
      data.validate_data = data.validate_data.length
        ? data.validate_data[0]
        : 0;
    }

    data.feed_delay =
      data.feed_delay_days * 86400 +
      data.feed_delay_hours * 3600 +
      data.feed_delay_minutes * 60;
    data.resubmit_interval =
      data.resubmit_interval_days * 86400 +
      data.resubmit_interval_hours * 3600 +
      data.resubmit_interval_minutes * 60;
    data.sold_lead_delay =
      data.sold_delay_days * 86400 +
      data.sold_delay_hours * 3600 +
      data.sold_delay_minutes * 60;

    (async () => {
      try {
        await Query("data_service_update", data);
        Notification.success({
          title: "Success",
          description: "Data Service has been updated",
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const hideModal = () => {
    setShow(false);
  };

  const cancelModal = () => {
    hideModal();
  };

  const handleTestButtonClick = () => {
    fetchRecordData();
    setShow(true);
  };

  const fetchRecordData = async () => {
    try {
      setIsLoading(true);
      const recordResults: any = await Query("record_data_service_test", {});
      if (recordResults && Array.isArray(recordResults.data)) {
        const params = {
          record_id: recordResults.data[0].record_id,
          post_data: postData,
        };

        parsePostData(params);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  // @ts-ignore
  const parsePostData = async (params: any) => {
    try {
      const results: any = await Query("service_test_record_data", params);
      if (Array.isArray(results.data)) {
        setPostDataFormValue({
          ...postDataFormValue,
          ...{
            interface_url: interfaceUrl,
            get_post_data: results.data[0].post_data,
          },
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleTestServiceSubmit = async () => {
    try {
      const postParams = {
        interface_url: interfaceUrl,
        post_data: postDataFormValue.get_post_data,
        interface_type: interfaceType,
      };

      const results: any = await Query("data_service_test_post", postParams);

      setPostDataFormValue({
        ...postDataFormValue,
        ...{
          server_response: JSON.stringify(results.data[0]),
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!serviceId ? "Edit" : "Add"} Service
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <ServiceForm
          serviceId={serviceId}
          formRef={mainFormRef}
          parentCallback={handleCallback}
          clonedData={cloneData}
        />
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            disabled={Admediary.userDisable}
          >
            Save
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
          <Button appearance="subtle" onClick={handleTestButtonClick}>
            Test
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>

      <Modal size="md" show={show} onHide={hideModal} overflow={true}>
        <Modal.Header>
          <Modal.Title>Test Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loader center size="lg" content="Loading..." />
          ) : (
            <Form
              model={model}
              layout="horizontal"
              formValue={postDataFormValue}
              formError={formError}
            >
              <Field label="Interface URL" name="interface_url" />

              <Field
                label="Get/Post Data"
                name="get_post_data"
                componentClass="textarea"
                rows={12}
                onChange={(v: any) =>
                  setPostDataFormValue({
                    ...postDataFormValue,
                    ...{ get_post_data: v },
                  })
                }
              />

              <Field
                label="Server Response"
                name="server_response"
                componentClass="textarea"
                rows={12}
              />
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={handleTestServiceSubmit}>
            Submit
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Service;

import React from "react";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const Buyers: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const config: any = Admediary.config;
  const location = useLocation();
  const [showing, setShowing] = useState(false);

  React.useEffect(() => {
    location.pathname === "/direct-posts/post-summary" ||
    location.pathname === "/direct-posts/post-leads" ||
    location.pathname === "/products/performance" ||
    location.pathname === "/data/sms-delivery" ||
    location.pathname === "/products/products" ||
    location.pathname === "/data/voice-campaign" ||
    location.pathname === "/data/voice-activity" ||
    location.pathname === "/products/data-validation" ||
    location.pathname === "/affiliates/common-list" ||
    location.pathname === "/buyers/contracts" ||
    location.pathname === "/buyers/contract-filters" ||
    location.pathname === "/buyers/routing" ||
    location.pathname === "/data/data-report-incoming" ||
    location.pathname === "/products/buy-hold" ||
    location.pathname === "/leads" ||
    location.pathname === "/flow/tree-report" ||
    location.pathname === "/products/record-search" ||
    location.pathname === "/products/ping-trees" ||
    location.pathname === "/products/campaigns" ||
    location.pathname === "/products/pixel-percentage" ||
    location.pathname === "/data/active-sources" ||
    location.pathname === "/data/outgoing-report" ||
    location.pathname === "/affiliates/esign-affiliates" ||
    location.pathname === "/products/gp-report" ||
    location.pathname === "/cake/cake-offers" ||
    location.pathname === "/reports/external-reports" ||
    location.pathname === "/reports/call-programs" ||
    location.pathname === "/data/services" ||
    location.pathname === "/campaigns/numbers" ||
    location.pathname === "/leads/disposition-lookup" ||
    location.pathname === "/products/categories"
      ? hideSelect()
      : setShowing(true);
  }, [location]);

  const hideSelect = () => {
    Admediary.setBuyer(null);
    setShowing(false);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setBuyer = (data: any) => {
    Admediary.setBuyer(data);
  };

  const onKeyEnter = (value: any, item: any, event: any) => {
    if (event.keyCode === 13) {
      Admediary.setRun(true);
    }
  };

  const Select = () => (
    <SelectPicker
      placeholder="All Buyers"
      data={config.buyers}
      labelKey="buyer_name"
      valueKey="buyer_id"
      onChange={setBuyer}
      value={Admediary.buyer}
      menuStyle={{ width: "340px" }}
      onSelect={(value: any, item: any, event: any) =>
        onKeyEnter(value, item, event)
      }
    />
  );

  return <div>{showing ? <Select /> : null}</div>;
};

export default Buyers;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import DocumentForm from "./DocumentForm";
import { Query, PostQuery } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type DocumentListType = {
  affiliateId: number;
  height?: number;
};

const DocumentList: React.FC<DocumentListType> = ({
  affiliateId = 0,
  height = 650,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date ? format(item.entry_date, "MM-dd-Y") : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    affiliate_id: affiliateId,
  };
  const [data, isLoading] = useAdmediaryApi("affiliate_documents", params);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    const query = {
      affiliate_id: data.affiliate_id,
      document_name: data.document_name,
      document_type: data.document_type,
    };
    const formData = new FormData();
    formData.append("document_file", data.document_file[0].blobFile);

    PostQuery("affiliate_document_upload", formData, query, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setFormLoading(false);
        Notification.success({
          title: "Success",
          description: "Document has been uploaded",
        });
        handleEditModalClose();
        refresh();
      })
      .catch((error) => {
        setFormLoading(false);
        console.log(error);
      });
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }
    (async () => {
      try {
        await Query("affiliate_document_remove", { document_id: itemId });
        Notification.success({
          title: "Success",
          description: "Document has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.document_id);

    handleClose();
  };

  const handleDownloadActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    const itemId = rowData.document_id;

    Query("affiliate_document_download", { document_id: itemId })
      .then((response) => {
        const documentUrl = response.data.document_url;
        const n = documentUrl.lastIndexOf("/");
        const fileName = documentUrl.substring(n + 1);
        const link = document.createElement("a");
        link.href = documentUrl;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: error,
        });
      });
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddClick = () => {
    setSelectedItem({
      document_id: 0,
      affiliate_id: affiliateId,
    });

    handleEditModalOpen();
  };

  return (
    <>
      <FlexboxGrid
        justify="space-between"
        style={{ marginTop: 15, marginBottom: 15 }}
      >
        <FlexboxGrid.Item colspan={5}>
          <IconButton
            onClick={handleAddClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
          >
            Add Document
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={100} align={"center"} fixed>
          <HeaderCell>Action</HeaderCell>
          <Cell className="link-group">
            {(rowData: any) => {
              return (
                <>
                  <IconButton
                    appearance="subtle"
                    onClick={() => handleDownloadActionClick(rowData)}
                    icon={<Icon icon="download" />}
                  />
                  <IconButton
                    appearance="subtle"
                    onClick={() => handleRemoveActionClick(rowData)}
                    icon={<Icon icon="trash" />}
                  />
                </>
              );
            }}
          </Cell>
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="document_name" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Document Type</HeaderCell>
          <Cell dataKey="document_type" />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>Uploaded</HeaderCell>
          <Cell dataKey="entry_date" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Document?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.document_id > 0 ? "Edit" : "Add") +
          " Document"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
        loading={formLoading}
      >
        <DocumentForm
          affiliateId={selectedItem ? selectedItem.affiliate_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default DocumentList;

import React from "react";
import Dashboard from "../@Components/Dashboard";
import { Grid, Row, Col, Content } from "rsuite";
import { AdmediaryContext } from "../@Context/AdmediaryContext";
import TopBuyerAlerts from "../@Components/Alerts/TopBuyerAlerts";
import TopAffiliateAlerts from "../@Components/Alerts/TopAffiliateAlerts";
import RevenuePieGraph from "../@Components/Visualization/RevenuePieGraph";
import TopBuyersByLeadsBarGraph from "../@Components/Visualization/TopBuyersByLeadsBarGraph";
import ConsideredVsAcceptedBarGraph from "../@Components/Visualization/ConsideredVsAcceptedBarGraph";
import TopVerticalsByLeadsBarGraph from "../@Components/Visualization/TopVerticalsByLeadsBarGraph";
import TopAffiliatesByLeadsBarGraph from "../@Components/Visualization/TopAffiliatesByLeadsBarGraph";
import LeadsByDayLineGraph from "../@Components/Visualization/LeadsByDayLineGraph";
import BudgetsByBuyer from "../@Components/Budgets/BudgetsByBuyer";
import PrimaryStatCards from "../@Components/Visualization/PrimaryStatCards";
import ConsideredVsAcceptedBarGraphByCategory from "../@Components/Visualization/ConsideredVsAcceptedBarGraphByCategory";

const Home: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [Cost, setCost] = React.useState<number | undefined>();
  const [Profit, setProfit] = React.useState<number | undefined>();

  /**
   * Capture data coming from Top Stat Card component
   * @param data
   */
  const captureStatCardData = (data: any) => {
    if (data.cost !== null && data.profit !== null) {
      setProfit(data.profit);
      setCost(data.cost);
    }
  };

  return (
    <Dashboard>
      <PrimaryStatCards success={captureStatCardData} />
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24} sm={12} md={7} lg={6}>
            <Content>
              <h5>Costs vs Profit</h5>
              <div style={{ height: 295 }}>
                <RevenuePieGraph costs={Cost} profit={Profit} />
              </div>
            </Content>
          </Col>
          <Col className="content-card chart" xs={24} sm={12} md={10} lg={12}>
            <Content>
              <h5>Top Buyers by Leads Purchased</h5>
              <div style={{ height: 295 }}>
                <TopBuyersByLeadsBarGraph />
              </div>
            </Content>
          </Col>
          <Col
            className="content-card chart primary"
            xs={24}
            sm={24}
            md={7}
            lg={6}
          >
            <Content>
              <h5>Buyer Alerts</h5>
              <TopBuyerAlerts />
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={12} sm={12} md={6} lg={6}>
            <Content>
              <h5>Considered vs Accepted</h5>
              <div style={{ height: 295 }}>
                <ConsideredVsAcceptedBarGraph showLegend={false} />
              </div>
            </Content>
          </Col>
          <Col className="content-card chart" xs={12} sm={12} md={12} lg={12}>
            <Content>
              <h5>Budgets by Buyer</h5>
              <BudgetsByBuyer />
            </Content>
          </Col>
          <Col
            className="content-card chart primary-inverse"
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Content>
              <h5>Affiliate Alerts</h5>
              <TopAffiliateAlerts />
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content>
              <h5>Top Considered vs Accepted by Product</h5>
              <div style={{ height: 295 }}>
                <ConsideredVsAcceptedBarGraphByCategory />
              </div>
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24} sm={24} md={12}>
            <Content>
              <h5>
                Top {!Admediary.category ? "Verticals" : "Products"} by Leads
                Purchased
              </h5>
              <div style={{ height: 295 }}>
                <TopVerticalsByLeadsBarGraph />
              </div>
            </Content>
          </Col>
          <Col className="content-card chart" xs={24} sm={24} md={12}>
            <Content>
              <h5>Top Affiliates by Leads Purchased</h5>
              <div style={{ height: 295 }}>
                <TopAffiliatesByLeadsBarGraph />
              </div>
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content>
              <h5>Leads by Day</h5>
              <div style={{ height: 295 }}>
                <LeadsByDayLineGraph />
              </div>
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default Home;

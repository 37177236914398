import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Title from "../Title";
import { Query } from "../../@Utils/AdmediaryApi";
import ProductCategoriesTable from "./ProductCategoriesTable";
import EditProductCategory from "../Drawers/EditProductCategory";

const ProductCategories: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [data, setData] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState("category_id");
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("categories", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [refreshFlag]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) =>
      (isNaN(v) ? v.toLowerCase() : v.toString()).includes(search);
    const fields = ["category_id", "category_name"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field] ?? ""))
    );
  };

  const handleAddCategoryClick = () => {
    // Set selected content into drawer
    Admediary.openDrawer(
      <EditProductCategory category_id={0} refreshData={refresh} />,
      "lg"
    );
  };
  return (
    <>
      <Title title="Product Categories" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={16}>
          <InputGroup
            style={{ width: 200, marginRight: 20, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <IconButton
            onClick={handleAddCategoryClick}
            icon={<Icon icon="plus-circle" />}
            placement="right"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 10 }}
            disabled={Admediary.userDisable}
          >
            Add Product Category
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <ProductCategoriesTable
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default ProductCategories;

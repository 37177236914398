import { Parser } from "json2csv";

/**
 * Export lead data to CSV
 * @param filename
 * @param data
 */
export const exportCsv = (filename: string, data: any) => {
  if (data.length > 0) {
    try {
      const parser = new Parser();
      const csv = parser.parse(data);
      const hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      // hiddenElement.href = "data:text/csv;charset=utf-8," + csv;
      hiddenElement.target = "_blank";
      hiddenElement.download = filename;
      hiddenElement.click();
    } catch (err) {
      console.log(err);
    }
  }
};

import React from "react";
import { FlexboxGrid, Table } from "rsuite";
import RecordDetail from "../Products/RecordDetail";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type RecentRecordProps = {
  data: any;
  isLoading: boolean;
};

const RecentRecordsList: React.FC<RecentRecordProps> = (props: any) => {
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [dataSourcedId, setDataSourceId] = React.useState();
  const [recordId, setRecordId] = React.useState();
  const data = props.data;
  const isLoading = props.isLoading;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openRecordDetail = (rowData: any) => {
    setRecordId(rowData.record_id);
    setDataSourceId(rowData.data_source_id);
    setOpen(true);
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item
          colspan={24}
          style={{ textAlign: "left" }}
        ></FlexboxGrid.Item>
      </FlexboxGrid>
      <RecordDetail
        title="Request Details"
        open={open}
        onClose={handleClose}
        onYes={handleOpen}
        recordId={recordId}
        dataSourcedId={dataSourcedId}
        serviceId={
          Array.isArray(data) && typeof data[0] !== "undefined"
            ? data[0].service_id
            : 0
        }
      ></RecordDetail>
      <Table
        height={650}
        virtualized
        affixHeader
        affixHorizontalScrollbar
        data={data}
        loading={isLoading}
        rowClassName="clickable-data striped-rows"
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
        onRowClick={openRecordDetail}
        hover
      >
        <Column sortable resizable>
          <HeaderCell>Record ID</HeaderCell>
          <Cell dataKey="record_id" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Record Email</HeaderCell>
          <Cell dataKey="record_email" />
        </Column>
        <Column width={130} sortable resizable>
          <HeaderCell>Data Source ID </HeaderCell>
          <Cell dataKey="data_source_id" />
        </Column>
        <Column sortable resizable>
          <HeaderCell>Code Email</HeaderCell>
          <Cell dataKey="code_email" />
        </Column>
        {/*<Column width={140} sortable resizable>*/}
        {/*    <HeaderCell>Entry Date</HeaderCell>*/}
        {/*    <Cell>*/}
        {/*        {(rowData: any) => (*/}
        {/*            <span>{format(rowData.entry_date, "Y-MM-dd HH:mm")}</span>*/}
        {/*        )}*/}
        {/*    </Cell>*/}
        {/*</Column>*/}
        <Column width={150} resizable>
          <HeaderCell>Entry Date</HeaderCell>
          <Cell dataKey="entry_date" />
        </Column>
        <Column align="center" resizable>
          <HeaderCell>Service ID</HeaderCell>
          <Cell dataKey="service_id" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Submit Date</HeaderCell>
          <Cell dataKey="submit_date" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Processed Date</HeaderCell>
          <Cell dataKey="processed_date" />
        </Column>
        <Column align="center" resizable>
          <HeaderCell>Success</HeaderCell>
          <Cell dataKey="success" />
        </Column>
        <Column width={200} align="center" resizable>
          <HeaderCell>Attempt count</HeaderCell>
          <Cell dataKey="attempt_count" />
        </Column>
      </Table>
    </>
  );
};

export default RecentRecordsList;

import React from "react";
import {
  Checkbox,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Schema,
  Loader,
} from "rsuite";
import { FormContext } from "../../@Context/FormContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
const { StringType } = Schema.Types;

const model = Schema.Model({
  description: StringType().isRequired("Product Description is required."),
  product: StringType().isRequired("Please choose product."),
  category: StringType().isRequired("Please choose category."),
});

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type PingTreeProps = {
  treeId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};
const FormField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 150 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <HelpBlock>{message}</HelpBlock>
    </FormGroup>
  );
};

const PingTree: React.FC<PingTreeProps> = ({
  treeId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  const Forms = React.useContext(FormContext);
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(true);
  const [formValue, setFormValue] = React.useState({});

  Forms.setTree(treeId);
  const params = {
    tree_id: treeId,
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      await Query("products_routing_ping_trees", params);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  const setActive = (data: any) => {
    Forms.setActive(!data);
  };
  const setCategory = (data: any) => {
    Forms.setCategory(data);
  };

  const setProduct = (data: any) => {
    Forms.setProduct(data);
  };

  const setDescription = (data: any) => {
    Forms.setDescription(data);
  };
  return (
    <>
      <Form
        ref={formRef}
        onChange={handleChange}
        model={model}
        layout="horizontal"
        formValue={formValue}
      >
        <FormField
          label="Active"
          name="active"
          accepter={Checkbox}
          checked={Forms.active}
          onChange={setActive}
          value={Forms.active ? 1 : 0}
          disabled={Admediary.userDisable}
        />
        <FormField
          label="Category"
          name="category"
          accepter={SelectPicker}
          placeholder="All Verticals"
          data={config.categories.map((category: any) => ({
            category_name: category.category_name,
            category_id: category.category_id.toString(),
          }))}
          labelKey="category_name"
          valueKey="category_id"
          onChange={setCategory}
          value={Forms.category.toString()}
          cleanable={false}
          disabled={Admediary.userDisable}
        />
        <FormField
          label="Product"
          name="product"
          accepter={SelectPicker}
          placeholder="Choose Type"
          data={config.products.map((product: any) => ({
            product_description: product.product_description,
            product_id: product.product_id.toString(),
          }))}
          labelKey="product_description"
          valueKey="product_id"
          onChange={setProduct}
          value={Forms.product.toString()}
          cleanable={false}
          disabled={Admediary.userDisable}
        />
        <FormField
          label="Description"
          name="description"
          onChange={setDescription}
          value={Forms.description.toString()}
          disabled={Admediary.userDisable}
        />
      </Form>
    </>
  );
};

export default PingTree;

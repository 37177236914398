import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form, Checkbox, CheckboxGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import Config from "../../@Config/Forms";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  buyer_id: NumberType().isRequired("Buyer is required."),
  category_id: NumberType().isRequired("Category is required."),
  contract_name: StringType().isRequired("Contract Name is required."),
  post_email: StringType(),
  tier_name: StringType(),
  routing_tag: StringType(),
  filter_email: StringType(),
  filter_address: StringType(),
  filter_phone: StringType(),
  filter_ip: StringType(),
  template_id: NumberType(),
  enable_filters: ArrayType(),
  is_esign: ArrayType(),
  is_retainer: ArrayType(),
  is_revshare: ArrayType(),
});

type BuyerContractFormType = {
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractForm: React.FC<BuyerContractFormType> = ({
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyContract = {
    category_id: 0,
    active: [],
    enable_filters: [],
    is_esign: [],
    is_retainer: [],
    is_revshare: [],
  };
  const [formValue, setFormValue] = React.useState(emptyContract);
  const [formError, setFormError] = React.useState({});

  const params = {
    contract_id: contractId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.contract_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(
      `${api_base}/api/reports.php?op=buyer_contract_tier_names,buyer_contract_email_templates`
    )
      .then((response) => {
        const tierNames = response.data.response_data.buyer_contract_tier_names.data.map(
          (tier: any) => {
            return {
              ...tier,
              ...{
                tier_id: parseInt(tier.tier_id),
                category_id: parseInt(tier.category_id),
              },
            };
          }
        );
        const emailTemplates = response.data.response_data.buyer_contract_email_templates.data.map(
          (template: any) => {
            return {
              ...template,
              ...{
                template_id: parseInt(template.template_id),
              },
            };
          }
        );

        Forms.setData({
          ...Forms.data,
          ...{
            tierNames,
            emailTemplates,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    if (!Array.isArray(record.enable_filters)) {
      record.enable_filters = record.enable_filters === 1 ? [1] : [];
    }

    if (!Array.isArray(record.is_esign)) {
      record.is_esign = record.is_esign === 1 ? [1] : [];
    }

    if (!Array.isArray(record.is_retainer)) {
      record.is_retainer = record.is_retainer === 1 ? [1] : [];
    }

    if (!Array.isArray(record.is_revshare)) {
      record.is_revshare = record.is_revshare === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    // Check if the tier name list by a current category contains a current tier name value
    if (formData.tierNames && formData.tierNames.length > 0) {
      const tierIsInList = formData.tierNames
        .filter((tier: any) => tier.category_id === data.category_id)
        .some((tier: any) => tier.tier_name === data.tier_name);

      if (!tierIsInList) {
        data.tier_name = "";
      }
    }

    setFormValue(data);

    Forms.setDefaultAllow(
      data.default_allow !== undefined ? data.default_allow : null
    );

    if (data.ping_contract?.tokens != null) {
      data.ping_contract.tokens.forEach((token: any) => {
        data.ping_contract[token.token] = token.value;
      });
    }

    Forms.setPostUrl(data.post_url !== undefined ? data.post_url : "");
    Forms.setTimeout(data.timeout !== undefined ? data.timeout : 0);

    Forms.setPingContractId(
      data.ping_contract?.contract_id !== undefined
        ? data.ping_contract.contract_id
        : 0
    );
    Forms.setPingUrl(
      data.ping_contract?.post_url !== undefined
        ? data.ping_contract.post_url
        : ""
    );
    Forms.setPingTimeout(
      data.ping_contract?.post_url !== undefined
        ? data.ping_contract?.timeout
        : 0
    );
    Forms.setPingMinimumPrice(
      data.ping_contract?.ping_minimum_price !== undefined &&
        data.ping_contract?.ping_minimum_price != "" &&
        data.ping_contract?.ping_minimum_price !== null &&
        !isNaN(parseFloat(data.ping_contract?.ping_minimum_price))
        ? Number(
            parseFloat(data.ping_contract.ping_minimum_price).toFixed(2)
          ).toString()
        : ""
    );
    Forms.setActive(
      data.ping_contract?.ping_active !== undefined
        ? data.ping_contract.ping_active == 1
        : 0
    );

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Active" name="active" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field
        label="Buyer"
        name="buyer_id"
        accepter={SelectPicker}
        placeholder="Select Buyer"
        data={config.buyers.map((buyer: any) => ({
          buyer_name: buyer.buyer_name,
          buyer_id: parseInt(buyer.buyer_id),
        }))}
        labelKey="buyer_name"
        valueKey="buyer_id"
        cleanable={false}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Category"
        name="category_id"
        accepter={SelectPicker}
        placeholder="Select Vertical"
        data={config.categories.map((category: any) => ({
          category_name: category.category_name,
          category_id: parseInt(category.category_id),
        }))}
        labelKey="category_name"
        valueKey="category_id"
        cleanable={false}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Contract Name"
        name="contract_name"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Tier Name"
        name="tier_name"
        accepter={SelectPicker}
        placeholder="Select Tier Name"
        data={formData.tierNames.filter(
          (tier: any) => tier.category_id === formValue.category_id
        )}
        labelKey="tier_name"
        valueKey="tier_name"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Routing Tag"
        name="routing_tag"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Filter Email"
        name="filter_email"
        postfix={"days"}
        style={{ textAlign: "right" }}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Filter Address"
        name="filter_address"
        postfix={"days"}
        style={{ textAlign: "right" }}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Filter Phone"
        name="filter_phone"
        postfix={"days"}
        style={{ textAlign: "right" }}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Filter IP"
        name="filter_ip"
        postfix={"days"}
        style={{ textAlign: "right" }}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Email Template"
        name="template_id"
        accepter={SelectPicker}
        placeholder="Select Email Template"
        data={formData.emailTemplates}
        labelKey="template_name"
        valueKey="template_id"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Enable Filters"
        name="enable_filters"
        accepter={CheckboxGroup}
      >
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field label="Is E-Sign" name="is_esign" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field label="Is Retainer" name="is_retainer" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field label="Is Revshare" name="is_revshare" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractForm;

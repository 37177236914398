import React from "react";
import { Drawer } from "rsuite";
import BudgetsByBuyerContract from "../Budgets/BudgetsByBuyerContract";

type BuyerContractsOverview = {
  buyer_id: number;
};

const BuyerContractsOverview: React.FC<BuyerContractsOverview> = ({
  buyer_id,
}) => {
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>Contracts</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <BudgetsByBuyerContract buyer_id={buyer_id} />
      </Drawer.Body>
    </>
  );
};

export default BuyerContractsOverview;

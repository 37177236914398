import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Title from "../Title";
import ProductsList from "./ProductsList";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormContext } from "../../@Context/FormContext";
import EditProduct from "../Drawers/EditProduct";

export type ProductsType = {
  product_id?: number;
  category_id?: number;
  category_name: string;
  product_domain: string;
  product_description: string;
  product_url: string;
  reconcile_cake_conversions: number[];
  data_source_id: number;
  data_source_key: string;
  data_source_name: string;
  data_manage_all_records: number;
  email_days: number;
  address_days: number;
  phone_days: number;
  ip_days: number;
  default_allow: number;
  affiliate_caps_filter: number[];
};

type ProductsProps = {
  height?: number;
};

const Products: React.FC<ProductsProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [data, setData] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const categoryId = Admediary.category;
  const productId = Admediary.product;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const callbackOnSave = (savedProduct: ProductsType) => {
    if (
      data.findIndex(
        (record: any) => record.product_id == savedProduct.product_id
      ) < 0
    ) {
      setData([savedProduct, ...data]);
    } else {
      let newDataList = (data ?? []).map((record: any) => {
        if (record.percent_id == savedProduct.product_id) {
          return { ...savedProduct };
        }
        return record;
      });
      setData(newDataList);
    }
    fetchData();
  };

  const params = {
    refresh: refreshFlag,
    product_id: productId || 0,
    tag_name: "",
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("product_select", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [refreshFlag, productId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddProductClick = () => {
    Admediary.openDrawer(<EditProduct callbackOnSave={callbackOnSave} />, "lg");
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) =>
      (isNaN(v) ? v.toLowerCase() : v.toString()).includes(search);
    const fields = ["product_id", "product_description"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field] ?? ""))
    );
  };

  return (
    <>
      <Title title="Products" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={16}>
          <InputGroup
            style={{ width: 200, marginRight: 20, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <IconButton
            onClick={handleAddProductClick}
            icon={<Icon icon="plus-circle" />}
            placement="right"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 10 }}
            disabled={Admediary.userDisable}
          >
            Add Product
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <ProductsList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        callbackOnSave={callbackOnSave}
      />
    </>
  );
};

export default Products;

import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { FirebaseAuthConsumer } from "../@Context/FirebaseAuthContext";
import { PropsWithChildren } from "react";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}: PropsWithChildren<any>) => {
  return (
    <FirebaseAuthConsumer>
      {({ isAuth }) => (
        <Route
          render={(props) =>
            isAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/signin", state: { from: props.location } }}
              />
            )
          }
          {...rest}
        />
      )}
    </FirebaseAuthConsumer>
  );
};

export default ProtectedRoute;

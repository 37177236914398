import React from "react";
import { Drawer, FlexboxGrid, Icon } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Loader } from "rsuite";
import { Form, FormGroup, FormControl, ControlLabel, HelpBlock } from "rsuite";

type PandaLead = {
  row_data: any;
};

const PandaLead: React.FC<PandaLead> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const lead_instance_id = row_data.lead_instance_id;

  const shareLink = {
    name: "share_link",
    source_name: "share_link",
    table: "",
    title: "",
    value: row_data.share_link,
  };

  const params = {
    lead_instance_id,
  };

  const [data, isLoading] = useAdmediaryApi("view_lead", params);

  function getInputs(data: any) {
    return data.map((item: any) => {
      return (
        <FormGroup>
          <ControlLabel>{item.name}</ControlLabel>
          <FormControl name={item.name} value={item.value} />
          <HelpBlock tooltip>{item.value}</HelpBlock>
        </FormGroup>
      );
    });
  }

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          View Lead
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item
            colspan={24}
            style={{ textAlign: "right" }}
          ></FlexboxGrid.Item>
        </FlexboxGrid>
        <Form layout="horizontal">
          {isLoading ? (
            <Loader center size="lg" content="Loading..." />
          ) : (
            getInputs(Array.isArray(data) ? data : [])
          )}
          {isLoading ? (
            ""
          ) : (
            <FormGroup>
              <ControlLabel>{shareLink.name}</ControlLabel>
              <FormControl name={shareLink.name} value={shareLink.value} />
              <HelpBlock tooltip>{shareLink.value}</HelpBlock>
            </FormGroup>
          )}
        </Form>
      </Drawer.Body>
    </>
  );
};

export default PandaLead;

import React from "react";
import {
  Button,
  ButtonToolbar,
  Drawer,
  Form,
  Icon,
  Loader,
  Nav,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import _, { unset } from "lodash";
import { FormInstance } from "rsuite/lib/Form/Form";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import BuyerContractBudgetList from "../Products/BuyerContractBudgetList";
import BuyerContractCapList from "../Products/BuyerContractCapList";
import BuyerContractFieldList from "../Products/BuyerContractFieldList";
import BuyerContractFilterMapList from "../Products/BuyerContractFilterMapList";
import BuyerContractForm from "../Products/BuyerContractForm";
import BuyerContractPostHeaderList from "../Products/BuyerContractPostHeaderList";
import BuyerContractPostResponseList from "../Products/BuyerContractPostResponseList";
import BuyerContractRateList from "../Products/BuyerContractRateList";
import BuyerContractScheduleList from "../Products/BuyerContractScheduleList";
import BuyerContractSourceList from "../Products/BuyerContractSourceList";
import BuyerContractEmailList from "../Products/BuyerContractEmailList";
import EditModal from "../EditModal";
import BuyerContractClone from "../Products/BuyerContractClone";

type BuyerContractType = {
  contractId: number;
  contractName?: string;
  refreshData?: () => void;
};

const BuyerContract: React.FC<BuyerContractType> = ({
  contractId = 0,
  contractName = "",
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [activeKey, setActiveKey] = React.useState("main");
  const [subActiveKey, setSubActiveKey] = React.useState("post");
  const [savingInProgress, setSavingInProgress] = React.useState(false);
  const [cloneContractModalOpen, setCloneContractModalOpen] = React.useState(
    false
  );
  const initFormValue = {
    category_id: 0,
    post_body: "",
    price: "",
    lead_id: "",
    redirect_url: "",
    error: "",
    post_email: "",
    ping_contract: {
      price: "",
      error: "",
      session_id: "",
      post_body: "",
    },
  };
  const [formValue, setFormValue] = React.useState(initFormValue);
  const mainFormRef = React.createRef<FormInstance>();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const openCloneContractModal = () => setCloneContractModalOpen(true);
  const closeCloneContractModal = () => setCloneContractModalOpen(false);

  const handleCloneContractClick = () => {
    Admediary.setCloneContractModal(true);
    // openCloneContractModal();
  };

  const handleCloseCloneContractModal = () => {
    closeCloneContractModal();
  };

  const handleSave = () => {
    setSavingInProgress(true);
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      setSavingInProgress(false);
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }

    if (Array.isArray(data.enable_filters)) {
      data.enable_filters = data.enable_filters.length
        ? data.enable_filters[0]
        : 0;
    }

    if (Array.isArray(data.is_esign)) {
      data.is_esign = data.is_esign.length ? data.is_esign[0] : 0;
    }

    if (Array.isArray(data.is_retainer)) {
      data.is_retainer = data.is_retainer.length ? data.is_retainer[0] : 0;
    }

    if (Array.isArray(data.is_revshare)) {
      data.is_revshare = data.is_revshare.length ? data.is_revshare[0] : 0;
    }

    // Remove empty emails
    data.post_email = JSON.stringify(
      data.post_email
        ? JSON.parse(data.post_email).filter((email: string) => email !== "")
        : []
    );

    // Get data from the Form context
    data.default_allow = Forms.defaultAllow;
    data.post_url = Forms.postUrl;
    data.timeout = Forms.timeout;
    data.ping_active = Forms.active;

    var pingContractData =
      Forms.pingContractId == 0
        ? {
            contract_id: 0,
            timeout: "",
            session_id: "",

            active: data.active,
            buyer_id: data.buyer_id,
            category_id: data.category_id,
            contract_name: data.contract_name,
          }
        : {
            ...data.ping_contract,
            active: data.active,
            buyer_id: data.buyer_id,
            category_id: data.category_id,
            contract_name: data.contract_name,
            contract_id: Forms.pingContractId,
            post_url: Forms.pingUrl,
            timeout: Forms.pingTimeout,
            ping_active: Forms.active ? 1 : 0,
          };

    if (Forms.pingContractId != 0) {
      if (
        Number.isNaN(parseInt(Forms.pingTimeout)) &&
        Forms.pingTimeout != ""
      ) {
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>Ping Timeout must be an integer</>,
        });
        setSavingInProgress(false);
        return;
      } else if (
        Number.isNaN(parseFloat(Forms.pingMinimumPrice)) &&
        Forms.pingMinimumPrice != ""
      ) {
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>Ping Minimum Price must be a money value</>,
        });
        setSavingInProgress(false);
        return;
      }
    }

    (async () => {
      try {
        const postContractResult: any = await Query(
          "buyer_contract_update",
          data
        );
        const pingContractResult: any = await Query(
          "buyer_contract_update",
          pingContractData
        );

        var postContractId = postContractResult?.data[0]?.contract_id;
        var pingContractId = pingContractResult?.data[0]?.contract_id;

        if (pingContractId) {
          Forms.setPingContractId(pingContractId);
        }
        if (postContractId && pingContractId) {
          await Query("ping_post_mapping_update", {
            post_contract_id: postContractId,
            ping_contract_id: pingContractId,
            active: Forms.active ? 1 : 0,
            ping_minimum_price:
              Forms.pingMinimumPrice != "" ? Forms.pingMinimumPrice : "null",
          });
        }

        Notification.success({
          title: "Success",
          description: "Buyer Contract has been updated",
        });

        setSavingInProgress(false);
        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        setSavingInProgress(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const BuyerTokenForm = (
    <Form layout="horizontal">
      <Field
        label="Price"
        name="price"
        value={formValue.price}
        onChange={(value: any) =>
          setFormValue({ ...formValue, ...{ price: value } })
        }
        disabled={Admediary.userDisable}
      />
      <Field
        label="Lead ID"
        name="lead_id"
        value={formValue.lead_id}
        onChange={(value: any) =>
          setFormValue({ ...formValue, ...{ lead_id: value } })
        }
        disabled={Admediary.userDisable}
      />
      <Field
        label="Redirect URL"
        name="redirect_url"
        value={formValue.redirect_url}
        onChange={(value: any) =>
          setFormValue({ ...formValue, ...{ redirect_url: value } })
        }
        disabled={Admediary.userDisable}
      />
      <Field
        label="Errors"
        name="error"
        value={formValue.error}
        onChange={(value: any) =>
          setFormValue({ ...formValue, ...{ error: value } })
        }
        disabled={Admediary.userDisable}
      />
    </Form>
  );

  const PingTokenForm = (
    <Form layout="horizontal">
      <Field
        label="Price"
        name="ping_price"
        value={formValue.ping_contract?.price}
        onChange={(value: any) =>
          setFormValue({
            ...formValue,
            ping_contract: { ...formValue.ping_contract, price: value },
          })
        }
      />
      <Field
        label="Session ID"
        name="session_id"
        value={formValue.ping_contract?.session_id}
        onChange={(value: any) =>
          setFormValue({
            ...formValue,
            ping_contract: { ...formValue.ping_contract, session_id: value },
          })
        }
      />
      <Field
        label="Errors"
        name="ping_error"
        value={formValue.ping_contract?.error}
        onChange={(value: any) =>
          setFormValue({
            ...formValue,
            ping_contract: { ...formValue.ping_contract, error: value },
          })
        }
      />
    </Form>
  );

  const tabs = [
    {
      key: "main",
      title: "Contract",
      content: (
        <BuyerContractForm
          contractId={contractId}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      ),
    },
  ];

  if (contractId > 0) {
    tabs.push(
      ...[
        {
          key: "post_main",
          title: "Post",
          content: <></>,
        },
        {
          key: "ping_main",
          title: "Ping",
          content: <></>,
        },
        {
          key: "filters",
          title: "Filters",
          content: (
            <BuyerContractFilterMapList
              contractId={contractId}
              contractData={formValue}
            />
          ),
        },
        {
          key: "rates",
          title: "Rates",
          content: <BuyerContractRateList contractId={contractId} />,
        },
        {
          key: "schedule",
          title: "Schedule",
          content: <BuyerContractScheduleList contractId={contractId} />,
        },
        {
          key: "sources",
          title: "Sources",
          content: (
            <BuyerContractSourceList
              contractId={contractId}
              contractName={contractName}
            />
          ),
        },
        {
          key: "affiliate_caps",
          title: "Affiliate Caps",
          content: <BuyerContractCapList contractId={contractId} />,
        },
        {
          key: "budget",
          title: "Budget",
          content: <BuyerContractBudgetList contractId={contractId} />,
        },
      ]
    );
  }

  const emails = (formValue.post_email
    ? JSON.parse(formValue.post_email)
    : []
  ).map((email: string, id: number) => ({ id: id + 1, email }));

  const subTabs = new Map();

  subTabs.set("post_main", [
    {
      key: "post",
      title: "Post",
      content: (
        <BuyerContractFieldList
          contractId={contractId}
          categoryId={formValue.category_id}
        />
      ),
    },
    {
      key: "responses",
      title: "Responses",
      content: <BuyerContractPostResponseList contractId={contractId} />,
    },
    {
      key: "tokens",
      title: "Tokens",
      content: BuyerTokenForm,
    },
    {
      key: "headers",
      title: "Headers",
      content: <BuyerContractPostHeaderList contractId={contractId} />,
    },
    {
      key: "post_body",
      title: "Post Body",
      content: (
        <Form>
          <Field
            label="Post Body"
            name="post_body"
            componentClass="textarea"
            rows={12}
            value={formValue.post_body}
            onChange={(postBody: any) =>
              setFormValue({
                ...formValue,
                ...{ post_body: postBody },
              })
            }
            disabled={Admediary.userDisable}
          />
        </Form>
      ),
    },
    {
      key: "email",
      title: "Email",
      content: (
        <BuyerContractEmailList
          emails={emails}
          onChange={(postEmail: any) => {
            setFormValue({
              ...formValue,
              ...{ post_email: JSON.stringify(postEmail) },
            });
          }}
        />
      ),
    },
  ]);

  subTabs.set("ping_main", [
    {
      key: "ping",
      title: "Ping",
      content: (
        <BuyerContractFieldList
          contractId={Forms.pingContractId}
          categoryId={formValue.category_id}
          pingType={true}
        />
      ),
    },
    {
      key: "responses",
      title: "Responses",
      content: (
        <BuyerContractPostResponseList contractId={Forms.pingContractId} />
      ),
    },
    {
      key: "tokens",
      title: "Tokens",
      content: PingTokenForm,
    },
    {
      key: "headers",
      title: "Headers",
      content: (
        <BuyerContractPostHeaderList contractId={Forms.pingContractId} />
      ),
    },
    {
      key: "ping_body",
      title: "Post Body",
      content: (
        <Form>
          <Field
            label="Post Body"
            name="ping_body"
            componentClass="textarea"
            rows={12}
            value={formValue.ping_contract?.post_body}
            onChange={(ping_body: any) =>
              setFormValue({
                ...formValue,
                ping_contract: {
                  ...formValue.ping_contract,
                  post_body: ping_body,
                },
              })
            }
          />
        </Form>
      ),
    },
  ]);

  const currentSubTabs = subTabs.has(activeKey) ? subTabs.get(activeKey) : [];

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!contractId ? "Edit" : "Add"} Lead Buyer Contract
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
        <Nav
          activeKey={activeKey}
          appearance="tabs"
          onSelect={(eventKey) => {
            if (eventKey == "post_main") {
              setSubActiveKey("post");
            } else if (eventKey == "ping_main") {
              setSubActiveKey("ping");
            }
            setActiveKey(eventKey);
          }}
          style={{ marginTop: 10 }}
        >
          {tabs.map((tab: any, index: number) => (
            <Nav.Item eventKey={tab.key} key={index}>
              {tab.title}
            </Nav.Item>
          ))}
        </Nav>
        {currentSubTabs.length > 0 && (
          <Nav
            activeKey={subActiveKey}
            appearance="tabs"
            onSelect={(eventKey) => {
              setSubActiveKey(eventKey);
            }}
            style={{ marginTop: 10 }}
          >
            {currentSubTabs.map((tab: any, index: number) => (
              <Nav.Item eventKey={tab.key} key={index}>
                {tab.title}
              </Nav.Item>
            ))}
          </Nav>
        )}
      </Drawer.Header>
      <Drawer.Body>
        {tabs
          .filter((tab: any) => !subTabs.has(tab.key))
          .map((tab: any, index: number) => (
            <div
              key={index}
              id={"main_content_" + tab.key}
              style={{
                display: activeKey === tab.key ? "block" : "none",
                minHeight: "100px", // put the loading icon down off the tabs
              }}
            >
              {tab.content}
            </div>
          ))}
        {currentSubTabs.map((tab: any, index: number) => (
          <div
            key={index}
            id={"sub_content_" + tab.key}
            style={{
              display: subActiveKey === tab.key ? "block" : "none",
              minHeight: "100px", // put the loading icon down off the tabs
            }}
          >
            {tab.content}
          </div>
        ))}
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            style={savingInProgress ? { paddingBottom: "0.2em" } : {}}
            disabled={Admediary.userDisable}
          >
            {!savingInProgress && <>Save</>}
            {savingInProgress && (
              <Loader size="sm" style={{ padding: "0", margin: "0 0.5em" }} />
            )}
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default BuyerContract;

import React from "react";
import { Table } from "rsuite";
import { thousands, percent } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { NumberCell, ParentCell, PercentCell, HeaderSummary } from "../Table";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerDailyBudgetsTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
  group: string;
};

const BuyerDailyBudgetsTable: React.FC<BuyerDailyBudgetsTableProps> = (
  props: any
) => {
  const data = props.data;
  const summary = props.summary;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sortType = props.sortType || "desc";
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const group = props.group;

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          total_count: thousands(item.total_count),
          filtered: thousands(item.filtered),
          accepted: thousands(item.accepted),
          rejected: thousands(item.rejected),
          pinged: thousands(item.pinged),
          posted: thousands(item.posted),
          filter_percentage: item.filter_percentage,
          accept_percentage: parseInt(item.accept_percentage),
          priority: !isNaN(item.priority) ? item.priority : null,
          posted_accept_percentage: item.posted_accept_percentage,
          average_post: item.average_post,
          min_post: item.min_post,
          max_post: item.max_post,
          stddev_post: item.stddev_post,
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
          cap: isNaN(item.cap) ? "" : item.cap,
          remaining_cap: isNaN(item.remaining_cap) ? "" : item.remaining_cap,
          percent_remaining: isNaN(item.percent_remaining)
            ? 0
            : item.percent_remaining,
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sortColumn && sortType ? sortTree(list, sortColumn, sortType) : list
    );
  };

  return (
    <>
      <Table
        isTree
        virtualized
        // height={650}
        autoHeight={true}
        headerHeight={90}
        loading={isLoading === true}
        data={sortData(data)}
        rowKey={rowKey}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {group === "buyer" ? (
          <Column width={300} fixed sortable resizable>
            <HeaderCell>Buyer/Product/Contract Name/Date</HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>
        ) : (
          <Column width={300} fixed sortable resizable>
            <HeaderCell>Product/Buyer/Contract Name/Date</HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>
        )}
        <Column width={125} sortable resizable>
          <HeaderCell>Contract ID</HeaderCell>
          <Cell dataKey="contract_id" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>Priority</HeaderCell>
          <Cell dataKey="priority" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Total Count"
              summary={thousands(summary.total_count)}
            />
          </HeaderCell>
          <NumberCell dataKey="total_count" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Accepted"
              summary={thousands(summary.accepted)}
            />
          </HeaderCell>
          <NumberCell dataKey="accepted" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary title="Cap" summary={thousands(summary.cap)} />
          </HeaderCell>
          <NumberCell dataKey="cap" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Remaining Cap"
              summary={thousands(summary.remaining_cap)}
            />
          </HeaderCell>
          <Cell dataKey="remaining_cap" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="% to Cap"
              summary={percent(summary.percent_remaining)}
            />
          </HeaderCell>
          <PercentCell dataKey="percent_remaining" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Filtered"
              summary={thousands(summary.filtered)}
            />
          </HeaderCell>
          <NumberCell dataKey="filtered" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Rejected"
              summary={thousands(summary.rejected)}
            />
          </HeaderCell>
          <NumberCell dataKey="rejected" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Filter %"
              summary={percent(summary.filter_percentage)}
            />
          </HeaderCell>
          <PercentCell dataKey="filter_percentage" />
        </Column>
        <Column width={125} sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Accept %"
              summary={percent(summary.accept_percentage)}
            />
          </HeaderCell>
          <PercentCell dataKey="accept_percentage" />
        </Column>
      </Table>
    </>
  );
};

export default BuyerDailyBudgetsTable;

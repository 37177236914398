import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../../@Utils/Export";
import LeadDetailList from "./LeadDetailList";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import _, { isEmpty } from "lodash";
import PivotTable from "../../Drawers/PivotTable";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = ["rate", "earned", "profit"];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return summary;
}

type LeadDetailProps = {
  height?: number;
};

const LeadDetail: React.FC<LeadDetailProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultStatusValue = "";
  const [status, setStatus] = React.useState(defaultStatusValue);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [affiliateId, setAffiliate] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState();
  const [subId1, setSubId1] = React.useState();
  const [subId2, setSubId2] = React.useState();
  const affiliates = Admediary.config.affiliates;
  const categoryId = Admediary.category;
  const intervalRef: any = React.useRef(null);
  const [dynamicExportData, setDynamicExportData] = React.useState<any[]>([]);
  const [filteredKeys, setFilteredKeys] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: categoryId,
    product_id: Admediary.product,
    affiliate_id: affiliateId,
    buyer_id: Admediary.buyer,
    status,
    query: searchQuery,
    sub_id_1: subId1,
    sub_id_2: subId2,
  };

  let [data, isLoading, , config] = useAdmediaryApiManual(
    "lead_detail_report",
    params
  );

  const statusByList = [
    { status: "", label: "All" },
    { status: "accepted", label: "Accepted" },
    { status: "error", label: "Error" },
    { status: "rejected", label: "Rejected" },
    { status: "success", label: "Success" },
    { status: "repost_pending", label: "Repost Pending" },
    { status: "repost_complete", label: "Repost Complete" },
    { status: "repost_processing", label: "Repost Processing" },
  ];

  let aList: Record<string, any>[] = [];

  let affiliatesList: any = data;

  affiliates.forEach((v: any, i: number) => {
    aList.push({
      affiliate_name: v.affiliate_id + " - " + v.affiliate_name,
      affiliate_id: v.affiliate_id,
    });
  });

  let dataArray = [];
  let results = aList.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.affiliate_id]: item.affiliate_name }),
    {}
  );

  let dataObj = {};

  for (let key in results) {
    dataObj = {
      affiliate_name: results[key],
      affiliate_id: key,
    };
    dataArray.push(dataObj);
  }

  affiliatesList = dataArray;

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  const handleCallback = (data: any) => {
    setFilteredKeys(data);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      if (type === 1) {
        setSubId1(value);
      } else if (type === 2) {
        setSubId2(value);
      } else {
        setSearchQuery(value);
      }
    }, 750);
  };

  /**
   * Method is used to get config values for unique product answers
   * and filtering objects from data to only include those. Calls function renameKey
   * @param data
   * @param columns
   */
  const filterKeys = (data: any, columns: any) => {
    const fieldsArray = columns.fields;
    const keyArrayObjects = columns?.keys;
    const keyArray: any = [];
    const clonedData = _.cloneDeep(data);

    if (
      Admediary.product === 125 ||
      Admediary.product === 116 ||
      Admediary.product === 88 ||
      Admediary.product === 37 ||
      Admediary.product === 108
    ) {
      return formatExportData(Array.isArray(data) ? data : []);
      // return false;
    } else {
      if (Array.isArray(keyArrayObjects)) {
        keyArrayObjects.forEach((item: any) => {
          keyArray.push(item.name);
        });
      }

      const result = clonedData.map((obj: any) =>
        Object.fromEntries(
          keyArray
            .map((key: any) => obj.hasOwnProperty(key) && [key, obj[key]])
            .filter(Boolean)
        )
      );

      keyArray.forEach((item: any, index: any) => {
        renameKey(result, item, fieldsArray[index]);
      });
    }
  };

  /**
   * Method accepts data, the key and the new value for the key.
   * Loops through obj and replaces the old key with the new one for export
   * @param obj
   * @param key
   * @param newValue
   */
  const renameKey = (obj: any, key: any, newValue: any) => {
    obj.forEach((item: any) => {
      if (item.hasOwnProperty(key)) {
        item[newValue] = item[key];
        delete item[key];
      } else {
        item[newValue] = "";
        return item;
      }
    });

    const objClone = _.cloneDeep(obj);
    setDynamicExportData(objClone);
  };

  const formatExportData = (data: any) => {
    const clonedData = _.cloneDeep(data);

    const filteredExportData =
      filteredKeys !== undefined || filteredKeys?.length
        ? clonedData.filter((item: any) => {
            return filteredKeys.includes(item.lead_instance_id);
          })
        : clonedData;

    const formattedExportData = filteredExportData.map(
      (item: any, index: any) => {
        const commonValidationIpFields = {
          "Buyer Disposition": item.lid_disposition ? item.lid_disposition : "",
          "Fraud Score": item.fraud_score ? item.fraud_score : "",
          Proxy: item.proxy ? item.proxy : "",
          "Recent Abuse": item.recent_abuse ? item.recent_abuse : "",
          "Bot Status": item.bot_status ? item.bot_status : "",
          Tor: item.tor ? item.tor : "",
          VPN: item.vpn ? item.vpn : "",
          Mobile: item.mobile ? item.mobile : "",
          "Abuse Velocity": item.abuse_velocity ? item.abuse_velocity : "",
        };

        const utmFields = {
          "UTM Source": item.utm_source ? item.utm_source : "",
          "UTM Medium": item.utm_medium ? item.utm_medium : "",
          "UTM Campaign": item.utm_campaign ? item.utm_campaign : "",
          "UTM Content": item.utm_content ? item.utm_content : "",
          "UTM Term": item.utm_term ? item.utm_term : "",
        };

        if (Admediary.product === 125) {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
              Attorney: item.have_attorney ? item.have_attorney : "",
              "First Name": item.edit_firstname ? item.edit_firstname : "",
              "Last Name": item.edit_lastname ? item.edit_lastname : "",
              Address: item.edit_address ? item.edit_address : "",
              City: item.edit_city ? item.edit_city : "",
              State: item.edit_state ? item.edit_state : "",
              Zip: item.edit_zip ? item.edit_zip : "",
              Phone: item.edit_phone ? item.edit_phone : "",
              "Ph Carrier": item.phone_carrier_name
                ? item.phone_carrier_name
                : "",
              "Ph Line Type": item.phone_line_type ? item.phone_line_type : "",
              Served: item.served ? item.served : "",
              Diagnosis: item.diagnosis ? item.diagnosis : "",
              Relationship: item.relationship ? item.relationship : "",
              "Mother Pregnant": item.mother_pregnant
                ? item.mother_pregnant
                : "",
              Gender: item.gender ? item.gender : "",
              Deceased: item.deceased ? item.deceased : "",
              "Deceased Date": item.edit_deceased_date
                ? item.edit_deceased_date
                : "",
              "Deceased Relationship": item.deceased_relationship
                ? item.deceased_relationship
                : "",
              "Injured Name": item.injured_name ? item.injured_name : "",
              "Signature Auth": item.signature_authority
                ? item.signature_authority
                : "",
              "Have POA": item.have_poa ? item.have_poa : "",
              Suffered: item.suffered ? item.suffered : "",
              "Diagnosis Year": item.diagnosis_year ? item.diagnosis_year : "",
              Reason: item.reason ? item.reason : "",
              "Lived 30 Days": item.length_lived ? item.length_lived : "",
              "First Year": item.first_year ? item.first_year : "",
              "Last Year": item.last_year ? item.last_year : "",
              DOB: item.edit_dob ? item.edit_dob : "",
              "Case Notes": item.case_notes ? item.case_notes : "",
              "Is E-Sign": item.esign_lp ? item.esign_lp : "",
              "Jornaya Lead ID": item.universal_leadid
                ? item.universal_leadid
                : "",
              "Source Url": item.edit_source_url ? item.edit_source_url : "",
              Version: item.v ? item.v : "",
              "Trusted Form Cert ID": item.certificate_id
                ? item.certificate_id
                : "",
            },
            ...utmFields,
            ...commonValidationIpFields,
          };
        } else if (Admediary.product === 116) {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
              Attorney: item.have_attorney ? item.have_attorney : "",
              "First Name": item.edit_firstname ? item.edit_firstname : "",
              "Last Name": item.edit_lastname ? item.edit_lastname : "",
              Address: item.edit_address ? item.edit_address : "",
              City: item.edit_city ? item.edit_city : "",
              State: item.edit_state ? item.edit_state : "",
              Zip: item.edit_zip ? item.edit_zip : "",
              Phone: item.edit_phone ? item.edit_phone : "",
              "Ph Carrier": item.phone_carrier_name
                ? item.phone_carrier_name
                : "",
              "Ph Line Type": item.phone_line_type ? item.phone_line_type : "",
              "Was Diagnosed": item.was_diagnosed ? item.was_diagnosed : "",
              "Used Formula": item.used_formula ? item.used_formula : "",
              "Any Complications": item.any_complications
                ? item.any_complications
                : "",
              Relationship: item.relationship ? item.relationship : "",
              "Formula Brand": item.formula_brand ? item.formula_brand : "",
              "Year First Used": item.year_first_use ? item.year_first_use : "",
              "Year Last Used": item.year_last_use ? item.year_last_use : "",
              "State Formula Given": item.formula_given_state
                ? item.formula_given_state
                : "",
              Diagnosis: item.diagnosis ? item.diagnosis : "",
              "Diagnosis Year": item.diagnosis_year ? item.diagnosis_year : "",
              "Diagnosed in Hospital": item.diagnosed_in_hospital
                ? item.diagnosed_in_hospital
                : "",
              "Hospital Start Date": item.hospital_date_start
                ? item.hospital_date_start
                : "",
              "Hospital End Date": item.hospital_date_end
                ? item.hospital_date_end
                : "",
              "Diagnosis Hospital": item.diagnosis_hospital
                ? item.diagnosis_hospital
                : "",
              "Diagnosis City": item.diagnosis_city ? item.diagnosis_city : "",
              "Diagnosis State": item.diagnosis_state
                ? item.diagnosis_state
                : "",
              "Hospital Treated": item.treated_hospital
                ? item.treated_hospital
                : "",
              "Treated City": item.treated_city ? item.treated_city : "",
              "Treated State": item.treated_state ? item.treated_state : "",
              "Surgery Notes": item.surgery_notes ? item.surgery_notes : "",
              "Death Date": item.edit_death_date ? item.edit_death_date : "",
              "Is Minor": item.is_minor ? item.is_minor : "",
              "Relationship to Child": item.child_relationship
                ? item.child_relationship
                : "",
              "Signature Auth": item.signature_authority
                ? item.signature_authority
                : "",
              "Child Name": item.child_name ? item.child_name : "",
              DOB: item.edit_dob ? item.edit_dob : "",
              "Case Notes": item.case_notes ? item.case_notes : "",
              "Is E-Sign": item.esign_lp ? item.esign_lp : "",
              "Jornaya Lead ID": item.universal_leadid
                ? item.universal_leadid
                : "",
              "Source Url": item.edit_source_url ? item.edit_source_url : "",
              Version: item.v ? item.v : "",
              "Trusted Form Cert ID": item.certificate_id
                ? item.certificate_id
                : "",
            },
            ...utmFields,
            ...commonValidationIpFields,
          };
        } else if (Admediary.product === 37) {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
              Attorney: item.have_attorney ? item.have_attorney : "",
              "First Name": item.edit_firstname ? item.edit_firstname : "",
              "Last Name": item.edit_lastname ? item.edit_lastname : "",
              Address: item.edit_address ? item.edit_address : "",
              City: item.edit_city ? item.edit_city : "",
              State: item.edit_state ? item.edit_state : "",
              Zip: item.edit_zip ? item.edit_zip : "",
              Phone: item.edit_phone ? item.edit_phone : "",
              "Ph Carrier": item.phone_carrier_name
                ? item.phone_carrier_name
                : "",
              "Ph Line Type": item.phone_line_type ? item.phone_line_type : "",
              "Was Diagnosed": item.was_diagnosed ? item.was_diagnosed : "",
              "Year Diagnosed": item.diagnosis_year ? item.diagnosis_year : "",
              "Over Age 65": item.over_age_65 ? item.over_age_65 : "",
              "Age Diagnosed": item.diagnosis_age ? item.diagnosis_age : "",
              "Used For": item.use_talcum ? item.use_talcum : "",
              "BCRA Positive": item.bcra_positive ? item.bcra_positive : "",
              Relationship: item.relationship ? item.relationship : "",
              Deceased: item.deceased ? item.deceased : "",
              "Injured Name": item.injured_name ? item.injured_name : "",
              "Year First Used": item.use_year ? item.use_year : "",
              "Year Last Used": item.year_last_use ? item.year_last_use : "",
              Diagnosis: item.diagnosis ? item.diagnosis : "",
              Gender: item.gender ? item.gender : "",
              "Used Talcum Daily": item.used_talcum ? item.used_talcum : "",
              "Product Used": item.product_used ? item.product_used : "",
              DOB: item.edit_dob ? item.edit_dob : "",
              "Case Notes": item.case_notes ? item.case_notes : "",
              "Is E-Sign": item.esign_lp ? item.esign_lp : "",
              "Jornaya Lead ID": item.universal_leadid
                ? item.universal_leadid
                : "",
              "Source Url": item.edit_source_url ? item.edit_source_url : "",
              Version: item.v ? item.v : "",
              "Trusted Form Cert ID": item.certificate_id
                ? item.certificate_id
                : "",
            },
            ...utmFields,
            ...commonValidationIpFields,
          };
        } else if (Admediary.product === 108) {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
              Attorney: item.have_attorney ? item.have_attorney : "",
              "First Name": item.edit_firstname ? item.edit_firstname : "",
              "Last Name": item.edit_lastname ? item.edit_lastname : "",
              Address: item.edit_address ? item.edit_address : "",
              City: item.edit_city ? item.edit_city : "",
              State: item.edit_state ? item.edit_state : "",
              Zip: item.edit_zip ? item.edit_zip : "",
              Phone: item.edit_phone ? item.edit_phone : "",
              "Ph Carrier": item.phone_carrier_name
                ? item.phone_carrier_name
                : "",
              "Ph Line Type": item.phone_line_type ? item.phone_line_type : "",
              "Brand Name": item.brand_name ? item.brand_name : "",
              "Was Diagnosed": item.was_diagnosed ? item.was_diagnosed : "",
              "Have Symptoms": item.have_symptoms ? item.have_symptoms : "",
              "On Dialysis": item.on_dialysis ? item.on_dialysis : "",
              "Have License": item.have_license ? item.have_license : "",
              "Year Exposed": item.year_exposed ? item.year_exposed : "",
              Relationship: item.relationship ? item.relationship : "",
              "Diagnosis Year": item.diagnosis_year ? item.diagnosis_year : "",
              "Year Born": item.year_born ? item.year_born : "",
              "Year First Used": item.year_first_use ? item.year_first_use : "",
              "Stopped Using": item.stopped_use ? item.stopped_use : "",
              "Year Last Used": item.year_last_use ? item.year_last_use : "",
              "Year Symptoms Started": item.symptoms_year
                ? item.symptoms_year
                : "",
              "State Exposed": item.exposure_state ? item.exposure_state : "",
              "History Parkinsons": item.history_parkinsons
                ? item.history_parkinsons
                : "",
              "Case Notes": item.case_notes ? item.case_notes : "",
              "Is E-Sign": item.esign_lp ? item.esign_lp : "",
              "Jornaya Lead ID": item.universal_leadid
                ? item.universal_leadid
                : "",
              "Source Url": item.edit_source_url ? item.edit_source_url : "",
              Version: item.v ? item.v : "",
              "Trusted Form Cert ID": item.certificate_id
                ? item.certificate_id
                : "",
            },
            ...utmFields,
            ...commonValidationIpFields,
          };
        } else if (Admediary.product === 88) {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
              Attorney: item.have_attorney ? item.have_attorney : "",
              "First Name": item.edit_firstname ? item.edit_firstname : "",
              "Last Name": item.edit_lastname ? item.edit_lastname : "",
              Address: item.edit_address ? item.edit_address : "",
              City: item.edit_city ? item.edit_city : "",
              State: item.edit_state ? item.edit_state : "",
              Zip: item.edit_zip ? item.edit_zip : "",
              Phone: item.edit_phone ? item.edit_phone : "",
              "Ph Carrier": item.phone_carrier_name
                ? item.phone_carrier_name
                : "",
              "Ph Line Type": item.phone_line_type ? item.phone_line_type : "",
              "Roundup Exposed": item.exposed_to_roundup
                ? item.exposed_to_roundup
                : "",
              "Was Diagnosed": item.was_diagnosed ? item.was_diagnosed : "",
              Diagnosis: item.diagnosis ? item.diagnosis : "",
              "Year Diagnosed": item.year_diagnosed ? item.year_diagnosed : "",
              "Over 66": item.diagnosed_age_over ? item.diagnosed_age_over : "",
              "Location Used": item.location_used ? item.location_used : "",
              "Mfg. Plant Exposed": item.exposed ? item.exposed : "",
              "After Jan": item.after_jan ? item.after_jan : "",
              Relationship: item.relationship ? item.relationship : "",
              "Deceased Year": item.deceased_year ? item.deceased_year : "",
              "Injured Name": item.injured_name ? item.injured_name : "",
              "Year First Used": item.use_year ? item.use_year : "",
              "Year Last Used": item.year_last_use ? item.year_last_use : "",
              "Exposed on Job": item.exposed_on_job ? item.exposed_on_job : "",
              "Exposed Length": item.roundup_exposed_length
                ? item.roundup_exposed_length
                : "",
              "Year Born": item.year_born ? item.year_born : "",
              "Diagnosis Hospital": item.diagnosis_hospital
                ? item.diagnosis_hospital
                : "",
              "Diagnosis City": item.diagnosis_city ? item.diagnosis_city : "",
              "Diagnosis State": item.diagnosis_state
                ? item.diagnosis_state
                : "",
              "Case Notes": item.case_notes ? item.case_notes : "",
              "Is E-Sign": item.esign_lp ? item.esign_lp : "",
              "Jornaya Lead ID": item.universal_leadid
                ? item.universal_leadid
                : "",
              "Source Url": item.edit_source_url ? item.edit_source_url : "",
              Version: item.v ? item.v : "",
              "Trusted Form Cert ID": item.certificate_id
                ? item.certificate_id
                : "",
            },
            ...utmFields,
            ...commonValidationIpFields,
          };
        } else {
          return {
            ...{
              ID: item.lead_instance_id,
              "Product ID": item.product_id,
              Description: item.product_description,
              Email: item.email,
              Paid: item.pixel,
              Calling: item.held,
              "TK Call": item.tk_call,
              "Buy/Hold": item.default,
              "BH Pending": item.pending,
              "BH Processing": item.repost_processing,
              "Direct Post": item.direct_post,
              "Is Test": item.is_test,
              RSID: item.request_session_id,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              "Lead Status": item.lead_instance_status,
              Status: item.status,
              Rate: item.rate,
              Earned: item.earned,
              GP: item.profit,
              "Client IP": item.client_ip,
              Buyers: item.buyers,
              "Contract Name": item.contract_name,
              "Tier Name": item.tier_name,
              "Error List": item.error_list,
              Duration: item.duration,
              "Entry Date": item.entry_date
                ? format(item.entry_date, "MM-dd-y HH:mm")
                : "",
              "Transaction Date": item.transaction_date
                ? format(item.transaction_date, "MM-dd-y HH:mm")
                : "",
              SID1: item.sid1,
              SID2: item.sid2,
              SID3: item.sid3 ? item.sid3 : "",
            },
            ...dynamicExportData[index],
            ...commonValidationIpFields,
          };
        }
      }
    );

    return exportCsv(
      `lead_detail_${format(new Date(Admediary.start), "MMddyy")}_${format(
        new Date(Admediary.end),
        "MMddyy"
      )}.csv`,
      formattedExportData && Array.isArray(formattedExportData)
        ? formattedExportData
        : []
    );
  };

  React.useEffect(() => {
    if (dynamicExportData.length > 0) {
      formatExportData(Array.isArray(data) ? data : []);
    }
  }, [dynamicExportData]);

  const callExport = (data: any, config: any) => {
    filterKeys(Array.isArray(data) ? data : [], config ? config : []);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={16}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Status"
            defaultValue={defaultStatusValue}
            value={status}
            data={statusByList}
            valueKey="status"
            labelKey="label"
            onChange={(v) => {
              setStatus(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Affiliate"
            value={affiliateId}
            data={affiliatesList}
            onChange={(v) => {
              setAffiliate(v);
            }}
            valueKey="affiliate_id"
            labelKey="affiliate_name"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 1"
              onChange={(v) => {
                debounceSearchTerm(v, 1);
              }}
              size="sm"
            />
          </InputGroup>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 2"
              onChange={(v) => {
                debounceSearchTerm(v, 2);
              }}
              size="sm"
            />
          </InputGroup>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() => {
              Admediary.openDrawer(
                <PivotTable
                  data={Array.isArray(data) ? data : []}
                  graphKeys={[]}
                  groupBy={""}
                />,
                "lg"
              );
              Admediary.maximizeDrawer();
            }}
            icon={<Icon icon="table" />}
            placement="right"
            size="sm"
            disabled={
              isLoading === true || isEmpty(Array.isArray(data) ? data : [])
            }
          >
            Pivot
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              callExport(Array.isArray(data) ? data : [], config ? config : [])
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <LeadDetailList
        height={height}
        data={Array.isArray(data) ? data : []}
        config={config ? config : []}
        summary={getSummary(Array.isArray(data) ? data : [])}
        isLoading={isLoading === true}
        rowKey={rowKey}
        status={status}
        categoryId={categoryId}
        affiliateId={affiliateId}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
        parentCallback={handleCallback}
      />
    </>
  );
};

export default LeadDetail;

import React from "react";
import { Loader } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerOverview from "../Drawers/BuyerOverview";

const BudgetsByBuyer: React.FC = () => {
  const admediary_context = React.useContext(AdmediaryContext);
  const config: any = admediary_context.config;

  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: admediary_context.category,
    product_id: admediary_context.product,
    buyer_id: admediary_context.buyer,
    affiliate_id: 0,
    group: "",
  };

  const [data, leadsIsLoading] = useAdmediaryApi(
    "buyer_contract_budgets",
    params
  );

  /***
   * Reducer functions for aggregating calculations by contract
   * Function creates a mapping table with calculations. The grouping
   * is handled by `buyder_id`
   */
  const allBuyers = React.useMemo(() => {
    if (!Array.isArray(data)) return [];
    if (!Array.isArray(config.buyers)) return [];
    const reducedByBuyer = data.reduce((result: any, item: any) => {
      // Create new buyer object if it hasn't already
      if (result[item.buyer_id] === undefined) {
        result[item.buyer_id] = {
          buyer_id: item.buyer_id,
          cap: item.cap,
          leads_sold: item.leads_sold,
          revenue: item.revenue,
          accepted_percentage: item.accepted_percentage,
          accepted_percentage_aggr: item.accepted_percentage,
          contracts: 1,
        };

        // Run aggregation calculations
      } else {
        // basic sum aggregations
        result[item.buyer_id] = {
          ...result[item.buyer_id],
          ...{
            accepted_percentage_aggr:
              result[item.buyer_id].accepted_percentage_aggr +
              item.accepted_percentage,
            contracts: result[item.buyer_id].contracts + 1,
            cap: result[item.buyer_id].cap + item.cap,
            leads_sold: result[item.buyer_id].leads_sold + item.leads_sold,
            revenue: result[item.buyer_id].revenue + item.revenue,
          },
        };
        // it's easier to let sums happen first, then do averages
        // (intended for leads accepted %
        result[item.buyer_id] = {
          ...result[item.buyer_id],
          ...{
            accepted_percentage:
              item.contracts > 0
                ? item.accepted_percentage_aggr / item.contracts
                : 0,
          },
        };
      }
      return result;
    }, {});
    return config.buyers
      .filter((row: any) => {
        return reducedByBuyer[row.buyer_id] !== undefined;
      })
      .map((row: any) => {
        return {
          ...row,
          ...reducedByBuyer[row.buyer_id],
        };
      })
      .map((item: any) => {
        return {
          ...item,
          ...{
            budget_percentage: Math.round((item.leads_sold / item.cap) * 100),
          },
        };
      })
      .sort((a: any, b: any) => {
        return a.budget_percentage < b.budget_percentage ? 1 : -1;
      });
  }, [data, config.buyers]);

  if (leadsIsLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  /**
   * Open Drawer with Buyer Overview
   */
  const handleClick = (data: any): void => {
    const {
      buyer_id,
      buyer_name,
      leads_sold,
      price_owed,
      price_received,
    } = data;
    admediary_context.openDrawer(
      <BuyerOverview
        buyer_id={buyer_id}
        buyer_name={buyer_name}
        leads_sold={leads_sold}
        price_owed={price_owed}
        price_received={price_received}
      />
    );
  };
  const topBuyers = allBuyers.slice(0, 8);

  return (
    <React.Fragment>
      <table className="summary-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="summary-table-callout" style={{ maxWidth: 150 }}>
              Name
            </th>
            <th className="summary-table-graph" colSpan={2}>
              <span style={{ paddingLeft: 10 }}>% of Budget</span>
            </th>
            <th className="right">Leads</th>
            <th className="right">Budget</th>
            <th className="summary-table-graph" colSpan={2}>
              <span style={{ paddingLeft: 10 }}>% of Accepted</span>
            </th>
            <th className="right">Revenue</th>
          </tr>
        </thead>
        <tbody>
          {topBuyers.map((item: any) => (
            <tr
              key={item.buyer_id}
              onClick={() =>
                handleClick({
                  buyer_id: item.buyer_id,
                  buyer_name: item.buyer_name,
                  leads_sold: item.leads_sold,
                  price_owed: 0,
                  price_received: 0,
                })
              }
            >
              <td
                className="summary-table-callout"
                style={{
                  maxWidth: 150,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="summary-table-primary">{item.buyer_name}</span>
              </td>
              <td style={{ maxWidth: 45 }}>
                <span
                  style={{
                    width: 45,
                    paddingLeft: 10,
                    paddingRight: 12,
                  }}
                >
                  {item.budget_percentage}%
                </span>
              </td>
              <td style={{ minWidth: 50 }}>
                <span
                  style={{
                    display: "inline-block",
                    height: "12px",
                    width: `${item.budget_percentage}%`,
                    background: "#ff311b",
                  }}
                />
              </td>
              <td className="right">{item.leads_sold.toLocaleString()}</td>
              <td className="right">{item.cap.toLocaleString()}</td>
              <td style={{ maxWidth: 45 }}>
                <span
                  style={{
                    width: 45,
                    paddingLeft: 10,
                    paddingRight: 12,
                  }}
                >
                  {Math.round(item.accepted_percentage)}%
                </span>
              </td>
              <td style={{ minWidth: 50 }}>
                <span
                  style={{
                    display: "inline-block",
                    height: "12px",
                    width: `${Math.floor(item.accepted_percentage)}%`,
                    background: "#ff311b",
                  }}
                />
              </td>
              <td className="right">
                {item.revenue.toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default BudgetsByBuyer;

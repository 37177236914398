import React, { useContext } from "react";
import {
  Container,
  Content,
  Drawer,
  Dropdown,
  Icon,
  Nav,
  Sidebar,
  Sidenav,
  Loader,
  Whisper,
  Tooltip,
  Popover,
} from "rsuite";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import NavToggle from "./Dashboard/NavToggle";
import TopNavbar from "./Dashboard/TopNavbar";
import { AdmediaryContext } from "../@Context/AdmediaryContext";
import { Initialize, Query } from "../@Utils/AdmediaryApi";
import {
  FirebaseAuthContext,
  LOCAL_STORAGE_AUTH_KEYS,
} from "../@Context/FirebaseAuthContext";
import { auth } from "../@Config/Firebase";
import { setInterval } from "timers";
import NavTabBar from "./Dashboard/NavTabBar";

const header_styles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: "#023066",
  color: " #fff",
  whiteSpace: "nowrap" as const,
  overflow: "hidden",
  // width: 56,
  textAlign: "center",
};

const sidebar_styles: any = {
  // background: "#021b3b",
  background: "#023066",
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
  overflowY: "scroll",
  zIndex: 100,
};

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const location = useLocation();
  const admediary_context = useContext(AdmediaryContext);
  const firebase_context = useContext(FirebaseAuthContext);
  //const [openKeys,setOpenKeys] = React.useState(["2", "3", "4", "5", "6", "7", "8", "9"]);
  const handleToggle = () => {
    admediary_context.setSidebarExpanded(!admediary_context.expandSidebar);
  };
  const allAccessEmails = [
    "bwarner@admediaryllc.com",
    "awelch@admediaryllc.com",
    "lfoster@admediaryllc.com",
    "jvillanueva@admediaryllc.com",
    "awarden@admediaryllc.com",
    "apalchak@admediaryllc.com",
    "oleg@admediaryllc.com",
    "dzelaya@admediaryllc.com",
  ];
  let email = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL)
    ? localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL)
    : "";

  React.useEffect(() => {
    // @ts-ignore
    if (!allAccessEmails.includes(email)) {
      admediary_context.setUserDisable(true);
    } else {
      admediary_context.setUserDisable(false);
    }
  }, [email]);

  // refresh token on page route change
  const history = useHistory();
  React.useEffect(() => {
    return history.listen(() => {
      auth.currentUser
        ?.getIdToken(true)
        .then((token) =>
          localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token)
        )
        .catch((error) => console.log(error));
    });
  }, [history]);

  // Refresh an access token every 30 minutes
  React.useEffect(() => {
    const interval = setInterval(() => {
      auth.currentUser
        ?.getIdToken(true)
        .then((token) => {
          localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);

          Query("refresh_token");
        })
        .catch((error) => console.log(error));
    }, 1800000);
    return () => clearInterval(interval);
  }, []);

  /**
   * Load key entity data into state for reports
   */
  const fetchPrimaryList = async (attempt: number = 0) => {
    // well attempt this 3 times with a delay between each request
    // in testing, Firebase/Google Auth keys for signing aren't
    // verifying as fast as tokens are signed.
    try {
      const [
        contracts,
        buyers,
        categories,
        products,
        affiliates,
        lead_categories,
        all_clients,
        phones,
      ] = await Initialize();
      // Create a array with buyers organized by ID (used to attach contracts to buyers)
      const buyer_map = buyers.data.reduce((buyers: any, item: any) => {
        buyers[item.buyer_id] = Object.assign({
          ...{ contracts: [] },
          ...item,
        });
        return buyers;
      }, []);
      // organized contracts by buyer (used for several reports)
      const contracts_by_buyer = contracts.data.reduce(
        (buyers: any, item: any) => {
          buyers[item.buyer_id].contracts.push(item);
          return buyers;
        },
        buyer_map
      );
      admediary_context.setConfig({
        affiliates: affiliates.data,
        buyers: buyers.data,
        contracts: contracts.data,
        products: products.data,
        categories: categories.data,
        contracts_by_buyer: contracts_by_buyer,
        clients: all_clients.data,
        lead_categories: lead_categories.data,
        phones: phones.data,
      });
    } catch (error) {
      if (attempt < 3) {
        setTimeout(fetchPrimaryList, 500, attempt + 1);
      } else {
        if (error === "Unauthorized user") {
          firebase_context.logout();
        }
        firebase_context.logout();
      }
    }
  };

  if (!admediary_context.config.affiliates) {
    fetchPrimaryList();
    return (
      <div id="loaderInverseWrapper" style={{ height: 200 }}>
        <Loader
          center
          content={
            <div>
              Fetching Admin Entities...
              <br />
              <a
                href="signin"
                onClick={(e) => {
                  e.preventDefault();
                  firebase_context.logout();
                }}
                style={{ fontSize: 10 }}
              >
                Abort
              </a>
            </div>
          }
        />
      </div>
    );
  }

  /***
   * Helper function for setting active class if the current location
   * has a base that matches the string provided.
   */
  const isActive = (
    base: string,
    className: string,
    exact: boolean = false
  ) => {
    const stringLength: number = base.length;
    if (
      (!exact && location.pathname.substring(0, stringLength) === base) ||
      (exact && location.pathname === base)
    ) {
      return className;
    }
    return "";
  };

  const handleBuyersGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.buyers);
  };
  const handleAffiliatesGroup = () => {
    admediary_context.setActiveNavGroup(
      admediary_context.routingTabs.affiliates
    );
  };

  const handleLeadsGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.leads);
  };

  const handleFlowSiteGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.flowSite);
  };
  const handleProductsGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.products);
  };
  const handleDirectPostsGroup = () => {
    admediary_context.setActiveNavGroup(
      admediary_context.routingTabs.directPosts
    );
  };
  const handleAccountingGroup = () => {
    admediary_context.setActiveNavGroup(
      admediary_context.routingTabs.accounting
    );
  };
  const handleDataGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.data);
  };
  const handleCampaignGroup = () => {
    admediary_context.setActiveNavGroup(
      admediary_context.routingTabs.campaigns
    );
  };
  const handleCommonGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.common);
  };

  const handleExternalGroup = () => {
    admediary_context.setActiveNavGroup(admediary_context.routingTabs.external);
  };

  return (
    <Container>
      <Container
        className="content-container"
        style={{ marginLeft: admediary_context.expandSidebar ? 260 : 56 }}
      >
        <TopNavbar />
        <NavTabBar />
        <Content>{props.children}</Content>
      </Container>
      <Sidebar
        className="side-navigation"
        style={sidebar_styles}
        width={admediary_context.expandSidebar ? 260 : "fit-content"}
        collapsible
        scrollable="true"
      >
        <Sidenav.Header>
          <NavToggle
            expand={admediary_context.expandSidebar}
            onChange={handleToggle}
            styles={header_styles}
          />
        </Sidenav.Header>
        <Sidenav
          expanded={admediary_context.expandSidebar}
          defaultOpenKeys={admediary_context.sidebarOpenKeys}
          appearance="inverse"
          onOpenChange={(eventKey, event) => {
            admediary_context.setSidebarOpenKeys(eventKey);
            //setOpenKeys(eventKey);
          }}
          style={{ maxHeight: "100vh" }}
        >
          <Sidenav.Body>
            <Nav>
              <NavLink to="/" exact activeClassName="selected">
                <Nav.Item
                  componentClass="span"
                  eventKey="1"
                  icon={<Icon icon="dashboard" />}
                >
                  Dashboard
                </Nav.Item>
              </NavLink>
              <CustomSidebarTab
                category="affiliates"
                icon="peoples-map"
                handleGroup={handleAffiliatesGroup}
                isActive={isActive}
                title="Affiliates"
              />

              <CustomSidebarTab
                category="buyers"
                icon="group"
                handleGroup={handleBuyersGroup}
                isActive={isActive}
                title="Buyers"
              />

              <CustomSidebarTab
                category="leads"
                icon="order-form"
                handleGroup={handleLeadsGroup}
                isActive={isActive}
                title="Leads"
              />

              <CustomSidebarTab
                category="flowSite"
                icon="flow"
                handleGroup={handleFlowSiteGroup}
                isActive={isActive}
                title="Flow Sites"
              />

              <CustomSidebarTab
                category="products"
                icon="shopping-bag"
                handleGroup={handleProductsGroup}
                isActive={isActive}
                title="Products"
              />

              <CustomSidebarTab
                category="directPosts"
                icon="order-form"
                handleGroup={handleDirectPostsGroup}
                isActive={isActive}
                title="Direct Posts"
              />

              <CustomSidebarTab
                category="accounting"
                icon="money"
                handleGroup={handleAccountingGroup}
                isActive={isActive}
                title="Accounting"
              />

              <CustomSidebarTab
                category="data"
                icon="database"
                handleGroup={handleDataGroup}
                isActive={isActive}
                title="Data"
              />

              <CustomSidebarTab
                category="campaigns"
                icon="peoples"
                handleGroup={handleCampaignGroup}
                isActive={isActive}
                title="Campaigns"
              />

              <CustomSidebarTab
                category="common"
                icon="paragraph"
                handleGroup={handleCommonGroup}
                isActive={isActive}
                title="Common"
              />

              <CustomSidebarTab
                category="external"
                icon="file-text-o"
                handleGroup={handleExternalGroup}
                isActive={isActive}
                title="External Reports"
              />
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      <Drawer
        className="buyer-sidebar"
        backdrop={true}
        show={admediary_context.drawerOpened}
        onHide={admediary_context.closeDrawer}
        size={admediary_context.drawerSize}
        full={admediary_context.drawerFull}
        keyboard
      >
        {admediary_context.drawerContent}
      </Drawer>
    </Container>
  );
};

type MenuTypes = {
  tabs: any;
  onSelect: any;
  isActive: any;
  title: any;
};

const CustomMenu: React.FC<MenuTypes> = (props: any) => {
  const tabs = props.tabs;
  const onSelect = props.onSelect;
  const isActive = props.isActive;
  const title = props.title;

  return (
    <Dropdown.Menu onSelect={onSelect} className="sideBar-hover-menu">
      <Dropdown.Item panel>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "14px",
            paddingLeft: "4px",
            paddingRight: "4px",
          }}
        >
          {title}
        </p>
      </Dropdown.Item>
      <Dropdown.Item divider />
      {Array.isArray(tabs) && tabs.length
        ? tabs.map((item: any) => {
            return (
              <NavLink to={item.route}>
                <Dropdown.Item
                  componentClass="div"
                  eventKey={item.key}
                  className={isActive(item.route, "selected", true)}
                  onSelect={onSelect}
                >
                  {item.name}
                </Dropdown.Item>
              </NavLink>
            );
          })
        : ""}
    </Dropdown.Menu>
  );
};

const MenuPopover = ({ tabs, onSelect, isActive, title, ...rest }: any) => (
  <Popover {...rest} full>
    <CustomMenu
      tabs={tabs}
      onSelect={onSelect}
      isActive={isActive}
      title={title}
    />
  </Popover>
);

type SidebarWhisper = {
  category: any;
  handleGroup: any;
  isActive: any;
  icon: any;
  title: any;
};

const CustomSidebarTab: React.FC<SidebarWhisper> = (props: any) => {
  const category = props.category;
  const icon = props.icon;
  const handleGroup = props.handleGroup;
  const isActive = props.isActive;
  const title = props.title;
  const Admediary = React.useContext(AdmediaryContext);
  const routingTabs = category ? Admediary.routingTabs[category] : "";

  return (
    <Whisper
      placement="auto"
      trigger="hover"
      enterable
      preventOverflow
      speaker={
        <MenuPopover
          tabs={Array.isArray(routingTabs) ? routingTabs : []}
          onSelect={handleGroup}
          isActive={isActive}
          title={title}
        />
      }
    >
      <NavLink to={routingTabs[0].route} exact activeClassName="selected">
        <Nav.Item
          eventKey="2"
          trigger="hover"
          title={category.charAt(0).toUpperCase() + category.slice(1)}
          icon={<Icon icon={icon} />}
          placement="rightStart"
          className={isActive(routingTabs[0].route, "selected")}
          onClick={handleGroup}
        >
          {title}
        </Nav.Item>
      </NavLink>
    </Whisper>
  );
};

export default Dashboard;

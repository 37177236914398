import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import CommonAffiliatesList from "./CommonAffiliatesList";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";

type CommonAffiliatesType = {
  height?: number;
};

const CommonAffiliates: React.FC<CommonAffiliatesType> = ({ height = 650 }) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApi("common_affiliates", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  // Export Data Function
  const formatExportData = (data: any) => {
    return (
      data
        .map((item: any) => item)
        // Clean, format and reorder by fields with right headers
        .map((item: any) => {
          return {
            "Affiliate ID": item.affiliate_id,
            "Affiliate Name": item.affiliate_name,
            Address: item.address,
            City: item.city,
            State: item.state,
            "Zip Code": item.zip_code,
            TIN: item.tax_id,
            "Tax Class": item.tax_class,
          };
        })
    );
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["affiliate_id", "affiliate_name"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "affiliate_id") {
          return include(item[field].toString()); // convert contract_id to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  return (
    <>
      <Title title="Affiliates" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `affiliate_list_${new Date().toLocaleDateString()}.csv`,
                formatExportData(
                  filter(Array.isArray(data) ? data : [], searchQuery)
                )
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <CommonAffiliatesList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default CommonAffiliates;

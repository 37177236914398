import React from "react";
import { ResponsiveLine as NivoResponsiveLine, Serie } from "@nivo/line";
import moment from "moment";

type AverageResponseTimeGraphProps = {
  data?: Serie[];
};

const AverageResponseTimeGraph: React.FC<AverageResponseTimeGraphProps> = ({
  data = [],
}) => {
  return (
    <NivoResponsiveLine
      margin={{ top: 25, right: 30, bottom: 60, left: 60 }}
      animate={true}
      data={data}
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M",
        useUTC: false,
        precision: "hour",
      }}
      xFormat="time:%Y-%m-%d %H:%M"
      yScale={{
        type: "linear",
        stacked: false,
        min: "auto",
        max: "auto",
      }}
      yFormat=" >-.6f"
      axisLeft={{
        legend: "Average Time, s",
        legendOffset: -45,
        legendPosition: "middle",
      }}
      axisBottom={{
        tickValues: "every 2 hours",
        format: (value) =>
          moment(value).format("H") === "0"
            ? moment(value).format("MM/DD")
            : `${moment(value).format("H")}h`,
        legend: "Date",
        legendOffset: 40,
        legendPosition: "middle",
      }}
      colors={["#0b79ef"]}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: "white",
              padding: "9px 12px",
              border: "1px solid #ccc",
            }}
          >
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: "3px 0",
                }}
              >
                <strong>{point.serieId}</strong>
                <br />
                <strong>Date:</strong> {point.data.xFormatted}
                <br />
                <strong>Average Time:</strong> {point.data.yFormatted} s
              </div>
            ))}
          </div>
        );
      }}
      legends={[
        {
          anchor: "bottom-left",
          direction: "row",
          justify: false,
          translateX: -35,
          translateY: 50,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 73,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      markers={[
        {
          axis: "y",
          value: 6,
          lineStyle: { stroke: "#FFFF00", strokeWidth: 2 },
        },
        {
          axis: "y",
          value: 10,
          lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
        },
      ]}
    />
  );
};

export default AverageResponseTimeGraph;

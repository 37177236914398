import React from "react";
import { Drawer, Icon } from "rsuite";

import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format, parseISO } from "date-fns";
import ResponseTimes from "../Responses/ResponseTimes";

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type ResponseTime = {
  row_data: any;
  groupBy: string;
  affiliateId: number;
};

const ResponseTime: React.FC<ResponseTime> = ({
  row_data,
  groupBy,
  affiliateId = 0,
}) => {
  const Admediary = React.useContext(AdmediaryContext);

  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;

  const buyer_name =
    row_data.buyer_name || getFieldValueOrParentValue(row_data, "buyer_name");
  const buyer_id =
    row_data.buyer_id || getFieldValueOrParentValue(row_data, "buyer_id");
  const contract_id =
    row_data.contract_id || getFieldValueOrParentValue(row_data, "contract_id");
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id") ||
    affiliateId;
  const sid1 = row_data.sid1 || "";
  const disposition = row_data.disposition || "";

  const startFormatted = format(start_date, "MM/dd/yyyy");
  const endFormatted = format(end_date, "MM/dd/yyyy");
  const drawerTitle = `${buyer_name} [${startFormatted} - ${endFormatted}]`;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {`Response Times - ${drawerTitle}`}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <ResponseTimes
          startDate={start_date}
          endDate={end_date}
          buyerId={buyer_id}
          contractId={contract_id}
          groupBy={groupBy}
          affiliateId={affiliate_id}
          sid1={sid1}
          disposition={disposition}
          isDrawer={true}
        />
      </Drawer.Body>
    </>
  );
};

export default ResponseTime;

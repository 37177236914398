import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form, Checkbox, CheckboxGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import Config from "../../@Config/Forms";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import _ from "lodash";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  description: StringType().isRequired("Description is required."),
  reason: StringType().isRequired("Reason is required."),
  category_id: NumberType().isRequired("Category is required."),
  buyer_id: NumberType(),
  contract_id: NumberType(),
  dynamic: ArrayType(),
  evaluator: StringType(),
  filter_field: StringType(),
  one_of: ArrayType(),
  filter_values: StringType(),
});

type BuyerContractFilterFormType = {
  filterId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  disableScope?: boolean;
};

const BuyerContractFilterForm: React.FC<BuyerContractFilterFormType> = ({
  filterId = 0,
  formRef = null,
  parentCallback = () => {},
  disableScope = false,
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyFilter = {
    filter_id: 0,
    category_id: 0,
    buyer_id: 0,
    contract_id: 0,
    active: [],
    dynamic: [],
    evaluator: "",
    filter_field: "",
  };
  const [formValue, setFormValue] = React.useState(emptyFilter);
  const [formError, setFormError] = React.useState({});
  const [prevFields, setPrevFields] = React.useState();

  const params = {
    filter_id: filterId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.filter_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_filter", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(
      `${api_base}/api/reports.php?op=buyers_with_category,buyer_contracts`
    )
      .then((response) => {
        const buyersWithCategory = response.data.response_data.buyers_with_category.data.map(
          (buyer: any) => ({
            ...buyer,
            ...{
              buyer_id: parseInt(buyer.buyer_id),
              category_id: parseInt(buyer.category_id),
            },
          })
        );

        const buyerContracts = response.data.response_data.buyer_contracts.data.map(
          (contract: any) => ({
            ...contract,
            ...{
              contract_id: parseInt(contract.contract_id),
              buyer_id: parseInt(contract.buyer_id),
            },
          })
        );

        Forms.setData({
          ...Forms.data,
          ...{
            buyersWithCategory,
            buyerContracts,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const categoryId = Admediary.category;
  // const fieldsParams = {
  //   category_id: formValue.category_id || categoryId,
  //   buyer_id: formValue.buyer_id || 0,
  //   contract_id: formValue.contract_id || 0,
  // };
  const fieldsParams = {
    category_id: formValue.category_id || categoryId,
    buyer_id: 0,
    contract_id: 0,
  };
  const [fields] = useAdmediaryApi("buyer_contract_post_fields", fieldsParams);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    record.buyer_id = !isNaN(record.buyer_id) ? record.buyer_id : 0;
    record.contract_id = !isNaN(record.contract_id) ? record.contract_id : 0;
    record.evaluator = record.evaluator || "";

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    record.dynamic = record.evaluator && record.evaluator !== "" ? [1] : [];

    if (!Array.isArray(record.one_of)) {
      record.one_of = record.one_of === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    // Check if the buyer list by a current category contains a current buyer id
    if (formData.buyersWithCategory && formData.buyersWithCategory.length > 0) {
      const isBuyerInList = formData.buyersWithCategory
        .filter((buyer: any) => buyer.category_id === data.category_id)
        .some((buyer: any) => buyer.buyer_id === data.buyer_id);

      if (!isBuyerInList) {
        data.buyer_id = 0;
      }
    }

    // Check if the contract list by a current buyer contains a current contract id
    if (formData.buyerContracts && formData.buyerContracts.length > 0) {
      const isContractInList = formData.buyerContracts
        .filter((contract: any) => contract.buyer_id === data.buyer_id)
        .some((contract: any) => contract.contract_id === data.contract_id);

      if (!isContractInList) {
        data.contract_id = 0;
      }
    }

    setFormValue(data);

    //Forms.setDefaultAllow(
    //  data.default_allow !== undefined ? data.default_allow : null
    //);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  // Check if the filter field list contains a current filter field
  if (Array.isArray(fields) && !_.isEqual(prevFields, fields)) {
    const isFieldInList = fields.some(
      (field: any) => field.field.replaceAll("%", "") === formValue.filter_field
    );

    if (!isFieldInList) {
      formValue.filter_field = "";
    }

    setPrevFields(fields);
    setFormValue(formValue);
  }

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Active" name="active" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field
        label="Description"
        name="description"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Reason Code"
        name="reason"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Category"
        name="category_id"
        accepter={SelectPicker}
        placeholder="Select Vertical"
        data={config.categories.map((category: any) => ({
          category_name: category.category_name,
          category_id: parseInt(category.category_id),
        }))}
        labelKey="category_name"
        valueKey="category_id"
        cleanable={false}
        // disabled={disableScope}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Scope"
        name="buyer_id"
        accepter={SelectPicker}
        data={formData.buyersWithCategory.filter(
          (buyer: any) => buyer.category_id === formValue.category_id
        )}
        labelKey="buyer_name"
        valueKey="buyer_id"
        disabled={Admediary.userDisable}
        // disabled={
        //   disableScope ||
        //   formValue.category_id === undefined ||
        //   formValue.category_id <= 0
        // }
      />
      <Field
        label=""
        name="contract_id"
        accepter={SelectPicker}
        placeholder="Any"
        data={formData.buyerContracts
          .filter((contract: any) => contract.buyer_id === formValue.buyer_id)
          .sort((a: any, b: any) =>
            a.contract_name > b.contract_name ? 1 : -1
          )}
        labelKey="contract_name"
        valueKey="contract_id"
        disabled={
          disableScope ||
          formValue.buyer_id === undefined ||
          formValue.buyer_id <= 0
        }
      />
      <Field label="Dynamic" name="dynamic" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      {formValue.dynamic.length > 0 && (
        <Field
          label="Evaluator"
          name="evaluator"
          componentClass="textarea"
          rows={8}
          disabled={Admediary.userDisable}
        />
      )}
      {formValue.dynamic.length === 0 && (
        <Field
          label="Filter Field"
          name="filter_field"
          accepter={SelectPicker}
          placeholder="Select Post Field"
          disabled={Admediary.userDisable}
          data={
            Array.isArray(fields)
              ? fields.map((field: any) => ({
                  value: field.field.replaceAll("%", ""),
                  label: field.field.replaceAll("%", ""),
                }))
              : []
          }
        />
      )}
      {formValue.dynamic.length === 0 && (
        <Field label="One of" name="one_of" accepter={CheckboxGroup}>
          <Checkbox value={1} disabled={Admediary.userDisable} />
        </Field>
      )}
      {formValue.dynamic.length === 0 && (
        <Field label="" name="filter_values" disabled={Admediary.userDisable} />
      )}
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractFilterForm;

import React, { useState } from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import BuyerTreeTable from "./BuyerTreeTable";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";

let affiliateFilter = false; //if affiliate is selected
let dateFilter = false; //if date is selected

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "considered",
    "filtered",
    "presented",
    "accepted",
    "rejected",
    "rate",
    "earned",
    "profit",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function getExportData(data: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes

  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;
      const current = {
        // Add parent values
        ...{
          affiliate_id: item.affiliate_id || parent.affiliate_id,
          daily_bought: item.daily_bought || parent.daily_bought,
          daily_cap: item.daily_cap || parent.daily_cap,
          buyer_id: item.buyer_id || parent.buyer_id,
          buyer_name: item.buyer_name || parent.buyer_name,
          contract_id: item.contract_id || parent.contract_id,
          contract_name: item.contract_name || parent.contract_name,
        },
        ...item,
      };

      if (current.children && Array.isArray(current.children)) {
        current.product_display = "";
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };
  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        if (affiliateFilter) {
          return {
            "Affiliate ID": item.affiliate_id,
            "Buyer ID": item.buyer_id,
            SID1: item.sid1,
            Period: item.period_percentage,
            Daily: item.daily_percentage,
            "Buyer Name": item.buyer_name,
            Product: item.product_display,
            "Contract ID": item.contract_id,
            "Contract Name": item.contract_name,
            "Entry Date": item.entry_date,
            Considered: thousands(item.considered),
            Filtered: thousands(item.filtered),
            "Filter %": percent(parseFloat(item.filter_percent)),
            Presented: thousands(item.presented),
            Pinged: thousands(item.pinged),
            Posted: thousands(item.posted),
            "Accept %": percent(parseFloat(item.posted_accept_percentage)),
            Accepted: thousands(item.accepted),
            "Gross Accept %": percent(parseFloat(item.accept_percent)),
            Rejected: thousands(item.rejected),
            Rate: currency(item.rate),
            Earned: currency(item.earned),
            GP: currency(item.profit),
            CPL: currency(item.cpl),
            RPL: currency(item.rpl),
            PPL: currency(item.ppl),
          };
        } else if (dateFilter) {
          return {
            "Buyer ID": item.buyer_id,
            Period: item.period_percentage,
            Daily: item.daily_percentage,
            "Daily Cap": item.daily_cap,
            "Daily Bought": item.daily_bought,
            "Buyer Name": item.buyer_name,
            Product: item.product_display,
            "Contract ID": item.contract_id,
            "Contract Name": item.contract_name,
            "Entry Date": item.entry_date,
            Considered: thousands(item.considered),
            Filtered: thousands(item.filtered),
            "Filter %": percent(parseFloat(item.filter_percent)),
            Presented: thousands(item.presented),
            Pinged: thousands(item.pinged),
            Posted: thousands(item.posted),
            "Accept %": percent(parseFloat(item.posted_accept_percentage)),
            Accepted: thousands(item.accepted),
            "Gross Accept %": percent(parseFloat(item.accept_percent)),
            Rejected: thousands(item.rejected),
            Rate: currency(item.rate),
            Earned: currency(item.earned),
            GP: currency(item.profit),
            CPL: currency(item.cpl),
            RPL: currency(item.rpl),
            PPL: currency(item.ppl),
          };
        } else {
          return {
            "Buyer ID": item.buyer_id,
            Period: item.period_percentage,
            Daily: item.daily_percentage,
            "Buyer Name": item.buyer_name,
            Product: item.product_display,
            "Contract ID": item.contract_id,
            "Contract Name": item.contract_name,
            "Entry Date": item.entry_date,
            Considered: thousands(item.considered),
            Filtered: thousands(item.filtered),
            "Filter %": percent(parseFloat(item.filter_percent)),
            Presented: thousands(item.presented),
            Pinged: thousands(item.pinged),
            Posted: thousands(item.posted),
            "Accept %": percent(parseFloat(item.posted_accept_percentage)),
            Accepted: thousands(item.accepted),
            "Gross Accept %": percent(parseFloat(item.accept_percent)),
            Rejected: thousands(item.rejected),
            Rate: currency(item.rate),
            Earned: currency(item.earned),
            GP: currency(item.profit),
            CPL: currency(item.cpl),
            RPL: currency(item.rpl),
            PPL: currency(item.ppl),
          };
        }
      })
  );
}

function addCalculatedValues(row: any) {
  row.accepted = parseFloat(row.accepted);
  row.considered = parseFloat(row.considered);
  row.presented = parseFloat(row.presented);
  row.profit = row.earned - row.rate;
  row.posted =
    row.ping_rejected === null
      ? row.presented
      : row.presented - row.ping_rejected;

  return {
    ...row,
    ...{
      cpl: row.accepted !== 0 ? row.rate / row.accepted : 0,
      rpl: row.accepted !== 0 ? row.earned / row.accepted : 0,
      ppl: row.accepted !== 0 ? row.profit / row.accepted : 0,
      filter_percent:
        row.considered !== 0 ? (row.filtered / row.considered) * 100 : 0,
      accept_percent:
        row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
      pinged: row.ping_rejected === null ? 0 : row.presented,
      posted_accept_percentage:
        row.posted !== 0 ? (row.accepted / row.posted) * 100 : 0,
    },
  };
}

function addCalculatedValuesRecursively(list: any, level: number = 0) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children, level + 1);
      if (level == 0) {
        //Hide product display on the highest grouping
        row.product_display = "";
      }
    }

    return addCalculatedValues(row);
  });
}

const BuyerTree: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = "entry_date";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [affiliate, setAffiliate] = React.useState(0);
  const affiliates = Admediary.config.affiliates;

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    affiliate_id: affiliate,
    buyer_id: Admediary.buyer,
    group: group,
  };

  // const [data, isLoading] = useAdmediaryApi("buyer_tree_report", params);
  const [data, isLoading] = useAdmediaryApiManual("buyer_tree_report", params);
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);
  const groupByList = [
    { group: "entry_date", label: "Date" },
    { group: "affiliate_id", label: "Affiliate" },
    { group: "disposition", label: "Disposition" },
  ];

  let aList: Record<string, any>[] = [];

  affiliates.forEach((v: any, i: number) => {
    aList.push({
      affiliate_name: v.affiliate_id + " - " + v.affiliate_name,
      affiliate_id: v.affiliate_id,
    });
  });

  let dataArray = [];
  let results = aList.reduce(
    (obj, item) =>
      Object.assign(obj, { [item.affiliate_id]: item.affiliate_name }),
    {}
  );

  let dataObj = {};

  for (let key in results) {
    dataObj = {
      affiliate_name: results[key],
      affiliate_id: key,
    };
    dataArray.push(dataObj);
  }

  const affiliatesList = dataArray;

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  React.useEffect(() => {
    handleSortColumn("parent_title", "asc");
  }, []);

  const start = format(new Date(Admediary.start), "MMddyy");
  const end = format(new Date(Admediary.end), "MMddyy");
  let exportBy = "";

  if (group == "affiliate_id") {
    affiliateFilter = true;
    dateFilter = false;
    exportBy = "byAffiliate";
  } else if (group == "entry_date") {
    dateFilter = true;
    affiliateFilter = false;
    exportBy = "byDate";
  } else {
    affiliateFilter = false;
    dateFilter = false;
    exportBy = "byDisposition";
  }

  return (
    <>
      <Title title="Buyer Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Detail by"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Affiliate"
            value={affiliate}
            data={affiliatesList}
            onChange={(v) => {
              setAffiliate(v);
            }}
            valueKey="affiliate_id"
            labelKey="affiliate_name"
            style={{ marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `buyer_report_${exportBy}_${start}_${end}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerTreeTable
        data={treeData}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        group={group}
        affiliateId={affiliate}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default BuyerTree;

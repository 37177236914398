import React, { useContext } from "react";
import {
  FirebaseAuthContext,
  LOCAL_STORAGE_AUTH_KEYS,
} from "../@Context/FirebaseAuthContext";
import { Redirect, useLocation } from "react-router-dom";
import { Container, FlexboxGrid, Panel, Button, Icon } from "rsuite";
// import config from "../@Config/Admediary";
// const base_url = window.location.origin;
import { auth, provider } from "../@Config/Firebase";
// import { AdmediaryContext } from "../@Context/AdmediaryContext";

const Signin = () => {
  const authContext = React.useContext(FirebaseAuthContext);
  const location = useLocation();
  // const admediary_context = useContext(AdmediaryContext);
  let from;
  if (
    location.state &&
    typeof (location.state as any).from !== "undefined" &&
    (location.state as any).from != "/signin"
  ) {
    from = location.state ? (location.state as any).from : { pathname: "/" };
    localStorage.setItem("history.url", from.pathname);
  } else {
    from = { pathname: localStorage.getItem("history.url") };
  }
  // console.log(from.pathname);
  // console.log(authContext.isAuth);
  // React.useEffect(() => {
  //   auth.signInWithPopup(provider);
  // }, []);
  // console.log(from.pathname);
  // console.log("context url", admediary_context.historyURL)
  /**
   * Using Firebase Auth, redirect user to Google Sign In
   * @constructor
   */
  const SignIn = () => {
    /**
     * Add #redirecting hash to URL to enable support for
     * a custom message upon returning from Google's Sign
     * In page.
     */
    // window.location.hash = "redirecting";
    // auth.signInWithRedirect(provider);
    // auth.signInWithPopup(provider);
    authContext.signInPopup();
  };

  return (
    <Container>
      <FlexboxGrid justify="center" style={{ marginTop: 100 }}>
        <FlexboxGrid.Item colspan={12}>
          <Panel header={<h3>Sign In</h3>} bordered>
            {authContext.isAuth ? (
              from.pathname === "/signin" || from.pathname === "/" ? (
                <Redirect to={"/"} />
              ) : (
                <Redirect to={from.pathname} />
              )
            ) : (
              <Button
                color="cyan"
                onClick={SignIn}
                // disabled={window.location.hash === "#redirecting"}
              >
                <Icon icon="google" /> with Google
              </Button>
            )}
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>
  );
};

export default Signin;

import React, { SyntheticEvent } from "react";
import { Table, Button, Divider, Notification } from "rsuite";
import { CheckCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import EditModal from "../EditModal";
import BuyerContractSourceList from "./BuyerContractSourceList";
import { Query } from "../../@Utils/AdmediaryApi";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractsWithSourcesProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  contractId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const BuyerContractsWithSources: React.FC<BuyerContractsWithSourcesProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  contractId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const [selectedItem, setSelectedItem] = React.useState();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleImportClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    openConfirmModal();
  };

  const handlePreviewClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    openPreviewModal();
  };

  const [openPreview, setOpenPreview] = React.useState(false);
  const openPreviewModal = () => setOpenPreview(true);
  const closePreviewModal = () => setOpenPreview(false);

  const [open, setOpen] = React.useState(false);
  const openConfirmModal = () => setOpen(true);
  const closeConfirmModal = () => setOpen(false);

  const handleImportSources = () => {
    closeConfirmModal();

    importSources(selectedItem.contract_id, contractId);
  };
  const importSources = (sourceId: number, destId: number) => {
    Query("buyer_contract_source_import", {
      source_contract_id: sourceId,
      destination_contract_id: destId,
    })
      .then((response) => {
        Notification.success({
          title: "Success",
          description: "Sources have been imported.",
        });
      })
      .catch((error) => {
        console.log(error);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: error,
        });
      });
  };

  return (
    <>
      <Table
        virtualized
        height={height}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        {categoryId === 14 && (
          <Column align="right" fixed sortable resizable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="lead_instance_status" />
          </Column>
        )}
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Contract ID</HeaderCell>
          <Cell dataKey="contract_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Buyer Name</HeaderCell>
          <Cell dataKey="buyer_name" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>Category Name</HeaderCell>
          <Cell dataKey="category_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Contract Name</HeaderCell>
          <Cell dataKey="contract_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Routing Tag</HeaderCell>
          <Cell dataKey="routing_tag" />
        </Column>
        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={200} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <ActionCell
            onImportClick={handleImportClick}
            onPreviewClick={handlePreviewClick}
          />
        </Column>
      </Table>

      <EditModal
        title="Sources"
        open={openPreview}
        size={"sm"}
        onClose={closePreviewModal}
        onCancel={closePreviewModal}
        hideSubmitButton
      >
        <div>Contract ID: {selectedItem ? selectedItem.contract_id : 0}</div>
        <div>
          Contract Name: {selectedItem ? selectedItem.contract_name : ""}
        </div>
        <br />
        <BuyerContractSourceList
          contractId={selectedItem ? selectedItem.contract_id : 0}
          onlyPreview={true}
        />
      </EditModal>

      <ConfirmModal
        title="Importing"
        open={open}
        onClose={closeConfirmModal}
        onYes={handleImportSources}
      >
        Are you sure you want to import sources?
      </ConfirmModal>
    </>
  );
};

const ActionCell = ({
  rowData,
  onImportClick,
  onPreviewClick,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <Button onClick={(e) => onImportClick(rowData, e)}>Import</Button>
      <Divider vertical />
      <Button onClick={(e) => onPreviewClick(rowData, e)}>Preview</Button>
    </Cell>
  );
};

export default BuyerContractsWithSources;

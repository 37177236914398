import React from "react";
import {
  Button,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Table,
  Modal,
  Whisper,
  Tooltip,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import { InputNumber } from "rsuite";
import { exportCsv } from "../../@Utils/Export";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormContext } from "../../@Context/FormContext";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

type BuyerRoutingBindingListProps = {
  routingId: number;
  height?: number;
};

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      "Contract Name": item.contract_name,
      Priority: item.priority,
    };
  });
}

const BuyerRoutingBindingList: React.FC<BuyerRoutingBindingListProps> = ({
  routingId = 0,
  height = 320,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const { config } = Admediary;
  const [operation, setOperation] = React.useState("add");
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState([]);
  const handleRemoveClose = () => setShow(false);
  const handleRemoveShow = (rowData: any) => {
    Forms.setBinding(rowData.binding_id);
    setOperation("remove");
    setShow(true);
  };
  const Contract = (index: any, key: any, value: any) => {
    const nextData = Object.assign([], data);
    Forms.setContract(value);
    //@ts-ignore
    nextData[index].contract_id = value;
    setData(nextData);
  };
  const Priority = (index: any, key: any, value: any) => {
    const nextData = Object.assign([], data);
    Forms.setPriority(value);
    //@ts-ignore
    nextData[index].priority = value;
    setData(nextData);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };
  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const params = {
    routing_set_id: routingId,
  };
  /**
   * Fetch data for table
   */
  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("routing_set_binding_select", params);
      const contracts = results.data.map((item: any) => ({
        ...item,
        rates:
          isJson(item.rates) && Array.isArray(JSON.parse(item.rates))
            ? JSON.parse(item.rates).map((rate: any) => rate.rate)
            : [],
      }));

      Forms.setContracts(contracts);
      Forms.setLock(false);
      setData(contracts);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setSearch = () => {};
  const handleChange = (search: any) => {
    Forms.setSearch(search.search);
    // Loop through all list items, and hide those who don't match the search query
    const filtered = data
      .filter(function (item: any) {
        return (
          item.contract_name
            .toUpperCase()
            .indexOf(search.search.toUpperCase()) > -1
        );
      })
      .map(function (item) {
        return item;
      });
    setData(filtered);
    if (search.search === "") {
      setData(Forms.contracts);
    }
  };

  const handleAddShow = () => {
    setShow(true);
    setOperation("add");
    Forms.setRoute(routingId);
    Forms.setBinding(0);
    Forms.setContract(0);
    Forms.setPriority(0);
  };
  const addBinding = () => {
    setIsLoading(true);
    setShow(false);
    const data = {
      binding_id: 0,
      routing_set_id: Forms.route,
      contract_id: Forms.contract,
      priority: Forms.priority,
    };

    (async () => {
      try {
        await Query("routing_set_binding_update", data);
        fetchData();
      } catch (e) {
        // setIs
      } finally {
      }
    })();
    Forms.setClose(true);
    Forms.setLock(false);
  };
  /**
   * edit Item
   *
   */
  const handleEditAction = (rowData: any) => {
    Forms.setLock(true);
    Forms.setContract(rowData.contract_id);
    Forms.setBinding(rowData.binding_id);
    Forms.setRoute(routingId);
    Forms.setPriority(rowData.priority);
  };

  const deleteRouteAction = () => {
    const params = {
      binding_id: Forms.binding,
    };
    setIsLoading(true);
    setShow(false);
    (async () => {
      try {
        await Query("routing_set_binding_remove", params);
        fetchData();
      } catch (e) {
        console.log("error", e);
      } finally {
      }
    })();
  };

  const setContract = (data: any) => {
    Forms.setContract(data);
  };
  const setPriority = (data: any) => {
    Forms.setPriority(data);
  };
  return (
    <FlexboxGrid.Item justify="space-between" style={{ marginBottom: 25 }}>
      <hr />
      <FlexboxGrid.Item>
        <Form onChange={handleChange} layout="inline">
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item>
              <FormGroup>
                <ControlLabel>Search</ControlLabel>
                <FormControl
                  name="search"
                  type="search"
                  placeholder="Search"
                  value={Forms.search}
                  onChange={setSearch}
                />
              </FormGroup>
              {/*<Button*/}
              {/*  appearance="ghost"*/}
              {/*  onClick={handleAddShow}*/}
              {/*  style={{*/}
              {/*    padding: 2,*/}
              {/*    paddingRight: 5,*/}
              {/*    marginLeft: 10,*/}
              {/*    marginTop: -5,*/}
              {/*  }}*/}
              {/*>*/}
              <IconButton
                appearance="ghost"
                onClick={handleAddShow}
                style={{
                  marginLeft: 15,
                }}
                icon={<Icon icon="plus-circle" />}
                placement="left"
                size="md"
                disabled={Admediary.userDisable}
              >
                Add Binding
              </IconButton>

              {/*</Button>*/}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <IconButton
                onClick={() =>
                  exportCsv(
                    `pingTree_EditRoute_Bindings_${format(
                      new Date(Admediary.start),
                      "MMddyy"
                    )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                    formatDataForExporting(Array.isArray(data) ? data : [])
                  )
                }
                icon={<Icon icon="file-download" />}
                placement="right"
                size="sm"
              >
                Export
              </IconButton>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form>

        <Modal
          show={show}
          onClose={handleRemoveClose}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              {operation === "add" ? "Add Binding" : "Remove Binding"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {operation === "add" ? (
              <Form>
                <FormGroup>
                  <ControlLabel>Contract</ControlLabel>
                  <SelectPicker
                    data={config.contracts.map((contract: any) => ({
                      contract_name: contract.contract_name,
                      contract_id: parseInt(contract.contract_id),
                    }))}
                    style={{ width: 150 }}
                    labelKey="contract_name"
                    valueKey="contract_id"
                    Placeholder={"Choose Contract"}
                    value={Forms.contract}
                    onChange={setContract}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Priority</ControlLabel>
                  <InputNumber
                    value={Forms.priority}
                    onChange={setPriority}
                    min={1}
                    max={100000}
                    step={1}
                  />
                </FormGroup>
              </Form>
            ) : (
              "You want to remove this Schedule. Are you sure"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={operation === "add" ? addBinding : deleteRouteAction}
              color={operation === "add" ? "blue" : "red"}
              appearance="primary"
            >
              {operation === "add" ? "Save" : "Remove"}
            </Button>
            <Button onClick={handleRemoveClose} appearance="subtle">
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item>
        <Table
          virtualized
          autoHeight={true}
          headerHeight={65}
          loading={isLoading === true}
          data={sortData(Array.isArray(data) ? data : [])}
          rowClassName="clickable-data striped-rows"
          affixHeader
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          <Column width={500} fixed sortable resizable>
            <HeaderCell>Contract</HeaderCell>
            <ContractCell
              contracts={config.contracts}
              dataKey="contract_name"
              Contract={Contract}
            />
          </Column>
          <Column width={120} align="left" sortable resizable>
            <HeaderCell>Priority</HeaderCell>
            <PriorityCell Priority={Priority} dataKey="priority"></PriorityCell>
          </Column>
          {Admediary.userDisable ? (
            ""
          ) : (
            <Column width={120} align="left" resizable>
              <HeaderCell>Action</HeaderCell>
              <ActionCell
                handleEditAction={handleEditAction}
                handleRemoveAction={handleRemoveShow}
                fetchData={fetchData}
              />
            </Column>
          )}
        </Table>
      </FlexboxGrid.Item>
    </FlexboxGrid.Item>
  );
};

const ContractCell = ({
  rowData,
  rowKey,
  contracts,
  dataKey,
  groupBy,
  Contract,
  ...props
}: any) => {
  const Forms = React.useContext(FormContext);
  const enabled = rowData.contract_id === Forms.contract && Forms.lock;

  const selector = (
    <SelectPicker
      data={contracts.map((contract: any) => ({
        contract_name: contract.contract_name,
        contract_id: parseInt(contract.contract_id),
      }))}
      labelKey="contract_name"
      valueKey="contract_id"
      style={{ width: 500 }}
      value={rowData.contract_id}
      disabled={!enabled}
      cleanable={false}
      onChange={(event) => {
        Contract && Contract(rowKey, dataKey, event);
      }}
    />
  );

  const tooltipText =
    Array.isArray(rowData.rates) && rowData.rates.length
      ? "Rates: " + rowData.rates.join("; ")
      : "Ping/Post";

  return (
    <Cell {...props} className="link-group">
      {enabled ? (
        selector
      ) : (
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{tooltipText}</Tooltip>}
        >
          {selector}
        </Whisper>
      )}
    </Cell>
  );
};
const PriorityCell = ({
  rowData,
  contracts,
  rowKey,
  dataKey,
  groupBy,
  Priority,
  ...props
}: any) => {
  const Forms = React.useContext(FormContext);

  return (
    <Cell {...props} className="link-group">
      <div style={{ width: 100 }}>
        <InputNumber
          value={rowData.priority}
          onChange={(event) => {
            Priority && Priority(rowKey, dataKey, event);
          }}
          min={1}
          max={100000}
          step={1}
          disabled={
            rowData.contract_id === Forms.contract && Forms.lock ? false : true
          }
        />
      </div>
    </Cell>
  );
};
const ActionCell = ({
  fetchData,
  rowData,
  handleEditAction,
  handleRemoveAction,
  ...props
}: any) => {
  const Forms = React.useContext(FormContext);
  const triggerRef = React.useRef();
  const editing = Forms.lock;
  const updateContract = () => {
    const data = {
      binding_id: Forms.binding,
      routing_set_id: Forms.route,
      contract_id: Forms.contract,
      priority: Forms.priority,
    };

    (async () => {
      try {
        await Query("routing_set_binding_update", data);
        fetchData();
      } catch (e) {
      } finally {
      }
    })();
    Forms.setClose(true);
    // @ts-ignore
    Forms.setLock(false);
  };

  return (
    <Cell {...props} className="link-group">
      {editing && rowData.binding_id === Forms.binding ? (
        <Button appearance="primary" onClick={updateContract}>
          Save
        </Button>
      ) : (
        <div className="icon-example-list">
          <IconButton
            onClick={(e) => handleEditAction(rowData, triggerRef)}
            icon={<Icon icon="edit2" />}
          />
          <IconButton
            appearance="subtle"
            onClick={(e) => handleRemoveAction(rowData, e)}
            icon={<Icon icon="trash-o" />}
          />
        </div>
      )}
    </Cell>
  );
};

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export default BuyerRoutingBindingList;

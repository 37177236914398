import React, { useEffect } from "react";
import Field from "../Field";
import SelectPicker from "rsuite/lib/SelectPicker";
import {
  Table,
  FlexboxGrid,
  Button,
  ButtonToolbar,
  IconButton,
  Icon,
  Form,
  Checkbox,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Schema,
  Grid,
  Row,
  Col,
  Nav,
  InputNumber,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Modal } from "rsuite";
import { FormContext } from "../../@Context/FormContext";
import axios from "axios";
import Config from "../../@Config/Forms";
import { Query } from "../../@Utils/AdmediaryApi";
import { CheckCell } from "../Table";
import PingTreeRoutingScheduleList from "./PingTreeRoutingScheduleList";
import PingTreeRoutingSourceList from "./PingTreeRoutingSourceList";
import BuyerRoutingBindingList from "./BuyerRoutingBindingList";
import { sort } from "../../@Utils/Sorting";
import { result } from "lodash";
const { Column, HeaderCell, Cell } = Table;

const { StringType } = Schema.Types;
const model = Schema.Model({
  description: StringType().isRequired("This field is required."),
});

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type RoutingsProps = {
  treeId: any;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const Routings: React.FC<RoutingsProps> = ({
  treeId,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const Forms = React.useContext(FormContext);
  const formRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState("main");
  const [operation, setOperation] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [routes, setRoutes] = React.useState([]);
  const [formError, setformError] = React.useState<any>({});
  const [formValue, setformValue] = React.useState({
    routing_set_id: Forms.route,
    tree_id: treeId,
    description: Forms.descriptionRoute,
    default_allow: Forms.defaultAllow,
    priority: Forms.priorityRoute,
    is_default: Forms.defaultRoute,
  });

  /**
   * warning modal window for removing Route
   *
   */

  const handleRemoveClose = () => {
    Forms.setLock(false);
    Forms.setBinding(0);
    // Forms.contract(0);
    setShow(false);
  };
  const handleRemoveShow = (rowData: any) => {
    Forms.setRoute(rowData.routing_set_id);
    setShow(true);
  };
  /**
   * open modal window for adding new Route
   *
   */
  const handleAddRoute = () => {
    setOpen(true);
    setOperation("add");
    Forms.setRoute(0);
    Forms.setActiveRoute(false);
    Forms.setDescriptionRoute("");
    Forms.setPriorityRoute(0);
    Forms.setDefaultRoute(false);
  };
  const params = {
    treeId: treeId,
  };

  /**
   * fetch Data
   *
   */

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("ping_tree_routing_set_select", params);
      setIsLoading(true);
      setRoutes(results.data);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    // Forms.setDefaultAllow(Forms.defaultAllow);
    // Forms.setPriorityRoute(Forms.priorityRoute);
    // Forms.setDefaultRoute(Forms.defaultRoute);
    // submitHandle();
    fetchData();
  }, [fetchData]);

  /**
   * remove Item
   *
   */

  const deleteRouteAction = async () => {
    setShow(false);
    const param = {
      routing_set_id: parseInt(Forms.route),
    };
    try {
      setIsLoading(true);
      await Query("ping_tree_routing_set_remove", param);
      fetchData();
    } catch (e) {
      setIsLoading(false);
    }
  };

  /**
   * edit Item
   *
   */

  const editRouteAction = (rowData: any) => {
    Forms.setActiveRoute(
      rowData.active === "1" || rowData.active === 1 ? true : false
    );
    Forms.setDescriptionRoute(rowData.description);
    Forms.setRoute(rowData.routing_set_id);
    Forms.setPriorityRoute(rowData.priority);
    Forms.setDefaultRoute(
      rowData.is_default === "1" || rowData.is_default === 1 ? true : false
    );
    Forms.setDefaultAllow(rowData.default_allow);
    setOperation("edit");
    setOpen(true);
  };

  /**
   * submit
   *
   */

  const submitHandle = () => {
    // @ts-ignore
    if (formRef && formRef.current && !formRef.current.check()) {
      return;
    }
    Forms.setLock(false);
    Forms.setBinding(0);
    Forms.setContract(0);
    const data = {
      // ...formValue,
      ...{
        routing_set_id: Forms.route,
        tree_id: treeId,
        description: Forms.descriptionRoute,
        active: Forms.activeRoute ? 1 : 0,
        priority: Forms.priorityRoute,
        default_allow:
          Forms.defaultAllow !== null
            ? Forms.defaultAllow === 0
              ? 0
              : Forms.defaultAllow
            : 1,
        is_default: Forms.defaultRoute ? 1 : 0,
      },
    };

    (async () => {
      try {
        await Query("ping_tree_routing_set_update", data);
        fetchData();
        setIsLoading(true);
      } catch (e) {
      } finally {
        setOpen(false);
      }
    })();
  };
  const handleChange = (data: any) => {
    setformValue(data);
  };
  const setActive = (data: any) => {
    Forms.setActiveRoute(!data);
  };

  const setDescription = (data: any) => {
    const trimmedValue = data.trim();
    Forms.setDescriptionRoute(trimmedValue);
  };

  const setPriorityRoute = (data: any) => {
    Forms.setPriorityRoute(data);
  };

  const setDefaultRoute = (data: any) => {
    Forms.setDefaultRoute(!data);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };
  /**
  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={2} style={{ textAlign: "right" }}>
          <div className="modal-container">
            <ButtonToolbar>
              <Button
                onClick={handleAddRoute}
                appearance="primary"
                style={{ marginTop: 15 }}
                disabled={Admediary.userDisable}
              >
                Add Routing Set
              </Button>
            </ButtonToolbar>
            <Modal
              size={"lg"}
              show={open}
              onHide={() => {
                setOpen(false);
                Forms.setContract(0);
                Forms.setBinding(0);
                Forms.setLock(true);
              }}
            >
              <Modal.Header>
                <Modal.Title>
                  {operation === "edit" ? "Edit Route Set" : "Add Route Set"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {operation === "add" ? (
                  <Form
                    ref={formRef}
                    onCheck={(formError) => {
                      setformError({ formError });
                    }}
                    onChange={handleChange}
                    formValue={formValue}
                    model={model}
                    layout="horizontal"
                  >
                    <FormField
                      label="Active"
                      name="active"
                      accepter={Checkbox}
                      checked={Forms.activeRoute}
                      onChange={setActive}
                      value={Forms.activeRoute ? 1 : 0}
                    />
                    <FormField
                      label="Description"
                      name="description"
                      onChange={setDescription}
                      error={formError.description}
                      value={Forms.descriptionRoute.toString().trim()}
                    />
                    <Field
                      label="Priority"
                      name="priority"
                      accepter={InputNumber}
                      placeholder="Select Priority"
                      defaultValue=""
                      onChange={setPriorityRoute}
                      // data={Array.from({ length: 20 }, (_, i) => ({
                      //   priority: i + 1,
                      // }))}
                      value={Forms.priorityRoute}
                      min={0}
                      max={20}
                      labelKey="priority"
                      valueKey="priority"
                      cleanable={false}
                    />
                    {/* <FormField
                      label="Priority"
                      name="priority"
                      onChange={setPriority}
                      error={formError.priority}
                      value={Forms.priorityRoute}
                    /> */}

                    <FormField
                      label="Default"
                      name="is_default"
                      accepter={Checkbox}
                      checked={Forms.defaultRoute}
                      onChange={setDefaultRoute}
                      value={Forms.defaultRoute ? 1 : 0}
                    />
                  </Form>
                ) : operation === "edit" ? (
                  <Grid fluid>
                    <Row>
                      <Col xs={24}>
                        <Nav
                          activeKey={activeKey}
                          appearance="tabs"
                          onSelect={(eventKey) => {
                            setActiveKey(eventKey);
                            Forms.setLock(false);
                          }}
                        >
                          <Nav.Item eventKey="main"> RoutingSet</Nav.Item>
                          <Nav.Item eventKey="schedule">Schedule</Nav.Item>
                          <Nav.Item eventKey="sources">Sources</Nav.Item>
                          <Nav.Item eventKey="bindings">Bindings</Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          display: activeKey === "main" ? "block" : "none",
                          minHeight: "100px",
                        }}
                      >
                        <Form
                          ref={formRef}
                          onCheck={(formError) => {
                            setformError({ formError });
                          }}
                          onChange={handleChange}
                          formValue={formValue}
                          model={model}
                          layout="horizontal"
                        >
                          <FormField
                            label="Active"
                            name="active"
                            accepter={Checkbox}
                            // errorMessage={formError.buyer}
                            checked={Forms.activeRoute}
                            onChange={setActive}
                            value={Forms.activeRoute ? 1 : 0}
                            disabled={Admediary.userDisable}
                          />
                          <FormField
                            label="Description"
                            name="description"
                            onChange={setDescription}
                            error={formError.description}
                            value={Forms.descriptionRoute.toString()}
                            disabled={Admediary.userDisable}
                          />
                          {/* <FormField
                            label="Priority"
                            name="priority"
                            onChange={setPriority}
                            error={formError.priority}
                            value={Forms.priorityRoute}
                          /> */}
                          <Field
                            label="Priority"
                            name="priority"
                            accepter={InputNumber}
                            placeholder="Select Priority"
                            defaultValue=""
                            onChange={setPriorityRoute}
                            // data={Array.from({ length: 20 }, (_, i) => ({
                            //   priority: i + 1,
                            // }))}
                            value={Forms.priorityRoute}
                            min={0}
                            max={20}
                            labelKey="priority"
                            valueKey="priority"
                            cleanable={false}
                            disabled={Admediary.userDisable}
                          />
                          <FormField
                            label="Default"
                            name="is_default"
                            accepter={Checkbox}
                            checked={Forms.defaultRoute}
                            onChange={setDefaultRoute}
                            value={Forms.defaultRoute ? 1 : 0}
                            disabled={Admediary.userDisable}
                          />
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          display: activeKey === "schedule" ? "block" : "none",
                        }}
                      >
                        <PingTreeRoutingScheduleList routingId={Forms.route} />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          display: activeKey === "sources" ? "block" : "none",
                          marginTop: 10,
                        }}
                      >
                        <PingTreeRoutingSourceList routingId={Forms.route} />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          display: activeKey === "bindings" ? "block" : "none",
                        }}
                      >
                        <BuyerRoutingBindingList routingId={Forms.route} />
                      </Col>
                    </Row>
                  </Grid>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={submitHandle}
                  appearance="primary"
                  disabled={Admediary.userDisable}
                >
                  Ok
                </Button>
                <Button
                  onClick={() => {
                    setformError({});
                    setOpen(false);
                    Forms.setLock(true);
                    Forms.setBinding(0);
                    Forms.setContract(0);
                  }}
                  appearance="subtle"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={show}
              onClose={() => {
                setformError({});
                setOpen(false);
                Forms.setDescriptionRoute("");
              }}
            >
              <Modal.Header>
                <Modal.Title>Modal Title</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                You want to remove this Route. Are you sure?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={deleteRouteAction}
                  color="red"
                  appearance="primary"
                >
                  Yes
                </Button>
                <Button onClick={handleRemoveClose} appearance="subtle">
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        height={300}
        headerHeight={65}
        loading={isLoading}
        data={sortData(routes)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="center" width={100} fixed sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column align="center" width={135} fixed sortable resizable>
          <HeaderCell>Routing Set ID</HeaderCell>
          <Cell dataKey="routing_set_id" />
        </Column>
        <Column align="center" width={100} fixed sortable resizable>
          <HeaderCell>Tree ID</HeaderCell>
          <Cell dataKey="tree_id" />
        </Column>
        <Column align="center" width={135} fixed sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column align="center" width={135} fixed sortable resizable>
          <HeaderCell>Default Allow</HeaderCell>
          <CheckCell dataKey="default_allow" />
        </Column>
        <Column align="center" width={100} fixed sortable resizable>
          <HeaderCell>Priority</HeaderCell>
          <Cell dataKey="priority" />
        </Column>
        <Column align="center" width={100} fixed sortable resizable>
          <HeaderCell>Is Default</HeaderCell>
          <CheckCell dataKey="is_default" />
        </Column>
        <Column width={300} sortable resizable>
          <HeaderCell>Action</HeaderCell>
          <ActionCell
            deleteRouteAction={deleteRouteAction}
            editRouteAction={editRouteAction}
            handleRemoveShow={handleRemoveShow}
            userPermission={Admediary.userDisable}
          ></ActionCell>
        </Column>
      </Table>
    </>
  );
};
const FormField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 150 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <HelpBlock>{message}</HelpBlock>
    </FormGroup>
  );
};

const ActionCell = ({
  rowData,
  deleteRouteAction,
  editRouteAction,
  handleRemoveShow,
  userPermission,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={(e) => editRouteAction(rowData, e)}
        icon={<Icon icon="edit2" />}
      />
      {!userPermission ? (
        <IconButton
          appearance="subtle"
          onClick={(e) => handleRemoveShow(rowData, e)}
          icon={<Icon icon="trash" />}
        />
      ) : (
        ""
      )}
    </Cell>
  );
};

export default Routings;

import React, { useEffect, useState, useRef } from "react";
import {
  Icon,
  IconButton,
  Popover,
  Table,
  Whisper,
  Dropdown,
  Loader,
} from "rsuite";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import { sort } from "../../../@Utils/Sorting";
import ViewTransactions from "../../Drawers/Transactions";
import ViewHistory from "../../Drawers/ViewHistory";
import Lead from "../../Drawers/Lead";
import { format } from "date-fns";
import { registerAllModules } from "handsontable/registry";
import "../../../css/handsonTableCustomStyles.css";
import { HotTable, HotColumn } from "@handsontable/react";
import $ from "jquery";

registerAllModules();

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type LeadDetailListProps = {
  data: any;
  config: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  status: string;
  categoryId: number;
  affiliateId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
  parentCallback?: any;
};

const LeadDetailList: React.FC<LeadDetailListProps> = ({
  data = [],
  summary = {},
  config = {},
  isLoading = false,
  rowKey = "node_id",
  status = "",
  categoryId = 0,
  affiliateId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
  parentCallback = () => {},
}) => {
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const [Loading, setIsLoading] = useState(false);
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const [gridData, setGridData] = useState([]);
  const [hasData, setHasData] = useState(false);
  // @ts-ignore

  useEffect(() => {
    setIsLoading(true);
    // @ts-ignore

    setGridData([...gridData, data]);
    setHasData(data.length > 0 ? false : true);
    setIsLoading(false);
  }, [data]);

  // Build dynamic columns related by category and product ids
  const dynamicColumns =
    config && Array.isArray(config.keys) && config.keys.length
      ? config.keys.map((field: any, index: number) => (
          <Column sortable resizable key={index}>
            <HeaderCell>{config.fields[index]}</HeaderCell>
            <Cell dataKey={field.name} />
          </Column>
        ))
      : [];

  const dynamicColumns1 =
    config && Array.isArray(config.keys) && config.keys.length
      ? config.keys.map((field: any, index: number) => (
          <HotColumn
            title={config.fields[index]}
            data={field.name}
            width={200}
            readOnly={true}
          />
        ))
      : [];

  const formatUSD = {
    pattern: "0,0.00 $",
    culture: "en-US",
  };

  let fixedColValue = 1;

  useEffect(() => {
    if (categoryId === 14) {
      fixedColValue = 2;
    } else if (categoryId !== 14) {
      fixedColValue = 1;
    }
  }, [categoryId, fixedColValue]);

  const CustomRenderer = (props: any, status: any, affiliateId: any) => {
    let value = 0;
    if (categoryId === 14) {
      value = 1;
    } else {
      value = 0;
    }
    const item = {
      ...data[props.row],
      // @ts-ignore
      lead_instance_id: hotTableComponent.current.hotInstance.getData(
        props.row,
        // @ts-ignore
        hotTableComponent.current.hotInstance.propToCol("lead_instance_id")
      )[props.row][value],
    };

    return (
      <CustomWhisper
        row={item}
        statusBy={status}
        affiliateId={affiliateId}
      ></CustomWhisper>
    );
  };

  // Get the Handsontable table element
  const table = document.getElementsByClassName("handsontable");

  // Attach a click event listener to the table rows
  $(table).on("click", "tr", function () {
    // Remove the background color from any previously selected rows
    $("tr.selectedhtable").removeClass("selectedhtable");

    // Add the background color to the clicked row
    $(this).addClass("selectedhtable");
  });

  // Define the CSS class that defines the background color
  const selectedRowStyle = `
  .selectedhtable {
    background-color: #a6c9ff !important;
  }
`;

  // Add the CSS class to the document head
  const style = document.createElement("style");
  style.type = "text/css";
  style.appendChild(document.createTextNode(selectedRowStyle));
  document.head.appendChild(style);
  const hotTableComponent = useRef(null);
  const columnDataKeys = useRef([]);

  useEffect(() => {
    if (hotTableComponent.current) {
      // @ts-ignore
      const hot = hotTableComponent.current.hotInstance;
      hot.updateSettings({
        colWidths: 120,
        cells: function (row: any, col: any, prop: any) {
          var cellProperties = {};
          // @ts-ignore
          cellProperties.className = "wordWrap";
          return cellProperties;
        },
      });

      columnDataKeys.current = hot
        .getColHeader()
        .map((header: any, col: any) => {
          const columnSettings = hot.getSettings().columns[col];
          return columnSettings.data;
        });

      hot.addHook("afterFilter", (conditionsStack: any) => {
        // Get the filtered data as an array of arrays
        const rawFilteredData = hot.getData();

        // Construct objects for each row using the data keys
        const filteredData = rawFilteredData.map((row: any) => {
          const rowObject: any = {};
          hot.getColHeader().forEach((header: any, col: any) => {
            const dataKey = columnDataKeys.current[col];
            rowObject[dataKey] = row[col];
          });
          return rowObject;
        });

        const filteredLeadIds = filteredData.map((item: any) => {
          return item.lead_instance_id;
        });

        if (parentCallback instanceof Function) {
          parentCallback(filteredLeadIds);
        }
      });
    }
  }, [data]);

  if (categoryId === 14) {
    fixedColValue = 2;
  } else if (categoryId !== 14) {
    fixedColValue = 1;
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "60%",
            height: "500px",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
          <p style={{ marginLeft: "10px" }}>Loading...</p>
        </div>
      )}
      {hasData && !isLoading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "60%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <p>No Data</p>
        </div>
      )}
      {
        <div className="hot">
          <HotTable
            ref={hotTableComponent}
            data={isLoading ? [] : sortData(data)}
            colHeaders={["Action"]}
            dropdownMenu={true}
            filters={true}
            width="-webkit-fill-available"
            height="80vh"
            rowHeights="60"
            autoColumnSize={true}
            columnSorting={{
              sortEmptyCells: false,
            }}
            manualColumnResize={true}
            fixedColumnsStart={fixedColValue}
            viewportColumnRenderingOffset={99}
            licenseKey="non-commercial-and-evaluation"
            copyPaste={{
              columnsLimit: 200,
              rowsLimit: 2000,
              pasteMode: "shift_down",
              copyColumnHeaders: true,
              copyColumnGroupHeaders: true,
              copyColumnHeadersOnly: true,
            }}
          >
            {categoryId === 14 && (
              <HotColumn
                title="Status"
                data="lead_instance_status"
                readOnly={true}
                filters={false}
              />
            )}
            <HotColumn
              title="ID"
              data="lead_instance_id"
              readOnly={true}
              width="120"
              sortIndicator={false}
            />
            <HotColumn
              title="Product ID"
              data="product_id"
              readOnly={true}
              width="120"
            />
            <HotColumn
              title="Description"
              data="product_description"
              readOnly={true}
              width="120"
            />

            <HotColumn title="Email" data="email" width={150} readOnly={true} />

            <HotColumn
              title="Paid"
              data="pixel"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="Calling"
              data="held"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="TK Call"
              data="tk_call"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="Buy/Hold"
              data="default"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="BH Pending"
              data="pending"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="BH Processing"
              data="repost_processing"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="Direct Post"
              data="direct_post"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="Is Test"
              data="is_test"
              readOnly={true}
              width="120"
              type="checkbox"
              checkedTemplate="1"
              uncheckedTemplate="0"
            />

            <HotColumn
              title="RSID"
              data="request_session_id"
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Affiliate ID"
              data="affiliate_id"
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Affiliate Name"
              data="affiliate_name"
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Status"
              data="status"
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Rate"
              data="rate"
              type="numeric"
              numericFormat={formatUSD}
              readOnly={true}
              width="120"
            />
            <HotColumn
              title="Earned"
              data="earned"
              type="numeric"
              numericFormat={formatUSD}
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Profit"
              data="profit"
              type="numeric"
              numericFormat={formatUSD}
              readOnly={true}
              width="120"
            />

            <HotColumn
              title="Client IP"
              data="client_ip"
              width="120"
              readOnly={true}
            />

            <HotColumn
              title="Buyers"
              data="buyers"
              width="120"
              readOnly={true}
            />

            <HotColumn
              title="Contract Name"
              data="contract_name"
              width="120"
              readOnly={true}
            />

            <HotColumn
              title="Tier Name"
              data="tier_name"
              width="120"
              readOnly={true}
            />

            <HotColumn
              title="Error List"
              data="error_list"
              width={200}
              readOnly={true}
            />

            <HotColumn
              title="Duration"
              data="duration"
              width="80"
              readOnly={true}
            />

            <HotColumn
              title="Entry Date"
              data="entry_date"
              width="120"
              readOnly={true}
            />

            <HotColumn
              title="Transaction Date"
              data="transaction_date"
              width={180}
              readOnly={true}
            />

            <HotColumn title="SID1" data="sid1" readOnly={true} />

            <HotColumn title="SID2" data="sid2" readOnly={true} />

            <HotColumn title="SID3" data="sid3" readOnly={true} />

            {dynamicColumns1}

            <HotColumn
              title="Buyer Disposition"
              data="lid_disposition"
              width={180}
              readOnly={true}
            />
            <HotColumn title="Fraud Score" data="fraud_score" readOnly={true} />

            <HotColumn title="Proxy" data="proxy" readOnly={true} />

            <HotColumn
              title="Recent Abuse"
              data="recent_abuse"
              readOnly={true}
            />

            <HotColumn title="Bot Status" data="bot_status" readOnly={true} />

            <HotColumn title="Tor" data="tor" readOnly={true} />

            <HotColumn title="VPN" data="vpn" readOnly={true} />

            <HotColumn title="Mobile" data="mobile" readOnly={true} />

            <HotColumn
              title="Abuse Velocity"
              data="abuse_velocity"
              readOnly={true}
            />
            <HotColumn title="Action" readOnly={true}>
              <CustomRenderer
                status={status}
                affiliateId={affiliateId}
                hot-renderer
              />
            </HotColumn>
          </HotTable>
        </div>
      }
    </>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_lead">View Lead</Dropdown.Item>
    <Dropdown.Item eventKey="view_transactions">
      View Transactions
    </Dropdown.Item>
    <Dropdown.Item eventKey="view_history">View History</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
  statusBy: string;
  affiliateId: string;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  // const statusBy = props.statusBy;
  // const affiliateId = props.affiliateId;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["view_lead", <Lead row_data={row} />],
      ["view_transactions", <ViewTransactions row_data={row} />],
      ["view_history", <ViewHistory row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_lead", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

export default LeadDetailList;

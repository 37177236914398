import React from "react";
import { Drawer, FlexboxGrid, Icon, Table } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HourCell, NumberCell } from "../Table";
import { sort } from "../../@Utils/Sorting";

const { Column, HeaderCell } = Table;

type HourlyVolume = {
  row_data: any;
  start?: any;
  end?: any;
};

const HourlyVolume: React.FC<HourlyVolume> = ({ row_data, start, end }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  let currentDate;

  if (start) {
    currentDate = start;
  } else {
    const date = row_data.start_date.split(" ")[0].split("-");
    currentDate = date[1] + "/" + date[2] + "/" + date[0];
  }

  const params = {
    start_date: start ? new Date(start) : Admediary.start,
    end_date: end ? new Date(end) : Admediary.end,
    data_source_id: row_data.data_source_id,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  const [data, isLoading] = useAdmediaryApi("hourly_volume", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Hourly Volume - {row_data.data_source_name} - {currentDate}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item
            colspan={24}
            style={{ textAlign: "right" }}
          ></FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={Array.isArray(data) ? sortData(data) : []}
          hover
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Hour</HeaderCell>
            <HourCell dataKey="date_offset" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Count</HeaderCell>
            <NumberCell dataKey="volume" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default HourlyVolume;

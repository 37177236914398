import React from "react";
import { Drawer, FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import EsignDocStatusTable from "../EsignDoc/EsignDocStatusTable";
import { sort } from "../../@Utils/Sorting";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";
import moment from "moment/moment";

type EsignStatus = {
  row_data: any;
};

const EsignStatusDrawer: React.FC<EsignStatus> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState("desc");
  const [sort_column, set_sort_column] = React.useState("date_created");
  const defaultGroupValue = "date_modified";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const defaultStatusGroupValue = "document.completed";
  const [statusgroup, setStatusGroup] = React.useState(defaultStatusGroupValue);

  const groupByList = [
    { group: "date_created", label: "Date Created" },
    { group: "date_modified", label: "Date Modified" },
  ];

  const docStatusGroupByList = [
    { status: "all", label: "All" },
    { status: "document.completed", label: "Completed" },
    { status: "document.viewed", label: "Viewed" },
    { status: "document.sent", label: "Sent" },
    { status: "document.voided", label: "Voided" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    group: group,
    affiliate_id: row_data.affiliate_id,
    status: statusgroup,
  };

  const [data, isLoading] = useAdmediaryApi(
    "panda_doc_status_lead_select",
    params
  );

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          post_data: Object.fromEntries(new URLSearchParams(item.post_data)),
        },
      };
    });
  };
  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const dataToFlatten = Array.isArray(data) ? formatData(data) : [];

  const flattenTree = (list: any) => {
    const flattened: any = {};

    Object.keys(list).forEach((key: any) => {
      const value = list[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, flattenTree(value));
      } else {
        flattened[key] = value;
      }
    });
    return flattened;
  };

  const flattenLoop = (list: any) => {
    return list.map((item: any) => {
      return flattenTree(item);
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  function formatDataForExporting(data: any) {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          date_created: item.date_created
            ? moment(item.date_created).local().format("MM-DD-Y HH:mm")
            : "",
          date_modified: item.date_modified
            ? moment(item.date_modified).local().format("MM-DD-Y HH:mm")
            : "",
        },
      };
    });
  }

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          E-Sign Status
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={19}>
            <SelectPicker
              size="sm"
              searchable={false}
              cleanable={false}
              placeholder="Filter by"
              defaultValue={defaultGroupValue}
              value={group}
              data={groupByList}
              valueKey="group"
              labelKey="label"
              onChange={(v) => {
                setGroup(v);
              }}
              style={{ marginRight: 15 }}
            />
            <SelectPicker
              size="sm"
              searchable={false}
              cleanable={false}
              placeholder="Filter Status by"
              defaultValue={defaultStatusGroupValue}
              value={statusgroup}
              data={docStatusGroupByList}
              valueKey="status"
              labelKey="label"
              onChange={(v) => {
                setStatusGroup(v);
              }}
              style={{ marginRight: 15 }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `esign_status_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  formatDataForExporting(flattenLoop(dataToFlatten))
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <EsignDocStatusTable
          data={flattenLoop(dataToFlatten)}
          isLoading={isLoading === true}
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        />
      </Drawer.Body>
    </>
  );
};

export default EsignStatusDrawer;

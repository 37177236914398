import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  Pagination,
  InputGroup,
  Input,
  SelectPicker,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import RecentRecordsList from "./RecentRecordsList";
import { Loader } from "rsuite";

type RecentRecords = {
  row_data: any;
  start?: any;
  end?: any;
};

const RecentRecords: React.FC<RecentRecords> = ({ row_data, start, end }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);
  const [records, setRecords] = React.useState();
  const [searchQuery, setSearchQuery] = React.useState();
  const [search, setSearch] = React.useState();
  const [recordStatusFilter, setRecordStatusFilter] = React.useState("All");

  const params = {
    start_date: start ? new Date(start) : Admediary.start,
    end_date: end ? new Date(end) : Admediary.start,
    data_source_id: row_data.data_source_id,
    data_target_id: row_data.service_id,
    offset: offset,
    row_count: 100,
    query: search,
    tag: recordStatusFilter,
  };

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const results: any = await Query("recent_records", params);
      setRecords(results.data.data);
      setTotal(results.data.count);
      setPages(Math.ceil(results.data.count / 100));
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [page, search, recordStatusFilter]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const searchByQuery = () => {
    setSearch(searchQuery);
    setOffset(0);
    setPage(1);
  };

  const onChangePage = (page: number): void => {
    setOffset(100 * (page - 1));
    setPage(page);
  };
  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      setSearch(searchQuery);
      setOffset(0);
      setPage(1);
    }
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */

  const debounceSearchTerm = (value: any) => {
    setSearchQuery(value);
  };

  const recentRecordStatus = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Duplicate",
      value: "duplicate",
    },
    {
      label: "Failure",
      value: "failure",
    },
    {
      label: "Queued",
      value: "queued",
    },
  ];

  const styles = {
    width: 300,
    marginBottom: 10,
  };
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Recent Records
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={8}>
            <InputGroup inside style={styles}>
              <Input
                placeholder="Search"
                size="md"
                value={searchQuery}
                onChange={(v) => {
                  debounceSearchTerm(v);
                }}
                onKeyUp={onKeyUpEnter}
              />
              <InputGroup.Button onClick={searchByQuery}>
                <Icon icon="search" />
              </InputGroup.Button>
            </InputGroup>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <InputGroup inside>
              <SelectPicker
                size="md"
                searchable={false}
                cleanable={false}
                defaultValue={"all"}
                value={recordStatusFilter}
                data={recentRecordStatus}
                onChange={(v) => {
                  setRecordStatusFilter(v);
                }}
              />
            </InputGroup>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <span style={{ marginLeft: 20 }}>
              {" "}
              Matching Rows：{isLoading ? <Loader /> : total}{" "}
            </span>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <RecentRecordsList data={records} isLoading={isLoading === true} />
        <div style={{ padding: 20 }}>
          {records ? (
            <Pagination
              first={true}
              last={true}
              prev={true}
              next={true}
              ellipsis={true}
              boundaryLinks={true}
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              limitOptions={[10]}
              limit={10}
              pages={pages}
              activePage={page}
              onSelect={onChangePage}
            />
          ) : (
            <Loader />
          )}
        </div>
      </Drawer.Body>
    </>
  );
};

export default RecentRecords;

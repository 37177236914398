import React from "react";
import { Button, Modal } from "rsuite";
import { TypeAttributes } from "rsuite/lib/@types/common";

type EditModalType = {
  title?: string;
  open?: boolean;
  loading?: boolean;
  hideButtons?: boolean;
  hideSubmitButton?: boolean;
  hideCancelButton?: boolean;
  size?: TypeAttributes.Size;
  onClose?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  onShow?: any;
};

const EditModal: React.FC<EditModalType> = ({
  title = "",
  open = false,
  loading = false,
  onShow,
  onClose,
  onCancel,
  onSubmit,
  hideButtons = false,
  hideSubmitButton = false,
  hideCancelButton = false,
  ...props
}) => {
  return (
    <Modal
      show={open}
      size="lg"
      onShow={() => {
        if (typeof onShow === "function") {
          onShow();
        }
      }}
      onHide={() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      {...props}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        {!hideButtons && !hideSubmitButton && (
          <Button
            appearance="primary"
            onClick={(e) => {
              if (typeof onSubmit === "function") {
                onSubmit();
              }
            }}
            loading={loading}
          >
            Submit
          </Button>
        )}
        {!hideButtons && !hideCancelButton && (
          <Button
            onClick={() => {
              if (typeof onCancel === "function") {
                onCancel();
              }
            }}
            appearance="subtle"
          >
            Cancel
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;

import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { exportCsv } from "../../@Utils/Export";
import { CurrencyCell } from "../Table";
import { format } from "date-fns";
import Title from "../Title";

const { Column, HeaderCell, Cell } = Table;

type ViewTransactions = {
  row_data: any;
};

const ViewTransactions: React.FC<ViewTransactions> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const lead_instance_id = row_data.lead_instance_id;

  const params = {
    lead_instance_id,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const formatDataForExporting = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
        },
      };
    });
  };

  const [data, isLoading] = useAdmediaryApi("view_transactions", params);
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* View Transactions */}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Title title="View Transactions" />
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `leads_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  formatDataForExporting(data)
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          height={650}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>
              <span>ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Transactions ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="transaction_id" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Contract ID</span>
              <Whisper trigger="hover" speaker={<Tooltip>Contract ID</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="contract_id" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Contract</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Contract Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="contract_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Buyer Name</span>
              <Whisper trigger="hover" speaker={<Tooltip>Buyer Name</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="buyer_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Product</span>
              <Whisper trigger="hover" speaker={<Tooltip>Request</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="request" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Response</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's first name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="response" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Status</span>
              <Whisper trigger="hover" speaker={<Tooltip>Status</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Rate</span>
              <Whisper trigger="hover" speaker={<Tooltip>Rate</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <CurrencyCell dataKey="rate" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Total Time</span>
              <Whisper trigger="hover" speaker={<Tooltip>Total Time</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="total_time" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>Post Time</span>
              <Whisper trigger="hover" speaker={<Tooltip>Post Time</Tooltip>}>
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="post_time" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default ViewTransactions;

import React, { SyntheticEvent } from "react";
import { Table, Notification } from "rsuite";
import { CheckCell, EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import EditPixelPercentage from "../Drawers/EditPixelPercentage";
import { PixelPercentageType } from "./PixelPercentage";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */

type PixelPercentageListProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
  callbackOnSave: Function;
  callbackOnRemove: Function;
};

const PixelPercentageList: React.FC<PixelPercentageListProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
  callbackOnSave,
  callbackOnRemove,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const { config } = admediaryContext;
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const preparePixelPercentage = (record: any) => {
    return {
      ...record,
      afid: record.affiliate_id,
      active: record.active === 1 ? [1] : [],
    };
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    admediaryContext.openDrawer(
      <EditPixelPercentage
        percentage={preparePixelPercentage(rowData)}
        callbackOnSave={callbackOnSave}
      />,
      "md"
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-y HH:mm")
            : "",
          product_display:
            "(" +
            item.product_id +
            ") " +
            config.products.find(
              (prod: any) => prod.product_id == item.product_id
            ).product_description,
        },
      };
    });
  };

  const sortData = (list: any) => {
    list = formatData(list);
    if (sortColumn && sortType) {
      //If column can be an integer, than sort as an integer.
      if (
        list[0][sortColumn] != null &&
        !isNaN(parseInt(list[0][sortColumn]))
      ) {
        return sort(
          list.map((record: any) => {
            return {
              ...record,
              sortColumn: parseInt(record[sortColumn]),
            };
          }),
          sortColumn,
          sortType
        );
      }
      let sortCol =
        sortColumn == "product_display" ? "product_description" : sortColumn;
      return sort(list, sortCol, sortType);
    }
    return list;
  };

  const handleRemoveActionClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);
    setOpen(true);
  };
  const handleRemoving = () => {
    removePercent((selectedItem ?? { percent_id: 0 }).percent_id);
    setOpen(false);
  };

  const removePercent = (percentId: number = 0) => {
    if (percentId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("pixel_percentage_remove", { percent_id: percentId });
        Notification.success({
          title: "Success",
          description: "Pixel Percentage has been removed",
        });

        if (refreshData instanceof Function) {
          refreshData();
        }
        callbackOnRemove(percentId);
      } catch (e) {
        console.log("error", e);
      } finally {
      }
    })();
  };

  return (
    <>
      <Table
        virtualized
        // height={650}
        autoHeight={true}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="center" fixed sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>

        <Column width={220} align="right" fixed sortable resizable>
          <HeaderCell>Product</HeaderCell>
          <Cell dataKey="product_display" />
        </Column>

        <Column width={135} sortable resizable>
          <HeaderCell>Tier Name</HeaderCell>
          <Cell dataKey="tier_name" />
        </Column>

        <Column align="right" fixed sortable resizable>
          <HeaderCell>AFID</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>

        <Column width={80} align="right" fixed sortable resizable>
          <HeaderCell>SID1</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>

        <Column width={200} sortable resizable>
          <HeaderCell>Affiliate Name</HeaderCell>
          <Cell dataKey="affiliate_name" />
        </Column>

        <Column width={100} align="right" sortable resizable>
          <HeaderCell>Pay %</HeaderCell>
          <Cell dataKey="percent_pay" />
        </Column>

        {admediaryContext.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed="right">
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}
      </Table>

      <ConfirmModal
        title="Removing Pixel Percentage"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Pixel Percentage?
      </ConfirmModal>
    </>
  );
};

export default PixelPercentageList;

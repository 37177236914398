import React, { SyntheticEvent } from "react";
import { Table, Notification } from "rsuite";
import { CheckCell, EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import EditProduct from "../Drawers/EditProduct";
import { ProductsType } from "./Products";
import { FormContext } from "../../@Context/FormContext";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */

type ProductsListProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
  callbackOnSave: Function;
  // callbackOnRemove: Function;
};

const ProductsList: React.FC<ProductsListProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
  callbackOnSave,
  // callbackOnRemove,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const prepareProduct = (record: any) => {
    Forms.setDefaultAllow(
      record.default_allow !== undefined ? record.default_allow : null
    );

    record.affiliate_caps_filter = parseInt(record.affiliate_caps_filter);

    return {
      ...record,
    };
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    admediaryContext.openDrawer(
      <EditProduct
        product={prepareProduct(rowData)}
        callbackOnSave={callbackOnSave}
      />,
      "lg"
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{},
      };
    });
  };

  const sortData = (list: any) => {
    list = formatData(list);
    if (sortColumn && sortType) {
      //If column can be an integer, than sort as an integer.
      if (
        list[0][sortColumn] != null &&
        !isNaN(parseInt(list[0][sortColumn]))
      ) {
        return sort(
          list.map((record: any) => {
            return {
              ...record,
              sortColumn: parseInt(record[sortColumn]),
            };
          }),
          sortColumn,
          sortType
        );
      }
      let sortCol =
        sortColumn == "product_display" ? "product_description" : sortColumn;
      return sort(list, sortCol, sortType);
    }
    return list;
  };

  return (
    <>
      <Table
        virtualized
        // height={650}
        autoHeight={true}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="center" fixed sortable resizable>
          <HeaderCell>Product ID</HeaderCell>
          <Cell dataKey="product_id" />
        </Column>

        <Column width={150} align="left" fixed sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="product_description" />
        </Column>

        <Column width={200} sortable resizable>
          <HeaderCell>Product Url</HeaderCell>
          <Cell dataKey="product_url" />
        </Column>

        <Column align="center" fixed sortable resizable>
          <HeaderCell>Category ID</HeaderCell>
          <Cell dataKey="category_id" />
        </Column>

        <Column width={150} align="left" fixed sortable resizable>
          <HeaderCell>Category Name</HeaderCell>
          <Cell dataKey="category_name" />
        </Column>

        <Column width={100} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            // removeHandler={handleRemoveActionClick}
          />
        </Column>
      </Table>
    </>
  );
};

export default ProductsList;

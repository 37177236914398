import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { currency, thousands } from "../../@Utils/Format";
import BuyHoldTreeTable from "./BuyHoldTreeTable";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "total_leads",
    "unposted_leads",
    "posted_leads",
    "sold_leads",
    "unsold_leads",
    "revenue",
    "cost",
    "pnl",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function getExportData(data: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        ...{
          product_id: item.product_id || parent.product_id,
          product_name: item.product_name || parent.product_name,
          bh_contract_id: item.bh_contract_id || parent.bh_contract_id,
        },
        ...item,
      };

      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });

    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Product ID": item.product_id,
          "Product Name": item.product_name,
          "BH Contract ID": item.bh_contract_id,
          Date: item.entry_date,
          "Total Leads": thousands(item.total_leads),
          "Unposted Leads": thousands(item.unposted_leads),
          "Posted Leads": thousands(item.posted_leads),
          "Sold Leads": thousands(item.sold_leads),
          "Unsold Leads": thousands(item.unsold_leads),
          Revenue: currency(item.revenue),
          Cost: currency(item.cost),
          "P&L": currency(item.pnl),
        };
      })
  );
}

function addCalculatedValues(row: any) {
  row.total_leads = isNaN(parseInt(row.total_leads))
    ? 0
    : parseInt(row.total_leads);
  row.unposted_leads = isNaN(parseInt(row.unposted_leads))
    ? 0
    : parseInt(row.unposted_leads);
  row.posted_leads = isNaN(parseInt(row.posted_leads))
    ? 0
    : parseInt(row.posted_leads);
  row.sold_leads = isNaN(parseInt(row.sold_leads))
    ? 0
    : parseInt(row.sold_leads);
  row.unsold_leads = isNaN(parseInt(row.unsold_leads))
    ? 0
    : parseInt(row.unsold_leads);
  row.revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);
  row.cost = isNaN(parseFloat(row.cost)) ? 0 : parseFloat(row.cost);
  row.pnl = isNaN(parseFloat(row.pnl)) ? 0 : parseFloat(row.pnl);

  return row;
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const BuyHoldReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = "date_queued";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    group: group,
  };

  // const [data, isLoading] = useAdmediaryApi("products_buyhold_tree", params);
  const [data, isLoading] = useAdmediaryApiManual(
    "products_buyhold_tree",
    params
  );

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);
  const groupByList = [
    { group: "date_queued", label: "Date Queued" },
    { group: "date_posted", label: "Date Posted" },
  ];

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="Buy/Hold Status Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Detail by"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `products_buyhold_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyHoldTreeTable
        data={treeData}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        group={group}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default BuyHoldReport;

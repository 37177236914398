import React from "react";
import {
  Table,
  IconButton,
  Icon,
  Input,
  InputGroup,
  FlexboxGrid,
  Whisper,
  Tooltip,
  Dropdown,
  Popover,
} from "rsuite";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import LeadDetails from "../Drawers/LeadDetails";
import { sort } from "../../@Utils/Sorting";
import { exportCsv } from "../../@Utils/Export";
import SelectPicker from "rsuite/lib/SelectPicker";
import { formatDistance, format } from "date-fns";

const { HeaderCell, Cell } = Table;

type LeadDetailsProps = {
  buyer_id?: number;
  category_id?: number;
  height?: number;
  default_disposition?: string;
  override_dispositions?: string[];
};

const LeadList: React.FC<LeadDetailsProps> = ({
  buyer_id = 0,
  category_id = 0,
  height = 650,
  default_disposition = "accepted",
  override_dispositions = undefined,
}) => {
  const admediary_context = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [affiliate, setAffiliate] = React.useState();
  const [subid1, setSubId1] = React.useState();
  const [subid2, setSubId2] = React.useState();
  const [buyer, setBuyer] = React.useState();
  const [disposition, setDisposition] = React.useState(default_disposition);
  const intervalRef: any = React.useRef(null);

  const buyers = admediary_context.config.buyers;
  const affiliates = admediary_context.config.affiliates;

  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: category_id ? category_id : admediary_context.category,
    product_id: admediary_context.product,
    affiliate_id: affiliate,
    buyer_id: buyer_id === 0 && buyer !== null ? buyer : buyer_id,
    sub_id_1: subid1,
    sub_id_2: subid2,
    disposition: disposition,
  };

  // const [data, isLoading] = useAdmediaryApi("buyer_transactions", params);
  const [data, isLoading] = useAdmediaryApiManual("buyer_transactions", params);

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          time_distance: formatDistance(item.post_date, new Date()),
          price_received: item.price_received.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        },
      };
    });
  };

  /**
   * List of Lead Status / Dispositions
   * Dispositions can be overwritten through a provided prop
   */
  const dispositions = !override_dispositions
    ? [
        {
          disposition: "",
          label: "All Leads",
        },
        {
          disposition: "accepted",
          label: "Accepted Leads",
        },
        {
          disposition: "rejected",
          label: "Rejected",
        },
        {
          disposition: "filtered",
          label: "Filtered",
        },
        {
          disposition: "affiliate",
          label: "Affiliate",
        },
      ]
    : override_dispositions.map((row) => {
        return { disposition: row, label: row };
      });

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param id
   * @param value
   */
  const debounceSearchTerm = (id: number, value: any) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      if (id === 1) {
        setSubId1(value);
      } else if (id === 2) {
        setSubId2(value);
      }
    }, 750);
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          {buyer_id ? null : (
            <SelectPicker
              size="sm"
              placeholder="Filter by Buyer"
              value={buyer}
              data={buyers}
              onChange={(v) => {
                setBuyer(v);
              }}
              valueKey="buyer_id"
              labelKey="buyer_name"
              style={{ marginRight: 15 }}
            />
          )}
          <SelectPicker
            size="sm"
            placeholder="Filter by Disposition"
            value={disposition}
            data={dispositions}
            onChange={(v) => {
              setDisposition(v);
            }}
            valueKey="disposition"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Affiliate"
            value={affiliate}
            data={affiliates}
            onChange={(v) => {
              setAffiliate(v);
            }}
            valueKey="affiliate_id"
            labelKey="affiliate_name"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 1"
              onChange={(v) => {
                debounceSearchTerm(1, v);
              }}
              size="sm"
            />
          </InputGroup>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Sub ID 2"
              onChange={(v) => {
                debounceSearchTerm(2, v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `leads_report_${format(
                  new Date(admediary_context.start),
                  "MMddyy"
                )}_${format(new Date(admediary_context.end), "MMddyy")}.csv`,
                data
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        height={height}
        // autoHeight={true}
        data={Array.isArray(data) ? sortData(data) : []}
        loading={isLoading === true}
        rowClassName="clickable-data striped-rows"
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      >
        <Table.Column width={125} fixed sortable resizable>
          <Table.HeaderCell>
            <span>ID</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Lead Instance ID</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="lead_instance_id" />
        </Table.Column>

        <Table.Column width={175} align="center" sortable>
          <Table.HeaderCell>
            <span>Post Date</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Date lead posted</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell>
            {(rowData: any) => <span>{rowData.time_distance}</span>}
          </Table.Cell>
        </Table.Column>

        <Table.Column sortable resizable width={175}>
          <Table.HeaderCell>
            <span>Buyer</span>
            <Whisper trigger="hover" speaker={<Tooltip>Buyer's Name</Tooltip>}>
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="buyer_name" />
        </Table.Column>

        <Table.Column sortable resizable width={175}>
          <Table.HeaderCell>
            <span>Contract</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Name of Buyer's Contract</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="contract_name" />
        </Table.Column>

        <Table.Column sortable resizable width={175}>
          <Table.HeaderCell>
            <span>Affiliate</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Name of Affiliate</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="affiliate_name" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>Product</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Name of the Product</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="product_description" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>First Name</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Lead's first name</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="first_name" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>Last Name</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Lead's last name</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="last_name" />
        </Table.Column>

        <Table.Column sortable resizable width={200}>
          <Table.HeaderCell>
            <span>Email</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Email address of customer</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>Phone</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Phone # of customer</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="phone" />
        </Table.Column>

        <Table.Column sortable resizable width={135}>
          <Table.HeaderCell>
            <span>Disposition</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Disposition Status of Lead</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="disposition" />
        </Table.Column>

        <Table.Column sortable resizable width={175}>
          <Table.HeaderCell>
            <span>Reason</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Disposition Reason</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="disposition_detailed" />
        </Table.Column>

        <Table.Column align="right" sortable resizable width={125}>
          <Table.HeaderCell>
            <span>Price</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Revenue generated by lead</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="price_received" />
        </Table.Column>

        <Table.Column align="right" sortable resizable width={135}>
          <Table.HeaderCell>
            <span>IP</span>
            <Whisper
              trigger="hover"
              speaker={
                <Tooltip>
                  IP address of customer's computer at submission
                </Tooltip>
              }
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="client_ip" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>SID1</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Sub ID 1 provided by affiliate</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="sid1" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>SID2</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Sub ID 2 provided by affiliate</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="sid2" />
        </Table.Column>

        <Table.Column sortable resizable width={150}>
          <Table.HeaderCell>
            <span>SID3</span>
            <Whisper
              trigger="hover"
              speaker={<Tooltip>Sub ID 3 provided by affiliate</Tooltip>}
            >
              <Icon icon="info" style={{ marginLeft: 7 }} />
            </Whisper>
          </Table.HeaderCell>
          <Table.Cell dataKey="sid3" />
        </Table.Column>
        <Table.Column width={150} fixed="right">
          <HeaderCell>&nbsp;</HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>
    </>
  );
};

const Menu = ({ onSelect, row, summary }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, summary, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} checked={checked} summary={summary} />
  </Popover>
);

type TableWhisper = {
  row: any;
  summary: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  row.checked = props.row.checked ? props.row.checked : false;
  function handleSelectMenu(eventKey: string, event: any) {
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([["view_leads", <LeadDetails lead={row} />]]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, checked, summary, ...props }: any) => {
  rowData.checked = checked;
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} summary={summary} />
    </Cell>
  );
};

export default React.memo(LeadList);

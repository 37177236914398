import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  IconButton,
  Icon,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import Config from "../../@Config/Forms";
import { FormContext } from "../../@Context/FormContext";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Field from "../Field";
import { parseInt, result } from "lodash";
import EditModal from "../EditModal";
import { FormInstance } from "rsuite/lib/Form/Form";
import moment from "moment";

const { StringType, NumberType, ArrayType, DateType } = Schema.Types;

const model = Schema.Model({
  domain_id: NumberType(),
  domain_name: StringType().isRequired("Domain Name is required."),
  ssl_certificate: ArrayType(),
  renew: ArrayType(),
  expired: ArrayType(),
  parked: ArrayType(),
  registration_date: DateType().isRequired("Entry Date is required"),
  expiration_date: DateType().isRequired("Expiration Date is required"),
  notes: StringType(),
  // account_id: NumberType().isRequired("Please select an account"),
  account_id: NumberType().min(1, "Please select an account"),
  usage: StringType().isRequired("Please select usage"),
});

const multiDomainModel = Schema.Model({
  domains: StringType()
    .isRequired("Domains is required.")
    .maxLength(500, "Max length is 500."),
});

type DomainFormType = {
  itemId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const DomainForm: React.FC<DomainFormType> = ({
  itemId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const dateHolder = moment().format("YYYY-MM-DD");
  const initItem = {
    domain_id: 0,
    account_id: 0,
    ssl_certificate: [],
    expired: [],
    parked: [],
    renew: [],
    registration_date: moment().format("YYYY-MM-DD"),
    expiration_date: moment().add(1, "years"),
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const [formError, setFormError] = React.useState({});
  const [originalDate, setOriginalDate] = React.useState();
  const [originalUsage, setOriginalUsage] = React.useState();

  const params = {
    domain_id: itemId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleInitForm(prepareFormData(Array.isArray(data) ? data[0] : data));
    // handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.domain_id === 0) {
      setData(initItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("common_domain", params);
      setData(results.data);
      setIsLoading(false);
      if (Array.isArray(results.data) && results.data.length) {
        setOriginalDate(results.data[0].expiration_date);
        setOriginalUsage(results.data[0].usage);
      }
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(`${api_base}/api/reports.php?op=domain_registrars`)
      .then((response) => {
        const domainRegistrars = response.data.response_data.data.map(
          (registrar: any) => {
            return {
              ...registrar,
              ...{
                account_id: parseInt(registrar.account_id),
                label: `${registrar.registrar} \\ ${registrar.username}`,
              },
            };
          }
        );

        Forms.setData({
          ...Forms.data,
          ...{
            domainRegistrars,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.ssl_certificate)) {
      record.ssl_certificate = record.ssl_certificate === 1 ? [1] : [];
    }

    if (!Array.isArray(record.renew)) {
      record.renew = record.renew === 1 ? [1] : [];
    }

    if (!Array.isArray(record.expired)) {
      record.expired = record.expired === 1 ? [1] : [];
    }

    if (!Array.isArray(record.parked)) {
      record.parked = record.parked === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    data.account_id = data.account_id ? data.account_id : 0;

    if (
      data.ssl_certificate.length &&
      data.ssl_certificate[0] &&
      data.ssl_certificate[0] === 1
    ) {
      if (params.domain_id === 0) {
        data.expiration_date = moment().add(90, "days");
      }
      data.parked = data.parked === 1 ? [] : [];
      data.usage = "ssl";
    } else {
      if (params.domain_id === 0) {
        data.expiration_date = moment().add(1, "years");
        data.usage = data.usage !== "ssl" ? data.usage : "admin";
      } else {
        data.expiration_date = originalDate;
        data.usage = data.usage !== "ssl" ? data.usage : originalUsage;
      }
    }

    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  const handleInitForm = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  const [show, setShow] = React.useState(false);
  const handleEditModalClose = () => setShow(false);
  const handleEditDomains = () => setShow(true);
  const multiDomainFormRef = React.createRef<FormInstance>();
  const [multiDomainFormValue, setMultiDomainFormValue] = React.useState();

  const handleEnterDomains = () => {
    const node = multiDomainFormRef && multiDomainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Separate domains with commas
    const domains = multiDomainFormValue.domains
      .replace(/ /g, ",")
      .replace(/\n/g, ",")
      .replace(/,\s*$/, "");

    setFormValue({
      ...formValue,
      ...{
        domain_name: domains,
      },
    });
    setShow(false);
  };
  const handleMultiDomainChange = (data: any) => setMultiDomainFormValue(data);

  const usageList = [
    { value: "admin", label: "Admin" },
    { value: "affiliate", label: "Affiliate" },
    { value: "cake_cookie", label: "Cake Cookie" },
    { value: "cake_redirect", label: "Cake Redirect" },
    { value: "corporate", label: "Corporate" },
    { value: "email", label: "Email" },
    { value: "link_shortener", label: "Link Shortener" },
    { value: "product", label: "Product" },
    { value: "ssl", label: "SSL" },
  ];

  const isUsageDisabled =
    !!(formValue.ssl_certificate.length && formValue.ssl_certificate[0]) ||
    Admediary.userDisable;

  const getRegistrarInfo = (id: number) => {
    const registrar = formData.domainRegistrars.find(
      (item: any) => item.account_id === id
    );

    if (!registrar) {
      return <span>Select Account at first</span>;
    }

    const cellStyle = { padding: 10 };

    return (
      <table style={{ textAlign: "left" }}>
        <thead>
          <tr>
            <td colSpan={2} style={cellStyle}>
              Registrar Info
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={cellStyle}>Registrar</td>
            <td style={cellStyle}>{registrar.registrar}</td>
          </tr>
          <tr>
            <td style={cellStyle}>UserName</td>
            <td style={cellStyle}>{registrar.username}</td>
          </tr>
          <tr>
            <td style={cellStyle}>Password</td>
            <td style={cellStyle}>{registrar.password}</td>
          </tr>
          <tr>
            <td style={cellStyle}>Comment</td>
            <td style={cellStyle}>{registrar.comment}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  if (isLoading) {
    return <Loader center size="lg" content="Loading..." />;
  }
  return (
    <>
      <Form
        ref={formRef}
        onChange={handleChange}
        onCheck={setFormError}
        model={model}
        layout="horizontal"
        formValue={formValue}
        formError={formError}
      >
        <Field
          label="Domain Name"
          name="domain_name"
          postfix={
            formValue.domain_id === 0 ? (
              <IconButton
                icon={<Icon icon={"plus"} onClick={handleEditDomains} />}
              />
            ) : null
          }
          postfixStyles={{ marginTop: 0 }}
          disabled={Admediary.userDisable}
        />
        <Field
          label="SSL Certificate"
          name="ssl_certificate"
          accepter={CheckboxGroup}
        >
          <Checkbox value={1} disabled={itemId > 0 || Admediary.userDisable} />
        </Field>
        <Field
          label="Entry Date"
          name="registration_date"
          placeholder="Select Date"
          accepter={DatePicker}
          placement="autoVerticalStart"
          cleanable={false}
          oneTap
          disabled={Admediary.userDisable}
        />
        <Field
          label="Expiration Date"
          name="expiration_date"
          placeholder="Select Date"
          accepter={DatePicker}
          placement="autoVerticalStart"
          cleanable={false}
          oneTap
          disabled={Admediary.userDisable}
        />
        <Field label="Renew" name="renew" accepter={CheckboxGroup}>
          <Checkbox value={1} disabled={Admediary.userDisable} />
        </Field>
        <Field label="Expired" name="expired" accepter={CheckboxGroup}>
          <Checkbox value={1} disabled={Admediary.userDisable} />
        </Field>
        <Field label="Parked" name="parked" accepter={CheckboxGroup}>
          <Checkbox
            value={1}
            disabled={Admediary.userDisable || isUsageDisabled}
          />
        </Field>
        <Field label="Notes" name="notes" disabled={Admediary.userDisable} />
        <Field
          label="Account"
          name="account_id"
          accepter={SelectPicker}
          placeholder="Select Account"
          placement="autoVerticalStart"
          data={formData.domainRegistrars}
          valueKey="account_id"
          message={getRegistrarInfo(formValue.account_id)}
          required
          disabled={Admediary.userDisable}
        />
        <Field
          label="Usage"
          name="usage"
          accepter={SelectPicker}
          placeholder="Select Usage"
          placement="autoVerticalStart"
          data={usageList}
          disabled={isUsageDisabled}
        />
      </Form>

      <EditModal
        title="Domains"
        open={show}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleEnterDomains}
      >
        <Form
          ref={multiDomainFormRef}
          onChange={handleMultiDomainChange}
          onCheck={setFormError}
          model={multiDomainModel}
          fluid={true}
          formValue={multiDomainFormValue}
        >
          <ControlLabel>Add Domains</ControlLabel>
          <HelpBlock tooltip style={{ marginTop: 0 }}>
            Enter next new domain on a new line or split domains with a space '
            '
          </HelpBlock>
          <FormControl rows={12} name="domains" componentClass="textarea" />
        </Form>
      </EditModal>
    </>
  );
};

export default DomainForm;

import React from "react";
import {
  Col,
  Content,
  Grid,
  Row,
  Loader,
  FlexboxGrid,
  Whisper,
  Icon,
  Tooltip,
} from "rsuite";
import Dashboard from "../../@Components/Dashboard";
import LeadPrimaryLeadStatCards from "../../@Components/Visualization/LeadPrimaryLeadStatCards";
import TopFilteredLeadReasons from "../../@Components/Visualization/TopFilteredLeadReasons";
import FilteredVsRejectedPieGraph from "../../@Components/Visualization/FilteredVsRejectedPieGraph";
import FilteredLeadDetail from "../../@Components/Leads/Report/FilteredLeadDetail";
import FullDispositionFilterList from "../../@Components/Drawers/FullDispositionFilterList";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Title from "../../@Components/Title";

const FilteredLeads: React.FC = () => {
  const admediary_context = React.useContext(AdmediaryContext);
  const [filters, setFilters] = React.useState<string[] | undefined>();
  const [filterReasons, setFilterReasons] = React.useState<any>();
  const [filtered, setFiltered] = React.useState<number | undefined>();
  const [rejected, setRejected] = React.useState<number | undefined>();

  /**
   * Capture data coming from Top Stat Card component
   * @param data
   */
  const captureStatCardData = React.useCallback(
    (data: any) => {
      if (data.leads_filtered !== null && data.leads_rejected !== null) {
        setFiltered(data.leads_filtered);
        setRejected(data.leads_rejected);
      }
    },
    [setFiltered, setRejected]
  );

  /**
   * Capture data coming from filtered lead reasons
   */
  const captureTopFilteredReasons = React.useCallback(
    (data: any) => {
      if (Array.isArray(data.aggregated)) {
        setFilters(data.aggregated);
      }
      if (Array.isArray(data.reasons)) {
        setFilterReasons(data.reasons);
      }
    },
    [setFilters, setFilterReasons]
  );

  /**
   * Handle Reason Detail Drawer
   */
  const openFullReasonReport = () => {
    admediary_context.openDrawer(<FullDispositionFilterList data={filters} />);
  };

  return (
    <Dashboard>
      <LeadPrimaryLeadStatCards success={captureStatCardData} />
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24} sm={16}>
            <Content>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={16}>
                  <h5>
                    Top Filtered Reasons
                    <Whisper
                      trigger="hover"
                      speaker={
                        <Tooltip>
                          Top leads filtered that were not sold or rejected
                        </Tooltip>
                      }
                    >
                      <Icon icon="info" style={{ marginLeft: 7 }} />
                    </Whisper>
                  </h5>
                </FlexboxGrid.Item>
                {!filterReasons ? null : (
                  <FlexboxGrid.Item
                    colspan={8}
                    style={{ textAlign: "right", paddingRight: 10 }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        openFullReasonReport();
                      }}
                    >
                      View All
                    </button>
                  </FlexboxGrid.Item>
                )}
              </FlexboxGrid>
              <div style={{ height: 295 }}>
                <TopFilteredLeadReasons success={captureTopFilteredReasons} />
              </div>
            </Content>
          </Col>
          <Col className="content-card chart" xs={24} sm={8}>
            <Content>
              <h5>Filtered vs Rejected</h5>
              <div style={{ height: 295 }}>
                <FilteredVsRejectedPieGraph
                  rejected={rejected}
                  filtered={filtered}
                />
              </div>
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24} sm={24}>
            <Content style={{ height: "100%", minHeight: 400 }}>
              <Title title="Leads" />
              {!filters ? (
                <Loader size="md" speed="slow" vertical center />
              ) : (
                <FilteredLeadDetail
                  height={750}
                  overrideReasons={filterReasons}
                />
              )}
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};
export default FilteredLeads;

import React from "react";
import { Button, Modal, Popover, Table, Whisper } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { NumberCell } from "../Table";

const { Column, HeaderCell, Cell } = Table;

type RecordDetailProps = {
  recordId?: any;
  dataSourcedId?: any;
  serviceId?: number;
  title?: string;
  open?: boolean;
  onClose?: () => void;
  onYes?: () => void;
};

const RecordDetail: React.FC<RecordDetailProps> = ({
  recordId = 0,
  dataSourcedId = 0,
  serviceId = 0,
  title = "",
  open = false,
  onClose,
  onYes,
  ...props
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const params = {
    record_id: recordId,
    service_id: serviceId,
    start_date: Admediary.start,
    end_date: Admediary.end,
  };
  const [data, isLoading] = useAdmediaryApi("record_detail", params);

  return (
    <Modal full show={open} onHide={onClose} {...props} size={"lg"}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={Array.isArray(data) ? data : []}
          hover
          rowClassName="clickable-data striped-rows"
          affixHorizontalScrollbar
        >
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Result ID</HeaderCell>
            <NumberCell dataKey="result_id" />
          </Column>
          <Column flexGrow={4} resizable>
            <HeaderCell>History</HeaderCell>
            <CountsCell dataKey="history" />
          </Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onYes} appearance="primary">
          Yes
        </Button>
        <Button onClick={onClose} appearance="subtle">
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const CountsCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  group,
  index,
  label,
  ...props
}: any) => {
  const triggerRef = React.createRef();

  return (
    <Cell {...props}>
      <Whisper
        triggerRef={triggerRef}
        controlId="control-id-focus"
        trigger={"click"}
        enterable={true}
        placement="bottomStart"
        speaker={<MenuPopover row={rowData}>{rowData[dataKey]}</MenuPopover>}
      >
        <span>{rowData[dataKey]}</span>
      </Whisper>
    </Cell>
  );
};

const MenuPopover = ({ onSelect, row, group, label, ...rest }: any) => (
  <Popover {...rest} full>
    <List row={row} label={label} />
  </Popover>
);

const List = ({ row }: any) => {
  const rows = JSON.parse(row.history);
  const tableData: any = [];
  rows.forEach(function (value: any) {
    tableData.push({
      timestamp: value.timestamp,
      success: value.success,
      duplicate: value.duplicate,
      request: value.request,
      response: value.response,
      status_code: value.status_code,
      duration: value.duration,
    });
  });

  const tableRows = tableData.map((row: any, index: number) => (
    <ul key={index} style={{ listStyle: "none" }}>
      <li>
        <span>
          <strong>timestamp :</strong>{" "}
        </span>{" "}
        {row.timestamp}
      </li>
      <li>
        <span>
          <strong>success :</strong>
        </span>{" "}
        {row.success}
      </li>
      <li>
        <span>
          <strong>duplicate :</strong>{" "}
        </span>{" "}
        {row.duplicate}
      </li>
      <li>
        <span>
          <strong>request :</strong>{" "}
        </span>{" "}
        {row.request}
      </li>
      <li>
        <span>
          <strong>response :</strong>{" "}
        </span>{" "}
        {row.response}
      </li>
      <li>
        <span>
          <strong>status_code :</strong>{" "}
        </span>{" "}
        {row.status_code}
      </li>
      <li>
        <span>
          <strong>duration :</strong>{" "}
        </span>{" "}
        {row.duration}
      </li>
    </ul>
  ));

  return (
    <table>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
export default RecordDetail;

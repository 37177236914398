import React from "react";
import {
  Loader,
  Schema,
  Form,
  DatePicker,
  CheckboxGroup,
  Checkbox,
} from "rsuite";
import Field from "../Field";

const { NumberType, DateType, ArrayType } = Schema.Types;

const model = Schema.Model({
  service_id: NumberType().isRequired("Contract ID is required."), // Hidden
  day_of_week: ArrayType().isRequired("Select Day of Week please."),
  start_time: DateType().isRequired("Start Time is required."),
  end_time: DateType().isRequired("End Time is required."),
});

type Schedule24x7FormType = {
  serviceId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const Schedule24x7Form: React.FC<Schedule24x7FormType> = ({
  serviceId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading] = React.useState(false);
  const [formValue, setFormValue] = React.useState(
    getDefaultSchedule24x6FormData(serviceId)
  );
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label=""
        name="day_of_week"
        placeholder="Select Day of Week"
        accepter={CheckboxGroup}
        placement="autoVerticalEnd"
      >
        <Checkbox value="mo">Monday</Checkbox>
        <Checkbox value="tu">Tuesday</Checkbox>
        <Checkbox value="we">Wednesday</Checkbox>
        <Checkbox value="th">Thursday</Checkbox>
        <Checkbox value="fr">Friday</Checkbox>
        <Checkbox value="sa">Saturday</Checkbox>
        <Checkbox value="su">Sunday</Checkbox>
      </Field>
      <Field
        label="Start Time"
        name="start_time"
        accepter={DatePicker}
        placement="autoVerticalEnd"
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
      <Field
        label="End Time"
        name="end_time"
        accepter={DatePicker}
        placement="autoVerticalEnd"
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0 && minute !== 59}
        cleanable={false}
      />
    </Form>
  );
};

export const getDefaultSchedule24x6FormData = (serviceId: number) => {
  const startDate = new Date();
  startDate.setHours(0);
  startDate.setMinutes(0);

  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);

  return {
    service_id: serviceId,
    day_of_week: ["mo", "tu", "we", "th", "fr", "sa", "su"], // All days
    start_time: startDate,
    end_time: endDate,
  };
};

export default Schedule24x7Form;

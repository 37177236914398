import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HeaderSummary, CurrencyCell, NumberCell, ParentCell } from "../Table";
import { currency } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";
import ViewBuyHoldLeads from "../Drawers/ViewBuyHoldLeads";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyHoldTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyHoldTreeTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  group: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const BuyHoldTreeTable: React.FC<BuyHoldTreeTableProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const group = props.group;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={65}
      loading={isLoading === true}
      data={sortData(data)}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={300} fixed sortable resizable>
        <HeaderCell>Product/BH Contract/Date</HeaderCell>
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Total Leads" summary={summary.total_leads} />
        </HeaderCell>
        <NumberCell dataKey="total_leads" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Unposted Leads"
            summary={summary.unposted_leads}
          />
        </HeaderCell>
        <NumberCell dataKey="unposted_leads" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Posted Leads" summary={summary.posted_leads} />
        </HeaderCell>
        <NumberCell dataKey="posted_leads" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Sold Leads" summary={summary.sold_leads} />
        </HeaderCell>
        <NumberCell dataKey="sold_leads" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Unsold Leads" summary={summary.unsold_leads} />
        </HeaderCell>
        <NumberCell dataKey="unsold_leads" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Revenue" summary={currency(summary.revenue)} />
        </HeaderCell>
        <CurrencyCell dataKey="revenue" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Cost" summary={currency(summary.cost)} />
        </HeaderCell>
        <CurrencyCell dataKey="cost" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="P&L" summary={currency(summary.pnl)} />
        </HeaderCell>
        <CurrencyCell dataKey="pnl" />
      </Column>

      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell groupBy={group} />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
  groupBy: string;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const groupBy = props.groupBy;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["view_leads", <ViewBuyHoldLeads row_data={row} group={groupBy} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, groupBy, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} groupBy={groupBy} />
    </Cell>
  );
};

export default BuyHoldTreeTable;

import React from "react";
import { Col, Grid, Row } from "rsuite";
import StatCard from "./StatCard";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

interface ICallBack {
  (data: PrimaryStatCardsCallBackObject): void;
}

type PrimaryStatCardsCallBackObject = {
  revenue: number | null;
  cost: number | null;
  profit: number | null;
  leads_sold: number | null;
  clicks: number | null;
};

type PrimaryStatCardsProps = {
  success?: ICallBack;
};

const PrimaryStatCards: React.FC<PrimaryStatCardsProps> = ({
  success = (data: any) => {
    /* do nothing */
  },
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  let Revenue: any = undefined,
    Cost: any = undefined,
    Profit: any = undefined,
    LeadsSold: any = undefined,
    Clicks: any = undefined;
  const [clicks, clicks_loading] = useAdmediaryApi("clicks", {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    category_id: Admediary.category,
    buyer_id: Admediary.buyer,
  });
  if (clicks_loading === false) {
    /**
     * Make sure we have a response for clicks (array returned)
     */
    if (Array.isArray(clicks) && clicks.length > 0) {
      // Clicks = clicks[0].clicks.toLocaleString();
      Clicks = clicks[0].clicks.toString();
    }
  }

  // const [leads_purchased, leads_purchased_loading] = useAdmediaryApi(
  //   "buyer_contract_counts",
  //   {
  //     start_date: Admediary.start,
  //     end_date: Admediary.end,
  //     product_id: Admediary.product,
  //     category_id: Admediary.category,
  //     buyer_id: Admediary.buyer,
  //     group: "",
  //   }
  // );

  const [leads_purchased, leads_purchased_loading] = useAdmediaryApi(
    "buyer_contract_counts",
    {
      start_date: Admediary.start,
      end_date: Admediary.end,
      category_id: Admediary.category,
      product_id: Admediary.product,
      affiliate_id: 0,
      buyer_id: Admediary.buyer,
      group: "",
    }
  );
  if (leads_purchased_loading === false) {
    /**
     * Make sure we have a response for leads_purchased
     */
    if (Array.isArray(leads_purchased) && leads_purchased.length > 0) {
      const overview = leads_purchased[0];
      LeadsSold = overview.leads_sold;
      Revenue = overview.price_received ? overview.price_received : 0;
      Cost = overview.price_owed ? overview.price_owed : undefined;
      Profit = overview.price_received - overview.price_owed;
    }
  }

  /**
   * Using useEffect to fire callback after component is done
   */
  React.useEffect(() => {
    if (clicks_loading === false && leads_purchased_loading === false) {
      success({
        revenue: Revenue || null,
        cost: Cost || null,
        profit: Profit || null,
        leads_sold: LeadsSold || null,
        clicks: Clicks || null,
      });
    }
  }, [
    success,
    clicks_loading,
    leads_purchased_loading,
    LeadsSold,
    Revenue,
    Cost,
    Profit,
    Clicks,
  ]);

  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Revenue" type="currency" value={Revenue} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Profit" type="currency" value={Profit} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Leads Sold" type="number" value={LeadsSold} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Total Clicks" type="number" value={Clicks} />
        </Col>
      </Row>
    </Grid>
  );
};

export default PrimaryStatCards;

import React from "react";
import { FlexboxGrid, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import SelectPicker from "rsuite/lib/SelectPicker";
import BuyerContractCloneList from "./BuyerContractCloneList";

type BuyerContractCloneType = {
  refreshData?: () => void;
};

const BuyerContractClone: React.FC<BuyerContractCloneType> = ({
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultActiveValue = 1;
  const [active, setActive] = React.useState(defaultActiveValue);
  const activeList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const [categoryId, setCategoryId] = React.useState(Admediary.category);
  // const category = Admediary.category ? Admediary.category : "ALL";
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  const params = {
    category_id: categoryId,
    buyer_id: Admediary.buyer,
    active,
    include_sources: 1,
  };

  const [data, isLoading] = useAdmediaryApi(
    "products_routing_contracts",
    params
  );

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */

  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = [
      "contract_id",
      "buyer_name",
      "category_name",
      "contract_name",
      "routing_tag",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "contract_id") {
          return include(item[field].toString()); // convert contract_id to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={false}
            defaultValue={defaultActiveValue}
            value={active}
            data={activeList}
            onChange={(v) => {
              setActive(v);
            }}
            valueKey="value"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerContractCloneList
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refreshData}
      />
    </>
  );
};
export default BuyerContractClone;

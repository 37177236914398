import React, { useState } from "react";
import { Query, ApiOperations, ApiParameters } from "../@Utils/AdmediaryApi";
import { AdmediaryContext } from "../@Context/AdmediaryContext";

/**
 * React Hook operation as a wrapper for making API requests
 *
 * @param operation
 * @param options
 */
const useAdmediaryApiManual = (
  operation: keyof typeof ApiOperations,
  options?: ApiParameters
) => {
  // ---- State
  const [data, setData] = React.useState<any | any[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  // ---- API
  const state: string = JSON.stringify({ operation, options });
  const Admediary = React.useContext(AdmediaryContext);

  // Fire request to the API
  React.useEffect(() => {
    if (Admediary.runReport === true) {
      (async () => {
        setError(null);
        try {
          setIsLoading(true);
          const results: any = await Query(operation, options);
          setData(results.data);
          if (results.hasOwnProperty("config")) {
            setConfig(results.config);
          }
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
          Admediary.setRun(false);
        }
      })();
    }
  }, [Admediary.runReport]);

  return [data, isLoading, error, config];
};

export default useAdmediaryApiManual;

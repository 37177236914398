import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  InputNumber,
  Schema,
} from "rsuite";
import Field from "../Field";
import SelectPicker from "rsuite/lib/SelectPicker";

const { ArrayType, NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  filter_id: NumberType(),
  include: ArrayType(),
  filter_type: StringType().isRequired("Filter Type is required."),
  filter_value: StringType(),
  evaluator: StringType(),
});

type FilterFormType = {
  id?: number;
  data?: object;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const FilterForm: React.FC<FilterFormType> = ({
  id = 0,
  data = {},
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.include)) {
      record.include =
        record.include === 1 || record.include === "1" ? [1] : [];
    }

    // Dynamic fields
    if (
      !Array.isArray(record.filter_values) &&
      record.filter_type === "daily_cap_queue"
    ) {
      const parts = record.filter_value.split(" ");
      record.filter_value_start = parseInt(parts[0], 10) || "";
      record.filter_value_end = parseInt(parts[2], 10) || "";
      record.filter_values = [
        record.filter_value_start,
        record.filter_value_end,
      ];
      record.filter_delimiter = parts[1] || "and";
    }

    return record;
  };

  const [formValue, setFormValue] = React.useState({
    ...prepareFormData(data),
    ...{ filter_id: id },
  });
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    const type = data.filter_type;

    switch (type) {
      case "percentage":
        data.operator = ">";
        data.include = [];
        break;

      case "daily_cap":
        data.operator = "<=";
        data.include = [];
        break;

      case "daily_cap_queue":
        data.operator = "between";
        data.include = [];
        data.filter_values = [data.filter_value_start, data.filter_value_end];
        data.filter_value = data.filter_values.join(
          ` ${data.filter_delimiter} `
        );
        break;

      case "sql":
        data.include = true;
        data.include = [1];
        data.operator = "Evaluate";
        data.filter_value = "";
        data.filter_values = [];
        break;

      case "domain_mx":
      case "domain_name":
      case "source_url":
        data.operator = operatorData
          .map((item: any) => item.value)
          .includes(data.operator)
          ? data.operator
          : "";
        break;
    }

    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Include" name="include" accepter={CheckboxGroup}>
        <Checkbox
          value={1}
          disabled={[
            "percentage",
            "daily_cap",
            "daily_cap_queue",
            "sql",
          ].includes(formValue.filter_type)}
        />
      </Field>
      <Field
        label="Filter Type"
        name="filter_type"
        placeholder="Select Filter Type"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "percentage", label: "Percentage" },
          { value: "daily_cap", label: "Daily Cap" },
          { value: "daily_cap_queue", label: "Daily Cap/Queue" },
          { value: "domain_mx", label: "Domain MX" },
          { value: "domain_name", label: "Domain Name" },
          { value: "source_url", label: "Source URL" },
          { value: "sql", label: "SQL" },
        ]}
      />
      <FormGroup inline>
        <ControlLabel>Operator</ControlLabel>
        <OperatorField
          value={formValue.operator}
          filterType={formValue.filter_type}
        />
      </FormGroup>
      {formValue.filter_type !== "sql" && (
        <FormGroup inline>
          <ControlLabel>Filter Value</ControlLabel>
          <FilterValueField
            value={formValue.filter_value}
            filterType={formValue.filter_type}
            start={formValue.filter_value_start}
            end={formValue.filter_value_start}
            delimiter={formValue.filter_delimiter}
          />
        </FormGroup>
      )}
      {formValue.filter_type === "sql" && (
        <Field
          label="Evaluator"
          rows={8}
          name="evaluator"
          componentClass="textarea"
        />
      )}
    </Form>
  );
};

const operatorData = [
  { value: "equal_to", label: "Equal To" },
  { value: "not_equal_to", label: "Not Equal To" },
  { value: "contains", label: "Contains" },
  { value: "does_not_contain", label: "Does Not Contain" },
];

const OperatorField = ({ value, filterType }: any) => {
  let component = <></>;

  value = !value && filterType === "sql" ? "Evaluate" : value;

  switch (filterType) {
    case "percentage":
    case "daily_cap":
    case "daily_cap_queue":
    case "sql":
      component = (
        <span style={{ paddingTop: 8, display: "inline-block" }}>{value}</span>
      );
      break;

    case "domain_mx":
    case "domain_name":
    case "source_url":
      component = (
        <FormControl
          label="Operator"
          name="operator"
          placeholder="Select Operator"
          accepter={SelectPicker}
          searchable={false}
          cleanable={false}
          data={operatorData}
        />
      );
  }

  return component;
};

const FilterValueField = ({ filterType, delimiter = "and" }: any) => {
  // Default is text field
  let component = (
    <FormControl
      label="Filter Value"
      name="filter_value"
      placeholder="Enter Filter Value"
    />
  );

  switch (filterType) {
    case "percentage":
      component = (
        <FormControl
          label="Filter Value"
          name="filter_value"
          placeholder="Enter Filter Value"
          accepter={InputNumber}
          min={0}
          // max={10}
        />
      );
      break;

    case "daily_cap_queue":
      component = (
        <InputGroup style={{ width: 300 }}>
          <FormControl name="filter_value_start" />
          <InputGroup.Addon>{delimiter}</InputGroup.Addon>
          <FormControl name="filter_value_end" />
        </InputGroup>
      );
      break;

    // case "daily_cap":
    // case "sql":
    // case "domain_mx":
    // case "domain_name":
    // case "source_url":
    // default:
  }

  return component;
};

export default FilterForm;

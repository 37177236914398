import React, { SyntheticEvent, useRef } from "react";
import { Table, Button } from "rsuite";
import { CheckCell } from "../Table";

import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import ConfirmModal from "../ConfirmModal";
import Service from "../Drawers/Service";

const { Column, HeaderCell, Cell } = Table;

type DataServiceCloneListProps = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
  handleClose?: () => void;
  refreshData?: () => void;
};

const DataServiceCloneList: React.FC<DataServiceCloneListProps> = ({
  data = [],
  isLoading = false,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
  handleClose = () => {},
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const openConfirmModal = () => setOpen(true);
  const closeConfirmModal = () => setOpen(false);

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return sort(list, sortColumn, sortType);
    }
    return list;
  };

  const handleCloneClick = (rowData: any) => {
    setSelectedItem(rowData);
    openConfirmModal();
  };

  const handleCloneService = () => {
    fetchCloneServiceData();
  };

  const fetchCloneServiceData = async () => {
    try {
      const results: any = await Query("data_service", {
        service_id: selectedItem.service_id,
      });

      if (results && Array.isArray(results.data)) {
        const service = results.data[0];

        const newServiceCloneData = {
          ...service,
          cloned_service_id: service.service_id,
          service_id: 0,
          // remove old service and set filter_id/schedule_id by default value (0)
          filters: service.filters.map(({ service_id, ...filter }: any) => ({
            ...filter,
            filter_id: 0,
          })),
          time_filters: service.time_filters.map(
            ({ service_id, ...filter }: any) => ({ ...filter, schedule_id: 0 })
          ),
        };

        Admediary.openDrawer(
          <Service
            serviceId={0}
            cloneData={newServiceCloneData}
            refreshData={refreshData}
          />,
          "lg"
        );
        closeConfirmModal();
        handleClose();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <Table
        virtualized
        height={height}
        loading={isLoading === true}
        data={Array.isArray(data) ? sortData(data) : []}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column sortable resizable>
          <HeaderCell>Service ID</HeaderCell>
          <Cell dataKey="service_id" />
        </Column>
        <Column sortable resizable>
          <HeaderCell>Data Source ID</HeaderCell>
          <Cell dataKey="data_source_id" />
        </Column>
        <Column sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Service Provider</HeaderCell>
          <Cell dataKey="company_name" />
        </Column>
        <Column width={190} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Data Provider</HeaderCell>
          <Cell dataKey="data_provider" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Data Source</HeaderCell>
          <Cell dataKey="data_source" />
        </Column>
        <Column width={200} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <ActionCell onCloneClick={handleCloneClick} />
        </Column>
      </Table>

      <ConfirmModal
        title="Clone Service"
        open={open}
        onClose={closeConfirmModal}
        onYes={handleCloneService}
      >
        Are you sure you want to clone this service?
      </ConfirmModal>
    </>
  );
};

const ActionCell = ({ rowData, onCloneClick, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <Button onClick={(e) => onCloneClick(rowData, e)}>Clone</Button>
    </Cell>
  );
};

export default DataServiceCloneList;

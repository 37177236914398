import React from "react";
import { FlexboxGrid, Input, InputGroup } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import DataServiceCloneList from "./DataServiceCloneList";

type DataServiceCloneProps = {
  closeModal?: any;
  refreshData?: () => void;
};

const DataServiceClone: React.FC<DataServiceCloneProps> = ({
  closeModal = () => {},
  refreshData,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  const params = {
    service_id: 0,
    active: 2,
  };

  const [data, isLoading] = useAdmediaryApi("data_service_list", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */

  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = [
      "service_id",
      "data_source_id",
      "company_name",
      "description",
      "data_provider",
      "data_source",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "service_id" || field === "data_source_id") {
          return include(item[field].toString()); // convert service to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <DataServiceCloneList
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        handleClose={handleClose}
        refreshData={refreshData}
      />
    </>
  );
};

export default DataServiceClone;

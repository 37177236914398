import React from "react";
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormGroup,
  Icon,
  IconButton,
  Popover,
  Table,
  Whisper,
  DatePicker,
  Modal,
  Notification,
  FormControl,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormContext } from "../../@Context/FormContext";
import EditModal from "../EditModal";
import PingTreesRoutingSchedule24X7Form from "./PingTreesRoutingSchedule24X7Form";
import SelectPicker from "rsuite/lib/SelectPicker";
import { FormInstance } from "rsuite/lib/Form/Form";
import { format } from "date-fns";
import _ from "lodash";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

type BuyerRoutingScheduleListProps = {
  routingId: number;
  height?: number;
};

const PingTreeRoutingScheduleList: React.FC<BuyerRoutingScheduleListProps> = ({
  routingId = 0,
  height = 650,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [show, setShow] = React.useState(false);
  const [operation, setOperation] = React.useState("add");
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const schedule24x7FormRef = React.createRef<FormInstance>();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [schedule24x7FormValue, setSchedule24x7FormValue] = React.useState({});
  const [updateCall, setUpdateCall] = React.useState(false);
  const [schedule24x7ModalOpen, setSchedule24x7ModalOpen] = React.useState(
    false
  );

  const handleSchedule24x7ModalOpen = () => setSchedule24x7ModalOpen(true);
  const handleSchedule24x7ModalClose = () => setSchedule24x7ModalOpen(false);
  const handleSchedule24x7Callback = (formValue: any) => {
    setSchedule24x7FormValue(formValue);
  };
  const idKey = "schedule_id";
  const handleSchedule24x7Click = () => {
    setSelectedItem({
      routing_set_id: routingId,
    });

    handleSchedule24x7ModalOpen();
  };
  const handleRemoveClose = () => setShow(false);
  const handleRemoveShow = (rowData: any) => {
    Forms.setSchedule(rowData.schedule_id);
    setShow(true);
    setOperation("remove");
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };
  const handleSchedule24x7ModalSave = () => {
    const node = schedule24x7FormRef && schedule24x7FormRef.current;
    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(schedule24x7FormValue);
    // Transform Date instance to an hours+minutes string
    data.start_time = format(data.start_time, "HH:mm");
    data.end_time = format(data.end_time, "HH:mm");

    (async () => {
      try {
        await Query("ping_trees_routing_schedule24x7", data);
        Notification.success({
          title: "Success",
          description: "Ping Tree Schedule 24x7 has been updated",
        });
        fetchData();

        handleSchedule24x7ModalClose();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };
  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };
  const days = [
    { abbreviation: "mo", day: "Monday" },
    { abbreviation: "tu", day: "Tuesday" },
    { abbreviation: "we", day: "Wednesday" },
    { abbreviation: "we", day: "Wednesday" },
    { abbreviation: "th", day: "Thursday" },
    { abbreviation: "fr", day: "Friday" },
    { abbreviation: "sa", day: "Saturday" },
    { abbreviation: "su", day: "Sunday" },
  ];

  const params = {
    routing_set_id: routingId,
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("routing_set_schedule_select", params);
      Forms.setSchedules(results.data);
      Forms.setLock(true);
      setData(results.data);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Day = (index: any, key: any, value: any) => {
    const nextData = Object.assign([], data);
    Forms.setDay(value);
    //@ts-ignore
    nextData[index].day_of_week = value;
    setData(nextData);
  };

  const handleAddShow = () => {
    setShow(true);
    setOperation("add");
    Forms.setRoute(routingId);
    Forms.setSchedule(0);
    Forms.setDay("Monday");
    Forms.setStartTime(new Date("2017-12-12 00:00:00"));
    Forms.setEndTime(new Date("2021-12-12 23:59:00"));
  };

  const saveItem = (data: any) => {
    // Transform Date instance to an hours+minutes string
    data.start_time =
      typeof data.start_time == "string"
        ? data.start_time
        : format(data.start_time, "HH:mm");
    data.end_time =
      typeof data.end_time == "string"
        ? data.end_time
        : format(data.end_time, "HH:mm");

    Query("routing_set_schedule_update", data)
      .then((response) => {
        console.log("Update");
        Notification.success({
          title: "Success",
          description: "Ping Tree Schedule has been updated",
        });

        // handleEditModalClose();
        fetchData();
      })
      .catch((error) => {
        console.log("error", error);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: error,
        });
      });
  };

  const handleSaveActionClick = (rowData: any) => {
    setSelectedItem(null);
    console.log("Enterave");
    const currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );
    if (currentItem) {
      saveItem(currentItem);
    }

    changeActiveItemStatus(rowData, null);
  };

  const addSchedule = () => {
    setIsLoading(true);
    setShow(false);
    const startHours =
      parseInt(Forms.startTime.getHours()) < 9
        ? "0" + Forms.startTime.getHours()
        : Forms.startTime.getHours();
    const startMinutes =
      parseInt(Forms.startTime.getMinutes()) < 9
        ? "0" + Forms.startTime.getMinutes()
        : Forms.startTime.getMinutes();
    const endHours =
      parseInt(Forms.endTime.getHours()) < 9
        ? "0" + Forms.endTime.getHours()
        : Forms.endTime.getHours();
    const endMinutes =
      parseInt(Forms.endTime.getMinutes()) < 9
        ? "0" + Forms.endTime.getMinutes()
        : Forms.endTime.getMinutes();
    const data = {
      schedule_id: 0,
      routing_set_id: Forms.route,
      day_of_week: Forms.day,
      end_time: endHours + ":" + endMinutes,
      start_time: startHours + ":" + startMinutes,
    };

    (async () => {
      try {
        await Query("routing_set_schedule_update", data);
        fetchData();
      } catch (e) {
        // setIs
      } finally {
      }
    })();
    Forms.setClose(true);
    Forms.setLock(false);
  };

  const handleEditAction = (rowData: any, triggerRef: any) => {
    setSelectedItem(rowData);
    // triggerRef.current.state.isOverlayShown === true
    //   ? Forms.setLock(false)
    //   : Forms.setLock(true);
    console.log("Enter handle edit");
    setOperation("edit");
    Forms.setClose(false);
    Forms.setSchedule(rowData.schedule_id);
    Forms.setTemporaryDay(rowData.day_of_week);
    Forms.setStartTime(rowData.start_time);
    Forms.setEndTime(rowData.end_time);
    changeActiveItemStatus(rowData, EDIT_STATUS);
    // @ts-ignore
    // triggerRef.current.state.isOverlayShown
    //   ? triggerRef.current.close()
    //   : triggerRef.current.open();
  };

  const changeActiveItemStatus = (rowData: any, status: string | null) => {
    const nextData = Object.assign([], data);
    const activeItem: any = nextData.find(
      (item) => item[idKey] === rowData[idKey]
    );
    if (activeItem) {
      activeItem.status = status;
    }

    setData(nextData);
  };
  const EDIT_STATUS = "EDIT";

  const handleCloseActionClick = (rowData: any) => {
    setSelectedItem(null);

    // Replace changed data by original ones
    const index = changedData.findIndex(
      (item) => item[idKey] === rowData[idKey]
    );
    if (index > -1) {
      changedData[index] = { ...rowData };
      setChangedData(changedData);
    }

    changeActiveItemStatus(rowData, null);
  };

  const deleteRouteAction = () => {
    setShow(false);
    setIsLoading(true);
    const params = {
      schedule_id: Forms.schedule,
    };

    (async () => {
      try {
        await Query("routing_set_schedule_remove", params);
        fetchData();
      } catch (e) {
      } finally {
      }
    })();
  };

  const setStartTime = (data: any) => {
    Forms.setStartTime(data);
  };
  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };
  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }
    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["day_of_week"];
    Forms.setSearch(search);
    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  const initChangedData: any[] = [];
  const [changedData, setChangedData] = React.useState(initChangedData);
  const handleChange = (rowData: any, key: string, value: any) => {
    let currentItem: any = changedData.find(
      (item) => item[idKey] === rowData[idKey]
    );

    if (!currentItem) {
      currentItem = data.find((item) => item[idKey] === rowData[idKey]);
      currentItem = { ...currentItem };
      changedData.push(currentItem);
    }

    if (currentItem) {
      currentItem[key] = value;
    }

    setChangedData(changedData);
  };

  return (
    <FlexboxGrid.Item justify="space-between" style={{ marginBottom: 25 }}>
      <hr />
      <FlexboxGrid.Item>
        <Form layout="inline">
          <FormGroup>
            <ControlLabel>Search</ControlLabel>
            <FormControl
              style={{ width: 220 }}
              name="search"
              type="search"
              placeholder="Search"
              onChange={(v: any) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </FormGroup>
          {/*<Button*/}
          {/*  appearance="ghost"*/}
          {/*  onClick={handleAddShow}*/}
          {/*  style={{ padding: 2, paddingRight: 5 }}*/}
          {/*>*/}
          <IconButton
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            disabled={Admediary.userDisable}
            appearance="ghost"
            onClick={handleAddShow}
            style={{ marginRight: 15 }}
          >
            Add Schedule
          </IconButton>
          {/*</Button>*/}
          <IconButton
            onClick={handleSchedule24x7Click}
            icon={<Icon icon="calendar" style={{ marginTop: 3 }} />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            24x7
          </IconButton>
        </Form>

        <EditModal
          title="Edit Schedule 24x7"
          open={schedule24x7ModalOpen}
          size={"xs"}
          onClose={handleSchedule24x7ModalClose}
          onCancel={handleSchedule24x7ModalClose}
          onSubmit={handleSchedule24x7ModalSave}
        >
          <PingTreesRoutingSchedule24X7Form
            routingId={selectedItem ? selectedItem.routing_set_id : 0}
            formRef={schedule24x7FormRef}
            parentCallback={handleSchedule24x7Callback}
          />
        </EditModal>
        <Modal
          show={show}
          onClose={handleRemoveClose}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Header>
            <Modal.Title>
              {" "}
              {operation === "add" ? "Add Schedule" : "Remove Schedule"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {operation === "add" ? (
              <Form>
                <FormGroup>
                  <ControlLabel>Day Of Week</ControlLabel>
                  <SelectPicker
                    data={days.map((day: any) => ({
                      abbreviation: day.abbreviation,
                      day: day.day,
                    }))}
                    style={{ width: 150 }}
                    labelKey="day"
                    valueKey="abbreviation"
                    Placeholder={"All Days"}
                    value={Forms.day}
                    onChange={Forms.setDay}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Start Time</ControlLabel>
                  <DatePicker
                    format="HH:mm"
                    value={Forms.startTime}
                    onChange={setStartTime}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>End Time</ControlLabel>
                  <DatePicker
                    format="HH:mm"
                    value={Forms.endTime}
                    onChange={Forms.setEndTime}
                  />
                </FormGroup>
              </Form>
            ) : (
              "You want to remove this Schedule. Are you sure"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={operation === "add" ? addSchedule : deleteRouteAction}
              color={operation === "add" ? "blue" : "red"}
              appearance="primary"
            >
              {operation === "add" ? "Save" : "Remove"}
            </Button>
            <Button onClick={handleRemoveClose} appearance="subtle">
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </FlexboxGrid.Item>

      <FlexboxGrid.Item>
        <Table
          virtualized
          height={height}
          headerHeight={65}
          loading={isLoading === true}
          data={filter(sortData(Array.isArray(data) ? data : []), searchQuery)}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          <Column width={150} fixed sortable resizable>
            <HeaderCell>Day of Week</HeaderCell>
            <DayEditableCell dataKey="day_of_week" onChange={handleChange} />
            {/* <DayCell
              days={days}
              operation={operation}
              dataKey="day_of_week"
              Day={Day}
            ></DayCell> */}
          </Column>
          <Column width={150} sortable resizable>
            <HeaderCell>Start Time</HeaderCell>
            <TimeEditableCell dataKey="start_time" onChange={handleChange} />
            {/* <TimeStartCell></TimeStartCell> */}
          </Column>
          <Column width={150} sortable resizable>
            <HeaderCell>End Time</HeaderCell>
            <TimeEditableCell dataKey="end_time" onChange={handleChange} />
            {/* <TimeEndCell></TimeEndCell> */}
          </Column>
          {Admediary.userDisable ? (
            ""
          ) : (
            <Column width={120} sortable resizable>
              <HeaderCell>Action</HeaderCell>
              <ActionCell
                // handleEditAction={handleEditAction}
                // handleRemoveAction={handleRemoveShow}
                editHandler={handleEditAction}
                removeHandler={handleRemoveShow}
                saveHandler={handleSaveActionClick}
                closeHandler={handleCloseActionClick}
                fetchData={fetchData}
                setIsLoading={setIsLoading}
              />
            </Column>
          )}
        </Table>
      </FlexboxGrid.Item>
    </FlexboxGrid.Item>
  );
};

const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  saveHandler,
  closeHandler,
  ...props
}: any) => {
  const trash = removeHandler instanceof Function && (
    <IconButton
      appearance="subtle"
      onClick={(e) => removeHandler(rowData, e)}
      icon={<Icon icon="trash" />}
    />
  );

  return (
    <Cell {...props} className="link-group">
      {rowData.status === "EDIT"
        ? [
            <IconButton
              appearance="subtle"
              onClick={(e) => saveHandler(rowData, e)}
              icon={<Icon icon="check" />}
            />,
            <IconButton
              appearance="subtle"
              onClick={(e) => closeHandler(rowData, e)}
              icon={<Icon icon="close" />}
            />,
          ]
        : [
            <IconButton
              appearance="subtle"
              onClick={(e) => editHandler(rowData, e)}
              icon={<Icon icon="edit2" />}
            />,
            trash,
          ]}
    </Cell>
  );
};

// const ActionCell = ({
//   fetchData,
//   setIsLoading,
//   addSchedule,
//   rowData,
//   lock,
//   handleEditAction,
//   handleRemoveAction,
//   ...props
// }: any) => {
//   const Forms = React.useContext(FormContext);
//   const triggerRef = React.createRef();
//   const updateSchedule = () => {
//     const data = {
//       schedule_id: Forms.schedule,
//       routing_set_id: Forms.route,
//       day_of_week: Forms.day,
//       end_time: Forms.endTime,
//       start_time: Forms.startTime,
//     };

//     setIsLoading(true);
//     (async () => {
//       try {
//         await Query("routing_set_schedule_update", data);
//         fetchData();
//       } catch (e) {
//       } finally {
//       }
//     })();

//     Forms.setClose(true);
//     Forms.setLock(false);
//     // @ts-ignore
//     triggerRef.current.close();
//   };

//   const close = () => {
//     // @ts-ignore
//     triggerRef.current.close();
//     Forms.setLock(false);
//   };
//   const trash = handleRemoveAction instanceof Function && (
//     <IconButton
//       appearance="subtle"
//       onClick={(e) => handleRemoveAction(rowData, e)}
//       icon={<Icon icon="trash" />}
//     />
//   );

//   return (
//     <Cell {...props} className="link-group">
//       {rowData.status === "EDIT"
//         ? [
//             <IconButton
//               appearance="subtle"
//               onClick={(e) => addSchedule(rowData, e)}
//               icon={<Icon icon="check" />}
//             />,
//             <IconButton
//               appearance="subtle"
//               // onClick={(e) => closeHandler(rowData, e)}
//               icon={<Icon icon="close" />}
//             />,
//           ]
//         : [
//             <IconButton
//               appearance="subtle"
//               onClick={(e) => handleEditAction(rowData, e)}
//               icon={<Icon icon="edit2" />}
//             />,
//              <IconButton
//                 appearance="subtle"
//                 onClick={(e) => handleRemoveAction(rowData, e)}
//                 icon={<Icon icon="trash" />}
//               />,
//           ]}
//     </Cell>
//   );

//   // return (
//   //   <Cell {...props} className="link-group">
//   //     <Whisper
//   //       placement={"topEnd"}
//   //       speaker={<Speaker close={close} update={updateSchedule} />}
//   //       trigger="click"
//   //       ref={triggerRef}
//   //     >
//   //       <IconButton
//   //         onClick={(e) => handleEditAction(rowData, triggerRef)}
//   //         icon={<Icon icon="edit2" />}
//   //       />
//   //     </Whisper>
//   //     <IconButton
//   //       appearance="subtle"
//   //       onClick={(e) => handleRemoveAction(rowData, e)}
//   //       icon={<Icon icon="trash-o" />}
//   //     />
//   //   </Cell>
//   // );
// };
const getDayOfWeekByAbbreviation = (abbr: string) => {
  const days = new Map([
    ["mo", "Monday"],
    ["tu", "Tuesday"],
    ["we", "Wednesday"],
    ["th", "Thursday"],
    ["fr", "Friday"],
    ["sa", "Saturday"],
    ["su", "Sunday"],
  ]);

  return days.has(abbr) ? days.get(abbr) : null;
};
const EDIT_STATUS = "EDIT";

const transformTimeToDate = (time: string) => {
  const date = new Date();
  const [hours, minutes] = time.split(":");
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));

  return date;
};
const DayEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <SelectPicker
          defaultValue={rowData[dataKey]}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          searchable={false}
          cleanable={false}
          data={[
            { value: "mo", label: "Monday" },
            { value: "tu", label: "Tuesday" },
            { value: "we", label: "Wednesday" },
            { value: "th", label: "Thursday" },
            { value: "fr", label: "Friday" },
            { value: "sa", label: "Saturday" },
            { value: "su", label: "Sunday" },
          ]}
        />
      ) : (
        getDayOfWeekByAbbreviation(rowData[dataKey])
      )}
    </Cell>
  );
};

const TimeEditableCell = ({ rowData, dataKey, onChange, ...props }: any) => {
  return (
    <Cell
      {...props}
      className={
        rowData.status === EDIT_STATUS ? "editor-cell" : "pre-editor-cell"
      }
    >
      {rowData.status === EDIT_STATUS ? (
        <DatePicker
          defaultValue={transformTimeToDate(rowData[dataKey])}
          onChange={(value: any) => {
            onChange && onChange(rowData, dataKey, value);
          }}
          placement="autoVerticalStart"
          format="HH:mm"
          ranges={[]}
          hideMinutes={(minute: number) => minute % 5 !== 0}
          cleanable={false}
        />
      ) : (
        rowData[dataKey]
      )}
    </Cell>
  );
};

const DayCell = ({
  rowData,
  days,
  Day,
  operation,
  groupBy,
  rowKey,
  dataKey,
  ...props
}: any) => {
  const Forms = React.useContext(FormContext);
  return (
    <Cell {...props} className="link-group">
      <SelectPicker
        data={days.map((day: any) => ({
          abbreviation: day.abbreviation,
          day: day.day,
        }))}
        style={{ width: 150 }}
        labelKey="day"
        valueKey="abbreviation"
        Placeholder={"All Days"}
        value={rowData.day_of_week}
        onChange={(event) => {
          Day && Day(rowKey, dataKey, event);
        }}
        cleanable={false}
        disabled={
          Forms.lock && rowData.schedule_id === Forms.schedule ? false : true
        }
      />
    </Cell>
  );
};

const TimeStartCell = ({ rowData, groupBy, ...props }: any) => {
  const Forms = React.useContext(FormContext);
  const [time, setTime] = React.useState(
    new Date("2021-12-12" + " " + rowData.start_time + ":00")
  );

  const updateTime = (newTime: Date) => {
    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setTime(newTime);
    rowData.start_time = formattedTime;
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ width: 100 }}>
        <DatePicker
          format="HH:mm"
          value={time}
          onChange={updateTime}
          cleanable={false}
          disabled={
            Forms.lock && rowData.schedule_id === Forms.schedule ? false : true
          }
        />
      </div>
    </Cell>
  );
};

const TimeEndCell = ({ rowData, groupBy, ...props }: any) => {
  const Forms = React.useContext(FormContext);
  const [time, setTime] = React.useState(
    new Date("2021-12-12" + " " + rowData.end_time + ":00")
  );

  const updateTime = (newTime: Date) => {
    const formattedTime = newTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setTime(newTime);
    rowData.end_time = formattedTime;
  };

  return (
    <Cell {...props} className="link-group">
      <div style={{ width: 100 }}>
        <DatePicker
          format="HH:mm"
          value={time}
          onChange={updateTime}
          cleanable={false}
          disabled={
            Forms.lock && rowData.schedule_id === Forms.schedule ? false : true
          }
        />
      </div>
    </Cell>
  );
};

// const TimeStartCell = ({
//   rowData,
//   groupBy,
//   changeStartTime,
//   ...props
// }: any) => {
//   const Forms = React.useContext(FormContext);
//   const [time, setTime] = React.useState(
//     new Date("2021-12-12" + " " + rowData.start_time + ":00")
//   );
//   const Time = (data: any) => {
//     setTime(data);
//     const hours =
//       parseInt(data.getHours()) < 9 ? "0" + data.getHours() : data.getHours();
//     const minutes =
//       parseInt(data.getMinutes()) < 9
//         ? "0" + data.getMinutes()
//         : data.getMinutes();
//     Forms.setStartTime(hours + ":" + minutes);
//   };
//   return (
//     <Cell {...props} className="link-group">
//       <div style={{ width: 100 }}>
//         <DatePicker
//           format="HH:mm"
//           value={time}
//           onChange={Time}
//           cleanable={false}
//           disabled={
//             Forms.lock && rowData.schedule_id === Forms.schedule ? false : true
//           }
//         />
//       </div>
//     </Cell>
//   );
// };

// const TimeEndCell = ({ rowData, groupBy, ...props }: any) => {
//   const Forms = React.useContext(FormContext);
//   const [time, setTime] = React.useState(
//     new Date("2021-12-12" + " " + rowData.end_time + ":00")

//   );
//   console.log(rowData.end_time)
//   const Time = (data: any) => {
//     setTime(data);
//     const hours =
//       parseInt(data.getHours()) < 9 ? "0" + data.getHours() : data.getHours();
//     const minutes =
//       parseInt(data.getMinutes()) < 9
//         ? "0" + data.getMinutes()
//         : data.getMinutes();
//     Forms.setEndTime(hours + ":" + minutes);
//     console.log("Time End Call")
//   };
//   return (
//     <Cell {...props} className="link-group">
//       <div style={{ width: 100 }}>
//         <DatePicker
//           format="HH:mm"
//           value={time}
//           onChange={Time}
//           cleanable={false}
//           disabled={
//             Forms.lock && rowData.schedule_id === Forms.schedule ? false : true
//           }
//         />
//       </div>
//     </Cell>
//   );
// };

const ButtonField: React.FC<any> = ({
  fetchData,
  name,
  close,
  update,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <ButtonToolbar>
      <Button color="blue" onClick={update}>
        Save
      </Button>
      <Button color="red" onClick={close}>
        Cancel
      </Button>
    </ButtonToolbar>
  );
};
const Speaker = ({ triggerRef, fetchData, close, update, ...props }: any) => {
  return (
    <Popover {...props}>
      <Form layout="inline" style={{ width: 250, height: 40 }}>
        <ButtonField
          name="number"
          label="Number"
          accepter={Button}
          close={close}
          update={update}
          fetchData={fetchData}
        />
      </Form>
    </Popover>
  );
};
export default PingTreeRoutingScheduleList;

import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  InputNumber,
} from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";
import moment from "moment";

const { StringType, NumberType, ArrayType, DateType } = Schema.Types;

const model = Schema.Model({
  budget_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  cap: NumberType(),
  is_cap_amount: NumberType(),
  buyer_contract_budget_cap_interval: StringType(),
  budget_start_date: DateType() || null,
  budget_end_date: DateType() || null,
  is_active: ArrayType(),
});

type BuyerContractBudgetFormType = {
  budgetId?: number;
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractBudgetForm: React.FC<BuyerContractBudgetFormType> = ({
  budgetId = 0,
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyBudget = {
    budget: 0,
    contract_id: contractId,
    is_active: [],
    buyer_contract_budget_cap_interval: "",
    cap: 1,
  };
  const [formValue, setFormValue] = React.useState(emptyBudget);
  const [formError, setFormError] = React.useState({});

  const params = {
    budget_id: budgetId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.budget_id === 0) {
      setData(emptyBudget);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_budget", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.is_active)) {
      record.is_active = record.is_active === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);
    if (parentCallback instanceof Function) {
      data.budget_end_date =
        data.budget_end_date && data.budget_end_date !== null
          ? data.budget_end_date
          : null;

      data.budget_start_date =
        data.budget_start_date && data.budget_start_date !== null
          ? data.budget_start_date
          : null;
      parentCallback({
        ...data,
        budget_start_date:
          data.budget_start_date !== null
            ? moment(new Date(data.budget_start_date)).format("YYYY-MM-DD")
            : null,
        budget_end_date:
          data.budget_end_date !== null
            ? moment(new Date(data.budget_end_date)).format("YYYY-MM-DD")
            : null,
      });
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Active" name="is_active" accepter={CheckboxGroup}>
        <Checkbox value={1} />
      </Field>
      <Field
        label="Type"
        name="is_cap_amount"
        placeholder="Select Type"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: 1, label: "Dollars" },
          { value: 0, label: "Leads" },
        ]}
      />
      <Field
        label="Interval"
        name="buyer_contract_budget_cap_interval"
        placeholder="Select Interval"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "", label: "Lifetime" },
          { value: "weekly", label: "Weekly" },
          { value: "monthly", label: "Monthly" },
          { value: "custom", label: "Custom" },
        ]}
      />
      <Field
        label="Amount"
        name="cap"
        accepter={InputNumber}
        defaultValue=""
        min={0}
      />
      {formValue.buyer_contract_budget_cap_interval === "custom" && (
        <Field
          label="Start Date"
          name="budget_start_date"
          accepter={DatePicker}
        />
      )}
      {formValue.buyer_contract_budget_cap_interval === "custom" && (
        <Field label="End Date" name="budget_end_date" accepter={DatePicker} />
      )}
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractBudgetForm;

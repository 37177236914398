import React from "react";
import { Table } from "rsuite";
import {
  HeaderSummary,
  CurrencyCell,
  NumberCell,
  PercentCell,
  ParentCell,
} from "../Table";
import { currency, percent } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";

const { Column, HeaderCell, Cell } = Table;

type PerformanceTableProps = {
  data: any;
  isLoading: boolean;
  rowKey: string;
  checked: boolean;
  group: string;
  sortType: string;
  sortColumn: string;
  summary: any;
  onSortColumn: (column: any, type: any) => void;
};

const PerformanceTable: React.FC<PerformanceTableProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const checked = props.checked;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const group = props.group;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sortTree(list, sort_column, sort_type) : list
    );
  };

  const groupTitles = new Map([
    ["site", "Site ID/Affliate"],
    ["date", "Entry Date/Site URL"],
  ]);

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading}
      data={Array.isArray(data) ? sortData(data) : []}
      checked={checked}
      rowKey={rowKey}
      group={group}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={250} align="left" sortable resizable>
        {checked ? (
          <HeaderCell>Product ID/{groupTitles.get(group)}</HeaderCell>
        ) : (
          <HeaderCell>Product ID/{groupTitles.get(group)}</HeaderCell>
        )}
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column width={175} align="left" sortable resizable>
        <HeaderCell>Product Name</HeaderCell>
        <Cell dataKey="product_description" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Site ID</HeaderCell>
        <Cell dataKey="site_id" />
      </Column>
      {group === "date" ? (
        ""
      ) : (
        <Column align="right" sortable resizable>
          <HeaderCell>Aff ID</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
      )}
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Impressions" summary={summary.impressions} />
        </HeaderCell>
        <NumberCell dataKey="impressions" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Unique" summary={summary.unique_impressions} />
        </HeaderCell>
        <NumberCell dataKey="unique_impressions" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Submits" summary={summary.submits} />
        </HeaderCell>
        <NumberCell dataKey="submits" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Submit %"
            summary={percent(summary.submitPercent)}
          />
        </HeaderCell>
        <PercentCell dataKey="submitPercent" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Conversions" summary={summary.conversions} />
        </HeaderCell>
        <NumberCell dataKey="conversions" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Conv %"
            summary={percent(summary.conversionPercent)}
          />
        </HeaderCell>
        <PercentCell dataKey="conversionPercent" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Cost" summary={summary.paid_amount} />
        </HeaderCell>
        <Cell dataKey="paid_amount" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="CPL" summary={currency(summary.cpl)} />
        </HeaderCell>
        <CurrencyCell dataKey="cpl" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="CPI" summary={currency(summary.cpi)} />
        </HeaderCell>
        <CurrencyCell dataKey="cpi" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Revenue" summary={currency(summary.revenue)} />
        </HeaderCell>
        <CurrencyCell dataKey="revenue" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="RPI" summary={currency(summary.rpi)} />
        </HeaderCell>
        <CurrencyCell dataKey="rpi" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="P&L" summary={currency(summary.pnl)} />
        </HeaderCell>
        <CurrencyCell dataKey="pnl" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="PM" summary={percent(summary.pm)} />
        </HeaderCell>
        <PercentCell dataKey="pm" />
      </Column>
    </Table>
  );
};

export default PerformanceTable;

import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Button,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";
import LeadDispositionsTable from "./LeadDispositionsTable";
import EditModal from "../EditModal";
import moment from "moment/moment";
import { percent, thousands } from "../../@Utils/Format";

const addCalculatedValues = (row: any) => {
  return {
    ...row,
    ...{
      // contract_id: isNaN(row.contract_id) ? 0 : row.contract_id,
      transaction_date: row.transaction_date
        ? moment(row.transaction_date).local().format("MM-DD-Y HH:mm")
        : "",
    },
  };
};

const addCalculatedValuesRecursively = (list: any) => {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
};

const LeadDispositions: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState("asc");
  const [sort_column, set_sort_column] = React.useState("transaction_date");
  const [lidList, setLidList] = React.useState();
  const [show, setShow] = React.useState(false);
  const [unparsedLidList, setUnparsedLidList] = React.useState();
  const handleEditModalClose = () => setShow(false);
  const handleEditLids = () => setShow(true);

  const params = {
    lid_list: lidList,
    start_date: Admediary.start,
    end_date: Admediary.end,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "disposition_lookup_report",
    params
  );

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  const runReport = () => {
    Admediary.setRun(true);
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      runReport();
    }
  };

  const getExportData = (data: any) => {
    const flattenTree = (list: any, parent?: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        parent = parent || item;

        const current = {
          ...{
            contract_id: item.contract_id || parent.contract_id,
            contract_name: item.contract_name || parent.contract_name,
          },
          ...item,
        };

        if (current.children && Array.isArray(current.children)) {
          result.push(...flattenTree(current.children, current));
        } else {
          result.push(current);
        }
      });

      return result;
    };

    return flattenTree(data.map((item: any) => item)).map((item: any) => {
      return {
        "Contract ID": parseInt(item.contract_id) || 0,
        "Contract Name": item.contract_name,
        "Lead Instance ID": item.lead_instance_id,
        Status: item.status,
        Response: item.response,
        "Transaction Date": item.transaction_date,
      };
    });
  };

  // const formatExportData = (data: any) => {
  //   return (
  //     data
  //       .map((item: any) => item)
  //       // Clean, format and reorder by fields with right headers
  //       .map((item: any) => {
  //         return {
  //           "Contract ID": parseInt(item.contract_id) || 0,
  //           "Contract Name": item.contract_name,
  //           "Lead Instance ID": item.lead_instance_id,
  //           Status: item.status,
  //           Response: item.response,
  //           "Transaction Date": item.transaction_date,
  //         };
  //       })
  //   );
  // };

  const handleEnterLids = () => {
    // Separate lead instance ids with commas
    const lids = unparsedLidList
      .replace(/ /g, ",")
      .replace(/\n/g, ",")
      .replace(/,\s*$/, "");

    if (lids) {
      setLidList(lids);
      runReport();
    }

    setShow(false);
  };

  const tableData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const exportData = getExportData(tableData);

  // console.log('data', data)
  return (
    <>
      <Title title="Lead Disposition Lookup" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <Button
            size="lg"
            onClick={handleEditLids}
            style={{
              background: "rgb(2, 27, 59)",
              color: "rgb(255, 255, 255)",
            }}
          >
            Enter Lead IDs
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() => exportCsv(`lead_disposition_lookup.csv`, exportData)}
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <LeadDispositionsTable
        data={Array.isArray(tableData) ? tableData : []}
        isLoading={isLoading === true}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />

      <EditModal
        title="Lead Instance IDs"
        open={show}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleEnterLids}
      >
        <InputGroup
          style={{ width: 500, marginRight: 15, display: "inline-block" }}
        >
          <Input
            componentClass="textarea"
            rows={12}
            placeholder="Lead ID List"
            onChange={(v) => {
              setUnparsedLidList(v);
            }}
            value={unparsedLidList}
            size="lg"
          />
        </InputGroup>
      </EditModal>
    </>
  );
};

export default LeadDispositions;

import React from "react";
import {
  Alert,
  Form,
  ControlLabel,
  FormControl,
  FormGroup,
  ButtonToolbar,
  Button,
  Notification,
  FlexboxGrid,
  Schema,
  HelpBlock,
  InputNumber,
} from "rsuite";
import DatePicker from "rsuite/lib/DatePicker";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";

// ***********************************************************
//            Custom Field Component and Model
// ***********************************************************

const { StringType, NumberType, DateType } = Schema.Types;
const model = Schema.Model({
  // skills: ArrayType()
  // .minLength(2, 'Please select at least 2 types of Skills.')
  // .isRequired('This field is required.'),
  // status: ArrayType()
  // .minLength(2, 'Please select at least 2 types of Status.')
  // .isRequired('This field is required.'),
  // number: NumberType().min(5, 'This field must be greater than 5').isRequired('This field is required'),
  buyer: NumberType().isRequired("This field is required"),
  contract: NumberType().isRequired("This field is required"),
  prepayment_date: DateType().isRequired(),
  prepayment_amount: NumberType()
    .addRule((value, data) => {
      return value.toString() !== "0";
    }, "Enter number greater or less then 0")
    .isRequired("This field is required"),
  // level: NumberType().min(5, 'This field must be greater than 5'),
  prepayment_type: StringType().isRequired("This field is required"),
});

type PrepaymentFieldProps = {
  name: string;
  message?: string;
  label?: string;
  accepter: any;
  error?: any;
};
const PrepaymentField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 150 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <HelpBlock>{message}</HelpBlock>
    </FormGroup>
  );
};

// ***********************************************************
//             Interfaces
// ***********************************************************

/**
 * interface for form params
 *
 */
interface paramsType {
  start_date: Date;
  end_date: Date;
  contract_id: number;
  product_id: number;
  buyer_id: number;
  PrepaymentAmount: number;
  PrepaymentRefresh: Boolean;
  PrepaymentDate: Date;
  prepayment_type: string;
  prepayment_note: string;
}
interface validationType {
  valid: Boolean;
  message: string;
}

// ***********************************************************
//           Component
// ***********************************************************

type PrepaymentFormsProps = {};
const PrepaymentForm: React.FC<PrepaymentFormsProps> = () => {
  const admediary_context = React.useContext(AdmediaryContext);
  const { config } = admediary_context;

  // ***********************************************************
  //             State / Data Functions
  // ***********************************************************
  const [prepayment_type, setPrepaymentType] = React.useState<string>(
    "prepayment"
  );
  const [prepayment_note, setPrepaymentNote] = React.useState<string>("");
  const [contract_id, setContract_id] = React.useState(0);
  const [form, setForm] = React.useState<any>({});
  const [formValue, setformValue] = React.useState<any>({
    product: admediary_context.product,
    buyer: admediary_context.buyer,
    PrepaymentAmount: admediary_context.PrepaymentAmount,
    PrepaymentRefresh: admediary_context.PrepaymentRefresh,
    prepayment_date: new Date(),
    prepayment_type: prepayment_type,
    prepayment_note: "",
  });
  const [formError, setformError] = React.useState<any>({});
  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setPrepaymentDate = (data: any) => {
    admediary_context.setPrepaymentDate(data);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setPrepaymentAmount = (data: any) => {
    admediary_context.setPrepaymentAmount(data);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setPrepaymentRefresh = (data: any) => {
    admediary_context.setPrepaymentRefresh(data);
  };

  /**
   * Proxy for capturing buyers and sending it up stream
   * @param data
   */
  const setBuyer = (data: any) => {
    admediary_context.setBuyer(data);
  };

  const availableContracts = () => {
    if (admediary_context.buyer) {
      return config.contracts.filter(
        (item: any) => item.buyer_id === admediary_context.buyer
      );
    }
    return config.contracts;
  };

  // ***********************************************************
  //            options and params
  // ***********************************************************
  /**
   * form parameters
   *
   */
  const params: paramsType = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    contract_id: contract_id,
    product_id: admediary_context.product,
    buyer_id: admediary_context.buyer,
    PrepaymentAmount: admediary_context.PrepaymentAmount,
    PrepaymentRefresh: admediary_context.PrepaymentRefresh,
    PrepaymentDate: admediary_context.PrepaymentDate,
    prepayment_type: prepayment_type,
    prepayment_note: prepayment_note,
  };

  /**
   *
   * options for prepayment_types dropdown
   * @todo might want to change this at some point to be DB driven
   *
   */
  const prepayment_types = [
    {
      prepayment_name: "Prepayment",
      prepayment_type: "prepayment",
    },
    {
      prepayment_name: "Adjustment",
      prepayment_type: "adjustment",
    },
    {
      prepayment_name: "Calls Sold",
      prepayment_type: "calls_sold",
    },
  ];
  // ***********************************************************
  //              Form Functions
  // ***********************************************************

  /**
   * submit
   *
   */
  const handleSubmit = () => {
    if (!form.check()) {
      Alert.error("Error");
      return;
    }
    Alert.success("Success");

    (async () => {
      try {
        await Query("prepayment_insert", params);
        Notification.open({
          title: "Success",
          description: "The Prepayment was sucessfully added",
        });
        handleRecalc();
      } catch (e) {
        Notification.open({
          title: "Error",
          description: "The Prepayment was not added",
        });
      } finally {
        handleFormClear();
      }
    })();
  };

  /**
   * trigger Recalc for the date and params
   *
   */
  const handleRecalc = () => {
    (async () => {
      try {
        await Query("prepayment_calc", params);
        if (admediary_context.PrepaymentRefresh) {
          setPrepaymentRefresh(false);
        } else {
          setPrepaymentRefresh(true);
        }
        Notification.open({
          title: "Success",
          description: "The Prepayment was sucessfully recalculated.",
        });
      } catch (e) {
        Notification.open({
          title: "Error",
          description: "The Prepayment was not recalculated",
        });
      } finally {
      }
    })();
  };
  /**
   * clear form
   *
   */
  const handleFormClear = () => {
    setContract_id(0);
    setPrepaymentAmount(0);
    setPrepaymentDate(new Date());
    setPrepaymentNote("");
    setPrepaymentType("");
  };

  return (
    <>
      <FlexboxGrid justify="space-around" style={{}}>
        <FlexboxGrid.Item colspan={24} style={{}}>
          <h3 style={{ fontSize: "18px" }}>New Prepayment/ Adjustment</h3>
          <Form
            layout="inline"
            ref={setForm}
            onChange={(formValue) => {
              setformValue(formValue);
            }}
            onCheck={(formError) => {
              setformError({ formError });
            }}
            formValue={formValue}
            model={model}
          >
            <PrepaymentField
              label="Buyer"
              name="buyer"
              accepter={SelectPicker}
              errorMessage={formError.buyer}
              placeholder="Choose Buyer"
              data={config.buyers}
              labelKey="buyer_name"
              valueKey="buyer_id"
              onChange={setBuyer}
              value={admediary_context.buyer}
            />
            <PrepaymentField
              label="Contract"
              name="contract"
              accepter={SelectPicker}
              errorMessage={formError.buyer}
              placeholder="Choose Contract"
              data={availableContracts()}
              labelKey="contract_name"
              valueKey="contract_id"
              onChange={setContract_id}
              value={contract_id}
            />
            <PrepaymentField
              accepter={DatePicker}
              name="prepayment_date"
              label="Prepayment Date"
              errorMessage={formError.prepayment_date}
              size="md"
              block={false}
              style={{ width: 150 }}
              value={new Date(admediary_context.PrepaymentDate)}
              onChange={setPrepaymentDate}
              cleanable={false}
              format="MMM Do, YYYY"
            />
            <PrepaymentField
              name="prepayment_amount"
              label="Prepayment Amount"
              accepter={InputNumber}
              error={formError.number}
              onChange={setPrepaymentAmount}
              value={admediary_context.PrepaymentAmount}
            />
            <PrepaymentField
              label="Prepayment Type"
              name="prepayment_type"
              accepter={SelectPicker}
              errorMessage={formError.buyer}
              placeholder="Choose Type"
              data={prepayment_types}
              labelKey="prepayment_name"
              valueKey="prepayment_type"
              onChange={setPrepaymentType}
              value={prepayment_type}
            />
            <PrepaymentField
              name="prepayment_note"
              label="Notes / Comments"
              error={formError.prepayment_note}
              onChange={setPrepaymentNote}
              value={prepayment_note}
            />

            <FormGroup>
              <ControlLabel style={{ display: "block" }}>&nbsp;</ControlLabel>
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button
                  appearance="default"
                  onClick={(e) => {
                    handleFormClear();
                  }}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default PrepaymentForm;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

type BuyerContractsUploadSourcesProps = {
  contractId?: number;
  parentCallback?: any;
};

const BuyerContractsUploadSources: React.FC<BuyerContractsUploadSourcesProps> = ({
  contractId = 0,
  parentCallback = () => {},
}) => {
  const [file, setFile] = React.useState();
  const [sources, setSources] = React.useState([]);

  const fileReader = new FileReader();

  //Set file to contents to state
  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  //Convert text csv to array of objects
  const csvFileToArray = (item: any) => {
    const validateCSV = ["afid", "sid1", "sid2", "sid3", "sid4", "allow"];
    let csvHeader = item.slice(0, item.indexOf("\n")).split(",");
    let csvRows = item.slice(item.indexOf("\n") + 1).split("\n");

    csvHeader = item.slice(0, item.indexOf("\r")).split(",");

    const missingHeaders = validateCSV.filter(
      (item: any) => !csvHeader.includes(item)
    );
    const isValidCSV =
      Array.isArray(missingHeaders) && missingHeaders.length > 0 ? false : true;

    if (!isValidCSV) {
      Notification.error({
        title: "Error",
        duration: 60000,
        description: `Invalid CSV: Your CSV is missing the follow headers "${missingHeaders.join(
          ", "
        )}"`,
      });
    } else {
      let importedSources = csvRows.map((item: any) => {
        const values = item.split(",");
        const formattedValued = values.map((value: any) =>
          value.replace("\r", "")
        );

        return csvHeader.reduce((object: any, header: any, index: any) => {
          object[header] = formattedValued[index];
          return object;
        }, {});
      });
      importedSources = importedSources.filter(
        (o: any) => !Object.keys(o).every((k) => !o[k])
      );
      setSources(importedSources);

      const formatSources = importedSources.map((item: any) => {
        return {
          source_id: 0,
          contract_id: contractId,
          affiliate_id: item.afid,
          sid1: item.sid1 ? item.sid1 : "",
          sid2: item.sid2 ? item.sid2 : "",
          sid3: item.sid3 ? item.sid3 : "",
          sid4: item.sid4 ? item.sid4 : "",
          allow: item.allow ? item.allow : "",
        };
      });

      if (parentCallback instanceof Function) {
        parentCallback(Array.isArray(formatSources) ? formatSources : []);
      }
    }
  };

  const handleInputFile = (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event: any) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <>
      <FlexboxGrid justify="center" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={6}>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            style={{ marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <IconButton
            onClick={handleInputFile}
            icon={<Icon icon="file-upload" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
          >
            Import File
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        data={Array.isArray(sources) ? sources : []}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
      >
        <Column>
          <HeaderCell>AFID</HeaderCell>
          <Cell dataKey="afid" />
        </Column>
        <Column>
          <HeaderCell>Sid1</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>
        <Column>
          <HeaderCell>Sid2</HeaderCell>
          <Cell dataKey="sid2" />
        </Column>
        <Column>
          <HeaderCell>Sid3</HeaderCell>
          <Cell dataKey="sid3" />
        </Column>
        <Column>
          <HeaderCell>Sid4</HeaderCell>
          <Cell dataKey="sid4" />
        </Column>
        <Column>
          <HeaderCell>Allow</HeaderCell>
          <Cell dataKey="allow">
            {(rowData: any) => (rowData.allow ? "Allow" : "Block")}
          </Cell>
        </Column>
      </Table>
    </>
  );
};

export default BuyerContractsUploadSources;

import React, { useContext } from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  ControlLabel,
  Form,
  FormGroup,
  Icon,
  IconButton,
  InputNumber,
  Loader,
  Notification,
  Schema,
  SelectPicker,
} from "rsuite";
import Field from "../Field";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import EditModal from "../EditModal";
import BuyerContractFilterForm from "./BuyerContractFilterForm";
import _ from "lodash";
import { FormInstance } from "rsuite/lib/Form/Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  mapping_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  active: ArrayType(),
  priority: NumberType()
    .isRequired("Priority is required.")
    .min(1, "Minimum is 1")
    .max(10, "Maximum is 10"),
  action: StringType(),
  evaluator: StringType(),
});

type BuyerContractFilterMapFormType = {
  mappingId?: number;
  contractId?: number;
  categoryId?: number;
  buyerId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractFilterMapForm: React.FC<BuyerContractFilterMapFormType> = ({
  mappingId = 0,
  contractId = 0,
  categoryId = 0,
  buyerId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    mapping_id: 0,
    filter_id: 0,
    contract_id: contractId,
    priority: 1,
    active: [],
    evaluator: "",
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});
  const Admediary = useContext(AdmediaryContext);

  const params = {
    mapping_id: mappingId,
    contract_id: contractId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.mapping_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_filter_map", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [filters] = useAdmediaryApi("buyer_contract_filters", {
    category_id: categoryId,
    contract_id: contractId,
    buyer_id: buyerId,
    list_by_category: 0,
  });

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    // Change evaluator by filter value
    const evaluator = getFilterEvaluator(data.filter_id);
    if (evaluator !== undefined && evaluator !== null) {
      data.evaluator = evaluator;
    }

    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  const sortFilters = (filters: any) => {
    const key = "description";

    return filters.sort((a: any, b: any) =>
      b[key] < a[key] ? 1 : b[key] > a[key] ? -1 : 0
    );
  };

  const getFilterEvaluator = (filterId: any) => {
    if (!Array.isArray(filters)) {
      return;
    }

    const filter = filters.find((filter: any) => filter.filter_id === filterId);

    return filter ? filter.evaluator : null;
  };

  const [formFilterLoading, setFormFilterLoading] = React.useState(false);
  const [editFilterModalOpen, setEditFilterModalOpen] = React.useState(false);
  const [selectedFilterId, setSelectedFilterId] = React.useState(0);
  const [filterFormValue, setFilterFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();

  const handleEditFilterModalOpen = () => setEditFilterModalOpen(true);
  const handleEditFilterModalClose = () => setEditFilterModalOpen(false);
  const handleCallback = (filterFormValue: any) => {
    setFilterFormValue(filterFormValue);
  };
  const handleSaveFilter = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormFilterLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(filterFormValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }

    if (Array.isArray(data.dynamic)) {
      data.dynamic = data.dynamic.length ? data.dynamic[0] : 0;
    }

    if (Array.isArray(data.one_of)) {
      data.one_of = data.one_of.length ? data.one_of[0] : 0;
    }

    data.evaluator = data.dynamic ? data.evaluator : "";
    data.filter_field = !data.dynamic ? data.filter_field : "";
    data.filter_values = !data.dynamic ? data.filter_values : "";

    (async () => {
      try {
        const results: any = await Query("buyer_contract_filter_update", data);
        setFormFilterLoading(false);

        Notification.success({
          title: "Success",
          description:
            "Buyer Contract Filter has been " +
            (data.filter_id ? "updated" : "added"),
        });

        handleEditFilterModalClose();

        setFormValue({
          ...formValue,
          ...{
            filter_id: parseInt(results.data.filter_id),
            evaluator: data.evaluator,
          },
        });
        //refresh();
      } catch (e) {
        setFormFilterLoading(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const editFilter = (filterId: number) => {
    if (!filterId) {
      Notification.info({
        title: "Select Filter",
        description: "You should select a filter at first to edit it",
      });
      return;
    }

    setSelectedFilterId(filterId);

    handleEditFilterModalOpen();
  };

  const createFilter = () => {
    setSelectedFilterId(0);

    handleEditFilterModalOpen();
  };

  // Render the component
  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  const disabled = !Admediary.userDisable ? false : true;

  return (
    <>
      <Form
        ref={formRef}
        onChange={handleChange}
        onCheck={setFormError}
        model={model}
        layout="horizontal"
        formValue={formValue}
        formError={formError}
      >
        {formValue.mapping_id === 0 && (
          <Field
            label="Filter"
            name="filter_id"
            placeholder="Select Filter"
            accepter={SelectPicker}
            data={Array.isArray(filters) ? sortFilters(filters) : []}
            valueKey="filter_id"
            labelKey="description"
            postfix={
              <Button appearance="link" onClick={createFilter}>
                Create new filter
              </Button>
            }
          />
        )}
        <Field label="Active" name="active" accepter={CheckboxGroup}>
          <Checkbox value={1} disabled={Admediary.userDisable} />
        </Field>
        <Field
          label="Priority"
          name="priority"
          accepter={InputNumber}
          defaultValue={1}
          min={1}
          max={10}
          disabled={Admediary.userDisable}
        />
        <Field
          label="Action"
          name="action"
          placeholder="Select Action"
          accepter={SelectPicker}
          data={[
            { value: "a", label: "Accept" },
            { value: "d", label: "Drop" },
            { value: "c", label: "Continue" },
          ]}
          disabled={Admediary.userDisable}
        />
        <FormGroup>
          <ControlLabel style={{ display: "block" }}>Evaluator</ControlLabel>
          <IconButton
            appearance="subtle"
            icon={<Icon icon="edit2" />}
            size="lg"
            onClick={(e) => editFilter(formValue.filter_id)}
          />
        </FormGroup>

        <Field
          label=""
          rows={8}
          name="evaluator"
          componentClass="textarea"
          disabled={true}
          style={{
            color: "#555",
            backgroundColor: "#EBEBE4",
          }}
        />
        {/*<span>{JSON.stringify(formValue)}</span>*/}
      </Form>

      <EditModal
        title={(selectedFilterId > 0 ? "Edit" : "Add") + " Filter"}
        open={editFilterModalOpen}
        size={"sm"}
        onClose={handleEditFilterModalClose}
        onCancel={handleEditFilterModalClose}
        onSubmit={handleSaveFilter}
        loading={formFilterLoading}
        hideSubmitButton={Admediary.userDisable}
      >
        <BuyerContractFilterForm
          filterId={selectedFilterId || 0}
          formRef={mainFormRef}
          disableScope={selectedFilterId > 0}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractFilterMapForm;

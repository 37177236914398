import React from "react";
import { Drawer, Table, Icon } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

type DispositionFilterBreakdownType = {
  data: any;
};

const DispositionFilterDetails: React.FC<DispositionFilterBreakdownType> = ({
  data = [],
}) => {
  const admediary_context = React.useContext(AdmediaryContext);

  // Reduce buyers based on data provided in props
  let buyers = admediary_context.config.buyers.reduce(
    (accum: any[], row: any) => {
      if (data.buyer_ids.includes(row.buyer_id.toString())) {
        accum[row.buyer_id] = { ...row, ...{ contracts: [] } };
      }
      return accum;
    },
    {}
  );

  // Reduce contracts to specific buyers and merge them with buyers array
  buyers = admediary_context.config.contracts.reduce(
    (accum: any[], row: any) => {
      if (
        accum[row.buyer_id] &&
        data.contract_ids.includes(row.contract_id.toString())
      ) {
        accum[row.buyer_id].contracts.push(row);
      }
      return accum;
    },
    buyers
  );

  /**
   * Container tables fed by container by buyers
   * @param contracts
   */
  const renderContractTable = (contracts: any) => {
    return (
      <>
        <Table data={contracts}>
          <Table.Column>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.Cell dataKey="contract_id" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Contract</Table.HeaderCell>
            <Table.Cell dataKey="contract_name" />
          </Table.Column>
        </Table>
      </>
    );
  };

  /**
   * Container by buyer
   * @param buyers
   */
  const renderBuyersByContract = (buyers: any) => {
    return Object.keys(buyers).map((key, id) => {
      const item: any = buyers[key];
      return (
        <div key={id}>
          <h4>{item.buyer_name}</h4>
          {renderContractTable(item.contracts)}
        </div>
      );
    });
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Status: {data.status} / Reason: `{data.reason}`
          {admediary_context.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={admediary_context.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={admediary_context.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>{renderBuyersByContract(buyers)}</Drawer.Body>
    </>
  );
};

export default DispositionFilterDetails;

import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HeaderSummary } from "../Table";
import { thousands } from "../../@Utils/Format";
import RecentRecords from "../Drawers/RecentRecords";
import DataHygiene from "../Drawers/DataHygiene";
import SourcePaths from "../Drawers/SourcePaths";
import SourceURLs from "../Drawers/SourceURLs";
import HourlyVolume from "../Drawers/HourlyVolume";
import UniqueRecords from "../Drawers/UniueRecords";
import EditSource from "../Drawers/EditSource";
import { sort } from "../../@Utils/Sorting";
import { FormInstance } from "rsuite/lib/Form/Form";

const { Column, HeaderCell, Cell } = Table;

type IncomingDataTable = {
  data: any;
  summary: any;
  isLoading: boolean;
  dateColumns: any;
  rowKey: string;
  group: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  refreshData?: () => void;
};
// Build dynamic columns related by category and product ids

const IncomingDataTable: React.FC<IncomingDataTable> = (props: any) => {
  const data = props.data;
  const isLoading = props.isLoading;
  const summary = props.summary;
  const dateColumns = props.dateColumns;
  const group = props.group;
  const rowKey = props.rowKey;
  const sortType = props.sortType;
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const refreshData = props.refreshData;

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          total_count: thousands(item.total_count),
        },
      };
    });
  };

  /**
   * @param list
   */

  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  // Build dynamic columns related by category and product ids
  const dynamicColumns =
    dateColumns && Array.isArray(dateColumns) && dateColumns.length
      ? dateColumns.map((column: any, index: number) => (
          <Column align="right" sortable resizable key={index}>
            <HeaderCell>
              <HeaderSummary
                title={column.headerTitle}
                summary={thousands(summary[column.dataKey])}
              />
            </HeaderCell>
            <CountsCell
              title={column.headerTitle}
              index={index}
              dataKey={column.dataKey}
              columnKeyPrefix={column.keyPrefix}
              group={group}
              label="counts"
            />
          </Column>
        ))
      : [];

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={65}
      loading={isLoading === true}
      data={sortData(data)}
      rowKey={rowKey}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
    >
      <Column width={200} align="center" fixed sortable resizable>
        <HeaderCell>DATA source id</HeaderCell>
        <Cell dataKey="data_source_id" />
      </Column>
      <Column width={200} fixed sortable resizable>
        <HeaderCell>Company name</HeaderCell>
        <Cell dataKey="company_name" />
      </Column>

      <Column width={200} fixed sortable resizable>
        <HeaderCell>Data source name</HeaderCell>
        <Cell dataKey="data_source_name" />
      </Column>
      <Column width={120} align="right" fixed sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Total Count"
            summary={thousands(summary.total_count)}
          />
        </HeaderCell>
        <Cell dataKey="total_count" />
      </Column>
      {dynamicColumns}
      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell groupBy={group} refreshData={refreshData} />
      </Column>
    </Table>
  );
};

const renderToolTipContent = (
  data: any,
  dataKey = "",
  index: any,
  rowData: any,
  start: any,
  end: any,
  context: any,
  group: any,
  triggerRef: any
) => {
  dataKey = dataKey ? dataKey + "_" : "";
  data[dataKey + "total_count"] = data.volume[index];

  function handleSelectMenu(eventKey: string, event: any) {
    triggerRef.current.hide();
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();

    const contents = new Map([
      [
        "hourly_volume",
        <HourlyVolume row_data={rowData} start={start} end={end} />,
      ],

      [
        "data_hygiene",
        <DataHygiene row_data={rowData} start={start} end={end} />,
      ],
      [
        "unique_records",
        <UniqueRecords row_data={rowData} start={start} end={end} />,
      ],
      [
        "source_urls",
        <SourceURLs row_data={rowData} start={start} end={end} />,
      ],
      [
        "source_path",
        <SourcePaths row_data={rowData} start={start} end={end} />,
      ],
      ["default", <span>Unknown menu item</span>],
    ]);
    if (group === 1)
      contents.set(
        "hourly_volume",
        <HourlyVolume row_data={rowData} start={start} end={end} />
      );

    const sizes = new Map([
      ["recent_records", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer

    context.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }
  return <Menu onSelect={handleSelectMenu} group={group} />;
};

const CountsCell = ({
  title,
  rowData,
  dataKey,
  columnKeyPrefix,
  group,
  index,
  label,
  ...props
}: any) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  let year = new Date().getFullYear();
  let array = title.split("/");
  let start = "0" + array[0] + "/" + "0" + array[1] + "/" + year;
  let current = parseInt(array[1]) + 1;
  let end = "0" + array[0] + "/" + "0" + current + "/" + year;
  const triggerRef = React.createRef();
  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();

    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["hourly_volume", <HourlyVolume row_data={rowData} />],
      ["recent_records", <RecentRecords row_data={rowData} />],
      ["data_hygiene", <DataHygiene row_data={rowData} />],
      ["unique_records", <UniqueRecords row_data={rowData} />],
      ["source_urls", <SourceURLs row_data={rowData} />],
      ["source_path", <SourcePaths row_data={rowData} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([["default", "md"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }
  //  const triggerRef = React.createRef();
  const content = renderToolTipContent(
    rowData,
    columnKeyPrefix,
    index,
    rowData,
    start,
    end,
    admediaryContext,
    group,
    triggerRef
  );

  return (
    <Cell {...props}>
      <Whisper
        triggerRef={triggerRef}
        controlId="control-id-focus"
        trigger={["focus", "click"]}
        enterable={true}
        placement="auto"
        speaker={
          <MenuPopover
            row={rowData}
            onSelect={handleSelectMenu}
            label="counts"
            group={group}
          >
            {content || rowData[dataKey]}
          </MenuPopover>
        }
      >
        <span>{rowData[dataKey]}</span>
      </Whisper>
    </Cell>
  );
};
const Menu = ({ onSelect, group, label, start }: any) => {
  return (
    <Dropdown.Menu onSelect={onSelect} group={group} start={start}>
      {group === 1 ? (
        <Dropdown.Item eventKey="hourly_volume">
          <Icon icon="clock-o" />
          Hourly Volume
        </Dropdown.Item>
      ) : null}
      {label === "counts" ? (
        <Dropdown.Item eventKey="recent_records">
          <Icon icon="envelope" />
          Recent Records
        </Dropdown.Item>
      ) : null}
      {label != "counts" ? (
        <Dropdown.Item eventKey="edit_source">
          <Icon icon="pencil" />
          Edit Source
        </Dropdown.Item>
      ) : null}
      <Dropdown.Item eventKey="data_hygiene">
        <Icon icon="leaf" />
        Data Hygiene
      </Dropdown.Item>
      <Dropdown.Item eventKey="unique_records">
        <Icon icon="star" />
        Unique Records
      </Dropdown.Item>
      <Dropdown.Item eventKey="source_urls">
        <Icon icon="globe" />
        Source URLs
      </Dropdown.Item>
      <Dropdown.Item eventKey="source_path">
        <Icon icon="globe2" />
        Source Paths
      </Dropdown.Item>
    </Dropdown.Menu>
  );
};

const MenuPopover = ({ onSelect, row, group, label, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} group={group} row={row} label={label} />
  </Popover>
);

type TableWhisper = {
  row: any;
  refreshData?: () => void;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const item = props.row;
  const refreshData = props.refreshData;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const mainFormRef = React.createRef<FormInstance>();
  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();

    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      [
        "edit_source",
        <EditSource
          dataSourceId={item.data_source_id}
          formRef={mainFormRef}
          refreshData={refreshData}
        />,
      ],
      ["recent_records", <RecentRecords row_data={item} />],
      ["data_hygiene", <DataHygiene row_data={item} />],
      ["unique_records", <UniqueRecords row_data={item} />],
      ["source_urls", <SourceURLs row_data={item} />],
      ["source_path", <SourcePaths row_data={item} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([["default", "md"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={item} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, refreshData, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} refreshData={refreshData} />
    </Cell>
  );
};

export default IncomingDataTable;

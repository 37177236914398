import React, { useState } from "react";
import { Query, ApiOperations, ApiParameters } from "../@Utils/AdmediaryApi";

/**
 * React Hook operation as a wrapper for making API requests
 *
 * @param operation
 * @param options
 */
const useAdmediaryApi = (
  operation: keyof typeof ApiOperations,
  options?: ApiParameters
) => {
  // ---- State
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  // ---- API
  const state: string = JSON.stringify({ operation, options });

  // Fire request to the API
  React.useEffect(() => {
    (async () => {
      setError(null);
      try {
        setIsLoading(true);
        const results: any = await Query(operation, options);
        setData(results.data);
        if (results.hasOwnProperty("config")) {
          setConfig(results.config);
        }
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [state]);

  return [data, isLoading, error, config];
};

export default useAdmediaryApi;

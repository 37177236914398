import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractCapForm from "./BuyerContractCapForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format, parseISO } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractCapListType = {
  contractId: number;
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractCapList: React.FC<BuyerContractCapListType> = ({
  contractId = 0,
  height = 650,
  autoHeight = true,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [defaultIntervalList, setDefaultIntervalList] = React.useState<any[]>(
    []
  );
  const mainFormRef = React.createRef<FormInstance>();
  const [affIdRequired, setAffIdRequired] = React.useState();
  const [startDateRequired, setStartDateRequired] = React.useState();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          start_date: item.start_date
            ? format(parseISO(item.start_date), "MM-dd-y")
            : "",
          end_date: item.end_date
            ? format(parseISO(item.end_date), "MM-dd-y")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const getCapIntervalTitle = (interval: number) => {
    const intervals = new Map([
      [1, "Daily"],
      [7, "Weekly"],
      [30, "Monthly"],
      [0, "Lifetime"],
      [-1, "Custom"],
    ]);

    return intervals.has(interval) ? intervals.get(interval) : null;
  };

  const getAffiliateById = (id: number) => {
    const affiliate = config.affiliates.find(
      (affiliate: any) => parseInt(affiliate.affiliate_id) === id
    );
    let name = affiliate ? affiliate.affiliate_name : "";

    name = id === 0 ? "Default" : name;

    return `${id} - ${name}`;
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const [data, isLoading] = useAdmediaryApi("buyer_contract_caps", params);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    if (Array.isArray(data.default)) {
      data.affiliate_id = data.default.length ? 0 : data.affiliate_id;

      if (!data.default.length && data.affiliate_id === 0) {
        setAffIdRequired(true);
        return;
      } else {
        setAffIdRequired(false);
      }
    }

    if (
      data.buyer_contract_cap_cap_interval === -1 &&
      data.cap_start_date === null
    ) {
      setStartDateRequired(true);
      return;
    } else {
      setStartDateRequired(false);
    }

    (async () => {
      try {
        await Query("buyer_contract_cap_update", data);
        Notification.success({
          title: "Success",
          description:
            "Affiliate Cap has been " + (data.cap_id ? "updated" : "added"),
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  // React.useEffect(() => {
  //   if (!isLoading) {
  //     if (Array.isArray(data)) {
  //       const clonedData = _.cloneDeep(data);
  //
  //       const defaultCaps = clonedData.filter(
  //         (item: any) => item.affiliate_id === 0
  //       );
  //
  //       if (defaultCaps.length) {
  //         const defaultCapList = defaultCaps.map((item: any) => {
  //           return item.cap_interval;
  //         });
  //
  //         setDefaultIntervalList([...defaultCapList]);
  //       }
  //     }
  //   }
  // }, [data, isLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      if (Array.isArray(data)) {
        setDefaultIntervalList([]);
        const clonedData = _.cloneDeep(data);

        const defaultCaps = clonedData.filter(
          (item: any) => item.affiliate_id === 0
        );

        if (defaultCaps.length) {
          const defaultCapList = defaultCaps.map((item: any) => {
            return item.cap_interval;
          });
          // console.log('defaultCaps', defaultCapList)
          //   setDefaultIntervalList([...defaultCapList]);
          setDefaultIntervalList(defaultCapList);
        }
      }
    }
  }, [data, isLoading]);

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_cap_remove", { cap_id: itemId });
        Notification.success({
          title: "Success",
          description: "Affiliate Cap has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.cap_id);
    setDefaultIntervalList([]);
    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem({
      cap_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  console.log(formValue);

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={5}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
          >
            Add Cap
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={100} align={"center"} fixed>
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            removeHandler={handleRemoveActionClick}
          />
        </Column>
        <Column width={250} sortable resizable>
          <HeaderCell>Affiliate</HeaderCell>
          <Cell dataKey="affiliate_id">
            {(rowData: any) => getAffiliateById(rowData.affiliate_id)}
          </Cell>
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Cap</HeaderCell>
          <Cell dataKey="cap" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Interval</HeaderCell>
          <Cell dataKey="cap_interval">
            {(rowData: any) => getCapIntervalTitle(rowData.cap_interval)}
          </Cell>
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>Start Date</HeaderCell>
          <Cell dataKey="start_date" />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>End Date</HeaderCell>
          <Cell dataKey="end_date" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Cap?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.cap_id > 0 ? "Edit" : "Add") + " Cap"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <BuyerContractCapForm
          capId={selectedItem ? selectedItem.cap_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
          defaultIntervalList={defaultIntervalList}
          affIdRequired={affIdRequired}
          startDateRequired={startDateRequired}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractCapList;

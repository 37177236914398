import React from "react";
import { Dropdown, Icon, IconButton, Popover, Table, Whisper } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { PercentCell, CurrencyCell, HeaderSummary } from "../Table";
import { currency, percent } from "../../@Utils/Format";
import { sort } from "../../@Utils/Sorting";
import moment from "moment";
import EsignStatusDrawer from "../Drawers/EsignStatus";
import EsignEPCDrawer from "../Drawers/ESignEPC";

const { Column, HeaderCell, Cell } = Table;

/**
 * EsignDocAffiliatesTable is a separated component to prevent a scroll-to-top bug
 */
type EsignDocAffiliatesTableProps = {
  data: any;
  isLoading: boolean;
  sortType: string;
  sortColumn: string;
  summary: any;
  onSortColumn: (column: any, type: any) => void;
  type: string;
};

const EsignDocAffiliatesTable: React.FC<EsignDocAffiliatesTableProps> = (
  props: any
) => {
  const data = props.data;
  const summary = props.summary;
  const isLoading = props.isLoading;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const type = props.type;

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          date_created: item.date_created
            ? moment(item.date_created).local().format("MM-DD-Y HH:mm")
            : "",
          date_modified: item.date_modified
            ? moment(item.date_modified).local().format("MM-DD-Y HH:mm")
            : "",
          paid_amount: parseInt(item.paid_amount),
          non_retainer_received_amount: parseInt(
            item.non_retainer_received_amount
          ),
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={Array.isArray(data) ? sortData(data) : []}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
    >
      {type === "affiliate" ? (
        <Column width={100} align="right" sortable resizable>
          <HeaderCell>Affiliate ID</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
      ) : (
        <Column width={100} align="right" sortable resizable>
          <HeaderCell>Buyer ID</HeaderCell>
          <Cell dataKey="buyer_id" />
        </Column>
      )}
      {type === "affiliate" ? (
        <Column width={125} align="right" sortable resizable>
          <HeaderCell>Affiliate Name</HeaderCell>
          <Cell dataKey="affiliate_name" />
        </Column>
      ) : (
        <Column width={125} align="right" sortable resizable>
          <HeaderCell>Buyer Name</HeaderCell>
          <Cell dataKey="buyer_name" />
        </Column>
      )}
      {type === "affiliate" ? (
        <Column width={125} align="right" sortable resizable>
          <HeaderCell>Affiliate Manager</HeaderCell>
          <Cell dataKey="affiliate_manager" />
        </Column>
      ) : (
        ""
      )}
      <Column width={100} align="right" sortable resizable>
        <HeaderCell>Product ID</HeaderCell>
        <Cell dataKey="product_id" />
      </Column>
      <Column width={185} align="right" sortable resizable>
        <HeaderCell>Product Description</HeaderCell>
        <Cell dataKey="product_description" />
      </Column>
      {type === "affiliate" ? (
        <Column width={100} align="left" sortable resizable>
          <HeaderCell>
            <HeaderSummary title="Clicks" summary={summary.clicks} />
          </HeaderCell>
          <Cell dataKey="clicks" />
        </Column>
      ) : (
        ""
      )}

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title={`${type === "affiliate" ? "Total Leads" : "Accepted Leads"}`}
            summary={summary.total_leads}
          />
        </HeaderCell>
        <Cell dataKey="total_leads" />
      </Column>
      {type === "affiliate" ? (
        <Column align="right" sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Form Fill %"
              summary={percent(summary.form_percent)}
            />
          </HeaderCell>
          <PercentCell dataKey="form_percent" />
        </Column>
      ) : (
        ""
      )}

      <Column width={124} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Created Docs" summary={summary.presented} />
        </HeaderCell>
        <Cell dataKey="presented" />
      </Column>
      <Column width={125} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Completed Docs" summary={summary.completed} />
        </HeaderCell>
        <Cell dataKey="completed" />
      </Column>
      <Column width={125} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Completed %"
            summary={percent(summary.completed_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="completed_percent" />
      </Column>
      {type === "affiliate" ? (
        <Column width={175} align="right" sortable resizable>
          <HeaderCell>
            <HeaderSummary
              title="Non-Approved Leads"
              summary={summary.non_retainer_count}
            />
          </HeaderCell>
          <Cell dataKey="non_retainer_count" />
        </Column>
      ) : (
        ""
      )}
      <Column width={135} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Total Cost (CPC/CPA)"
            summary={currency(summary.paid_amount)}
          />
        </HeaderCell>
        <CustomCurrencyCell dataKey="paid_amount" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Document Revenue"
            summary={currency(summary.doc_completed_rate)}
          />
        </HeaderCell>
        <CustomCurrencyCell dataKey="doc_completed_rate" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Ping Tree Revenue"
            summary={currency(summary.non_retainer_received_amount)}
          />
        </HeaderCell>
        <CustomCurrencyCell dataKey="non_retainer_received_amount" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Profit" summary={currency(summary.profit)} />
        </HeaderCell>
        <CustomCurrencyCell dataKey="profit" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Loss" summary={currency(summary.loss)} />
        </HeaderCell>
        <CustomCurrencyCell dataKey="loss" />
      </Column>

      <Column width={150} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell />
      </Column>
    </Table>
  );
};

const CustomCurrencyCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  ...props
}: any) => {
  const style = rowData.loss < 0 ? "red" : "black";

  return (
    <CurrencyCell
      rowData={rowData}
      dataKey={dataKey}
      {...props}
      style={{ color: style }}
    />
  );
};

const Menu = ({ onSelect, rowData }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="doc_status">Doc Status List</Dropdown.Item>
    <Dropdown.Item eventKey="epc">EPC</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, rowData, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} rowData={rowData} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const rowData = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const handleSelectMenu = (eventKey: string, event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["doc_status", <EsignStatusDrawer row_data={rowData} />],
      ["epc", <EsignEPCDrawer row_data={rowData} />],
    ]);
    const sizes = new Map([["epc", "lg"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  };

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={rowData} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, checked, summary, ...props }: any) => {
  rowData.checked = checked;
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} />
    </Cell>
  );
};

export default EsignDocAffiliatesTable;

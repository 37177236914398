import React, { ReactElement } from "react";

import { ResponsivePie as NivoPie } from "@nivo/pie";
import { Loader } from "rsuite";

type RevenuePieGraph = {
  profit: any | undefined;
  costs: any | undefined;
};

const RevenuePieGraph: React.FC<RevenuePieGraph> = (props) => {
  if (props.profit === undefined || props.costs === undefined) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const total: number = parseFloat(props.profit) + parseFloat(props.costs);

  const formatValue = (value: string, total: number): string => {
    const percent: number = Math.round((parseInt(value) / total) * 100);
    return `${percent}%`;
  };

  const defaultData = [
    {
      id: "profit",
      label: "Profit",
      sliceLabel: formatValue(props.profit, total),
      value: parseFloat(props.profit),
    },
    {
      id: "costs",
      label: "Costs",
      sliceLabel: formatValue(props.costs, total),
      value: parseFloat(props.costs),
    },
  ];

  const tooltip = (item: any): ReactElement => {
    return (
      <div style={{ width: 175 }}>{`${item.label}: ${item.value.toLocaleString(
        undefined,
        {
          style: "currency",
          currency: "USD",
        }
      )}`}</div>
    );
  };

  return (
    <NivoPie
      data={defaultData}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0}
      padAngle={0.7}
      cornerRadius={0}
      colors={[
        "#37d199",
        "#eaeaea",
        "#8abac4",
        "#da6d42",
        "#315263",
        "#ecae5b",
      ]}
      sortByValue
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabel={(e) => `${e.label}`}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={-5}
      radialLabelsLinkDiagonalLength={8}
      radialLabelsLinkHorizontalLength={8}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableSlicesLabels={true}
      sliceLabel="sliceLabel"
      isInteractive={true}
      tooltip={tooltip}
    />
  );
};

export default RevenuePieGraph;

import React from "react";
import { Nav, Navbar, Button } from "rsuite";
import DateRangePicker from "rsuite/lib/DateRangePicker";
import {
  format,
  subDays,
  subMonths,
  startOfMonth,
  lastDayOfMonth,
} from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FirebaseAuthContext } from "../../@Context/FirebaseAuthContext";
import { NavLink, useLocation } from "react-router-dom";
import Verticals from "../DropDowns/Verticles";
import Products from "../DropDowns/Products";
import Buyers from "../DropDowns/Buyers";
import BuyerContracts from "../DropDowns/BuyerContracts";

const TopNavbar: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const Firebase = React.useContext(FirebaseAuthContext);
  const location = useLocation();
  const [dateRange, setDateRage] = React.useState(60);
  const approvedRoutes = [
    "/direct-posts/post-summary",
    "/direct-posts/post-leads",
    "/leads/detail-report",
    "/affiliates/tree-report",
    "/affiliates/esign-affiliates",
    "/cake/cake-offers",
    "/buyers/tree-report",
    "/buyers/response-times",
    "/buyers/contract-performance",
    "/buyers/buyer-budgets",
    // "/leads/filtered"
    "/leads",
    "/flow/tree-report",
    "/products/buy-hold",
    "/products/pixel-percentage",
    "/products/data-validation",
    "/products/performance",
    "/products/esign-doc",
    "/products/gp-report",
    "/accounting/prepayments",
    "/data/outgoing-report",
    "/data/data-report-incoming",
    "/data/sms-delivery",
    "/buyers/buyer-daily-caps",
  ];

  const navTabs = Admediary.activeNavGroup;

  const hideDateRoutes = [
    "/buyers/buyer-budgets",
    "/reports/call-programs",
    "/data/voice-campaign",
    "/products/products",
    "/data/services",
    "/products/categories",
  ];
  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setDates = (dates: any[]) => {
    Admediary.setDateRange({
      start: dates[0],
      end: dates[1],
    });
  };

  const runReport = () => {
    Admediary.setRun(true);
  };

  /**
   * Custom function for formatting date ranges. Idea is to make
   * it more human readable.
   */
  const formatDates = (value: any, dateFormat: string) => {
    if (!value[0] || !value[1]) {
      return null;
    }
    if (value[0].toString() === value[1].toString()) {
      return (
        <span style={{ paddingRight: 10 }}>
          {format(value[0], "LLL do, yyyy")}
        </span>
      );
    }
    return (
      <span style={{ paddingRight: 10 }}>
        From {format(value[0], "LLL do, yyyy")} to{" "}
        {format(value[1], "LLL do, yyyy")}
      </span>
    );
  };

  const last_month = subMonths(new Date(), 1);

  const logoutUser = () => {
    Firebase.logout();
    window.location.reload();
  };

  React.useEffect(() => {
    if (location.pathname === "/leads/disposition-lookup") {
      setDates([subDays(new Date(), 7), subDays(new Date(), 0)]);
    }
  }, []);

  return (
    <Navbar
      className="top-navbar"
      appearance="subtle"
      style={{ background: "#fff" }}
    >
      <Navbar.Body>
        {!hideDateRoutes.includes(location.pathname) ? (
          <Nav>
            <DateRangePicker
              size="md"
              renderValue={formatDates}
              block={true}
              value={[Admediary.start, Admediary.end]}
              defaultValue={[Admediary.start, Admediary.end]}
              onChange={setDates}
              cleanable={false}
              disabledDate={
                location.pathname === "/data/data-report-incoming"
                  ? DateRangePicker.afterToday()
                  : location.pathname === "/buyers/tree-report"
                  ? DateRangePicker.allowedMaxDays(100)
                  : DateRangePicker.allowedMaxDays(60)
              }
              ranges={[
                {
                  label: "Yesterday",
                  value: [subDays(new Date(), 1), subDays(new Date(), 1)],
                },
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "Last 7 days",
                  value: [subDays(new Date(), 7), subDays(new Date(), 1)],
                },
                {
                  label: "This Month",
                  value: [startOfMonth(new Date()), new Date()],
                },
                {
                  label: "Last Month",
                  value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
                },
              ]}
            />
          </Nav>
        ) : (
          ""
        )}

        <Nav>
          <Verticals />
        </Nav>
        <Nav>
          <Products />
        </Nav>
        <Nav>
          <Buyers />
        </Nav>
        <Nav>
          <BuyerContracts />
        </Nav>
        <Nav>
          {approvedRoutes.includes(location.pathname) ? (
            <Button
              onClick={runReport}
              style={{
                lineHeight: "25px",
                background: "rgb(2, 27, 59)",
                color: "rgb(255, 255, 255)",
                height: "35px",
                textAlign: "center",
              }}
              size="sm"
              disabled={Admediary.runReport}
              loading={Admediary.runReport}
            >
              Run Report
            </Button>
          ) : (
            ""
          )}
        </Nav>
        <Nav pullRight>
          <Button
            // onClick={() => {
            //   Firebase.logout();
            // }}
            onClick={logoutUser}
            style={{ lineHeight: "36px" }}
            size="sm"
          >
            Sign Out
          </Button>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

export default TopNavbar;

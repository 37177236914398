import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import IncomingDataTable from "./IncomingDataTable";
import SelectPicker from "rsuite/lib/SelectPicker";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";
import { thousands } from "../../@Utils/Format";
import { format } from "date-fns";

const IncomingReportData: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = 1;
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const startDate = Admediary.start;
  const endDate = Admediary.end;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    group_by: group,
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "report_incoming_volume_select",
    params
  );

  const groupByList = [
    { value: 1, label: "Day" },
    { value: 7, label: "Week" },
    { value: 30, label: "Month" },
  ];

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };
  const getDateColumns = (
    data: any[],
    start: Date,
    end: Date,
    group: number = 1
  ) => {
    const current = new Date(startDate.getTime()); // clone date
    const dates: any = [];

    if (
      Array.isArray(data) &&
      data.length &&
      data[0].volume &&
      Array.isArray(data[0].volume)
    ) {
      data[0].volume.forEach((volumeItem: any) => {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        group === 30
          ? current.setMonth(current.getMonth() + 1)
          : current.setDate(current.getDate() + group);
      });
    } else {
      do {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        group === 30
          ? current.setMonth(current.getMonth() + 1)
          : current.setDate(current.getDate() + group);
      } while (current <= end);
    }

    return dates.map((current: Date) => {
      const currentDay = current.getDate();
      const currentMonth = current.getMonth() + 1;
      const currentYear = current.getFullYear().toString().substr(-2);
      const dataIndexPrefix = `_${currentMonth}_${currentDay}`;

      return {
        headerTitle: `${currentMonth}/${
          group === 30 ? currentYear : currentDay
        }`,
        dataKey: dataIndexPrefix + "_total_count",
        keyPrefix: dataIndexPrefix,
      };
    });
  };

  const getExportData = (data: any) => {
    const dateColumns = getDateColumns(
      data,
      Admediary.start,
      Admediary.end,
      group
    );

    // Clean, format and reorder by fields with right headers
    const exportData = data.map((item: any) => {
      const row: any = {
        "Data Source ID": item.data_source_id,
        "Company Name": item.company_name,
        "Data Source Name": item.data_source_name,
        "Total Count": thousands(item.total_count),
      };

      // Add date columns
      dateColumns.forEach((column: any, index: any) => {
        row[column.headerTitle] = item.volume[index];
      });

      return row;
    });

    return exportData;
  };

  const getSummary = (data: any[]) => {
    const dateColumns = getDateColumns(
      data,
      Admediary.start,
      Admediary.end,
      group
    );
    const transformData = data.map((item: any) => {
      const row: any = {
        data_source_id: item.data_source_id,
        company_name: item.company_name,
        data_source_name: item.data_source_name,
        total_count: item.total_count,
      };

      // Add date columns
      dateColumns.forEach((column: any, index: any) => {
        row[column.headerTitle] = item.volume[index];
      });

      return row;
    });

    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = ["total_count"];
    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = transformData.map((item: any): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    dateColumns.forEach((column: any, index: any) => {
      let values = transformData.map((item: any): number => {
        return parseFloat(item[column.headerTitle]);
      });
      summary[column.dataKey] = values.reduce(summarizer, 0) || 0;
    });

    return summary;
  };

  if (!isLoading && data && Array.isArray(data) && data.length) {
    data.forEach(function (serviceData, index) {
      const current = new Date(startDate.getTime()); // clone date
      const additionalIndices = ["total_count"];

      serviceData.volume.forEach(function (groupedData: any) {
        const currentDay = current.getDate();
        const currentMonth = current.getMonth() + 1;
        const dataIndexPrefix = `_${currentMonth}_${currentDay}`;

        // Add current volume data to grid data
        additionalIndices.forEach(function (index) {
          serviceData[dataIndexPrefix + "_" + index] = Number(
            groupedData[index]
          );
        });

        // Increase current date by date range
        group === 30
          ? current.setMonth(currentMonth)
          : current.setDate(currentDay + group);
      });

      //delete serviceData["volume"];
      data[index] = serviceData;
    });
  }

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any[], search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toString().toLowerCase().includes(search);
    const fields = ["data_source_id", "company_name", "data_source_name"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  React.useEffect(() => {
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    if (startMonth !== endMonth) {
      setGroup(30);
    } else {
      setGroup(1);
    }
  }, [startDate, endDate]);

  const filteredData = Array.isArray(data) ? filter(data, searchQuery) : [];
  const summary = getSummary(filteredData);

  return (
    <>
      <Title title="Volume Incoming Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Group By"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="value"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `incoming_data_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                getExportData(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <IncomingDataTable
        data={filteredData}
        isLoading={isLoading === true}
        summary={summary}
        dateColumns={getDateColumns(
          filteredData,
          Admediary.start,
          Admediary.end,
          group
        )}
        group={group}
        rowKey={rowKey}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
    </>
  );
};

export default IncomingReportData;

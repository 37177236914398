import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import {
  CurrencyCell,
  NumberCell,
  ParentCell,
  PercentCell,
  HeaderSummary,
} from "../Table";
import { sortTree } from "../../@Utils/Sorting";
import FilterReasons from "../Drawers/FilterReasons";
import PostedLeads from "../Drawers/PostedLeads";
import SubID from "../Drawers/SubID";
import Responses from "../Drawers/Responses";
import { currency, percent, thousands } from "../../@Utils/Format";
import { couldStartTrivia } from "typescript";

const { Column, HeaderCell, Cell } = Table;

/**
 * PostSummaryTable is a separated component to prevent a scroll-to-top bug
 */
type PostSummaryTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  checked: boolean;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const PostSummaryTable: React.FC<PostSummaryTableProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const checked = props.checked;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const formatPrice = (cost: any) => {
    const price = parseFloat(cost);
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          cost: formatPrice(item.cost),
          profit: isNaN(item.profit) ? 0 : formatPrice(item.profit),
          accept_percent: Number(item.accept_percent),
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sortTree(list, sort_column, sort_type) : list
    );
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={Array.isArray(data) ? sortData(data) : []}
      checked={checked}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={300} fixed sortable resizable>
        {checked ? (
          <HeaderCell>Affiliate ID</HeaderCell>
        ) : (
          <HeaderCell>Affiliate ID</HeaderCell>
        )}
        <ParentCell dataKey="affiliate_id" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate Name</HeaderCell>
        <Cell dataKey="affiliate_name" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell title="Campaign ID">Campaign ID</HeaderCell>
        <Cell dataKey="campaign_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell title="Category ID">Category ID</HeaderCell>
        <Cell dataKey="category_id" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell title="Product Name">Category Name</HeaderCell>
        <Cell dataKey="category_name" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Description</HeaderCell>
        <Cell dataKey="campaign_desc" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Total" summary={summary.total_count} />
        </HeaderCell>
        <NumberCell dataKey="total_count" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Accepted"
            summary={thousands(summary.accepted_count)}
          />
        </HeaderCell>
        <NumberCell dataKey="accepted_count" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Accept %"
            summary={percent(summary.accept_percent)}
          />
        </HeaderCell>
        <PercentCell dataKey="accept_percent" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Cost" summary={currency(summary.cost)} />
        </HeaderCell>
        <CurrencyCell dataKey="cost" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="CPL" summary={currency(summary.cpl)} />
        </HeaderCell>
        <CurrencyCell dataKey="cpl" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Revenue" summary={currency(summary.revenue)} />
        </HeaderCell>
        <CurrencyCell dataKey="revenue" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Rate" summary={currency(summary.rate)} />
        </HeaderCell>
        <CurrencyCell dataKey="rate" />
      </Column>

      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Profit" summary={currency(summary.profit)} />
        </HeaderCell>
        <CurrencyCell dataKey="profit" />
      </Column>

      <Column width={150} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell checked={checked} summary={summary} />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row, summary }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
    {row.checked && row.sid1 ? (
      <Dropdown.Item eventKey="sub_id">SubID </Dropdown.Item>
    ) : null}

    {row.disposition === "accepted" || row.disposition === "rejected" ? (
      <Dropdown.Item eventKey="view_responses">Responses </Dropdown.Item>
    ) : null}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, summary, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} checked={checked} summary={summary} />
  </Popover>
);

type TableWhisper = {
  row: any;
  summary: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const summary = props.summary;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  row.checked = props.row.checked ? props.row.checked : false;
  function handleSelectMenu(eventKey: string, event: any) {
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["filter_reasons", <FilterReasons row_data={row} />],
      ["view_leads", <PostedLeads row_data={row} />],
      ["sub_id", <SubID row_data={row} summary={summary} />],
      ["view_responses", <Responses row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, checked, summary, ...props }: any) => {
  rowData.checked = checked;
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} summary={summary} />
    </Cell>
  );
};

export default PostSummaryTable;

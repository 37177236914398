import React, { useState } from "react";
import { Checkbox, FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import PostSummaryTable from "./PostSummaryTable";
import { format } from "date-fns";
import Title from "../Title";
import SelectPicker from "rsuite/lib/SelectPicker";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "total_count",
    "accepted_count",
    "accept_percent",
    "cost",
    "cpl",
    "profit",
    "revenue",
    "rate",
  ];

  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      ...item,
      ...{
        entry_date: item.entry_date
          ? format(item.entry_date, "MM-dd-Y HH:mm")
          : "",
        transaction_date: item.transaction_date
          ? format(item.transaction_date, "MM-dd-Y HH:mm")
          : "",
      },
    };
  });
}

const addCalculatedValues = (row: any) => {
  row.total_count = isNaN(parseFloat(row.total_count))
    ? 0
    : parseFloat(row.total_count);
  row.accepted_count = isNaN(parseFloat(row.accepted_count))
    ? 0
    : parseFloat(row.accepted_count);

  row.revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);

  row.cost = isNaN(parseFloat(row.cost)) ? 0 : parseFloat(row.cost);

  row.rate = isNaN(parseFloat(row.rate)) ? 0 : parseFloat(row.rate);

  return {
    ...row,
    ...{
      accept_percent:
        row.total_count !== 0
          ? (row.accepted_count / row.total_count) * 100
          : 0,
      cpl: row.accepted_count !== 0 ? row.cost / row.accepted_count : 0,
      profit: Number(row.revenue - row.cost),
    },
  };
};

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const PostSummary: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [checked, setChecked] = useState(false);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [affiliateFilterId, setAffiliateFilterId] = React.useState(0);
  const config: any = Admediary.config;
  const handleChecked = (content: any): void => {
    setChecked(!checked);
  };

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category ? Admediary.category : 0,
    product_id: Admediary.product,
    buyer_id: Admediary.buyer,
    add_subid: checked ? 1 : 0,
    affiliate_id: affiliateFilterId,
    sid1: "",
    sid2: "",
    detail_by: "disposition",
    data_type: "date_sold",
  };

  // const [data, isLoading] = useAdmediaryApi("leads_post_summary", params);
  const [data, isLoading] = useAdmediaryApiManual("leads_post_summary", params);
  const [affiliateListFetch, isLoadingAffiliates] = useAdmediaryApi(
    "affiliates"
  );

  const affiliateList =
    !isLoadingAffiliates && affiliateListFetch !== null
      ? Array.isArray(affiliateListFetch)
        ? affiliateListFetch.map((val) => {
            return {
              ...val,
              label: `(${val.affiliate_id}) ${val.affiliate_name}`,
            };
          })
        : []
      : [];

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setCategory = (data: any) => {
    Admediary.setCategory(data);
  };

  return (
    // borderBottom:"solid grey 1px", , marginBottom:"1%"
    <>
      <Title title="Post Summary Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={2}>
          <Checkbox onChange={handleChecked} style={{ float: "left" }}>
            SubID
          </Checkbox>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4}>
          <SelectPicker
            placeholder="Leads Category"
            data={config.lead_categories}
            labelKey="category_name"
            valueKey="category_id"
            onChange={setCategory}
            value={Admediary.category}
            style={{ float: "left", marginLeft: "1%" }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={13}>
          <SelectPicker
            size="md"
            placeholder="Search Affiliates"
            data={affiliateList}
            valueKey="affiliate_id"
            labelKey="label"
            style={{ marginRight: 15, width: "250px" }}
            value={affiliateFilterId}
            searchable
            onChange={(val: any) => {
              setAffiliateFilterId(val);
            }}
            disabled={isLoadingAffiliates === true}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `post_summary_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <PostSummaryTable
        data={treeData}
        checked={checked}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default PostSummary;

import React from "react";
import { Drawer, Icon, Popover, Table, Whisper } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

import { ToolTipCell } from "../Table";
const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type VoiceCampaignLogEvent = {
  row_data: any;
};

const VoiceCampaignLogEvent: React.FC<VoiceCampaignLogEvent> = ({
  row_data,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const log_id =
    row_data.log_id || getFieldValueOrParentValue(row_data, "log_id");
  const params = {
    log_id: log_id ? log_id : 0,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const [data, isLoading] = useAdmediaryApi("voice_campaign_log_event", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Voice Log Events
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Table
          height={750}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={240} fixed sortable resizable>
            <HeaderCell>Voice SID</HeaderCell>
            <Cell dataKey="voice_sid" />
          </Column>
          <Column sortable resizable width={200}>
            <HeaderCell>Parent SID</HeaderCell>
            <ToolTipCell dataKey="parent_voice_sid" />
          </Column>
          <Column sortable resizable width={120}>
            <HeaderCell>Call Status</HeaderCell>
            <ToolTipCell dataKey="call_status" />
          </Column>
          <Column sortable resizable width={200}>
            <HeaderCell>Post Data</HeaderCell>
            <Cell dataKey="post_data">
              {(rowData: any) => {
                const value = rowData.post_data;
                const speaker = (
                  <Popover>
                    <div style={{ height: 100, width: 400, overflow: "auto" }}>
                      {value}
                    </div>
                  </Popover>
                );

                return (
                  <Whisper
                    trigger="hover"
                    placement="leftStart"
                    enterable
                    speaker={speaker}
                  >
                    <span>{value}</span>
                  </Whisper>
                );
              }}
            </Cell>
          </Column>
          <Column sortable resizable width={175}>
            <HeaderCell>Get Data</HeaderCell>
            <Cell dataKey="get_data">
              {(rowData: any) => {
                const value = rowData.get_data;
                const speaker = (
                  <Popover>
                    <div style={{ height: 100, width: 400, overflow: "auto" }}>
                      {value}
                    </div>
                  </Popover>
                );

                return (
                  <Whisper
                    trigger="hover"
                    placement="leftStart"
                    enterable
                    speaker={speaker}
                  >
                    <span>{value}</span>
                  </Whisper>
                );
              }}
            </Cell>
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default VoiceCampaignLogEvent;

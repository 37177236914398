import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import OutgoingList from "./OutgoingList";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";
import axios from "axios";
import Config from "../../@Config/Admediary";
import { thousands } from "../../@Utils/Format";

const OutgoingReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = 1;
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [dataProvider, setDataProvider] = React.useState(0);
  const [dataProviders, setDataProviders] = React.useState([]);
  const [serviceProvider, setServiceProvider] = React.useState(0);
  const [serviceProviders, setServiceProviders] = React.useState([]);
  const startDate = Admediary.start;

  const params = {
    start_date: startDate,
    end_date: Admediary.end,
    source_company: dataProvider,
    target_company: serviceProvider,
    group_by_days: group,
  };

  // const [data, isLoading] = useAdmediaryApi("outgoing_volumes", params);
  const [data, isLoading] = useAdmediaryApiManual("outgoing_volumes", params);
  // console.log(data);
  const groupByList = [
    { value: 1, label: "Day" },
    { value: 7, label: "Week" },
    { value: 30, label: "Month" },
  ];

  const formatData = (list: any) => {
    list.duplicate_count = isNaN(parseFloat(list.duplicate_count))
      ? 0
      : parseFloat(list.duplicate_count);
    const queuedPercent = (list.queued_count / list.total_count) * 100;
    const failurePercent = (list.failure_count / list.total_count) * 100;

    return {
      ...list,
      duplicate_count: list.duplicate_count,
      queued_percent: queuedPercent,
      failed_percent: failurePercent,
    };
  };

  function addCalculatedValuesRecursively(list: any) {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = addCalculatedValuesRecursively(row.children);
      }

      return formatData(row);
    });
  }

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const getDateColumns = (
    data: any[],
    start: Date,
    end: Date,
    group: number = 1
  ) => {
    const current = new Date(startDate.getTime()); // clone date
    const dates: any = [];

    if (
      Array.isArray(data) &&
      data.length &&
      data[0].volume &&
      Array.isArray(data[0].volume)
    ) {
      data[0].volume.forEach((volumeItem: any) => {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        group === 30
          ? current.setMonth(current.getMonth() + 1)
          : current.setDate(current.getDate() + group);
      });
    } else {
      do {
        dates.push(new Date(current.getTime())); // Clone current date

        // Increase current date by date range
        group === 30
          ? current.setMonth(current.getMonth() + 1)
          : current.setDate(current.getDate() + group);
      } while (current <= end);
    }

    return dates.map((current: Date) => {
      var endDate = new Date(current.getTime());
      group === 30
        ? endDate.setMonth(endDate.getMonth() + 1)
        : group === 7
        ? endDate.setDate(endDate.getDate() + group - 1) //Week date in old report only returns up to 6 days
        : (endDate = new Date(current.getTime() + group * 86400000 - 1));

      const currentDay = current.getDate();
      const currentMonth = current.getMonth() + 1;
      const currentYear = current.getFullYear().toString().substr(-2);
      const dataIndexPrefix = `_${currentMonth}_${currentDay}`;

      return {
        headerTitle: `${currentMonth}/${
          group === 30 ? currentYear : currentDay
        }`,
        dataKey: dataIndexPrefix + "_total_count",
        keyPrefix: dataIndexPrefix,
        start: current,
        end: endDate,
      };
    });
  };

  const getExportData = (data: any) => {
    const dateColumns = getDateColumns(
      data,
      Admediary.start,
      Admediary.end,
      group
    );

    // Clean, format and reorder by fields with right headers
    const exportData = data.map((item: any) => {
      const row: any = {
        "Service ID": item.service_id,
        "Source Company": item.source_company,
        "Data Source": item.data_source_name,
        "Target Company": item.target_company,
        Service: item.service_name,
        "Total Count": thousands(item.total_count),
        "Success Count": thousands(item.success_count),
        "Dupe Count": thousands(item.duplicate_count),
        "Failure Count": thousands(item.failure_count),
      };

      // Add date columns
      dateColumns.forEach((column: any) => {
        row[column.headerTitle] = item[column.dataKey];
      });

      return row;
    });

    return exportData;
  };

  const getSummary = (data: any[]) => {
    const dateColumns = getDateColumns(
      data,
      Admediary.start,
      Admediary.end,
      group
    );

    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = [
      "total_count",
      "success_count",
      "duplicate_count",
      "failure_count",
      "queued_count",
    ];
    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = data.map((item: any): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    dateColumns.forEach((column: any) => {
      let values = data.map((item: any): number => {
        return parseFloat(item[column.dataKey]);
      });
      summary[column.dataKey] = values.reduce(summarizer, 0) || 0;
    });

    return summary;
  };

  // TODO Rewrite it using general helper function
  // Getting data for drop downs
  const API = axios.create({});
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });
  React.useEffect(() => {
    const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
    API.get(`${api_base}/api/reports.php?op=data_providers,service_providers`)
      .then((response) => {
        const responseData = response.data.response_data;

        setDataProviders(responseData.data_providers.data);
        setServiceProviders(responseData.service_providers.data);
      })
      .catch((error) => console.log(error));
  }, []);

  if (!isLoading && data && Array.isArray(data) && data.length) {
    data.forEach(function (serviceData, index) {
      const current = new Date(startDate.getTime()); // clone date
      const additionalIndices = [
        "total_count",
        "success_count",
        "duplicate_count",
        "failure_count",
        "queued_count",
        "transaction_count",
        "average_duration",
      ];

      serviceData.volume.forEach(function (groupedData: any) {
        const currentDay = current.getDate();
        const currentMonth = current.getMonth() + 1;
        const dataIndexPrefix = `_${currentMonth}_${currentDay}`;

        // groupedData.success_count =
        //   groupedData.duplicate_count - groupedData.success_count;
        //   // groupedData.success_count - groupedData.duplicate_count;
        // groupedData.failure_count =
        //   groupedData.total_count -
        //   groupedData.success_count -
        //   groupedData.duplicate_count -
        //   groupedData.queued_count;

        // Add current volume data to grid data
        additionalIndices.forEach(function (index) {
          serviceData[dataIndexPrefix + "_" + index] = Number(
            groupedData[index]
          );
        });

        // Increase current date by date range
        group === 30
          ? current.setMonth(currentMonth)
          : current.setDate(currentDay + group);
      });

      //delete serviceData["volume"];
      data[index] = serviceData;
    });
  }

  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any[], search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toString().toLowerCase().includes(search);
    const fields = [
      "service_id",
      "source_company",
      "data_source_name",
      "target_company",
      "service_name",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  const filteredData = Array.isArray(data) ? filter(data, searchQuery) : [];
  const summary = getSummary(filteredData);
  const treeData =
    !isLoading && filteredData.length
      ? addCalculatedValuesRecursively(filteredData)
      : [];

  return (
    <>
      <Title title="Volume Outgoing Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Group By"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="value"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Source"
            value={dataProvider}
            data={dataProviders}
            onChange={(v) => {
              setDataProvider(v);
            }}
            valueKey="client_id"
            labelKey="company_name"
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            placeholder="Filter by Target"
            value={serviceProvider}
            data={serviceProviders}
            onChange={(v) => {
              setServiceProvider(v);
            }}
            valueKey="client_id"
            labelKey="company_name"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `lead_detail_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(
                  new Date(Admediary.end),
                  "MMddyy"
                )}_SOURCE-${dataProvider}_TARGET-${serviceProvider}.csv`,
                getExportData(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <OutgoingList
        data={treeData}
        summary={summary}
        dateColumns={getDateColumns(
          filteredData,
          Admediary.start,
          Admediary.end,
          group
        )}
        startDate={startDate}
        groupBy={group}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default OutgoingReport;

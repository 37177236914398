import React from "react";
import { Nav, Navbar, Button } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { NavLink, useLocation } from "react-router-dom";

const NavTabBar: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const navTabs = Admediary.activeNavGroup;

  return (
    <Nav
      appearance="tabs"
      activeKey={Admediary.activeNav}
      reversed
      style={{ textAlign: "center" }}
    >
      {navTabs.map((element: any) => {
        return (
          <NavLink to={element.route} exact activeClassName="selectedTab">
            <Nav.Item
              eventKey={element.key}
              onSelect={Admediary.setActiveNav(element.name)}
              style={{
                border: "1px grey solid",
                borderRadius: "0 0 6px 6px",
                backgroundColor: "#fff",
              }}
            >
              {element.name}
            </Nav.Item>
          </NavLink>
        );
      })}
    </Nav>
  );
};

export default NavTabBar;

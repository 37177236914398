import React from "react";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const Products: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const config: any = Admediary.config;
  const location = useLocation();
  const [showing, setShowing] = useState(false);
  const [disabled, setDisabled] = useState(false);

  React.useEffect(() => {
    location.pathname === "/direct-posts/post-summary" ||
    location.pathname === "/direct-posts/post-leads" ||
    location.pathname === "/products/performance" ||
    location.pathname === "/flow/tree-report" ||
    location.pathname === "/data/sms-delivery" ||
    location.pathname === "/data/voice-campaign" ||
    location.pathname === "/data/voice-activity" ||
    location.pathname === "/products/data-validation" ||
    location.pathname === "/affiliates/common-list" ||
    location.pathname === "/buyers/contracts" ||
    location.pathname === "/buyers/contract-filters" ||
    location.pathname === "/buyers/routing" ||
    location.pathname === "/data/data-report-incoming" ||
    location.pathname === "/flow/tree-report" ||
    location.pathname === "/products/record-search" ||
    location.pathname === "/products/campaigns" ||
    location.pathname === "/data/active-sources" ||
    location.pathname === "/data/outgoing-report" ||
    location.pathname === "/cake/cake-offers" ||
    location.pathname === "/reports/external-reports" ||
    location.pathname === "/products/products" ||
    location.pathname === "/reports/call-programs" ||
    location.pathname === "/campaigns/numbers" ||
    location.pathname === "/products/categories" ||
    location.pathname === "/leads/disposition-lookup"
      ? hideSelect()
      : setShowing(true);
  }, [location]);

  const hideSelect = () => {
    Admediary.setProduct(null);
    setShowing(false);
  };

  React.useEffect(() => {
    if (
      Admediary.category === 9 &&
      location.pathname === "/products/gp-report"
    ) {
      setDisabled(true);
    } else if (
      Admediary.category === 11 &&
      location.pathname === "/products/gp-report"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [Admediary.category]);

  const Select = () => (
    <SelectPicker
      placeholder="All Products"
      data={availableProducts()}
      labelKey="product_description"
      valueKey="product_id"
      onChange={setProduct}
      value={Admediary.product}
      menuStyle={{ width: "340px" }}
      disabled={disabled}
    />
  );

  const availableProducts = () => {
    if (Admediary.category && Array.isArray(config.products)) {
      const filteredProducts = config.products.filter(
        (item: any) => item.category_id === Admediary.category
      );

      // Clear the selected product if selected category doesn't relates to it
      const foundSelectedProduct = !!filteredProducts.find(
        (item: any) => item.product_id === Admediary.product
      );
      if (!foundSelectedProduct) {
        setProduct(null);
      }

      return filteredProducts;
    }

    return config.products;
  };

  /**
   * Proxy for capturing products and sending it up stream
   * @param data
   */
  const setProduct = (data: any) => {
    if (
      Admediary.category === 9 &&
      location.pathname === "/products/gp-report"
    ) {
      Admediary.setProduct(45);
      setDisabled(true);
    } else if (
      Admediary.category === 11 &&
      location.pathname === "/products/gp-report"
    ) {
      Admediary.setProduct(57);
      setDisabled(true);
    } else {
      Admediary.setProduct(data);
      setDisabled(false);
    }
  };

  return <div>{showing ? <Select /> : null}</div>;
};

export default Products;

import React from "react";
import { Table } from "rsuite";
import { EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Campaign from "./../Drawers/Campaign";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type CampaignListProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const CampaignList: React.FC<CampaignListProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  sortType = "asc",
  sortColumn = "campaign_id",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  const handleEditActionClick = (rowData: any) => {
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <Campaign campaignId={rowData.campaign_id} refreshData={refreshData} />,
      "md"
    );
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column width={120} align="right" fixed sortable resizable>
          <HeaderCell>Campaign ID</HeaderCell>
          <Cell dataKey="campaign_id" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Product Description</HeaderCell>
          <Cell dataKey="product_description" />
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Site URL</HeaderCell>
          <Cell dataKey="site_url" />
        </Column>
        <Column width={100} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell editHandler={handleEditActionClick} />
        </Column>
      </Table>
    </>
  );
};

export default CampaignList;

import React from "react";
import { DatePicker, Drawer, FlexboxGrid, Icon, Table } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HourCell, NumberCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { isAfter, isBefore } from "date-fns";

const { Column, HeaderCell } = Table;

type OutgoingHourlyVolumeType = {
  rowData: any;
  start: Date;
  end: Date;
};

const OutgoingHourlyVolume: React.FC<OutgoingHourlyVolumeType> = ({
  rowData,
  start,
  end,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [startDate, setStartDate] = React.useState(start);
  const endDate = new Date(startDate);

  const params = {
    start_date: new Date(startDate),
    end_date: new Date(endDate),
    service_id: rowData.service_id,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  const [data, isLoading] = useAdmediaryApi("outgoing_hourly_volume", params);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Hourly Volume - {rowData.data_source_name}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
        <FlexboxGrid justify="space-between" style={{ marginTop: 20 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <DatePicker
              size="md"
              block={true}
              value={startDate}
              defaultValue={start}
              onChange={setStartDate}
              cleanable={false}
              disabledDate={(date) =>
                date ? isBefore(date, start) || isAfter(date, end) : false
              }
              ranges={[]}
              oneTap
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Drawer.Header>
      <Drawer.Body>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={Array.isArray(data) ? sortData(data) : []}
          hover
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Hour</HeaderCell>
            <HourCell dataKey="date_offset" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Total Count</HeaderCell>
            <NumberCell dataKey="total_count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Success Count</HeaderCell>
            <NumberCell dataKey="success_count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Duplicate Count</HeaderCell>
            <NumberCell dataKey="duplicate_count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Queued Count</HeaderCell>
            <NumberCell dataKey="queued_count" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default OutgoingHourlyVolume;

import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import VoiceActivityTreeTable from "./VoiceActivityTreeTable";
import { format } from "date-fns";
import Title from "../Title";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "total_calls",
    "human_calls",
    "machine_calls",
    "duration",
    "dialed_call_duration",
    "converted",
    "payable",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    const values = data.map((item: { [index: string]: string }): number => {
      return isNaN(parseFloat(item[field])) ? 0 : parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  const average = {
    duration: data.length ? summary.duration / data.length : 0,
    dialed_call_duration: data.length
      ? summary.dialed_call_duration / data.length
      : 0,
  };

  summary.duration = average.duration;
  summary.dialed_call_duration = average.dialed_call_duration;

  return addCalculatedValues(summary);
}

function getExportData(data: any) {
  const exportLevel = [1];

  const flattenTree = (list: any, parent?: any, currentLevel = 0) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        campaign_id: item.campaign_id || parent.campaign_id,
        description: item.description || parent.description,
        ...item,
      };

      if (exportLevel.includes(currentLevel)) {
        result.push(current);
      }

      if (current.children && Array.isArray(current.children)) {
        result.push(
          ...flattenTree(current.children, current, currentLevel + 1)
        );
      }
    });

    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Campaign ID": item.campaign_id,
          Description: item.description,
          Carrier: item.carrier,
          "Dialed From": item.phone_number,
          Calls: item.total_calls,
          Human: item.human_calls,
          Voicemail: item.machine_calls,
          Dialed: item.voice_to,
          Affiliate: item.affiliate,
          SID1: item.sid1,
          "Total Time": item.duration,
          "Call Time": item.dialed_call_duration,
          Conversion: item.converted,
          Payable: item.payable,
          "Retreaver UUID": item.uuid,
          RCID: item.rcid,
          RAFID: item.rafid,
          RSID: item.rsid,
          "Call Date": item.call_date,
        };
      })
  );
}

function addCalculatedValues(row: any) {
  const parseFloatWithoutStrippingZeroes = (
    value: string | number,
    defaultValue: null | number = null
  ) => {
    if (typeof value === "number") {
      return value;
    }

    if (isNaN(parseFloat(value))) {
      return defaultValue;
    }

    const separator = value.split(",").length > 1 ? "," : ".";
    const parts = value.split(separator);
    const toFixedLength = parts.length === 2 ? parts[1].length : 0;

    return parseFloat(value).toFixed(toFixedLength);
  };

  row.total_calls = parseFloatWithoutStrippingZeroes(row.total_calls, 0);
  row.human_calls = parseFloatWithoutStrippingZeroes(row.human_calls, 0);
  row.machine_calls = parseFloatWithoutStrippingZeroes(row.machine_calls, 0);
  row.converted = parseFloatWithoutStrippingZeroes(row.converted, 0);
  row.payable = parseFloatWithoutStrippingZeroes(row.payable, 0);
  row.duration = parseFloatWithoutStrippingZeroes(row.duration, 0);
  row.dialed_call_duration = parseFloatWithoutStrippingZeroes(
    row.dialed_call_duration,
    0
  );

  return {
    ...row,
  };
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const VoiceActivityTree: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = "disposition";
  const [group] = React.useState(defaultGroupValue);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
  };

  const [data, isLoading] = useAdmediaryApi("voice_activity_tree", params);
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="Voice Campaign Activity Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `voice_campaign_activity_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                getExportData(treeData)
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <VoiceActivityTreeTable
        data={treeData}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        group={group}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default VoiceActivityTree;

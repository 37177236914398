import React from "react";
import { Drawer, FlexboxGrid, Icon, Input, InputGroup, Table } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { NumberCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
const { Column, HeaderCell, Cell } = Table;

type OutgoingSourceUrlsType = {
  rowData: any;
  start?: any;
  end?: any;
};

const OutgoingSourceUrls: React.FC<OutgoingSourceUrlsType> = ({
  rowData,
  start,
  end,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  const params = {
    start_date: start ? new Date(start) : Admediary.start,
    end_date: end ? new Date(end) : Admediary.end,
    service_id: rowData.service_id,
    filter_set_id: 1,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */

  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };
  const [data, isLoading] = useAdmediaryApi("outgoing_source_urls", params);

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */

  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */

  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["source_url"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Source URLs - {rowData.data_source_name}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={19} style={{ textAlign: "left" }}>
            <InputGroup
              style={{ width: 125, marginRight: 15, display: "inline-block" }}
            >
              <Input
                placeholder="Search"
                onChange={(v) => {
                  debounceSearchTerm(v);
                }}
                size="sm"
              />
            </InputGroup>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          virtualized
          autoHeight
          loading={isLoading === true}
          data={filter(Array.isArray(data) ? sortData(data) : [], searchQuery)}
          hover
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={100} align="right" fixed sortable>
            <HeaderCell>Count</HeaderCell>
            <NumberCell dataKey="count" />
          </Column>
          <Column flexGrow={1} fixed sortable>
            <HeaderCell>Path</HeaderCell>
            <Cell dataKey="source_url" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default OutgoingSourceUrls;

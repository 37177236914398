import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HeaderSummary, ToolTipCell } from "../Table";
import { percent, thousands } from "../../@Utils/Format";
import { sort } from "../../@Utils/Sorting";
import OutgoingHourlyVolume from "../Drawers/OutgoingHourlyVolume";
import ResponseTime from "../Drawers/ResponseTime";
import ServiceResponseTime from "../Drawers/ServiceResponseTime";
import { format } from "date-fns";
import EditSource from "../Drawers/EditSource";
import { FormInstance } from "rsuite/lib/Form/Form";
import RecentRecords from "../Drawers/RecentRecords";
import OutgoingSourceUrls from "../Drawers/OutgoingSourceUrls";
import Service from "../Drawers/Service";
import DropdownMenu from "rsuite/lib/Dropdown/DropdownMenu";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type OutgoingListProps = {
  data: any;
  summary: any;
  dateColumns: any;
  startDate: Date;
  isLoading: boolean;
  rowKey: string;
  groupBy: number;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const OutgoingList: React.FC<OutgoingListProps> = (props: any) => {
  const data = props.data;
  const summary = props.summary;
  const dateColumns = props.dateColumns;
  const groupBy = props.groupBy;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sortType = props.sortType;
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  // let style = ""

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      if (item.failed_percent >= 10.0 && item.failed_percent <= 19.99) {
        item.style = "yellow";
      }
      if (item.failed_percent >= 20.0) {
        item.style = "red";
      }
      if (item.failed_percent < 10) {
        item.style = "transparent";
      }
      return {
        ...item,
        ...{
          total_count: thousands(item.total_count),
          success_count: thousands(item.success_count),
          duplicate_count: thousands(item.duplicate_count),
          failure_count: thousands(item.failure_count),
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
          style: item.style,
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  // Build dynamic columns related by category and product ids
  const dynamicColumns =
    dateColumns && Array.isArray(dateColumns) && dateColumns.length
      ? dateColumns.map((column: any, index: number) => (
          <Column align="right" sortable resizable key={index}>
            <HeaderCell>
              <HeaderSummary
                title={column.headerTitle}
                summary={thousands(summary[column.dataKey])}
              />
            </HeaderCell>
            <CustomDateCountsCell
              dataKey={column.dataKey}
              columnKeyPrefix={column.keyPrefix}
              start={column.start}
              end={column.end}
              groupBy={groupBy}
            />
          </Column>
        ))
      : [];
  return (
    <Table
      virtualized
      // height={650}
      autoHeight={true}
      headerHeight={65}
      loading={isLoading === true}
      data={sortData(data)}
      rowKey={rowKey}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
    >
      <Column fixed sortable resizable>
        <HeaderCell>Service ID</HeaderCell>
        <Cell dataKey="service_id" />
      </Column>
      <Column width={150} fixed sortable resizable>
        <HeaderCell>Source Company</HeaderCell>
        <Cell dataKey="source_company" />
      </Column>
      <Column width={150} fixed sortable resizable>
        <HeaderCell>Data Source</HeaderCell>
        <Cell dataKey="data_source_name" />
      </Column>
      <Column width={150} fixed sortable resizable>
        <HeaderCell>Target Company</HeaderCell>
        <Cell dataKey="target_company" />
      </Column>
      <Column width={150} fixed sortable resizable>
        <HeaderCell>Service</HeaderCell>
        <Cell dataKey="service_name" />
      </Column>
      <Column width={120} align="right" sortable resizable>
        <HeaderCell style={{ backgroundColor: rowKey.style }}>
          <HeaderSummary
            title="Total Count"
            summary={thousands(summary.total_count)}
          />
        </HeaderCell>
        <CountsCell dataKey="total_count" />
      </Column>
      <Column width={120} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Success Count"
            summary={thousands(summary.success_count)}
          />
        </HeaderCell>
        <CountsCell dataKey="success_count" />
      </Column>
      <Column width={130} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Duplicate Count"
            summary={thousands(summary.duplicate_count)}
          />
        </HeaderCell>
        <CountsCell dataKey="duplicate_count" />
      </Column>
      <Column width={120} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Failure Count"
            summary={thousands(summary.failure_count)}
          />
        </HeaderCell>
        <CountsCell dataKey="failure_count" />
      </Column>
      {dynamicColumns}
      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell groupBy={groupBy} />
      </Column>
    </Table>
  );
};

const renderToolTipContent = (data: any, dataKey = "") => {
  dataKey = dataKey ? dataKey + "_" : "";
  const counts = new Map([
    ["total", data[dataKey + "total_count"] || 0],
    ["success", data[dataKey + "success_count"] || 0],
    ["duplicate", data[dataKey + "duplicate_count"] || 0],
    ["failure", data[dataKey + "failure_count"] || 0],
    ["queued", data[dataKey + "queued_count"] || 0],
  ]);
  counts.set(
    "failure",
    counts.get("failure") ||
      counts.get("total") -
        counts.get("success") -
        counts.get("duplicate") -
        counts.get("queued")
  );

  // Build data with percents
  const tableData: any = [];
  counts.forEach(function (value, key) {
    if (key === "total") return;

    const percentValue = percent((value / counts.get("total")) * 100 || 0, 2);

    const title = key.charAt(0).toUpperCase() + key.slice(1);

    tableData.push({
      title: title,
      count: value,
      percent: percentValue,
    });
  });

  const tableRows = tableData.map((row: any, index: number) => (
    <tr key={index}>
      <td style={{ textAlign: "left" }}>{row.title}</td>
      <td style={{ textAlign: "right" }}>{row.count}</td>
      <td style={{ textAlign: "right", paddingLeft: 5 }}>{row.percent}</td>
    </tr>
  ));

  const averageDuration = data[dataKey + "average_duration"]
    ? data[dataKey + "average_duration"].toFixed(4)
    : 0;
  tableRows.push(
    <tr key={tableRows.length}>
      <td colSpan={3}>
        <hr />
      </td>
    </tr>
  );
  tableRows.push(
    <tr key={tableRows.length}>
      <td>Response Time:</td>
      <td colSpan={2} style={{ textAlign: "right", paddingLeft: 5 }}>
        {averageDuration}
      </td>
    </tr>
  );

  return (
    <table>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

const CountsCell = ({ rowData, dataKey, columnKeyPrefix, ...props }: any) => {
  const content = renderToolTipContent(rowData, columnKeyPrefix);

  return (
    <ToolTipCell
      rowData={rowData}
      dataKey={dataKey}
      content={content}
      {...props}
      style={{ backgroundColor: rowData.style }}
    />
  );
};

const CustomDateCountsCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  ...props
}: any) => {
  const content = renderToolTipContent(rowData, columnKeyPrefix);
  const totalCount = rowData[columnKeyPrefix + "_total_count"];
  const failedCount = rowData[columnKeyPrefix + "_failure_count"];
  const queuedCount = rowData[columnKeyPrefix + "_queued_count"];

  const queuedPercent = (queuedCount / totalCount) * 100;
  const failurePercent = (failedCount / totalCount) * 100;

  let style = "transparent";
  if (queuedPercent > 10 || failurePercent > 10) {
    style = "yellow";
  }
  if (queuedPercent > 20 || failurePercent > 20) {
    style = "red";
  }

  const row = rowData;
  const groupBy = props.groupBy;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const start = props.start;
  const end = props.end;

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      [
        "hourly_volume",
        <OutgoingHourlyVolume rowData={row} start={start} end={end} />,
      ],
      [
        "response_time",
        <ServiceResponseTime row_data={row} start={start} end={end} />,
      ],
      [
        "recent_records",
        <RecentRecords row_data={row} start={start} end={end} />,
      ],
    ]);
    const sizes = new Map([
      ["recent_records", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="right"
      trigger="click"
      triggerRef={triggerRef}
      speaker={
        <MenuPopover
          onSelect={handleSelectMenu}
          groupBy={groupBy}
          exclude={["source_urls", "edit_service", "edit_source"]}
        />
      }
    >
      <ToolTipCell
        rowData={rowData}
        dataKey={dataKey}
        content={content}
        {...props}
        style={{ backgroundColor: style }}
      />
    </Whisper>
  );
};

/*
Action list:
 */
const Menu = ({ onSelect, groupBy, exclude = [] }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    {groupBy === 1 && !exclude.includes("hourly_volume") && (
      <Dropdown.Item eventKey="hourly_volume">Hourly Volume</Dropdown.Item>
    )}
    <Dropdown.Item eventKey="response_time">Response Time</Dropdown.Item>
    <Dropdown.Item eventKey="recent_records">Recent Records</Dropdown.Item>
    {!exclude.includes("source_urls") && (
      <Dropdown.Item eventKey="source_urls">Source URLs</Dropdown.Item>
    )}
    {groupBy === 1 && !exclude.includes("edit_service") && (
      <Dropdown.Item eventKey="edit_service">Edit Service</Dropdown.Item>
    )}
    {groupBy === 1 && !exclude.includes("edit_source") && (
      <Dropdown.Item eventKey="edit_source">Edit Source</Dropdown.Item>
    )}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, groupBy, exclude = [], ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} groupBy={groupBy} exclude={exclude} />
  </Popover>
);

type TableWhisper = {
  row: any;
  groupBy: number;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const groupBy = props.groupBy;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const start = admediaryContext.start;
  const end = admediaryContext.end;

  const formRef = React.createRef<FormInstance>();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      [
        "hourly_volume",
        <OutgoingHourlyVolume rowData={row} start={start} end={end} />,
      ],
      [
        "response_time",
        <ServiceResponseTime row_data={row} start={start} end={end} />,
      ],
      [
        "recent_records",
        <RecentRecords row_data={row} start={start} end={end} />,
      ],
      ["source_urls", <OutgoingSourceUrls rowData={row} />],
      ["edit_service", <Service serviceId={row.service_id} />],
      [
        "edit_source",
        <EditSource dataSourceId={row.data_source_id} formRef={formRef} />,
      ],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["recent_records", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={
        <MenuPopover
          onSelect={handleSelectMenu}
          groupBy={groupBy}
          exclude={[]}
        />
      }
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, groupBy, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} groupBy={groupBy} />
    </Cell>
  );
};

export default OutgoingList;

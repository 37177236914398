import React from "react";
import { Dropdown, Popover, Table, Whisper, Icon, IconButton } from "rsuite";
import { float, percent, thousands } from "../../@Utils/Format";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { FloatCell, NumberCell, PercentCell, ToolTipCell } from "../Table";
import _ from "lodash";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import ResponseTimeChart from "../Drawers/ResponseTimeChart";
import AverageResponseTimeChart from "../Drawers/AverageResponseTimeChart";
import Responses from "../Drawers/Responses";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type PerformanceListProps = {
  data: any;
  dateColumns: any;
  startDate: Date;
  isLoading: boolean;
  rowKey: string;
  reportView: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const PerformanceList: React.FC<PerformanceListProps> = (props: any) => {
  const data = props.data;
  const dateColumns = props.dateColumns;
  const reportView = props.reportView;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sortType = props.sortType || "desc";
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      const failedPercent = item.failed_percent;
      const style =
        failedPercent >= 10.0 && failedPercent <= 19.99
          ? "yellow"
          : failedPercent >= 20.0
          ? "red"
          : "transparent";

      return {
        ...item,
        ...{
          total_count: thousands(item.total_count),
          filtered: thousands(item.filtered),
          accepted: thousands(item.accepted),
          rejected: thousands(item.rejected),
          pinged: thousands(item.pinged),
          posted: thousands(item.posted),
          filter_percentage: item.filter_percentage,
          accept_percentage: item.accept_percentage,
          priority: item.priority,
          posted_accept_percentage: item.posted_accept_percentage,
          average_post: item.average_post,
          min_post: item.min_post,
          max_post: item.max_post,
          stddev_post: item.stddev_post,
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-y HH:mm")
            : "",
          style,
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sortColumn && sortType ? sort(list, sortColumn, sortType) : list
    );
  };

  // Build dynamic columns related by the report view
  const additionalMainColumns =
    reportView === "accept_rate"
      ? [
          {
            headerTitle: "Accepted",
            dataKey: "accepted",
            type: "number",
          },
          {
            headerTitle: "Rejected",
            dataKey: "rejected",
            type: "number",
          },
          {
            headerTitle: "Pinged",
            dataKey: "pinged",
            type: "number",
          },
          {
            headerTitle: "Posted",
            dataKey: "posted",
            type: "number",
          },
          {
            headerTitle: "Filtered",
            dataKey: "filtered",
            type: "number",
          },
          {
            headerTitle: "Gross Accept %",
            dataKey: "accept_percentage",
            type: "percent",
          },
          {
            headerTitle: "Accept %",
            dataKey: "posted_accept_percentage",
            type: "percent",
          },
          {
            headerTitle: "Priority",
            dataKey: "priority",
            type: "cell",
          },
        ]
      : [
          {
            headerTitle: "Post Count",
            dataKey: "post_count",
            type: "number",
          },
          {
            headerTitle: "Average",
            dataKey: "average_post",
            cell: "CustomDateCountsCell",
            reportView: reportView,
          },
        ];

  const dynamicMainColumns =
    additionalMainColumns &&
    Array.isArray(additionalMainColumns) &&
    additionalMainColumns.length
      ? additionalMainColumns.map((column: any, index: number) => (
          <Column align="right" sortable resizable key={index} fixed>
            <HeaderCell>{column.headerTitle}</HeaderCell>
            {column.type === "percent" ? (
              <PercentCell dataKey={column.dataKey} digits={2} />
            ) : column.type === "float" ? (
              <FloatCell dataKey={column.dataKey} digits={3} postfix=" s" />
            ) : column.type === "number" ? (
              <NumberCell dataKey={column.dataKey} />
            ) : column.cell === "CustomDateCountsCell" ? (
              <CustomDateCountsCell
                dataKey={column.dataKey}
                reportView={column.reportView}
              />
            ) : (
              <Cell dataKey={column.dataKey} />
            )}
          </Column>
        ))
      : [];

  // Build dynamic columns related by date column list
  const dynamicDateColumns =
    dateColumns && Array.isArray(dateColumns) && dateColumns.length
      ? dateColumns.map((column: any, index: number) => (
          <Column align="right" sortable resizable key={index}>
            <HeaderCell>{column.headerTitle}</HeaderCell>
            <CustomDateCountsCell
              dataKey={column.dataKey}
              columnKeyPrefix={column.keyPrefix}
              columnDate={column.fullDate}
              reportView={reportView}
            />
          </Column>
        ))
      : [];

  //
  // const sortData = (list: any) => {
  //     return formatData(
  //         sortColumn && sortType ? sort(list, sortColumn, sortType) : list
  //     );
  // };

  const priorityFormatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          priority:
            !isNaN(item.priority) && item.priority !== null
              ? item.priority
              : -1,
        },
      };
    });
  };

  const sortArray = (data: any) => {
    const nullSortedData = [
      ...data.filter((x: any) => x.priority !== null),
      ...data.filter((x: any) => x.priority === null),
    ];

    return priorityFormatData(nullSortedData);
  };

  return (
    <Table
      virtualized
      // height={650}
      autoHeight={true}
      loading={isLoading === true}
      // data={sortData(data)}
      data={sortArray(sortData(data))}
      rowKey={rowKey}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
    >
      <Column width={250} fixed sortable resizable>
        <HeaderCell>Buyer</HeaderCell>
        <ToolTipCell dataKey="buyer_name" />
      </Column>
      <Column fixed sortable resizable align="right">
        <HeaderCell>Contract ID</HeaderCell>
        <Cell dataKey="contract_id" />
      </Column>
      <Column width={180} fixed sortable resizable>
        <HeaderCell>Contract</HeaderCell>
        <ToolTipCell dataKey="contract_name" />
      </Column>
      {dynamicMainColumns}
      {dynamicDateColumns}
      {reportView === "accept_rate" ? (
        <Column width={50} fixed="right">
          <HeaderCell>&nbsp;</HeaderCell>
          <ActionCell />
        </Column>
      ) : null}
    </Table>
  );
};

const ActionCell = ({ rowData, ...props }: any) => (
  <Cell {...props} className="link-group">
    <CustomWhisper row={rowData} />
  </Cell>
);

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;

  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["responses", <Responses row_data={row} statusLead="rejected" />],
    ]);
    const sizes = new Map([["view_leads", "lg"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="click"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    {parseInt(row.rejected) > 0 && row.rejected ? (
      <Dropdown.Item eventKey="responses">Rejected Responses</Dropdown.Item>
    ) : (
      <Message type="warning" />
    )}
  </Dropdown.Menu>
);
const Message = React.forwardRef(({ type, ...rest }: any, ref: any) => {
  return <p> There are no rejected leads based on filters selected </p>;
});

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const getCellData = (data: any, dataKey = "") => {
  dataKey = dataKey ? dataKey + "_" : "";

  return {
    total_count: thousands(data[dataKey + "total_count"] || 0),
    filtered: thousands(data[dataKey + "filtered"] || 0),
    accepted: thousands(data[dataKey + "accepted"] || 0),
    rejected: thousands(data[dataKey + "rejected"] || 0),
    pinged: thousands(data[dataKey + "pinged"] || 0),
    posted: thousands(data[dataKey + "posted"] || 0),
    filter_percentage: percent(data[dataKey + "filter_percentage"] || 0, 2),
    accept_percentage: percent(data[dataKey + "accept_percentage"] || 0, 2),
    priority: data[dataKey + "priority"],
    posted_accept_percentage: percent(
      data[dataKey + "posted_accept_percentage"] || 0,
      2
    ),
    average_post: `${float(data[dataKey + "average_post"] || 0, 3)} s`,
    min_post: `${float(data[dataKey + "min_post"] || 0, 3)} s`,
    max_post: `${float(data[dataKey + "max_post"] || 0, 3)} s`,
    stddev_post: `${float(data[dataKey + "stddev_post"] || 0, 3)} s`,
  };
};

const CustomDateCountsCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  columnDate,
  reportView = "accept_rate",
  ...props
}: any) => {
  const values = getCellData(rowData, columnKeyPrefix);
  const description =
    reportView === "accept_rate" ? (
      <AcceptRateTable values={values} />
    ) : (
      <ResponseTimeTable values={values} />
    );

  const color = ((reportView, acceptPercentage, averagePost) => {
    if (reportView === "accept_rate") {
      if (acceptPercentage < 20) return "red";
      if (acceptPercentage < 50) return "yellow";
    } else {
      if (averagePost > 10) return "red";
      if (averagePost > 6) return "yellow";
    }
    return "none";
  })(
    reportView,
    parseFloat(values["accept_percentage"]),
    parseFloat(values["average_post"])
  );

  const value = rowData[dataKey];
  const content =
    reportView === "accept_rate" ? percent(value, 2) : `${float(value, 3)} s`;

  const admediaryContext = React.useContext(AdmediaryContext);
  const clickWhisperRef = React.createRef();
  const hoverWhisperRef = React.createRef();

  // TODO Move it into the PopoverCell???
  if (reportView === "response_time") {
    const onSelect = (eventKey: string, event: any) => {
      // Stop the event bubbling
      event.preventDefault();
      event.stopPropagation();

      // @ts-ignore
      clickWhisperRef.current.close();
      const contents = new Map([
        [
          "response_time",
          <ResponseTimeChart
            contractId={rowData.contract_id}
            contractName={rowData.contract_name}
            columnDate={columnDate}
          />,
        ],
        [
          "average_response_time",
          <AverageResponseTimeChart
            contractId={rowData.contract_id}
            buyerName={rowData.buyer_name}
            columnDate={columnDate}
          />,
        ],
        ["default", <span>Unknown menu item</span>],
      ]);
      const sizes = new Map([
        ["response_time", "lg"],
        ["average_response_time", "lg"],
        ["default", "md"],
      ]);

      // Set selected content into drawer
      admediaryContext.openDrawer(
        contents.has(eventKey)
          ? contents.get(eventKey)
          : contents.get("default"),
        sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
      );
    };

    // @ts-ignore
    const onWhisperClick = () => hoverWhisperRef.current.close();

    const menu = (
      <Dropdown.Menu onSelect={onSelect}>
        <Dropdown.Item eventKey="response_time">Response Time</Dropdown.Item>
        <Dropdown.Item eventKey="average_response_time">
          Average Response Time
        </Dropdown.Item>
      </Dropdown.Menu>
    );

    return (
      <Cell
        {...props}
        style={color !== "none" ? { backgroundColor: color } : {}}
      >
        <Whisper
          placement="autoVertical"
          trigger="click"
          ref={clickWhisperRef}
          speaker={<Popover>{menu}</Popover>}
          onClick={onWhisperClick}
        >
          <Whisper
            placement="auto"
            trigger="hover"
            ref={hoverWhisperRef}
            enterable
            speaker={<Popover>{description}</Popover>}
          >
            <div>{content}</div>
          </Whisper>
        </Whisper>
      </Cell>
    );
  }

  return (
    <PopoverCell
      content={content}
      description={description}
      style={color !== "none" ? { backgroundColor: color } : {}}
      {...props}
    />
  );
};

const PopoverCell = ({ content = "", description = "", ...props }: any) => (
  <Cell {...props}>
    <Whisper
      placement="auto"
      trigger="hover"
      enterable
      speaker={<Popover>{description}</Popover>}
    >
      <div>{content}</div>
    </Whisper>
  </Cell>
);

const AcceptRateTable = ({ values }: any) => {
  const data = [
    { title: "Total", value: values["total_count"] },
    { title: "Filtered", value: values["filtered"] },
    { title: "Accepted", value: values["accepted"] },
    { title: "Rejected", value: values["rejected"] },
    { title: "Pinged", value: values["pinged"] },
    { title: "Posted", value: values["posted"] },
    { title: "Gross Accept %", value: values["accept_percentage"] },
    { title: "Accept %", value: values["posted_accept_percentage"] },
    {
      title: "Priority",
      value: values["priority"] !== 0 ? values["priority"] : -1,
    },
    { title: "Filter %", value: values["filter_percentage"] },
  ];

  return <VerticalTable data={data} />;
};

const ResponseTimeTable = ({ values }: any) => {
  const data = [
    { title: "Average Post Time", value: values["average_post"] },
    { title: "Minimum", value: values["min_post"] },
    { title: "Maximum", value: values["max_post"] },
    { title: "Standard Deviation", value: values["stddev_post"] },
  ];

  return <VerticalTable data={data} height={200} />;
};

const VerticalTable = ({ data, width = 300, height = 470 }: any) => (
  <Table style={{ width }} height={height} data={data} showHeader={false}>
    <Column width={150}>
      <HeaderCell>Title</HeaderCell>
      <Cell dataKey="title" />
    </Column>
    <Column width={80} align="right">
      <HeaderCell>Value</HeaderCell>
      <Cell dataKey="value" />
    </Column>
  </Table>
);

export default PerformanceList;

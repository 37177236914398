import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Pagination,
  CheckPicker,
  Toggle,
  Loader,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import Config from "../../@Config/Forms";
import SelectPicker from "rsuite/lib/SelectPicker";
import InputPicker from "rsuite/lib/InputPicker";
import { format } from "date-fns";
import Title from "../Title";
import CakeOffersList from "./CakeOffersList";
import CakeOffersBarGraph from "./CakeOffersBarGraph";
import axios from "axios";

const CakeOffersReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "offer_id";
  const [group, setGroup] = React.useState(1);
  const [sortDir, setSortDir] = React.useState("desc");
  const [sortColumn, setSortColumn] = React.useState("click_count");
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(15);
  const startDate = Admediary.start;
  const [affiliateOfferFilterId, setAffiliateOfferFilterId] = React.useState(0);
  const [affiliateFilterId, setAffiliateFilterId] = React.useState(0);
  const [viewMode, setViewMode] = React.useState("graph");
  const [graphKeys, setGraphKeys] = React.useState(["Clicks"]);

  const availablePageSizes = [10, 15, 20].map((item) => ({
    label: item,
    value: item,
  }));

  const availableGraphBars = ["Clicks", "Conversions"].map((item) => ({
    label: item,
    value: item,
  }));

  const API = axios.create({});
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  React.useEffect(() => {
    Admediary.setAffiliate(null);
  }, []);

  const params = {
    start_date: startDate,
    end_date: Admediary.end,
    offer_id: affiliateOfferFilterId,
    page: 0,
    page_size: 100000,
    sort_by: sortColumn,
    sort_dir: sortDir,
    affiliate_id: affiliateFilterId,
  };

  // const [data, isLoading] = useAdmediaryApi(
  //   "report_affiliate_offers_select",
  //   params
  // );
  const [data, isLoading] = useAdmediaryApiManual(
    "report_affiliate_offers_select",
    params
  );
  // const [affiliateOfferListFetch, isLoadingAffiliateOffers] = useAdmediaryApi(
  //   "affiliate_offer_select_distinct"
  // );
  const [
    affiliateOfferListFetch,
    isLoadingAffiliateOffers,
  ] = useAdmediaryApiManual("affiliate_offer_select_distinct");
  // const [affiliateListFetch, isLoadingAffiliates] = useAdmediaryApi(
  //   "affiliates"
  // );
  const [affiliateListFetch, isLoadingAffiliates] = useAdmediaryApiManual(
    "affiliates"
  );

  const treeData =
    !isLoading && data !== null
      ? Array.isArray(data)
        ? data.filter((row: any, key: number) => {
            //Perform pagination filter
            return key >= page * pageSize && key < (page + 1) * pageSize;
          })
        : []
      : [];

  const affiliateOfferList =
    !isLoadingAffiliateOffers && affiliateOfferListFetch !== null
      ? Array.isArray(affiliateOfferListFetch)
        ? affiliateOfferListFetch.map((val) => {
            return { ...val, label: `(${val.offer_id}) ${val.offer_name}` };
          })
        : []
      : [];

  const affiliateList =
    !isLoadingAffiliates && affiliateListFetch !== null
      ? Array.isArray(affiliateListFetch)
        ? affiliateListFetch.map((val) => {
            return {
              ...val,
              label: `(${val.affiliate_id}) ${val.affiliate_name}`,
            };
          })
        : []
      : [];

  return (
    <>
      <Title title="Cake Offers Report" />
      <FlexboxGrid justify="start" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={6}>
          <SelectPicker
            size="md"
            placeholder="Search Offers"
            data={affiliateOfferList}
            valueKey="offer_id"
            labelKey="label"
            style={{ marginRight: 15, width: "250px" }}
            value={affiliateOfferFilterId}
            searchable
            onChange={(val) => {
              setAffiliateOfferFilterId(val);
              setPage(0);
            }}
            disabled={isLoadingAffiliateOffers === true}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={10}>
          <SelectPicker
            size="md"
            placeholder="Search Affiliates"
            data={affiliateList}
            valueKey="affiliate_id"
            labelKey="label"
            style={{ marginRight: 15, width: "250px" }}
            value={affiliateFilterId}
            searchable
            onChange={(val) => {
              setAffiliateFilterId(val);
              Admediary.setAffiliate(val);
              setPage(0);
            }}
            disabled={isLoadingAffiliates === true}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3}>
          <IconButton
            size="md"
            onClick={(e) => {
              setViewMode(viewMode === "graph" ? "table" : "graph");
            }}
          >
            <Icon icon={viewMode === "graph" ? "table" : "bar-chart"} />
            {viewMode === "graph" ? "Table" : "Graph"}
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5}>
          {viewMode === "graph" && (
            <CheckPicker
              size="md"
              data={availableGraphBars}
              label={"Columns: "}
              value={graphKeys}
              onChange={(val: any) => {
                setGraphKeys(val);
              }}
              style={{ width: 250 }}
              countable={false}
              searchable={false}
              cleanable={false}
              renderValue={(value, items) => {
                return (
                  <span>
                    <span style={{ color: "#575757" }}>Columns:</span>{" "}
                    {value.join(" , ")}
                  </span>
                );
              }}
            />
          )}
          {viewMode === "table" && (
            <IconButton
              onClick={() => {
                const fullParams = {
                  ...params,
                  page_size: affiliateOfferList.length,
                };

                const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
                API.get(
                  `${api_base}/api/reports.php?op=report_affiliate_offers_select`,
                  { params: fullParams }
                )
                  .then((response: any) => {
                    exportCsv(
                      `cake_offers_report_${format(
                        new Date(Admediary.start),
                        "MMddyy"
                      )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                      response.data.response_data.data
                    );
                  })
                  .catch((error: any) => console.log(error));
              }}
              icon={<Icon icon="file-download" />}
              placement="right"
              size="md"
            >
              Export
            </IconButton>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {isLoading === true && (
        <Loader content="Loading..." style={{ margin: "1em" }} />
      )}
      {isLoading !== true && (
        <>
          {viewMode === "graph" && (
            <CakeOffersBarGraph
              data={treeData}
              startDate={startDate}
              isLoading={false}
              graphKeys={graphKeys}
              groupBy="offer"
            />
          )}

          {viewMode === "table" && (
            <CakeOffersList
              data={treeData}
              dateColumns={[]}
              startDate={startDate}
              groupBy={group}
              isLoading={false}
              rowKey={rowKey}
              sortColumn={sortColumn}
              sortType={sortDir}
              onSortColumn={(col, dir) => {
                setSortColumn(col);
                setSortDir(dir);
              }}
            />
          )}
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={10}>
              <Pagination
                first={true}
                last={true}
                prev={true}
                next={true}
                ellipsis={true}
                boundaryLinks={true}
                maxButtons={5}
                size="md"
                layout={["total", "-", "limit", "|", "pager", "skip"]}
                limitOptions={[10]}
                limit={50}
                pages={
                  affiliateOfferFilterId
                    ? 1
                    : Math.ceil(affiliateOfferList.length / pageSize)
                }
                activePage={page + 1}
                onSelect={(p) => {
                  setPage(p - 1);
                }}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6}>
              <InputPicker
                size="sm"
                data={availablePageSizes}
                value={pageSize}
                onSelect={(s: any) => {
                  setPageSize(s);
                  setPage(0);
                }}
                cleanable={false}
                defaultValue={50}
                style={{ width: "80px" }}
              />
              <label> Results per page</label>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </>
      )}
    </>
  );
};

export default CakeOffersReport;
/*

      
      */

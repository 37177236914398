import React from "react";
import { Avatar, Button, FlexboxGrid, Icon, List, Loader } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import BuyerOverview from "../Drawers/BuyerOverview";
import BuyerAlerts from "./BuyerAlerts";

const TopBuyerAlerts: React.FC = () => {
  const admediary_context = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: admediary_context.category,
    product_id: admediary_context.product,
    buyer_id: admediary_context.buyer,
    affiliate_id: 0,
  };

  const [data, isLoading] = useAdmediaryApi(
    "buyer_contract_notifications",
    params
  );

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  // Nothing to show
  if (data.length === 0) {
    return (
      <List size="sm">
        <List.Item className="alerts-table-no-alerts">
          There are no Buyer alerts.
        </List.Item>
      </List>
    );
  }

  /**
   * Function for opening Buyer Overview
   * @param buyer_id
   */
  const openBuyerOverview = (buyer_id: number) => {
    admediary_context.openDrawer(<BuyerOverview buyer_id={buyer_id} />);
  };

  const openBuyerAlerts = () => {
    admediary_context.openDrawer(<BuyerAlerts data={data} />);
  };

  // Render alerts
  return (
    <List size="sm">
      {data.slice(0, 5).map((item: any, key: number) => (
        <List.Item
          className="alerts-table-item"
          onClick={() => {
            openBuyerOverview(item.buyer_id);
          }}
          key={key}
          style={{ borderBottom: "solid grey 1px" }}
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={4} style={{ paddingTop: 7 }}>
              <Avatar size="sm" style={{ background: "none" }}>
                <Icon
                  icon="exclamation-triangle"
                  style={{ color: "#022654" }}
                />
              </Avatar>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={20}>
              <span
                style={{
                  fontSize: 11,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {item.contract_name}
              </span>
              <br />
              <span
                style={{
                  fontSize: 14,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {item.message}
              </span>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
      ))}
      <List.Item className="alerts-table-cta">
        <Button
          appearance="link"
          onClick={() => {
            openBuyerAlerts();
          }}
        >
          View All
        </Button>
      </List.Item>
    </List>
  );
};

export default TopBuyerAlerts;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { EditRemoveActionCell, ToolTipCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { FormContext } from "../../@Context/FormContext";
import EditModal from "../EditModal";
import EditCreativeModal from "./EditCreativeModal";
import ConfirmModal from "../ConfirmModal";
import { FormInstance } from "rsuite/lib/Form/Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

type Messages = {
  messages: any;
  parentCallback?: any;
  renderToolTipContent?: any;
  removeSmsID?: any;
  setRemoveSmsID?: any;
  setSumProbability?: any;
  sumProbability?: any;
  campaignId?: any;
};

const Messages: React.FC<Messages> = ({
  messages = [],
  renderToolTipContent = () => {},
  removeSmsID,
  setRemoveSmsID,
  setSumProbability,
  sumProbability,
  campaignId,
  // onChange = (data: any) => {},
  parentCallback = () => {},
}) => {
  let filtered;
  const formContext = React.useContext(FormContext);
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [data, setData] = React.useState(messages);
  const [open, setOpen] = React.useState(false);
  const [operation, setOperation] = React.useState();
  const [id, setId] = React.useState(0);
  const [editModalOpen, setEditModalOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => {
    setEditModalOpen(false);
    formContext.setProbability(0);
    formContext.setMessage("");
  };

  const handleRemoving = () => {
    removeSmsID.push(selectedItem.sms_creative_id);
    setRemoveSmsID(removeSmsID);
    removeItem(selectedItem.creative_id);
    handleClose();
  };

  const removeItem = (id: number) => {
    filtered = data.filter((item: any) => item.creative_id !== id);
    setData(filtered);
    if (filtered.length === 0) {
      setSumProbability(0);
      return;
    }
    const probability = filtered
      .map((item: any) => item.probability)
      .reduce((prev: any, next: any) => parseInt(prev) + parseInt(next));
    setSumProbability(probability);
  };
  const mainFormRef = React.createRef<FormInstance>();
  const initItem = { creative_id: 0, probability: 0, sms_message: "" };
  const [formValue, setFormValue] = React.useState(initItem);
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };
  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);
    handleOpen();
  };

  const handleEditActionClick = (rowData: any) => {
    setOperation("edit");
    setSelectedItem(rowData);
    setId(rowData.creative_id);
    formContext.setMessage(rowData.sms_message);
    formContext.setProbability(rowData.probability);
    handleEditModalOpen();
  };

  const handleAddAction = () => {
    setOperation("add");
    setSelectedItem(initItem);
    setId(data.length);
    handleEditModalOpen();
  };

  const handleCallback = (formValue: any) => {
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
    setFormValue(formValue);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;
    if (node && !node.check()) {
      return;
    }
    setFormValue(formValue);
    if (operation === "add") {
      data.push({
        ...formValue,
        ...{
          campaign_id: campaignId,
          sms_creative_id: 0,
          operation: "add",
        },
      });
      const probability = data
        .map((item: any) => item.probability)
        .reduce((prev: any, next: any) => parseInt(prev) + parseInt(next));
      setSumProbability(parseInt(probability));
      setData([...data]);
    } else {
      const index = data.findIndex(
        (item: any) => item.creative_id === formValue.creative_id
      );
      if (formValue.sms_message === "") {
        setData([...data]);
      } else {
        data[index].sms_message = formValue.sms_message;
        data[index].probability = formValue.probability;
        setData([...data]);
      }
      const probability = data
        .map((item: any) => item.probability)
        .reduce((prev: any, next: any) => parseInt(prev) + parseInt(next));
      setSumProbability(parseInt(probability));
    }
    handleEditModalClose();
  };

  return (
    <FlexboxGrid
      justify="space-between"
      style={{ marginBottom: 25, marginTop: 20 }}
    >
      <IconButton
        onClick={handleAddAction}
        placement="left"
        size="sm"
        appearance="ghost"
        icon={<Icon icon="plus-circle" />}
        style={{ marginBottom: 25 }}
        disabled={Admediary.userDisable}
      >
        Add Creative
      </IconButton>
      <FlexboxGrid.Item colspan={24}>
        <Table
          isTree
          height={550}
          headerHeight={45}
          rowClassName="clickable-data striped-rows"
          affixHeader
          data={Array.isArray(sortData(data)) ? data : []}
          onSortColumn={handleSortColumn}
        >
          <Column width={500} align="left" fixed sortable resizable>
            <HeaderCell>Message</HeaderCell>
            <MessageCell
              renderToolTipContent={renderToolTipContent}
              dataKey="sms_message"
            />
          </Column>
          <Column width={100} fixed sortable resizable>
            <HeaderCell>Probability</HeaderCell>
            <Cell dataKey="probability" />
          </Column>
          <Column width={100} align={"center"}>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
              userPermission={Admediary.userDisable}
            />
          </Column>
        </Table>
        <ConfirmModal
          title="Removing"
          open={open}
          onClose={handleClose}
          onYes={handleRemoving}
        >
          Are you sure you want to remove this Email?
        </ConfirmModal>
        <EditModal
          title={
            (selectedItem && selectedItem.email !== "" ? "Edit" : "Add") +
            " Email"
          }
          open={editModalOpen}
          size={"sm"}
          onClose={handleEditModalClose}
          onCancel={handleEditModalClose}
          onSubmit={handleSave}
          hideSubmitButton={Admediary.userDisable}
        >
          <EditCreativeModal
            id={id}
            message={selectedItem ? selectedItem.sms_message : ""}
            probability={selectedItem ? selectedItem.probability : 0}
            formRef={mainFormRef}
            parentCallback={handleCallback}
          ></EditCreativeModal>
        </EditModal>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const MessageCell = ({
  rowData,
  dataKey,
  renderToolTipContent,
  ...props
}: any) => {
  const content = renderToolTipContent(rowData);
  return (
    <ToolTipCell
      rowData={rowData}
      placement={"topStart"}
      dataKey={"sms_message"}
      content={content}
      {...props}
    />
  );
};
export default Messages;

import React from "react";
import {
  Icon,
  IconButton,
  Popover,
  Table,
  Whisper,
  Dropdown,
  PopoverProps,
  WhisperProps,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import CakeOfferDrilldown from "../Drawers/CakeOfferDrilldown";
import { Bar as NivoBar } from "@nivo/bar";

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type CakeOffersBarGraphProps = {
  data: any;
  startDate: Date;
  isLoading: boolean;
  graphKeys: Array<any>;
  groupBy: string;
  drilldownHour?: (rowData: any) => void;
  drilldownAffiliate?: (rowData: any) => void;
};

const CakeOffersBarGraph: React.FC<CakeOffersBarGraphProps> = (props: any) => {
  const data = props.data;
  const isLoading = props.isLoading;
  const graphKeys = props.graphKeys;
  const groupBy = props.groupBy;
  const drilldownHour = props.drilldownHour;
  const drilldownAffiliate = props.drilldownAffiliate;
  const admediaryContext = React.useContext(AdmediaryContext);

  const [contextMenuPosition, setContextMenuPosition] = React.useState({
    top: 0,
    left: 0,
  });
  const [activeRow, setActiveRow] = React.useState({ index: -1 });
  const whisperRef = React.useRef<WhisperProps>();

  const Overlay = (style: any, ref: any) => {
    const styles = {
      ...style,
      color: "#000",
      background: "#fff",
      minWidth: 200,
      padding: 10,
      borderRadius: 4,
      position: "absolute",
      border: "1px solid #ddd",
      boxShadow: "0 3px 6px -2px rgba(0, 0, 0, 0.6)",
    };

    return (
      <div
        style={{
          ...styles,
          left: contextMenuPosition.left,
          top: contextMenuPosition.top,
        }}
        id="graphContextMenu"
      >
        <Dropdown.Menu
          onSelect={(key: any) => {
            if (activeRow.index > 0 && activeRow.index <= data.length) {
              const start = admediaryContext.start;
              const end = admediaryContext.end;
              const rowData = data[data.length - activeRow.index - 1];

              admediaryContext.openDrawer(
                <CakeOfferDrilldown
                  rowData={rowData} //Graph Array is reversed to show largest rows at the top
                  start={start}
                  end={end}
                  groupBy={key}
                />,
                "full"
              );
            }
          }}
        >
          <Dropdown.Item eventKey="affiliate">
            Affiliate Breakdown
          </Dropdown.Item>
          <Dropdown.Item eventKey="date">Daily Breakdown</Dropdown.Item>
        </Dropdown.Menu>
      </div>
    );
  };

  const [maxValue, setMaxValue] = React.useState(0);
  // let style = ""

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {};

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return list;
  };

  const formatGraphData = (data: any) => {
    var $return = data.map((item: any) => {
      var name_tag;
      if (groupBy == "offer") {
        name_tag =
          "(" + item.offer_id + ") " + item["offer_name"].substring(0, 28);
      } else if (groupBy == "affiliate") {
        name_tag = item["affiliate_name"];
      } else {
        name_tag = item["interval"];
      }
      return {
        name: name_tag,
        Clicks: item.click_count,
        Conversions: item.conversion_count,
      };
    });

    if (groupBy == "offer") $return = $return.reverse(); //Sort largest rows at the top
    return $return;
  };

  return (
    <div
      onClick={(e: any) => {
        //Only move menu if clicking on a bar in graph
        if (
          e.target.nodeName === "rect" &&
          e.target.attributes.fill.nodeValue !== "transparent"
        ) {
          const xCoord = e.pageX;
          const yCoord = e.pageY;
          setContextMenuPosition({ left: xCoord, top: yCoord });
        } else {
          whisperRef.current?.close();
          setActiveRow({ index: -1 });
        }
      }}
    >
      <Whisper
        ref={whisperRef}
        enterable
        trigger="click"
        speaker={(props, ref) => {
          const { left, top } = props;
          return <Overlay style={{ left, top }} ref={ref} />;
        }}
      >
        <NivoBar
          data={formatGraphData(data)}
          keys={graphKeys}
          indexBy="name"
          isInteractive={isLoading === false}
          margin={{ top: 15, right: 0, bottom: 80, left: 220 }}
          padding={0.2}
          colors={{ scheme: "nivo" }}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          maxValue={
            Array.isArray(data)
              ? Math.round(
                  parseInt(
                    data.reduce(
                      (prev: any, curr: any) => {
                        return parseInt(prev.click_count) >
                          parseInt(curr.click_count)
                          ? prev
                          : curr;
                      },
                      { click_count: 0 }
                    ).click_count
                  ) * 1.06
                )
              : 100
          }
          minValue={0}
          width={1100}
          //Determine a dynamic height based on number of rows in data set.
          height={
            groupBy == "offer"
              ? 700
              : data.length > 12
              ? 500 + data.length * 25
              : 500
          }
          enableLabel={true}
          axisBottom={{
            tickSize: 3,
            tickPadding: 3,
            tickRotation: 0,
            legend: "Clicks",
            legendPosition: "middle",
            legendOffset: 36,
          }}
          enableGridX={true}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend:
              groupBy == "offer"
                ? "Affiliate Offers"
                : groupBy == "hour"
                ? "Date / Time"
                : "Date",
            legendOffset: -210,
            legendPosition: "middle",
          }}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          layout="horizontal"
          groupMode="grouped"
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          onClick={(e: any) => {
            if (groupBy == "offer") {
              setActiveRow({ index: e.index });
            } else if (groupBy == "date") {
              drilldownHour(data[e.index]);
            } else if (groupBy == "affiliate") {
              drilldownAffiliate(data[e.index]);
            }
          }}
        />
      </Whisper>
    </div>
  );
};

export default CakeOffersBarGraph;

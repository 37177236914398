import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyDjxvmnOFt_FvNRfZHd9IJm2elUcluOyFk",
  authDomain: "auth.dashboard.admediary.com", //"authentication-8c8d4.firebaseapp.com",
  databaseURL: "https://authentication-8c8d4.firebaseio.com",
  projectId: "authentication-8c8d4",
  storageBucket: "authentication-8c8d4.appspot.com",
  messagingSenderId: "23970361646",
  appId: "1:23970361646:web:c5fbb39f7f6101625d0abf",
  measurementId: "G-2T0W0PBXGZ",
};

firebase.initializeApp(config);
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

import React from "react";
import { FlexboxGrid, Icon, Row, Table } from "rsuite";
import {
  FloatCell,
  HeaderSummaryToolTip,
  IconToolTip,
  NumberCell,
  ParentCell,
  PercentCell,
} from "../Table";
import SelectPicker from "rsuite/lib/SelectPicker";
import { sortTree } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { format, parseISO } from "date-fns";
import { exportCsv } from "../../@Utils/Export";
import { float, percent, thousands } from "../../@Utils/Format";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Title from "../Title";
import { forEach } from "lodash";
import { Query } from "../../@Utils/AdmediaryApi";

const { Column, ColumnGroup, HeaderCell, Cell } = Table;

type ServiceResponseTimesProps = {
  startDate?: Date;
  endDate?: Date;
  serviceId?: number;
};

const ServiceResponseTimes: React.FC<ServiceResponseTimesProps> = ({
  startDate,
  endDate,
  serviceId = 0,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState("date");
  const [tableData, setTableData] = React.useState<any[]>([{}]);
  const [isLoading, setIsLoading] = React.useState(true);

  const params = {
    start_date: startDate || Admediary.start,
    end_date: endDate || Admediary.end,
    service_id: serviceId,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);

    var sortedData = tableData.sort((curr: any, next: any) => {
      var [src, dest] = [curr.timestamp, next.timestamp];
      switch (column) {
        case "average_duration":
          [src, dest] = [curr.average_duration, next.average_duration];
          break;
        case "transaction_count":
          [src, dest] = [curr.transaction_count, next.transaction_count];
          break;
        case "date":
          var [src, dest] = [curr.timestamp, next.timestamp];
          break;
      }
      if (type == "desc") {
        return src < dest ? 1 : -1;
      }
      return src > dest ? 1 : -1;
    });

    setTableData(sortedData);
  };

  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const respData: any = await Query(
        "service_queue_duration_results",
        params
      );

      setTableData(formatData(respData.data));
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatData = (data: any[]) => {
    //Do some calculations to scale row colors for graph
    let averageDuration =
      data.reduce((total, row) => {
        return total + row.average_duration;
      }, 0) / data.length;

    //Calculate standard deviation of rows
    let stdDev = data.reduce((total, row) => {
      return total + Math.pow(row.average_duration - averageDuration, 2);
    }, 0);
    stdDev = Math.sqrt(stdDev / (data.length - 1));

    const varianceClassList = [
      "",
      " med-variance",
      " high-variance",
      " very-high-variance",
    ];
    var newTableData = data.map((row) => {
      row.timestamp = `${row.month}/${row.day}   ${row.hour.padStart(
        2,
        "0"
      )}:00`;
      row.date = `${row.month}/${row.day}`;
      row.time = `${row.hour.padStart(2, "0")}:00`;

      //Assign a row color class based on the number of standard deviations away the row is
      var varianceColorClass = Math.round(
        (row.average_duration - averageDuration) / stdDev
      );
      varianceColorClass =
        varianceColorClass > 0
          ? varianceColorClass > 3
            ? 3
            : varianceColorClass
          : 0;
      row.rowClass = varianceClassList[varianceColorClass];

      //Calculate color using std deviation
      var varianceColor = Math.round(
        255 / (Math.abs(row.average_duration - averageDuration) / stdDev)
      );
      varianceColor =
        varianceColor > 0 ? (varianceColor > 255 ? 255 : varianceColor) : 0;

      //Convert to a shade of red.
      var blueGreen = varianceColor.toString(16);
      blueGreen = blueGreen.padEnd(4, blueGreen).toUpperCase();
      row.color = "FF" + blueGreen;

      return row;
    });
    return newTableData;
  };

  return (
    <>
      <Table
        rowKey={"timestamp"}
        height={600}
        headerHeight={50}
        virtualized
        affixHeader
        affixHorizontalScrollbar
        data={tableData}
        loading={isLoading === true}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
      >
        <Column width={70} fixed sortable>
          <HeaderCell>Date</HeaderCell>
          <Cell dataKey="date" />
        </Column>
        <Column width={70} fixed>
          <HeaderCell>Hour</HeaderCell>
          <Cell dataKey="time" />
        </Column>
        <Column align="right" width={200} sortable resizable>
          <HeaderCell>Average Duration</HeaderCell>
          <Cell dataKey="average_duration">
            {(rowData: any) => (
              <>{parseFloat(rowData.average_duration).toFixed(4)} seconds</>
            )}
          </Cell>
        </Column>
        <Column align="right" width={150} sortable>
          <HeaderCell>Transaction Count</HeaderCell>
          <NumberCell dataKey="transaction_count" />
        </Column>
        <Column align="right" width={60}>
          <HeaderCell></HeaderCell>
          <Cell>
            {(rowData: any) => {
              var className =
                typeof rowData !== "undefined" &&
                typeof rowData.rowClass !== "undefined"
                  ? rowData.rowClass
                  : "";
              if (rowData.rowClass != "") {
                return (
                  <Icon icon="exclamation-triangle" className={className} />
                );
              }
            }}
          </Cell>
        </Column>
      </Table>
    </>
  );
};

export default ServiceResponseTimes;

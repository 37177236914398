import React from "react";
import { Icon, Nav, Navbar } from "rsuite";
// import {Link} from "react-router-dom";

type NavToggleType = {
  expand?: any;
  onChange?: any;
  styles?: any;
};

// const iconStyles = {
//     width: 56,
//     height: 56,
//     lineHeight: "56px",
//     textAlign: "center" as const,
// };

const NavToggle: React.FC<NavToggleType> = ({ expand, onChange, styles }) => {
  return (
    <Navbar
      appearance="inverse"
      className="nav-toggle"
      style={{ overflow: "hidden" }}
    >
      <Navbar.Body>
        <Nav pullRight className="testingNav" style={{ width: "100%" }}>
          <Nav.Item
            onClick={onChange}
            style={{ textAlign: "center", width: "100%" }}
          >
            <Icon
              icon="logo-analytics"
              size="lg"
              style={{
                verticalAlign: 0,
                float: "left",
                display: expand ? "block" : "none",
              }}
            />
            {/*display: expand ? 'block' : 'none'*/}
            <span
              style={{
                verticalAlign: 0,
                marginLeft: 14,
                float: "left",
                fontSize: "1.2em",
                display: expand ? "block" : "none",
              }}
            >
              {" "}
              AdMediary
            </span>
            <Icon
              icon={expand ? "angle-left" : "angle-right"}
              style={{ float: "right" }}
            />
          </Nav.Item>
        </Nav>
        {/*<Nav pullRight>*/}

        {/*  /!*<span style={{ marginLeft: 14, float: "left",  padding: 18, fontSize: 16, height: 56 }}> AdMediary</span>*!/*/}
        {/*  <Nav.Item*/}
        {/*    onClick={onChange}*/}
        {/*    style={{ width: 56, textAlign: "center" }}*/}
        {/*  >*/}

        {/*    <Icon icon={expand ? "angle-left" : "angle-right"} style={{float: 'left'}}/>*/}

        {/*  </Nav.Item>*/}
        {/*</Nav>*/}
      </Navbar.Body>
    </Navbar>
  );
};

export default NavToggle;

import React from "react";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const Verticals: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const config: any = Admediary.config;
  const location = useLocation();
  const [showing, setShowing] = useState(false);
  const [categories, setCategories] = useState(config.categories);

  React.useEffect(() => {
    location.pathname === "/direct-posts/post-summary" ||
    location.pathname === "/direct-posts/post-leads" ||
    location.pathname === "/products/performance" ||
    location.pathname === "/data/sms-delivery" ||
    location.pathname === "/products/products" ||
    location.pathname === "/data/voice-campaign" ||
    location.pathname === "/data/voice-activity" ||
    location.pathname === "/products/data-validation" ||
    location.pathname === "/affiliates/common-list" ||
    location.pathname === "/buyers/routing" ||
    location.pathname === "/data/data-report-incoming" ||
    location.pathname === "/products/buy-hold" ||
    location.pathname === "/flow/tree-report" ||
    location.pathname === "/products/record-search" ||
    location.pathname === "/products/campaigns" ||
    location.pathname === "/data/active-sources" ||
    location.pathname === "/data/outgoing-report" ||
    location.pathname === "/products/esign-doc" ||
    location.pathname === "/affiliates/esign-affiliates" ||
    location.pathname === "/cake/cake-offers" ||
    location.pathname === "/reports/external-reports" ||
    location.pathname === "/reports/call-programs" ||
    location.pathname === "/data/services" ||
    location.pathname === "/campaigns/numbers" ||
    location.pathname === "/leads/disposition-lookup" ||
    location.pathname === "/products/categories"
      ? hideSelect()
      : setShowing(true);

    if (location.pathname === "/products/gp-report") {
      const gp_categories = config.categories.filter((obj: any) => {
        return (
          obj.category_id === 7 ||
          obj.category_id === 9 ||
          obj.category_id === 11 ||
          obj.category_id === 10 ||
          obj.category_id === 12 ||
          obj.category_id === 15 ||
          obj.category_id === 5 ||
          obj.category_id === 8 ||
          obj.category_id === 13 ||
          obj.category_id === 6
        );
      });
      setCategories(gp_categories);
    } else if (
      location.pathname === "/affiliates/esign-affiliates" ||
      location.pathname === "/products/esign-doc"
    ) {
      setCategory(7);
    } else {
      setCategories(config.categories);
    }
  }, [location]);

  const hideSelect = () => {
    Admediary.setCategory(null);
    setShowing(false);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setCategory = (data: any) => {
    Admediary.setCategory(data);
  };

  const Select = () => (
    <SelectPicker
      placeholder="All Categories"
      data={categories}
      labelKey="category_name"
      valueKey="category_id"
      onChange={setCategory}
      value={Admediary.category}
      menuStyle={{ width: "340px" }}
    />
  );

  return <div>{showing ? <Select /> : null}</div>;
};

export default Verticals;

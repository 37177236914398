import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  InputNumber,
  DatePicker,
  Checkbox,
  CheckboxGroup,
} from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import moment from "moment/moment";
import _ from "lodash";

const { NumberType, DateType, ArrayType } = Schema.Types;

const model = Schema.Model({
  cap_id: NumberType(),
  product_id: NumberType().isRequired("Product ID is required."),
  affiliate_id: NumberType().isRequired("Affiliate ID is required."),
  cap: NumberType(),
  buyer_contract_cap_cap_interval: NumberType().isRequired(
    "Cap Interval is required."
  ),
});

type ProductAffiliateCapFormType = {
  capId?: number;
  productId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  defaultIntervalList?: any;
  startDateRequired?: boolean;
  affIdRequired?: boolean;
};

const ProductAffiliateCapForm: React.FC<ProductAffiliateCapFormType> = ({
  capId = 0,
  productId = 0,
  formRef = null,
  parentCallback = () => {},
  defaultIntervalList = [],
  startDateRequired = false,
  affIdRequired = false,
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    cap_id: 0,
    product_id: productId,
    buyer_contract_cap_cap_interval: "",
    cap: 1,
    default: [],
    affiliate_id: null,
    active: [],
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});
  const [isDefault, setIsDefault] = React.useState(false);

  const intervalList = [
    { value: 1, label: "Daily" },
    { value: 7, label: "Weekly" },
    { value: 30, label: "Monthly" },
    { value: 0, label: "Lifetime" },
    { value: -1, label: "Custom" },
  ];

  const params = {
    cap_id: capId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.cap_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_cap", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    if (!record.default) {
      record.default = record.affiliate_id === -1 ? [1] : [];
    }

    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    if (data.default && data.default[0] === 1) {
      setIsDefault(true);
      setFormValue({
        ...data,
        ...{
          affiliate_id: -1,
        },
      });
    } else {
      setIsDefault(false);
      setFormValue({
        ...data,
        ...{ active: [] },
      });
    }

    if (
      data.default &&
      data.default[0] === 1 &&
      data.buyer_contract_cap_cap_interval === -1
    ) {
      setFormValue({
        ...data,
        ...{
          buyer_contract_cap_cap_interval: "",
        },
      });
    }

    if (parentCallback instanceof Function) {
      data.cap_end_date =
        data.cap_end_date &&
        data.cap_end_date !== null &&
        data.buyer_contract_cap_cap_interval === -1
          ? data.cap_end_date
          : null;
      data.cap_start_date =
        data.cap_start_date &&
        data.cap_start_date !== null &&
        data.buyer_contract_cap_cap_interval === -1
          ? data.cap_start_date
          : null;

      parentCallback({
        ...data,
        cap_start_date:
          data.cap_start_date !== null
            ? moment(new Date(data.cap_start_date)).format("YYYY-MM-DD")
            : null,
        cap_end_date:
          data.cap_end_date !== null
            ? moment(new Date(data.cap_end_date)).format("YYYY-MM-DD")
            : null,
      });
    }
  };

  // const handleIntervalList = () => {
  //   if (formValue.default && formValue.default.length) {
  //     if (!defaultIntervalList.includes(-1)) {
  //       defaultIntervalList.push(-1);
  //     }
  //   }
  //   const defaultList = _.cloneDeep(defaultIntervalList);
  //   if (defaultList.includes(formValue.buyer_contract_cap_cap_interval)) {
  //     const list = defaultList.filter((item: any) => {
  //       return item !== formValue.buyer_contract_cap_cap_interval;
  //     });
  //
  //     if (Array.isArray(list)) {
  //       return intervalList.filter((item: any) => {
  //         return !list.includes(item.value);
  //       });
  //     }
  //   } else if (
  //     formValue.default &&
  //     formValue.default.length &&
  //     formValue.cap_id === 0
  //   ) {
  //     return intervalList.filter((item: any) => {
  //       return !defaultIntervalList.includes(item.value);
  //     });
  //   } else {
  //     return intervalList;
  //   }
  // };

  const handleIntervalList = () => {
    if (formValue.default && formValue.default.length) {
      if (!defaultIntervalList.includes(-1)) {
        defaultIntervalList.push(-1);
      }
    }

    if (formValue.default && formValue.default.length) {
      return intervalList.filter((item: any) => {
        return (
          !defaultIntervalList.includes(item.value) ||
          (formValue.buyer_contract_cap_cap_interval === item.value &&
            item.value !== -1)
        );
      });
    } else {
      return intervalList;
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Default" name="default" accepter={CheckboxGroup}>
        <Checkbox value={1} />
      </Field>
      <Field
        label="Affiliate"
        name="affiliate_id"
        accepter={SelectPicker}
        placeholder="Select Affiliate"
        data={
          !isDefault
            ? config.affiliates.map((affiliate: any) => ({
                label: `${affiliate.affiliate_id} - ${affiliate.affiliate_name}`,
                value: parseInt(affiliate.affiliate_id),
              }))
            : [{ label: "0 - Default", value: 0 }]
        }
        cleanable={false}
        disabled={isDefault}
        className={affIdRequired ? "error" : ""}
      />
      <Field
        label="Cap"
        name="cap"
        accepter={InputNumber}
        defaultValue={1}
        min={1}
      />
      <Field
        label="Cap Interval"
        name="buyer_contract_cap_cap_interval"
        placeholder="Select Interval"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={handleIntervalList()}
      />
      {parseFloat(formValue.buyer_contract_cap_cap_interval) === -1 && (
        <Field
          label="Start Date"
          name="cap_start_date"
          accepter={DatePicker}
          className={startDateRequired === true ? "error" : ""}
        />
      )}
      {parseFloat(formValue.buyer_contract_cap_cap_interval) === -1 && (
        <Field label="End Date" name="cap_end_date" accepter={DatePicker} />
      )}
      {formValue.affiliate_id === -1 && formValue.default[0] === 1 && (
        <Field label="Active" name="active" accepter={CheckboxGroup}>
          <Checkbox value={1} />
        </Field>
      )}
    </Form>
  );
};

export default ProductAffiliateCapForm;

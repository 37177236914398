import React from "react";
import { Button, ButtonToolbar, Drawer, Icon, Notification } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerContractFilterForm from "../Products/BuyerContractFilterForm";
import { Query } from "../../@Utils/AdmediaryApi";
import _ from "lodash";
import { FormInstance } from "rsuite/lib/Form/Form";

type BuyerContractFilterType = {
  filterId: number;
  refreshData?: () => void;
};

const BuyerContractFilter: React.FC<BuyerContractFilterType> = ({
  filterId = 0,
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }

    if (Array.isArray(data.dynamic)) {
      data.dynamic = data.dynamic.length ? data.dynamic[0] : 0;
    }

    if (Array.isArray(data.one_of)) {
      data.one_of = data.one_of.length ? data.one_of[0] : 0;
    }

    data.evaluator = data.dynamic ? data.evaluator : "";
    data.filter_field = !data.dynamic ? data.filter_field : "";
    data.filter_values = !data.dynamic ? data.filter_values : "";

    (async () => {
      try {
        await Query("buyer_contract_filter_update", data);
        Notification.success({
          title: "Success",
          description: "Buyer Contract Filter has been updated",
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!filterId ? "Edit" : "Add"} Buyer Contract Filter
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <BuyerContractFilterForm
          filterId={filterId}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            disabled={Admediary.userDisable}
          >
            Save
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default BuyerContractFilter;

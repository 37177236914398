import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Form, Loader, Schema } from "rsuite";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  header_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  header: StringType().isRequired("Text is required."),
  value: StringType().isRequired("Value is required."),
});

type BuyerContractPostHeaderFormType = {
  headerId?: number;
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractPostHeaderForm: React.FC<BuyerContractPostHeaderFormType> = ({
  headerId = 0,
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const initItem = {
    header_id: 0,
    contract_id: contractId,
    header: "",
    value: "",
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    header_id: headerId,
    contract_id: contractId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.header_id === 0) {
      setData(initItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_post_header", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  // Render the component
  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Header" name="header" />
      <Field label="Value" name="value" />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractPostHeaderForm;

import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Modal,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";
import Config from "../../@Config/Admediary";
import { thousands, percent } from "../../@Utils/Format";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import DataServiceList from "./DataServiceList";
import { exportCsv } from "../../@Utils/Export";
import Service from "../Drawers/Service";
import Domain from "../Drawers/Domain";
import DataServiceClone from "./DataServiceClone";

const DataServices: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const defaultGroupValue = 1;
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [cloneShow, setCloneShow] = React.useState(false);

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const groupByList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const params = {
    service_id: 0,
    active: group,
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApi("data_service_list", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddService = () => {
    Admediary.openDrawer(<Service serviceId={0} />, "lg");
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = [
      "service_id",
      "data_source_id",
      "company_name",
      "description",
      "data_provider",
      "data_source",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "service_id" || field === "data_source_id") {
          return include(item[field].toString()); // convert contract_id to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  const hideCloneModal = () => {
    setCloneShow(false);
  };

  const handleCloneButtonClick = () => {
    setCloneShow(true);
  };

  return (
    <>
      <Title title="Service Administration" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Group By"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="value"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <IconButton
            onClick={handleAddService}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Service
          </IconButton>
          <IconButton
            onClick={handleCloneButtonClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Clone Service
          </IconButton>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="md"
            />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <DataServiceList
        data={filter(
          Array.isArray(data) && !isLoading ? data : [],
          searchQuery
        )}
        isLoading={isLoading === true}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
      <Modal size="lg" show={cloneShow} onHide={hideCloneModal} overflow={true}>
        <Modal.Header>
          <Modal.Title>Clone Service List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataServiceClone closeModal={hideCloneModal} refreshData={refresh} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataServices;

/**
 * Helper function for sorting arrays
 *
 * @param attribute
 * @param data
 * @param type
 */
export const sort = (data: any[], attribute: string, type?: string): any[] => {
  // descending order
  if (type === "desc") {
    return data.sort((a: any, b: any) => {
      return a[attribute] > b[attribute] ? -1 : 1;
    });
  }
  // ascending order
  return data.sort((a: any, b: any) => {
    return a[attribute] > b[attribute] ? 1 : -1;
  });
};

/**
 * Helper function for sorting tree-children structure
 *
 * @param attribute
 * @param data
 * @param type
 */
export const sortTree = (
  data: any[],
  attribute: string,
  type?: string
): any[] => {
  data = sort(data, attribute, type);

  data.forEach((item, index) => {
    const children = item.children;
    if (children && Array.isArray(children)) {
      data[index].children = sortTree(children, attribute, type);
    }
  });

  return data;
};

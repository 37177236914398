import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractEmailTemplateForm from "./BuyerContractEmailTemplateForm";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractEmailTemplateListType = {
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractEmailTemplateList: React.FC<BuyerContractEmailTemplateListType> = ({
  height = 650,
  autoHeight = true,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
  };
  const [data, isLoading] = useAdmediaryApi(
    "buyer_contract_email_templates",
    params
  );

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    (async () => {
      try {
        await Query("buyer_contract_email_template_update", data);

        setFormLoading(false);

        Notification.success({
          title: "Success",
          description:
            "Email Template has been " +
            (data.template_id ? "updated" : "added"),
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        setFormLoading(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_email_template_remove", {
          template_id: itemId,
        });
        Notification.success({
          title: "Success",
          description: "Email Template has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.template_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem({
      template_id: 0,
    });

    handleEditModalOpen();
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={5}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Email Template
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={100} align={"center"} fixed>
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            removeHandler={handleRemoveActionClick}
            userPermission={Admediary.userDisable}
          />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>Template ID</HeaderCell>
          <Cell dataKey="template_id" />
        </Column>
        <Column width={300} sortable resizable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="template_name" />
        </Column>
        <Column width={300} sortable resizable>
          <HeaderCell>Content</HeaderCell>
          <Cell dataKey="template_content" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Email Template?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.template_id > 0 ? "Edit" : "Add") +
          " Email Template"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
        loading={formLoading}
        hideSubmitButton={Admediary.userDisable}
      >
        <BuyerContractEmailTemplateForm
          templateId={selectedItem ? selectedItem.template_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractEmailTemplateList;

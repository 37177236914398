import React from "react";
import { Col, Grid, Row } from "rsuite";
import StatCard from "./StatCard";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

interface ICallBack {
  (data: LeadPrimaryStatCardsCallBackObject): void;
}

type LeadPrimaryStatCardsCallBackObject = {
  total: number | null;
  leads_sold: number | null;
  leads_unsold: number | null;
  leads_filtered: number | null;
  leads_rejected: number | null;
  sold_percentage: number | null;
  filtered_percentage: number | null;
};

type LeadPrimaryStatCardsProps = {
  success?: ICallBack;
};

const LeadPrimaryLeadStatCards: React.FC<LeadPrimaryStatCardsProps> = ({
  success = (data: any) => {
    /* do nothing */
  },
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  let LeadsFiltered: any = undefined,
    Total: any = undefined,
    LeadsSold: any = undefined,
    LeadsUnsold: any = undefined,
    LeadsRejected: any = undefined,
    SoldPercentage: any = undefined,
    FilteredPercentage: any = undefined;

  const [data, is_loading] = useAdmediaryApi("buyer_contract_counts", {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    category_id: Admediary.category,
    group: "",
  });
  if (!is_loading) {
    /**
     * Make sure we have a response for leads_purchased
     */
    if (Array.isArray(data) && data.length > 0) {
      const overview = data[0];
      LeadsSold = overview.leads_sold;
      LeadsFiltered = overview.filtered;
      LeadsRejected = overview.rejected;
      LeadsUnsold = LeadsFiltered + LeadsRejected;
      Total = LeadsSold + LeadsFiltered + LeadsRejected;
      SoldPercentage = Math.round((LeadsSold / Total) * 100);
      FilteredPercentage = Math.round((LeadsFiltered / Total) * 100);
    }
  }

  /**
   * Using useEffect to fire callback after component is done
   */
  React.useEffect(() => {
    if (!is_loading) {
      success({
        total: Total || null,
        leads_sold: LeadsSold || null,
        leads_filtered: LeadsFiltered || null,
        leads_rejected: LeadsRejected || null,
        leads_unsold: LeadsUnsold || null,
        sold_percentage: SoldPercentage || null,
        filtered_percentage: FilteredPercentage || null,
      });
    }
  }, [
    success,
    is_loading,
    LeadsSold,
    LeadsFiltered,
    LeadsRejected,
    LeadsUnsold,
    Total,
    SoldPercentage,
    FilteredPercentage,
  ]);

  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Total" type="number" value={Total} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Sold Leads" type="number" value={LeadsSold} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Filtered" type="number" value={LeadsFiltered} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard
            label="% Filtered"
            type="percent"
            value={FilteredPercentage}
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default React.memo(LeadPrimaryLeadStatCards);

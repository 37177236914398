import React, { useState } from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  SelectPicker,
  Checkbox,
  CheckPicker,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import BuyerBudgetsTable from "./BuyerBudgetsTable";
import { format } from "date-fns";
import { exportCsv } from "../../@Utils/Export";

const addCalculatedValues = (row: any) => {
  row.leads = isNaN(parseFloat(row.leads)) ? 0 : parseFloat(row.leads);
  row.daily_average = isNaN(parseFloat(row.daily_average))
    ? 0
    : Math.round(parseFloat(row.daily_average));
  row.days_remaining = isNaN(parseFloat(row.days_remaining))
    ? 0
    : Math.round(parseFloat(row.days_remaining));
  row.leads_remaining = isNaN(parseFloat(row.leads_remaining))
    ? 0
    : parseFloat(row.leads_remaining);
  row.percent_remaining = isNaN(parseFloat(row.percent_remaining))
    ? 0
    : Math.round(parseFloat(row.percent_remaining));
  row.earned = isNaN(parseFloat(row.earned)) ? 0 : parseFloat(row.earned);
  row.profit = isNaN(parseFloat(row.profit)) ? 0 : parseFloat(row.profit);
  row.rate = isNaN(parseFloat(row.rate)) ? 0 : parseFloat(row.rate);
  row.e_sign_leads = isNaN(parseFloat(row.e_sign_leads))
    ? 0
    : parseFloat(row.e_sign_leads);
  row.retainer_leads = isNaN(parseFloat(row.retainer_leads))
    ? 0
    : parseFloat(row.retainer_leads);
  row.revshare_leads = isNaN(parseFloat(row.revshare_leads))
    ? 0
    : parseFloat(row.revshare_leads);
  row.budget = isNaN(parseFloat(row.budget)) ? 0 : parseFloat(row.budget);
  row.dollar_budget = isNaN(parseFloat(row.dollar_budget))
    ? 0
    : parseFloat(row.dollar_budget);
  // row.budget_amount_remaining = isNaN(parseFloat(row.budget_amount_remaining))
  //   ? 0
  //   : parseFloat(row.budget_amount_remaining);
  row.is_budget_dollars = isNaN(parseFloat(row.is_budget_dollars))
    ? 0
    : parseFloat(row.is_budget_dollars);
  row.total_rev = isNaN(parseFloat(row.total_rev))
    ? 0
    : parseFloat(row.total_rev);

  const leads_percent_remaining_helper = row.budget - row.leads;
  const dollar_percent_remaining_helper = row.dollar_budget - row.total_rev;
  const leads_percent_remaining =
    row.budget !== 0 ? (leads_percent_remaining_helper / row.budget) * 100 : 0;
  const dollar_percent_remaining =
    row.dollar_budget !== 0
      ? (dollar_percent_remaining_helper / row.dollar_budget) * 100
      : 0;
  // row.budget !== 0 ? (percent_remaining_helper / row.budget) * 100 : 0,

  return {
    ...row,
    ...{
      percent_remaining:
        row.is_budget_dollars === 1
          ? dollar_percent_remaining
          : leads_percent_remaining,
      budget_amount_remaining:
        row.is_budget_dollars === 1 ? dollar_percent_remaining_helper : 0,
    },
  };
};

const addCalculatedValuesRecursively = (list: any) => {
  return list.map((row: any) => {
    let parentBudgetType = row.budget_type;
    if (row.children && Array.isArray(row.children)) {
      parentBudgetType = "";
      row.children = addCalculatedValuesRecursively(row.children);

      row.children.every((child: any) => {
        if (parentBudgetType == "") {
          parentBudgetType = child.budget_type;
        } else if (parentBudgetType != child.budget_type) {
          parentBudgetType = "Mixed";
          return false;
        }
        return true;
      });
    }
    row.budget_type = parentBudgetType;
    return addCalculatedValues(row);
  });
};

function getExportData(data: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        ...{
          buyer_id: item.buyer_id || parent.buyer_id,
          buyer_name: item.buyer_name || parent.buyer_name,
          contract_id: item.contract_id || parent.contract_id,
          contract_name: item.contract_name || parent.contract_name,
          product_id: item.product_id || parent.product_id,
          product_description:
            item.product_description || parent.product_description,
        },
        ...item,
      };

      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });

    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Product ID": item.product_id,
          "Product Description": item.product_description,
          "Buyer Name": item.buyer_name,
          "Buyer ID": item.buyer_id,
          "Contract Name": item.contract_name,
          "Contract ID": item.contract_id,
          Leads: item.leads,
          "Dollar Budget": item.dollar_budget,
          "Leads Budget": item.budget,
          "Leads Remaining": item.leads_remaining,
          "Dollar Amt Remaining": item.budget_amount_remaining,
          Earned: item.total_rev,
          "Percent Remaining": item.percent_remaining,
          "Daily Avg": item.daily_average,
          "Days Remaining": item.days_remaining,
          "Budget Interval": item.budget_type,
          "E-Sign Leads": item.e_sign_leads,
          "Retainer Leads": item.retainer_leads,
          "Revshare/Unsold Leads": item.revshare_leads,
        };
      })
  );
}

const getSummary = (data: any) => {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "leads",
    "budget",
    "leads_remaining",
    "daily_average",
    "e_sign_leads",
    "retainer_leads",
    "revshare_leads",
    "dollar_budget",
    "budget_amount_remaining",
    "total_rev",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
};

const BuyerBudgets: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [sort_type, set_sort_type] = React.useState("desc");
  const [sort_column, set_sort_column] = React.useState("leads");
  const defaultGroupValue = "buyer";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const defaultBudgetType = "all";
  const [budgetType, setBudgetType] = useState(defaultBudgetType);

  const availableContractTypeFilters = [
    "Cost Per Lead",
    "Revshare/Unsold",
    "E-Sign",
  ].map((item) => ({ label: item, value: item }));
  const [contractTypeFilter, setContractTypeFilter] = React.useState([
    "Cost Per Lead",
  ]);

  const groupByList = [
    { group: "product", label: "Product" },
    { group: "buyer", label: "Buyer" },
  ];

  const budgetTypeList = [
    { group: "all", label: "All" },
    { group: "weekly", label: "Weekly" },
    { group: "monthly", label: "Monthly" },
  ];

  //Reduce contractTypeFilter into set of boolean flags to use in the stored proc
  const constructBooleanFlagInteger = () => {
    return parseInt(
      contractTypeFilter.reduce((acc: string, curr: string, index: number) => {
        return (
          "" +
          (parseInt(acc) +
            Math.pow(
              2,
              availableContractTypeFilters.findIndex((i: any) => {
                return curr == i.label;
              })
            ))
        );
      }, "0")
    );
  };

  const params = {
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: Admediary.buyer,
    contract_id: Admediary.contract,
    group: group,
    budget_type: budgetType,
    include_cpl: contractTypeFilter.includes("Cost Per Lead") ? 1 : 0,
    include_revshare: contractTypeFilter.includes("Revshare/Unsold") ? 1 : 0,
    include_esign: contractTypeFilter.includes("E-Sign") ? 1 : 0,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "buyer_budget_report",
    params
  );

  const tableData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(tableData);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <div style={{ height: "100vh" }}>
      {/*<Title title="Buyer Budgets" />*/}
      <div className="tableTitle" style={{ color: "#023066" }}>
        <h3 style={{ paddingLeft: "10px" }}>Buyer Budgets</h3>
        {budgetType === "monthly" ? (
          <p style={{ paddingLeft: "10px", color: "red" }}>
            <Icon icon="exclamation-circle" /> Budget Resets at the End of Each
          </p>
        ) : budgetType === "weekly" ? (
          <p style={{ paddingLeft: "10px", color: "red" }}>
            <Icon icon="exclamation-circle" /> Budget Resets at End of Each Week
          </p>
        ) : (
          ""
        )}
      </div>
      <hr />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Sort By"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ float: "left", marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Budget Type"
            defaultValue={defaultBudgetType}
            value={budgetType}
            data={budgetTypeList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setBudgetType(v);
            }}
            style={{ float: "left", marginRight: 15 }}
          />

          <CheckPicker
            label="Contract Type"
            value={contractTypeFilter}
            data={availableContractTypeFilters}
            onChange={(data: string[]) => {
              setContractTypeFilter(data);
            }}
            searchable={false}
            style={{ width: 224 }}
            size="sm"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `buyer_budgets_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}_cat_${
                  Admediary.category
                }_prod_${Admediary.product}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <BuyerBudgetsTable
        data={tableData}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
        group={group}
      />
    </div>
  );
};

export default BuyerBudgets;

import React from "react";
import {
  Button,
  ButtonToolbar,
  Icon,
  Drawer,
  Form,
  Checkbox,
  Loader,
  Schema,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import { Query } from "../../@Utils/AdmediaryApi";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  buyer_name: StringType().isRequired("Buyer Name is required."),
  abbreviation: StringType().addRule((value, data) => {
    return value.toString().length > 2 || value.toString().length === 0;
  }, "Please enter more than 2 symbols"),
  email_days: NumberType("Please enter a valid number."),
  address_days: NumberType("Please enter a valid number.") || null,
  phone_days: NumberType("Please enter a valid number.") || null,
  ip_days: NumberType("Please enter a valid number.") || null,
  cake_buyer_id: NumberType("Please enter a valid number.") || null,
});

type BuyerLeadType = {
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  buyerId: number;
  reloadingData: any;
};

const BuyerLead: React.FC<BuyerLeadType> = ({
  buyerId = 0,
  formRef = null,
  parentCallback = () => {},
  reloadingData,
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const params = {
    buyer_id: buyerId,
  };
  const emptyBuyer = {
    active: 0,
    buyer_id: 0,
    contract_id: 0,
    buyer_name: "",
    abbreviation: "",
    email_days: "",
    filter_id: 0,
  };
  const [formValue, setFormValue] = React.useState(emptyBuyer);
  const [activeValue, setActiveValue] = React.useState();
  const fetchForm = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));
    if (params.buyer_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_select_api", params);
      setData(results.data);
      const activeResponse = results.data[0].active === 1;
      setActiveValue(activeResponse);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  React.useEffect(() => {});

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }
    return record;
  };

  const handleChange = (data: any) => {
    if (isNaN(data.email_days)) data.email_days = "";
    if (isNaN(data.filter_id)) data.filter_id = "";
    if (isNaN(data.contract_id)) data.contract_id = 0;
    if (data.abbreviation !== undefined)
      data.abbreviation = data.abbreviation
        .split(/\s/)
        .join("")
        .toString()
        .substring(0, 10);

    setFormValue(data);
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  const handleCheck = (data: any) => {};

  const handleClose = () => {
    Admediary.closeDrawer();
  };
  const handleSave = () => {
    // formValue.active = formContext.active ? 1 : 0;
    formValue.active = activeValue ? 1 : 0;
    // @ts-ignore
    if (formRef && formRef.current && !formRef.current.check()) {
      return;
    }
    (async () => {
      try {
        await Query("buyer_lead_update", formValue);
      } catch (e) {
      } finally {
        reloadingData();
        setIsLoading(false);
      }
    })();

    Admediary.closeDrawer();
  };
  const setActive = (data: any) => {
    const active = data ? 1 : 0;
    setActiveValue(!data);
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Edit Lead Buyer
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading ? (
          <Loader center size="lg" content="Loading..." />
        ) : (
          <Form
            ref={formRef}
            onChange={handleChange}
            onCheck={handleCheck}
            model={model}
            layout="horizontal"
            formValue={formValue}
          >
            <Field
              label="Active"
              name="active"
              accepter={Checkbox}
              checked={activeValue}
              onChange={setActive}
              value={activeValue ? true : false}
              disabled={Admediary.userDisable}
            ></Field>
            <Field
              label="Buyer name"
              name="buyer_name"
              disabled={Admediary.userDisable}
            />
            <Field
              label="Abbreviation"
              name="abbreviation"
              disabled={Admediary.userDisable}
            />
            <Field
              label="Email"
              name="email_days"
              disabled={Admediary.userDisable}
            />

            <Field
              label="Address Unique"
              name="address_days"
              disabled={Admediary.userDisable}
            />
            <Field
              label="Phone Unique"
              name="phone_days"
              disabled={Admediary.userDisable}
            />
            <Field
              label="IP Unique"
              name="ip_days"
              disabled={Admediary.userDisable}
            />
            <Field
              label="Cake Buyer ID"
              name="cake_buyer_id"
              disabled={Admediary.userDisable}
            />
          </Form>
        )}
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            disabled={Admediary.userDisable}
          >
            Save
          </Button>
          <Button onClick={() => handleClose()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};
const Field: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 150 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <ControlLabel style={{ display: "block", textAlign: "left" }}>
        {name === "email_days" ||
        name === "ip_days" ||
        name === "phone_days" ||
        name === "address_days"
          ? "days"
          : ""}
      </ControlLabel>
      <HelpBlock>{message}</HelpBlock>
    </FormGroup>
  );
};
export default BuyerLead;

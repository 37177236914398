import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HeaderSummary, NumberCell, ParentCell } from "../Table";
import { float, thousands } from "../../@Utils/Format";
import { sortTree } from "../../@Utils/Sorting";
import VoiceCampaignLogEvent from "../Drawers/VoiceCampaignLogEvent";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * VoiceActivityTreeTable is a separated component to prevent a scroll-to-top bug
 */
type VoiceActivityTreeTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  group: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const VoiceActivityTreeTable: React.FC<VoiceActivityTreeTableProps> = (
  props: any
) => {
  const summary = props.summary;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={sortData(data)}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={110} fixed sortable resizable align="right">
        <HeaderCell>Campaign ID</HeaderCell>
        <ParentCell dataKey="campaign_id" />
      </Column>
      <Column width={300} fixed sortable resizable treeCol>
        <HeaderCell>Campaign/Carrier/Dialed</HeaderCell>
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column align="right" sortable resizable width={120}>
        <HeaderCell>Dialed From</HeaderCell>
        <Cell dataKey="phone_number" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Calls</HeaderCell>
        <Cell dataKey="total_calls">
          {(rowData: any) => (
            <span>
              {rowData.carrier_id || rowData.campaign_id
                ? rowData.total_calls
                : ""}
            </span>
          )}
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Human</HeaderCell>
        <Cell dataKey="human_calls">
          {(rowData: any) => (
            <span>
              {rowData.human_calls > 0
                ? rowData.human_calls
                : rowData.carrier_id
                ? 0
                : ""}
            </span>
          )}
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Voicemail</HeaderCell>
        <Cell dataKey="machine_calls">
          {(rowData: any) => (
            <span>
              {rowData.machine_calls > 0
                ? rowData.machine_calls
                : rowData.carrier_id
                ? 0
                : ""}
            </span>
          )}
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Affiliate</HeaderCell>
        <Cell dataKey="affiliate" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>SID1</HeaderCell>
        <Cell dataKey="sid1" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Total Time"
            summary={float(summary.duration, 2)}
          />
        </HeaderCell>
        <Cell dataKey="duration">
          {(rowData: any) =>
            rowData.campaign_id && rowData["duration"] > 0
              ? float(rowData["duration"], 0)
              : thousands(rowData["duration"] || 0)
          }
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Call Time"
            summary={float(summary.dialed_call_duration, 2)}
          />
        </HeaderCell>
        <Cell dataKey="dialed_call_duration">
          {(rowData: any) =>
            rowData.campaign_id && rowData["dialed_call_duration"] > 0
              ? float(rowData["dialed_call_duration"], 0)
              : thousands(rowData["dialed_call_duration"] || 0)
          }
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Conversion" summary={summary.converted} />
        </HeaderCell>
        <NumberCell dataKey="converted" />
      </Column>
      *
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Payable" summary={summary.payable} />
        </HeaderCell>
        <NumberCell dataKey="payable" />
      </Column>
      <Column align="right" sortable resizable width={200}>
        <HeaderCell>Retreaver UUID</HeaderCell>
        <Cell dataKey="uuid" />
      </Column>
      <Column align="right" sortable resizable width={90}>
        <HeaderCell>Retreaver Data</HeaderCell>
        <Cell dataKey="json_data">
          {(rowData: any) => {
            let value = rowData.json_data;

            if (!value || !isJson(value)) {
              return value;
            }

            let data = JSON.parse(value);

            if (data.length > 0) {
              // Remove null items
              data = data.filter((item: any) => !!item);
              // Rebuild value without null items
              value = JSON.stringify(data);
            } else {
              data = [data];
            }

            let dataRows: any[] = [];

            data.forEach((item: any) => {
              Object.keys(item).forEach((key) => {
                dataRows.push({ key, value: item[key] });
              });
            });

            const speaker = (
              <Popover>
                <div style={{ height: 400, width: 400, overflow: "auto" }}>
                  <table>
                    <tbody>
                      {dataRows.map((dataRow: any, index) => (
                        <tr key={index}>
                          <td>{dataRow.key}</td>
                          <td style={{ wordBreak: "break-all" }}>
                            {JSON.stringify(dataRow.value)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Popover>
            );

            return (
              <Whisper
                trigger="hover"
                placement="leftStart"
                enterable
                speaker={speaker}
              >
                <span>{value}</span>
              </Whisper>
            );
          }}
        </Cell>
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>RCID</HeaderCell>
        <Cell dataKey="rcid" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>RAFID</HeaderCell>
        <Cell dataKey="rafid" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>RSID</HeaderCell>
        <Cell dataKey="rsid">
          {(rowData: any) => {
            const value = rowData.rsid;
            const speaker = (
              <Popover>
                <div style={{ height: 100, width: 200, overflow: "auto" }}>
                  {value}
                </div>
              </Popover>
            );

            return (
              <Whisper
                trigger="hover"
                placement="leftStart"
                enterable
                speaker={speaker}
              >
                <span>{value}</span>
              </Whisper>
            );
          }}
        </Cell>
      </Column>
      <Column align="right" sortable resizable width={150}>
        <HeaderCell>Call Date</HeaderCell>
        <Cell dataKey="call_date">
          {(rowData: any) => (
            <span>
              {rowData.call_date
                ? format(new Date(rowData.call_date), "MM-dd-Y HH:mm")
                : ""}
            </span>
          )}
        </Cell>
      </Column>
      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell summary={summary} />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    {row.log_id ? (
      <Dropdown.Item eventKey="details">Details</Dropdown.Item>
    ) : null}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, summary, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} checked={checked} summary={summary} />
  </Popover>
);

type TableWhisper = {
  row: any;
  summary: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);

  if (row.campaign_id) return null;

  const triggerRef = React.createRef();

  row.checked = props.row.checked ? props.row.checked : false;

  const handleSelectMenu = (eventKey: string, event: any) => {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["details", <VoiceCampaignLogEvent row_data={row} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["details", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  };

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, summary, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} summary={summary} />
    </Cell>
  );
};

const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export default VoiceActivityTreeTable;

import React from "react";
import { Table, IconButton, Icon } from "rsuite";
import { CheckCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import PingTreesModal from "../Drawers/PingTreesModal";
import { FormContext } from "../../@Context/FormContext";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
const { Column, HeaderCell, Cell } = Table;

/**

 */
type PingTreesListProps = {
  data: any;
  fetchData: any;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const PingTreesList: React.FC<PingTreesListProps> = ({
  data = [],
  isLoading = false,
  fetchData,
  // categoryId = 0,
  sortType = "asc",
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const Forms = React.useContext(FormContext);
  const admediaryContext = React.useContext(AdmediaryContext);

  const handleEditAction = (rowData: any) => {
    Forms.setActive(
      rowData.active === "1" || rowData.active === 1 ? true : false
    );
    Forms.setTree(rowData.tree_id);
    Forms.setCategory(rowData.category_id);
    Forms.setProduct(rowData.product_id);
    Forms.setDescription(rowData.description);
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <PingTreesModal
        rowData={rowData}
        treeId={rowData.tree_id}
        fetchData={fetchData}
      />,
      "lg"
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="center" width={135} fixed sortable resizable>
          <HeaderCell>Tree ID</HeaderCell>
          <Cell dataKey="tree_id" />
        </Column>
        <Column align="center" width={135} sortable resizable>
          <HeaderCell>Category ID</HeaderCell>
          <Cell dataKey="category_id" />
        </Column>
        <Column align="center" width={135} sortable resizable>
          <HeaderCell>Product ID</HeaderCell>
          <Cell dataKey="product_id" />
        </Column>
        <Column align={"center"} width={200} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>

        <Column width={300} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>

        <Column width={300} sortable resizable>
          <HeaderCell>Action</HeaderCell>
          <ActionCell editHandler={handleEditAction} />
        </Column>
      </Table>
    </>
  );
};
const ActionCell = ({ rowData, editHandler, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={() => editHandler(rowData)}
        icon={<Icon icon="edit2" />}
      />
    </Cell>
  );
};
export default PingTreesList;

import React from "react";
import {
  Button,
  ControlLabel,
  FlexboxGrid,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Modal,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import _ from "lodash";
import { CheckCell, EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import { FormInstance } from "rsuite/lib/Form/Form";
import FilterForm from "./FilterForm";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

const FiltersField: React.FC<any> = ({
  name,
  label,
  onAfterSave,
  error,
  gridData,
  message = null,
  buttonLabel = "Select",
  modalTitle = "Select Clients",
  ...props
}) => {
  const [data, setData] = React.useState(gridData);
  const [show, setShow] = React.useState(false);
  const [showAfterDelay, setShowAfterDelay] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState();
  const delayIntervalRef: any = React.useRef(null);
  const Admediary = React.useContext(AdmediaryContext);

  // Set default values
  props.style = {
    ...{ width: 300 },
    ...props.style,
  };

  const handleButtonClick = () => {
    // Clone data
    const items: any = _.cloneDeep(gridData);

    setShow(true);
    setShowAfterDelay(true);
    setData(items);

    if (delayIntervalRef.current) {
      clearTimeout(delayIntervalRef.current);
    }
    delayIntervalRef.current = setTimeout(() => {
      setShowAfterDelay(false);
    }, 250);
  };

  const hideModal = () => {
    setShow(false);
  };

  const cancelModal = () => {
    hideModal();
  };

  const [searchQuery, setSearchQuery] = React.useState();
  const intervalRef: any = React.useRef(null);

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   */
  const debounceSearchTerm = (value: any) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 50);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    // Don't show data to remove
    data = data.filter((item: any) => item.service_id !== 0);

    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => {
      return v.toString().toLowerCase().includes(search);
    };

    const searchFields = [
      "filter_type",
      "operator",
      "filter_value",
      "evaluator",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) => {
      return searchFields.some((field: string) => {
        return include(item[field]);
      });
    });
  };

  const handleSaveClick = () => {
    setShow(false);

    if (typeof onAfterSave === "function") {
      onAfterSave(data);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.filter_id);

    handleClose();
  };

  const removeItem = (id: number) => {
    const index = data.findIndex((item: any) => item.filter_id === id);

    // Set service ID to zero to remove it after saving of service
    data[index].service_id = 0;

    setData([...data]);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const initItem = { filter_id: 0, include: [] };
  const [formValue, setFormValue] = React.useState(initItem);
  const mainFormRef = React.createRef<FormInstance>();

  const handleAddItemClick = () => {
    setSelectedItem(initItem);

    handleEditModalOpen();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleSaveItem = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    const formValues: any = _.cloneDeep(formValue);

    if (Array.isArray(formValues.include)) {
      formValues.include = formValues.include.length
        ? formValues.include[0]
        : 0;
    }

    if (formValues.filter_id === 0) {
      data.unshift(formValues);
    } else {
      const index = data.findIndex(
        (item: any) => item.filter_id === formValues.filter_id
      );
      data[index] = formValues;
    }

    handleEditModalClose();
  };

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const getFilterTypeTitle = (type: string) => {
    const types = new Map([
      ["percentage", "Percentage"],
      ["daily_cap", "Daily Cap"],
      ["daily_cap_queue", "Daily Cap/Queue"],
      ["domain_mx", "Domain MX"],
      ["domain_name", "Domain Name"],
      ["source_url", "Source URL"],
      ["sql", "SQL"],
    ]);

    return types.has(type) ? types.get(type) : null;
  };

  const getFilterOperatorTitle = (type: string, operator: string) => {
    if (["daily_cap", "daily_cap_queue", "sql", "percentage"].includes(type)) {
      return operator;
    }

    const operators = new Map([
      ["equal_to", "Equal To"],
      ["not_equal_to", "Not Equal To"],
      ["contains", "Contains"],
      ["does_not_contain", "Does Not Contain"],
    ]);

    return operators.has(operator) ? operators.get(operator) : null;
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label}</ControlLabel>
      <InputGroup {...props} className="inner-button-field-group">
        <FormControl name={name} errorMessage={error} disabled />
        <InputGroup.Button
          onClick={handleButtonClick}
          disabled={Admediary.userDisable}
        >
          <Icon icon="filter" />
        </InputGroup.Button>
      </InputGroup>

      <Modal size="lg" show={show} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
            <FlexboxGrid.Item colspan={19}>
              <InputGroup
                inside
                style={{ width: 300, marginRight: 15, display: "inline-block" }}
              >
                <Input
                  placeholder="Search"
                  onChange={debounceSearchTerm}
                  size="md"
                  value={searchQuery}
                />
                <InputGroup.Addon>
                  <Icon icon="search" />
                </InputGroup.Addon>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
              <IconButton
                onClick={handleAddItemClick}
                icon={<Icon icon="plus-circle" />}
                placement="left"
                size="sm"
                appearance="ghost"
              >
                Add Filter
              </IconButton>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {showAfterDelay ? (
            <Loader size="md" speed="slow" vertical center />
          ) : (
            <Table
              data={filter(Array.isArray(data) ? data : [], searchQuery)}
              shouldUpdateScroll={false}
            >
              <Column width={100} align={"center"} fixed>
                <HeaderCell>Action</HeaderCell>
                <EditRemoveActionCell
                  editHandler={handleEditActionClick}
                  removeHandler={handleRemoveActionClick}
                />
              </Column>
              <Column width={60} align="center">
                <HeaderCell>Include</HeaderCell>
                <CheckCell dataKey="include" />
              </Column>
              <Column width={140}>
                <HeaderCell>Filter Type</HeaderCell>
                <Cell dataKey="filter_type">
                  {(rowData: any) => getFilterTypeTitle(rowData.filter_type)}
                </Cell>
              </Column>
              <Column width={150}>
                <HeaderCell>Operator</HeaderCell>
                <Cell dataKey="operator">
                  {(rowData: any) =>
                    getFilterOperatorTitle(
                      rowData.filter_type,
                      rowData.operator
                    )
                  }
                </Cell>
              </Column>
              <Column>
                <HeaderCell>Filter Value</HeaderCell>
                <Cell dataKey="filter_value" />
              </Column>
              <Column flexGrow={1}>
                <HeaderCell>Evaluator</HeaderCell>
                <Cell dataKey="evaluator">
                  {(rowData: any) => (
                    <Whisper
                      trigger="hover"
                      enterable={true}
                      placement="autoVerticalStart"
                      speaker={
                        <Popover>
                          <span>{rowData.evaluator}</span>
                        </Popover>
                      }
                    >
                      <span>{rowData.evaluator}</span>
                    </Whisper>
                  )}
                </Cell>
              </Column>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={handleSaveClick}>
            Save
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Filter?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.filter_id > 0 ? "Edit" : "Add") +
          " Filter"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSaveItem}
      >
        <FilterForm
          id={selectedItem ? selectedItem.filter_id : 0}
          data={selectedItem ? selectedItem : {}}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </FormGroup>
  );
};

export default FiltersField;

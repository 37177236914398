import React from "react";
import { DatePicker, Form, Schema } from "rsuite";
import Field from "../Field";
import SelectPicker from "rsuite/lib/SelectPicker";

const { DateType, NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  schedule_id: NumberType(),
  service_id: NumberType(),
  day_of_week: StringType().isRequired("Day of Week is required."),
  end_time: DateType().isRequired("Start Time is required."),
  start_time: DateType().isRequired("End Time is required."),
});

type ScheduleFormType = {
  id?: number;
  data?: object;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const ScheduleForm: React.FC<ScheduleFormType> = ({
  id = 0,
  data = {},
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    record.start_time = record.start_time || "00:00";
    record.end_time = record.end_time || "18:00";

    // Transform string 'HH:mm' to Date object to pass into the DatePicker component
    if (!(record.start_time instanceof Date)) {
      const startDate = new Date();
      const [hours, minutes] = record.start_time.split(":");
      startDate.setHours(hours);
      startDate.setMinutes(minutes);

      record.start_time = startDate;
    }

    if (!(record.end_time instanceof Date)) {
      const endDate = new Date();
      const [hours, minutes] = record.end_time.split(":");
      endDate.setHours(hours);
      endDate.setMinutes(minutes);

      record.end_time = endDate;
    }

    return record;
  };

  const [formValue, setFormValue] = React.useState({
    ...prepareFormData(data),
    ...{ schedule_id: id },
  });
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label="Day of Week"
        name="day_of_week"
        placeholder="Select Day of Week"
        accepter={SelectPicker}
        placement="autoVerticalEnd"
        searchable={false}
        cleanable={false}
        data={[
          { value: "mo", label: "Monday" },
          { value: "tu", label: "Tuesday" },
          { value: "we", label: "Wednesday" },
          { value: "th", label: "Thursday" },
          { value: "fr", label: "Friday" },
          { value: "sa", label: "Saturday" },
          { value: "su", label: "Sunday" },
        ]}
      />
      <Field
        label="Start Time"
        name="start_time"
        accepter={DatePicker}
        placement="autoVerticalEnd"
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
      <Field
        label="End Time"
        name="end_time"
        accepter={DatePicker}
        placement="autoVerticalEnd"
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
    </Form>
  );
};

export default ScheduleForm;

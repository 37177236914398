import { CheckPicker } from "rsuite";
import React from "react";

type ContractCheckPickerProps = {
  data: any;
  change: (data: any) => void;
  value: string;
};

const ContractCheckPicker: React.FC<ContractCheckPickerProps> = (
  props: any
) => {
  const data = props.data;
  const change = props.change;
  const value = props.value;

  return (
    <CheckPicker
      placeholder="All Contracts"
      data={Array.isArray(data) ? data : []}
      labelKey="contract_name"
      valueKey="contract_id"
      onChange={change}
      value={value}
      size="md"
      style={{ width: 340 }}
    />
  );
};

export default ContractCheckPicker;

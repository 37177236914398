import React from "react";
import { Dropdown, Icon, IconButton, Popover, Table, Whisper } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import {
  CurrencyCell,
  HeaderSummary,
  NumberCell,
  ParentCell,
  PercentCell,
} from "../Table";
import { currency, percent } from "../../@Utils/Format";
import { sort } from "../../@Utils/Sorting";
import FilterReasons from "../Drawers/FilterReasons";
import ViewLeads from "../Drawers/ViewLeads";
import Responses from "../Drawers/Responses";
import HourlyReport from "../Drawers/HourlyReport";
import ResponseTime from "../Drawers/ResponseTime";
import BuyerContract from "../Drawers/BuyerContract";
import Backend from "react-dnd-html5-backend";
import { useDrag, useDrop, DndProvider } from "react-dnd";

const { Column, HeaderCell, Cell } = Table;

const ItemTypes = {
  COLUMN: "column",
  ROW: "row",
};

function DraggableHeaderCell({
  children,
  onDrag,
  id,
  title,
  summary,
  ...rest
}: any) {
  const ref = React.useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any, monitor) {
      onDrag(item.id, id);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id, type: ItemTypes.COLUMN },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    cursor: "grab",
    opacity: isDragging ? 0 : 1,
    borderLeft: isActive ? "2px solid #2589f5" : "",
  };

  return (
    <HeaderCell {...rest}>
      <div ref={ref} style={styles}>
        {children}
      </div>
    </HeaderCell>
  );
}

function columnSwap(source: any, sourceId: any, targetId: any) {
  const nextData = source.filter((item: any) => item.id !== sourceId);
  const dragItem = source.find((item: any) => item.id === sourceId);
  const index = nextData.findIndex((item: any) => item.id === targetId);

  nextData.splice(index + 1, 0, dragItem);
  return nextData;
}

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerTreeProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  group: string;
  affiliateId: number;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const BuyerTreeTable: React.FC<BuyerTreeProps> = (props: any) => {
  const Admediary = React.useContext(AdmediaryContext);
  const summary = props.summary;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const group = props.group;
  const affiliateId = props.affiliateId;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  const [columns, setColumns] = React.useState([
    { id: "considered", name: "Considered", width: 150 },
    { id: "filtered", name: "Filtered", width: 150 },
    { id: "filter_percent", name: "Filter %", width: 150 },
    { id: "presented", name: "Presented", width: 150 },
    { id: "pinged", name: "Pinged", width: 150 },
    { id: "posted", name: "Posted", width: 150 },
    { id: "accepted", name: "Accepted", width: 150 },
    { id: "accept_percent", name: "Gross Accept %", width: 150 },
    { id: "posted_accept_percentage", name: "Accept %", width: 150 },
    { id: "rejected", name: "Rejected", width: 150 },
    { id: "rate", name: "Paid(Cost)", width: 150 },
    { id: "earned", name: "Earned(Revenue)", width: 150 },
    { id: "profit", name: "GP", width: 150 },
    { id: "cpl", name: "CPL", width: 150 },
    { id: "rpl", name: "RPL", width: 150 },
    { id: "ppl", name: "PPL", width: 150 },
  ]);

  const handleDragColumn = (sourceId: any, targetId: any) => {
    setColumns(columnSwap(columns, sourceId, targetId));
  };

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const groupTitles = new Map([
    ["entry_date", "Date"],
    ["affiliate_id", "Affiliate/SID1"],
    ["disposition", "Disposition"],
  ]);

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return sortTree(list, sort_column, sort_type);
  };

  /**
    Custom version of this function will sort subdates on contracts into descending order from most recent
   */
  const sortTree = (
    data: any[],
    attribute: string,
    type?: string,
    level = 0
  ): any[] => {
    if (attribute == "parent_title" && level == 2) {
      type = "desc";
    }
    data = sort(data, attribute, type);

    data.forEach((item, index) => {
      const children = item.children;
      if (children && Array.isArray(children)) {
        data[index].children = sortTree(children, attribute, type, level + 1);
      }
    });

    return data;
  };

  const formatSummary = (data: any) => {
    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = ["pinged", "posted", "posted_accept_percentage"];
    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = data.map((item: { [index: string]: string }): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    return {
      pinged: summary.pinged,
      posted: summary.posted,
      posted_accept_percentage: summary.posted_accept_percentage / data.length,
    };
  };

  const formattedSummary = formatSummary(data);

  return (
    <DndProvider backend={Backend}>
      <div>
        <Table
          isTree
          // height={650}
          autoHeight={true}
          fillHeight={true}
          headerHeight={90}
          loading={isLoading === true}
          data={sortData(data)}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
          onRowClick={handleExpanded}
        >
          <Column width={300} fixed sortable resizable>
            <HeaderCell>Buyer/Contract/{groupTitles.get(group)}</HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>
          <Column width={180} align="right" sortable resizable>
            <HeaderCell>Product</HeaderCell>
            <Cell
              dataKey="product_display"
              style={{ direction: "rtl", fontSize: "0.9em" }}
            />
          </Column>
          <Column width={100} align="right" sortable resizable>
            <HeaderCell>Contract ID</HeaderCell>
            <Cell dataKey="contract_id" />
          </Column>
          <Column width={30}>
            <HeaderCell>P</HeaderCell>
            <CircleCell
              type="period"
              group={group}
              isMultipleDayPeriod={
                Admediary.start.getTime() !== Admediary.end.getTime()
              }
            />
          </Column>
          <Column width={30}>
            <HeaderCell>D</HeaderCell>
            <CircleCell
              type="daily"
              group={group}
              isMultipleDayPeriod={
                Admediary.start.getTime() !== Admediary.end.getTime()
              }
            />
          </Column>
          {columns.map((column) => (
            <Column
              width={column.width}
              key={column.id}
              align="right"
              sortable
              resizable
            >
              <DraggableHeaderCell
                onDrag={handleDragColumn}
                id={column.id}
                title={column.name}
                summary={summary}
              >
                {column.id === "considered" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={summary.considered}
                  />
                ) : column.id === "filtered" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={summary.filtered}
                  />
                ) : column.id === "filter_percent" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={percent(summary.filter_percent)}
                  />
                ) : column.id === "presented" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={summary.presented}
                  />
                ) : column.id === "pinged" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={formattedSummary.pinged}
                  />
                ) : column.id === "posted" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={formattedSummary.posted}
                  />
                ) : column.id === "accepted" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={summary.accepted}
                  />
                ) : column.id === "accept_percent" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={percent(summary.accept_percent)}
                  />
                ) : column.id === "posted_accept_percentage" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={
                      !isNaN(formattedSummary.posted_accept_percentage)
                        ? percent(formattedSummary.posted_accept_percentage)
                        : percent(0)
                    }
                  />
                ) : column.id === "rejected" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={summary.rejected}
                  />
                ) : column.id === "rate" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.rate)}
                  />
                ) : column.id === "earned" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.earned)}
                  />
                ) : column.id === "profit" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.profit)}
                  />
                ) : column.id === "cpl" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.cpl)}
                  />
                ) : column.id === "rpl" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.rpl)}
                  />
                ) : column.id === "ppl" ? (
                  <HeaderSummary
                    title={column.name}
                    summary={currency(summary.ppl)}
                  />
                ) : (
                  <HeaderSummary></HeaderSummary>
                )}
              </DraggableHeaderCell>
              {column.id === "accept_percent" ? (
                <PercentCell dataKey="accept_percent" />
              ) : column.id === "posted_accept_percentage" ? (
                <PercentCell dataKey="posted_accept_percentage" />
              ) : column.id === "filter_percent" ? (
                <PercentCell dataKey="filter_percent" />
              ) : column.id === "cpl" ? (
                <CurrencyCell dataKey="cpl" />
              ) : column.id === "earned" ? (
                <CurrencyCell dataKey="earned" />
              ) : column.id === "rate" ? (
                <CurrencyCell dataKey="rate" />
              ) : column.id === "profit" ? (
                <CurrencyCell dataKey="profit" />
              ) : column.id === "rpl" ? (
                <CurrencyCell dataKey="rpl" />
              ) : column.id === "ppl" ? (
                <CurrencyCell dataKey="ppl" />
              ) : (
                <NumberCell dataKey={column.id}></NumberCell>
              )}
            </Column>
          ))}

          <Column width={50} fixed="right">
            <HeaderCell>&nbsp;</HeaderCell>
            <ActionCell groupBy={group} affiliateId={affiliateId} />
          </Column>
        </Table>
      </div>
    </DndProvider>
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="filter_reasons">Filter Reasons</Dropdown.Item>
    {row.disposition === "accepted" && (
      <Dropdown.Item eventKey="responses">Responses </Dropdown.Item>
    )}
    {row.disposition === "rejected" && (
      <Dropdown.Item eventKey="responses">Responses </Dropdown.Item>
    )}
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
    {row.contract_id > 0 && (
      <Dropdown.Item eventKey="hourly">Hourly</Dropdown.Item>
    )}
    {(row.contract_id > 0 || (row._parent && row._parent.contract_id)) && (
      <Dropdown.Item eventKey="edit_contract">Edit Contract</Dropdown.Item>
    )}
    {!row.hasOwnProperty("sid1") && (
      <Dropdown.Item eventKey="response_time">Response Time</Dropdown.Item>
    )}
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
  groupBy: string;
  affiliateId: string;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const groupBy = props.groupBy;
  const affiliateId = props.affiliateId;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const contractId =
    row.contract_id > 0
      ? row.contract_id
      : row._parent && row._parent.contract_id
      ? row._parent.contract_id
      : 0;

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["filter_reasons", <FilterReasons row_data={row} />],
      ["view_leads", <ViewLeads row_data={row} />],
      ["hourly", <HourlyReport row_data={row} />],
      ["responses", <Responses row_data={row} />],
      ["edit_contract", <BuyerContract contractId={contractId} />],
      [
        "response_time",
        <ResponseTime
          row_data={row}
          groupBy={groupBy}
          affiliateId={affiliateId}
        />,
      ],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["edit_contract", "lg"],
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, groupBy, affiliateId = 0, ...props }: any) => (
  <Cell {...props} className="link-group">
    <CustomWhisper row={rowData} groupBy={groupBy} affiliateId={affiliateId} />
  </Cell>
);

const CircleCell = ({
  rowData,
  type,
  group,
  isMultipleDayPeriod,
  ...props
}: any) => (
  <Cell {...props} className="link-group">
    <Circle
      record={rowData}
      type={type}
      group={group}
      isMultipleDayPeriod={isMultipleDayPeriod}
    />
  </Cell>
);

const Circle = ({ record, type, group, isMultipleDayPeriod }: any) => {
  const percentage = parseFloat(record[type + "_percentage"]);

  if (isNaN(percentage)) {
    return <></>;
  }

  const bought = record[type + "_bought"];
  const cap = record[type + "_cap"];

  const affiliate_period_bought =
    record["affiliate_period_bought"] !== null
      ? record["affiliate_period_bought"]
      : 0;
  const affiliate_period_cap =
    record["affiliate_period_cap"] !== null
      ? record["affiliate_period_cap"]
      : 0;
  const affiliate_period_percentage =
    record["affiliate_period_percentage"] !== null
      ? record["affiliate_period_percentage"]
      : 0;

  const GREEN = "#00ff11";
  const YELLOW = "#f8ff46";
  const RED = "#f00";
  const LIGHTGREY = "#d3d3d3";
  const WHITE = "#ffffff";

  let color =
    percentage >= 1
      ? RED
      : percentage === -1 || percentage < 0.8
      ? GREEN
      : YELLOW;

  if (
    (bought === 0 ||
      bought === null ||
      affiliate_period_bought === 0 ||
      affiliate_period_bought === null) &&
    (cap === 0 ||
      cap === null ||
      affiliate_period_cap === 0 ||
      affiliate_period_cap === null)
  ) {
    color = GREEN;
  }

  if (
    type === "period" &&
    group === "affiliate_id" &&
    record["affiliate_id"] > 0
  ) {
    if (affiliate_period_percentage >= 1) {
      color = RED;
    } else if (
      affiliate_period_percentage < 0.8 ||
      affiliate_period_percentage === -1
    ) {
      color = GREEN;
    } else {
      color = YELLOW;
    }
  }

  let text: any[] = [];

  if (
    type === "period" &&
    group === "affiliate_id" &&
    record["affiliate_id"] > 0
  ) {
    text = [
      "affiliate_period_bought = " + affiliate_period_bought,
      "affiliate_period_cap = " + affiliate_period_cap,
      "affiliate_period_percentage = " +
        (affiliate_period_percentage * 100).toFixed(1) +
        "%",
    ];
  } else if (
    type === "period" &&
    group === "affiliate_id" &&
    record["affiliate_id"] === undefined
  ) {
    text = [];
  } else {
    text = [
      type + "_bought = " + bought,
      type + "_cap = " + cap,
      type + "_percentage = " + (percentage * 100).toFixed(1) + "%",
    ];
  }

  text = percentage === -1 && cap === -1 ? ["No period set"] : text;
  color = percentage === -1 && cap === -1 ? LIGHTGREY : color;
  text = percentage === -2 ? ["Multi period selected"] : text;
  color = percentage === -2 ? LIGHTGREY : color;

  if (type === "daily") {
    if (isMultipleDayPeriod && record["contract_id"] > 0) {
      color = LIGHTGREY;
      text = ["Multiday period selected"];
    }

    if (group !== "entry_date") {
      color = WHITE;
      text = ["Detail date not selected"];
    }
  }

  if (
    type === "period" &&
    group === "affiliate_id" &&
    record["affiliate_id"] === undefined
  ) {
    if (record["affiliate_id"] === undefined) {
      color = LIGHTGREY;
      text = ["Select next step to view affiliate data"];
    }
  }

  const style = {
    height: 20,
    width: 20,
    marginTop: 8,
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
  };

  // Add tooltip with full value
  const fullValue = text.map((line: string, index) => (
    <p key={index}>{line}</p>
  ));

  // return type === "period" && group === "affiliate_id" && record["affiliate_id"] === undefined ? (
  //   <></>
  // ) : (
  //   <Whisper
  //     trigger="hover"
  //     placement={"autoHorizontalEnd"}
  //     speaker={<Popover>{fullValue}</Popover>}
  //   >
  //     <span style={style} />
  //   </Whisper>
  // );

  return (
    <Whisper
      trigger="hover"
      placement={"autoHorizontalEnd"}
      speaker={<Popover>{fullValue}</Popover>}
    >
      <span style={style} />
    </Whisper>
  );
};

export default BuyerTreeTable;

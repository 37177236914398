import React, { SyntheticEvent } from "react";
import {
  Icon,
  IconButton,
  Popover,
  Table,
  Whisper,
  Dropdown,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { sort } from "../../@Utils/Sorting";
import Service from "../Drawers/Service";
import { CheckCell, EditRemoveActionCell } from "../Table";
import BuyerContract from "../Drawers/BuyerContract";
import { Query } from "../../@Utils/AdmediaryApi";
import ConfirmModal from "../ConfirmModal";

const { Column, HeaderCell, Cell } = Table;

type DataServiceListProps = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
  refreshData?: () => void;
};

const DataServiceList: React.FC<DataServiceListProps> = ({
  data = [],
  refreshData,
  isLoading,
  sortType,
  sortColumn,
  onSortColumn,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const [selectedItem, setSelectedItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return sort(list, sortColumn, sortType);
    }
    return list;
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      <Service serviceId={rowData.service_id} refreshData={refreshData} />,
      "lg"
    );
  };

  const deleteService = (serviceID: number = 0) => {
    if (serviceID === 0) {
      return;
    }

    (async () => {
      try {
        await Query("data_service_delete", { service_id: serviceID });
        Notification.success({
          title: "Success",
          description: "Service has been removed",
        });

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const handleRemoveActionClick = (rowData: any, event: SyntheticEvent) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleRemoving = () => {
    deleteService(selectedItem.service_id);

    handleClose();
  };

  return (
    <>
      <Table
        virtualized
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column fixed sortable resizable>
          <HeaderCell>Service ID</HeaderCell>
          <Cell dataKey="service_id" />
        </Column>
        <Column fixed sortable resizable>
          <HeaderCell>Data Source ID</HeaderCell>
          <Cell dataKey="data_source_id" />
        </Column>
        <Column fixed sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={150} fixed sortable resizable>
          <HeaderCell>Service Provider</HeaderCell>
          <Cell dataKey="company_name" />
        </Column>
        <Column width={190} fixed sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={150} fixed sortable resizable>
          <HeaderCell>Data Provider</HeaderCell>
          <Cell dataKey="data_provider" />
        </Column>
        <Column width={150} fixed sortable resizable>
          <HeaderCell>Data Source</HeaderCell>
          <Cell dataKey="data_source" />
        </Column>
        <Column width={100} fixed="right">
          <HeaderCell>&nbsp;</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            removeHandler={handleRemoveActionClick}
            userPermission={admediaryContext.userDisable}
          />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Contract?
      </ConfirmModal>
    </>
  );
};

export default DataServiceList;

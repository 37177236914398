import React from "react";
import { FlexboxGrid, Icon, IconButton } from "rsuite";

import SmsReportDataTable from "./SmsReportDataTable";

import Title from "../Title";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { exportCsv } from "../../@Utils/Export";

const SmsReportData: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    start_date: Admediary.start,
    end_date: Admediary.end,
    campaign_id: 0,
  };

  const [data, isLoading] = useAdmediaryApi("sms_campaign_select", params);

  function formatDataForExporting(data: any) {
    return data.map((item: any) => {
      return {
        Active: item.active,
        "Campaign ID": item.campaign_id,
        "SMS FROM": item.sms_from,
        Description: item.description,
      };
    });
  }

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  return (
    <>
      <Title title="Sms Campaigns Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `data_campaigns_sms.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <SmsReportDataTable
        data={Array.isArray(data) ? data : []}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshData={refresh}
      />
    </>
  );
};

export default SmsReportData;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import BuyerContractsList from "./BuyerContractsList";
import SelectPicker from "rsuite/lib/SelectPicker";
import Title from "../Title";
import BuyerContract from "../Drawers/BuyerContract";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent } from "../../@Utils/Format";
import { format } from "date-fns";
import BuyerContractClone from "./BuyerContractClone";
import EditModal from "../EditModal";

type BuyerContractsProps = {
  height?: number;
};

const BuyerContracts: React.FC<BuyerContractsProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultActiveValue = 1;
  const [active, setActive] = React.useState(defaultActiveValue);
  const activeList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];
  const categoryId = Admediary.category;
  // const category = Admediary.category ? Admediary.category : "ALL";
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    category_id: categoryId,
    buyer_id: Admediary.buyer,
    active,
    include_sources: 1,
  };

  const [data, isLoading] = useAdmediaryApi(
    "products_routing_contracts",
    params
  );

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddContractClick = () => {
    // Set selected content into drawer
    Admediary.openDrawer(
      <BuyerContract contractId={0} refreshData={refresh} />,
      "lg"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */

  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = [
      "contract_id",
      "buyer_name",
      "category_name",
      "contract_name",
      "routing_tag",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string | number) => {
        if (field === "contract_id") {
          return include(item[field].toString()); // convert contract_id to string before searching
        } else {
          return include(item[field]);
        }
      })
    );
  };

  const formatExportData = (data: any) => {
    return (
      data
        .map((item: any) => item)
        // Clean, format and reorder by fields with right headers
        .map((item: any) => {
          return {
            "Contract ID": item.contract_id,
            "Buyer ID": item.buyer_id,
            "Buyer Name": item.buyer_name,
            "Category ID": item.category_id,
            "Category Name": item.category_name,
            "Contract Name": item.contract_name,
            "Routing Tag": item.routing_tag,
            Active: item.active,
          };
        })
    );
  };

  const handleCloneContractClick = () => {
    Admediary.setCloneContractModal(true);
    // openCloneContractModal();
  };

  const handleCloseCloneContractModal = () => {
    Admediary.setCloneContractModal(false);
    refresh();
  };

  return (
    <>
      <Title title="Buyer Contracts" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={15}>
          <SelectPicker
            size="sm"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={false}
            defaultValue={defaultActiveValue}
            value={active}
            data={activeList}
            onChange={(v) => {
              setActive(v);
            }}
            valueKey="value"
            labelKey="label"
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={9} style={{ textAlign: "right" }}>
          <IconButton
            onClick={handleAddContractClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 10 }}
            disabled={Admediary.userDisable}
          >
            Add Contract
          </IconButton>
          <IconButton
            onClick={handleCloneContractClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 10 }}
          >
            Clone
          </IconButton>
          <IconButton
            onClick={() =>
              exportCsv(
                `buyer_contracts_list_catID.csv`,
                formatExportData(
                  Array.isArray(data) ? filter(data, searchQuery) : []
                )
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <BuyerContractsList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />

      <EditModal
        title="Clone Contract"
        open={Admediary.cloneContractModal}
        size={"lg"}
        onClose={handleCloseCloneContractModal}
        onCancel={handleCloseCloneContractModal}
        hideSubmitButton={true}
      >
        <BuyerContractClone refreshData={refresh} />
      </EditModal>
    </>
  );
};

export default BuyerContracts;

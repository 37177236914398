import React, { useState } from "react";
import { Checkbox, FlexboxGrid, Icon, IconButton } from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import AffiliateTreeTable from "./AffiliateTreeTable";
import { format } from "date-fns";
import Title from "../Title";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "considered",
    "filtered",
    "presented",
    "accepted",
    "rejected",
    "rate",
    "earned",
    "profit",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function getExportData(data: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        ...{
          affiliate_id: item.affiliate_id || parent.affiliate_id,
          affiliate_name: item.affiliate_name || parent.affiliate_name,
          buyer_id: item.buyer_id || parent.buyer_id,
          buyer_name: item.buyer_name || parent.buyer_name,
          contract_id: item.contract_id || parent.contract_id,
          contract_name: item.contract_name || parent.contract_name,
          category_id: item.category_id || parent.category_id,
          product_id: item.product_id || parent.product_id,
          product_name: item.product_name || parent.product_name,
        },
        ...item,
      };
      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Affiliate ID": item.affiliate_id,
          "Affiliate Name": item.affiliate_name,
          "Buyer ID": item.buyer_id,
          "Buyer Name": item.buyer_name,
          "Contract ID": item.contract_id,
          "Contract Name": item.contract_name,
          "Category ID": item.category_id,
          "Product ID": item.product_id,
          "Product Name": item.product_name,
          Disposition: item.disposition,
          Considered: thousands(item.considered),
          Filtered: thousands(item.filtered),
          "Filter %": percent(parseFloat(item.filter_percent)),
          Presented: thousands(item.presented),
          Accepted: thousands(item.accepted),
          "Accept %": percent(parseFloat(item.accept_percent)),
          Rejected: thousands(item.rejected),
          Rate: currency(item.rate),
          Earned: currency(item.earned),
          GP: currency(item.profit),
          CPL: currency(item.cpl),
          RPL: currency(item.rpl),
          PPL: currency(item.ppl),
        };
      })
  );
}

function addCalculatedValues(row: any) {
  row.accepted = parseFloat(row.accepted);
  row.considered = parseFloat(row.considered);
  row.presented = parseFloat(row.presented);
  row.profit = row.earned - row.rate;

  return {
    ...row,
    ...{
      cpl: row.accepted !== 0 ? row.rate / row.accepted : 0,
      rpl: row.accepted !== 0 ? row.earned / row.accepted : 0,
      ppl: row.accepted !== 0 ? row.profit / row.accepted : 0,
      filter_percent:
        row.considered !== 0 ? (row.filtered / row.considered) * 100 : 0,
      accept_percent:
        row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
    },
  };
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const AffiliateTree: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultGroupValue = "disposition";
  const [group] = React.useState(defaultGroupValue);
  const defaultDataTypeValue = "date_sold";
  const [dateType, setDateType] = React.useState(defaultDataTypeValue);
  const [checked, setChecked] = useState(false);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const handleChecked = (content: any): void => {
    setChecked(!checked);
  };

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: Admediary.buyer,
    add_subid: checked ? 1 : 0,
    affiliate_id: 0,
    sid1: "",
    sid2: "",
    detail_by: group,
    date_type: dateType,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "affiliate_tree_report",
    params
  );
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="Affiliate Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <Checkbox
            onChange={handleChecked}
            style={{ float: "left", marginRight: 15 }}
          >
            SubID
          </Checkbox>
          <SelectPicker
            placeholder="By"
            searchable={false}
            cleanable={false}
            size="sm"
            defaultValue={defaultDataTypeValue}
            value={dateType}
            data={[
              { value: "date_sold", label: "Date Sold" },
              { value: "date_submitted", label: "Date Submitted" },
            ]}
            onChange={(v) => {
              setDateType(v);
            }}
            style={{ marginTop: 4, marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `affiliate_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <AffiliateTreeTable
        data={treeData}
        checked={checked}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        group={group}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default AffiliateTree;

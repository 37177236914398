import React from "react";
import { getApiUrl, Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  Checkbox,
  CheckboxGroup,
  InputNumber,
  Drawer,
  Button,
  Icon,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import { FormContext } from "../../@Context/FormContext";
import { FormInstance } from "rsuite/lib/Form/Form";
import Field from "../Field";
import { PixelPercentageType } from "../Products/PixelPercentage";
import { sort } from "../../@Utils/Sorting";
import _ from "lodash";

const { StringType, NumberType, BooleanType, ArrayType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  product_id: NumberType().isRequired("Product is required."),
  tier_name: StringType(),
  afid: NumberType(),
  sid1: NumberType(),
  percent_pay: NumberType().min(0).max(100),
  percent_id: NumberType(),
  category_id: NumberType().isRequired("Category is required."),
});

type EditPixelPercentageProps = {
  percentage?: PixelPercentageType;
  callbackOnSave: Function;
};

const EditPixelPercentage: React.FC<EditPixelPercentageProps> = (props) => {
  const formRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const callbackOnSave = props.callbackOnSave;

  const initItem = props.percentage ?? {
    active: [],
    afid: undefined,
    affiliate_name: "",
    category_id: undefined,
    percent_id: 0,
    product_id: undefined,
    percent_pay: 0,
    product_description: "",
    sid1: "",
    tier_name: "",
  };

  const prepareFormData = (record: PixelPercentageType) => {
    return record;
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [formValue, setFormValue] = React.useState<PixelPercentageType>(
    prepareFormData(initItem)
  );
  const [formError, setFormError] = React.useState({});

  const params = {};

  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const apiUrl = getApiUrl();
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(`${apiUrl}?op=products,buyer_contract_tier_names`)
      .then((response) => {
        const products = response.data.response_data.products.data.map(
          (product: any) => ({
            ...product,
            ...{
              // criteria_group_id: parseInt(product.criteria_group_id),
              // product_id: parseInt(product.product_id),
            },
          })
        );

        const buyer_contract_tier_names = response.data.response_data.buyer_contract_tier_names.data.map(
          (tier_name: any) => ({
            ...tier_name,
            ...{},
          })
        );

        Forms.setData({
          ...Forms.data,
          ...{
            products,
            buyer_contract_tier_names,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (data: any) => {
    setFormValue(data);
  };

  const handleSave = () => {
    const node = formRef && formRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }
    if (typeof data.afid == "undefined" || data.afid == "") {
      data.afid = "NULL";
    }

    (async () => {
      try {
        let resp = await Query("pixel_percentage_update", data);
        data.percent_id = resp.data[0].percent_id;
        data.affiliate_name = (
          config.affiliates.find(
            (aff: any) => aff.affiliate_id == data.afid
          ) ?? { affiliate_name: "" }
        ).affiliate_name;
        data.affiliate_id = data.afid;

        Notification.success({
          title: "Success",
          description: "Pixel Percentage has been updated",
        });

        Admediary.closeDrawer();

        callbackOnSave(data);
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>({e})</>,
        });
      } finally {
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {typeof formValue.percent_id === "undefined" ? <>Add </> : <>Edit </>}
          Pixel Percentage
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <Field label="Active" name="active" accepter={CheckboxGroup}>
            <Checkbox value={1} />
          </Field>
          <Field
            label="Category ID"
            name="category_id"
            accepter={SelectPicker}
            data={config.categories}
            labelKey="category_name"
            valueKey="category_id"
            cleanable={false}
          />
          <Field
            label="Product"
            name="product_id"
            accepter={SelectPicker}
            placeholder="Select Product"
            data={config.products
              .filter(
                (product: any) => product.category_id == formValue.category_id
              )
              .map((product: any) => ({
                product_description:
                  "(" +
                  parseInt(product.product_id) +
                  ") " +
                  product.product_description,
                product_id: parseInt(product.product_id),
              }))}
            labelKey="product_description"
            valueKey="product_id"
            cleanable={false}
            disabled={
              typeof formValue.category_id == "undefined" ||
              !Number.isInteger(formValue.category_id)
            }
          />
          <Field
            label="Tier Name"
            name="tier_name"
            accepter={SelectPicker}
            data={sort(
              Forms.data.buyer_contract_tier_names != null
                ? Array.isArray(Forms.data.buyer_contract_tier_names)
                  ? Forms.data.buyer_contract_tier_names.map(
                      (tier_name: any) => ({
                        tier_display:
                          "(" +
                          parseInt(tier_name.tier_id) +
                          ") " +
                          tier_name.tier_name,
                        tier_name: tier_name.tier_name,
                      })
                    )
                  : []
                : [],
              "tier_name",
              "ASC"
            )}
            labelKey="tier_display"
            valueKey="tier_name"
            cleanable={true}
            searchable={false}
            disabled={
              typeof formValue.product_id == "undefined" ||
              !Number.isInteger(formValue.product_id)
            }
          />
          <Field
            label="AFID"
            name="afid"
            disabled={
              typeof formValue.product_id == "undefined" ||
              !Number.isInteger(formValue.product_id)
            }
          />
          <Field
            label="SID1"
            name="sid1"
            disabled={
              typeof formValue.product_id == "undefined" ||
              !Number.isInteger(formValue.product_id)
            }
          />
          <Field
            label="Pay Percentage (%)"
            name="percent_pay"
            disabled={
              typeof formValue.product_id == "undefined" ||
              !Number.isInteger(formValue.product_id)
            }
          />
        </Form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={handleSave} appearance="primary">
          Save
        </Button>
        <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
          Cancel
        </Button>
      </Drawer.Footer>
    </>
  );
};

export default EditPixelPercentage;

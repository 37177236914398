import React from "react";
import { Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { NumberCell } from "../Table";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type ActiveSourcesListType = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const ActiveSourcesList: React.FC<ActiveSourcesListType> = ({
  data = [],
  isLoading = false,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          last_post: item.last_post
            ? format(item.last_post, "MM-dd-y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column width={150} align="right" fixed sortable resizable>
          <HeaderCell>Data Source ID</HeaderCell>
          <Cell dataKey="data_source_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Data Source Name</HeaderCell>
          <Cell dataKey="data_source_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Last Post</HeaderCell>
          <Cell dataKey="last_post" />
        </Column>
        <Column width={130} sortable resizable align="right">
          <HeaderCell>Today Count</HeaderCell>
          <NumberCell dataKey="today" />
        </Column>
        <Column width={130} sortable resizable align="right">
          <HeaderCell>Yesterday Count</HeaderCell>
          <NumberCell dataKey="yesterday" />
        </Column>
      </Table>
    </>
  );
};

export default ActiveSourcesList;

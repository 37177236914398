import React from "react";
import { Drawer, Table } from "rsuite";
import DispositionFilterDetails from "./DispositionFilterDetails";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

type DispositionFilterBreakdownType = {
  data: any;
};

const FullDispositionFilterList: React.FC<DispositionFilterBreakdownType> = ({
  data = [],
}) => {
  const admediary_context = React.useContext(AdmediaryContext);
  const total_leads = data.reduce((count: number, row: any) => {
    return count + row.filtered_leads;
  }, 0);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>All Filtered Reasons</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Table
          virtualized
          data={data}
          autoHeight
          onRowClick={(item) => {
            admediary_context.openDrawer(
              <DispositionFilterDetails data={item} />
            );
          }}
        >
          <Table.Column width={150}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell dataKey="status" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.Cell dataKey="reason" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Filtered</Table.HeaderCell>
            <Table.Cell dataKey="filtered_leads" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Filtered %</Table.HeaderCell>
            <Table.Cell>
              {(rowData: any) =>
                `${Math.round((rowData.filtered_leads / total_leads) * 100)}%`
              }
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Buyers</Table.HeaderCell>
            <Table.Cell dataKey="buyers_considered_count" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Contracts</Table.HeaderCell>
            <Table.Cell dataKey="contracts_considered_count" />
          </Table.Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default FullDispositionFilterList;

import React from "react";
import { Query } from "../@Utils/AdmediaryApi";
import { AdmediaryContext } from "../@Context/AdmediaryContext";
import Dashboard from "../@Components/Dashboard";
import {
  Button,
  ButtonToolbar,
  Col,
  Content,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row,
  Schema,
} from "rsuite";

const Debug: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const form_values = {
    op: "lead_dispositions",
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    affiliate_id: 0,
    buyer_id: 0,
    group: "",
  };

  /**
   * Handle Form Submit
   */
  const submitForm = () => {
    (async () => {
      try {
        console.log(form_values);
        // const op: ApiOperations = form_values.op;
        const results: any = await Query("lead_dispositions", {
          start_date: form_values.start_date,
          end_date: form_values.end_date,
          category_id: form_values.category_id,
          product_id: form_values.product_id,
          affiliate_id: form_values.affiliate_id,
          buyer_id: form_values.buyer_id,
          group: form_values.group,
        });
        console.log(results);
      } catch (e) {
      } finally {
      }
    })();
  };

  // Set model for form validators
  const model = Schema.Model({
    op: Schema.Types.StringType().isRequired(
      "API OP is needed to route your request"
    ),
    start_date: Schema.Types.DateType(),
    end_date: Schema.Types.DateType(),
    product_id: Schema.Types.NumberType(),
    affiliate_id: Schema.Types.NumberType(),
    buyer_id: Schema.Types.NumberType(),
    group: Schema.Types.StringType(),
  });

  return (
    <Dashboard>
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content style={{ height: "100%" }}>
              <Form model={model} onSubmit={submitForm} formValue={form_values}>
                <FormGroup>
                  <ControlLabel>API Operation</ControlLabel>
                  <FormControl name="op" />
                  <HelpBlock>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Start Date</ControlLabel>
                  <FormControl name="start_date" />
                  <HelpBlock>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>End Date</ControlLabel>
                  <FormControl name="end_date" />
                  <HelpBlock>Required</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Product ID</ControlLabel>
                  <FormControl name="product_id" />
                  <HelpBlock>Required, use 0 for all</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Affiliate ID</ControlLabel>
                  <FormControl name="affiliate_id" />
                  <HelpBlock>Required, use 0 for all</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Buyer ID</ControlLabel>
                  <FormControl name="buyer_id" />
                  <HelpBlock>Required, use 0 for all</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Group By</ControlLabel>
                  <FormControl name="group" />
                </FormGroup>
                <ButtonToolbar>
                  <Button appearance="ghost" type="submit">
                    Submit Request
                  </Button>
                </ButtonToolbar>
              </Form>
            </Content>
          </Col>
        </Row>
        <Row>
          <Col className="content-card" xs={24}>
            <Content style={{ height: "100%" }}></Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default Debug;

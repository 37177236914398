import React, { useState } from "react";
import { FlexboxGrid, Icon, IconButton, Checkbox } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent } from "../../@Utils/Format";
import GrossProfitReportTable from "./GrossProfitReportTable";
import { format } from "date-fns";
import Title from "../Title";
import SelectPicker from "rsuite/lib/SelectPicker";
import Config from "../../@Config/Forms";
import axios from "axios";

const getSummary = (data: any) => {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = ["cost", "revenue", "gp", "gp_percentage"];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return summary;
};

const addCalculatedValues = (row: any) => {
  row.revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);
  row.gp = isNaN(parseFloat(row.gp)) ? 0 : parseFloat(row.gp);
  row.cost = isNaN(parseFloat(row.cost)) ? 0 : parseFloat(row.cost);

  return {
    ...row,
    ...{
      gp_percentage: row.revenue > 0 ? (row.gp / row.revenue) * 100 : 0,
    },
  };
};

const addCalculatedValuesRecursively = (list: any) => {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
};

const GrossProfitReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const defaultGroupValue = "affiliate";
  const defaultTypeValue = "all";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const [type, setType] = React.useState(defaultTypeValue);
  const [affiliateManagersList, setAffliateManagersList] = React.useState();
  const [affiliateManager, setAffiliateManager] = React.useState();
  const [
    buyerAccountManagersList,
    setBuyerAccountManagersList,
  ] = React.useState();
  const [buyerAccountManager, setBuyerAccountManager] = React.useState();
  const [advertiserManagersList, setAdvertiserManagersList] = React.useState();
  const [advertiserManager, setAdvertiserManager] = React.useState();
  const [checked, setChecked] = useState(false);

  const handleChecked = (content: any): void => {
    setChecked(!checked);
  };

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    debug: 0,
    group,
    group_by_afid: 1,
    group_by_subid: 1,
    product_id: Admediary.product ? Admediary.product : 0,
    type: type,
    affiliate_manager: affiliateManager !== null ? affiliateManager : "",
    advertiser_manager: advertiserManager,
    include_esign_revenue: checked ? 1 : 0,
  };

  const buyerParams = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    debug: 0,
    group,
    group_by_afid: 1,
    group_by_subid: 1,
    product_id: Admediary.product ? Admediary.product : 0,
    type: type,
    affiliate_manager: affiliateManager !== null ? affiliateManager : "",
    buyer_account_manager:
      buyerAccountManager !== null ? buyerAccountManager : 0,
    advertiser_manager: advertiserManager,
    include_esign_revenue: checked ? 1 : 0,
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "gp_report_select",
    group === "affiliate" ? params : buyerParams
  );

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(treeData) ? treeData : []);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  const productID = Admediary.product ? Admediary.product : 0;

  const groupByList = [
    { group: "buyer", label: "Buyer" },
    { group: "affiliate", label: "Affiliate" },
  ];

  const typeList = [
    { type: "all", label: "All" },
    { type: "web", label: "Web" },
    { type: "call", label: "Call" },
  ];

  const getExportData = (data: any, groupBy: string) => {
    const flattenTree = (list: any, parent?: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        parent = parent || item;

        const current = {
          ...item,
          // Add parent values
          product_id: item.product_id || parent.product_id,
          product_description:
            item.product_description || parent.product_description,
          buyer_id: item.buyer_id || parent.buyer_id,
          buyer_name: item.buyer_name || parent.buyer_name,
          contract_id: item.contract_id || parent.contract_id,
          contract_name: item.contract_name || parent.contract_name,
          affiliate_id: !isNaN(parseInt(item.affiliate_id, 10))
            ? parseInt(item.affiliate_id, 10)
            : parent.affiliate_id,
          affiliate_name: item.affiliate_name || parent.affiliate_name,
          affiliate_manager: item.affiliate_manager || parent.affiliate_manager,
          buyer_manager: item.buyer_manager || parent.buyer_manager,
          subid: item.subid || parent.subid,
        };

        if (current.children && Array.isArray(current.children)) {
          result.push(...flattenTree(current.children, current));
        } else {
          result.push(current);
        }
      });
      return result;
    };

    return flattenTree(data.map((item: any) => item)).map((item: any) => {
      const parentFields = new Map();

      parentFields.set("buyer", {
        "Product ID": item.product_id,
        "Product Name": item.product_description,
        "Buyer ID": item.buyer_id,
        "Buyer Name": item.buyer_name,
        "Contract ID": item.contract_id,
        "Contract Name": item.contract_name,
        "Affiliate ID": item.affiliate_id,
        "Affiliate Name": item.affiliate_name,
        "Affiliate Manager": item.affiliate_manager,
        "Buyer Manager": item.buyer_manager,
        "Sub ID": item.subid,
      });
      parentFields.set("affiliate", {
        "Product ID": item.product_id,
        "Product Name": item.product_description,
        "Affiliate ID": item.affiliate_id,
        "Affiliate Name": item.affiliate_name,
        "Affiliate Manager": item.affiliate_manager,
        "Sub ID": item.subid,
      });

      const parentColumnResults = parentFields.has(groupBy)
        ? parentFields.get(groupBy)
        : parentFields.get("affiliate");

      if (group === "affiliate" && type !== "all") {
        return {
          ...parentColumnResults,
          "Sum of Cost": currency(item.cost),
          "Sum of Revenue": currency(item.revenue),
          "Sum of GP": currency(item.gp),
          "GP Percentage": percent(item.gp_percentage),
          Conversions: item.conversions,
          Type: type === "web" ? "Web" : "Call",
        };
      } else {
        return {
          ...parentColumnResults,
          "Sum of Cost": currency(item.cost),
          "Sum of Revenue": currency(item.revenue),
          "Sum of GP": currency(item.gp),
          "GP Percentage": percent(item.gp_percentage),
          Conversions: item.conversions,
        };
      }
    });
  };

  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  React.useEffect(() => {
    API.get(
      `${api_base}/api/reports.php?op=affiliate_managers,buyer_account_managers,advertiser_managers`
    )
      .then((response) => {
        const affliateManagers = response.data.response_data.affiliate_managers.data.map(
          (affiliateManager: any) => ({
            label: affiliateManager.affiliate_manager,
            value: affiliateManager.affiliate_manager,
          })
        );

        const buyerAccountManagers = response.data.response_data.buyer_account_managers.data.map(
          (buyerAccountManager: any) => ({
            label: `${buyerAccountManager.fname} ${buyerAccountManager.lname}`,
            value: buyerAccountManager.manager_id,
          })
        );

        const advertiserManagers = response.data.response_data.advertiser_managers.data.map(
          (advertiserManager: any) => ({
            label: advertiserManager.account_manager,
            value: advertiserManager.account_manager,
          })
        );

        setAffliateManagersList(affliateManagers);
        setBuyerAccountManagersList(buyerAccountManagers);
        setAdvertiserManagersList(advertiserManagers);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Title title="Gross Profit Report" />
      {/*justify="space-between"*/}
      <FlexboxGrid style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={15}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Detail by"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15, float: "left" }}
          />
          {group === "affiliate" ? (
            <SelectPicker
              size="sm"
              searchable={false}
              cleanable={false}
              placeholder="Detail by"
              defaultValue={defaultGroupValue}
              value={type}
              data={typeList}
              valueKey="type"
              labelKey="label"
              onChange={(v) => {
                setType(v);
              }}
              style={{ marginRight: 15, float: "left" }}
            />
          ) : (
            ""
          )}
          <SelectPicker
            size="sm"
            searchable={true}
            cleanable={true}
            placeholder="Affiliate Manager"
            value={affiliateManager}
            data={affiliateManagersList}
            valueKey="value"
            labelKey="label"
            onChange={(v) => {
              setAffiliateManager(v);
            }}
            style={{ marginRight: 15, float: "left" }}
          />
          <SelectPicker
            size="sm"
            searchable={true}
            cleanable={true}
            placeholder="Advertiser Manager"
            value={advertiserManager}
            data={advertiserManagersList}
            valueKey="value"
            labelKey="label"
            onChange={(v) => {
              setAdvertiserManager(v);
            }}
            style={{ marginRight: 15, float: "left" }}
          />
          {group === "buyer" ? (
            <SelectPicker
              size="sm"
              searchable={true}
              cleanable={true}
              placeholder="Buyer Manager"
              value={buyerAccountManager}
              data={buyerAccountManagersList}
              valueKey="value"
              labelKey="label"
              onChange={(v) => {
                setBuyerAccountManager(v);
              }}
              style={{ marginRight: 15, float: "left" }}
            />
          ) : (
            ""
          )}
          <Checkbox
            onChange={handleChecked}
            style={{ marginRight: 15, float: "left" }}
          >
            Include E-Sign Revenue
          </Checkbox>
        </FlexboxGrid.Item>
        {/*<FlexboxGrid.Item colspan={8}>*/}
        {/*  <Checkbox*/}
        {/*    onChange={handleChecked}*/}
        {/*    style={{ marginRight: 15, float: 'left' }}*/}
        {/*  >*/}
        {/*    Include E-Sign Revenue*/}
        {/*  </Checkbox>*/}
        {/*</FlexboxGrid.Item>*/}
        <FlexboxGrid.Item colspan={8} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() => {
              group === "affiliate"
                ? exportCsv(
                    `gp_report_cat_${
                      Admediary.category
                    }_prod_${productID}_type_${type}_${format(
                      new Date(Admediary.start),
                      "MMddyy"
                    )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                    getExportData(treeData, group)
                  )
                : exportCsv(
                    `gp_report_cat_${
                      Admediary.category
                    }_prod_${productID}_${format(
                      new Date(Admediary.start),
                      "MMddyy"
                    )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                    getExportData(treeData, group)
                  );
            }}
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <GrossProfitReportTable
        data={treeData}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
        group={group}
      />
    </>
  );
};

export default GrossProfitReport;

import React, { ReactElement } from "react";

import { ResponsivePie as NivoPie } from "@nivo/pie";
import { Loader } from "rsuite";

type FilteredLeadReasonsType = {
  rejected: any | undefined;
  filtered: any | undefined;
};

const FilteredVsRejectedPieGraph: React.FC<FilteredLeadReasonsType> = (
  props
) => {
  if (props.rejected === undefined || props.filtered === undefined) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const total: number = parseInt(props.rejected) + parseInt(props.filtered);

  const formatValue = (value: string, total: number): string => {
    const percent: number = Math.round((parseInt(value) / total) * 100);
    return `${percent}%`;
  };

  const defaultData = [
    {
      id: "rejected",
      label: "Rejected",
      sliceLabel: formatValue(props.rejected, total),
      value: parseInt(props.rejected),
    },
    {
      id: "filtered",
      label: "Filtered",
      sliceLabel: formatValue(props.filtered, total),
      value: parseInt(props.filtered),
    },
  ];

  const tooltip = (item: any): ReactElement => {
    return (
      <div style={{ width: 175 }}>{`${item.label}: ${item.value.toLocaleString(
        undefined
      )}`}</div>
    );
  };

  return (
    <NivoPie
      data={defaultData}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0}
      padAngle={0.7}
      cornerRadius={0}
      colors={[
        "#37d199",
        "#eaeaea",
        "#8abac4",
        "#da6d42",
        "#315263",
        "#ecae5b",
      ]}
      sortByValue
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabel={(e) => `${e.label}`}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={-5}
      radialLabelsLinkDiagonalLength={8}
      radialLabelsLinkHorizontalLength={8}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      isInteractive={true}
      tooltip={tooltip}
    />
  );
};

export default FilteredVsRejectedPieGraph;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";
import Title from "../Title";
import CampaignList from "./CampaignList";
import Campaign from "../Drawers/Campaign";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      "Campaign ID": item.campaign_id,
      Description: item.description,
      "Product Description": item.product_description,
      "Site URL": item.site_url,
    };
  });
}

type CampaignsProps = {
  height?: number;
};

const Campaigns: React.FC<CampaignsProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
  };

  const [data, isLoading] = useAdmediaryApi("campaigns", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddItemClick = () => {
    // Set selected content into drawer
    Admediary.openDrawer(
      <Campaign campaignId={0} refreshData={refresh} />,
      "md"
    );
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   */
  const debounceSearchTerm = (value: any) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toString().toLowerCase().includes(search);
    const fields = [
      "campaign_id",
      "description",
      "product_description",
      "site_url",
    ];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <>
      <Title title="Campaigns" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
          >
            Add Campaign
          </IconButton>
          <IconButton
            onClick={() =>
              exportCsv(
                `product_campaigns_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <CampaignList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default Campaigns;

import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
// import ViewLeads from "../Drawers/ViewLeads";
// import LeadDetails from "../Drawers/LeadDetails";
// import RecordSearchLeadDetails from "../Drawers/RecordSearchLeadDetails";
import Lead from "../Drawers/Lead";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyHoldTreeTable is a separated component to prevent a scroll-to-top bug
 */
type RecordSearchTable = {
  data: any;
  isLoading: boolean;
};

const RecordSearchTable: React.FC<RecordSearchTable> = (props: any) => {
  const data = props.data;
  const isLoading = props.isLoading;

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={65}
      loading={isLoading === true}
      data={data}
    >
      <Column width={200} align="center" fixed>
        <HeaderCell>Lead Instance ID</HeaderCell>
        <Cell dataKey="lead_instance_id" />
      </Column>

      <Column width={300} fixed>
        <HeaderCell>Lead Date</HeaderCell>
        <Cell>{(rowData: any) => rowData.entry_date.toString()}</Cell>
      </Column>

      <Column width={200}>
        <HeaderCell>Product ID</HeaderCell>
        <Cell dataKey="product_id" />
      </Column>

      <Column width={200}>
        <HeaderCell>Email</HeaderCell>
        <Cell dataKey="email" />
      </Column>

      <Column width={200}>
        <HeaderCell>Phone</HeaderCell>
        <Cell dataKey="phone" />
      </Column>

      <Column width={100}>
        <HeaderCell>AFID</HeaderCell>
        <Cell dataKey="affiliate_id" />
      </Column>

      <Column width={200}>
        <HeaderCell>Affiliate Name</HeaderCell>
        <Cell dataKey="affiliate_name" />
      </Column>

      <Column width={100}>
        <HeaderCell>SID1</HeaderCell>
        <Cell dataKey="sid1" />
      </Column>
      <Column width={200}>
        <HeaderCell>Client IP</HeaderCell>
        <Cell dataKey="client_ip" />
      </Column>
      <Column width={300}>
        <HeaderCell>Trusted Form URL</HeaderCell>
        <Cell dataKey="trusted_form_url" />
      </Column>
      <Column width={300}>
        <HeaderCell>Source URL</HeaderCell>
        <Cell dataKey="source_url" />
      </Column>

      <Column width={150} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell />
      </Column>
    </Table>
  );
};

const Menu = ({ onSelect, row, summary }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="view_leads">View Leads</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, affiliate_id, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const item = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();
    const contents = new Map([
      ["view_leads", <Lead row_data={item} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([
      ["view_leads", "lg"],
      ["default", "md"],
    ]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={item} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} />
    </Cell>
  );
};

export default RecordSearchTable;

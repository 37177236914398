import React from "react";
import { FlexboxGrid, Icon, IconButton, CheckPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import RoiTreeTable from "./RoiTreeTable";
import SelectPicker from "rsuite/lib/SelectPicker";
import { format } from "date-fns";
import Title from "../Title";

/**
 * Filtered values from &amp; and &nbsp;

 */
function filteredValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = filteredValuesRecursively(row.children);
    }
    return {
      ...row,
      parent_title: row.parent_title
        .replace(/&amp;/i, "&")
        .replace(/&nbsp;/i, " "),
    };
  });
}
function getProductsData(products: any) {
  return products.map((item: any) => {
    return {
      ...item,
      ...{
        group: "product.id",
        label: item.product_description,
      },
    };
  });
}
/**
 * Fetch affiliates Ids separated by comma
 * @param affiliates
 */
function getAffiliateIds(affiliates: any) {
  return affiliates !== undefined ? affiliates.toString() : "";
}

/**
 * Fetch products Ids separated by comma
 * @param products
 */
function getProductIds(products: any) {
  return products !== undefined ? products.toString() : "";
}
function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "form_submit_count",
    "impression_count",
    "impression_unique_count",
    "conversion_count",
    "cost",
    "click_count",
    "revenue",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function getExportData(data: any, order?: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any, order?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        // Add parent values
        ...{
          site_id: item.site_id || parent.site_id,
          site_url: item.site_url || parent.site_url,
          affiliate_id: item.affiliate_id || parent.affiliate_id,
          affiliate_name: item.affiliate_name || parent.affiliate_name,
          account_manager: item.account_manager || parent.account_manager,
          page_id: item.page_id || parent.page_id,
          page_description: item.page_description || parent.page_description,
          flow_id: item.flow_id || parent.flow_id,
          flow_name: item.flow_name || parent.flow_name,
          step_id: item.step_id || parent.step_id,
          step_name: item.step_name || parent.step_name,
          offer_id: item.offer_id || parent.offer_id,
          offer_name: item.offer_name || parent.offer_name,
          sub_id: item.sub_id || parent.sub_id,
        },
        ...item,
      };
      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        const obj = {
          "Db Impression Count": parseInt(item.impression_count),
          "Db Impression Unique": thousands(item.impression_unique_count),
          Submits: item.form_submit_count,
          "Submits%": percent(parseFloat(item.submit_percent)),
          Conversions: parseFloat(item.conversion_count),
          "Conv %": percent(item.conversion_percent),
          "Accept %": percent(parseFloat(item.accept_percent)),
          Cost: currency(item.cost),
          CPL: currency(item.cpl),
          CPI: currency(item.cpi),
          Clicks: item.click_count,
          Revenue: parseFloat(item.revenue),
          RPI: currency(item.rpi),
          PPL: currency(item.pnl),
          PM: percent(item.pm),
        };
        var current = {};
        if (order === "afid_offer") {
          current = {
            ...{
              "Site URL": item.site_url,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              "Account Manager": item.account_manager,
              Page: item.page_description,
              Flow: item.flow_name,
              Step: item.step_name,
              Offer: item.offer_name,
            },
            ...obj,
          };
        } else if (order === "page_flow_step") {
          current = {
            ...{
              "Site URL": item.site_url,
              Page: item.page_description,
              Flow: item.flow_name,
              Step: item.step_name,
              Offer: item.offer_name,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              "Account Manager": item.account_manager,
              "Sub ID": item.sub_id,
            },
            ...obj,
          };
        } else if (order === "afid_subid") {
          current = {
            ...{
              "Site URL": item.site_url,
              "Affiliate ID": item.affiliate_id,
              "Affiliate Name": item.affiliate_name,
              "Account Manager": item.account_manager,
              "Sub ID": item.sub_id,
              Offer: item.offer_name,
            },
            ...obj,
          };
        } else if (order === "site_offer") {
          current = {
            ...{
              "Site URL": item.site_url,
              Offer: item.offer_name,
            },
            ...obj,
          };
        } else if (order === "offer_site") {
          delete obj["Submits"];
          delete obj["Submits%"];
          current = {
            ...{
              Offer: item.offer_name,
              "Site URL": item.site_url,
            },
            ...obj,
          };
        }

        return current;
      })
  );
}

function addCalculatedValues(row: any) {
  row.form_submit_count = isNaN(parseFloat(row.form_submit_count))
    ? 0
    : parseFloat(row.form_submit_count);
  row.impression_unique_count = isNaN(parseFloat(row.impression_unique_count))
    ? 0
    : parseFloat(row.impression_unique_count);
  row.conversion_count = isNaN(parseFloat(row.conversion_count))
    ? 0
    : parseFloat(row.conversion_count);
  row.cost = isNaN(parseFloat(row.cost)) ? 0 : parseFloat(row.cost);
  row.revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);
  row.click_count = isNaN(parseFloat(row.click_count))
    ? 0
    : parseFloat(row.click_count);
  // row.presented = parseFloat(row.presented);
  return {
    ...row,
    ...{
      cpl: row.conversion_count !== 0 ? row.cost / row.conversion_count : 0,
      cpi: row.impression_count !== 0 ? row.cost / row.impression_count : 0,
      rpi: row.accepted !== 0 ? row.revenue / row.impression_count : 0,
      pnl: row.revenue - row.cost,
      cpm: (row.revenue / row.impression_count) * 1000,
      pm:
        row.revenue !== 0 ? ((row.revenue - row.cost) / row.revenue) * 100 : 0,
      submit_percent:
        row.impression_unique_count !== 0
          ? (row.form_submit_count / row.impression_unique_count) * 100
          : 0,
      conversion_percent:
        row.impression_unique_count !== 0
          ? (row.conversion_count / row.impression_unique_count) * 100
          : 0,
      accept_percent:
        row.form_submit_count !== 0
          ? (row.conversion_count / row.form_submit_count) * 100
          : 0,
      // accept_percent:
      //   row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
    },
  };
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const RoiTree: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultOrderValue = "afid_offer";
  const [order, setOrder] = React.useState(defaultOrderValue);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [products, setProducts] = React.useState();
  const affiliatesData = Admediary.config.affiliates;
  const productIds = getProductIds(products);
  const [affiliates, setAffiliate] = React.useState();
  const affiliateIds = getAffiliateIds(affiliates);

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: productIds,
    affiliate_id: affiliateIds,
    grouping: order,
  };
  //fetch data for Report
  const [data, isLoading] = useAdmediaryApi("site_roi_flow_tree", params);

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const exportData = getExportData(treeData, order);
  const filterdData = filteredValuesRecursively(treeData);
  const summaryData = getSummary(Array.isArray(data) ? data : []);

  const groupByList = [
    { order: "afid_offer", label: "Site/Affiliate/Page/Flow" },
    { order: "page_flow_step", label: "Site/Page/Flow/Step" },
    { order: "afid_subid", label: "Site/Affiliate/SubID" },
    { order: "site_offer", label: "Site/Offer" },
    { order: "offer_site", label: "Offer/Site" },
  ];
  //fetch products for Filter
  const productList: any = useAdmediaryApi("products", {
    tag_name: "adm_flow_site",
  });

  const productsData = getProductsData(
    Array.isArray(productList[0]) ? productList[0] : []
  );

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="ROI Flow Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Order by"
            defaultValue={defaultOrderValue}
            value={order}
            data={groupByList}
            valueKey="order"
            labelKey="label"
            onChange={(v) => {
              setOrder(v);
            }}
            style={{ marginRight: 15, width: 250 }}
          />
          <CheckPicker
            size="sm"
            searchable={false}
            cleanable={false}
            value={products}
            data={productsData}
            onChange={(v) => {
              setProducts(v);
            }}
            valueKey="product_id"
            labelKey="product_description"
            style={{ width: 250, marginRight: 15 }}
            placeholder="Products"
          />
          <CheckPicker
            size="sm"
            placeholder="Affiliates"
            value={affiliates}
            data={affiliatesData}
            onChange={(v) => {
              setAffiliate(v);
            }}
            valueKey="affiliate_id"
            labelKey="affiliate_name"
            style={{ width: 250, marginRight: 15 }}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `flow_roi_tree_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <RoiTreeTable
        data={filterdData}
        titles={groupByList}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        order={order}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default RoiTree;

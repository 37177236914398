import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
  Table,
  ControlLabel,
  SelectPicker,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractSourceForm from "./BuyerContractSourceForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { FormContext } from "../../@Context/FormContext";
import BuyerContractsWithSources from "./BuyerContractsWithSources";
import BuyerContractsUploadSources from "./BuyerContractsUploadSources";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractSourceListType = {
  contractId: number;
  height?: number;
  autoHeight?: boolean;
  onlyPreview?: boolean;
  contractName?: string;
};

const BuyerContractSourceList: React.FC<BuyerContractSourceListType> = ({
  contractId = 0,
  height = 650,
  autoHeight = true,
  onlyPreview = false,
  contractName = "",
}) => {
  const Forms = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const [uploadSources, setUploadSources] = React.useState();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };

  const [data, isLoading] = useAdmediaryApi("buyer_contract_sources", params);
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleUploadCallback = (data: any) => {
    setUploadSources(data);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.is_active)) {
      data.is_active = data.is_active.length ? data.is_active[0] : 0;
    }

    (async () => {
      try {
        await Query("buyer_contract_source_update", data);
        Notification.success({
          title: "Success",
          description: "Buyer Contract Source has been updated",
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const handleSaveUpload = () => {
    uploadSources.forEach((item: any) => {
      (async () => {
        try {
          await Query("buyer_contract_source_update", item);
        } catch (e) {
          console.log("error", e);
          Notification.error({
            title: "Error",
            duration: 60000,
            description: e,
          });
        } finally {
        }
      })();
      handleCloseUploadSourcesModal();
      refresh();
    });
  };

  const removeSource = (sourceId: number = 0) => {
    if (sourceId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_source_remove", { source_id: sourceId });
        Notification.success({
          title: "Success",
          description: "Buyer Contract Source has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeSource(selectedItem.source_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddSourceClick = () => {
    setSelectedItem({
      source_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  const setDefaultAllow = (data: any) => {
    Forms.setDefaultAllow(data);
  };

  const [importSourcesModalOpen, setImportSourcesModalOpen] = React.useState(
    false
  );

  const [uploadSourcesModalOpen, setUploadSourcesModalOpen] = React.useState(
    false
  );

  const openImportSourcesModal = () => setImportSourcesModalOpen(true);
  const closeImportSourcesModal = () => setImportSourcesModalOpen(false);

  const openUploadSourcesModal = () => setUploadSourcesModalOpen(true);
  const closeUploadSourcesModal = () => setUploadSourcesModalOpen(false);

  const handleImportSourcesClick = () => {
    openImportSourcesModal();
  };

  const handleUploadSourcesClick = () => {
    openUploadSourcesModal();
  };

  const handleCloseImportSourcesModal = () => {
    refresh();

    closeImportSourcesModal();
  };

  const handleCloseUploadSourcesModal = () => {
    refresh();

    closeUploadSourcesModal();
  };

  const formatExportData = (data: any) => {
    return data
      .map((item: any) => item)
      .map((item: any) => {
        return {
          "Contract ID": contractId,
          "Contract Name": contractName,
          "Affiliate ID": item.affiliate_id,
          Sid1: item.sid1,
          Sid2: item.sid2,
          Sid3: item.sid3,
          Sid4: item.sid4,
          Allow: item.allow ? "Allow" : "Block",
          Date: format(new Date(), "MM/dd/yyyy"),
        };
      });
  };

  return (
    <>
      {!onlyPreview && (
        <FlexboxGrid justify="start" style={{ marginBottom: 15 }}>
          <FlexboxGrid.Item colspan={24}>
            <IconButton
              onClick={handleAddSourceClick}
              icon={<Icon icon="plus-circle" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Add Source
            </IconButton>
            <IconButton
              onClick={handleImportSourcesClick}
              icon={<Icon icon="import" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Import Sources
            </IconButton>
            <IconButton
              onClick={handleUploadSourcesClick}
              icon={<Icon icon="file-upload" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Upload CSV
            </IconButton>
            <IconButton
              onClick={() =>
                exportCsv(
                  `contractID_${contractId}_${contractName}_${format(
                    new Date(),
                    "MMddyy"
                  )}_sources`,
                  formatExportData(Array.isArray(data) ? data : [])
                )
              }
              icon={<Icon icon="file-download" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Export
            </IconButton>
            <ControlLabel style={{ marginRight: 15 }}>Default:</ControlLabel>
            <SelectPicker
              size="sm"
              searchable={false}
              placeholder=""
              value={Forms.defaultAllow}
              data={[
                { value: 1, label: "Allow" },
                { value: 0, label: "Block" },
              ]}
              onChange={setDefaultAllow}
              style={{ marginRight: 15 }}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {!onlyPreview && (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Afid</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid1</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid2</HeaderCell>
          <Cell dataKey="sid2" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid3</HeaderCell>
          <Cell dataKey="sid3" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid4</HeaderCell>
          <Cell dataKey="sid4" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Allow</HeaderCell>
          <Cell dataKey="allow">
            {(rowData: any) => (rowData.allow ? "Allow" : "Block")}
          </Cell>
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Source?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.source_id > 0 ? "Edit" : "Add") +
          " Source"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <BuyerContractSourceForm
          sourceId={selectedItem ? selectedItem.source_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>

      <EditModal
        title="Import Sources"
        open={importSourcesModalOpen}
        size={"lg"}
        onClose={handleCloseImportSourcesModal}
        onCancel={handleCloseImportSourcesModal}
        hideSubmitButton={true}
      >
        <BuyerContractsWithSources contractId={contractId} />
      </EditModal>

      <EditModal
        title="Upload Sources"
        open={uploadSourcesModalOpen}
        size={"lg"}
        onClose={handleCloseUploadSourcesModal}
        onCancel={handleCloseUploadSourcesModal}
        onSubmit={handleSaveUpload}
      >
        <BuyerContractsUploadSources
          contractId={contractId}
          parentCallback={handleUploadCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractSourceList;

import React from "react";
import {
  Button,
  Icon,
  Drawer,
  Grid,
  Row,
  Col,
  Nav,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import PingTreeCampaigns from "../Products/PingTreeCampaigns";
import Routings from "../Products/Routings";
import PingTree from "../Products/PingTree";
import { Query } from "../../@Utils/AdmediaryApi";

type PingTreesModal = {
  treeId: number;
  rowData: any;
  fetchData: any;
};

const PingTreesModal: React.FC<PingTreesModal> = ({
  rowData,
  treeId = 0,
  fetchData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [activeKey, setActiveKey] = React.useState("A");
  const [formValue, setFormValue] = React.useState({});
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const mainFormRef = React.createRef();
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleSave = () => {
    if (
      mainFormRef &&
      mainFormRef.current &&
      // @ts-ignore
      !mainFormRef.current.check() &&
      // @ts-ignore
      mainFormRef.current.cleanErrors()
    ) {
      setFormValue({});
      return;
    }
    const data = {
      // ...formValue,
      ...{
        tree_id: Forms.tree,
        category_id: Forms.category,
        product_id: Forms.product,
        description: Forms.description,
        active: Forms.active ? 1 : 0,
      },
    };

    (async () => {
      try {
        await Query("update_ping_tree", data);
        Notification.open({
          title: "Success",
          description: "Ping Tree  has been updated",
        });
        fetchData();
        Admediary.closeDrawer();
      } catch (e) {
        setFormValue({});
        console.log("error", e);
      } finally {
        setFormValue({});
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Edit {rowData.description}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Nav
                activeKey={activeKey}
                appearance="tabs"
                onSelect={(eventKey) => {
                  setActiveKey(eventKey);
                }}
              >
                <Nav.Item eventKey="A">Ping Tree</Nav.Item>
                <Nav.Item eventKey="B">Routings</Nav.Item>
                <Nav.Item eventKey="C">Campaigns</Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                display: activeKey === "A" ? "block" : "none",
                minHeight: "100px",
              }}
            >
              <PingTree
                treeId={treeId}
                formRef={mainFormRef}
                parentCallback={handleCallback}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{ display: activeKey === "B" ? "block" : "none" }}
            >
              <Routings
                treeId={treeId}
                sortColumn={sort_column}
                sortType={sort_type}
                onSortColumn={handleSortColumn}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{ display: activeKey === "C" ? "block" : "none" }}
            >
              <PingTreeCampaigns
                treeId={treeId}
                sortColumn={sort_column}
                sortType={sort_type}
                onSortColumn={handleSortColumn}
              />
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
      <Drawer.Footer style={{ marginTop: -70, textAlign: "center" }}>
        <Button
          onClick={handleSave}
          appearance="primary"
          disabled={Admediary.userDisable}
        >
          Save
        </Button>
        <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
          Cancel
        </Button>
      </Drawer.Footer>
    </>
  );
};

export default PingTreesModal;

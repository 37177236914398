import React from "react";
import { Drawer, FlexboxGrid, Icon, IconButton } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { sortTree } from "../../@Utils/Sorting";
import { exportCsv } from "../../@Utils/Export";
import { format } from "date-fns";
import { Table } from "rsuite";
import {
  CurrencyCell,
  PercentCell,
  ParentCell,
  HeaderSummary,
  NumberCell,
} from "../Table";
import { currency, percent, thousands } from "../../@Utils/Format";

const { Column, HeaderCell } = Table;

type EsignEPC = {
  row_data: any;
};

const EsignEPCDrawer: React.FC<EsignEPC> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const rowKey: string = "node_id";
  const product_id = row_data.product_id;
  const affiliate_id = row_data.affiliate_id;
  const config: any = Admediary.config;

  React.useEffect(() => {
    handleSortColumn("clicks", "desc");
  }, []);

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: product_id,
    afid: affiliate_id,
  };

  const [data, isLoading] = useAdmediaryApi("panda_epc_select", params);

  /**
   *
   * @param rowData
   * Expand Rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   *
   * @param data
   * Calculate Summary Data
   */
  const getSummary = (data: any) => {
    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = [
      "clicks",
      "total_leads",
      "conversions",
      "doc_completed_rate",
      "non_retainer_received_amount",
      "non_retainer_count",
      "paid_amount",
      "completed",
      "presented",
      "completed_percent",
      "profit",
      "loss",
      "form_percent",
      "epc",
    ];

    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = data.map((item: { [index: string]: string }): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    return addCalculatedValues(summary);
  };

  /**
   *
   * @param row
   * Called from addCalculatedValuesRecursively to format data and handle calculations
   */
  const addCalculatedValues = (row: any) => {
    row.clicks = isNaN(parseFloat(row.clicks)) ? 0 : parseFloat(row.clicks);
    row.conversions = isNaN(parseFloat(row.conversions))
      ? 0
      : parseFloat(row.conversions);
    row.doc_completed_rate = isNaN(parseFloat(row.doc_completed_rate))
      ? 0
      : parseFloat(row.doc_completed_rate);
    row.presented = isNaN(parseFloat(row.presented))
      ? 0
      : parseFloat(row.presented);
    row.non_retainer_count = isNaN(parseFloat(row.non_retainer_count))
      ? 0
      : parseFloat(row.non_retainer_count);
    row.non_retainer_received_amount = isNaN(
      parseFloat(row.non_retainer_received_amount)
    )
      ? 0
      : parseFloat(row.non_retainer_received_amount);
    row.paid_amount = isNaN(parseFloat(row.paid_amount))
      ? 0
      : parseFloat(row.paid_amount);
    row.completed = isNaN(parseFloat(row.completed))
      ? 0
      : parseFloat(row.completed);
    row.total_leads = isNaN(row.total_leads) ? 0 : parseFloat(row.total_leads);
    const total_received =
      parseFloat(row.non_retainer_received_amount) +
      parseFloat(row.doc_completed_rate);
    const profit = total_received - parseFloat(row.paid_amount);
    const loss = parseFloat(row.paid_amount) - total_received;
    const formFillRate = (row.total_leads / row.clicks) * 100;

    return {
      ...row,
      ...{
        completed_percent:
          row.presented !== 0 ? (row.completed / row.presented) * 100 : 0,
        profit: profit > 0 ? profit : 0,
        loss:
          row.paid_amount > 0 && profit <= 0
            ? "-" + Number(loss).toFixed(2)
            : 0,
        form_percent: isNaN(formFillRate) ? 0 : formFillRate,
        epc: total_received / row.clicks,
      },
    };
  };

  /**
   *
   * @param list
   * Accepts report data to recursively format data and handle calculations
   * for tree data
   */
  const addCalculatedValuesRecursively = (list: any) => {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = addCalculatedValuesRecursively(row.children);
      }

      return addCalculatedValues(row);
    });
  };

  /**
   *
   * @param data
   * Flattens tree and formats data for export
   */
  const formatExportData = (data: any) => {
    const flattenTree = (list: any, parent?: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        parent = parent || item;

        const current = {
          ...{
            sid1: item.sid1 || parent.sid1,
            cid: item.cid || parent.cid,
          },
          ...item,
        };
        if (current.children && Array.isArray(current.children)) {
          result.push(...flattenTree(current.children, current));
        } else {
          result.push(current);
        }
      });
      return result;
    };

    return flattenTree(data.map((item: any) => item)).map((item: any) => {
      const ping_tree_received = parseInt(item.non_retainer_received_amount);
      const total_cost = parseInt(item.paid_amount);
      const doc_completed_rate = parseInt(item.doc_completed_rate);

      return {
        "Sub Affiliate": item.sid1,
        "Campaign ID": item.cid,
        Clicks: item.clicks,
        "Total Leads": item.total_leads,
        "Form Fill Percent": percent(item.form_percent),
        "Total Created Docs": item.presented,
        "Completed Docs": item.completed,
        "Document Revenue": currency(doc_completed_rate),
        "Completed %": percent(item.completed_percent),
        "Non-Approved Leads": item.non_retainer_count,
        "Ping Tree Revenue": currency(ping_tree_received),
        "Total Cost (CPC/CPA)": currency(total_cost),
        Profit: currency(item.profit),
        Loss: currency(item.loss),
        EPC: currency(item.epc),
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  /**
   * @param list
   * Sorts tree data
   */
  const sortData = (list: any) => {
    return addCalculatedValuesRecursively(
      sort_column && sort_type ? sortTree(list, sort_column, sort_type) : list
    );
  };

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = formatExportData(treeData);

  console.log(summaryData);

  /**
   * Returns an array with object from config.products that matches the product_id
   * passed from the prop row_data
   */
  const productName = config.products.filter((item: any) => {
    return item.product_id === product_id;
  });

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {productName
            ? `EPC - ${productName[0].product_description} - Affiliate ID: ${affiliate_id}`
            : `EPC - Affiliate ID: ${affiliate_id}`}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={19}></FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `esign_epc_productID_${product_id}_afid_${affiliate_id}_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  exportData
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          isTree
          height={650}
          headerHeight={80}
          loading={isLoading === true}
          data={sortData(Array.isArray(treeData) ? treeData : [])}
          rowClassName="clickable-data striped-rows"
          affixHeader
          affixHorizontalScrollbar
          rowKey={rowKey}
          onRowClick={handleExpanded}
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>Sub Affiliate/Campaign ID</HeaderCell>
            <ParentCell dataKey="parent_title" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Clicks"
                summary={thousands(summaryData.clicks)}
              />
            </HeaderCell>
            <NumberCell dataKey="clicks" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Total Leads"
                summary={thousands(summaryData.total_leads)}
              />
            </HeaderCell>
            <NumberCell dataKey="total_leads" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Form Fill %"
                summary={percent(summaryData.form_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="form_percent" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Created Docs"
                summary={thousands(summaryData.presented)}
              />
            </HeaderCell>
            <NumberCell dataKey="presented" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Completed Docs"
                summary={thousands(summaryData.completed)}
              />
            </HeaderCell>
            <NumberCell dataKey="completed" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Completed %"
                summary={percent(summaryData.completed_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="completed_percent"></PercentCell>
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Document Revenue"
                summary={currency(summaryData.doc_completed_rate)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="doc_completed_rate" />
          </Column>

          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Ping Tree Count"
                summary={thousands(summaryData.non_retainer_count)}
              />
            </HeaderCell>
            <NumberCell dataKey="non_retainer_count" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Ping Tree Revenue"
                summary={currency(summaryData.non_retainer_received_amount)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="non_retainer_received_amount" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Cost"
                summary={currency(summaryData.paid_amount)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="paid_amount" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Profit"
                summary={currency(summaryData.profit)}
              />
            </HeaderCell>
            <CustomCurrencyCell dataKey="profit" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Loss"
                summary={currency(summaryData.loss)}
              />
            </HeaderCell>
            <CustomCurrencyCell dataKey="loss" />
          </Column>
          <Column width={125} align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="EPC" summary={currency(summaryData.epc)} />
            </HeaderCell>
            <CurrencyCell dataKey="epc" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

const CustomCurrencyCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  ...props
}: any) => {
  const style = rowData.loss < 0 ? "red" : "black";

  return (
    <CurrencyCell
      rowData={rowData}
      dataKey={dataKey}
      {...props}
      style={{ color: style }}
    />
  );
};

export default EsignEPCDrawer;

import React from "react";
import { ResponsiveLine as NivoResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import { Loader } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

type LeadsByDayLineGraphProps = {
  buyer_id?: number;
  affiliate_id?: number;
  category_id?: number;
};

const LeadsByDayLineGraph: React.FC<LeadsByDayLineGraphProps> = ({
  buyer_id,
  affiliate_id,
  category_id,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: category_id ? category_id : admediaryContext.category,
    product_id: admediaryContext.product,
    affiliate_id: affiliate_id ? affiliate_id : 0,
    buyer_id: buyer_id
      ? buyer_id
      : admediaryContext.buyer
      ? admediaryContext.buyer
      : 0,
    group: "day",
  };

  const [data, isLoading] = useAdmediaryApi("leads_disposition", params);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const LineData: any = [
    {
      id: "Accepted",
      data: [],
    },
    {
      id: "Considered",
      data: [],
    },
  ];

  data.forEach((row) => {
    const date = format(row.posted_date, "MMM d");
    LineData.forEach((line: any) => {
      line.id === "Considered"
        ? line.data.push({ x: date, y: row.rejected + row.accepted })
        : line.data.push({ x: date, y: row.accepted });
    });
  });

  return (
    <NivoResponsiveLine
      data={LineData}
      margin={{ top: 25, right: 110, bottom: 80, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 12,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 0,
        tickRotation: 0,
        legend: "Leads",
        legendOffset: -50,
        legendPosition: "middle",
      }}
      colors={["#37d199", "#ff8400", "#bd24ce", "#0bd6ef"]}
      enableArea={true}
      areaBaselineValue={40}
      areaOpacity={0.05}
      lineWidth={1}
      pointSize={8}
      pointColor={{ from: "color" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "color" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      isInteractive={true}
      enableSlices="x"
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LeadsByDayLineGraph;

import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Field from "../Field";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  template_id: NumberType(),
  template_name: StringType().isRequired("Template Name is required."),
  template_content: StringType().isRequired("Template Content is required."),
});

type BuyerContractEmailTemplateFormType = {
  templateId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractEmailTemplateForm: React.FC<BuyerContractEmailTemplateFormType> = ({
  templateId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    template_id: 0,
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});
  const Admediary = React.useContext(AdmediaryContext);

  const params = {
    template_id: templateId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.template_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_email_template", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label="Template Name"
        name="template_name"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Template Content"
        name="template_content"
        rows={8}
        componentClass="textarea"
        disabled={Admediary.userDisable}
      />
    </Form>
  );
};

export default BuyerContractEmailTemplateForm;

import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  CheckboxGroup,
  Checkbox,
  InputNumber,
} from "rsuite";
import Field from "../Field";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  rate_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  active: ArrayType(),
  rate: NumberType().isRequired("Rate is required."),
  description: StringType(),
  evaluator: StringType(),
  affiliate_id: NumberType(),
  sid1: StringType(),
});

type BuyerContractRateFormType = {
  rateId?: number;
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractRateForm: React.FC<BuyerContractRateFormType> = ({
  rateId = 0,
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptyItem = {
    rate_id: 0,
    contract_id: contractId,
    active: [],
    affiliate_id: null,
    sid1: null,
  };
  const [formValue, setFormValue] = React.useState(emptyItem);
  const [formError, setFormError] = React.useState({});
  const [isDisabled, setIsDisabled] = React.useState(true);

  const params = {
    rate_id: rateId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.rate_id === 0) {
      setData(emptyItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_rate", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    if (isNaN(record.affiliate_id)) {
      record.affiliate_id = null;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (
      data.affiliate_id > 0 &&
      data.affiliate_id !== "" &&
      data.affiliate_id !== null
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setFormValue({ ...data, ...{ sid1: "" } });
    }

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Active" name="active" accepter={CheckboxGroup}>
        <Checkbox value={1} />
      </Field>
      <Field label="Rate" name="rate" accepter={InputNumber} />
      <Field label="Description" name="description" />
      <Field label="AFID" name="affiliate_id" />
      <Field label="SID1" name="sid1" disabled={isDisabled} />
      <Field
        label="Evaluator"
        rows={8}
        name="evaluator"
        componentClass="textarea"
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractRateForm;

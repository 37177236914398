import React from "react";
import {
  Form,
  FormControl,
  ControlLabel,
  FormGroup,
  InputNumber,
  Schema,
} from "rsuite";

import { FormContext } from "../../@Context/FormContext";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Field from "../Field";

type EditCreativeModalProps = {
  id?: number;
  count?: number;
  probability?: number;
  message?: string;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  creative_id: NumberType(),
  probability: NumberType(),
  sms_message: StringType().isRequired("Message Name is required."),
});

const EditCreativeModal: React.FC<EditCreativeModalProps> = ({
  id = 0,
  probability = 0,
  message = "",
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const formContext = React.useContext(FormContext);
  const Admediary = React.useContext(AdmediaryContext);
  const [formValue, setFormValue] = React.useState({
    creative_id: id,
    probability: probability === 0 ? probability : formContext.probability,
    sms_message: message === "" ? message : formContext.message,
  });

  const [formError, setFormError] = React.useState({});
  const handleChange = (data: any) => {
    setFormValue(data);
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };
  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setProbability = (data: any) => {
    formContext.setProbability(data);
  };

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setMessage = (data: any) => {
    formContext.setMessage(data);
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label="Probability"
        name="probability"
        accepter={InputNumber}
        value={formContext.probability}
        onChange={setProbability}
        defaultValue={0}
        min={0}
        max={100}
        disabled={Admediary.userDisable}
      />
      <FormGroup>
        <ControlLabel>Message</ControlLabel>
        <FormControl
          rows={8}
          name="sms_message"
          componentClass="textarea"
          value={formContext.message}
          onChange={setMessage}
          disabled={Admediary.userDisable}
        />
      </FormGroup>
    </Form>
  );
};

export default EditCreativeModal;

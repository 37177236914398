import React from "react";
import { Drawer, Form, FormGroup, ControlLabel, FormControl } from "rsuite";

type LeadDetailsProps = {
  lead: any;
};

/**
 * affiliate_id: "487"
 affiliate_name: "Popular Marketing LLC"
 sid1: "271717"
 sid2: "b9735baa1fe5427f9dd58d7ab4819273"
 sid3: "38a35c39d2a2446583b51a834315ca77"
 buyer_name: "National Lawsuit Funding"
 disposition: "accepted"
 disposition_detailed: "accepted"
 first_name: "Jimmy"
 last_name: "Taylor"
 email: "jimmytaylor359@gmail.com"
 phone: "2629977710"
 client_ip: "172.58.145.166"
 product_description: "LawsuitWinning Personal Injury"
 product_id: "32"
 get_data: ""
 lead_id: "96434610"
 lead_instance_id: "96434610"
 post_data: "&edit_firstname=Jimmy&edit_lastname=Taylor&edit_email=jimmytaylor359%40gmail.com&edit_phone=2629977710&edit_address=4706+38th+Ave&edit_zip=53144&injured_accident=1&injury_type=issue_caraccident&issue_caraccident=1&issue_mcaccident=0&issue_truckaccident=0&issue_medmalpractice=0&issue_workrelated=0&issue_slip=0&issue_product=0&injury_year=2018&was_hospitalized=1&have_attorney=1&lawsuit_pending_or_settled=1&cash_option=1&case_notes=I%27%27m+waiting+on+another+law+suit+&submit=Submit+My+Claim&xxTrustedFormToken=https%3A%2F%2Fcert.trustedform.com%2F97288e1c744471316aa53dbf42e03bfe0217274e&xxTrustedFormCertUrl=https%3A%2F%2Fcert.trustedform.com%2F97288e1c744471316aa53dbf42e03bfe0217274e&xxTrustedFormPingUrl=https%3A%2F%2Fping.trustedform.com%2F0.NQ7CodZbsUJ7bzVpxm1yhJ7li4xGt8I37kqeBvQFL6zp_UYEA9sh7PBhqWQBETE-fkyrqSWE.gnXoufk5U7Pbgwt_Pem94g.FmzcnS41JPswub7ZJz738g&universal_leadid=F1FE0D82-0BD3-84C3-6B14-66C041CEF625&edit_lead_instance_id=96434610&edit_product_id=32&edit_crid=202321071&edit_afid=487&edit_cid=7914&edit_sid1=271717&edit_sid2=b9735baa1fe5427f9dd58d7ab4819273&edit_sid3=38a35c39d2a2446583b51a834315ca77&edit_click_instance_id=&edit_redirect_instance_id=&edit_campaign_id=275&edit_source_url=https%3A%2F%2Flawsuit-winning.com%2Fpersonalinjury5%2F&lead_is_unique=1"
 post_date: "2020-09-22 15:00"
 price_received: "25.00"
 */

const LeadDetails: React.FC<LeadDetailsProps> = ({ lead }) => {
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>
          Lead ID #{lead.lead_instance_id}{" "}
          {lead.lead_id.length > 0 && lead.lead_id ? `(${lead.lead_id})` : null}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form fluid formValue={lead}>
          <FormGroup>
            <ControlLabel>Post Date</ControlLabel>
            <FormControl name="post_date" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Status</ControlLabel>
            <FormControl name="disposition" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Disposition</ControlLabel>
            <FormControl name="disposition_detailed" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Buyer</ControlLabel>
            <FormControl name="buyer_name" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Affiliate</ControlLabel>
            <FormControl name="affiliate_name" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>SID1</ControlLabel>
            <FormControl name="sid1" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>SID2</ControlLabel>
            <FormControl name="sid2" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>SID3</ControlLabel>
            <FormControl name="sid3" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Product</ControlLabel>
            <FormControl name="product_description" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>First Name</ControlLabel>
            <FormControl name="first_name" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Last Name</ControlLabel>
            <FormControl name="last_name" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <FormControl name="email" type="email" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>IP</ControlLabel>
            <FormControl name="client_ip" readOnly />
          </FormGroup>
          <FormGroup>
            <ControlLabel>GET Data</ControlLabel>
            <FormControl rows={8} name="get_data" componentClass="textarea" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>POST Data</ControlLabel>
            <FormControl rows={8} name="post_data" componentClass="textarea" />
          </FormGroup>
        </Form>
      </Drawer.Body>
    </>
  );
};

export default LeadDetails;

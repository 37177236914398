import React from "react";
import { FlexboxGrid, Icon, IconButton, Input, InputGroup } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { exportCsv } from "../../@Utils/Export";
import ActiveSourcesList from "./ActiveSourcesList";
import Title from "../Title";
import { format } from "date-fns";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    const lastPost = item.last_post
      ? format(item.last_post, "MM-dd-y HH:mm")
      : "";

    return {
      "Data Source ID": item.data_source_id,
      "Data Source Name": item.data_source_name,
      Description: item.description,
      "Last Post": lastPost,
      "Yesterday Count": item.yesterday_count,
      "Today Count": item.today_count,
    };
  });
}

type ActiveSourcesProps = {
  height?: number;
};

const ParseData = (list: any) => {
  return list.map((item: any) => {
    const today = parseInt(item.today_count);
    const yesterday = parseInt(item.yesterday_count);
    return {
      ...item,
      ...{
        today: today,
        yesterday: yesterday,
      },
    };
  });
};

const ActiveSources: React.FC<ActiveSourcesProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const [data, isLoading] = useAdmediaryApi("data_active_sources");

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["data_source_name", "description"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <>
      <Title title="Data Active Sources" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `data_active_contracts_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <ActiveSourcesList
        height={height}
        data={filter(Array.isArray(data) ? ParseData(data) : [], searchQuery)}
        isLoading={isLoading === true}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default ActiveSources;

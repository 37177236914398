import React from "react";
import Dashboard from "../@Components/Dashboard";
import PrimaryStatCards from "../@Components/Visualization/PrimaryStatCards";
import { Col, Content, Grid, Row } from "rsuite";
import TopVerticalsByLeadsBarGraph from "../@Components/Visualization/TopVerticalsByLeadsBarGraph";
import LeadsByDayLineGraph from "../@Components/Visualization/LeadsByDayLineGraph";
import { AdmediaryContext } from "../@Context/AdmediaryContext";
import BuyerList from "../@Components/Buyers/BuyerList";
import Title from "../@Components/Title";

const Buyers: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  return (
    <Dashboard>
      <PrimaryStatCards />
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24} sm={24} md={12}>
            <Content>
              <h5>
                Top {!Admediary.category ? "Verticals" : "Products"} by Leads
                Purchased
              </h5>
              <div style={{ height: 295 }}>
                <TopVerticalsByLeadsBarGraph />
              </div>
            </Content>
          </Col>
          <Col className="content-card chart" xs={24} sm={24} md={12}>
            <Content>
              <h5>Leads by Day</h5>
              <div style={{ height: 295 }}>
                <LeadsByDayLineGraph />
              </div>
            </Content>
          </Col>
        </Row>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content style={{ height: 725 }}>
              <Title title="Buyers List" />
              <BuyerList />
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default Buyers;

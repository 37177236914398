import React from "react";
import Dashboard from "../../@Components/Dashboard";
import { Col, Content, Grid, Row, Message } from "rsuite";
import LeadDetail from "../../@Components/Leads/Report/LeadDetail";
import Title from "../../@Components/Title";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const LeadDetailReport: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  return (
    <Dashboard>
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card chart" xs={24}>
            <Content style={{ height: "fit-content" }}>
              <Title title="Lead Detail Report" />
              {Admediary.product && Admediary.category ? (
                <LeadDetail />
              ) : (
                <div>
                  <Message
                    showIcon
                    type="warning"
                    description={
                      "Category and Product should be selected for loading corresponding data"
                    }
                  />
                </div>
              )}
            </Content>
          </Col>
        </Row>
      </Grid>
    </Dashboard>
  );
};

export default LeadDetailReport;

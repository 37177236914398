import React from "react";
import { Col, Drawer, Grid, Row } from "rsuite";
import StatCard from "../Visualization/StatCard";
import TopBuyersByLeadsBarGraph from "../Visualization/TopBuyersByLeadsBarGraph";
import LeadsByDayLineGraph from "../Visualization/LeadsByDayLineGraph";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

type BuyerOverview = {
  category_id: number;
  category_name: string;
  accepted: number;
  filtered: number;
  rejected: number;
};

const AffiliateOverview: React.FC<BuyerOverview> = ({
  category_id,
  category_name,
  accepted,
  filtered,
  rejected,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: category_id,
    product_id: 0,
    affiliate_id: 0,
    buyer_id: 0,
    group: "",
  };

  let revenue = undefined,
    profit = undefined;
  const [data, leadsIsLoading] = useAdmediaryApi("leads_purchased", params);
  if (!leadsIsLoading && Array.isArray(data)) {
    revenue = data[0].price_received;
    profit = data[0].price_received - data[0].price_owed;
  }

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>{category_name}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Grid fluid style={{ width: "100%" }}>
          <Row style={{ marginBottom: 25 }}>
            <Col xs={6} className="content-card overview-stat-card">
              <StatCard
                label="Considered"
                type="number"
                value={filtered + rejected}
              />
            </Col>
            <Col xs={6} className="content-card overview-stat-card">
              <StatCard label="Accepted" type="number" value={accepted} />
            </Col>
            <Col xs={6} className="content-card overview-stat-card">
              <StatCard label="Revenue" type="currency" value={revenue} />
            </Col>
            <Col xs={6} className="content-card overview-stat-card">
              <StatCard label="Profit" type="currency" value={profit} />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <h5>Leads by Buyers</h5>
              <div style={{ height: 295 }}>
                <TopBuyersByLeadsBarGraph category_id={category_id} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <h5>Leads by Day</h5>
              <div style={{ height: 295 }}>
                <LeadsByDayLineGraph category_id={category_id} />
              </div>
            </Col>
          </Row>
        </Grid>
      </Drawer.Body>
    </>
  );
};

export default AffiliateOverview;

import React from "react";
import { parseISO } from "date-fns";
import { TypeAttributes } from "rsuite/lib/@types/common";

/**
 * Constants for local storage keys
 */
const LOCAL_STORAGE_KEYS = {
  DATES: "filters.dates",
  CATEGORY: "filters.category",
  CLIENTS: "filters.clients",
  PRODUCT: "filters.product",
  BUYER: "filters.buyer",
  AFFILIATE: "filters.affiliates",
  CONTRACT: "filters.contract",
  BUYER_CONTRACT: "filters.buyerContract",
  PREPAYMENT_AMOUNT: "prepayment.amount",
  PREPAYMENT_DATE: "prepayment.date",
  PREPAYMENT_REFRESH: "prepayment.refresh",
  SIDEBAR_STATE: "sidebar.state",
  SIDEBAR_OPEN_KEYS: "sidebar.openKeys",
  DETAIL: "filters.detail",
};

interface AdmediaryProviderProps {
  children?: React.ReactNode;
}

type AdmediaryContextType = {
  start: Date;
  end: Date;
  PrepaymentDate: Date;
  PrepaymentAmount: number;
  PrepaymentRefresh: boolean;
  category: any;
  drawerOpened: boolean;
  drawerContent?: any;
  drawerSize: TypeAttributes.Size;
  drawerFull: boolean;
  openDrawer?: any;
  closeDrawer?: any;
  changeSize?: any;
  maximizeDrawer?: any;
  restoreDrawer?: any;
  affiliate: any;
  product: any;
  buyer: any;
  contract: any;
  buyerContract: any;
  expandSidebar: boolean;
  sidebarOpenKeys?: any[];
  setCategory?: any;
  setBuyer?: any;
  setAffiliate?: any;
  setDateRange?: any;
  setProduct?: any;
  setContract?: any;
  setBuyerContract?: any;
  setPrepaymentDate?: any;
  setPrepaymentAmount?: any;
  setPrepaymentRefresh?: any;
  setSidebarExpanded?: any;
  setSidebarOpenKeys?: any;
  config: any;
  setConfig?: any;
  detail?: any;
  setDetail?: any;
  setURL?: any;
  historyURL?: any;
  runReport?: boolean;
  setRun?: any;
  activeNav?: any;
  setActiveNav?: any;
  routingTabs?: any;
  setRoutingTabs?: any;
  activeNavGroup?: any;
  setActiveNavGroup?: any;
  userDisable?: any;
  setUserDisable?: any;
  cloneContractModal?: any;
  setCloneContractModal?: any;
};

// Create React Context to keep track of current auth state
const AdmediaryContext = React.createContext<AdmediaryContextType>({
  start: new Date(),
  end: new Date(),
  PrepaymentDate: new Date(),
  PrepaymentAmount: 0,
  PrepaymentRefresh: false,
  category: 0,
  affiliate: 0,
  product: 0,
  buyer: 0,
  contract: 0,
  buyerContract: 0,
  expandSidebar: false,
  sidebarOpenKeys: ["2", "3", "4", "5", "6", "7", "8", "9"],
  drawerOpened: false,
  drawerSize: "md",
  drawerFull: false,
  config: null,
  detail: "site",
  historyURL: "/",
  runReport: false,
  activeNav: null,
  activeNavGroup: [],
  userDisable: true,
});

/**
 * Helper function for recording data to local storage
 * @param localStorageKey
 */
const useStoredDates = () => {
  const storedValue = sessionStorage.getItem(LOCAL_STORAGE_KEYS.DATES);

  // Default value for today's date
  const initialValue = {
    start: new Date(),
    end: new Date(),
  };

  /**
   * Helper function for parsing storage date range filters
   * @param value
   */
  const parseStoredValue = (value: any) => {
    const parsed = JSON.parse(value);
    parsed.start = parseISO(parsed.start);
    parsed.end = parseISO(parsed.end);
    parsed.PrepaymentDate = parseISO(parsed.PrepaymentDate);
    return parsed;
  };

  // Set state via useState Hook
  const [value, setValue] = React.useState(
    !storedValue ? initialValue : parseStoredValue(storedValue)
  );

  // Save values to local storage once state updates
  React.useEffect(() => {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.DATES, JSON.stringify(value));
  }, [value]);
  return [value, setValue];
};

/**
 * Helper function for recording misc data to local storage
 */
const useStoredValues = (localStorageKey: string, initialValue: any) => {
  const storedValue = localStorage.getItem(localStorageKey);

  /**
   * Helper function for parsing storage date range filters
   * @param value
   */
  // Set state via useState Hook
  const [value, setValue] = React.useState(
    !storedValue ? initialValue : JSON.parse(storedValue)
  );

  // Save values to local storage once state updates
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);
  return [value, setValue];
};

/**
 * Higher Order Component for DataFilteringContext
 * @param props
 * @constructor
 */
const AdmediaryProvider: React.FC<AdmediaryProviderProps> = (props) => {
  const [Admediary, setAdmediary] = useStoredDates();
  const [Category, setCategory] = useStoredValues(
    LOCAL_STORAGE_KEYS.CATEGORY,
    0
  );
  const [Product, setProduct] = useStoredValues(LOCAL_STORAGE_KEYS.PRODUCT, 0);
  const [Buyer, setBuyer] = useStoredValues(LOCAL_STORAGE_KEYS.BUYER, 0);
  const [Affiliate, setAffiliate] = useStoredValues(
    LOCAL_STORAGE_KEYS.AFFILIATE,
    0
  );
  const [Contract, setContract] = useStoredValues(
    LOCAL_STORAGE_KEYS.CONTRACT,
    0
  );
  const [BuyerContract, setBuyerContract] = useStoredValues(
    LOCAL_STORAGE_KEYS.BUYER_CONTRACT,
    0
  );
  const [PrepaymentAmount, setPrepaymentAmount] = useStoredValues(
    LOCAL_STORAGE_KEYS.PREPAYMENT_AMOUNT,
    0
  );
  const [PrepaymentDate, setPrepaymentDate] = useStoredValues(
    LOCAL_STORAGE_KEYS.PREPAYMENT_DATE,
    new Date()
  );
  const [PrepaymentRefresh, setPrepaymentRefresh] = useStoredValues(
    LOCAL_STORAGE_KEYS.PREPAYMENT_REFRESH,
    false
  );
  const [SidebarExpanded, setSidebarExpanded] = useStoredValues(
    LOCAL_STORAGE_KEYS.SIDEBAR_STATE,
    false
  );
  const [SidebarOpenKeys, setSidebarOpenKeys] = useStoredValues(
    LOCAL_STORAGE_KEYS.SIDEBAR_OPEN_KEYS,
    false
  );
  const [DrawerOpened, setDrawerOpened] = React.useState(false);
  const [DrawerContent, setDrawerContent] = React.useState(null);
  const [DrawerSize, setDrawerSize] = React.useState();
  const [DrawerFull, setDrawerFull] = React.useState();
  // const [Config, setConfig] = React.useState({});
  const [Config, setConfig] = React.useState({
    // buyers: [],
    // affiliates: [],
    // clients: [],
    // lead_categories: []
    // contracts: [],
    // products: [],
    // categories: [],
    // contracts_by_buyer: [],
  });
  const [Detail, setDetail] = useStoredValues(
    LOCAL_STORAGE_KEYS.DETAIL,
    "site"
  );
  const [historyURL, setURL] = React.useState("");
  const [runReport, setRun] = React.useState(false);
  const [ActiveNav, setActiveNav] = React.useState();
  const [UserDisable, setUserDisable] = React.useState();
  const [ActiveNavGroup, setActiveNavGroup] = React.useState([]);
  const [CloneContractModal, setCloneContractModal] = React.useState(false);
  const [RoutingTabs, setRoutingTabs] = React.useState({
    affiliates: [
      {
        name: "Affiliates Report",
        route: "/affiliates/tree-report",
        key: "2-1",
      },
      {
        name: "Affiliates",
        route: "/affiliates/common-list",
        key: "2-2",
      },
      {
        name: "E-Sign Affiliate",
        route: "/affiliates/esign-affiliates",
        key: "2-3",
      },
      {
        name: "Cake Offers Report",
        route: "/cake/cake-offers",
        key: "2-4",
      },
    ],
    buyers: [
      {
        name: "Overview",
        route: "/buyers",
        key: "3-1",
      },
      {
        name: "Buyer Report",
        route: "/buyers/tree-report",
        key: "3-2",
      },
      {
        name: "Response Times",
        route: "/buyers/response-times",
        key: "3-3",
      },
      {
        name: "Buyer Contracts",
        route: "/buyers/contracts",
        key: "3-5",
      },
      {
        name: "Buyer Contract Filters",
        route: "/buyers/contract-filters",
        key: "3-6",
      },
      {
        name: "Buyer Contract Performance",
        route: "/buyers/contract-performance",
        key: "3-7",
      },
      {
        name: "Edit Buyers",
        route: "/buyers/routing",
        key: "3-8",
      },
      {
        name: "Buyer Budgets",
        route: "/buyers/buyer-budgets",
        key: "3-9",
      },
      {
        name: "Buyer Daily Caps",
        route: "/buyers/buyer-daily-caps",
        key: "3-10",
      },
    ],
    leads: [
      {
        name: "Lead Detail",
        route: "/leads/detail-report",
        key: "4-1",
      },
      {
        name: "Filtered Leads",
        route: "/leads/filtered",
        key: "4-2",
      },
      {
        name: "Transactions",
        route: "/leads",
        key: "4-3",
      },
      {
        name: "Lead Disposition Lookup",
        route: "/leads/disposition-lookup",
        key: "4-4",
      },
    ],
    flowSite: [
      {
        name: "ROI Report",
        route: "/flow/tree-report",
        key: "5-1",
      },
    ],
    products: [
      {
        name: "Buy/Hold Status Report",
        route: "/products/buy-hold",
        key: "6-1",
      },
      {
        name: "Record search",
        route: "/products/record-search",
        key: "6-2",
      },
      {
        name: "Data Validation",
        route: "/products/data-validation",
        key: "6-3",
      },
      {
        name: "Ping trees",
        route: "/products/ping-trees",
        key: "6-4",
      },
      {
        name: "Campaigns",
        route: "/products/campaigns",
        key: "6-5",
      },
      {
        name: "Performance",
        route: "/products/performance",
        key: "6-6",
      },
      {
        name: "E-Sign Status",
        route: "/products/esign-doc",
        key: "6-7",
      },
      {
        name: "GP Report",
        route: "/products/gp-report",
        key: "6-8",
      },
      {
        name: "Pixel Percentages",
        route: "/products/pixel-percentage",
        key: "6-9",
      },
      {
        name: "Products",
        route: "/products/products",
        key: "6-10",
      },
      {
        name: "Product Categories",
        route: "/products/categories",
        key: "6-11",
      },
    ],
    directPosts: [
      {
        name: "Post Summary",
        route: "/direct-posts/post-summary",
        key: "7-1",
      },
      {
        name: "Post Leads",
        route: "/direct-posts/post-leads",
        key: "7-1",
      },
    ],
    accounting: [
      {
        name: "Prepayments",
        route: "/accounting/prepayments",
        key: "8-1",
      },
    ],
    data: [
      {
        name: "Outgoing Report",
        route: "/data/outgoing-report",
        key: "9-1",
      },
      {
        name: "Incoming Report",
        route: "/data/data-report-incoming",
        key: "9-2",
      },
      {
        name: "Active Sources",
        route: "/data/active-sources",
        key: "9-3",
      },
      {
        name: "SMS Delivery",
        route: "/data/sms-delivery",
        key: "9-4",
      },
      {
        name: "Data Sources",
        route: "/data/data-sources",
        key: "9-5",
      },
      {
        name: "Services",
        route: "/data/services",
        key: "9-6",
      },
      {
        name: "Voice Campaign",
        route: "/data/voice-campaign",
        key: "9-7",
      },
      {
        name: "Voice Activity",
        route: "/data/voice-activity",
        key: "9-8",
      },
    ],
    campaigns: [
      {
        name: "Numbers",
        route: "/campaigns/numbers",
        key: "10-1",
      },
      {
        name: "SMS",
        route: "/campaigns/sms",
        key: "10-2",
      },
    ],
    common: [
      {
        name: "Domains",
        route: "/common/domains",
        key: "11-1",
      },
    ],
    external: [
      {
        name: "Main",
        route: "/reports/external-reports",
        key: "12-1",
      },
      {
        name: "Call Programs",
        route: "/reports/call-programs",
        key: "12-2",
      },
    ],
  });

  /**
   * Helper function for opening drawer
   * @param content
   * @param size TypeAttributes.Size
   */
  const openDrawer = (content: any, size?: TypeAttributes.Size): void => {
    setDrawerOpened(true);
    setDrawerContent(content);
    if (size) {
      setDrawerSize(size);
    }
  };
  /**
   * Helper function for closing drawer
   */
  const closeDrawer = (): void => {
    setDrawerOpened(false);
    setDrawerContent(null);
  };
  /**
   * Helper function to change drawer size
   * @param size string
   */
  const changeSize = (size: string): void => {
    setDrawerSize(size);
  };
  /**
   * Show full drawer
   */
  const maximizeDrawer = (): void => {
    setDrawerFull(true);
  };
  /**
   * Restore drawer
   * @param full boolean
   */
  const restoreDrawer = (full: boolean): void => {
    setDrawerFull(false);
  };

  return (
    <AdmediaryContext.Provider
      value={{
        start: Admediary.start,
        end: Admediary.end,
        PrepaymentDate,
        PrepaymentAmount,
        PrepaymentRefresh,
        affiliate: Affiliate,
        category: Category,
        setCategory,
        product: Product,
        buyer: Buyer,
        contract: Contract,
        buyerContract: BuyerContract,
        expandSidebar: SidebarExpanded,
        sidebarOpenKeys: SidebarOpenKeys,
        drawerOpened: DrawerOpened,
        drawerContent: DrawerContent,
        drawerSize: DrawerSize,
        drawerFull: DrawerFull,
        openDrawer,
        closeDrawer,
        setSidebarExpanded,
        setSidebarOpenKeys,
        setProduct,
        setBuyer,
        setAffiliate,
        setContract,
        setBuyerContract,
        changeSize,
        maximizeDrawer,
        restoreDrawer,
        setDateRange: setAdmediary,
        setPrepaymentDate,
        setPrepaymentAmount,
        setPrepaymentRefresh,
        config: Config,
        setConfig,
        detail: Detail,
        setDetail,
        setURL,
        historyURL: historyURL,
        runReport: runReport,
        setRun,
        activeNav: ActiveNav,
        setActiveNav,
        routingTabs: RoutingTabs,
        setRoutingTabs,
        activeNavGroup: ActiveNavGroup,
        setActiveNavGroup,
        userDisable: UserDisable,
        setUserDisable,
        cloneContractModal: CloneContractModal,
        setCloneContractModal,
      }}
    >
      {props.children}
    </AdmediaryContext.Provider>
  );
};

export { AdmediaryProvider, AdmediaryContext };

import React from "react";
import { getApiUrl, Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  Checkbox,
  CheckboxGroup,
  FormGroup,
  ControlLabel,
  InputGroup,
  FormControl,
  InputNumber,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import ClientSelector from "../ClientSelector";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import FiltersField from "./FiltersField";
import GetPostDataField from "./GetPostDataField";
import SchedulesField from "./SchedulesField";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  service_id: NumberType(),
  client_id: NumberType().isRequired("Service Provider is required."),
  active: ArrayType(),
  feed_delay: NumberType(),
  resubmit_interval: NumberType(),
  data_source_id: NumberType().isRequired("Data Source is required."),
  rev_share_percent: NumberType(),
  ds_client_id: NumberType().isRequired("Data Provider is required."),
  validate_data: ArrayType(),
  service_type: StringType(),
  description: StringType(),
  interface_type: StringType(),
  interface_url: StringType(),
  username: StringType(),
  password: StringType(),
  post_data: StringType(),
  success_response: StringType(),
  duplicate_response: StringType(),
  failure_response: StringType(),
  company_name: StringType(),
  schedule: StringType(),
  rev_share_from: StringType(),
  reporting_tag: StringType(),
  data_provider: StringType(),
  data_source: StringType(),
  ds_company_name: StringType(),
  sold_lead_delay: NumberType(),
});

type ServiceFormType = {
  serviceId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  clonedData?: any;
};

const ServiceForm: React.FC<ServiceFormType> = ({
  serviceId = 0,
  formRef = null,
  parentCallback = () => {},
  clonedData = null,
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(false);
  const initItem = {
    active: [],
    service_id: 0,
    validate_data: [],
    ds_client_id: 0,
    client_id: 0,
    data_source_id: 0,
    post_data: "",
    filters: [],
    time_filters: [],
    filters_output: "",
    time_filters_output: "",
    feed_delay: 0,
    feed_delay_days: 0,
    feed_delay_hours: 0,
    feed_delay_minutes: 0,
    resubmit_interval_days: 0,
    resubmit_interval_hours: 0,
    resubmit_interval_minutes: 0,
    sold_delay_days: 0,
    sold_delay_hours: 0,
    sold_delay_minutes: 0,
    resubmit_interval: 0,
    rev_share_percent: 0,
    cloned_service_id: 0,
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const [formError, setFormError] = React.useState({});

  const clients =
    // Format data
    config.clients
      .map((item: any) => ({
        ...item,
        ...{
          client_id: parseInt(item.client_id),
          affiliate_id: isNaN(parseInt(item.affiliate_id))
            ? 0
            : parseInt(item.affiliate_id),
        },
      }))
      // Remove zero row
      .filter((item: any) => item.client_id !== 0);

  const params = {
    service_id: serviceId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.service_id === 0 && clonedData === null) {
      setData(initItem);
      return;
    } else if (params.service_id === 0 && clonedData !== null) {
      setData(clonedData);
      return;
    }

    setIsLoading(true);
    try {
      const results: any = await Query("data_service", params);

      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  const dataSourcesParams = {
    client_id: formValue.ds_client_id,
    category_id: 0,
    active: 1,
  };

  const [dataSources] = useAdmediaryApi("data_sources", dataSourcesParams);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === 1 ? [1] : [];
    }

    if (!Array.isArray(record.validate_data)) {
      record.validate_data = record.validate_data === 1 ? [1] : [];
    }

    if (!record.data_provider_output || record.data_provider_output === "") {
      record.data_provider_output = `${record.ds_client_id} - ${record.ds_company_name}`;
    }

    if (
      !record.service_provider_output ||
      record.service_provider_output === ""
    ) {
      record.service_provider_output = `${record.client_id} - ${record.company_name}`;
    }

    // Convert to days, hours and minutes
    record.feed_delay_days = Math.floor(record.feed_delay / (3600 * 24));
    record.feed_delay_hours = Math.floor(
      (record.feed_delay % (3600 * 24)) / 3600
    );
    record.feed_delay_minutes = Math.floor((record.feed_delay % 3600) / 60);
    record.resubmit_interval_days = Math.floor(
      record.resubmit_interval / (3600 * 24)
    );
    record.resubmit_interval_hours = Math.floor(
      (record.resubmit_interval % (3600 * 24)) / 3600
    );
    record.resubmit_interval_minutes = Math.floor(
      (record.resubmit_interval % 3600) / 60
    );

    record.sold_delay_days = Math.floor(record.sold_lead_delay / (3600 * 24));
    record.sold_delay_hours = Math.floor(
      (record.sold_lead_delay % (3600 * 24)) / 3600
    );
    record.sold_delay_minutes = Math.floor(
      (record.sold_lead_delay % 3600) / 60
    );

    record.filters_output = convertFiltersToString(record.filters);

    // Convert a list of time filters to string
    record.time_filters_output = convertTimeFiltersToString(
      record.time_filters
    );

    return record;
  };

  /**
   * Convert a list of filters to string which composed of a series of field-value pairs
   * with include option '-'\'+' at the beginning
   *
   * @param {Object[]} filters - Filter list
   *
   * @returns {string} A string like '-key1=value1+key2=value2'
   */
  const convertFiltersToString = function (filters: any) {
    if (!Array.isArray(filters)) {
      return "";
    }

    return (
      filters
        // Don't show filters to remove
        .filter((filter: any) => filter.service_id !== 0)
        .map((filter: any) => {
          if (!Object.keys(filter).length) {
            return "";
          }

          const include = filter.include;
          const isNotInclude =
            include === "0" ||
            include === 0 ||
            (Array.isArray(include) && include.length === 0);

          return (
            (isNotInclude ? "-" : "+") +
            (filter.filter_type + "=") +
            (filter.filter_type === "sql"
              ? filter.evaluator
              : filter.filter_value)
          );
        })
        .join()
    );
  };

  /**
   * Convert a list of filters to string which composed of a series of field-value pairs
   * with include option '-'\'+' at the beginning
   *
   * @param {Object[]} filters - Filter list
   *
   * @returns {string} A string like '-key1=value1+key2=value2'
   */
  const convertTimeFiltersToString = (filters: any) => {
    if (!Array.isArray(filters) || filters.length === 0) {
      return "";
    }

    return (
      filters
        // Don't show filters to remove
        .filter((filter: any) => filter.removing !== true)
        .map(
          (filter: any) =>
            filter.day_of_week + "=" + filter.start_time + "-" + filter.end_time
        )
        .join(";")
    );
  };

  const convertGetPostDataToString = (data: any) => {
    const params = data
      .filter((row: any) => row.name !== "")
      .map((row: any) => {
        const name = row.name;
        const value = row.value;
        const keyIsParameter = /=/.test(name);

        return keyIsParameter ? name : [name, value].join("=");
      });

    return params.length ? `?${params.join("&")}` : "";
  };

  const handleChange = (data: any) => changeFormValue(data);

  const changeFormValue = (data: any) => {
    // Check if the site url list by a current category contains a current tier name value
    /*if (formData.productSites && formData.productSites.length > 0) {
      const siteUrlIsInList = formData.productSites
        .filter((site: any) => site.product_id === data.product_id)
        .some((site: any) => site.site_url === data.site_url);

      if (!siteUrlIsInList) {
        data.site_url = "";
      }
    }*/

    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  const handleAfterDataProviderSelection = (selectedValue: any, rowData: any) =>
    changeFormValue({
      ...formValue,
      ...{
        ds_client_id: selectedValue,
        data_provider_output: `${rowData.client_id} - ${rowData.company_name}`,
      },
    });

  const handleAfterServiceProviderSelection = (
    selectedValue: any,
    rowData: any
  ) =>
    changeFormValue({
      ...formValue,
      ...{
        client_id: selectedValue,
        service_provider_output: `${rowData.client_id} - ${rowData.company_name}`,
      },
    });

  const handleAfterFiltersSave = (filters: any) => {
    filters = filters.map((filter: any) => ({
      ...filter,
      include: Array.isArray(filter.include)
        ? filter.include.length
          ? filter.include[0]
          : 0
        : filter.include,
    }));

    changeFormValue({
      ...formValue,
      ...{
        filters,
        filters_output: convertFiltersToString(filters),
      },
    });
  };

  const handleAfterTimeFiltersSave = (timeFilters: any) => {
    changeFormValue({
      ...formValue,
      ...{
        time_filters: timeFilters,
        time_filters_output: convertTimeFiltersToString(timeFilters),
      },
    });
  };

  const handleAfterGetPostDataSaveClick = (getPostData: any) => {
    changeFormValue({
      ...formValue,
      ...{
        post_data: convertGetPostDataToString(getPostData),
      },
    });
  };

  const transformPostDataToArray = (queryString: string) =>
    queryString
      .replace(/\?/, "")
      .split("&")
      .map((parameter: any) => {
        const [name, value = ""] = parameter.split("=");

        return {
          name,
          value,
        };
      });

  const getNumericArray = (to: number, from = 0) => {
    const numbers = [];

    for (let i = from; i <= to; i++) {
      numbers.push(i);
    }

    return numbers.map((item: number) => ({ value: item, label: item }));
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Active" name="active" accepter={CheckboxGroup}>
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <Field
        label="Validate Data"
        name="validate_data"
        accepter={CheckboxGroup}
        disabled={Admediary.userDisable}
      >
        <Checkbox value={1} disabled={Admediary.userDisable} />
      </Field>
      <ClientSelector
        label="Data Provider"
        modalTitle="Select Data Provider"
        name="data_provider_output"
        selectedValue={formValue.ds_client_id}
        selectedField="client_id"
        onAfterSelection={handleAfterDataProviderSelection}
        gridData={clients}
      />
      <Field
        label="Data Source"
        name="data_source_id"
        accepter={SelectPicker}
        placeholder="Select Data Source"
        data={
          dataSources !== null && Array.isArray(dataSources)
            ? dataSources.map((dataSource: any) => ({
                label: `${dataSource.data_source_id} - ${dataSource.data_source_name}`,
                value: dataSource.data_source_id,
              }))
            : []
        }
        disabled={Admediary.userDisable}
      />
      <ClientSelector
        label="Service Provider"
        modalTitle="Select Service Provider"
        name="service_provider_output"
        selectedValue={formValue.client_id}
        selectedField="client_id"
        onAfterSelection={handleAfterServiceProviderSelection}
        gridData={clients}
      />
      <Field
        label="Description"
        name="description"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Service Type"
        name="service_type"
        accepter={SelectPicker}
        placeholder="Select Service Type"
        data={[{ label: "listmanager", value: "listmanager" }]}
        searchable={false}
        cleanable={false}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Interface Type"
        name="interface_type"
        accepter={SelectPicker}
        placeholder="Select Interface Type"
        data={[
          { label: "GET", value: "get" },
          { label: "POST", value: "post" },
          { label: "FTP", value: "ftp" },
        ]}
        searchable={false}
        cleanable={false}
        disabled={Admediary.userDisable}
      />
      <Field
        label="Interface URL"
        name="interface_url"
        disabled={Admediary.userDisable}
      />
      <GetPostDataField
        label="GET/POST Data"
        name="post_data"
        dataSourceId={formValue.data_source_id}
        gridData={transformPostDataToArray(formValue.post_data)}
        onAfterSave={handleAfterGetPostDataSaveClick}
      />
      <FiltersField
        label="Filters"
        modalTitle="Edit Filters"
        name="filters_output"
        gridData={formValue.filters}
        onAfterSave={handleAfterFiltersSave}
      />
      <SchedulesField
        label=""
        modalTitle="Edit Service Schedules"
        name="time_filters_output"
        serviceId={
          clonedData !== null
            ? formValue.cloned_service_id
            : formValue.service_id
        }
        gridData={formValue.time_filters}
        onAfterSave={handleAfterTimeFiltersSave}
      />
      <Field
        label="Success Response"
        name="success_response"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Duplicate Response"
        name="duplicate_response"
        disabled={Admediary.userDisable}
      />
      <Field
        label="Failure Response"
        name="failure_response"
        disabled={Admediary.userDisable}
      />
      <FormGroup inline>
        <ControlLabel>Delay</ControlLabel>
        <InputGroup style={{ width: 300 }}>
          <FormControl
            style={{ width: 100 }}
            name="feed_delay_days"
            placeholder="Days"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(120)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="feed_delay_hours"
            placeholder="Hours"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(23)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="feed_delay_minutes"
            placeholder="Min"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(59)}
            disabled={Admediary.userDisable}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup inline>
        <ControlLabel>Resubmit</ControlLabel>
        <InputGroup style={{ width: 300 }}>
          <FormControl
            style={{ width: 100 }}
            name="resubmit_interval_days"
            placeholder="Days"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(120)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="resubmit_interval_hours"
            placeholder="Hours"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(23)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="resubmit_interval_minutes"
            placeholder="Min"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(59)}
            disabled={Admediary.userDisable}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup inline>
        <ControlLabel>Sold Delay</ControlLabel>
        <InputGroup style={{ width: 300 }}>
          <FormControl
            style={{ width: 100 }}
            name="sold_delay_days"
            placeholder="Days"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(120)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="sold_delay_hours"
            placeholder="Hours"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(23)}
            disabled={Admediary.userDisable}
          />
          <FormControl
            style={{ width: 100 }}
            name="sold_delay_minutes"
            placeholder="Min"
            placement="autoVerticalEnd"
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={getNumericArray(59)}
            disabled={Admediary.userDisable}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup inline>
        <ControlLabel>Admediary Rev Share</ControlLabel>
        <InputGroup style={{ width: 300 }}>
          <FormControl
            style={{ width: 70 }}
            name="rev_share_percent"
            className="rev_share_percent-field"
            accepter={InputNumber}
            disabled={Admediary.userDisable}
          />
          <InputGroup.Addon>% of</InputGroup.Addon>
          <FormControl
            style={{ width: 175 }}
            name="rev_share_from"
            className="rev_share_from-field"
            placeholder=""
            accepter={SelectPicker}
            searchable={false}
            data={[
              { value: "gross", label: "Gross" },
              { value: "net", label: "Net" },
            ]}
            disabled={Admediary.userDisable}
          />
        </InputGroup>
      </FormGroup>
      <Field
        label="Reporting Tag"
        name="reporting_tag"
        disabled={Admediary.userDisable}
      />
    </Form>
  );
};

export default ServiceForm;

import React from "react";
import { ResponsiveBar as NivoResponsiveBar } from "@nivo/bar";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { Loader } from "rsuite";
import DispositionFilterDetails from "../Drawers/DispositionFilterDetails";

/**
 * Helper function for reducing API response into usable format for barchart
 * @param accum
 * @param row
 */
const disposition_reducer = (accum: any, row: any) => {
  if (!accum[row.response]) {
    accum[row.response] = {
      status: row.status,
      reason: row.response,
      filtered_entries: 0,
      filtered_leads: 0,
      buyers_considered_count: 0,
      contracts_considered_count: 0,
      products: [],
      categories: [],
      buyer_ids: [],
      contract_ids: [],
    };
  }
  accum[row.response].filtered_entries += row.filtered_entries;
  accum[row.response].filtered_leads += row.filtered_leads;
  accum[row.response].products.push(row.product_id);
  accum[row.response].categories.push(row.category_id);
  accum[row.response].buyers_considered_count += row.buyers_considered_count;
  accum[row.response].contracts_considered_count +=
    row.contracts_considered_count;
  accum[row.response].buyer_ids = accum[row.response].buyer_ids.concat(
    row.buyer_ids
  );
  accum[row.response].contract_ids = accum[row.response].contract_ids.concat(
    row.contract_ids
  );
  return accum;
};

interface ICallBack {
  (data: any): void;
}

type TopFilteredLeadReasonsProps = {
  success?: ICallBack;
};

const TopFilteredLeadReasons: React.FC<TopFilteredLeadReasonsProps> = ({
  success = (data: any) => {
    /* do nothing */
  },
}) => {
  const admediary_context = React.useContext(AdmediaryContext);

  // API Parameters
  const params = {
    start_date: admediary_context.start,
    end_date: admediary_context.end,
    category_id: admediary_context.category,
    product_id: admediary_context.product,
  };

  // Fetch lead dispositions
  const [data, isLoading] = useAdmediaryApi("lead_dispositions", params);

  /**
   * Reduce results into structure for bar graph
   * and sort data by most filtered. Graph will use
   * the top 10.
   */
  const reduced =
    isLoading || !Array.isArray(data)
      ? []
      : data.reduce(disposition_reducer, []);
  const aggregated = Object.keys(reduced)
    .map((row) => {
      return reduced[row];
    })
    .sort((a: any, b: any) => {
      return a.filtered_leads > b.filtered_leads ? -1 : 1;
    });

  /**
   * Using useEffect to fire callback after component is done
   */
  React.useEffect(() => {
    const reasons = Object.keys(reduced);
    if (!isLoading && Array.isArray(data) && reasons.length > 0) {
      success({ reasons, aggregated });
    }
  }, [isLoading, data, success, reduced, aggregated]);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  /**
   * Open Drawer with Buyer Overview
   */
  const handleClick = (event: any) => {
    admediary_context.openDrawer(
      <DispositionFilterDetails data={event.data} />
    );
  };

  return aggregated.length === 0 ? (
    <span>No Data</span>
  ) : (
    <NivoResponsiveBar
      data={aggregated.slice(0, 9)}
      keys={["filtered_leads"]}
      indexBy={"reason"}
      margin={{ top: 0, right: 25, bottom: 50, left: 125 }}
      padding={0.2}
      colors={["#0bd6ef", "#8abac4", "#ecae5b", "#8abac4"]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: 0,
        legend: "Leads Filtered",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      enableGridX={true}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -70,
        legendPosition: "middle",
      }}
      enableLabel={false}
      // labelSkipWidth={12}
      // labelSkipHeight={12}
      // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layout="horizontal"
      groupMode="grouped"
      onClick={handleClick}
      // tooltip={tooltip}
    />
  );
};

export default React.memo(TopFilteredLeadReasons);

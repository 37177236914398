import React from "react";
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
  Input,
  InputGroup,
  Modal,
  Table,
} from "rsuite";
import _ from "lodash";
import { AdmediaryContext } from "../@Context/AdmediaryContext";

const { Column, HeaderCell } = Table;

const ClientSelector: React.FC<any> = ({
  name,
  label,
  selectedValue,
  selectedField,
  onAfterSelection,
  error,
  gridData,
  message = null,
  buttonLabel = "Select",
  modalTitle = "Select Clients",
  ...props
}) => {
  const [data, setData] = React.useState(gridData);
  const [currentValue, setCurrentValue] = React.useState(selectedValue);
  const [currentRow, setCurrentRow] = React.useState();
  const [show, setShow] = React.useState(false);
  const Admediary = React.useContext(AdmediaryContext);

  // Set default values
  props.style = {
    ...{ width: 300 },
    ...props.style,
  };

  const handleButtonClick = () => {
    // Clone data
    const items: any = _.cloneDeep(gridData);

    // Find selected item index by value
    const selectedIndex = items.findIndex(
      (item: any) => item[selectedField] === currentValue
    );

    setShow(true);
    setCurrentValue(currentValue);

    if (selectedIndex === -1) {
      setData(items);

      return;
    }

    const selectedItem = items[selectedIndex];

    // Remove selected item from data
    items.splice(selectedIndex, 1);

    // Move selected item in the first place
    items.unshift(selectedItem);

    setCurrentRow(selectedItem);
    setData(items);
  };

  const handleRowClick = (rowData: any) => {
    setCurrentValue(rowData[selectedField]);
    setCurrentRow(rowData);
  };

  const hideModal = () => {
    setShow(false);
    setCurrentValue(selectedValue);
  };

  const cancelModal = () => {
    hideModal();
  };

  const [searchQuery, setSearchQuery] = React.useState();
  const intervalRef: any = React.useRef(null);

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   */
  const debounceSearchTerm = (value: any) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 50);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => {
      return v.toString().toLowerCase().includes(search);
    };

    const searchFields = ["client_id", "affiliate_id", "company_name"];

    // Check if any field includes a search string
    return data.filter((item: any) => {
      return searchFields.some((field: string) => {
        return include(item[field]);
      });
    });
  };

  const handleSelectClick = () => {
    setShow(false);

    if (typeof onAfterSelection === "function") {
      onAfterSelection(currentValue, currentRow);
    }
  };

  const HighlightCell = ({ rowData, dataKey, ...props }: any) => {
    const { Cell } = Table;

    return (
      <Cell
        {...props}
        dataKey={dataKey}
        style={
          currentValue && currentValue === rowData[selectedField]
            ? { background: "#68b3f2" }
            : {}
        }
      >
        {rowData[dataKey]}
      </Cell>
    );
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label}</ControlLabel>
      <InputGroup {...props} className="inner-button-field-group">
        <FormControl name={name} errorMessage={error} disabled />
        <InputGroup.Button
          onClick={handleButtonClick}
          disabled={Admediary.userDisable}
        >
          {buttonLabel}
        </InputGroup.Button>
        {message && <HelpBlock tooltip>{message}</HelpBlock>}
      </InputGroup>

      <Modal size={"sm"} show={show} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup
            inside
            style={{ width: 300, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={debounceSearchTerm}
              size="md"
              value={searchQuery}
            />
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
          </InputGroup>
          {/* TODO Customize it to set a table as parameter or children */}
          <Table
            virtualized
            data={filter(Array.isArray(data) ? data : [], searchQuery)}
            onRowClick={handleRowClick}
            shouldUpdateScroll={false}
            height={500}
          >
            <Column width={100} align="right" fixed>
              <HeaderCell>Client ID</HeaderCell>
              <HighlightCell dataKey="client_id" />
            </Column>
            <Column width={100} align="right">
              <HeaderCell>Affiliate ID</HeaderCell>
              <HighlightCell dataKey="affiliate_id" />
            </Column>
            <Column flexGrow={1}>
              <HeaderCell>Company Name</HeaderCell>
              <HighlightCell dataKey="company_name" />
            </Column>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            onClick={handleSelectClick}
            disabled={Admediary.userDisable}
          >
            Select
          </Button>
          <Button onClick={cancelModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </FormGroup>
  );
};

export default ClientSelector;

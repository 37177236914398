import React, { SyntheticEvent, useRef } from "react";
import {
  Table,
  Notification,
  Icon,
  IconButton,
  Whisper,
  Popover,
  FlexboxGrid,
  Button,
  Divider,
} from "rsuite";
import { CheckCell } from "../Table";

import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import ConfirmModal from "../ConfirmModal";
import BuyerContract from "../Drawers/BuyerContract";

const { Column, HeaderCell, Cell } = Table;

type BuyerContractCloneListProps = {
  data: any;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
  refreshData?: () => void;
};

const BuyerContractCloneList: React.FC<BuyerContractCloneListProps> = ({
  data = [],
  isLoading = false,
  categoryId = 0,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [open, setOpen] = React.useState(false);
  const openConfirmModal = () => setOpen(true);
  const closeConfirmModal = () => setOpen(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [openClone, setOpenClone] = React.useState(false);
  const openCloneModal = () => setOpenClone(true);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleCloneClick = (rowData: any) => {
    setSelectedItem(rowData);

    openConfirmModal();
  };

  const handleCloneContract = () => {
    closeConfirmModal();
    Admediary.setCloneContractModal(false);

    cloneContract(selectedItem.contract_id);
  };

  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const cloneContract = (sourceContractID: number) => {
    Query("buyer_contract_clone", {
      source_contract_id: sourceContractID,
    })
      .then((response) => {
        // Set selected content into drawer
        Admediary.openDrawer(
          <BuyerContract
            contractId={response.data[0].contract_id}
            refreshData={refresh}
          />,
          "lg"
        );
        Notification.success({
          title: "Success",
          description: "Contract has been cloned",
        });
        if (refreshData instanceof Function) {
          refreshData();
        }
      })
      .catch((error) => {
        console.log(error);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: error,
        });
      });
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Contract ID</HeaderCell>
          <Cell dataKey="contract_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Buyer Name</HeaderCell>
          <Cell dataKey="buyer_name" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>Category Name</HeaderCell>
          <Cell dataKey="category_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Contract Name</HeaderCell>
          <Cell dataKey="contract_name" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Routing Tag</HeaderCell>
          <Cell dataKey="routing_tag" />
        </Column>
        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={200} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <ActionCell onCloneClick={handleCloneClick} />
        </Column>
      </Table>

      <ConfirmModal
        title="Clone Contract"
        open={open}
        onClose={closeConfirmModal}
        onYes={handleCloneContract}
      >
        Are you sure you want to clone contract?
      </ConfirmModal>
    </>
  );
};

const ActionCell = ({ rowData, onCloneClick, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <Button onClick={(e) => onCloneClick(rowData, e)}>Clone</Button>
    </Cell>
  );
};

export default BuyerContractCloneList;

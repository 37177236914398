import React, { SyntheticEvent } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  Form,
  Icon,
  Loader,
  Modal,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Table,
  InputGroup,
  Input,
  ButtonToolbar,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import SelectPicker from "rsuite/lib/SelectPicker";
import { Query } from "../../@Utils/AdmediaryApi";
import Config from "../../@Config/Forms";
import axios from "axios";
import _ from "lodash";

const { StringType, ArrayType, NumberType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),
  monitor: ArrayType(),
  category_id: NumberType().isRequired("Required"),
  name: StringType().isRequired("Name is required."),
  description: StringType().isRequired("Description is required."),
});

type EditSourceType = {
  dataSourceId?: number;
  dataSourceKey?: any;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  refreshData?: () => void;
};
const EditSource: React.FC<EditSourceType> = ({
  dataSourceId = 0,
  dataSourceKey = "",
  formRef = null,
  parentCallback = () => {},
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const emptySource = {
    active: [],
    monitor: [],
  };
  formRef = formRef || React.createRef();
  const defaultCategoryValue = 1;
  const defaultRevShareValue = "";
  const [formValue, setFormValue] = React.useState(emptySource);
  const [formError, setFormError] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const categoryList = [
    { value: 1, label: "Personal Loan Leads" },
    { value: 2, label: "Email List management" },
    { value: 3, label: "Solar Coreg" },
    { value: 4, label: "Debt" },
    { value: 5, label: "Legal" },
    { value: 6, label: "Investing" },
    { value: 7, label: "Solar" },
    { value: 8, label: "SMS/IVR" },
  ];

  const revShareList = [
    { value_name: "gross", label_name: "Gross" },
    { value_name: "net", label_name: "Net" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    data_source_id: dataSourceId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.data_source_id === 0) {
      setData({});
      return;
    }
    try {
      setIsLoading(true);
      const results: any = await Query("edit_source", params);
      Forms.setRow(results.data[0].client_id);
      Forms.setCompany(results.data[0].company_name);
      Forms.setDataSourceKey(results.data[0].data_source_key);
      Forms.setActive(results.data[0].active);
      Forms.setMonitor(parseInt(results.data[0].monitor));
      Forms.setCategory(results.data[0].category_id);
      Forms.setRevShare(
        results.data[0].rev_share_from === "gross" ||
          results.data[0].rev_share_from === "net"
          ? results.data[0].rev_share_from
          : ""
      );
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const url =
    "https://admin.admediary.com/docs/dataposting.php?dsid=" +
    dataSourceId.toString() +
    "&dskey=" +
    dataSourceKey;

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (data: any) => {
    setFormValue(data);
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };
  //  const formData: any = Forms.data;
  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(`${api_base}/api/reports.php?op=all_clients,category_list`)
      .then((response) => {})
      .catch((error) => console.log(error));
  }, []);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === "1" ? [1] : [];
    }

    if (!Array.isArray(record.monitor)) {
      record.monitor = record.monitor === "1" ? [1] : [];
    }

    return record;
  };

  const handleSubmit = () => {
    const node = formRef && formRef.current;
    if (node && !node.check()) {
      return;
    }
    const data: any = _.cloneDeep(formValue);
    const options = {
      data_source_id: dataSourceId,
      active: Forms.active ? 1 : 0,
      client_id: Forms.row,
      data_source_name: data.name,
      description: data.description,
      data_source_key: data.data_source_key ? data.data_source_key : "",
      category_id: Forms.category,
      monitor: Forms.monitor ? 1 : 0,
      rev_share_percent: data.rev_share_percent,
      rev_share_from: Forms.revShare ? Forms.revShare : "",
    };
    const operation = dataSourceId === 0 ? "added" : "updated";
    (async () => {
      try {
        await Query("data_source_update", options);
        Notification.success({
          title: "Success",
          description: "Data Source has been " + operation,
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };
  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {dataSourceId === 0 ? "Add Source" : "Edit Source"}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <CheckboxGroup style={{ marginLeft: 80 }} inline name="checkboxList">
            <label>Active/Monitor</label>
            <Checkbox
              onChange={() => {
                Forms.setActive(!Forms.active);
              }}
              name="active"
              checked={Forms.active ? true : false}
              disabled={Admediary.userDisable}
            />
            <Checkbox
              onChange={() => {
                Forms.setMonitor(!Forms.monitor);
              }}
              name="monitor"
              checked={Forms.monitor ? true : false}
              disabled={Admediary.userDisable}
            />
          </CheckboxGroup>

          <Field
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Select Category"
            accepter={SelectPicker}
            name="category_id"
            label="Category"
            defaultValue={defaultCategoryValue}
            value={Forms.category}
            data={categoryList}
            valueKey="value"
            labelKey="label"
            onChange={(v: any) => {
              Forms.setCategory(v);
            }}
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          />
          {/* TODO Replace by the ClientSelector component */}
          <FormField
            label="Client"
            name="client_id"
            width={300}
            value={
              Forms.row === "" || Forms.company === ""
                ? ""
                : Forms.row + "-" + Forms.company
            }
            client={Forms.row}
            dataSourceId={dataSourceId}
            disabled={Admediary.userDisable}
          />
          <Field label="Name" name="name" disabled={Admediary.userDisable} />
          <Field
            label="Description"
            name="description"
            disabled={Admediary.userDisable}
          />
          <FormGroup inline>
            <ControlLabel>Owner Rev</ControlLabel>
            <FormControl
              style={{ width: 100, marginRight: -90 }}
              name="rev_share_percent"
              disabled={Admediary.userDisable}
            />
            <ControlLabel style={{ marginRight: 100 }}>% of</ControlLabel>
            <SelectPicker
              size="sm"
              searchable={false}
              placeholder=""
              accepter={SelectPicker}
              label="Share"
              defaultValue={defaultRevShareValue}
              value={Forms.revShare}
              name="rev_share_from"
              data={revShareList.map((item: any) => ({
                value_name: item.value_name,
                label_name: item.label_name,
              }))}
              valueKey="value_name"
              labelKey="label_name"
              onChange={(v) => {
                Forms.setRevShare(v);
              }}
              readOnly
              style={{ marginLeft: -80, width: 100 }}
              disabled={Admediary.userDisable}
            />
          </FormGroup>
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={Admediary.userDisable}
              >
                Submit
              </Button>
              <Button
                onClick={() => Admediary.closeDrawer()}
                appearance="default"
              >
                Cancel
              </Button>
              <Button color="cyan" appearance="default">
                <a href={url} target="_blank">
                  Docs
                </a>
              </Button>
            </ButtonToolbar>
          </FormGroup>
          {/*<span>{JSON.stringify(formValue)}</span>*/}
        </Form>
      </Drawer.Body>
    </>
  );
};
const FormField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  dataSourceId,
  error,
  client,
  ...props
}) => {
  const { Column, HeaderCell } = Table;
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState();
  const intervalRef: any = React.useRef(null);

  const onChoose = () => {
    if (dataSourceId === 0) {
      const filtered = config.clients.filter(
        (item: any) => parseInt(item.client_id) !== parseInt(client)
      );

      setData(filtered);
      setShow(true);
      setIsLoading(false);
    } else {
      config.clients.shift();
      const selected = config.clients.filter(
        (item: any) => parseInt(item.client_id) === parseInt(client)
      );
      const filtered = config.clients.filter(
        (item: any) => parseInt(item.client_id) !== parseInt(client)
      );
      filtered.unshift(selected[0]);
      setData(filtered);
      setIsLoading(false);
      setShow(true);
    }
  };

  const selectedRow = (rowData: any, event: SyntheticEvent) => {
    Forms.setRow(parseInt(rowData.client_id));
    Forms.setCompany(rowData.company_name);
  };
  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }
    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search.toLowerCase());
    const fields = ["company_name"];
    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 300 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <Button onClick={onChoose} disabled={Admediary.userDisable}>
        Choose
      </Button>
      <HelpBlock>{message}</HelpBlock>
      <Modal size={"sm"} show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Select Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <Table
            virtualized
            height={650}
            loading={isLoading}
            data={filter(Array.isArray(data) ? data : [], searchQuery)}
            onRowClick={selectedRow}
          >
            <Column width={100} align="center" fixed>
              <HeaderCell>Client Id</HeaderCell>
              <CustomCell dataKey="client_id" />
            </Column>
            <Column width={100}>
              <HeaderCell>Affiliate Id</HeaderCell>
              <CustomCell dataKey="affiliate_id" />
            </Column>
            <Column width={400}>
              <HeaderCell>Company Name</HeaderCell>
              <CustomCell dataKey="company_name" />
            </Column>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={() => setShow(false)}>
            Select
          </Button>
          <Button onClick={() => setShow(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </FormGroup>
  );
};

export const CustomCell = ({ rowData, dataKey, ...props }: any) => {
  const { Cell } = Table;
  const Forms = React.useContext(FormContext);
  const rowId = parseInt(Forms.row);
  const clientId = parseInt(rowData.client_id);

  return (
    <Cell
      {...props}
      dataKey={dataKey}
      style={rowId === clientId ? { background: "#68b3f2" } : {}}
    >
      {rowData[dataKey]}
    </Cell>
  );
};

export default EditSource;

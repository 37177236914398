import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Loader, Schema, Form, DatePicker, InputNumber } from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";

const { StringType, NumberType, DateType } = Schema.Types;

const model = Schema.Model({
  schedule_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  cap: NumberType(),
  day_of_week: StringType().isRequired("Select Day of Week please."),
  rate: NumberType(),
  type: StringType().isRequired("Select Type please."),
  start_time: DateType().isRequired("Start Time is required."),
  end_time: DateType().isRequired("End Time is required."),
});

type BuyerContractScheduleFormType = {
  scheduleId?: number;
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractScheduleForm: React.FC<BuyerContractScheduleFormType> = ({
  scheduleId = 0,
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const emptySchedule = {
    schedule_id: 0,
    contract_id: contractId,
    cap: 0,
  };
  const [formValue, setFormValue] = React.useState(emptySchedule);
  const [formError, setFormError] = React.useState({});

  const params = {
    schedule_id: scheduleId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.schedule_id === 0) {
      setData(emptySchedule);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_schedule", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    record.start_time = record.start_time || "00:00";
    record.end_time = record.end_time || "18:00";

    // Transform string 'HH:mm' to Date object to pass into the DatePicker component
    const startDate = new Date();
    let [hours, minutes] = record.start_time.split(":");
    startDate.setHours(hours);
    startDate.setMinutes(minutes);

    record.start_time = startDate;

    const endDate = new Date();
    [hours, minutes] = record.end_time.split(":");
    endDate.setHours(hours);
    endDate.setMinutes(minutes);

    record.end_time = endDate;

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label="Day of Week"
        name="day_of_week"
        placeholder="Select Day of Week"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "mo", label: "Monday" },
          { value: "tu", label: "Tuesday" },
          { value: "we", label: "Wednesday" },
          { value: "th", label: "Thursday" },
          { value: "fr", label: "Friday" },
          { value: "sa", label: "Saturday" },
          { value: "su", label: "Sunday" },
        ]}
      />
      <Field
        label="Type"
        name="type"
        placeholder="Select Type"
        accepter={SelectPicker}
        searchable={false}
        cleanable={false}
        data={[
          { value: "e", label: "Exclusive" },
          { value: "h", label: "Hybrid" },
          { value: "m", label: "Multisell" },
        ]}
      />
      <Field
        label="Start Time"
        name="start_time"
        accepter={DatePicker}
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
      <Field
        label="End Time"
        name="end_time"
        accepter={DatePicker}
        format="HH:mm"
        ranges={[]}
        hideMinutes={(minute: number) => minute % 5 !== 0}
        cleanable={false}
      />
      <Field
        label="Cap"
        name="cap"
        accepter={InputNumber}
        defaultValue={0}
        min={0}
      />
      <Field
        label="Rate"
        name="rate"
        accepter={InputNumber}
        defaultValue={0.0}
        step={0.0001}
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractScheduleForm;

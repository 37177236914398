import React from "react";
import {
  Table,
  FlexboxGrid,
  Button,
  ButtonToolbar,
  IconButton,
  Icon,
  Form,
  Checkbox,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "rsuite";

import { Modal } from "rsuite";
import { FormContext } from "../../@Context/FormContext";
import { Query } from "../../@Utils/AdmediaryApi";
import { CheckCell } from "../Table";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { sort } from "../../@Utils/Sorting";
const { Column, HeaderCell, Cell } = Table;

/**
 * PingTreeCampaignsProps is a separated component to prevent a scroll-to-top bug
 */
type PingTreeCampaignsProps = {
  treeId: any;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const PingTreeCampaigns: React.FC<PingTreeCampaignsProps> = ({
  treeId,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const affiliates = config.affiliates;
  const Forms = React.useContext(FormContext);
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [operation, setOperation] = React.useState();

  const [form, setForm] = React.useState<any>({});
  const [formValue, setformValue] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState([]);

  const rareTypeList = [
    { value: "rev", label: "Rev Share" },
    { value: "cpa", label: "CPA" },
  ];
  /**
   * fetch Data
   *
   */
  const handleRemoveClose = () => setShow(false);
  const handleRemoveShow = (rowData: any) => {
    Forms.setCampaign(rowData.ping_campaign_id);
    setShow(true);
  };
  /**
   * open modal window for adding new Route
   *
   */

  const handleAddRoute = () => {
    setOpen(true);
    setOperation("add");
    Forms.setTree(treeId);
    Forms.setCampaign(0);
    Forms.setActiveCampaign(false);
    Forms.setDescriptionCampaign("");
    Forms.setAffiliate(0);
    Forms.setRare("");
    Forms.setRate("");
  };

  const params = {
    treeId: treeId,
  };
  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("ping_tree_campaign_select", params);
      setCampaigns(results.data);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);
  /**
   * remove Item
   *
   */
  const deleteRouteAction = () => {
    setShow(false);
    const params = {
      ping_campaign_id: Forms.campaign,
    };
    (async () => {
      try {
        setIsLoading(true);
        await Query("ping_tree_campaign_remove", params);
        fetchData();
      } catch (e) {
        setIsLoading(false);
      } finally {
      }
    })();
  };

  /**
   * edit Item
   *
   */
  const editRouteAction = (rowData: any) => {
    setOperation("edit");
    Forms.setCampaign(rowData.ping_campaign_id);
    Forms.setCampaignKey(rowData.campaign_key);
    Forms.setTree(treeId);
    Forms.setActiveCampaign(
      rowData.active === "1" || rowData.active === 1 ? true : false
    );
    Forms.setDescriptionCampaign(rowData.description);
    Forms.setAffiliate(rowData.affiliate_id);
    Forms.setRare(rowData.rate_type);
    Forms.setRate(rowData.rate);
    setOpen(true);
  };

  /**
   * submit
   *
   */
  const submitHandle = () => {
    // @ts-ignore
    if (form && form.current && !form.current.check()) {
      console.log("Error");
      return;
    }

    const data = {
      active: Forms.activeCampaign ? 1 : 0,
      ping_campaign_id: Forms.campaign,
      tree_id: treeId,
      affiliate_id: Forms.affiliate,
      rate_type: Forms.rare,
      rate: Forms.rate,
      description: Forms.descriptionCampaign,
    };

    (async () => {
      try {
        var updateResponse = await Query("ping_tree_campaign_update", data);
        if (
          updateResponse &&
          updateResponse.data &&
          updateResponse.data[0] &&
          updateResponse.data[0]["ping_campaign_id"]
        ) {
          data["ping_campaign_id"] = updateResponse.data[0]["ping_campaign_id"];
          await Query("ping_campaign_rate_update", data);
        }

        fetchData();
        setIsLoading(true);
      } catch (e) {
        console.log("error", e);
      } finally {
        setOpen(false);
      }
    })();
  };
  const handleChange = (data: any) => {
    setformValue(data);
  };
  const setActive = (data: any) => {
    Forms.setActiveCampaign(!data);
  };

  const setAffiliate = (data: any) => {
    Forms.setAffiliate(data);
  };

  const setDescription = (data: any) => {
    Forms.setDescriptionCampaign(data);
  };
  const setRare = (data: any) => {
    Forms.setRare(data);
  };
  const setRate = (data: any) => {
    Forms.setRate(data);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };
  /**
   /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const changeAffiliate = (index: any, key: any, value: any) => {
    const nextData = Object.assign([], campaigns);
    Forms.setAffiliate(value);
    //@ts-ignore
    nextData[index].affiliate_id = value;
    setCampaigns(nextData);
  };
  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={2} style={{ textAlign: "right" }}>
          <div className="modal-container">
            <ButtonToolbar>
              <Button
                onClick={handleAddRoute}
                appearance="primary"
                style={{ marginTop: 15 }}
                disabled={Admediary.userDisable}
              >
                Add Campaign
              </Button>
            </ButtonToolbar>
            <Modal
              show={open}
              onHide={() => {
                setOpen(false);
              }}
            >
              <Modal.Header>
                <Modal.Title>
                  {" "}
                  {operation === "edit" ? "Edit Campaign" : "Add Campaign"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form ref={setForm} onChange={handleChange} layout="horizontal">
                  <FormField
                    label="Active"
                    name="active"
                    accepter={Checkbox}
                    checked={Forms.activeCampaign}
                    onChange={setActive}
                    value={Forms.activeCampaign ? 1 : 0}
                  />
                  <FormField
                    label="Campaign Key"
                    name="description"
                    onChange={setDescription}
                    value={Forms.campaignKey}
                    readOnly={true}
                  />
                  <FormField
                    label="Description"
                    name="description"
                    onChange={setDescription}
                    value={Forms.descriptionCampaign.toString()}
                  />
                  <FormField
                    label="Affiliate"
                    name="affiliate_id"
                    accepter={SelectPicker}
                    placeholder="Select Affiliate"
                    data={affiliates.map((affiliate: any) => ({
                      affiliate_name: affiliate.affiliate_name,
                      affiliate_id: parseInt(affiliate.affiliate_id),
                    }))}
                    onChange={setAffiliate}
                    value={Forms.affiliate}
                    labelKey="affiliate_name"
                    valueKey="affiliate_id"
                    cleanable={false}
                  />
                  <FormField
                    label="Rate Type"
                    name="rare"
                    accepter={SelectPicker}
                    placeholder="Select Rate Type"
                    data={rareTypeList.map((rareType: any) => ({
                      label: rareType.label,
                      value: rareType.value,
                    }))}
                    onChange={setRare}
                    value={Forms.rare}
                    valueKey="value"
                    labelKey="label"
                    searchable={false}
                    cleanable={false}
                    style={{ marginRight: 15 }}
                  />
                  <FormField
                    label="Rate"
                    name="rate"
                    onChange={setRate}
                    value={Forms.rate}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={submitHandle} appearance="primary">
                  Ok
                </Button>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                  appearance="subtle"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={show} onClose={handleRemoveClose}>
              <Modal.Header>
                <Modal.Title>Modal Title</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                You want to remove this Campaign. Are you sure?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={deleteRouteAction}
                  color="red"
                  appearance="primary"
                >
                  Yes
                </Button>
                <Button onClick={handleRemoveClose} appearance="subtle">
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        height={height}
        headerHeight={65}
        loading={isLoading}
        data={Array.isArray(campaigns) ? sortData(campaigns) : []}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="center" width={80} fixed sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column align="center" width={110} sortable resizable>
          <HeaderCell>Campaign ID</HeaderCell>
          <Cell dataKey="ping_campaign_id" />
        </Column>
        <Column align="center" width={125} sortable resizable>
          <HeaderCell>Campaign Key</HeaderCell>
          <Cell dataKey="campaign_key" />
        </Column>
        <Column align={"center"} width={200} sortable resizable>
          <HeaderCell>Affiliate</HeaderCell>
          <AffiliateCell
            affiliates={config.affiliates}
            onChange={changeAffiliate}
            dataKey="affiliate_id"
          />
        </Column>

        <Column width={130} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>

        <Column width={90} sortable resizable>
          <HeaderCell>Rate type</HeaderCell>
          <Cell dataKey="rate_type"></Cell>
        </Column>
        <Column width={90} sortable resizable>
          <HeaderCell>Rate type</HeaderCell>
          <Cell dataKey="rate"></Cell>
        </Column>
        {Admediary.userDisable ? (
          ""
        ) : (
          <Column width={100} sortable resizable>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              deleteRouteAction={deleteRouteAction}
              editRouteAction={editRouteAction}
              handleRemoveShow={handleRemoveShow}
            />
          </Column>
        )}
      </Table>
    </>
  );
};
const FormField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  error,
  ...props
}) => {
  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 150 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <HelpBlock>{message}</HelpBlock>
    </FormGroup>
  );
};
const AffiliateCell = ({
  rowData,
  rowKey,
  dataKey,
  affiliates,
  groupBy,
  onChange,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <SelectPicker
        placeholder="All Affiliates"
        data={affiliates.map((affiliate: any) => ({
          affiliate_name: affiliate.affiliate_name,
          affiliate_id: parseInt(affiliate.affiliate_id),
        }))}
        onChange={(event) => {
          onChange && onChange(rowKey, dataKey, event);
        }}
        labelKey="affiliate_name"
        valueKey="affiliate_id"
        style={{ width: 224 }}
        value={rowData.affiliate_id}
        disabled
      />
    </Cell>
  );
};
const ActionCell = ({
  rowData,
  deleteRouteAction,
  editRouteAction,
  handleRemoveShow,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={(e) => editRouteAction(rowData, e)}
        icon={<Icon icon="edit2" />}
      />
      <IconButton
        appearance="subtle"
        onClick={(e) => handleRemoveShow(rowData, e)}
        icon={<Icon icon="trash" />}
      />
    </Cell>
  );
};
export default PingTreeCampaigns;

import React, { useState } from "react";
import { Checkbox, FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { percent } from "../../@Utils/Format";
import { format } from "date-fns";
import Title from "../Title";
import DataValidationTable from "./DataValidationTable";

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "email_request",
    "email_valid",
    "email_invalid",
    "email_invalid_percent",
    "email_blacklist",
    "email_blacklist_percent",
    "email_total_invalid_percent",
    "phone_request",
    "phone_valid",
    "phone_invalid",
    "phone_invalid_percent",
    "phone_blacklist",
    "phone_blacklist_percent",
    "phone_total_invalid_percent",
    "overlap",
    "overlap_percent",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

const getExportData = (data: any) => {
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        //add parent values
        ...{
          entry_date: item.entry_date || parent.entry_date,
          product_id: item.product_id || parent.product_id,
          source_url: item.source_url || parent.source_url,
          affiliate_id: item.affiliate_id || parent.affiliate_id,
          product_description:
            item.product_description || parent.product_description,
        },
        ...item,
      };
      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      //Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Entry Date": item.entry_date,
          "Product ID": item.product_id,
          "Source URL": item.source_url,
          "Affiliate ID": item.affiliate_id,
          "Product Description": item.product_description,
          SubID: item.sid1,
          "Email Request": item.email_request,
          "Email Valid": item.email_valid,
          "Email Invalid": item.email_invalid,
          "Email Invalid %": percent(parseFloat(item.email_invalid_percent)),
          "Email Blacklist": item.email_blacklist,
          "Email Blacklist %": percent(
            parseFloat(item.email_blacklist_percent)
          ),
          "Total Email Invalid %": percent(
            parseFloat(item.email_total_invalid_percent)
          ),
          "Phone Request": item.phone_request,
          "Phone Valid": item.phone_valid,
          "Phone Invalid": item.phone_invalid,
          "Phone Invalid %": percent(parseFloat(item.phone_invalid_percent)),
          "Phone Blacklist": item.phone_blacklist,
          "Phone Blacklist %": percent(
            parseFloat(item.phone_blacklist_percent)
          ),
          "Total Phone Invalid %": percent(
            parseFloat(item.phone_total_invalid_percent)
          ),
          "Overlap Invalid": item.overlap,
          "Overlap Invalid %": percent(parseFloat(item.overlap_percent)),
        };
      })
  );
};

const addCalculatedValues = (row: any) => {
  row.email_request = isNaN(parseFloat(row.email_request))
    ? 0
    : parseFloat(row.email_request);
  row.phone_request = isNaN(parseFloat(row.phone_request))
    ? 0
    : parseFloat(row.phone_request);
  row.email_invalid = isNaN(parseFloat(row.email_invalid))
    ? 0
    : parseFloat(row.email_invalid);
  row.email_blacklist = isNaN(parseFloat(row.email_blacklist))
    ? 0
    : parseFloat(row.email_blacklist);
  row.phone_valid = isNaN(parseFloat(row.phone_valid))
    ? 0
    : parseFloat(row.phone_valid);
  row.phone_invalid = isNaN(parseFloat(row.phone_invalid))
    ? 0
    : parseFloat(row.phone_invalid);
  row.phone_blacklist = isNaN(parseFloat(row.phone_blacklist))
    ? 0
    : parseFloat(row.phone_blacklist);
  row.overlap = isNaN(parseFloat(row.overlap)) ? 0 : parseFloat(row.overlap);

  return {
    ...row,
    ...{
      email_invalid_percent:
        row.email_request !== 0
          ? (row.email_invalid / row.email_request) * 100
          : 0,
      email_blacklist_percent:
        row.email_request !== 0
          ? (row.email_blacklist / row.email_request) * 100
          : 0,
      email_total_invalid_percent:
        row.email_request !== 0
          ? ((row.email_invalid + row.email_blacklist) / row.email_request) *
            100
          : 0,
      phone_invalid_percent:
        row.phone_request !== 0
          ? (row.phone_invalid / row.phone_request) * 100
          : 0,
      phone_blacklist_percent:
        row.phone_request !== 0
          ? (row.phone_blacklist / row.phone_request) * 100
          : 0,
      phone_total_invalid_percent:
        row.phone_request !== 0
          ? ((row.phone_invalid + row.phone_blacklist) / row.phone_request) *
            100
          : 0,
      overlap_percent:
        row.email_request !== 0 && row.phone_request !== 0
          ? (
              (row.overlap / (row.email_request + row.phone_request)) *
              100
            ).toFixed(3) + "%"
          : 0,
    },
  };
};

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const DataValidationSumarry: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const defaultTypeValue = "date";
  const [checked, setChecked] = useState(false);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [type, setType] = React.useState(defaultTypeValue);
  const selectTypeList = [
    { type: "date", label: "Date" },
    { type: "product", label: "Product" },
  ];
  const handleChecked = (content: any): void => {
    setChecked(!checked);
  };

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    add_subid: checked ? 1 : 0,
    viewType: type,
    type: "read",
  };

  // const [data, isLoading] = useAdmediaryApi("data_validation", params);
  const [data, isLoading] = useAdmediaryApiManual("data_validation", params);
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="Data Validation" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            defaultValue={defaultTypeValue}
            value={type}
            data={selectTypeList}
            valueKey="type"
            labelKey="label"
            onChange={(value) => {
              setType(value);
            }}
            style={{ marginLeft: "2%" }}
          ></SelectPicker>
          <Checkbox onChange={handleChecked} style={{ float: "left" }}>
            SubID
          </Checkbox>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `data_validation_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <DataValidationTable
        data={treeData}
        checked={checked}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        type={type}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default DataValidationSumarry;

import React, { SyntheticEvent } from "react";
import { Table } from "rsuite";
import { EditRemoveActionCell } from "../Table";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import CommonAffiliate from "./../Drawers/CommonAffiliate";

const { Column, HeaderCell, Cell } = Table;

/**
 * TreeTable is a separated component to prevent a scroll-to-top bug
 */
type CommonAffiliatesListType = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const CommonAffiliatesList: React.FC<CommonAffiliatesListType> = ({
  data = [],
  refreshData,
  isLoading = false,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          entry_date: item.entry_date
            ? format(item.entry_date, "MM-dd-Y HH:mm")
            : "",
          transaction_date: item.transaction_date
            ? format(item.transaction_date, "MM-dd-Y HH:mm")
            : "",
        },
      };
    });
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <CommonAffiliate
        affiliateId={rowData.affiliate_id}
        refreshData={refreshData}
      />,
      "lg"
    );
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  return (
    <>
      <Table
        virtualized
        height={height}
        // autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Affiliate ID</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Affiliate Name</HeaderCell>
          <Cell dataKey="affiliate_name" />
        </Column>
        <Column width={250} sortable resizable>
          <HeaderCell>Address</HeaderCell>
          <Cell dataKey="address" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>City</HeaderCell>
          <Cell dataKey="city" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>State</HeaderCell>
          <Cell dataKey="state" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Zip Code</HeaderCell>
          <Cell dataKey="zip_code" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>TIN</HeaderCell>
          <Cell dataKey="tax_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Tax Class</HeaderCell>
          <Cell dataKey="tax_class" />
        </Column>
        {admediaryContext.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed="right">
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell editHandler={handleEditActionClick} />
          </Column>
        )}
      </Table>
    </>
  );
};

export default CommonAffiliatesList;

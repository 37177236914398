import React from "react";
import { getApiUrl, Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  Checkbox,
  CheckboxGroup,
  InputNumber,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";

const { StringType, NumberType, ArrayType } = Schema.Types;

const model = Schema.Model({
  campaign_id: NumberType(),
  description: StringType().isRequired("Description is required."),
  product_id: NumberType().isRequired("Product ID is required."),
  site_id: NumberType().isRequired("Site is required."),
  relative_url: StringType().isRequired("Relative URL is required."),
  pixel_url: StringType().isRequired("Pixel URL is required."),
  criteria_group_id: NumberType().isRequired("Pixel Filter is required."),
  server_side: ArrayType(),
  campaign_rate: NumberType().isRequired("Rate is required."),
  rev_share: ArrayType(),
  is_esign_campaign: ArrayType(),
});

type CampaignFormType = {
  campaignId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const CampaignForm: React.FC<CampaignFormType> = ({
  campaignId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(false);
  const initItem = {
    product_id: 0,
    server_side: [],
    campaign_rate: 0.0,
    rev_share: [],
    is_esign_campaign: [],
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    campaign_id: campaignId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.campaign_id === 0) {
      setData({});
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("campaign", params);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const apiUrl = getApiUrl();
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(`${apiUrl}?op=product_sites,pixel_criteria_groups,products`)
      .then((response) => {
        const productSites = response.data.response_data.product_sites.data
          .map((site: any) => ({
            ...site,
            ...{
              site_id: parseInt(site.site_id),
              product_id: parseInt(site.product_id),
            },
          }))
          .sort((siteA: any, siteB: any) =>
            siteA.site_url > siteB.site_url ? 1 : -1
          );

        const pixelCriteriaGroups = response.data.response_data.pixel_criteria_groups.data
          .map((group: any) => ({
            ...group,
            ...{
              criteria_group_id: parseInt(group.criteria_group_id),
              product_id: parseInt(group.product_id),
            },
          }))
          .sort((groupA: any, groupB: any) =>
            groupA.description > groupB.description ? 1 : -1
          );

        const products = response.data.response_data.products.data.map(
          (product: any) => ({
            ...product,
            ...{
              // criteria_group_id: parseInt(product.criteria_group_id),
              // product_id: parseInt(product.product_id),
            },
          })
        );

        console.log("pixelCriteriaGroups: ", pixelCriteriaGroups);
        Forms.setData({
          ...Forms.data,
          ...{
            products,
            productSites,
            pixelCriteriaGroups,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    if (record.rev_share === 1) {
      record.campaign_rate = Number(record.campaign_rate) * 100;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.server_side)) {
      record.server_side = record.server_side === 1 ? [1] : [];
    }

    if (!Array.isArray(record.rev_share)) {
      record.rev_share = record.rev_share === 1 ? [1] : [];
    }

    if (!Array.isArray(record.is_esign_campaign)) {
      record.is_esign_campaign = record.is_esign_campaign === 1 ? [1] : [];
    }

    return record;
  };

  const handleChange = (data: any) => {
    // Check if the site url list by a current category contains a current tier name value
    if (formData.productSites && formData.productSites.length > 0) {
      const siteUrlIsInList = formData.productSites
        .filter((site: any) => site.product_id === data.product_id)
        .some((site: any) => site.site_url === data.site_url);

      if (!siteUrlIsInList) {
        data.site_url = "";
      }
    }

    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Description" name="description" />
      <Field
        label="Product"
        name="product_id"
        accepter={SelectPicker}
        placeholder="Select Product"
        data={config.products.map((product: any) => ({
          product_description:
            parseInt(product.product_id) + " - " + product.product_description,
          product_id: parseInt(product.product_id),
        }))}
        labelKey="product_description"
        valueKey="product_id"
        cleanable={false}
      />
      <Field
        label="Site URL"
        name="site_id"
        accepter={SelectPicker}
        placeholder="Select Site URL"
        data={formData.productSites.filter(
          (site: any) => site.product_id === formValue.product_id
        )}
        labelKey="site_url"
        valueKey="site_id"
      />
      <Field label="Relative URL" name="relative_url" />
      <Field label="Pixel URL" name="pixel_url" />
      <Field
        label="Pixel Filter"
        name="criteria_group_id"
        accepter={SelectPicker}
        placeholder="Select Pixel Filter"
        data={formData.pixelCriteriaGroups}
        labelKey="description"
        valueKey="criteria_group_id"
      />
      <Field label="Server Side" name="server_side" accepter={CheckboxGroup}>
        <Checkbox value={1} />
      </Field>
      <Field
        label="Rate"
        name="campaign_rate"
        accepter={InputNumber}
        defaultValue={0.0}
        step={0.01}
      />
      <Field label="Rev Share" name="rev_share" accepter={CheckboxGroup}>
        <Checkbox value={1} />
      </Field>
      <Field
        label="Is E-Sign Campaign"
        name="is_esign_campaign"
        accepter={CheckboxGroup}
      >
        <Checkbox value={1} />
      </Field>
    </Form>
  );
};

export default CampaignForm;

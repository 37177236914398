import React from "react";
import { Col, Grid, Row } from "rsuite";
import StatCard from "./StatCard";

type PrepaymentStatCardsProps = {
  leads_sold: number | null;
  prepayment_balance: number | null;
  prepayment_amount: number | null;
  revenue: number | null;
};

const PrepaymentStatCards: React.FC<PrepaymentStatCardsProps> = (
  props: any
) => {
  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard
            label="Prepayment Balance"
            type="currency"
            value={props.prepayment_balance}
          />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard
            label="Prepayment Amount"
            type="currency"
            value={props.prepayment_amount}
          />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Leads Sold" type="number" value={props.leads_sold} />
        </Col>
        <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
          <StatCard label="Revenue" type="currency" value={props.revenue} />
        </Col>
      </Row>
    </Grid>
  );
};

export default PrepaymentStatCards;

import React, { SyntheticEvent } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  Form,
  Icon,
  Loader,
  Modal,
  Schema,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Table,
  InputGroup,
  Input,
  ButtonToolbar,
  Notification,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { FormContext } from "../../@Context/FormContext";
import Field from "../Field";
import SelectPicker from "rsuite/lib/SelectPicker";
import { Query } from "../../@Utils/AdmediaryApi";
import Config from "../../@Config/Forms";
import axios from "axios";
import _ from "lodash";

const { StringType, ArrayType, NumberType } = Schema.Types;

const model = Schema.Model({
  active: ArrayType(),

  description: StringType().isRequired("Description is required."),
});

type EditVoiceCampaignType = {
  campaignId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  refreshData?: () => void;
};
const EditVoiceCampaign: React.FC<EditVoiceCampaignType> = ({
  campaignId = 0,
  formRef = null,
  parentCallback = () => {},
  refreshData,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const emptySource = {
    active: [],
  };
  formRef = formRef || React.createRef();
  const [formValue, setFormValue] = React.useState(emptySource);
  const [formError, setFormError] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const params = {
    // start_date: Admediary.start,
    // end_date: Admediary.end,
    campaign_id: campaignId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.campaign_id === 0) {
      setData({});
      return;
    }
    try {
      setIsLoading(true);
      const results: any = await Query("voice_campaign_select", params);
      Forms.setActive(results.data[0].active);
      setData(results.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (data: any) => {
    setFormValue(data);
    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };
  const formData: any = Forms.data;
  const api_base = !Config.USE_PROXY ? Config.API_BASE : "";
  const API = axios.create({});
  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    API.get(`${api_base}/api/reports.php?op=phone_numbers`)
      .then((response) => {
        console.log(response);
        const phoneNumbers = response.data.response_data.data.map(
          (phoneNumber: any) => ({
            ...phoneNumber,
            ...{},
          })
        );
        console.log(phoneNumbers);
        Forms.setData({
          ...Forms.data,
          ...{
            phoneNumbers,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);

  console.log(formData);

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    // Checkbox value should be an array
    if (!Array.isArray(record.active)) {
      record.active = record.active === "1" ? [1] : [];
    }
    return record;
  };

  // const handleSubmit = () => {
  //   const node = formRef && formRef.current;
  //   if (node && !node.check()) {
  //     return;
  //   }
  //   const data: any = _.cloneDeep(formValue);
  //   const options = {
  //     campaign_id: campaignId,
  //     active: Forms.active ? 1 : 0,
  //     client_id: Forms.row,
  //     data_source_name: data.name,
  //     description: data.description,
  //     data_source_key: data.data_source_key ? data.data_source_key : "",
  //     category_id: Forms.category,
  //     monitor: Forms.monitor ? 1 : 0,
  //     rev_share_percent: data.rev_share_percent,
  //     rev_share_from: Forms.revShare ? Forms.revShare : "",
  //   };
  //   const operation = campaignId === 0 ? "added" : "updated";
  //   (async () => {
  //     try {
  //       await Query("data_source_update", options);
  //       Notification.success({
  //         title: "Success",
  //         description: "Voice Campaign has been " + operation,
  //       });

  //       Admediary.closeDrawer();

  //       if (refreshData instanceof Function) {
  //         refreshData();
  //       }
  //     } catch (e) {
  //       console.log("error", e);
  //       Notification.error({
  //         title: "Error",
  //         duration: 60000,
  //         description: e,
  //       });
  //     } finally {
  //     }
  //   })();
  // };

  const handleSubmit = () => {
    const node = formRef && formRef.current;
    if (node && !node.check()) {
      return;
    }

    const data: any = _.cloneDeep(formValue);
    // if (Array.isArray(data.active)) {
    //   data.active = data.active.length ? data.active[0] : 0;
    // }
    data.active = Forms.active ? 1 : 0;
    const operation = campaignId === 0 ? "added" : "updated";
    (async () => {
      console.log(data);
      try {
        await Query("voice_campaign_update", data);
        Notification.success({
          title: "Success",
          description: "Voice Campaign has been " + operation,
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };
  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }
  console.log(formData);
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {campaignId === 0 ? "Add Source" : "Edit Source"}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          {/* <CheckboxGroup  inline name="checkboxList">
            <label>Active</label>
            <Checkbox
              onChange={() => {
                Forms.setActive(!Forms.active);
              }}
              name="active"
              checked={Forms.active ? true : false}
              disabled={Admediary.userDisable}
            />
          </CheckboxGroup> */}

          <Field label="Active" name="active" accepter={CheckboxGroup}>
            <Checkbox
              // value={1}
              onChange={() => {
                Forms.setActive(!Forms.active);
              }}
              checked={Forms.active ? true : false}
              disabled={Admediary.userDisable}
            />
          </Field>

          <Field
            label="Description"
            name="description"
            disabled={Admediary.userDisable}
          />

          <Field
            label="From"
            name="phone_id"
            accepter={SelectPicker}
            data={
              formData.phoneNumbers &&
              formData.phoneNumbers.map((phoneNumber: any) => ({
                label: phoneNumber.phone_number,
                value: phoneNumber.phone_id,
                // voice_from:phoneNumber.phone_number,
              }))
            }
            labelKey="label"
            valueKey="value"
            disabled={Admediary.userDisable}
            // defaultValue="voice_from"
          />

          <Field
            label="Transfer To"
            name="transfer_to"
            disabled={Admediary.userDisable}
          />

          {/* <Field
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Select Category"
            accepter={SelectPicker}
            name="category_id"
            label="Category"
            defaultValue={defaultCategoryValue}
            value={Forms.category}
            data={categoryList}
            valueKey="value"
            labelKey="label"
            onChange={(v: any) => {
              Forms.setCategory(v);
            }}
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          /> */}
          {/* TODO Replace by the ClientSelector component */}
          {/* <FormField
            label="Client"
            name="client_id"
            width={300}
            value={
              Forms.row === "" || Forms.company === ""
                ? ""
                : Forms.row + "-" + Forms.company
            }
            client={Forms.row}
            dataSourceId={dataSourceId}
            disabled={Admediary.userDisable}
          /> */}
          {/* <Field label="Name" name="name" disabled={Admediary.userDisable} />
          
          <FormGroup inline>
            <ControlLabel>Owner Rev</ControlLabel>
            <FormControl
              style={{ width: 100, marginRight: -90 }}
              name="rev_share_percent"
              disabled={Admediary.userDisable}
            />
            <ControlLabel style={{ marginRight: 100 }}>% of</ControlLabel>
            <SelectPicker
              size="sm"
              searchable={false}
              placeholder=""
              accepter={SelectPicker}
              label="Share"
              defaultValue={defaultRevShareValue}
              value={Forms.revShare}
              name="rev_share_from"
              data={revShareList.map((item: any) => ({
                value_name: item.value_name,
                label_name: item.label_name,
              }))}
              valueKey="value_name"
              labelKey="label_name"
              onChange={(v) => {
                Forms.setRevShare(v);
              }}
              readOnly
              style={{ marginLeft: -80, width: 100 }}
              disabled={Admediary.userDisable}
            />
          </FormGroup> */}
          <FormGroup>
            <ButtonToolbar>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={Admediary.userDisable}
              >
                Submit
              </Button>
              <Button
                onClick={() => Admediary.closeDrawer()}
                appearance="default"
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </FormGroup>
          {/*<span>{JSON.stringify(formValue)}</span>*/}
        </Form>
      </Drawer.Body>
    </>
  );
};
const FormField: React.FC<any> = ({
  name,
  message,
  label,
  accepter,
  dataSourceId,
  error,
  client,
  ...props
}) => {
  const { Column, HeaderCell } = Table;
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const { config } = Admediary;
  const [isLoading, setIsLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState();
  const intervalRef: any = React.useRef(null);

  const onChoose = () => {
    if (dataSourceId === 0) {
      const filtered = config.clients.filter(
        (item: any) => parseInt(item.client_id) !== parseInt(client)
      );

      setData(filtered);
      setShow(true);
      setIsLoading(false);
    } else {
      config.clients.shift();
      const selected = config.clients.filter(
        (item: any) => parseInt(item.client_id) === parseInt(client)
      );
      const filtered = config.clients.filter(
        (item: any) => parseInt(item.client_id) !== parseInt(client)
      );
      filtered.unshift(selected[0]);
      setData(filtered);
      setIsLoading(false);
      setShow(true);
    }
  };

  const selectedRow = (rowData: any, event: SyntheticEvent) => {
    Forms.setRow(parseInt(rowData.client_id));
    Forms.setCompany(rowData.company_name);
  };
  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }
    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search.toLowerCase());
    const fields = ["company_name"];
    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  return (
    <FormGroup className={error ? "has-error" : ""}>
      <ControlLabel style={{ display: "block" }}>{label} </ControlLabel>
      <FormControl
        style={{ width: 300 }}
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      <Button onClick={onChoose} disabled={Admediary.userDisable}>
        Choose
      </Button>
      <HelpBlock>{message}</HelpBlock>
      <Modal size={"sm"} show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Select Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup
            style={{ width: 125, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="sm"
            />
          </InputGroup>
          <Table
            virtualized
            height={650}
            loading={isLoading}
            data={filter(Array.isArray(data) ? data : [], searchQuery)}
            onRowClick={selectedRow}
          >
            <Column width={100} align="center" fixed>
              <HeaderCell>Client Id</HeaderCell>
              <CustomCell dataKey="client_id" />
            </Column>
            <Column width={100}>
              <HeaderCell>Affiliate Id</HeaderCell>
              <CustomCell dataKey="affiliate_id" />
            </Column>
            <Column width={400}>
              <HeaderCell>Company Name</HeaderCell>
              <CustomCell dataKey="company_name" />
            </Column>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={() => setShow(false)}>
            Select
          </Button>
          <Button onClick={() => setShow(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </FormGroup>
  );
};

export const CustomCell = ({ rowData, dataKey, ...props }: any) => {
  const { Cell } = Table;
  const Forms = React.useContext(FormContext);
  const rowId = parseInt(Forms.row);
  const clientId = parseInt(rowData.client_id);

  return (
    <Cell
      {...props}
      dataKey={dataKey}
      style={rowId === clientId ? { background: "#68b3f2" } : {}}
    >
      {rowData[dataKey]}
    </Cell>
  );
};

export default EditVoiceCampaign;

import React from "react";
import { Drawer, Icon, Loader } from "rsuite";
import ResponseTimeGraph from "../Visualization/ResponseTimeGraph";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { format } from "date-fns";
import moment from "moment";

type ResponseTimeChart = {
  contractId: number;
  contractName?: string;
  columnDate?: Date;
};

const ResponseTimeChart: React.FC<ResponseTimeChart> = ({
  contractId = 0,
  contractName = "",
  columnDate,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const startDate = columnDate || Admediary.start;
  const endDate = columnDate || Admediary.end;

  const params = {
    start_date: startDate,
    end_date: endDate,
    contract_id: contractId ? contractId : 0,
  };

  const [data, isLoading] = useAdmediaryApi("response_time_chart", params);

  const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
  const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
  const drawerTitle = `${contractName} [${formattedStartDate}-${formattedEndDate}]`;

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {`Response Times - ${drawerTitle}`}
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ paddingBottom: 5 }}>
        {isLoading ? (
          <Loader size="md" speed="slow" vertical center />
        ) : (
          <ResponseTimeGraph
            data={[
              {
                id: "Post Time",
                data: Array.isArray(data)
                  ? data
                      .filter((item: any) => item.post_time > 0)
                      .map((item: any) => ({
                        x: format(item.entry_date, "y-MM-dd HH:mm:ss"),
                        y: item.post_time,
                      }))
                  : [],
              },
            ]}
          />
        )}
      </Drawer.Body>
    </>
  );
};

export default ResponseTimeChart;

import React from "react";
import { getApiUrl, Query } from "../../@Utils/AdmediaryApi";
import {
  Loader,
  Schema,
  Form,
  Checkbox,
  CheckboxGroup,
  InputNumber,
  Drawer,
  Button,
  Icon,
  Notification,
  ButtonToolbar,
  Nav,
} from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import SelectPicker from "rsuite/lib/SelectPicker";
import axios from "axios";
import { FormContext } from "../../@Context/FormContext";
import { FormInstance } from "rsuite/lib/Form/Form";
import Field from "../Field";
import { ProductsType } from "../Products/Products";
import ProductSourceList from "./ProductSources/ProductSourceList";
import ProductAffiliateCapsList from "../Products/ProductAffiliateCapsList";
import _ from "lodash";

const { StringType, NumberType, BooleanType, ArrayType } = Schema.Types;

const model = Schema.Model({
  category_id: NumberType(),
  category_name: StringType(),
  product_domain: StringType().isRequired("Domain is required"),
  product_description: StringType().isRequired("Description is required"),
  product_url: StringType().isRequired("Url is required"),
  reconcile_cake_conversions: ArrayType(),
  data_source_id: NumberType(),
  data_source_key: StringType(),
  data_source_name: StringType(),
  data_manage_all_records: NumberType().isRequired(
    "Record to Route is required."
  ),
  email_days: NumberType(),
  address_days: NumberType(),
  phone_days: NumberType(),
  ip_days: NumberType(),
  affiliate_caps_filter: ArrayType(),
});

type EditProductsProps = {
  product?: ProductsType;
  callbackOnSave: Function;
};

const EditProduct: React.FC<EditProductsProps> = (props) => {
  const formRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);
  const { config } = Admediary;
  const callbackOnSave = props.callbackOnSave;
  const [activeKey, setActiveKey] = React.useState("main");
  const product_id = props.product?.product_id;

  const initItem = props.product ?? {
    product_id: undefined,
    category_id: 0,
    category_name: "",
    product_domain: "",
    product_description: "",
    product_url: "",
    reconcile_cake_conversions: [],
    data_source_id: 0,
    data_source_key: "",
    data_source_name: "",
    data_manage_all_records: 0,
    email_days: 0,
    address_days: 0,
    phone_days: 0,
    ip_days: 0,
    default_allow: 1,
    affiliate_caps_filter: [],
  };

  const [formError, setFormError] = React.useState({});
  const Forms = React.useContext(FormContext);
  const formData: any = Forms.data;
  const apiUrl = getApiUrl();
  const API = axios.create({});

  const prepareFormData = (record: ProductsType) => {
    if (!Array.isArray(record.reconcile_cake_conversions)) {
      record.reconcile_cake_conversions =
        record.reconcile_cake_conversions === 1 ? [1] : [];
    }

    if (!Array.isArray(record.affiliate_caps_filter)) {
      record.affiliate_caps_filter =
        record.affiliate_caps_filter === 1 ? [1] : [];
    }

    return record;
  };

  const [formValue, setFormValue] = React.useState<ProductsType>(
    prepareFormData(initItem)
  );

  /**
   * Intercept responses to catch auth issues
   */
  API.interceptors.request.use((config) => {
    const accessToken: string | null = localStorage.getItem("auth.id_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // Getting all data for drop downs
  React.useEffect(() => {
    const client_id = 0;
    const category_id = 0;
    const active = 1;
    API.get(
      `${apiUrl}?op=data_sources&client_id=${client_id}&category_id=${category_id}&active=${active}`
    )
      .then((response) => {
        const products = response.data.response_data.data.map(
          (product: any) => ({
            ...product,
            ...{},
          })
        );

        Forms.setData({
          ...Forms.data,
          ...{
            products,
          },
        });
      })
      .catch((error) => console.log(error));
  }, []);
  const handleChange = (data: any) => {
    setFormValue(data);
  };

  const handleSave = () => {
    const node = formRef && formRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    data.default_allow = Forms.defaultAllow;

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.reconcile_cake_conversions)) {
      data.reconcile_cake_conversions = data.reconcile_cake_conversions.length
        ? data.reconcile_cake_conversions[0]
        : 0;
    }

    if (Array.isArray(data.affiliate_caps_filter)) {
      data.affiliate_caps_filter = data.affiliate_caps_filter.length
        ? data.affiliate_caps_filter[0]
        : 0;
    }
    data.address_days = data.address_days ? data.address_days : 0;
    data.ip_days = data.ip_days ? data.ip_days : 0;

    (async () => {
      try {
        const postProductResult: any = await Query("product_update", data);

        Notification.success({
          title: "Success",
          description: "Products has been updated",
        });

        Admediary.closeDrawer();

        callbackOnSave(data);
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: <>({e})</>,
        });
      } finally {
      }
    })();
  };

  // console.log('Forms.defaultAllow', Forms.defaultAllow)

  const tabs = [
    {
      key: "main",
      title: "Product",
      content: (
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <Field
            label="Category ID"
            name="category_id"
            accepter={SelectPicker}
            data={config.categories}
            labelKey="category_name"
            valueKey="category_id"
            cleanable={false}
            disabled={Admediary.userDisable}
          />
          <Field
            label="Product Domain"
            name="product_domain"
            disabled={Admediary.userDisable}
          />
          <Field
            label="Product Description"
            name="product_description"
            disabled={Admediary.userDisable}
          />
          <Field
            label="Product URL"
            name="product_url"
            disabled={Admediary.userDisable}
          />
          <Field
            label="Reconcile Cake Conversions"
            name="reconcile_cake_conversions"
            accepter={CheckboxGroup}
          >
            <Checkbox value={1} disabled={Admediary.userDisable} />
          </Field>
          <Field
            label="Route To"
            name="data_source_id"
            accepter={SelectPicker}
            placeholder="Route To"
            data={formData.products.map((product: any) => ({
              data_source_name:
                "(" +
                parseInt(product.data_source_id) +
                ") " +
                product.data_source_name,
              data_source_id: parseInt(product.data_source_id),
            }))}
            labelKey="data_source_name"
            valueKey="data_source_id"
            cleanable={false}
            disabled={Admediary.userDisable}
          />
          <Field
            label="Records To Route"
            name="data_manage_all_records"
            accepter={SelectPicker}
            labelKey="label"
            valueKey="value"
            cleanable={false}
            data={[
              { label: "Purchased", value: "0" },
              { label: "All Submitted", value: "1" },
            ]}
            disabled={Admediary.userDisable}
          />

          <Field
            label="Email Unique"
            name="email_days"
            labelKey="email_days"
            valueKey="email_days"
            cleanable={false}
            disabled={Admediary.userDisable}
          />

          <Field
            label="Address Unique"
            name="address_days"
            labelKey="address_days"
            valueKey="address_days"
            cleanable={false}
            disabled={Admediary.userDisable}
          />

          <Field
            label="Phone Unique"
            name="phone_days"
            labelKey="phone_days"
            valueKey="phone_days"
            cleanable={false}
            disabled={Admediary.userDisable}
          />

          <Field
            label="IP Unique"
            name="ip_days"
            labelKey="ip_days"
            valueKey="ip_days"
            cleanable={false}
            disabled={Admediary.userDisable}
          />
          <Field
            label="Affiliates Cap Filter:"
            name="affiliate_caps_filter"
            accepter={CheckboxGroup}
          >
            <Checkbox value={1} disabled={Admediary.userDisable} />
          </Field>
        </Form>
      ),
    },
  ];

  if (product_id && product_id > 0) {
    tabs.push(
      ...[
        {
          key: "sources",
          title: "Sources",
          content: (
            <ProductSourceList
              product_id={product_id}
              product_description={props.product?.product_description}
            />
          ),
        },
        {
          key: "affiliate_caps",
          title: "Affiliate Caps",
          content: <ProductAffiliateCapsList productId={product_id} />,
        },
      ]
    );
  }

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {typeof formValue.product_id === "undefined" ? <>Add </> : <>Edit </>}
          Product
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
        <Nav
          activeKey={activeKey}
          appearance="tabs"
          onSelect={(eventKey) => {
            setActiveKey(eventKey);
          }}
          style={{ marginTop: 10 }}
        >
          {tabs.map((tab: any, index: number) => (
            <Nav.Item eventKey={tab.key} key={index}>
              {tab.title}
            </Nav.Item>
          ))}
        </Nav>
      </Drawer.Header>
      <Drawer.Body>
        {tabs.map((tab: any, index: number) => (
          <div
            key={index}
            id={"main_content_" + tab.key}
            style={{
              display: activeKey === tab.key ? "block" : "none",
              minHeight: "100px", // put the loading icon down off the tabs
            }}
          >
            {tab.content}
          </div>
        ))}
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            disabled={Admediary.userDisable}
          >
            Save
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default EditProduct;

import React, { useState } from "react";
import { FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import { format } from "date-fns";
import Title from "../Title";
import PerfomanceTable from "./PerformanceTable";

const getSummary = (data: any) => {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "impressions",
    "unique_impressions",
    "submits",
    "submitPercent",
    "conversions",
    "conversionPercent",
    "paid_amount",
    "cpl",
    "cpi",
    "revenue",
    "rpi",
    "pnl",
    "pm",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
};

const getExportData = (data: any) => {
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      const current = {
        //add parent values
        ...{
          product_id: item.product_id || parent.product_id,
          product_description:
            item.product_description || parent.product_description,
          site_id: item.site_id || parent.site_id,
          site_url: item.site_url || parent.site_url,
          affliate_id: item.affiliate_id || parent.affiliate_id,
          affliate_name: item.affiliate_name || parent.affiliate_name,
        },
        ...item,
      };
      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });
    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Product ID": item.product_id,
          "Product Name": item.product_description,
          "Site ID": item.site_id,
          "Site URL": item.site_url,
          "Affiliate ID": item.affiliate_id,
          "Affiliate Name": item.affiliate_name,
          Impressions: item.impressions,
          Unique: item.unique_impressions,
          Submits: thousands(item.submits),
          "Submit %": percent(parseFloat(item.submitPercent)),
          Conversions: thousands(item.conversions),
          "Conversion %": percent(parseFloat(item.conversionPercent)),
          Cost: currency(item.paid_amount),
          CPL: currency(item.cpl),
          CPI: currency(item.cpi),
          Revenue: currency(item.revenue),
          RPI: currency(item.rpi),
          "P&L": currency(item.pnl),
          PM: percent(parseFloat(item.pm)),
        };
      })
  );
};

const addCalculatedValues = (row: any) => {
  row.unique_impressions = isNaN(parseFloat(row.unique_impressions))
    ? 0
    : parseFloat(row.unique_impressions);
  row.conversions = isNaN(parseFloat(row.conversions))
    ? 0
    : parseFloat(row.conversions);
  row.revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);
  row.pnl = isNaN(parseFloat(row.pnl)) ? 0 : parseFloat(row.pnl);

  return {
    ...row,
    ...{
      submitPercent:
        row.unique_impressions !== 0
          ? (row.submits / row.unique_impressions) * 100
          : 0,
      conversionPercent:
        !isNaN(row.conversions) && row.unique_impressions !== 0
          ? (row.conversions / row.unique_impressions) * 100
          : 0,
      paid_amount: parseFloat(row.paid_amount).toLocaleString(),
      cpl:
        row.conversions !== 0
          ? ((row.paid_amount / row.conversions) * 1).toFixed(2)
          : 0,
      rpi: row.impressions ? (row.revenue / row.impressions).toFixed(2) : 0,
      cpi:
        row.impressions !== 0
          ? (row.paid_amount / row.impressions).toFixed(2)
          : 0.0,
      pnl: row.pnl.toLocaleString(),
      pm:
        row.revenue !== 0 && row.pnl !== 0
          ? (row.pnl / row.revenue) * 100
          : 0.0,
    },
  };
};

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

const PerfomanceSummary: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  const [checked] = useState(false);
  const [sort_type, set_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const detail = [
    { detailKey: "site", detailType: "Site" },
    { detailKey: "date", detailType: "Date" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    category_id: Admediary.category,
    product_id: Admediary.product,
    buyer_id: Admediary.buyer,
    add_subid: checked ? 1 : 0,
    affiliate_id: 0,
    sid1: "",
    sid2: "",
    detail_by: "disposition",
    data_type: "date_sold",
    detail: Admediary.detail,
    group: Admediary.detail,
  };

  // const [data, isLoading] = useAdmediaryApi("performance", params);
  const [data, isLoading] = useAdmediaryApiManual("performance", params);

  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summaryData = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);

  /**
   * Proxy for capturing categories and sending it up stream
   * @param data
   */
  const setDetail = (data: any) => {
    if (data === null) {
      Admediary.setDetail("site");
    } else {
      Admediary.setDetail(data);
    }
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  return (
    <>
      <Title title="Performance Report" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            placeholder="Detail"
            data={detail}
            labelKey="detailType"
            valueKey="detailKey"
            onChange={setDetail}
            value={Admediary.detail}
            style={{ float: "left", marginLeft: "1%" }}
            searchable={false}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `performance_report_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                exportData
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <PerfomanceTable
        data={treeData}
        checked={checked}
        summary={summaryData}
        isLoading={isLoading === true}
        rowKey={rowKey}
        group={params.group}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default PerfomanceSummary;

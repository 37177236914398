import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractPostResponseForm from "./BuyerContractPostResponseForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import _ from "lodash";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractPostResponseListType = {
  contractId: number;
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractPostResponseList: React.FC<BuyerContractPostResponseListType> = ({
  contractId = 0,
  height = 650,
  autoHeight = true,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const [data, isLoading] = useAdmediaryApi(
    "buyer_contract_post_responses",
    params
  );

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    (async () => {
      try {
        await Query("buyer_contract_post_response_update", data);

        Notification.success({
          title: "Success",
          description:
            "Buyer Contract Post Response has been " +
            (data.response_id ? "updated" : "added"),
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
        setFormLoading(false);
      }
    })();
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_post_response_remove", {
          response_id: itemId,
        });

        Notification.success({
          title: "Success",
          description: "Buyer Contract Post Response has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.response_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem({
      response_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={5}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Post Response
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={
          isLoading === true
            ? []
            : sortData(
                Array.isArray(data)
                  ? data[0] != null && data[0]["contract_id"] == contractId
                    ? data
                    : []
                  : []
              )
        }
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {Admediary.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}

        <Column width={250} sortable resizable>
          <HeaderCell>Text</HeaderCell>
          <Cell dataKey="text" />
        </Column>
        <Column width={250} sortable resizable>
          <HeaderCell>Interpret as</HeaderCell>
          <Cell dataKey="interpret_as" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Priority</HeaderCell>
          <Cell dataKey="priority" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Post Response?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.response_id > 0 ? "Edit" : "Add") +
          " Post Response"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
        loading={formLoading}
      >
        <BuyerContractPostResponseForm
          responseId={selectedItem ? selectedItem.response_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractPostResponseList;

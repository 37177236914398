import React, { ReactElement } from "react";
import { ResponsiveBar as NivoResponsiveBar } from "@nivo/bar";
import { Col, Grid, Loader, Progress, Row } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import VerticalOverview from "../Drawers/VerticalOverview";

type TopVerticalsByLeadsBarGraphProps = {};

const TopVerticalsByLeadsBarGraph: React.FC<TopVerticalsByLeadsBarGraphProps> = () => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const group = admediaryContext.category ? "product" : "category";
  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: admediaryContext.category,
    product_id: admediaryContext.product,
    affiliate_id: 0,
    buyer_id: admediaryContext.buyer,
    group: group,
  };

  const [data, isLoading] = useAdmediaryApi("leads_disposition", params);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  if (data.length === 0) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const index: string =
    group === "product" ? "product_description" : "category_name";

  const sorted_data: any = data
    .map((item) => {
      let short_name = item[index] ? item[index] : "";
      if (short_name.length > 21) {
        short_name = `${short_name.substring(0, 18)} ..`;
      }
      return {
        ...item,
        ...{
          short_name,
        },
      };
    })
    .sort((a, b) => {
      return a.accepted > b.accepted ? -1 : 1;
    });

  /**
   * Open Drawer with Vertical Overview
   */
  const handleClick = (data: any) => {
    if (group === "category") {
      const {
        category_id,
        category_name,
        accepted,
        filtered,
        rejected,
      } = data.data;
      admediaryContext.openDrawer(
        <VerticalOverview
          category_id={category_id}
          category_name={category_name}
          accepted={accepted}
          filtered={filtered}
          rejected={rejected}
        />
      );
    }
  };

  const tooltip = (item: any): ReactElement => {
    return (
      <div style={{ width: 200 }}>
        <Grid style={{ width: "100%" }}>
          <Row style={{ marginBottom: 10, marginTop: 7 }}>
            <Col xs={24}>
              <h6>{item.data[index]}</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={16}>
              <div>Accepted</div>
              <div>{item.data.accepted}</div>
              <div>Rejected</div>
              <div>{item.data.rejected}</div>
              <div>Filtered</div>
              <div>{item.data.filtered}</div>
            </Col>
            <Col xs={8}>
              <div style={{ width: 65, paddingTop: 10 }}>
                <Progress.Circle
                  percent={Math.round(
                    (item.data.accepted /
                      (item.data.accepted + item.data.rejected)) *
                      100
                  )}
                  strokeColor="#9daf37"
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  return (
    <NivoResponsiveBar
      data={sorted_data.slice(0, 9)}
      keys={["accepted"]}
      indexBy={"short_name"}
      margin={{ top: 0, right: 25, bottom: 50, left: 125 }}
      padding={0.2}
      colors={["#bd24ce", "#facc68", "#da6d42"]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: 0,
        legend: "Leads",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      enableGridX={true}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -70,
        legendPosition: "middle",
      }}
      enableLabel={false}
      // labelSkipWidth={12}
      // labelSkipHeight={12}
      // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layout="horizontal"
      groupMode="grouped"
      onClick={handleClick}
      tooltip={tooltip}
    />
  );
};

export default TopVerticalsByLeadsBarGraph;

import React from "react";
import { Drawer, Table } from "rsuite";
import BuyerOverview from "../Drawers/BuyerOverview";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

type BuyerAlertsProps = {
  data: any;
};

/**
 * accepted_percentage: "100.00"
 buyer_id: "137"
 cap: "83"
 contract_id: "975"
 contract_name: "Case Legal Media Pourasef LCC Boy Scout Abuse"
 last_accepted_date: "2020-09-23 12:44:07.910"
 leads_sold: "83"
 message: "At 100% of the custom limit"
 revenue: "12450.00"
 * @param data
 * @constructor
 */

const BuyerAlerts: React.FC<BuyerAlertsProps> = ({ data }) => {
  const admediary_context = React.useContext(AdmediaryContext);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>All Buyer Alerts</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Table
          autoHeight
          wordWrap
          data={data}
          hover
          onRowClick={(item) => {
            admediary_context.openDrawer(
              <BuyerOverview buyer_id={item.buyer_id} />
            );
          }}
          rowClassName="clickable-data"
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Contract</Table.HeaderCell>
            <Table.Cell dataKey="contract_name" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Alert</Table.HeaderCell>
            <Table.Cell dataKey="message" />
          </Table.Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default BuyerAlerts;

import React, { SyntheticEvent } from "react";
import {
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Icon,
  IconButton,
  Notification,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { addDays, addYears, format, parse } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import Domain from "./../Drawers/Domain";
import EditModal from "../EditModal";
import SelectPicker from "rsuite/lib/SelectPicker";
import Field from "../Field";
import ConfirmModal from "../ConfirmModal";
import { Query } from "../../@Utils/AdmediaryApi";
import moment from "moment/moment";

const { Column, HeaderCell, Cell } = Table;

/**
 * It's a separated component to prevent a scroll-to-top bug
 */
type DomainListProps = {
  data: any;
  refreshData?: () => void;
  isLoading: boolean;
  categoryId: number;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  height?: number;
};

const DomainList: React.FC<DomainListProps> = ({
  data = [],
  refreshData,
  isLoading = false,
  categoryId = 0,
  sortType = "asc",
  sortColumn = "domain_name",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          registration_date: item.registration_date
            ? format(item.registration_date, "y-MM-dd")
            : "",
          expiration_date: item.expiration_date
            ? format(item.expiration_date, "y-MM-dd")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleEditActionClick = (rowData: any, event: SyntheticEvent) => {
    // Set selected content into drawer
    admediaryContext.openDrawer(
      <Domain itemId={rowData.domain_id} refreshData={refreshData} />,
      "sm"
    );
  };

  const [showConfirmModal, setShowRenewConfirmModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [period, setPeriod] = React.useState(0);
  const [formLoading, setFormLoading] = React.useState(false);
  const [showRenew, setShowRenew] = React.useState(false);

  const periodOptions = [
    { value: 0, label: "" },
    { value: 90, label: "90 Days" },
    { value: 1, label: "One Year" },
    { value: 2, label: "Two Years" },
    { value: 3, label: "Three Years" },
  ];

  const closeRenewModal = () => setShowRenew(false);

  const showRenewModal = () => setShowRenew(true);

  const handleConfirmModalClose = () => setShowRenewConfirmModal(false);

  const handleRenew = () => {
    setShowRenewConfirmModal(false);
    setFormLoading(true);

    Query("common_domain_renew", {
      domain_id: selectedItem.domain_id,
      expiration_date: selectedItem.new_expiration_date,
    })
      .then((response) => {
        Notification.success({
          title: "Success",
          description: "Expiration date has been changed",
        });
        setFormLoading(false);
        closeRenewModal();
        if (refreshData instanceof Function) {
          refreshData();
        }
      })
      .catch((error) => {
        setFormLoading(false);
        console.log(error);
      });
  };

  const setActiveItem = (item: any) => {
    changeNewExpirationDate(item, period);
  };

  const onChangePeriod = (period: number) => {
    period = period || 0;
    setPeriod(period);
    changeNewExpirationDate(selectedItem, period);
  };

  const changeNewExpirationDate = (item: any, period: number = 0) => {
    const expirationDate = parse(
      item.expiration_date,
      "yyyy-MM-dd",
      new Date()
    );

    const SSLExpirationDate = format(new Date(), "yyyy-MM-dd");

    const newExpirationDate =
      period !== 90
        ? addYears(expirationDate, period)
        : addDays(parse(SSLExpirationDate, "yyyy-MM-dd", new Date()), period);

    setSelectedItem({
      ...item,
      ...{
        new_expiration_date: format(newExpirationDate, "y-MM-dd"),
      },
    });
  };

  const handleRenewSubmit = () => {
    setShowRenewConfirmModal(true);
  };

  const handleRenewOpen = () => {
    if (selectedItem.ssl_certificate === 1) {
      setPeriod(90);
      onChangePeriod(90);
    } else {
      setPeriod(0);
      onChangePeriod(0);
    }
  };

  function handleSelectMenu(eventKey: string, event: any) {
    if (eventKey === "renew") {
      showRenewModal();
    }
  }
  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Domain ID</HeaderCell>
          <Cell dataKey="domain_id" />
        </Column>
        <Column width={200} align="left" fixed sortable resizable>
          <HeaderCell>Domain Name</HeaderCell>
          <Cell dataKey="domain_name" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>Registration Date</HeaderCell>
          <Cell dataKey="registration_date" />
        </Column>
        <Column width={135} sortable resizable>
          <HeaderCell>Expiration Date</HeaderCell>
          <Cell dataKey="expiration_date" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>SSL</HeaderCell>
          <Cell dataKey="ssl_certificate" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Renew</HeaderCell>
          <Cell dataKey="renew" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Expired</HeaderCell>
          <Cell dataKey="expired" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Parked</HeaderCell>
          <Cell dataKey="parked" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Registrar</HeaderCell>
          <Cell dataKey="registrar" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Username</HeaderCell>
          <Cell dataKey="username" />
        </Column>

        <Column width={120} align={"center"} fixed="right">
          <HeaderCell>Action</HeaderCell>
          <ActionCell
            setActiveItem={setActiveItem}
            editHandler={handleEditActionClick}
            selectMenuHandler={handleSelectMenu}
          />
        </Column>
      </Table>

      <EditModal
        title="Renew"
        open={showRenew}
        size={"sm"}
        onShow={handleRenewOpen}
        onClose={closeRenewModal}
        onCancel={closeRenewModal}
        onSubmit={handleRenewSubmit}
        loading={formLoading}
        hideSubmitButton={admediaryContext.userDisable}
      >
        <span>
          <b>Note:</b> this doesn't actually renew the domain, it just records
          that it has been renewed.
        </span>
        <Form layout="horizontal" formValue={selectedItem}>
          <Field
            label="Period"
            name="period"
            accepter={SelectPicker}
            placeholder="Select Period"
            value={period}
            data={periodOptions}
            onChange={(v: number) => {
              onChangePeriod(v);
            }}
            style={{ marginRight: 15 }}
            disabled={admediaryContext.userDisable}
          />
          <Field label="Domain ID" name="domain_id" plaintext />
          <Field label="Domain Name" name="domain_name" plaintext />
          <Field
            label="Registration Date"
            name="registration_date"
            accepter={DatePicker}
            plaintext
          />
          <Field
            label="Expiration Date"
            name="expiration_date"
            accepter={DatePicker}
            plaintext
          />
          <Field
            label="New Expiration Date"
            name="new_expiration_date"
            accepter={DatePicker}
            plaintext
          />
        </Form>
      </EditModal>

      <ConfirmModal
        title="Renew"
        open={showConfirmModal}
        onClose={handleConfirmModalClose}
        onYes={handleRenew}
      >
        Expiration date for current domain will be changed. Are you sure?
      </ConfirmModal>
    </>
  );
};

const ActionCell = ({
  rowData,
  dataKey,
  setActiveItem,
  editHandler,
  selectMenuHandler,
  ...props
}: any) => {
  const onSelect = (eventKey: string, event: any) => {
    setActiveItem(rowData);
    selectMenuHandler(eventKey, event);
  };

  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={(e) => editHandler(rowData, e)}
        icon={<Icon icon="edit2" />}
      />
      <Divider vertical />
      <Whisper
        placement="autoVerticalStart"
        trigger="click"
        speaker={<MenuPopover onSelect={onSelect} />}
      >
        <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
      </Whisper>
    </Cell>
  );
};

const MenuPopover = ({ onSelect, checked, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} checked={checked} />
  </Popover>
);

const Menu = ({ onSelect }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="renew">Renew</Dropdown.Item>
  </Dropdown.Menu>
);

export default DomainList;

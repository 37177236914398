import React from "react";
import { Dropdown, Icon, IconButton, Popover, Table, Whisper } from "rsuite";
import {
  NumberCell,
  ParentCell,
  HeaderSummary,
  PercentCell,
  CurrencyCell,
} from "../Table";
import { thousands, currency } from "../../@Utils/Format";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import BuyerContract from "../Drawers/BuyerContract";

const { Column, HeaderCell, Cell } = Table;

/**
 * PostSummaryTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerBudgetsTableProps = {
  data: any;
  summary: any;
  isLoading: boolean;
  rowKey: string;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
  group: string;
};

const BuyerBudgetsTable: React.FC<BuyerBudgetsTableProps> = (props: any) => {
  const summary = props.summary;
  const data = props.data;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sort_type = props.sortType;
  const sort_column = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  const group = props.group;
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  /**
   * Helper function for sorting arrays
   *
   * @param attribute
   * @param data
   * @param type
   */
  const sortName = (data: any[], attribute: string, type?: string): any[] => {
    // descending order
    if (type === "desc") {
      return data.sort((a: any, b: any) => {
        return a[attribute] > b[attribute] ? -1 : 1;
      });
    }
    // ascending order
    return data.sort((a: any, b: any) => {
      return a[attribute] > b[attribute] ? 1 : -1;
    });
  };

  /**
   * Helper function for sorting tree-children structure
   *
   * @param attribute
   * @param data
   * @param type
   */
  const sortTreeName = (
    data: any[],
    attribute: string,
    type?: string
  ): any[] => {
    data = sortName(data, attribute, type);

    data.forEach((item, index) => {
      const children = item.children;
      if (children && Array.isArray(children)) {
        data[index].children = sortTree(children, attribute, type);
      }
    });

    return data;
  };

  const sort = (data: any[], attribute: string, type?: string): any[] => {
    // descending order
    if (type === "desc") {
      return data.sort((a: any, b: any) => {
        return b[attribute] - a[attribute];
      });
    }
    // ascending order
    return data.sort((a: any, b: any) => {
      return a[attribute] - b[attribute];
    });
  };

  /**
   * Helper function for sorting tree-children structure
   *
   * @param attribute
   * @param data
   * @param type
   */
  const sortTree = (data: any[], attribute: string, type?: string): any[] => {
    data = sort(data, attribute, type);

    data.forEach((item, index) => {
      const children = item.children;
      if (children && Array.isArray(children)) {
        data[index].children = sortTree(children, attribute, type);
      }
    });

    return data;
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type && sort_column !== "parent_title") {
      return sort_column && sort_type
        ? sortTree(list, sort_column, sort_type)
        : list;
    } else {
      return sort_column && sort_type
        ? sortTreeName(list, sort_column, sort_type)
        : list;
    }
  };

  return (
    <Table
      isTree
      // height={650}
      autoHeight={true}
      headerHeight={80}
      loading={isLoading === true}
      data={Array.isArray(data) ? sortData(data) : []}
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sort_column}
      sortType={sort_type}
      onSortColumn={handleSortColumn}
      onRowClick={handleExpanded}
    >
      <Column width={375} fixed sortable resizable>
        {group === "buyer" ? (
          <HeaderCell>Buyer/Contract Name</HeaderCell>
        ) : (
          <HeaderCell>Product/Buyer/Contract Name</HeaderCell>
        )}
        <ParentCell dataKey="parent_title" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>Contract ID</HeaderCell>
        <Cell dataKey="contract_id" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Budget Leads" summary={summary.budget} />
        </HeaderCell>
        <NumberCell dataKey="budget" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Budget Dollar Amt"
            summary={currency(summary.dollar_budget)}
          />
        </HeaderCell>
        <CurrencyCell dataKey="dollar_budget" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Leads" summary={summary.leads} />
        </HeaderCell>
        <NumberCell dataKey="leads" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="Earned" summary={currency(summary.total_rev)} />
        </HeaderCell>
        <CurrencyCell dataKey="total_rev" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Leads Remaining"
            summary={summary.leads_remaining}
          />
        </HeaderCell>
        <NumberCell dataKey="leads_remaining" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Dollar Amt Remaining"
            summary={currency(summary.budget_amount_remaining)}
          />
        </HeaderCell>
        <CurrencyCell dataKey="budget_amount_remaining" />
      </Column>
      <Column width={175} align="right" sortable resizable>
        <HeaderCell title="Percent Remaining">Percent Remaining</HeaderCell>
        <CustomPercentRemainingCell dataKey="percent_remaining" />
      </Column>
      <Column align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Daily Avg"
            summary={thousands(summary.daily_average)}
          />
        </HeaderCell>
        <Cell dataKey="daily_average" />
      </Column>
      <Column width={125} align="right" sortable resizable>
        <HeaderCell>Days Remaining</HeaderCell>
        <CustomDaysRemainingCell dataKey="days_remaining" />
      </Column>
      <Column width={125} align="right" sortable resizable>
        <HeaderCell>Budget Interval</HeaderCell>
        <Cell dataKey="budget_type" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary title="E-Sign Leads" summary={summary.e_sign_leads} />
        </HeaderCell>
        <NumberCell dataKey="e_sign_leads" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Retainer Leads"
            summary={summary.retainer_leads}
          />
        </HeaderCell>
        <NumberCell dataKey="retainer_leads" />
      </Column>
      <Column width={150} align="right" sortable resizable>
        <HeaderCell>
          <HeaderSummary
            title="Revshare/Unsold"
            summary={summary.revshare_leads}
          />
        </HeaderCell>
        <NumberCell dataKey="revshare_leads" />
      </Column>
      <Column width={50} fixed="right">
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell />
      </Column>
    </Table>
  );
};

const CustomDaysRemainingCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  ...props
}: any) => {
  let backgroundStyle =
    rowData.days_remaining < 5 &&
    rowData.leads_remaining > 0 &&
    rowData.daily_average > 0
      ? "red"
      : "transparent";
  let colorStyle =
    rowData.days_remaining < 5 &&
    rowData.leads_remaining > 0 &&
    rowData.daily_average > 0
      ? "white"
      : "black";

  return (
    <NumberCell
      rowData={rowData}
      dataKey={dataKey}
      {...props}
      style={{ backgroundColor: backgroundStyle, color: colorStyle }}
    />
  );
};

const CustomPercentRemainingCell = ({
  rowData,
  dataKey,
  columnKeyPrefix,
  ...props
}: any) => {
  let backgroundStyle = rowData.percent_remaining < 25 ? "red" : "transparent";
  let colorStyle = rowData.percent_remaining < 25 ? "white" : "black";

  return (
    <PercentCell
      rowData={rowData}
      dataKey={dataKey}
      {...props}
      style={{ backgroundColor: backgroundStyle, color: colorStyle }}
    />
  );
};

const Menu = ({ onSelect, row }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="edit_contract">Edit Contract</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, row, ...rest }: any) => (
  <Popover {...rest} fill>
    <Menu onSelect={onSelect} row={row} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const contractId =
    row.contract_id > 0
      ? row.contract_id
      : row._parent && row._parent.contract_id
      ? row._parent.contract_id
      : 0;

  const handleSelectMenu = (eventKey: string, event: any) => {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      ["edit_contract", <BuyerContract contractId={contractId} />],
      ["default", <span>Unknown menu item</span>],
    ]);
    const sizes = new Map([["default", "md"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      sizes.has(eventKey) ? sizes.get(eventKey) : sizes.get("default")
    );
  };

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} row={row} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, ...props }: any) => (
  <Cell {...props} className="link-group">
    {(rowData.contract_id > 0 ||
      (rowData._parent && rowData._parent.contract_id)) && (
      <CustomWhisper row={rowData} />
    )}
  </Cell>
);

export default BuyerBudgetsTable;

import React from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { CheckCell, EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractBudgetForm from "./BuyerContractBudgetForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractBudgetListType = {
  contractId: number;
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractBudgetList: React.FC<BuyerContractBudgetListType> = ({
  contractId = 0,
  height = 650,
  autoHeight = true,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = React.useContext(AdmediaryContext);

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          start_date: item.start_date
            ? format(parseISO(item.start_date), "MM-dd-Y")
            : "",
          end_date: item.end_date
            ? format(parseISO(item.end_date), "MM-dd-Y")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const getCapIntervalTitle = (abbr: string) => {
    const intervals = new Map([
      ["", "Lifetime"],
      ["weekly", "Weekly"],
      ["monthly", "Monthly"],
      ["custom", "Custom"],
    ]);

    return intervals.has(abbr) ? intervals.get(abbr) : null;
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const [data, isLoading] = useAdmediaryApi(
    "buyer_contract_budgets_by_contract",
    params
  );

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);
    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.is_active)) {
      data.is_active = data.is_active.length ? data.is_active[0] : 0;
    }

    (async () => {
      try {
        await Query("buyer_contract_budget_update", data);
        Notification.success({
          title: "Success",
          description: "Buyer Contract Budget has been updated",
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeBudget = (budgetId: number = 0) => {
    if (budgetId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_budget_remove", { budget_id: budgetId });
        Notification.success({
          title: "Success",
          description: "Buyer Contract Budget has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeBudget(selectedItem.budget_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddBudgetClick = () => {
    setSelectedItem({
      budget_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={5}>
          <IconButton
            onClick={handleAddBudgetClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Budget
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {Admediary.userDisable ? (
          ""
        ) : (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}

        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="is_active" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="is_cap_amount">
            {(rowData: any) => (rowData.is_cap_amount ? "Dollars" : "Leads")}
          </Cell>
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Interval</HeaderCell>
          <Cell dataKey="buyer_contract_budget_cap_interval">
            {(rowData: any) =>
              getCapIntervalTitle(rowData.buyer_contract_budget_cap_interval)
            }
          </Cell>
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Amount</HeaderCell>
          <Cell dataKey="cap" />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>Start Date</HeaderCell>
          <Cell dataKey="start_date" />
        </Column>
        <Column width={120} sortable resizable>
          <HeaderCell>End Date</HeaderCell>
          <Cell dataKey="end_date" />
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Budget?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.budget_id > 0 ? "Edit" : "Add") +
          " Budget"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <BuyerContractBudgetForm
          budgetId={selectedItem ? selectedItem.budget_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractBudgetList;

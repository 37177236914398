import React from "react";
import { Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { format } from "date-fns";
import { CheckCell, EditRemoveActionCell, NumberCell } from "../Table";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import EditSource from "../Drawers/EditSource";
import { FormInstance } from "rsuite/lib/Form/Form";
import { Query } from "../../@Utils/AdmediaryApi";
import ConfirmModal from "../ConfirmModal";
const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type DataSourcesListType = {
  data: any;
  isLoading: boolean;
  sortType?: "desc" | "asc";
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  refreshData?: () => void;
  height?: number;
};

const DataSourcesList: React.FC<DataSourcesListType> = ({
  data = [],
  isLoading = false,
  refreshData,
  sortType,
  sortColumn = "",
  onSortColumn = (column: any, type: any) => void 0,
  height = 650,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const mainFormRef = React.createRef<FormInstance>();
  const [formValue, setFormValue] = React.useState({});
  const [selectedItem, setSelectedItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeSource(selectedItem.data_source_id);
    handleClose();
  };
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          last_post: item.last_post
            ? format(item.last_post, "MM-dd-y HH:mm")
            : "",
        },
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  const handleEditActionClick = (rowData: any) => {
    // Set selected content into drawer

    admediaryContext.openDrawer(
      <EditSource
        dataSourceId={rowData.data_source_id}
        dataSourceKey={rowData.data_source_key}
        formRef={mainFormRef}
        parentCallback={handleCallback}
        refreshData={refreshData}
      />,
      "lg"
    );
  };
  const removeSource = (dataSourceId: number = 0) => {
    if (dataSourceId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("data_source_remove", { data_source_id: dataSourceId });
        Notification.success({
          title: "Success",
          description: "Data Source has been removed",
        });

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };
  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);
    handleOpen();
  };

  return (
    <>
      <Table
        virtualized
        // height={height}
        autoHeight={true}
        headerHeight={65}
        loading={isLoading === true}
        data={sortData(data)}
        rowClassName="clickable-data striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={onSortColumn}
      >
        <Column width={150} align="center" fixed sortable resizable>
          <HeaderCell>Data Source ID</HeaderCell>
          <Cell dataKey="data_source_id" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Monitor</HeaderCell>
          <CheckCell dataKey="monitor" />
        </Column>
        <Column width={200} sortable resizable>
          <HeaderCell>Company Name</HeaderCell>
          <Cell dataKey="company_name" />
        </Column>
        <Column width={250} sortable resizable align="right">
          <HeaderCell>Description</HeaderCell>
          <NumberCell dataKey="description" />
        </Column>
        {admediaryContext.userDisable ? (
          ""
        ) : (
          <Column width={200} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}
      </Table>
      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Source?
      </ConfirmModal>
    </>
  );
};

export default DataSourcesList;

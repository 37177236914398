import React, { ReactElement } from "react";
import { ResponsiveBar as NivoResponsiveBar } from "@nivo/bar";
import { Col, Grid, Loader, Row } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import BuyerOverview from "../Drawers/BuyerOverview";

type TopBuyersByLeadsBarGraphProps = {
  affiliate_id?: number;
  category_id?: number;
};

const TopBuyersByLeadsBarGraph: React.FC<TopBuyersByLeadsBarGraphProps> = ({
  affiliate_id,
  category_id,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: category_id ? category_id : admediaryContext.category,
    product_id: admediaryContext.product,
    buyer_id: admediaryContext.buyer,
    affiliate_id: affiliate_id ? affiliate_id : 0,
    group: "buyer",
  };

  const [data, isLoading] = useAdmediaryApi("buyer_contract_counts", params);

  // Assume if it's undefined, it's still loading
  if (isLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  const sorted_data: any = data
    .map((item) => {
      return {
        ...item,
        ...{
          buyer_name_short:
            item.buyer_name.length > 21
              ? `${item.buyer_name.substring(0, 18)} ..`
              : item.buyer_name,
        },
      };
    })
    .sort((a, b) => {
      return a.leads_sold > b.leads_sold ? -1 : 1;
    });

  /**
   * Open Drawer with Buyer Overview
   */
  const handleClick = (data: any) => {
    const {
      buyer_id,
      buyer_name,
      leads_sold,
      price_owed,
      price_received,
    } = data.data;
    admediaryContext.openDrawer(
      <BuyerOverview
        buyer_id={buyer_id}
        buyer_name={buyer_name}
        leads_sold={leads_sold}
        price_owed={price_owed}
        price_received={price_received}
      />
    );
  };

  const tooltip = (item: any): ReactElement => {
    return (
      <div style={{ width: 175 }}>
        <Grid style={{ width: "100%" }}>
          <Row style={{ marginBottom: 10, marginTop: 7 }}>
            <Col xs={24}>
              <h6>{item.data.buyer_name}</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div>Received</div>
              <div>
                {item.data.price_received.toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
              <div>Owed</div>
              <div>
                {item.data.price_owed.toLocaleString(undefined, {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
              <div>Sold</div>
              <div>{item.data.leads_sold}</div>
            </Col>
            {/*<Col xs={8}>*/}
            {/*  <div style={{ width: 65, paddingTop: 10 }}>*/}
            {/*    <Progress.Circle*/}
            {/*      percent={Math.round(*/}
            {/*        (item.data.price_owed / item.data.price_received) * 100*/}
            {/*      )}*/}
            {/*      strokeColor="#9daf37"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Col>*/}
          </Row>
        </Grid>
      </div>
    );
  };

  return (
    <NivoResponsiveBar
      data={sorted_data.slice(0, 9)}
      keys={["leads_sold"]}
      indexBy={"buyer_name_short"}
      margin={{ top: 0, right: 25, bottom: 50, left: 125 }}
      padding={0.2}
      colors={["#0bd6ef", "#8abac4", "#ecae5b", "#8abac4"]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisBottom={{
        tickSize: 3,
        tickPadding: 3,
        tickRotation: 0,
        legend: "Leads Sold",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      enableGridX={true}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendOffset: -70,
        legendPosition: "middle",
      }}
      enableLabel={false}
      // labelSkipWidth={12}
      // labelSkipHeight={12}
      // labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      layout="horizontal"
      groupMode="grouped"
      onClick={handleClick}
      tooltip={tooltip}
    />
  );
};

export default TopBuyersByLeadsBarGraph;

import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
  Table,
  ControlLabel,
  SelectPicker,
  InputGroup,
  Input,
} from "rsuite";
import { sort } from "../../../@Utils/Sorting";
import useAdmediaryApi from "../../../@Hooks/useAdmediaryApi";
import { EditRemoveActionCell } from "../../Table";
import ConfirmModal from "../../ConfirmModal";
import EditModal from "../../EditModal";
import ProductSourceForm from "./ProductSourceForm";
import { Query } from "../../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { FormContext } from "../../../@Context/FormContext";
import { exportCsv } from "../../../@Utils/Export";
import { format } from "date-fns";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

type ProductSourceListType = {
  height?: number;
  autoHeight?: boolean;
  onlyPreview?: boolean;
  product_description?: string;
  product_id?: number;
};

const ProductSourceList: React.FC<ProductSourceListType> = ({
  height = 650,
  autoHeight = true,
  onlyPreview = false,
  product_description = "",
  product_id = 0,
}) => {
  const Forms = React.useContext(FormContext);
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    contract_id: 0,
    product_id: product_id,
  };

  const [data, isLoading] = useAdmediaryApi("buyer_contract_sources", params);
  const handleCallback = (formValue: any) => {
    setFormValue({
      ...formValue,
      ...{
        source_id: selectedItem ? selectedItem.source_id : 0,
        contract_id: 0,
        product_id: product_id,
      },
    });
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.is_active)) {
      data.is_active = data.is_active.length ? data.is_active[0] : 0;
    }

    (async () => {
      try {
        await Query("buyer_contract_source_update", data);
        Notification.success({
          title: "Success",
          description: "Buyer Contract Source has been updated",
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeSource = (sourceId: number = 0) => {
    if (sourceId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_source_remove", { source_id: sourceId });
        Notification.success({
          title: "Success",
          description: "Buyer Contract Source has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeSource(selectedItem.source_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddSourceClick = () => {
    setSelectedItem({
      source_id: 0,
      contract_id: 0,
      product_id: product_id,
    });

    handleEditModalOpen();
  };

  const setDefaultAllow = (data: any) => {
    Forms.setDefaultAllow(data);
  };

  const formatExportData = (data: any) => {
    return data
      .map((item: any) => item)
      .map((item: any) => {
        return {
          "Product ID": product_id,
          "Product Description": product_description,
          "Affiliate ID": item.affiliate_id,
          Sid1: item.sid1,
          Sid2: item.sid2,
          Sid3: item.sid3,
          Sid4: item.sid4,
          Allow: item.allow ? "Allow" : "Block",
          Date: format(new Date(), "MM/dd/yyyy"),
        };
      });
  };

  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) =>
      (isNaN(v) ? v.toLowerCase() : v.toString()).includes(search);
    const fields = ["affiliate_id", "sid1", "sid2", "sid3", "sid4"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field] ?? ""))
    );
  };

  return (
    <>
      {!onlyPreview && (
        <FlexboxGrid justify="start" style={{ marginBottom: 15 }}>
          <FlexboxGrid.Item colspan={24}>
            <IconButton
              onClick={handleAddSourceClick}
              icon={<Icon icon="plus-circle" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Add Source
            </IconButton>
            <IconButton
              onClick={() =>
                exportCsv(
                  `productID_${product_id}_${product_description}_${format(
                    new Date(),
                    "MMddyy"
                  )}_sources`,
                  formatExportData(
                    Array.isArray(data) ? filter(data, searchQuery) : []
                  )
                )
              }
              icon={<Icon icon="file-download" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Export
            </IconButton>
            <ControlLabel style={{ marginRight: 15 }}>Default:</ControlLabel>
            <SelectPicker
              size="sm"
              searchable={false}
              placeholder=""
              value={Forms.defaultAllow}
              data={[
                { value: 1, label: "Allow" },
                { value: 0, label: "Block" },
              ]}
              onChange={setDefaultAllow}
              style={{ marginRight: 15 }}
              disabled={true}
            />

            <InputGroup
              style={{ width: 200, marginRight: 20, display: "inline-block" }}
            >
              <Input
                placeholder="Search"
                onChange={(v) => {
                  debounceSearchTerm(v);
                }}
                size="sm"
              />
            </InputGroup>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? filter(data, searchQuery) : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        affixHorizontalScrollbar
      >
        {!onlyPreview && (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Afid</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid1</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid2</HeaderCell>
          <Cell dataKey="sid2" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid3</HeaderCell>
          <Cell dataKey="sid3" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid4</HeaderCell>
          <Cell dataKey="sid4" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Allow</HeaderCell>
          <Cell dataKey="allow">
            {(rowData: any) => (rowData.allow ? "Allow" : "Block")}
          </Cell>
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Source?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.source_id > 0 ? "Edit" : "Add") +
          " Source"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <ProductSourceForm
          sourceId={selectedItem ? selectedItem.source_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default ProductSourceList;

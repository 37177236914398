import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Notification,
  Table,
  ControlLabel,
  SelectPicker,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import PingTreeRotuingSetSourceForm from "./PingTreeRoutingSetSourceForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { FormContext } from "../../@Context/FormContext";
import { useContext, useEffect } from "react";

const { Column, HeaderCell, Cell } = Table;

type PingTreeRoutingSourceListType = {
  routingId: number;
  height?: number;
  autoHeight?: boolean;
  onlyPreview?: boolean;
};

const PingTreeRoutingSourceList: React.FC<PingTreeRoutingSourceListType> = ({
  routingId = 0,
  height = 650,
  autoHeight = true,
  onlyPreview = false,
}) => {
  const Admediary = React.useContext(AdmediaryContext);
  const Forms = React.useContext(FormContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    routing_set_id: routingId,
  };

  const [data, isLoading] = useAdmediaryApi("routing_set_sources", params);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;
    if (node && !node.check()) {
      return;
    }

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.is_active)) {
      data.is_active = data.is_active.length ? data.is_active[0] : 0;
    }

    (async () => {
      try {
        await Query("routing_set_source_update", data);
        refresh();
        Notification.success({
          title: "Success",
          description: "Ping Tree Source has been updated",
        });
        refresh();

        handleEditModalClose();
        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeSource = async (sourceId: number = 0) => {
    if (sourceId === 0) {
      return;
    }

    try {
      await Query("routing_set_source_remove", { source_id: sourceId });
      refresh();
      Notification.success({
        title: "Success",
        description: "Ping Tree Routing Source has been removed",
      });

      refresh();
    } catch (e) {
      refresh();
      console.log("error", e);
      Notification.error({
        title: "Error",
        duration: 60000,
        description: e,
      });
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeSource(selectedItem.source_id);
    refresh();
    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddSourceClick = () => {
    setSelectedItem({
      source_id: 0,
      routing_set_id: routingId,
    });

    handleEditModalOpen();
  };

  // const setDefaultAllow = (data: any) => {
  //   Forms.setDefaultAllow(data);
  //   console.log(data)
  // };

  function setDefaultAllow(value: any) {
    Forms.setDefaultAllow(value);
    Forms.defaultAllow = value ? value : null;
  }

  useEffect(() => {
    Forms.setDefaultAllow(Forms.defaultAllow);
  }, [Forms.defaultAllow]);

  return (
    <>
      {!onlyPreview && (
        <FlexboxGrid justify="start" style={{ marginBottom: 15 }}>
          <FlexboxGrid.Item colspan={24}>
            <IconButton
              onClick={handleAddSourceClick}
              icon={<Icon icon="plus-circle" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
              disabled={Admediary.userDisable}
            >
              Add Source
            </IconButton>

            <ControlLabel style={{ marginRight: 15 }}>Default:</ControlLabel>

            <SelectPicker
              size="sm"
              searchable={false}
              placeholder=""
              value={Forms.defaultAllow}
              data={[
                { value: 1, label: "Allow" },
                { value: 0, label: "Block" },
              ]}
              onChange={(value) => setDefaultAllow(value)}
              style={{ marginRight: 15 }}
              disabled={Admediary.userDisable}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {!onlyPreview && !Admediary.userDisable && (
          <Column width={100} align={"center"} fixed>
            <HeaderCell>Action</HeaderCell>
            <EditRemoveActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        )}
        <Column align="right" fixed sortable resizable>
          <HeaderCell>Afid</HeaderCell>
          <Cell dataKey="affiliate_id" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid1</HeaderCell>
          <Cell dataKey="sid1" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid2</HeaderCell>
          <Cell dataKey="sid2" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Sid3</HeaderCell>
          <Cell dataKey="sid3" />
        </Column>
        <Column width={150} sortable resizable>
          <HeaderCell>Allow</HeaderCell>
          <Cell dataKey="allow">
            {(rowData: any) => (rowData.allow ? "Allow" : "Block")}
          </Cell>
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Source?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.source_id > 0 ? "Edit" : "Add") +
          " Source"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <PingTreeRotuingSetSourceForm
          sourceId={selectedItem ? selectedItem.source_id : 0}
          routingId={selectedItem ? selectedItem.routing_set_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};
export default PingTreeRoutingSourceList;

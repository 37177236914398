import { Icon, IconButton, Table, Tooltip, Whisper } from "rsuite";
import { currency, float, percent, thousands } from "../@Utils/Format";
import React, { SyntheticEvent, useContext } from "react";
import { IconNames } from "rsuite/lib/Icon/Icon";
import { AdmediaryContext } from "../@Context/AdmediaryContext";

const { Cell } = Table;

export const ParentCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell
    {...props}
    className={rowData.entry_date && "adm-rs-tree-cell-parent-date"}
  >
    {rowData[dataKey]}
  </Cell>
);
export const HourCell = ({ rowData, dataKey, ...props }: any) => {
  const hours =
    parseInt(rowData[dataKey]) < 10 ? "0" + rowData[dataKey] : rowData[dataKey];
  return <Cell {...props}>{hours + ":00"}</Cell>;
};

export const NumberCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
);

export const FloatCell = ({
  rowData,
  dataKey,
  digits = 2,
  postfix = "",
  ...props
}: any) => {
  return <Cell {...props}>{float(rowData[dataKey], digits) + postfix}</Cell>;
};

export const CurrencyCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props}>{currency(rowData[dataKey])}</Cell>
);

export const PercentCell = ({
  rowData,
  dataKey,
  digits = 1,
  ...props
}: any) => <Cell {...props}>{percent(rowData[dataKey], digits)}</Cell>;

export const ToolTipCell = ({
  rowData,
  dataKey,
  content = "",
  ...props
}: any) => (
  <Cell {...props}>
    <Whisper
      trigger="hover"
      placement={dataKey === "sms_message" ? "topStart" : "auto"}
      speaker={
        <Tooltip style={{ width: 400, zIndex: 100000 }}>
          {content || rowData[dataKey]}
        </Tooltip>
      }
    >
      <span>{rowData[dataKey]}</span>
    </Whisper>
  </Cell>
);

export const CheckCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props}>
    <Icon
      icon={parseInt(rowData[dataKey]) === 1 ? "check-square-o" : "square-o"}
    />
  </Cell>
);

export const HeaderSummary = ({ title, summary }: any) => (
  <div className={"adm-rs-table-cell-header-summary-wrapper"}>
    <label>{title}</label>
    <div className={"adm-rs-table-cell-header-summary-content"}>
      {thousands(summary || 0)}
    </div>
  </div>
);

export const IconToolTip = ({ description }: any) => (
  <Whisper trigger="hover" speaker={<Tooltip>{description}</Tooltip>}>
    <Icon icon="info" style={{ marginLeft: 7 }} />
  </Whisper>
);

export const HeaderSummaryToolTip = ({ title, summary, description }: any) => (
  <div className={"adm-rs-table-cell-header-summary-wrapper"}>
    <label>{title}</label>
    <IconToolTip description={description} />
    <div className={"adm-rs-table-cell-header-summary-content"}>
      {thousands(summary || 0)}
    </div>
  </div>
);

export const EditRemoveActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  userPermission = false,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={(e) => editHandler(rowData, e)}
        icon={<Icon icon="edit2" />}
      />
      {removeHandler instanceof Function && !userPermission ? (
        <IconButton
          appearance="subtle"
          onClick={(e) => removeHandler(rowData, e)}
          icon={<Icon icon="trash" />}
        />
      ) : (
        ""
      )}
    </Cell>
  );
};

export const EditActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={(e) => editHandler(rowData, e)}
        icon={<Icon icon="edit2" />}
      />
    </Cell>
  );
};

type IconParameterType = {
  icon: IconNames;
  handler: (rowData: any, e?: SyntheticEvent) => void;
};

type IconsActionCellType = {
  rowData?: any;
  icons: IconParameterType[];
};

export const IconsActionCell: React.FC<IconsActionCellType> = ({
  rowData,
  icons,
  ...props
}) => {
  return (
    <Cell {...props} className="link-group">
      {icons.map((icon: any, index: number) => (
        <IconButton
          key={index}
          appearance="subtle"
          icon={<Icon icon={icon.icon} />}
          onClick={(e) => icon.handler(rowData, e)}
        />
      ))}
    </Cell>
  );
};

import React from "react";
import { FlexboxGrid, Table } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
// import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { sort } from "../../@Utils/Sorting";

const RoiList: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    affiliate_id: Admediary.affiliate,
    product_id: Admediary.product,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  // fetch buyer_contract_counts
  // const [data, isLoading] = useAdmediaryApi("site_roi_flow_tree", params);
  const [data, isLoading] = useAdmediaryApiManual("site_roi_flow_tree", params);

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item
          colspan={24}
          style={{ textAlign: "right" }}
        ></FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        // height={650}
        autoHeight={true}
        loading={isLoading === true}
        data={Array.isArray(data) ? sortData(data) : []}
        hover
        rowClassName="clickable-data striped-rows"
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      >
        <Table.Column width={100} fixed sortable resizable>
          <Table.HeaderCell>SITE URL</Table.HeaderCell>
          <Table.Cell dataKey="site_url" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Aff ID</Table.HeaderCell>
          <Table.Cell dataKey="affiliate_id" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Affiliate Name</Table.HeaderCell>
          <Table.Cell dataKey="affiliate_name" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Manager</Table.HeaderCell>
          <Table.Cell dataKey="account_manager" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Page</Table.HeaderCell>
          <Table.Cell dataKey="page_description" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Flow</Table.HeaderCell>
          <Table.Cell dataKey="flow_name" />
        </Table.Column>

        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Step</Table.HeaderCell>
          <Table.Cell dataKey="step_name" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Offer Name</Table.HeaderCell>
          <Table.Cell dataKey="offer_name" />
        </Table.Column>
        <Table.Column width={225} fixed sortable resizable>
          <Table.HeaderCell>Sub ID</Table.HeaderCell>
          <Table.Cell dataKey="sub_id" />
        </Table.Column>
      </Table>
    </>
  );
};

export default RoiList;

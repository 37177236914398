import React from "react";
import {
  ControlLabel,
  FlexboxGrid,
  Icon,
  IconButton,
  Input,
  InputGroup,
} from "rsuite";
import SelectPicker from "rsuite/lib/SelectPicker";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { exportCsv } from "../../@Utils/Export";
import Title from "../Title";
import DomainList from "./DomainList";
import Domain from "../Drawers/Domain";
import { format } from "date-fns";

function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    const registrationDate = item.registration_date
      ? format(item.registration_date, "y-MM-dd")
      : "";
    const expirationDate = item.expiration_date
      ? format(item.expiration_date, "y-MM-dd")
      : "";

    return {
      "Domain ID": item.domain_id,
      "Domain Name": item.domain_name,
      "Registration Date": registrationDate,
      "Expiration Date": expirationDate,
      SSL: item.ssl_certificate,
      Renew: item.renew,
      Expired: item.expired,
      Parked: item.parked,
      Registrar: item.registrar,
      Username: item.username,
    };
  });
}

type DomainsProps = {
  height?: number;
};

const Domains: React.FC<DomainsProps> = ({ height = 650 }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const defaultExpiredValue = 0;
  const [expired, setExpired] = React.useState(defaultExpiredValue);
  const expiredList = [
    { value: -1, label: "All" },
    { value: 1, label: "Expired" },
    { value: 0, label: "Not Expired" },
  ];
  const defaultRenewValue = 1;
  const [renew, setRenew] = React.useState(defaultRenewValue);
  const renewList = [
    { value: -1, label: "All" },
    { value: 1, label: "Renew" },
    { value: 0, label: "Don't Renew" },
  ];
  const categoryId = Admediary.category;
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();

  const defaultSSLValue = -1;
  const [ssl, setSSL] = React.useState(defaultSSLValue);
  const sslList = [
    { value: -1, label: "All" },
    { value: 1, label: "SSL Only" },
    { value: 0, label: "No SSL" },
  ];

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const params = {
    refresh: refreshFlag,
    expired,
    renew,
    ssl_certificate: ssl,
  };

  const [data, isLoading] = useAdmediaryApi("common_domains", params);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleAddItemClick = () => {
    // Set selected content into drawer
    Admediary.openDrawer(<Domain itemId={0} refreshData={refresh} />, "sm");
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toString().toLowerCase().includes(search);
    const fields = ["domain_id", "domain_name", "registrar", "username"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  const allValue = "ALL";
  const expiredPostfix = new Map([
    [-1, allValue],
    [1, "EXPIRED"],
    [0, "NOT_EXPIRED"],
  ]);
  const renewPostfix = new Map([
    [-1, allValue],
    [1, "RENEW"],
    [0, "DONT_RENEW"],
  ]);
  let exportNameExpiredPostfix;
  if (expiredPostfix.has(expired)) {
    exportNameExpiredPostfix = expiredPostfix.get(expired);
  }
  if (!exportNameExpiredPostfix) {
    exportNameExpiredPostfix = allValue;
  }
  let exportNameRenewPostfix;
  if (renewPostfix.has(renew)) {
    exportNameRenewPostfix = renewPostfix.get(renew);
  }
  if (!exportNameRenewPostfix) {
    exportNameRenewPostfix = allValue;
  }

  if (
    exportNameExpiredPostfix === allValue &&
    exportNameRenewPostfix === allValue
  ) {
    exportNameRenewPostfix = "";
  }
  const exportNamePostfix =
    exportNameExpiredPostfix +
    (exportNameRenewPostfix ? "-" + exportNameRenewPostfix : "");

  return (
    <>
      <Title title="Domains" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <ControlLabel style={{ marginRight: 10 }}>Expired:</ControlLabel>
          <SelectPicker
            size="sm"
            placeholder="Filter by Expiration"
            searchable={false}
            cleanable={false}
            defaultValue={defaultExpiredValue}
            value={expired}
            data={expiredList}
            onChange={(v) => {
              setExpired(v);
            }}
            style={{ marginRight: 15 }}
          />
          <ControlLabel style={{ marginRight: 10 }}>Renew:</ControlLabel>
          <SelectPicker
            size="sm"
            placeholder="Filter by Renew action"
            searchable={false}
            cleanable={false}
            defaultValue={defaultRenewValue}
            value={renew}
            data={renewList}
            onChange={setRenew}
            style={{ marginRight: 15 }}
          />
          <ControlLabel style={{ marginRight: 10 }}>SSL:</ControlLabel>
          <SelectPicker
            size="sm"
            placeholder="Filter by SSL"
            searchable={false}
            cleanable={false}
            defaultValue={defaultSSLValue}
            value={ssl}
            data={sslList}
            onChange={(v) => setSSL(v)}
            style={{ marginRight: 15 }}
          />
          <InputGroup
            style={{ width: 250, marginRight: 15, display: "inline-block" }}
          >
            <Input
              placeholder="Search"
              onChange={(v) => {
                debounceSearchTerm(v);
              }}
              size="md"
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={5} style={{ textAlign: "right" }}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<Icon icon="plus-circle" />}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{ marginRight: 15 }}
            disabled={Admediary.userDisable}
          >
            Add Domain
          </IconButton>
          <IconButton
            onClick={() =>
              exportCsv(
                `common_domains_${exportNamePostfix}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <DomainList
        height={height}
        data={filter(Array.isArray(data) ? data : [], searchQuery)}
        refreshData={refresh}
        isLoading={isLoading === true}
        categoryId={categoryId}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default Domains;

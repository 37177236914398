import React from "react";
import { Loader } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

interface ICallback {
  (name: any): void;
}

interface ILeadCapSummary {
  buyer_id: number;
  limit?: number;
  callback?: ICallback;
}

const BudgetsByBuyerContract: React.FC<ILeadCapSummary> = ({
  buyer_id,
  callback,
  limit = 0,
}) => {
  const admediaryContext = React.useContext(AdmediaryContext);

  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
    category_id: admediaryContext.category,
    product_id: admediaryContext.product,
    affiliate_id: 0,
    buyer_id: buyer_id,
    group: "",
  };

  const [data, leadsIsLoading] = useAdmediaryApi(
    "buyer_contract_budgets",
    params
  );

  if (leadsIsLoading || !Array.isArray(data)) {
    return <Loader size="md" speed="slow" vertical center />;
  }

  if (callback) {
    callback(data);
  }

  if (data.length === 0) {
    return <React.Fragment>No contracts could be found.</React.Fragment>;
  }

  /**
     accepted_percentage: "92.77"
     buyer_id: "207"
     cap: "249"
     contract_id: "947"
     contract_name: "Response Tree Tier 1"
     last_accepted_date: "2020-08-21 23:57:40.397"
     lead_sold: "231"
     revenue: "3927.00"
     */
  let contracts = data
    .map((item: any) => {
      return {
        ...item,
        ...{
          budget_percentage: Math.round((item.leads_sold / item.cap) * 100),
        },
      };
    })
    .sort((a: any, b: any) => {
      return a.budget_percentage < b.budget_percentage ? 1 : -1;
    });
  if (limit > 0) {
    contracts = contracts.slice(0, 8);
  }

  return (
    <React.Fragment>
      <table className="summary-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="summary-table-callout" style={{ maxWidth: 150 }}>
              Name
            </th>
            <th className="summary-table-graph" colSpan={2}>
              <span style={{ paddingLeft: 10 }}>% of Budget</span>
            </th>
            <th className="right">Leads</th>
            <th className="right">Budget</th>
            <th className="summary-table-graph" colSpan={2}>
              <span style={{ paddingLeft: 10 }}>% of Accepted</span>
            </th>
            <th className="right">Revenue</th>
          </tr>
        </thead>
        <tbody>
          {contracts.map((item: any) => (
            <tr key={item.contract_id}>
              <td
                className="summary-table-callout"
                style={{
                  maxWidth: 150,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span className="summary-table-primary">
                  {item.buyer_name} {item.contract_name}
                </span>
              </td>
              <td style={{ maxWidth: 45 }}>
                <span
                  style={{
                    width: 45,
                    paddingLeft: 10,
                    paddingRight: 12,
                  }}
                >
                  {item.budget_percentage}%
                </span>
              </td>
              <td style={{ minWidth: 50 }}>
                <span
                  style={{
                    display: "inline-block",
                    height: "12px",
                    width: `${item.budget_percentage}%`,
                    background: "#ff311b",
                  }}
                />
              </td>
              <td className="right">{item.leads_sold.toLocaleString()}</td>
              <td className="right">{item.cap.toLocaleString()}</td>
              <td style={{ maxWidth: 45 }}>
                <span
                  style={{
                    width: 45,
                    paddingLeft: 10,
                    paddingRight: 12,
                  }}
                >
                  {isNaN(item.accepted_percentage)
                    ? 0
                    : Math.round(item.accepted_percentage)}
                  %
                </span>
              </td>
              <td style={{ minWidth: 50 }}>
                <span
                  style={{
                    display: "inline-block",
                    height: "12px",
                    width: `${Math.floor(item.accepted_percentage)}%`,
                    background: "#ff311b",
                  }}
                />
              </td>
              <td className="right">
                {isNaN(item.revenue)
                  ? 0
                  : item.revenue.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default BudgetsByBuyerContract;

import React from "react";
import {
  Button,
  ButtonToolbar,
  Drawer,
  Icon,
  Loader,
  Notification,
} from "rsuite";
import { FormInstance } from "rsuite/lib/Form/Form";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { Query } from "../../@Utils/AdmediaryApi";
import _ from "lodash";
import DomainForm from "../Common/DomainForm";
import { format } from "date-fns";
import moment from "moment";

type DomainType = {
  itemId: number;
  refreshData?: () => void;
};

const Domain: React.FC<DomainType> = ({ itemId = 0, refreshData }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [formValue, setFormValue] = React.useState({});
  const [isSaving, setIsSaving] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }
    setIsSaving(true);
    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.ssl_certificate)) {
      data.ssl_certificate = data.ssl_certificate.length
        ? data.ssl_certificate[0]
        : 0;
    }
    if (Array.isArray(data.renew)) {
      data.renew = data.renew.length ? data.renew[0] : 0;
    }
    if (Array.isArray(data.expired)) {
      data.expired = data.expired.length ? data.expired[0] : 0;
    }
    if (Array.isArray(data.parked)) {
      data.parked = data.parked.length ? data.parked[0] : 0;
    }
    console.log(data);
    data.registration_date = moment(data.registration_date).format(
      "YYYY-MM-DD"
    );
    data.expiration_date = moment(data.expiration_date).format("YYYY-MM-DD");

    // data.registration_date = format(data.registration_date, "yyyy-MM-dd");
    // data.expiration_date = format(data.expiration_date, "yyyy-MM-dd");

    (async () => {
      try {
        await Query("common_domain_update", data);

        Notification.success({
          title: "Success",
          description: "Domain has been updated",
        });

        Admediary.closeDrawer();

        if (refreshData instanceof Function) {
          refreshData();
        }
      } catch (e) {
        console.log("error", e);
        setIsSaving(false);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
        setIsSaving(false);
      }
    })();
  };

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!itemId ? "Edit" : "Add"} Domain
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <DomainForm
          itemId={itemId}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </Drawer.Body>
      <Drawer.Footer style={{ textAlign: "center" }}>
        <ButtonToolbar style={{ marginBottom: 18 }}>
          <Button
            onClick={handleSave}
            appearance="primary"
            disabled={Admediary.userDisable || isSaving}
          >
            {!isSaving && <>Save</>}
            {isSaving && (
              <Loader size="sm" style={{ padding: "0", margin: "0 0.5em" }} />
            )}
          </Button>
          <Button onClick={() => Admediary.closeDrawer()} appearance="subtle">
            Cancel
          </Button>
        </ButtonToolbar>
      </Drawer.Footer>
    </>
  );
};

export default Domain;

import React from "react";
import { parseISO } from "date-fns";

/**
 * Constants for local storage keys
 */
const LOCAL_STORAGE_KEYS = {
  DATES: "filters.dates",
  ACTIVE_FORM: "select.active",
  ACTIVE_FORM_ROUTE: "select.active_route",
  DEFAULT_FORM_ROUTE: "select.default_route",
  ACTIVE_FORM_CAMPAIGN: "select.active_campaign",
  CLIENT: "client",
  MONITOR: "MONITOR",
  REV_SHARE: "REV_SHARE",
  CATEGORY_FORM: "select.category",
  DESCRIPTION_FORM: "select.description",
  DESCRIPTION_FORM_ROUTE: "select.description_route",
  PRIORITY_FORM_ROUTE: "select.priority_form_route",
  DESCRIPTION_FORM_CAMPAIGN: "select.description_campaign",
  LOCK_ROUTE: "select.lock_route",
  PRIORITY_ROUTE: "select.priority_route",
  BINDING_ROUTE: "select.binding_route",
  SEARCH: "search",
  RARE_FORM: "",
  RATE_FORM: "",
  TREE_ID: "select.tree_id",
  ROUTE_ID: "select.route_id",
  CAMPAIGN_ID: "select.campaign.id",
  CAMPAIGN_KEY: "select.campaign.key",
  LOADING: "loading",
  CLOSE: "CLOSE",
  SHOW: "SHOW",
  KEY: "KEY",
  ROW: "ROW",
  FROM: "FROM",
  COMPANY: "COMPANY",
  PING_TRESS_DATA: "data",
  PRODUCT_FORM: "select.product",
  BUYER: "filters.buyer",
  BUYER_NAME: "buyer.name",
  ABBREVIATION: "buyer.abbreviation",
  EMAIL: "buyer.email",
  ADDRESS: "buyer.address",
  PHONE: "buyer.phone",
  IP: "buyer.ip",
  CAKE_BUYER_ID: "buyer.cake_buyer_id",
  FILTER_ID: "filter.id",
  AFFILIATE: "filters.affiliates",
  CONTRACT: "filters.contract",
  MESSAGE: "filters.message",
  MESSAGES: "filters.messages",
  PROBABILITY: "filters.probability",
  STARTTIME: "select.start",
  ENDTIME: "select.end",
  REFRESH: "refresh",
  DAY: "select.day",
  SCHEDULE: "select.schedule",
  TEMPORARY_CONTRACT: "select.contract",
  TEMPORARY_DAY: "filters.day",
  CONTRACTS: "contracts",
  SCHEDULES: "schedules",
  DETAIL: "filters.detail",
  DEFAULT_ALLOW: "select.default_allow",
  POST_URL: "select.post_url",
  TIMEOUT: "select.timeout",
  PING_CONTRACT_ID: "select.ping_contract_id",
  PING_URL: "select.ping_url",
  PING_TIMEOUT: "select.ping_timeout",
  PING_MINIMUM_PRICE: "select.ping_minimum_price",
};

interface FormProviderProps {
  children?: React.ReactNode;
}

type FormContextType = {
  start: Date;
  end: Date;
  startTime: any;
  endTime: any;
  revShare: any;
  refresh: boolean;
  close: boolean;
  show: boolean;
  dataSourceKey: any;
  from: any;
  row: any;
  company: string;
  loading: boolean;
  tree: any;
  route: any;
  priority: any;
  binding: any;
  rare: any;
  rate: any;
  category: any;
  description: any;
  descriptionRoute: any;
  priorityRoute: any;
  descriptionCampaign: any;
  affiliate: any;
  campaign?: any;
  active?: boolean;
  client: any;
  monitor?: any;
  search?: any;
  activeRoute?: boolean;
  defaultRoute?: boolean;
  lock: boolean;
  activeCampaign?: boolean;
  campaignKey?: string;
  product: any;
  buyer: any;
  message: any;
  messages: any;
  probability: number;
  buyerName: any;
  abbreviation: any;
  email: any;
  filter: any;
  address: any;
  phone: any;
  ip: any;
  cakeBuyerId: any;
  day: any;
  schedule: any;
  temporaryContract: any;
  temporaryDay: any;
  contract: any;
  contracts: any;
  schedules: any;
  setSearch?: any;
  setActive?: any;
  setClient?: any;
  setRevShare?: any;
  setMonitor?: any;
  setLock?: any;
  setActiveRoute?: any;
  setDefaultRoute?: any;
  setPriorityRoute?: any;
  setActiveCampaign?: any;
  setCampaignKey?: any;
  setRefresh?: any;
  setLoading?: any;
  setClose?: any;
  setShow?: any;
  setDataSourceKey?: any;
  setRow?: any;
  setCompany?: any;
  setTree?: any;
  setPriority?: any;
  setBinding?: any;
  setRoute?: any;
  setCategory?: any;
  setDescription?: any;
  setDescriptionRoute?: any;
  setDescriptionCampaign?: any;
  setRare?: any;
  setRate?: any;
  setBuyer?: any;
  setProbability?: any;
  setPhone?: any;
  setMessage?: any;
  setMessages?: any;
  setBuyerName?: any;
  setAbbreviation?: any;
  setEmail?: any;
  setFilter?: any;
  setAddress?: any;
  setIP?: any;
  setCakeBuyerId?: any;
  setAffiliate?: any;
  setCampaign?: any;
  setDateRange?: any;
  setProduct?: any;
  // setStart?:any;
  // setEnd?:any;
  setSchedule?: any;
  setDay?: any;
  setStartTime?: any;
  setEndTime?: any;
  setContract?: any;
  setTemporaryContract?: any;
  setTemporaryDay?: any;
  setContracts?: any;
  setSchedules?: any;
  setFrom?: any;
  data: any;
  setData?: any;
  detail?: any;
  setDetail?: any;
  defaultAllow: any;
  setDefaultAllow?: any;
  postUrl: any;
  setPostUrl?: any;
  timeout: any;
  setTimeout?: any;
  pingContractId: any;
  setPingContractId?: any;
  pingUrl: any;
  setPingUrl?: any;
  pingTimeout: any;
  setPingTimeout?: any;
  pingMinimumPrice: any;
  setPingMinimumPrice?: any;
};

// Create React Context to keep track of current auth state
const FormContext = React.createContext<FormContextType>({
  start: new Date(),
  end: new Date(),
  close: false,
  from: "",
  show: false,
  dataSourceKey: "",
  row: 0,
  company: "",
  refresh: false,
  loading: false,
  tree: 0,
  route: 0,
  priority: 0,
  binding: 0,
  lock: true,
  search: "",
  active: false,
  client: 0,
  revShare: "",
  monitor: false,
  activeRoute: false,
  defaultRoute: false,
  activeCampaign: false,
  campaignKey: "",
  category: 0,
  description: "",
  descriptionRoute: "",
  priorityRoute: 0,
  descriptionCampaign: "",
  rare: "",
  rate: 0,
  affiliate: 0,
  campaign: 0,
  product: 0,
  buyer: 0,
  phone: "",
  message: "",
  messages: [],
  probability: 0,
  buyerName: "",
  abbreviation: "",
  email: "",
  filter: "",
  address: "",
  ip: "",
  cakeBuyerId: null,
  contract: 0,
  startTime: "",
  endTime: "",
  schedule: 0,
  day: "",
  temporaryContract: 0,
  temporaryDay: "",
  contracts: [],
  schedules: [],
  data: null,
  detail: "site",
  defaultAllow: null,
  postUrl: "",
  timeout: 0,
  pingContractId: 0,
  pingUrl: "",
  pingTimeout: 0,
  pingMinimumPrice: "",
});

/**
 * Helper function for recording data to local storage
 * @param localStorageKey
 */
const useStoredDates = () => {
  const storedValue = sessionStorage.getItem(LOCAL_STORAGE_KEYS.DATES);

  // Default value for today's date
  const initialValue = {
    start: new Date(),
    end: new Date(),
  };

  /**
   * Helper function for parsing storage date range filters
   * @param value
   */
  const parseStoredValue = (value: any) => {
    const parsed = JSON.parse(value);
    parsed.start = parseISO(parsed.start);
    parsed.end = parseISO(parsed.end);
    return parsed;
  };

  // Set state via useState Hook
  const [value, setValue] = React.useState(
    !storedValue ? initialValue : parseStoredValue(storedValue)
  );

  // Save values to local storage once state updates
  React.useEffect(() => {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.DATES, JSON.stringify(value));
  }, [value]);
  return [value, setValue];
};

/**
 * Helper function for recording misc data to local storage
 */
const useStoredValues = (localStorageKey: string, initialValue: any) => {
  const storedValue = localStorage.getItem(localStorageKey);

  /**
   * Helper function for parsing storage date range filters
   * @param value
   */
  // Set state via useState Hook
  const [value, setValue] = React.useState(
    !storedValue ? initialValue : JSON.parse(storedValue)
  );

  // Save values to local storage once state updates
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);
  return [value, setValue];
};

/**
 * Higher Order Component for DataFilteringContext
 * @param props
 * @constructor
 */
const FormProvider: React.FC<FormProviderProps> = (props) => {
  const [Admediary, setAdmediary] = useStoredDates();
  const [Tree, setTree] = useStoredValues(LOCAL_STORAGE_KEYS.TREE_ID, 0);
  const [Priority, setPriority] = useStoredValues(
    LOCAL_STORAGE_KEYS.PRIORITY_ROUTE,
    0
  );
  const [Binding, setBinding] = useStoredValues(
    LOCAL_STORAGE_KEYS.BINDING_ROUTE,
    0
  );
  const [Route, setRoute] = useStoredValues(LOCAL_STORAGE_KEYS.ROUTE_ID, 0);
  const [Campaign, setCampaign] = useStoredValues(
    LOCAL_STORAGE_KEYS.CAMPAIGN_ID,
    0
  );
  const [CampaignKey, setCampaignKey] = useStoredValues(
    LOCAL_STORAGE_KEYS.CAMPAIGN_KEY,
    ""
  );
  const [Lock, setLock] = useStoredValues(LOCAL_STORAGE_KEYS.LOCK_ROUTE, false);
  const [RevShare, setRevShare] = useStoredValues(
    LOCAL_STORAGE_KEYS.REV_SHARE,
    ""
  );
  const [Client, setClient] = useStoredValues(LOCAL_STORAGE_KEYS.CLIENT, 0);
  const [Active, setActive] = useStoredValues(
    LOCAL_STORAGE_KEYS.ACTIVE_FORM,
    false
  );
  const [Monitor, setMonitor] = useStoredValues(
    LOCAL_STORAGE_KEYS.MONITOR,
    false
  );
  const [Search, setSearch] = useStoredValues(LOCAL_STORAGE_KEYS.SEARCH, false);
  const [ActiveRoute, setActiveRoute] = useStoredValues(
    LOCAL_STORAGE_KEYS.ACTIVE_FORM_ROUTE,
    false
  );

  const [DefaultRoute, setDefaultRoute] = useStoredValues(
    LOCAL_STORAGE_KEYS.DEFAULT_FORM_ROUTE,
    false
  );

  const [ActiveCampaign, setActiveCampaign] = useStoredValues(
    LOCAL_STORAGE_KEYS.ACTIVE_FORM_CAMPAIGN,
    false
  );
  const [Description, setDescription] = useStoredValues(
    LOCAL_STORAGE_KEYS.DESCRIPTION_FORM,
    ""
  );
  const [DescriptionRoute, setDescriptionRoute] = useStoredValues(
    LOCAL_STORAGE_KEYS.DESCRIPTION_FORM_ROUTE,
    ""
  );
  const [PriorityRoute, setPriorityRoute] = useStoredValues(
    LOCAL_STORAGE_KEYS.PRIORITY_FORM_ROUTE,
    0
  );
  const [DescriptionCampaign, setDescriptionCampaign] = useStoredValues(
    LOCAL_STORAGE_KEYS.DESCRIPTION_FORM_CAMPAIGN,
    ""
  );

  const [Rare, setRare] = useStoredValues(LOCAL_STORAGE_KEYS.RARE_FORM, "");
  const [Rate, setRate] = useStoredValues(LOCAL_STORAGE_KEYS.RATE_FORM, "");
  const [Category, setCategory] = useStoredValues(
    LOCAL_STORAGE_KEYS.CATEGORY_FORM,
    0
  );
  const [Refresh, setRefresh] = useStoredValues(
    LOCAL_STORAGE_KEYS.REFRESH,
    false
  );
  const [Loading, setLoading] = useStoredValues(
    LOCAL_STORAGE_KEYS.LOADING,
    false
  );

  const [Close, setClose] = useStoredValues(LOCAL_STORAGE_KEYS.CLOSE, false);
  const [Show, setShow] = useStoredValues(LOCAL_STORAGE_KEYS.SHOW, false);
  const [DataSourceKey, setDataSourceKey] = useStoredValues(
    LOCAL_STORAGE_KEYS.KEY,
    ""
  );
  const [Row, setRow] = useStoredValues(LOCAL_STORAGE_KEYS.ROW, 0);
  const [Company, setCompany] = useStoredValues(LOCAL_STORAGE_KEYS.COMPANY, "");
  const [Product, setProduct] = useStoredValues(
    LOCAL_STORAGE_KEYS.PRODUCT_FORM,
    0
  );
  const [Phone, setPhone] = useStoredValues(LOCAL_STORAGE_KEYS.PHONE, "");
  const [Message, setMessage] = useStoredValues(LOCAL_STORAGE_KEYS.MESSAGE, "");
  const [Messages, setMessages] = useStoredValues(
    LOCAL_STORAGE_KEYS.MESSAGES,
    []
  );
  const [Probability, setProbability] = useStoredValues(
    LOCAL_STORAGE_KEYS.PROBABILITY,
    0
  );
  const [Buyer, setBuyer] = useStoredValues(LOCAL_STORAGE_KEYS.BUYER, 0);
  const [BuyerName, setBuyerName] = useStoredValues(
    LOCAL_STORAGE_KEYS.BUYER_NAME,
    ""
  );
  const [Abbreviation, setAbbreviation] = useStoredValues(
    LOCAL_STORAGE_KEYS.ABBREVIATION,
    ""
  );
  const [Email, setEmail] = useStoredValues(LOCAL_STORAGE_KEYS.EMAIL, "");
  const [Filter, setFilter] = useStoredValues(LOCAL_STORAGE_KEYS.FILTER_ID, "");
  const [Address, setAddress] = useStoredValues(LOCAL_STORAGE_KEYS.ADDRESS, "");
  const [IP, setIP] = useStoredValues(LOCAL_STORAGE_KEYS.IP, "");
  const [CakeBuyerId, setCakeBuyerId] = useStoredValues(
    LOCAL_STORAGE_KEYS.CAKE_BUYER_ID,
    null
  );
  const [Affiliate, setAffiliate] = useStoredValues(
    LOCAL_STORAGE_KEYS.AFFILIATE,
    0
  );

  const [Schedule, setSchedule] = useStoredValues(
    LOCAL_STORAGE_KEYS.SCHEDULE,
    0
  );
  const [Day, setDay] = useStoredValues(LOCAL_STORAGE_KEYS.DAY, "");
  const [StartTime, setStartTime] = useStoredValues(
    LOCAL_STORAGE_KEYS.STARTTIME,
    ""
  );
  const [EndTime, setEndTime] = useStoredValues(LOCAL_STORAGE_KEYS.ENDTIME, 0);
  const [TemporaryContract, setTemporaryContract] = useStoredValues(
    LOCAL_STORAGE_KEYS.TEMPORARY_CONTRACT,
    0
  );
  const [TemporaryDay, setTemporaryDay] = useStoredValues(
    LOCAL_STORAGE_KEYS.TEMPORARY_DAY,
    ""
  );
  const [Contract, setContract] = useStoredValues(
    LOCAL_STORAGE_KEYS.CONTRACT,
    0
  );
  const [Schedules, setSchedules] = useStoredValues(
    LOCAL_STORAGE_KEYS.SCHEDULES,
    []
  );
  const [Contracts, setContracts] = useStoredValues(
    LOCAL_STORAGE_KEYS.CONTRACTS,
    []
  );
  const [From, setFrom] = useStoredValues(LOCAL_STORAGE_KEYS.FROM, []);
  const [Data, setData] = React.useState({
    products: [],
    categories: [],
    campaigns: [],
    routings: [],
    domainRegistrars: [],
    tierNames: [],
    emailTemplates: [],
    buyersWithCategory: [],
    buyerContracts: [],
    productSites: [],
    pixelCriteriaGroups: [],
  });
  const [Detail, setDetail] = useStoredValues(
    LOCAL_STORAGE_KEYS.DETAIL,
    "site"
  );
  const [DefaultAllow, setDefaultAllow] = useStoredValues(
    LOCAL_STORAGE_KEYS.DEFAULT_ALLOW,
    null
  );
  const [PostUrl, setPostUrl] = useStoredValues(
    LOCAL_STORAGE_KEYS.POST_URL,
    ""
  );
  const [Timeout, setTimeout] = useStoredValues(LOCAL_STORAGE_KEYS.TIMEOUT, 0);

  const [PingUrl, setPingUrl] = useStoredValues(
    LOCAL_STORAGE_KEYS.PING_URL,
    ""
  );
  const [PingTimeout, setPingTimeout] = useStoredValues(
    LOCAL_STORAGE_KEYS.PING_TIMEOUT,
    0
  );
  const [PingMinimumPrice, setPingMinimumPrice] = useStoredValues(
    LOCAL_STORAGE_KEYS.PING_MINIMUM_PRICE,
    0
  );
  const [PingContractId, setPingContractId] = useStoredValues(
    LOCAL_STORAGE_KEYS.PING_CONTRACT_ID,
    0
  );

  return (
    <FormContext.Provider
      value={{
        start: Admediary.start,
        end: Admediary.end,
        affiliate: Affiliate,
        lock: Lock,
        active: Active,
        activeRoute: ActiveRoute,
        defaultRoute: DefaultRoute,
        activeCampaign: ActiveCampaign,
        client: Client,
        revShare: RevShare,
        monitor: Monitor,
        search: Search,
        campaignKey: CampaignKey,
        category: Category,
        tree: Tree,
        campaign: Campaign,
        priority: Priority,
        binding: Binding,
        route: Route,
        refresh: Refresh,
        loading: Loading,
        close: Close,
        show: Show,
        dataSourceKey: DataSourceKey,
        row: Row,
        from: From,
        company: Company,
        description: Description,
        descriptionRoute: DescriptionRoute,
        priorityRoute: PriorityRoute,
        descriptionCampaign: DescriptionCampaign,
        rare: Rare,
        rate: Rate,
        product: Product,
        buyer: Buyer,
        phone: Phone,
        message: Message,
        messages: Messages,
        probability: Probability,
        buyerName: BuyerName,
        abbreviation: Abbreviation,
        email: Email,
        filter: Filter,
        address: Address,
        ip: IP,
        cakeBuyerId: CakeBuyerId,
        day: Day,
        schedule: Schedule,
        startTime: StartTime,
        endTime: EndTime,
        contract: Contract,
        schedules: Schedules,
        contracts: Contracts,
        temporaryContract: TemporaryContract,
        temporaryDay: TemporaryDay,
        setLock,
        setSearch,
        setRevShare,
        setActive,
        setClient,
        setMonitor,
        setPriority,
        setPriorityRoute,
        setBinding,
        setActiveRoute,
        setDefaultRoute,
        setActiveCampaign,
        setCampaignKey,
        setProduct,
        setCategory,
        setTree,
        setCampaign,
        setRoute,
        setRefresh,
        setLoading,
        setClose,
        setShow,
        setDataSourceKey,
        setRow,
        setCompany,
        setDescription,
        setDescriptionRoute,
        setDescriptionCampaign,
        setRare,
        setRate,
        setBuyer,
        setPhone,
        setMessage,
        setMessages,
        setProbability,
        setAbbreviation,
        setEmail,
        setFilter,
        setAddress,
        setIP,
        setCakeBuyerId,
        setBuyerName,
        setAffiliate,
        setDay,
        setSchedule,
        setStartTime,
        setEndTime,
        setContract,
        setSchedules,
        setContracts,
        setFrom,
        setTemporaryContract,
        setTemporaryDay,
        setDateRange: setAdmediary,
        data: Data,
        setData,
        detail: Detail,
        setDetail,
        defaultAllow: DefaultAllow,
        setDefaultAllow,
        postUrl: PostUrl,
        setPostUrl,
        timeout: Timeout,
        setTimeout,
        pingContractId: PingContractId,
        setPingContractId,
        pingUrl: PingUrl,
        setPingUrl,
        pingMinimumPrice: PingMinimumPrice,
        setPingMinimumPrice,
        pingTimeout: PingTimeout,
        setPingTimeout,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export { FormProvider, FormContext };

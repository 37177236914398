import React from "react";
import {
  Drawer,
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { sort } from "../../@Utils/Sorting";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { formatDistance, format, parseISO } from "date-fns";
import { exportCsv } from "../../@Utils/Export";
import Title from "../Title";

const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type ViewLeads = {
  row_data: any;
};

const ViewLeads: React.FC<ViewLeads> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const buyer_id =
    row_data.buyer_id || getFieldValueOrParentValue(row_data, "buyer_id");
  const contract_id =
    row_data.contract_id || getFieldValueOrParentValue(row_data, "contract_id");
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id");
  const sub_id_1 =
    row_data.sid1 || getFieldValueOrParentValue(row_data, "sid1");
  const sub_id_2 =
    row_data.sid2 || getFieldValueOrParentValue(row_data, "sid2");
  const disposition = row_data.disposition || "";
  // console.log(row_data.buyer_id)
  const params = {
    start_date,
    end_date,
    category_id: Admediary.category,
    product_id: Admediary.product,
    contract_id: contract_id ? contract_id : 0,
    buyer_id: buyer_id ? buyer_id : 0,
    affiliate_id,
    sub_id_1,
    sub_id_2,
    disposition,
  };

  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };
  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          time_distance: formatDistance(item.post_date, new Date()),
          price_received: item.price_received.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
        },
      };
    });
  };

  /**
   * Sort handler for Rsuite tables
   * @param list
   */
  const sortData = (list: any) => {
    return formatData(
      sort_column && sort_type ? sort(list, sort_column, sort_type) : list
    );
  };

  const formatDataForExporting = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          post_date: format(item.post_date, "y-MM-dd HH:mm"),
        },
      };
    });
  };

  const [data, isLoading] = useAdmediaryApi("buyer_transactions", params);
  // console.log(data)
  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {Admediary.drawerFull ? (
            <Icon
              icon="window-restore"
              onClick={Admediary.restoreDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          ) : (
            <Icon
              icon="window-maximize"
              onClick={Admediary.maximizeDrawer}
              style={{ marginTop: "3px", cursor: "pointer" }}
            />
          )}
        </Drawer.Title>
      </Drawer.Header>

      <Drawer.Body>
        <Title title="View Leads" />
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `leads_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  formatDataForExporting(data)
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          height={650}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          data={Array.isArray(data) ? sortData(data) : []}
          loading={isLoading === true}
          rowClassName="clickable-data striped-rows"
          sortColumn={sort_column}
          sortType={sort_type}
          onSortColumn={handleSortColumn}
        >
          <Column width={125} fixed sortable resizable>
            <HeaderCell>
              <span>ID</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead Instance ID</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="lead_instance_id" />
          </Column>

          <Column width={175} align="center" sortable>
            <HeaderCell>
              <span>Post Date</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Date lead posted</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell>
              {(rowData: any) => <span>{rowData.time_distance}</span>}
            </Cell>
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Buyer</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Buyer's Name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="buyer_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Contract</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of Buyer's Contract</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="contract_name" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Affiliate</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of Affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="affiliate_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Product</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Name of the Product</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="product_description" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>First Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's first name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="first_name" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Last Name</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's last name</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="last_name" />
          </Column>

          <Column sortable resizable width={200}>
            <HeaderCell>
              <span>Email</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Email address of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>Phone</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Phone # of customer</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="phone" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>Disposition</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Disposition Status of Lead</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="disposition" />
          </Column>

          <Column sortable resizable width={175}>
            <HeaderCell>
              <span>Reason</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Disposition Reason</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="disposition_detailed" />
          </Column>

          <Column align="right" sortable resizable width={125}>
            <HeaderCell>
              <span>Price</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Revenue generated by lead</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="price_received" />
          </Column>

          <Column align="right" sortable resizable width={135}>
            <HeaderCell>
              <span>IP</span>
              <Whisper
                trigger="hover"
                speaker={
                  <Tooltip>
                    IP address of customer's computer at submission
                  </Tooltip>
                }
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="client_ip" />
          </Column>

          <Column sortable resizable width={135}>
            <HeaderCell>
              <span>State</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Lead's State</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="edit_state" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID1</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 1 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid1" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID2</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 2 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid2" />
          </Column>

          <Column sortable resizable width={150}>
            <HeaderCell>
              <span>SID3</span>
              <Whisper
                trigger="hover"
                speaker={<Tooltip>Sub ID 3 provided by affiliate</Tooltip>}
              >
                <Icon icon="info" style={{ marginLeft: 7 }} />
              </Whisper>
            </HeaderCell>
            <Cell dataKey="sid3" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

export default ViewLeads;

import React from "react";
import { Query } from "../../@Utils/AdmediaryApi";
import { Form, InputNumber, Loader, Schema, SelectPicker } from "rsuite";
import Field from "../Field";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  response_id: NumberType(),
  contract_id: NumberType().isRequired("Contract ID is required."),
  text: StringType().isRequired("Text is required."),
  interpret_as: StringType().isRequired("Interpret As is required."),
  priority: NumberType()
    .isRequired("Priority is required.")
    .min(0, "Minimum is 0"),
});

type BuyerContractPostResponseFormType = {
  responseId?: number;
  contractId?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const BuyerContractPostResponseForm: React.FC<BuyerContractPostResponseFormType> = ({
  responseId = 0,
  contractId = 0,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const initItem = {
    response_id: 0,
    contract_id: contractId,
    text: "",
    interpret_as: "",
    priority: 0,
  };
  const [formValue, setFormValue] = React.useState(initItem);
  const [formError, setFormError] = React.useState({});

  const params = {
    response_id: responseId,
    contract_id: contractId,
  };

  const fetchData = React.useCallback(async () => {
    const setData = (data: any) =>
      handleChange(prepareFormData(Array.isArray(data) ? data[0] : data));

    if (params.response_id === 0) {
      setData(initItem);
      return;
    }

    try {
      setIsLoading(true);
      const results: any = await Query("buyer_contract_post_response", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [interpretAsList] = useAdmediaryApi(
    "buyer_contract_post_interpret_as_list"
  );

  const prepareFormData = (record: any) => {
    if (record === null) {
      return;
    }

    return record;
  };

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  // Render the component
  if (isLoading || formValue === null) {
    return <Loader center size="lg" content="Loading..." />;
  }

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Text" name="text" />
      <Field
        label="Interpret As"
        name="interpret_as"
        placeholder="Select Interpret As"
        accepter={SelectPicker}
        data={Array.isArray(interpretAsList) ? interpretAsList : []}
        valueKey="interpret_as"
        labelKey="interpret_as"
      />
      <Field
        label="Priority"
        name="priority"
        accepter={InputNumber}
        defaultValue={0}
        min={0}
      />
      {/*<span>{JSON.stringify(formValue)}</span>*/}
    </Form>
  );
};

export default BuyerContractPostResponseForm;

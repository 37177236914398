import React from "react";
import { Icon, IconButton, Popover, Table, Whisper, Dropdown } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { HeaderSummary, ToolTipCell } from "../Table";
import { percent, thousands } from "../../@Utils/Format";
import { sort } from "../../@Utils/Sorting";
import CakeOfferDrilldown from "../Drawers/CakeOfferDrilldown";
import ResponseTime from "../Drawers/ResponseTime";
import ServiceResponseTime from "../Drawers/ServiceResponseTime";
import { format } from "date-fns";
import EditSource from "../Drawers/EditSource";
import { FormInstance } from "rsuite/lib/Form/Form";
import RecentRecords from "../Drawers/RecentRecords";
import OutgoingSourceUrls from "../Drawers/OutgoingSourceUrls";
import Service from "../Drawers/Service";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type CakeOffersListProps = {
  data: any;
  dateColumns: any;
  startDate: Date;
  isLoading: boolean;
  rowKey: string;
  groupBy: number;
  sortType: string;
  sortColumn: string;
  onSortColumn: (column: any, type: any) => void;
};

const CakeOffersList: React.FC<CakeOffersListProps> = (props: any) => {
  const data = props.data;
  const dateColumns = props.dateColumns;
  const groupBy = props.groupBy;
  const isLoading = props.isLoading;
  const rowKey = props.rowKey;
  const sortType = props.sortType;
  const sortColumn = props.sortColumn;
  const handleSortColumn = props.onSortColumn;
  // let style = ""

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {};

  /**
   * @param list
   */
  const sortData = (list: any) => {
    return list;
  };

  return (
    <Table
      virtualized
      height={650}
      headerHeight={65}
      loading={isLoading === true}
      data={data}
      rowKey={rowKey}
      rowClassName="clickable-data striped-rows"
      affixHeader
      affixHorizontalScrollbar
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
    >
      <Column width={90} sortable>
        <HeaderCell>Offer ID</HeaderCell>
        <Cell dataKey="offer_id" />
      </Column>
      <Column width={200} sortable resizable>
        <HeaderCell>Offer Name</HeaderCell>
        <Cell dataKey="offer_name" />
      </Column>
      <Column width={100} sortable>
        <HeaderCell title="Clicks">Clicks</HeaderCell>
        <Cell dataKey="click_count" />
      </Column>
      <Column width={100} sortable>
        <HeaderCell title="Total Conversions">Conversions</HeaderCell>
        <Cell dataKey="conversion_count" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell title="Paid Conversions">Paid</HeaderCell>
        <Cell dataKey="paid_total" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Total Paid</HeaderCell>
        <Cell dataKey="paid_total" />
      </Column>
      <Column width={150} sortable resizable>
        <HeaderCell title="Conversion Percentage">Conversion Perc%</HeaderCell>
        <Cell dataKey="conversion_percentage" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Revenue</HeaderCell>
        <Cell dataKey="revenue_str" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Cost</HeaderCell>
        <Cell dataKey="cost" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Profit</HeaderCell>
        <Cell dataKey="profit" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Margin</HeaderCell>
        <Cell dataKey="margin" />
      </Column>
      <Column width={90} sortable>
        <HeaderCell>Earnings Per Click</HeaderCell>
        <Cell dataKey="earnings_per_click" />
      </Column>
      <Column width={90}>
        <HeaderCell>&nbsp;</HeaderCell>
        <ActionCell />
      </Column>
    </Table>
  );
};

/*
Action list:
 */
const Menu = ({ onSelect, groupBy, exclude = [] }: any) => (
  <Dropdown.Menu onSelect={onSelect}>
    <Dropdown.Item eventKey="affiliates">Affiliate Breakdown</Dropdown.Item>
    <Dropdown.Item eventKey="daily">Daily Breakdown</Dropdown.Item>
  </Dropdown.Menu>
);

const MenuPopover = ({ onSelect, groupBy, ...rest }: any) => (
  <Popover {...rest} full>
    <Menu onSelect={onSelect} groupBy={groupBy} />
  </Popover>
);

type TableWhisper = {
  row: any;
};

const CustomWhisper: React.FC<TableWhisper> = (props: any) => {
  const row = props.row;
  const admediaryContext = React.useContext(AdmediaryContext);
  const triggerRef = React.createRef();
  const start = admediaryContext.start;
  const end = admediaryContext.end;
  const formRef = React.createRef<FormInstance>();

  function handleSelectMenu(eventKey: string, event: any) {
    // Stop the event bubbling
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    triggerRef.current.hide();

    const contents = new Map([
      [
        "affiliates",
        <CakeOfferDrilldown
          rowData={row}
          start={start}
          end={end}
          groupBy="affiliate"
        />,
      ],
      [
        "daily",
        <CakeOfferDrilldown
          rowData={row}
          start={start}
          end={end}
          groupBy="date"
        />,
      ],
    ]);
    const sizes = new Map([["default", "lg"]]);

    // Set selected content into drawer
    admediaryContext.openDrawer(
      contents.has(eventKey) ? contents.get(eventKey) : contents.get("default"),
      "full"
    );
  }

  return (
    <Whisper
      placement="autoHorizontalStart"
      trigger="hover"
      enterable
      triggerRef={triggerRef}
      speaker={<MenuPopover onSelect={handleSelectMenu} />}
    >
      <IconButton appearance="subtle" icon={<Icon icon="ellipsis-v" />} />
    </Whisper>
  );
};

const ActionCell = ({ rowData, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <CustomWhisper row={rowData} />
    </Cell>
  );
};

export default CakeOffersList;

import React from "react";
import {
  FlexboxGrid,
  Icon,
  IconButton,
  Table,
  ButtonToolbar,
  Input,
  InputGroup,
} from "rsuite";

import { Query } from "../../../@Utils/AdmediaryApi";
import { AdmediaryContext } from "../../../@Context/AdmediaryContext";
import { FormContext } from "../../../@Context/FormContext";
import { exportCsv } from "../../../@Utils/Export";
import { format } from "date-fns";
import { CheckCell } from "../../Table";
import BuyerLead from "../../Drawers/BuyerLead";
import { FormInstance } from "rsuite/lib/Form/Form";
import { thousands } from "../../../@Utils/Format";
import { sort } from "../../../@Utils/Sorting";
import Title from "../../Title";
const { Column, HeaderCell, Cell } = Table;
// ***********************************************************
//             Interfaces
// ***********************************************************

/**
 * interface for form params
 *
 */
interface paramsType {
  start_date: Date;
  end_date: Date;
}
function formatDataForExporting(data: any) {
  return data.map((item: any) => {
    return {
      "Buyer ID": item.buyer_id,
      "Buyer Name": item.buyer_name,
      Active: item.active,
    };
  });
}
const BuyerManageList: React.FC = (props: any) => {
  const admediaryContext = React.useContext(AdmediaryContext);
  const formContext = React.useContext(FormContext);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort_type, sort_sort_type] = React.useState();
  const [sort_column, set_sort_column] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const intervalRef: any = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState();
  const mainFormRef = React.createRef<FormInstance>();

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };
  const params = {
    start_date: admediaryContext.start,
    end_date: admediaryContext.end,
  };
  const fetchData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const results: any = await Query("buyer_manage", params);
      setData(results.data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, []);
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const editHandler = (rowData: any) => {
    admediaryContext.openDrawer(
      <BuyerLead
        buyerId={rowData.buyer_id}
        formRef={mainFormRef}
        parentCallback={handleCallback}
        reloadingData={fetchData}
      />,
      "sm"
    );
  };

  const handleAddContractClick = () => {
    // Set selected content into drawer
    formContext.setActive(false);
    formContext.setBuyerName("");
    formContext.setAbbreviation("");
    formContext.setEmail("");
    formContext.setAddress("");
    formContext.setPhone("");
    formContext.setIP("");
    formContext.setCakeBuyerId("");
    formContext.setFilter(0);
    admediaryContext.openDrawer(
      <BuyerLead buyerId={0} reloadingData={fetchData} />,
      "sm"
    );
  };
  /**
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    sort_sort_type(type);
  };

  /**
   * Method is used to debounce server request while user types their search keyword
   * @param value
   * @param type
   */
  const debounceSearchTerm = (value: any, type: number = 0) => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
    }
    intervalRef.current = setTimeout(() => {
      setSearchQuery(value);
    }, 250);
  };

  /**
   * Filter data by search string as live search
   *
   * @param data
   * @param search
   */
  const filter = (data: any, search: string) => {
    if (!search) {
      return data;
    }

    search = search.toLowerCase();
    const include = (v: any) => v.toLowerCase().includes(search);
    const fields = ["buyer_name"];

    // Check if any field includes a search string
    return data.filter((item: any) =>
      fields.some((field: string) => include(item[field]))
    );
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          total_count: thousands(item.total_count),
        },
      };
    });
  };

  /**
   * @param list
   */

  const sortData = (list: any) => {
    if (sort_column && sort_type) {
      return formatData(sort(list, sort_column, sort_type));
    }
    return formatData(list);
  };

  return (
    <>
      <Title title="Edit Buyers" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={15}>
            <InputGroup style={{ width: 150, display: "inline-block" }}>
              <Input
                placeholder="Search"
                onChange={(v) => {
                  debounceSearchTerm(v);
                }}
                size="sm"
              />
            </InputGroup>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={9} style={{ textAlign: "left" }}>
            <ButtonToolbar>
              <IconButton
                onClick={handleAddContractClick}
                icon={<Icon icon="plus-circle" />}
                placement="left"
                size="sm"
                appearance="ghost"
                disabled={admediaryContext.userDisable}
              >
                Add Buyer
              </IconButton>
            </ButtonToolbar>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `buyers_list_${format(
                  new Date(admediaryContext.start),
                  "MMddyy"
                )}_${format(new Date(admediaryContext.end), "MMddyy")}.csv`,
                formatDataForExporting(Array.isArray(data) ? data : [])
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        virtualized
        // height={650}
        autoHeight={true}
        loading={isLoading === true}
        data={filter(Array.isArray(data) ? sortData(data) : [], searchQuery)}
        hover
        rowClassName="clickable-data striped-rows"
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      >
        <Column width={250} fixed sortable resizable>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="buyer_id" />
        </Column>
        <Column width={250} fixed sortable resizable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="buyer_name" />
        </Column>
        <Column width={300} fixed sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active"></CheckCell>
        </Column>

        <Column width={250} fixed sortable resizable>
          <HeaderCell>Action </HeaderCell>
          <ActionCell editHandler={editHandler}></ActionCell>
        </Column>
      </Table>
    </>
  );
};
const ActionCell = ({ rowData, editHandler, ...props }: any) => {
  return (
    <Cell {...props} className="link-group">
      <IconButton
        appearance="subtle"
        onClick={() => editHandler(rowData)}
        icon={<Icon icon="edit2" />}
      />
    </Cell>
  );
};
export default BuyerManageList;

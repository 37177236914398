import React, { useContext } from "react";
import { FlexboxGrid, Icon, IconButton, Notification, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { CheckCell, EditRemoveActionCell } from "../Table";
import ConfirmModal from "../ConfirmModal";
import EditModal from "../EditModal";
import BuyerContractFilterMapForm from "./BuyerContractFilterMapForm";
import { Query } from "../../@Utils/AdmediaryApi";
import { FormInstance } from "rsuite/lib/Form/Form";
import _ from "lodash";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";

const { Column, HeaderCell, Cell } = Table;

/**
 * BuyerTreeTable is a separated component to prevent a scroll-to-top bug
 */
type BuyerContractFilterMapListType = {
  contractId: number;
  contractData: any;
  height?: number;
  autoHeight?: boolean;
};

const BuyerContractFilterMapList: React.FC<BuyerContractFilterMapListType> = ({
  contractId = 0,
  contractData,
  height = 650,
  autoHeight = true,
}) => {
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [formValue, setFormValue] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();
  const Admediary = useContext(AdmediaryContext);
  const disabled = Admediary.userDisable;

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
      };
    });
  };

  /**
   * @param list
   */
  const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  // Additional parameter to refresh the table data
  const [refreshFlag, setRefreshFlag] = React.useState(false);
  const refresh = () => {
    setRefreshFlag(!refreshFlag);
  };
  const params = {
    refresh: refreshFlag,
    contract_id: contractId,
  };
  const [data, isLoading] = useAdmediaryApi(
    "buyer_contract_filter_maps",
    params
  );

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node && !node.check()) {
      return;
    }

    setFormLoading(true);

    // Build data for saving
    // We should clone data to change it
    const data: any = _.cloneDeep(formValue);

    // Checkbox value is array and empty when unchecked. Should be transform to 0 or 1
    if (Array.isArray(data.active)) {
      data.active = data.active.length ? data.active[0] : 0;
    }

    (async () => {
      try {
        await Query("buyer_contract_filter_map_update", data);

        setFormLoading(false);

        Notification.success({
          title: "Success",
          description:
            "Buyer Contract Filter Map has been " +
            (data.mapping_id ? "updated" : "added"),
        });

        handleEditModalClose();
        refresh();
      } catch (e) {
        setFormLoading(false);
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    (async () => {
      try {
        await Query("buyer_contract_filter_map_remove", { mapping_id: itemId });
        Notification.success({
          title: "Success",
          description: "Buyer Contract Filter Map has been removed",
        });

        refresh();
      } catch (e) {
        console.log("error", e);
        Notification.error({
          title: "Error",
          duration: 60000,
          description: e,
        });
      } finally {
      }
    })();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoving = () => {
    removeItem(selectedItem.mapping_id);

    handleClose();
  };

  const handleEditActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleEditModalOpen();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const handleAddItemClick = () => {
    setSelectedItem({
      mapping_id: 0,
      contract_id: contractId,
    });

    handleEditModalOpen();
  };

  const getActionTitle = (abbr: string) => {
    const types = new Map([
      ["", ""],
      ["a", "Accept"],
      ["d", "Drop"],
      ["c", "Continue"],
    ]);

    return types.has(abbr) ? types.get(abbr) : null;
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{ marginBottom: 15 }}>
        <FlexboxGrid.Item colspan={5}>
          {!Admediary.userDisable ? (
            <IconButton
              onClick={handleAddItemClick}
              icon={<Icon icon="plus-circle" />}
              placement="left"
              size="sm"
              appearance="ghost"
              style={{ marginRight: 15 }}
            >
              Add Filter Map
            </IconButton>
          ) : (
            ""
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Table
        height={height}
        autoHeight={autoHeight}
        loading={isLoading === true}
        data={sortData(Array.isArray(data) ? data : [])}
        rowClassName="clickable-data striped-rows"
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        <Column width={100} align={"center"} fixed>
          <HeaderCell>Action</HeaderCell>
          <EditRemoveActionCell
            editHandler={handleEditActionClick}
            removeHandler={handleRemoveActionClick}
            userPermission={Admediary.userDisable}
          />
        </Column>
        )
        <Column width={100} sortable resizable>
          <HeaderCell>ID</HeaderCell>
          <Cell dataKey="mapping_id" />
        </Column>
        <Column align={"center"} sortable resizable>
          <HeaderCell>Active</HeaderCell>
          <CheckCell dataKey="active" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Priority</HeaderCell>
          <Cell dataKey="priority" />
        </Column>
        <Column width={300} sortable resizable>
          <HeaderCell>Description</HeaderCell>
          <Cell dataKey="description" />
        </Column>
        <Column width={100} sortable resizable>
          <HeaderCell>Action</HeaderCell>
          <Cell dataKey="action">
            {(rowData: any) => getActionTitle(rowData.action)}
          </Cell>
        </Column>
      </Table>

      <ConfirmModal
        title="Removing"
        open={open}
        onClose={handleClose}
        onYes={handleRemoving}
      >
        Are you sure you want to remove this Filter Map?
      </ConfirmModal>

      <EditModal
        title={
          (selectedItem && selectedItem.mapping_id > 0 ? "Edit" : "Add") +
          " Filter Map"
        }
        open={editModalOpen}
        size={"sm"}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
        loading={formLoading}
        hideSubmitButton={Admediary.userDisable}
      >
        <BuyerContractFilterMapForm
          mappingId={selectedItem ? selectedItem.mapping_id : 0}
          contractId={selectedItem ? selectedItem.contract_id : 0}
          categoryId={contractData ? contractData.category_id : 0}
          buyerId={contractData ? contractData.buyer_id : 0}
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default BuyerContractFilterMapList;

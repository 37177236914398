import React from "react";
import Dashboard from "../@Components/Dashboard";
import { FlexboxGrid, Button } from "rsuite";

const linkStyle = (rowIndex: number) => {
  const background = ["#385723", "#203864", "#C00000", "#767171"][rowIndex];
  return {
    backgroundColor: background,
    color: "#d4d4d4",
    padding: "0.2em",
    paddingBottom: "0.3em",
    borderRadius: "1em",
    border: "2px solid #444",
    fontWeight: 1000,
    fontSize: "1.1em",
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 4%)",
    marginBottom: 30,
    width: "95%",
    minHeight: "4.8em",
    lineHeight: "2",
    textShadow: "1px 1px #222",
  };
};

const colStyle = {
  minHeight: "4.1em",
};

const rowHeight = {};

const ExternalReports: React.FC = () => {
  return (
    <Dashboard>
      <h2 style={{ marginBottom: "1em" }}>External Reports</h2>
      <FlexboxGrid justify="space-between">
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1GwaSBsI5AvaRMMGY1hLufc-VNASZj7SgsfKu3DEOL3Y/edit#gid=1890552446",
                "_blank"
              )
            }
          >
            Variance Reports
          </Button>
        </FlexboxGrid.Item>
        {/*
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://datastudio.google.com/reporting/d8f6b0ae-85b1-48df-894e-c4f33e305c2b/page/ZmYnC",
                "_blank"
              )
            }
          >
            Data Validation <wbr />
            Reports
          </Button>
        </FlexboxGrid.Item>
        */}
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1rxlFBIWOBwFFZDAip2-Kd1Hyx9rpGd06Sc-XdCexUKo/edit#gid=905433550",
                "_blank"
              )
            }
          >
            Legal GP <wbr />
            Report - Actions
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1XGr4C_4Q3TzweAmLh2Z97HP1hlUMkdBBC9jcebpXApQ/edit#gid=656550847",
                "_blank"
              )
            }
          >
            Short Form <wbr />
            Reports - Actions
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open("https://linktr.ee/ADMreports", "_blank")
            }
          >
            GDS Reports
          </Button>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={6}>
          <Button
            style={linkStyle(1)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1igqd9w8CCatS71w1VTsO4JtmQY7XkKiT_HHtKmETIfc/edit#gid=1619186736",
                "_blank"
              )
            }
          >
            Legal Buy & <wbr />
            Response Rate
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(1)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1eUVxXV-T02BL6oRbduaZYhgY5M6ncAn-o7ThRROZ-QM/edit#gid=960978561",
                "_blank"
              )
            }
          >
            Legal Search <wbr />
            Campaigns
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(1)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1WcGPeH2tRRURVJ2jnPFVIl8WNquR-yF6nII_Jpx27VM/edit#gid=0",
                "_blank"
              )
            }
          >
            Legal Budget & <wbr />
            Outreach
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(1)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1WqhnKvMbNYiw4zZiR77Y7tenq-i85IfiJjII_mVC77Q/edit#gid=1672555040",
                "_blank"
              )
            }
          >
            eSign Campaign <wbr />
            Financial Model
          </Button>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(2)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1n3zfErPKNRk1614E29tpq8E0WMRO0F_Zur4h9yrQ0qs/edit#gid=0",
                "_blank"
              )
            }
          >
            Media Planning & <wbr />
            Testing
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(2)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1i5oOzQ0lcuge4WyKi8O3n8aR1Bv9LRLpSGTeHOl-9bI/edit#gid=0",
                "_blank"
              )
            }
          >
            Review Site <wbr />
            Publishers
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(2)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1GfjPLbsT9JUC-60dkZMbN1kq4BjlSy_JaGN7AJdpAv4/edit#gid=0",
                "_blank"
              )
            }
          >
            Call Publishers
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(2)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1v07bdZBQKOMqSPM1c-B-W54fsDbZGVfuIir8wN4Ih7s/edit#gid=725301583",
                "_blank"
              )
            }
          >
            Conferences
          </Button>
        </FlexboxGrid.Item>

        {/*
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(0)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1oS_gLiZBefCnSukwjaL4I12vv-lWM03IqtfOxwxAZcs/edit#gid=0",
                "_blank"
              )
            }
          >
            Twilio Product <wbr />
            Checklist
          </Button>
        </FlexboxGrid.Item>
        */}
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(3)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1oS_gLiZBefCnSukwjaL4I12vv-lWM03IqtfOxwxAZcs/edit#gid=0",
                "_blank"
              )
            }
          >
            Twilio Program <wbr />
            Site Checklist
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(3)}
            className="external-link"
            onClick={() =>
              window.open(
                "https://docs.google.com/spreadsheets/d/1SRaBNn7hNSUraU5x1jpduwnIxohc6nrDvNCoEeC4emc/edit#gid=1531283949",
                "_blank"
              )
            }
          >
            Domain Checks
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={colStyle}>
          <Button
            style={linkStyle(3)}
            className="external-link"
            onClick={() =>
              window.open("http://offersuite.admediary.com/", "_blank")
            }
          >
            Offer Suite
          </Button>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
      </FlexboxGrid>
    </Dashboard>
  );
};

export default ExternalReports;

import React from "react";
import { Drawer, FlexboxGrid, Icon, IconButton, Table } from "rsuite";
import useAdmediaryApi from "../../@Hooks/useAdmediaryApi";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import { format, parseISO } from "date-fns";
import { CurrencyCell, HeaderSummary, NumberCell, PercentCell } from "../Table";
import { currency, percent, thousands } from "../../@Utils/Format";
import { exportCsv } from "../../@Utils/Export";

const { Column, HeaderCell, Cell } = Table;

const getFieldValueOrParentValue = (data: any, field: string): any => {
  const parent = data._parent;
  if (parent) {
    return parent[field] || getFieldValueOrParentValue(parent, field);
  }

  return data.field;
};

type HourlyReport = {
  row_data: any;
};

const HourlyReport: React.FC<HourlyReport> = ({ row_data }) => {
  const Admediary = React.useContext(AdmediaryContext);
  const rowKey: string = "node_id";
  // const [sort_type, sort_sort_type] = React.useState();
  // const [sort_column, set_sort_column] = React.useState();

  const start_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.start;
  const end_date = row_data.entry_date
    ? parseISO(row_data.entry_date)
    : Admediary.end;
  const contract_id =
    row_data.contract_id || getFieldValueOrParentValue(row_data, "contract_id");
  const affiliate_id =
    row_data.affiliate_id ||
    getFieldValueOrParentValue(row_data, "affiliate_id");

  const params = {
    start_date,
    end_date,
    category_id: Admediary.category,
    product_id: Admediary.product,
    contract_id,
    affiliate_id,
  };

  const [data, isLoading] = useAdmediaryApi("buyer_hourly_report", params);
  const treeData =
    !isLoading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  const summary = getSummary(Array.isArray(data) ? data : []);
  const exportData = getExportData(treeData);

  return (
    <>
      <Drawer.Header className="buyer-sidebar-header">
        <Drawer.Title>Hourly Report</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
          <FlexboxGrid.Item colspan={24} style={{ textAlign: "right" }}>
            <IconButton
              onClick={() =>
                exportCsv(
                  `buyer_hourly_report_${format(
                    new Date(Admediary.start),
                    "MMddyy"
                  )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                  exportData
                )
              }
              icon={<Icon icon="file-download" />}
              placement="right"
              size="sm"
            >
              Export
            </IconButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Table
          isTree
          height={650}
          headerHeight={65}
          loading={isLoading === true}
          data={treeData}
          rowKey={rowKey}
          rowClassName="clickable-data striped-rows"
          affixHeader
        >
          <Column width={150} fixed sortable resizable>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="entry_date" />
          </Column>
          <Column width={110} fixed sortable resizable>
            <HeaderCell>Disposition</HeaderCell>
            <Cell dataKey="disposition" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Considered" summary={summary.considered} />
            </HeaderCell>
            <NumberCell dataKey="considered" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Filtered" summary={summary.filtered} />
            </HeaderCell>
            <NumberCell dataKey="filtered" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Filter %"
                summary={percent(summary.filter_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="filter_percent" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Presented" summary={summary.presented} />
            </HeaderCell>
            <NumberCell dataKey="presented" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Accepted" summary={summary.accepted} />
            </HeaderCell>
            <NumberCell dataKey="accepted" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Accept %"
                summary={percent(summary.accept_percent)}
              />
            </HeaderCell>
            <PercentCell dataKey="accept_percent" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Rejected" summary={summary.rejected} />
            </HeaderCell>
            <NumberCell dataKey="rejected" />
          </Column>

          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="Paid" summary={currency(summary.rate)} />
            </HeaderCell>
            <CurrencyCell dataKey="rate" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Earned"
                summary={currency(summary.earned)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="earned" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary
                title="Profit"
                summary={currency(summary.profit)}
              />
            </HeaderCell>
            <CurrencyCell dataKey="profit" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="CPL" summary={currency(summary.cpl)} />
            </HeaderCell>
            <CurrencyCell dataKey="cpl" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="RPL" summary={currency(summary.rpl)} />
            </HeaderCell>
            <CurrencyCell dataKey="rpl" />
          </Column>
          <Column align="right" sortable resizable>
            <HeaderCell>
              <HeaderSummary title="PPL" summary={currency(summary.ppl)} />
            </HeaderCell>
            <CurrencyCell dataKey="ppl" />
          </Column>
        </Table>
      </Drawer.Body>
    </>
  );
};

function getSummary(data: any) {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;

  const summaryFields = [
    "considered",
    "filtered",
    "presented",
    "accepted",
    "rejected",
    "rate",
    "earned",
    "profit",
  ];
  summaryFields.forEach((field) => {
    summary[field] = 0;
  });

  summaryFields.forEach((field: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[field]);
    });
    summary[field] = values.reduce(summarizer, 0) || 0;
  });

  return addCalculatedValues(summary);
}

function getExportData(data: any) {
  // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
  const flattenTree = (list: any, parent?: any) => {
    const result: any[] = [];

    list.forEach((item: any) => {
      parent = parent || item;

      // Add parent values
      const current = {
        ...item,
        ...{
          entry_date: item.entry_date || parent.entry_date,
        },
      };

      if (current.children && Array.isArray(current.children)) {
        result.push(...flattenTree(current.children, current));
      } else {
        result.push(current);
      }
    });

    return result;
  };

  return (
    flattenTree(data.map((item: any) => item))
      // Clean, format and reorder by fields with right headers
      .map((item: any) => {
        return {
          "Entry Date": item.entry_date,
          Disposition: item.disposition,
          Considered: thousands(item.considered),
          Filtered: thousands(item.filtered),
          "Filter %": percent(parseFloat(item.filter_percent)),
          Presented: thousands(item.presented),
          Accepted: thousands(item.accepted),
          "Accept %": percent(parseFloat(item.accept_percent)),
          Rejected: thousands(item.rejected),
          Rate: currency(item.rate),
          Earned: currency(item.earned),
          Profit: currency(item.profit),
          CPL: currency(item.cpl),
          RPL: currency(item.rpl),
          PPL: currency(item.ppl),
        };
      })
  );
}

function addCalculatedValues(row: any) {
  try {
    row.entry_date = row.entry_date
      ? format(parseISO(row.entry_date), "y-MM-dd HH:mm")
      : "";
  } catch (e) {
    row.entry_date = "";
  } finally {
  }

  // TODO Move data formatting
  row.rate = parseFloat(row.rate);
  row.earned = parseFloat(row.earned);
  row.profit = parseFloat(row.profit);
  row.accepted = parseFloat(row.accepted);
  row.considered = parseFloat(row.considered);
  row.presented = parseFloat(row.presented);
  row.filtered = parseFloat(row.filtered);

  return {
    ...row,
    ...{
      cpl: row.accepted !== 0 ? row.rate / row.accepted : 0,
      rpl: row.accepted !== 0 ? row.earned / row.accepted : 0,
      ppl: row.accepted !== 0 ? row.profit / row.accepted : 0,
      filter_percent:
        row.considered !== 0 ? (row.filtered / row.considered) * 100 : 0,
      accept_percent:
        row.presented !== 0 ? (row.accepted / row.presented) * 100 : 0,
    },
  };
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}

export default HourlyReport;

import React from "react";
import { FlexboxGrid, Icon, IconButton, SelectPicker } from "rsuite";
import { AdmediaryContext } from "../../@Context/AdmediaryContext";
import useAdmediaryApiManual from "../../@Hooks/useAdmediaryApiManual";
import { format } from "date-fns";
import EsignDocStatusTable from "./EsignDocStatusTable";
import Title from "../Title";
import { exportCsv } from "../../@Utils/Export";
import moment from "moment";

const EsignDocStatus: React.FC = () => {
  const Admediary = React.useContext(AdmediaryContext);
  const [sort_type, set_sort_type] = React.useState("desc");
  const [sort_column, set_sort_column] = React.useState("date_created");
  const defaultGroupValue = "date_created";
  const [group, setGroup] = React.useState(defaultGroupValue);
  const defaultStatusGroupValue = "all";
  const [statusgroup, setStatusGroup] = React.useState(defaultStatusGroupValue);

  const groupByList = [
    { group: "date_created", label: "Date Created" },
    { group: "date_modified", label: "Date Modified" },
  ];

  const docStatusGroupByList = [
    { status: "all", label: "All" },
    { status: "document.completed", label: "Completed" },
    { status: "document.viewed", label: "Viewed" },
    { status: "document.sent", label: "Sent" },
    { status: "document.voided", label: "Voided" },
  ];

  const params = {
    start_date: Admediary.start,
    end_date: Admediary.end,
    product_id: Admediary.product,
    group: group,
    status: statusgroup,
    buyer_id: Admediary.buyer ? Admediary.buyer : null,
    contract_id: Admediary.buyerContract.length
      ? Admediary.buyerContract.join(",")
      : "",
  };

  const [data, isLoading] = useAdmediaryApiManual(
    "panda_doc_status_lead_select",
    params
  );

  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          doc_completed_rate: parseFloat(item.doc_completed_rate).toFixed(2),
          post_data: Object.fromEntries(new URLSearchParams(item.post_data)),
        },
      };
    });
  };

  /**
   * Handler for flattening data in order to get all of the post_data fields for the table and exporting
   * START
   */

  const dataToFlatten = Array.isArray(data) ? formatData(data) : [];

  const flattenTree = (list: any) => {
    const flattened: any = {};

    Object.keys(list).forEach((key: any) => {
      const value = list[key];

      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, flattenTree(value));
      } else {
        flattened[key] = value;
      }
    });
    return flattened;
  };

  const flattenLoop = (list: any) => {
    return list.map((item: any) => {
      return flattenTree(item);
    });
  };
  /**
   * Handler for flattening data in order to get all of the post_data fields for the table and exporting
   * END
   */

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    set_sort_column(column);
    set_sort_type(type);
  };

  function formatDataForExporting(data: any) {
    return data.map((item: any) => {
      return {
        ...{
          "Lead Instance ID": item.lead_instance_id
            ? item.lead_instance_id
            : "",
          "Product ID": item.product_id ? item.product_id : "",
          "Product Description": item.product_description
            ? item.product_description
            : "",
          "First Name": item.first_name ? item.first_name : "",
          "Last Name": item.last_name ? item.last_name : "",
          Email: item.email ? item.email : "",
          Phone: item.phone ? item.phone : "",
          City: item.city ? item.city : "",
          State: item.state ? item.state : "",
          Zip: item.zip ? item.zip : "",
          "Affiliate ID": item.affiliate_id ? item.affiliate_id : "",
          "Sid 1": item.edit_sid1 ? item.edit_sid1 : "",
          "Sid 2": item.edit_sid2 ? item.edit_sid2 : "",
          "Sid 3": item.edit_sid3 ? item.edit_sid3 : "",
          "Template Name": item.template_name ? item.template_name : "",
          "Doc Status": item.doc_status ? item.doc_status : "",
          "Doc Revenue": item.doc_completed_rate ? item.doc_completed_rate : "",
          "Date Created": item.date_created
            ? moment(item.date_created).local().format("MM-DD-Y HH:mm")
            : "",
          "Last Modified": item.date_modified
            ? moment(item.date_modified).local().format("MM-DD-Y HH:mm")
            : "",
        },
      };
    });
  }

  return (
    <>
      <Title title="Esign Doc Status" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={19}>
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Filter by"
            defaultValue={defaultGroupValue}
            value={group}
            data={groupByList}
            valueKey="group"
            labelKey="label"
            onChange={(v) => {
              setGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            size="sm"
            searchable={false}
            cleanable={false}
            placeholder="Filter Status by"
            defaultValue={defaultStatusGroupValue}
            value={statusgroup}
            data={docStatusGroupByList}
            valueKey="status"
            labelKey="label"
            onChange={(v) => {
              setStatusGroup(v);
            }}
            style={{ marginRight: 15 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={3} style={{ textAlign: "right" }}>
          <IconButton
            onClick={() =>
              exportCsv(
                `esign_status_${format(
                  new Date(Admediary.start),
                  "MMddyy"
                )}_${format(new Date(Admediary.end), "MMddyy")}.csv`,
                formatDataForExporting(flattenLoop(dataToFlatten))
              )
            }
            icon={<Icon icon="file-download" />}
            placement="right"
            size="sm"
          >
            Export
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <EsignDocStatusTable
        data={flattenLoop(dataToFlatten)}
        isLoading={isLoading === true}
        sortColumn={sort_column}
        sortType={sort_type}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

export default EsignDocStatus;
